<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-textarea
          solo
          name="input-7-4"
          clearable
          clear-icon="mdi-close-circle"
          v-model="message"
        ></v-textarea>
        <v-btn
          color="primary"
          small
          @click="sendEmailClick()"
          :disabled="!message.length || (!selected.length && !selectedMembers.length)"
        >Send</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="2"
        class="py-1"
      > Entrants: </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12">
        <v-checkbox
          v-model="selectAllEntrants"
          label="Select All"
          class="my-0 py-0"
          v-if="uniqueObj && uniqueObj.length > 0"
        ></v-checkbox>
        <span v-else>No entries yet!</span>
        <v-checkbox
          v-for="(e, i) in this.uniqueObj"
          :key="i"
          v-model="selected"
          :value="e.user_id"
          :label="e.nickname"
          class="my-0 py-0"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row v-if="members && members.length > 0">
      <v-col
        cols="2"
        class="py-1"
      > Remaining Members: </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12">
        <v-checkbox
          v-model="selectAllMembers"
          label="Select All"
          class="my-0 py-0"
        ></v-checkbox>
        <v-row
          v-for="(e, i) in this.members"
          :key="i"
          class="ma-0"
        >
          <v-col class="pa-0">
            <v-checkbox
              v-model="selectedMembers"
              :value="e.user_id"
              :label="e.nickname + ' (' + e.firstname + ' ' + e.lastname + ')'"
              class="my-0 py-0"
            ></v-checkbox>
          </v-col>
          <v-col class="pa-0">
            <v-btn
              small
              color="error"
              @click="confirmEntry(e)"
            >Enter</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showEnterDialog"
      v-if="selectedEnterMember"
      persistent
      max-width="320"
    >
      <v-card>
        <v-card-title class="headline">
          Enter the Contest?
        </v-card-title>
        <v-card-text>
          <p>Are you sure you want to enter {{ selectedEnterMember.nickname }} into this contest?</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            raised
            @click="enterMember"
          >
            Enter Contest
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            raised
            @click="cancelEnter"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import ContestService from '@/services/ContestService'

export default {
  name: 'ContestEmail',
  created () {
    this.init()
  },

  props: {
    contest: Object,
    group: Object
  },

  data () {
    return {
      userId: null,
      uniqueObj: [],
      members: [],
      message: '',
      mailData: [],
      selected: [],
      selectedMembers: [],
      selectAllEntrants: false,
      selectAllMembers: false,
      selectedEnterMember: null
    }
  },

  watch: {
    selectAllEntrants (n, o) {
      if (n !== o) {
        if (n && this.uniqueObj.length !== this.selected.length) {
          this.uniqueObj.forEach(e => {
            this.selected.push(e.user_id)
          })
          this.selected = [...new Set(this.selected)]
        } else {
          if (this.uniqueObj.length === this.selected.length) {
            this.selected = []
          }
        }
      }
    },

    selectAllMembers (n, o) {
      if (n !== o) {
        if (n && this.members.length !== this.selectedMembers.length) {
          this.members.forEach(e => {
            this.selectedMembers.push(e.user_id)
          })
          this.selectedMembers = [...new Set(this.selectedMembers)]
        } else {
          if (this.members.length === this.selectedMembers.length) {
            this.selectedMembers = []
          }
        }
      }
    },

    selected (n, o) {
      if (n !== o) {
        if (n.length === this.uniqueObj.length) {
          this.selectAllEntrants = true
        } else {
          this.selectAllEntrants = false
        }
      }
    },

    selectedMembers (n, o) {
      if (n !== o) {
        if (n.length === this.members.length) {
          console.dir(n)
          console.log('selectAllMembers true')
          this.selectAllMembers = true
        } else {
          console.log('selectAllMembers false')
          this.selectAllMembers = false
        }
      }
    }
  },

  methods: {
    init () {
      const user = UserService.getUser()
      this.userId = +user.id
      this.getUnique()
      this.getMembers()
    },

    getUnique () {
      this.uniqueObj = []
      this.contest.entrants.forEach(e => {
        if (this.uniqueObj.filter(el => { return el.user_id === e.user_id }).length === 0 && e.user_id !== this.userId) {
          this.uniqueObj.push(e)
        }
      })
    },

    getMembers () {
      this.members = []
      this.selectedMembers = []
      this.group.members.forEach(m => {
        if (!this.memberHasEntry(m.id)) {
          this.members.push(m)
        }
      })
    },

    memberHasEntry (fgmId) {
      let entered = false
      this.contest.entrants.some((e) => {
        if (+e.friendgroupmember_id === +fgmId) {
          entered = true
          return true
        } else {
          return false
        }
      })
      return entered
    },

    sendEmailClick () {
      const users = []
      if (this.selected && this.selected.length > 0) {
        this.selected.forEach((u) => {
          users.push(u)
        })
      }
      if (this.selectedMembers && this.selectedMembers.length > 0) {
        this.selectedMembers.forEach((u) => {
          users.push(u)
        })
      }
      var data = {
        message: this.message,
        selected: users,
        friendgroupid: this.contest.friendgroup_id,
        contest_id: this.contest.id
      }
      ContestService.sendContestGroupEmail(data).then(
        (response) => {
          if (response && response.data.result === 'Success') {
            this.message = ''
            this.selected = []
            this.selectedMembers = []
            this.$notify({
              group: 'notices',
              title: 'success',
              text: 'Successfully sent email',
              type: 'info'
            })
          } else {
            this.$notify({
              group: 'errors',
              title: 'Error',
              text: 'Failed to send email. Please try again later.',
              type: 'warn'
            })
          }
          this.loading = false
        }
      ).catch((err) => {
        console.dir(err)
        this.$notify({
          group: 'errors',
          title: err.response.data.errors[0],
          text: err.response.data.error,
          type: 'warn'
        })
      })
    },

    confirmEntry (member) {
      this.selectedEnterMember = member
      this.showEnterDialog = true
    },

    async enterMember () {
      if (this.loading || !this.selectedEnterMember) {
        return false
      }
      console.dir(this.selectedEnterMember)

      this.loading = true
      var data = {
        friendgroup_id: this.selectedEnterMember.friendgroup_id,
        contest_id: this.contest.id,
        friendgroupmember_id: this.selectedEnterMember.id,
        user_id: this.selectedEnterMember.user_id
      }
      ContestService.enterContest(data).then(
        (response) => {
          this.loading = false
          if (response.data.participant_id) {
            this.$notify({
              group: 'notices',
              title: 'Success',
              text: 'Successfully entered user into contest.',
              type: 'info'
            })
            this.showEnterDialog = false
            this.selectedEnterMember = null
          } else {
            this.$notify({
              group: 'notices',
              title: 'Error',
              text: 'Failed to enter contest. Please try again later.',
              type: 'danger'
            })
          }
        }
      ).catch(err => {
        this.$notify({
          group: 'notices',
          title: err.response.data.errors[0],
          text: err.response.data.error,
          type: 'danger'
        })
      }).finally(() => {
        this.loading = false
        this.$emit('refresh')
        setTimeout(() => {
          this.$nextTick(() => {
            this.selectedMembers = []
            this.init()
          })
        }, 1500)
      })
    },

    cancelEnter () {
      this.showEnterDialog = false
      this.selectedEnterMember = null
    }

  }
}
</script>
