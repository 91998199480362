<template>
  <div>

    <!-- new group dialog - Move into compoment -->
    <v-dialog
      v-model="groupmodal"
      width="500"
    >
      <v-card
        class="px-3 pt-3"
        :loading="formloading"
      >
        <v-card-text>
          <v-text-field
            label="Group Name"
            name="group-name"
            id="group-name"
            type="text"
            v-model="groupform.name"
            counter="18"
          ></v-text-field>

          <v-row>
            <v-col cols="10">
              <v-file-input
                label="Group Avatar"
                name="group-avatar"
                id="group-avatar"
                v-on:change="setFileUpload"
                v-model="editgroupform.avatar"
                ref="upload"
              ></v-file-input>
            </v-col>
            <v-col cols="1">
              <v-btn
                icon
                @click="saveGroupAvatar()"
              >
              <v-icon class="pt-8 pr-8">save</v-icon>
              </v-btn>
          </v-col>
        </v-row>

          <v-row>
            <v-col>Allow members to invite others?</v-col>
            <v-spacer></v-spacer>
            <v-col class="py-0">
              <v-checkbox
                class="py-0"
                v-model="groupform.allowmemberinvite"
                label="Allow Invitations"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-autocomplete
            label="Search Users"
            :items="searchresults"
            :search-input.sync="autocomplete"
            item-text="nickname"
            return-object
            :loading="loading"
            :filter="() => true"
            :hide-no-data="!loading"
            @input="adduser"
          ></v-autocomplete>
          <v-row
            v-if="memberslist.length > 0"
            class="my-0 py-0"
          >
            <v-col
              cols="12"
              class="black--text"
            >
              <v-row class="my-0 py-0 px-3"> Users </v-row>
              <v-row
                v-for="user in memberslist"
                :key="user.id"
                justify="space-between"
                align="center"
                class="my-0 py-0"
              >
                <v-col
                  cols="6"
                  class="text-left"
                >{{ user.nickname }}</v-col>
                <v-col cols="2">
                  <v-btn
                    icon
                    text
                    @click="removeinvite(user)"
                  >
                    <v-icon>person_remove</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-row>
          <v-col cols="12">
            <v-card-actions>
              <v-row justify="space-between">
                <v-btn
                  color="primary"
                  text
                  @click="groupmodal = false"
                  :disabled="formloading"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  type="submit"
                  @click.prevent="makegroupform"
                  :disabled="formloading"
                >
                  Submit
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- end new group dialog -->

    <!-- invitation modal -- move to new component -->
    <v-dialog
      v-model="invitemodal"
      v-if="selectedinvite != null"
      width="500"
    >
      <v-card>
        <v-card-text>
          <v-row
            align="center"
            class="my-0 py-0"
          >
            <v-col class="black--text pb-0">You have been invited by {{ selectedinvite.ownername }} ({{
                selectedinvite.owner
              }}) to join <b>{{ selectedinvite.name }}</b>. Would you like to join?
            </v-col>
          </v-row>
        </v-card-text>
        <v-row class="my-0 py-0">
          <v-col cols="12">
            <v-card-actions>
              <v-row
                justify="end"
                class="px-4"
              >
                <v-btn
                  class="black white--text mx-1"
                  @click="invited('cancel')"
                >Cancel</v-btn>
                <v-btn
                  class="background-red white--text mx-1"
                  @click="invited('decline')"
                >Decline</v-btn>
                <v-btn
                  class="primary mx-1"
                  @click="invited('accept')"
                >Accept</v-btn>
              </v-row>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- end invitation modal -->

    <!-- edit group -->
    <v-dialog
      v-model="editgroupmodal"
      v-if="selectedgroup != null"
      width="800"
    >
      <v-card
        class="px-0 pb-0"
        :loading="formloading"
      >
        <v-row
          class="my-0 py-0"
          justify="end"
        >
          <v-col
            cols="2"
            class="py-0 text-right"
          >
            <v-btn
              icon
              @click.prevent="cancelupdate()"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text class="black--text">
          <v-text-field
            label="Group Name"
            name="group-name"
            id="group-name"
            v-model="editgroupform.name"
          ></v-text-field>

        <v-row>
          <v-col cols="10">
            <v-file-input
              label="Group Avatar"
              name="group-avatar"
              id="group-avatar"
              v-on:change="setFileUpload"
              v-model="editgroupform.avatar"
              ref="upload"
            ></v-file-input>
          </v-col>
            <v-col cols="1">
              <v-btn
                icon
                @click="saveGroupAvatar()"
              >
              <v-icon class="pt-8 pr-8">save</v-icon>
              </v-btn>
          </v-col>
        </v-row>

          <v-checkbox
            class="py-0"
            v-model="editgroupform.allowmemberinvite"
            label="Allow invitations by members?"
          ></v-checkbox>

          <v-autocomplete
            label="Search Users"
            :items="searchresults"
            :search-input.sync="autocomplete"
            item-text="nickname"
            return-object
            :loading="loading"
            :filter="() => true"
            :hide-no-data="!loading"
            @input="inviteuser"
          ></v-autocomplete>
          <v-row class="py-0 my-0">
            <v-col cols="5" sm="3" class="p-0 pr-0">
              Members
            </v-col>
            <v-col class="checkboxHeaders" cols="7">
              <p class="takeOffer">Take <br/>Offer </p>
              <p class="makeOffer">Make <br/>Offer </p>
              <p>Invite </p>
            </v-col>
          </v-row>
          <v-row
            v-for="user in groupmembers"
            :key="user.user_id"
            align="center"
            class="my-0 py-0"
            justify="space-evenly"
          >
            <v-col
              cols="5"
              class="my-0 py-0 pr-0"
            >
              {{ user.nickname }}
              <span v-if="!user.isadmin">
                <v-btn
                  text
                  icon
                  @click="removeuser(user)"
                >
                  <v-icon small>person_remove</v-icon>
                </v-btn>
              </span>
            </v-col>
            <!-- <v-col cols="2" class=" my-0 py-0 pr-0" v-if="$vuetify.breakpoint.mdAndUp">
              {{ user.nickname }}
                <span v-if="!user.isadmin">
                <v-btn text icon @click="removeuser(user)" >
                  <v-icon small>person_remove</v-icon>
                </v-btn>
              </span>
              </v-col> -->
            <v-col
              cols="2"
              class="py-0 pr-10"
            >
              <v-checkbox
                class="py-0"
                v-model="user.cantake"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="2"
              class="my-0 py-0 pl-4"
              align="center"
            >
              <v-checkbox
                class="py-0"
                v-model="user.canoffer"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="2"
              class="my-0 py-0 pl-5"
              align="center"
            >
              <v-checkbox
                class="py-0"
                v-model="user.caninvite"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12">
            <v-card-actions>
              <v-row
                justify="space-between"
                align="center"
                class="py-0 my-0 px-2"
              >
                <v-col
                  cols="6"
                  class="text-left"
                >
                  <v-btn
                    class="background-red"
                    @click.prevent="opendelete()"
                    :disabled="formloading"
                  >
                    delete group
                  </v-btn>
                </v-col>
                <v-col
                  cols="6"
                  class="text-right"
                >
                  <v-btn
                    class="primary"
                    type="submit"
                    @click.prevent="updategroup"
                    :disabled="formloading"
                  >
                    save
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- end edit group -->

    <!-- delete group - move to component -->
    <v-dialog
      v-model="groupdelete"
      width="500"
    >
      <v-card>
        <v-card-title>Delete Group?</v-card-title>
        <v-card-text>
          <p class="black--text">
            Are you sure you want to delete this group? (this cannot be undone)
          </p>
        </v-card-text>
        <v-row class="my-0 py-0">
          <v-col cols="12">
            <v-card-actions>
              <v-row
                justify="end"
                class="my-0 py-0 px-2"
              >
                <v-btn
                  text
                  @click="canceldelete()"
                >Cancel</v-btn>
                <v-btn
                  text
                  @click="deletegroup()"
                >Delete</v-btn>
              </v-row>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- delete group -->

    <div>
      <div>
        <button
          class="vigzero-btn blue-btn robo text-center"
          style="width: 100%"
          @click="groupmodal = true"
        >
          <span style="display: block; margin: 0 auto">Create Group</span>
        </button>
      </div>
    </div>

    <!-- my pending group invitations -->
    <v-row
      class="my-0 py-0"
      v-if="invitelist.length > 0"
    >
      <v-col cols="12">
        <v-card-title>Group Invites</v-card-title>
        <v-card-text>
          <invitation v-for="invite in invitelist" :key="invite.id" :invite="invite" @update="invitedResponse()"></invitation>
        </v-card-text>
      </v-col>
    </v-row>

    <!-- my group listing -->
    <v-row class="px-0 py-0">
      <v-col cols="12">
        <v-card class="px-0 ma-0">
          <v-row justify="center">
            <v-card-title class="font-weight-bold px-0 ma-0">My groups</v-card-title>
          </v-row>
          <v-card-text>
            <v-row
              v-for="group in grouplist"
              :key="group.id"
              justify="space-between"
              class="my-0 py-0"
            >
              <v-col
                cols="12"
                class="py-0"
              >
                <v-row
                  justify="space-between"
                  align="center"
                  @click="clickexpander(group.id)"
                  class="vigzero-btn dashboard-btn red-btn ma-0 pa-0"
                >
                  <v-col :style="namesize(group.name)">{{ group.name }}</v-col>
                  <v-col>
                    <v-btn
                      icon
                      v-if="group.isadmin == 1"
                      @click="editgroup(group)"
                      class="pull-right"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="py-0 my-1">
                  <v-col
                    cols="12"
                    class="py-0"
                  >
                    <v-expand-transition>
                      <v-card
                        block
                        v-show="expanded == group.id"
                        class="pt-3 mt-n4"
                      >
                        <v-card-text class="mx-1 px-1 py-0">
                          <v-row>
                            <v-col
                              cols="12"
                              class="pa-0"
                            >
                              <v-row
                                justify="space-between"
                                class="mx-0 mt-2 mb-0"
                              >
                                <v-col
                                  cols="4"
                                  class="pa-0"
                                ></v-col>
                                <v-col
                                  cols="4"
                                  class="pa-0 text-center"
                                >W/L/T</v-col>
                                <v-col
                                  cols="4"
                                  class="pa-0 text-center"
                                >W %</v-col>
                              </v-row>
                              <v-row
                                justify="space-between"
                                class="ma-0"
                                v-for="member in group.members"
                                :key="member.id"
                              >
                                <v-col
                                  cols="4"
                                  class="py-1"
                                ><span v-if="member.accepted != null">{{
                                    member.nickname
                                  }}</span><span v-else>{{
                                    member.nickname
                                  }}</span></v-col>
                                <v-col
                                  cols="4"
                                  class="py-1 text-center"
                                >
                                  <div v-if="member.accepted != null">
                                    <span>{{ member.wins }} - {{ member.losses }} -
                                      {{ member.ties }}</span>
                                  </div>
                                  <div v-else>
                                    <v-icon class="icon">schedule</v-icon>
                                  </div>
                                </v-col>
                                <v-col
                                  cols="4"
                                  class="py-0 text-center"
                                >
                                  <div v-if="member.accepted != null">
                                    <span>{{ member.winperc }}</span>
                                  </div>
                                  <div v-else>
                                    <v-btn
                                      text
                                      @click="resendInvite(member.id)"
                                      :disabled="disabledResend(member.id)"
                                      dense
                                      v-if="group.isadmin == 1"
                                    >Resend</v-btn>
                                  </div>
                                </v-col>
                              </v-row>

                              <GroupInvite v-if="group && group.members && group.isowner > 0" :group="group" :members="group.members" @refresh="init"></GroupInvite>

                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-row
                          class="my-0 px-4"
                          v-if="group.isadmin != 1"
                        >
                          <v-col
                            cols="12"
                            class="py-0"
                          >
                            <v-card-actions>
                              <v-row justify="end">
                                <v-btn
                                  class="background-red white--text"
                                  @click.prevent="leavegroupmodal(group)"
                                  v-if="group.isadmin != 1"
                                >Leave Group</v-btn>
                              </v-row>
                            </v-card-actions>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-expand-transition>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- leave group dialog - move to component -->
    <v-dialog
      v-model="groupLeave.show"
      width="400"
    >
      <v-card class="px-4">
        <v-card-title>Leave Group?</v-card-title>
        <v-card-text>
          <v-row class="black--text my-0 py-0">
            <p>
              Are you sure you want to leave the group
              <b>{{ groupLeave.name }}</b>?
            </p>
          </v-row>
        </v-card-text>
        <v-row
          class="my-0 py-0"
          justify="end"
        >
          <v-card-actions>
            <v-btn
              class="px-1 mx-0"
              text
              @click="cancelLeaveGroup()"
            >Cancel</v-btn>
            <v-btn
              class="px-1 mx-0"
              text
              @click="leavegroup()"
            >Leave Group</v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
  .checkboxHeaders {
    padding-left: 135px;
    display: inline-flex;
  }

  .takeOffer {
    padding-right: 105px;
  }

  .makeOffer {
    padding-right: 95px;
  }

  @media only screen and (max-width: 600px) {
    .checkboxHeaders {
    padding-left: 10px;
  }

  .takeOffer {
    padding-right: 20px;
  }

  .makeOffer {
    padding-right: 20px;
  }
  }
</style>

<script>
import GroupInvite from '@/components/groups/Invite'
import CommunityService from '@/services/CommunityService'
import { validationMixin } from 'vuelidate'
import { minLength, required, maxLength } from 'vuelidate/lib/validators'
import UserService from '@/services/UserService'
import Invitation from '@/components/groups/Invitation'

export default {
  mixins: [validationMixin],
  components: {
    GroupInvite,
    Invitation
  },
  data: function () {
    return {
      expanded: -1,
      selected: '',
      groupform: {
        name: '',
        allowmemberinvite: 1
      },
      editgroupform: {
        name: null,
        allowmemberinvite: 1,
        userlist: []
      },
      file: {
        upload: '',
        file: null
      },
      groupdelete: false,
      groupmodal: false,
      editgroupmodal: false,
      selectedgroup: null,
      showAvatar: false,
      invitemodal: false,
      selectedinvite: null,
      grouplist: [],
      invitelist: [],
      searchresults: [],
      memberslist: [],
      autocomplete: '',
      memberSearchTimeout: null,
      loading: false,
      formloading: false,
      disabledbuttons: [],
      groupExpanders: [],
      groupLeave: {
        show: false,
        id: -1,
        name: ''
      }
    }
  },
  created: function () {
    this.init()
  },
  watch: {
    autocomplete (searchTerm) {
      if (searchTerm && searchTerm.length < 3) {
        this.searchresults = []
        return
      }
      // if search term is a formatted phone number remove all non digits
      // Stack Overflow link
      // https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
      if (/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(searchTerm)) {
        searchTerm = searchTerm.replace(/\D+/g, '')
      }
      clearTimeout(this.memberSearchTimeout)
      this.memberSearchTimeout = setTimeout(() => {
        this.findMembers(searchTerm)
      }, 300)
    }
  },
  methods: {
    init () {
      this.getgroups()
      this.getinvites()
    },
    getgroups: function () {
      CommunityService.getgroups().then(
        (response) => {
          var groups = response.data
          this.grouplist = []
          if (groups !== null && groups.length > 0) {
            groups.forEach(element => {
              var members = []
              CommunityService.getmembers(element.id).then(
                (response) => {
                  response.data.forEach(element => {
                    element.admin = element.isadmin
                    element.delete = false
                    element.cantake = element.cantake === 1
                    element.canoffer = element.canoffer === 1
                    element.caninvite = element.caninvite === 1
                    element.isadmin = element.isadmin === 1
                    element.wins = element.wins ? element.wins : 0
                    element.losses = element.losses ? element.losses : 0
                    element.ties = element.ties ? element.ties : 0
                    element.winperc = (isNaN(element.winperc) || element.winperc == null) ? '-' : element.winperc + '%'
                    members.push(element)
                  })
                }
              )
              element.members = members
              this.$set(this.grouplist, this.grouplist.length, element)
            })
          }
        }
      )
    },
    clickexpander: function (id) {
      if (this.expanded === id) {
        this.expanded = -1
      } else {
        this.expanded = id
      }
    },
    getinvites: function () {
      CommunityService.getinvites().then(
        (response) => {
          var invites = response.data
          this.invitelist = []
          if (invites !== null && invites.length > 0) {
            invites.forEach(element => {
              this.$set(this.invitelist, this.invitelist.length, element)
            })
          }
        }
      )
    },

    makegroupform: function () {
      this.editgroupform.name = '...'
      this.$v.$touch()

      var clearError = true
      if (!this.$v.$invalid) {
        let groupid = 0
        this.formloading = true
        CommunityService.makegroup(this.groupform.name).then(
          (response) => {
            groupid = response.data.groupid
            CommunityService.inviteusers(this.memberslist, groupid).then(
              (response) => {
                this.groupmodal = false
                this.$v.$reset()
                this.groupform.name = null
                this.memberslist = []
              },
              (err) => {
                clearError = false
                console.log(err)
              }
            )

            this.saveGroupAvatar(groupid)

            this.getgroups()
            this.getinvites()
            // on successful group creation
            this.$gtag.event('create', {
              event_category: 'group',
              event_label: 'created',
              value: this.groupform.name
            })
          },
          (err) => {
            var e = err.response.data
            if (e.error) {
              this.$notify({
                group: 'errors',
                title: e.errors[0],
                text: e.error,
                type: 'danger'
              })
            }
            clearError = false
            console.log(err)
          }
        ).finally(() => {
          if (clearError) {
            this.$notify({
              group: 'errors',
              clear: true
            })
          }
          this.formloading = false
        })
      }
      this.editgroupform.name = ''
    },

    updategroup: function () {
      this.$updateloading(1)
      this.$v.$touch()
      this.groupform.name = '...'
      if (!this.$v.$invalid) {
        var clearError = true
        this.editgroupform.id = this.selectedgroup.id
        this.formloading = true
        CommunityService.updategroup(this.editgroupform).then(
          (response) => {
            this.$v.$reset()
            this.editgroupform = {
              name: '',
              userlist: [],
              id: 0
            }
            this.saveGroupAvatar()
            this.getgroups()
            this.getinvites()
            this.selectedgroup = null
            this.editgroupmodal = false
          },
          (err) => {
            clearError = false
            var e = err.response.data
            if (e.error) {
              this.$notify({
                group: 'errors',
                title: e.errors[0],
                text: e.error,
                type: 'danger'
              })
            }
            console.log(err)
          }
        ).finally(() => {
          this.$updateloading(-1)
          if (clearError) {
            this.$notify({
              group: 'errors',
              clear: true
            })
          }
          this.formloading = false
        })
      }
      this.groupform.name = ''
    },
    inviteuser: function (user) {
      this.autocomplete = ''
      let adduser = true
      this.editgroupform.userlist.forEach(element => {
        if (user.id === element.user_id) {
          if (element.delete) {
            element.delete = false
          }
          adduser = false
        }
      })

      if (adduser) {
        var member = {
          id: 0,
          user_id: user.id,
          canoffer: true,
          cantake: true,
          caninvite: true,
          isadmin: false,
          admin: 0,
          nickname: user.nickname,
          delete: false
        }
        this.$set(this.editgroupform.userlist, this.editgroupform.userlist.length, member)
      }
    },
    resendInvite: function (memberid) {
      this.$updateloading(1)
      CommunityService.resendInvite(memberid).then(
        (response) => {
          this.disabledbuttons.push(memberid)
          this.$notify({
            group: 'notices',
            title: 'Re-sent Invite',
            text: 'Successfully re-sent invite',
            type: 'info'
          })
        }
      ).catch(
        error => {
          console.log(error)
        }
      ).finally(() => { this.$updateloading(-1) })
    },
    disabledResend: function (id) {
      return this.disabledbuttons.filter(el => { return el === id }).length > 0
    },
    removeuser: function (member) {
      if (member.id === 0) {
        var list = this.editgroupform.userlist.filter((element) => { return element.user_id !== member.user_id })
        this.editgroupform.userlist = []
        list.forEach(element => {
          this.$set(this.editgroupform.userlist, this.editgroupform.userlist.length, element)
        })
      } else {
        member.delete = true
      }
    },

    setFileUpload: function () {
      console.log(this)
      this.file.file = this.$refs.upload.$refs.input.files[0]
    },

    saveGroupAvatar: function (groupid) {
      this.$updateloading(1)
      const formData = new FormData()
      formData.append('upload', this.file.upload)
      formData.append('file', this.file.file)
      if (groupid) {
        formData.append('group_id', groupid)
      } else {
        formData.append('group_id', this.selectedgroup.id)
      }
      // formData.append('group_id', groupid ? groupid : this.selectedgroup.id)
      CommunityService.uploadGroupAvatar(formData).then(
        (response) => {
          console.log(response)
          if (response.data.success) {
          }
          this.$updateloading(-1)
        },
        (error) => {
          console.log(error)
          this.fatalerror = error.response.data.error
          this.$updateloading(-1)
        })
    },

    editgroup: function (group) {
      this.editgroupform = {
        name: group.name,
        allowmemberinvite: group.allowmemberinvite,
        userlist: []
      }
      this.selectedgroup = group
      CommunityService.getmembers(group.id).then(
        (response) => {
          this.editgroupform.userlist = []
          response.data.forEach(element => {
            element.admin = element.isadmin
            element.delete = false
            element.cantake = element.cantake === 1
            element.canoffer = element.canoffer === 1
            element.caninvite = element.caninvite === 1
            element.isadmin = element.isadmin === 1
            element.wins = element.wins ? element.wins : 0
            element.losses = element.losses ? element.losses : 0
            element.ties = element.ties ? element.ties : 0
            element.winperc = element.wins / (element.wins + element.losses + element.ties)
            this.$set(this.editgroupform.userlist, this.editgroupform.userlist.length, element)
          })
        }
      )
      this.editgroupmodal = true
    },
    cancelupdate: function () {
      this.editgroupform = {
        name: '',
        userlist: []
      }
      this.editgroupmodal = false
      this.selectedgroup = null
    },
    opendelete: function () {
      this.editgroupmodal = false
      this.groupdelete = true
    },
    canceldelete: function () {
      this.groupdelete = false
      this.editgroupmodal = true
    },
    deletegroup: function () {
      CommunityService.deletegroup(this.selectedgroup.id).then(
        (response) => {
          this.groupdelete = false
          this.editgroupmodal = false
          this.selectedgroup = null
          this.$notify({
            group: 'notices',
            title: 'Group Deleted',
            text: 'You have successfully deleted the group',
            type: 'info'
          })
          this.getgroups()
          this.getinvites()
        },
        (err) => {
          this.$notify({
            group: 'errors',
            title: 'Error',
            text: err,
            type: 'warn'
          })
          this.getgroups()
          this.getinvites()
        }
      )
    },
    leavegroupmodal: function (group) {
      this.groupLeave.name = group.name
      this.groupLeave.id = group.id
      this.groupLeave.show = true
    },
    leavegroup: function () {
      this.$updateloading(1)
      CommunityService.leavegroup(this.groupLeave.id).then(
        (response) => {
          this.getgroups()
          this.getinvites()
          this.$notify({
            group: 'notices',
            title: 'Left Group',
            text: 'You are no longer apart of this group',
            type: 'info'
          })
        },
        (err) => {
          console.log(err)
        }
      ).finally(() => {
        this.cancelLeaveGroup()
        this.$updateloading(-1)
      })
    },
    cancelLeaveGroup: function () {
      this.groupLeave = {
        name: '',
        id: -1,
        show: false
      }
    },
    selectinvite: function (invite) {
      console.log('running')
      this.selectedinvite = invite
      this.invitemodal = true
    },
    invitedResponse: function (action) {
      this.getinvites()
      this.getgroups()
    },
    findMembers: function (searchTerm) {
      if (searchTerm) {
        this.loading = true
        CommunityService.searchUsers(searchTerm.toLowerCase()).then(
          (response) => {
            this.searchresults = response.data
          },
          (err) => {
            console.log(err)
          }
        ).finally(() => {
          this.loading = false
        })
      }
    },
    adduser: function (user) {
      console.log(user)
      this.autocomplete = ''
      let adduser = true
      this.memberslist.forEach(element => {
        if (user.id === element.id) {
          adduser = false
        }
      })
      if (adduser) {
        this.$set(this.memberslist, this.memberslist.length, user)
        this.searchresults = []
      }
    },
    removeinvite: function (user) {
      var newList = this.memberslist.filter(({ id }) => { return id !== user.id })
      this.memberslist = []
      newList.forEach(element => {
        this.$set(this.memberslist, this.memberslist.length, element)
      })
    },
    isSelf: function (id) {
      return +id === +UserService.getUserObject().info.id
    },
    expandGroup: function (group) {
      if (this.isexpanded(group)) {
        var temp = this.groupExpanders.filter(el => { return el !== group.id })
        this.groupExpanders = []
        this.groupExpanders = temp
      } else {
        this.groupExpanders.push(group.id)
      }
    },
    isexpanded: function (group) {
      return this.groupExpanders.filter(el => { return el === group.id }).length > 0
    },
    userSearchFilter: function (item, queryText, itemText) {
      return true
    },
    namesize: function (name) {
      name += ''
      return {
        'font-size': name.length >= 10 ? '14px' : 'inherit'
      }
    }
  },
  computed: {
    groupmembers: function () {
      return this.editgroupform.userlist.filter((element) => !element.delete)
    }
  },
  validations: {
    groupform: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(18)
      }
    },
    editgroupform: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      }
    }
  }
}
</script>

<style scoped>
.scroll-content {
  height: 100%;
  overflow: auto;
}
.blue-btn {
  margin-top: 0;
}
.white {
  color: white !important;
}
.group-name {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.decline {
  background-color: rgb(180, 0, 0) !important;
  color: rgb(255, 255, 255) !important;
}
.edit {
  color: rgb(122, 122, 122);
}
.button-border {
  border-radius: 0;
  margin: 0 0 1em 0;
}
.userlist {
  max-height: 55vh;
  min-width: 90%;
  overflow-x: hidden;
  overflow-y: auto;
}
.deletebutton {
  background-color: rgb(195, 0, 0) !important;
  color: white !important;
}
</style>
