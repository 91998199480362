var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "container" },
    [
      _c("WagerInfo", { attrs: { wager: _vm.wager } }),
      _c(
        "v-row",
        { staticClass: "my-0 py-0" },
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "vig-card" },
                [
                  _c("v-card-title", [
                    _c("div", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm.wager.dispute_id
                            ? "Already Disputed"
                            : "Create Dispute"
                        )
                      )
                    ])
                  ]),
                  _vm.wager.dispute_id
                    ? _c("v-card-text", [
                        _c("p", { staticClass: "text-center" }, [
                          _vm._v("This wager has already been disputed.")
                        ])
                      ])
                    : _vm._e(),
                  !_vm.wager.dispute_id
                    ? _c(
                        "v-card-text",
                        [
                          _c(
                            "div",
                            { class: _vm.getValidationClass("disputetype_id") },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.filteredDisputeTypes,
                                  label: "Dispute Type",
                                  "item-text": "name",
                                  "item-value": "id",
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.disputetype_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "disputetype_id", $$v)
                                  },
                                  expression: "form.disputetype_id"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                attrs: { label: "Comments", solo: "" },
                                model: {
                                  value: _vm.form.description,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "description",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.description"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.form.disputetype_id == 20
                            ? _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "div",
                                    { class: _vm.getValidationClass("upload") },
                                    [
                                      _c("v-file-input", {
                                        ref: "upload",
                                        attrs: {
                                          label: "Proof of Payment",
                                          accept: "image/*",
                                          required:
                                            _vm.form.form.disputetype_id == 20
                                        },
                                        on: { change: _vm.setFileUpload },
                                        model: {
                                          value: _vm.form.upload,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "upload", $$v)
                                          },
                                          expression: "form.upload"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-card-actions",
                    [
                      !_vm.wager.dispute_id
                        ? _c(
                            "v-btn",
                            {
                              attrs: { disabled: _vm.$v.$invalid },
                              on: { click: _vm.create }
                            },
                            [_vm._v("Create")]
                          )
                        : _vm._e(),
                      _vm.wager.dispute_id
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                to: {
                                  name: "disputereview",
                                  params: { dispute_id: _vm.wager.dispute_id }
                                }
                              }
                            },
                            [_vm._v("Review Dispute")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }