var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("BackButton"),
      _c(
        "v-row",
        { staticClass: "my-0 py-0" },
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "vig-card" },
                [
                  _c(
                    "v-card-title",
                    [_c("GameSummary", { attrs: { game: _vm.game } })],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "vig-card-expand" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-0 py-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("GameDetail", {
                                attrs: { game: _vm.game, detail: true }
                              })
                            ],
                            1
                          ),
                          _vm.offers && _vm.offers.length
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "py-0 vig-card-expand",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "table",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c("offer-list", {
                                        attrs: { offers: _vm.offers },
                                        on: {
                                          update: function($event) {
                                            return _vm.update()
                                          },
                                          acceptOffer: _vm.accepted
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.game.id && !_vm.cancelled
                    ? _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "router-link",
                            {
                              staticClass: "text-right",
                              attrs: {
                                to: {
                                  name: "makeoffergame",
                                  params: { game_id: _vm.game.id }
                                },
                                tag: "v-btn"
                              }
                            },
                            [_vm._v("Make Offer")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }