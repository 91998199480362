var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "320" },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(" Entry Fee Paid? ")
          ]),
          _c("v-card-text", [
            _vm.payment.paid
              ? _c("div", [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.payment.nickname) +
                        "'s entry fee has been paid."
                    )
                  ]),
                  _c("p", [
                    _vm._v("Do you want to mark this entry fee as NOT paid?")
                  ])
                ])
              : _c("div", [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.payment.nickname) +
                        "'s entry fee has NOT been paid."
                    )
                  ]),
                  _c("p", [
                    _vm._v("Do you want to mark this entry fee as paid?")
                  ])
                ])
          ]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: _vm.payment.paid ? "error" : "success",
                    raised: "",
                    disabled: _vm.loading
                  },
                  on: { click: _vm.togglePayment }
                },
                [
                  _vm.payment.paid
                    ? _c("span", [_vm._v("Mark Unpaid")])
                    : _c("span", [_vm._v("Mark Paid")])
                ]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { raised: "", disabled: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }