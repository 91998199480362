import axios from 'axios'
import Vue from 'vue'
import Notifications from 'vue-notification'
import UserService from './UserService'

Vue.use(Notifications)

const ContestService = {

  async getContestType (contesttypeId) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'contest/gettype/' + contesttypeId,
      method: 'get'
    })
  },

  async saveContest (data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'contest/save',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async enterContest (data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'contest/enter',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async getContest (contestId, enter) {
    await UserService.authHeader()
    var url = UserService.baseUrl() + 'contest/get/' + contestId + '/'
    if (enter) {
      url += '1'
    }
    return axios({
      url: url,
      method: 'get'
    })
  },

  async sendContestGroupEmail (data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'contest/mail',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async toggleEntryPayment (entry) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'contest/entryfee',
      data: JSON.stringify(entry),
      method: 'post'
    })
  },

  async removeEntry (entry) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'contest/remove',
      data: JSON.stringify(entry),
      method: 'post'
    })
  }

}

export default ContestService
