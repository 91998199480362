<template>
  <div v-if="filteredPayouts && filteredPayouts.length > 0">

    <PaymentReceivedDialog
      :wager="confirmPayout"
      @cancel="confirmPayout = null"
      @paid="confirmPayout = null; $emit('updatedata')"
      @dispute="confirmPayout = null; $emit('updatedata')"
      @deny="confirmPayout = null; $emit('updatedata')"
    ></PaymentReceivedDialog>

    <PaymentReceivedDisputeDialog
      :wager="disputePayout"
      @cancel="disputePayout = null"
      @paid="disputePayout = null"
      @dispute="disputePayout = null; $emit('updatedata')"
    ></PaymentReceivedDisputeDialog>

    <v-card class="card-shadow card winner-shadow">
      <v-card-title>
        <div class="winner mx-auto">Confirm Payment</div>
      </v-card-title>
      <v-card-text class="black--text">
        <div
          v-for="payout in filteredPayouts"
          :key="payout.id"
          class="row button-border"
          :class="{ 'disable': payout.paymentsent == null || isDispute(payout) }"
        >
        <PayoutConfirmation :payout="payout" @confirm="$emit('updatedata')" @deny="confirmPayout = payout"></PayoutConfirmation>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PaymentReceivedDialog from '@/components/dashboard/actions/PaymentReceivedDialog'
import PaymentReceivedDisputeDialog from '@/components/dashboard/actions/PaymentReceivedDisputeDialog'
import PayoutConfirmation from '@/components/dashboard/PayoutConfirmation'
import DashboardService from '@/services/DashboardService'

export default {
  components: {
    PaymentReceivedDialog,
    PaymentReceivedDisputeDialog,
    PayoutConfirmation
  },
  data: function () {
    return {
      confirmPayout: null,
      disputePayout: null,

      selectedPayout: null,
      times: [],
      // betdetails: false,
      betDispute: false,
      filteredPayouts: null
    }
  },
  props: {
    payouts: Array
  },
  created: function () {
    this.filteredPayouts = this.payouts.filter(this.payoutsFilter)
    for (let i = 0; i < this.filteredPayouts.length; i++) {
      // timetosettle is calculated in minutes. convert that into hours and minutes seperately
      this.filteredPayouts[i].timetosettle = this.filteredPayouts[i].timetosettle / 60
    }
    this.interval = setInterval(this.timer, 60000)
  },
  destroyed: function () {
    clearInterval(this.interval)
  },
  methods: {
    async confirmPayment (payout) {
      this.$updateloading(1)
      await DashboardService.confirmPayment(payout.id).then(
        (response) => {
          this.$updateloading(-1)
          this.$emit('updatedata')
        },
        (error) => {
          this.$updateloading(-1)
          alert('An error has occured, please try again later. If the error continues to happen please contact us\n' + error.response.data.error)
        }
      )
    },

    denyPayment (payout) {
      if (payout.dispute_id !== null && payout.opendispute === 1) {
        this.$router.push('/disputes/' + payout.dispute_id)
      } else if (payout.paymentsent == null) {
        this.disputePayout = payout
      } else {
        this.confirmPayout = payout
      }
    },

    openConfirmation: function (payout) {
      if (payout.dispute_id !== null && payout.opendispute === 1) {
        this.$router.push('/disputes/' + payout.dispute_id)
      } else if (payout.paymentsent == null) {
        this.disputePayout = payout
      } else {
        this.confirmPayout = payout
      }
    },
    cancel: function () {
      // this.betdetails = false
      this.betDispute = false
      this.selectedPayout = null
    },
    getModalContent: function () {
      return 'Confirm here you got paid by ' + this.selectedPayout.nickname
    },
    timer: function () {
      this.filteredPayouts.forEach(el => {
        var time = el.timetosettle
        time *= 60
        time += 1
        time = time / 60

        el.timetosettle = time
      })
    },
    getGameInfo: function (payout) {
      var homeline = (payout.lineamount > 0 ? ' (+' + payout.lineamount : ' (' + payout.lineamount) + ')'
      var awayline = ((payout.lineamount * -1) > 0 ? '(+' + (payout.lineamount * -1) : '(' + (payout.lineamount * -1)) + ') '

      if (payout.offeredby_id === payout.winner_id) {
        if (payout.hometeam_id === payout.offeredbyteam_id) {
          if (payout.bettype_id === 20) {
            return 'Over ' + payout.lineamount
          } else {
            return payout.homeabbr + ' ' + homeline
          }
        } else {
          if (payout.bettype_id === 20) {
            return 'Under ' + payout.lineamount
          } else {
            return payout.awayabbr + ' ' + awayline
          }
        }
      } else {
        if (payout.hometeam_id === payout.takenbyteam_id) {
          if (payout.bettype_id === 20) {
            return 'Over ' + payout.lineamount
          } else {
            return payout.homeabbr + ' ' + homeline
          }
        } else {
          if (payout.bettype_id === 20) {
            return 'Under ' + payout.lineamount
          } else {
            return payout.awayabbr + ' ' + awayline
          }
        }
      }
    },
    fullgame: function (payout) {
      // var homeline = payout.bettype_id === 20 ? '' : (payout.lineamount > 0 ? ' (+' + payout.lineamount : ' (' + payout.lineamount) + ')'
      // var awayline = payout.bettype_id === 20 ? '' : ((payout.lineamount * -1) > 0 ? '(+' + (payout.lineamount * -1) : '(' + (payout.lineamount * -1)) + ') '

      return payout.awayteamscore + ' ' + payout.away + ' vs. ' + payout.home + ' ' + payout.hometeamscore
    },
    isDispute: function (wager) {
      return wager.opendispute === 1
    },
    payoutsFilter: function (payout) {
      return payout.paymentsent != null
      // return payout.candispute || payout.paymentsent != null
    }
  },
  filters: {
    timeFormat: function (val) {
      if (val < 10) return '0' + val
      else return val
    }
  }
}
</script>

<style scoped>
.content-margin {
  margin: 1.2em;
}
.timer {
  border: 0.2em solid rgb(0, 170, 0);
}
.disable {
  opacity: 0.6;
  filter: alpha(opacity = 60);
  color: grey;
}
.nottransparent {
  opacity: 1;
  filter: alpha(opacity = 100);
  color: black;
  width: 100%;
  text-align: center;
  font-weight: bold;
}
</style>
