<template>
  <v-card
    class="mb-4 tournament-matchup"
    v-if="game && game.id"
    elevation="2"
  >
    <v-card-title
      class="datetime vig-card-header-mini font-weight-regular normal-line-height pa-0 px-1"
      :class="(isUserGame) ? 'mygame' : ''"
    >
      <v-row
        class="ma-0"
        no-gutters
      >
        <v-col
          class="pa-0"
          align="left"
        >
          <span class="sub-text">{{ divisionName }} {{ round }}</span>
        </v-col>
        <v-col
          class="pa-0"
          align="right"
        >
          <span
            class="sub-text"
            v-if="game.game"
          >
            <span v-if="game.game.gamestatus === 'Upcoming'">{{ game.game.gamedate_formatted }} {{ game.game.starttime_formatted }}</span>
            <span v-else>
              <span v-if="game.game.gamestatus === 'In Progress'">
                {{ displayGameClock(game.game) }}
              </span>
              <span v-else>Final</span>
            </span>
          </span>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text class="pa-0">
      <TournamentTeam
        :game="game"
        :tournament="tournament"
        :user_id="user_id"
        :team="game.favorite"
        :group_id="group_id"
      ></TournamentTeam>

      <TournamentTeam
        :game="game"
        :tournament="tournament"
        :user_id="user_id"
        :team="game.underdog"
        :group_id="group_id"
      ></TournamentTeam>
    </v-card-text>

    <v-card-title
      v-if="game.winner !== 'undecided'"
      class="datetime vig-card-footer-mini font-weight-regular normal-line-height pa-0 px-1"
      :class="(isUserGame) ? 'mygame' : ''"
    >
      <v-row
        class="ma-0"
        no-gutters
      >
        <v-col
          class="pa-0"
          align="center"
        >
          <span class="sub-text">{{ winnerNickname }} advances with {{ winnerTeam }}</span>
        </v-col>
      </v-row>
    </v-card-title>
  </v-card>
</template>

<script>
import TournamentTeam from '@/components/tournaments/Team'

export default {
  name: 'TournamentMatchup',
  components: {
    TournamentTeam
  },

  props: {
    tournament: Object,
    game: Object,
    round: String,
    group_id: {
      type: [String, Number],
      default: '0'
    },
    user_id: {
      type: [String, Number],
      default: '0'
    }
  },

  computed: {
    divisionName: function () {
      if (this.round !== 'Final Four' && this.round !== 'Championship') {
        return this.tournament.teams[this.game.favorite].division + ' Regional - '
      }
      return ''
    },

    isUserGame: function () {
      var ismine = false
      if (+this.user_id > 0 && this.tournament.assignedteams && this.tournament.assignedteams[this.game.round] && this.tournament.assignedteams[this.game.round][this.game.favorite]) {
        ismine = (+this.tournament.participant[this.tournament.assignedteams[this.game.round][this.game.favorite].vigcontestentryid].user_id === +this.user_id ||
          +this.tournament.participant[this.tournament.assignedteams[this.game.round][this.game.underdog].vigcontestentryid].user_id === +this.user_id)
      }
      return ismine
    },

    winnerNickname: function () {
      var nick = ''
      if (this.game.winner === 'favorite') {
        nick = this.tournament.assignedteams[this.game.round][this.game.favorite].nickname
      } else {
        nick = this.tournament.assignedteams[this.game.round][this.game.underdog].nickname
      }
      return nick
    },

    winnerTeam: function () {
      var team = ''
      if (this.game.winner === 'favorite' || this.game.winner === 'cover underdog') {
        team = this.tournament.assignedteams[this.game.round][this.game.favorite].team
      } else {
        team = this.tournament.assignedteams[this.game.round][this.game.underdog].team
      }
      return team
    }
  },

  methods: {
    displayGameClock (game) {
      const scoreboard = JSON.parse(game.scoreboard)
      if (scoreboard) {
        return this.getNumberWithOrdinal(scoreboard.period) + ' ' + scoreboard.time
      } else {
        console.dir(scoreboard)
      }
    },

    getNumberWithOrdinal (n) {
      var s = ['th', 'st', 'nd', 'rd']
      var v = n % 100
      return n + (s[(v - 20) % 10] || s[v] || s[0])
    }
  }
}
</script>

<style scoped>
.mygame span {
  color: #fcc02c !important;
  font-weight: bold !important;
}

.normal-line-height {
  line-height: 1.2rem !important;
}
.game-summary-box {
  padding-top: 4px;
  padding-bottom: 4px;
}
.game-summary-box.left {
  padding-left: 16px;
}
.game-summary-box.right {
  padding-right: 16px;
}
.double-height {
  line-height: 3em;
}
.game-button {
  width: 100% !important;
  margin-bottom: 4px !important;
  background: #ddd !important;
  color: black !important;
}
.game-button >>> .md-button-content {
  width: 100% !important;
}
</style>
