<template>
  <div>
    <div
      class="dialog"
      v-if="!showSimilar"
    >
      <BackButton></BackButton>
      <md-steppers
        :md-active-step.sync="active"
        md-vertical
        md-linear
      >
        <md-step
          id="game"
          md-label="Pick a Game"
          :md-editable="false"
          :md-done.sync="gameChose"
        >
          <div class="md-title text-center">
            <h4>Upcoming Games</h4>
          </div>
          <div class="md-layot text-center">
            <md-button
              class="md-layout-item md-size-100 md-accent md-raised"
              @click="$router.push('/schedule')"
            >Search Games</md-button>
          </div>
          <div class="game-list">
            <div
              v-for="game in games"
              :key="game.id"
            >
              <div
                class="button-border"
                @click="gameClicked(game)"
              >
                <div class="datetime">
                  <span class="sub-text">{{ game.league }} {{ game.starttime | datetime }}</span>
                  <md-divider></md-divider>
                </div>
                <div class="md-layout md-alignment-center-space-between">
                  <div class="md-layout-item md-size-100">
                    <div class="md-layout md-alignment-center-space-between">
                      <div class="md-layout-item md-size-100">
                        <div class="md-layout linepadding">
                          <div class="md-layout-item md-size-100">
                            <span>
                              (<span :class="lineColor(game.ats_line * -1)">{{ game.ats_line * -1 | offerLine }}</span>)
                            </span>
                            <span>{{ game.awayteam }}</span>
                          </div>
                        </div>
                        <div class="md-layout linepadding">
                          <div class="md-layout-item md-size-100">
                            <span>
                              (<span :class="lineColor(game.ats_line)">{{ game.ats_line | offerLine }}</span>)
                            </span>
                            <span>{{ game.hometeam }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="md-layout-item md-size-100 text-center">O/U: {{ game.ou_line }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </md-step>

        <md-step
          id="details"
          md-label="Setup Offer"
          :md-editable="false"
          :md-done.sync="detailsSet"
        >
          <div v-if="chosenGame !== null">
            <div class="md-layout md-gutter md-alignment-center-space-around">
              <div class="md-layout-item md-xsmall-size-35 md-small-size-30 md-medium-size-25 md-size-20">
                <md-button
                  class="md-primary md-raised"
                  @click="selectLine(chosenGame.awayteam_id)"
                  :class="[offerDetails.teamChoice == chosenGame.awayteam_id ? 'md-accent' : '']"
                >{{ chosenGame.awayteam_abbr }}</md-button>
              </div>
              <div class="md-layout-item md-size-5">AT</div>
              <div class="md-layout-item md-xsmall-size-35 md-small-size-30 md-medium-size-25 md-size-20">
                <md-button
                  class="md-primary md-raised"
                  @click="selectLine(chosenGame.hometeam_id)"
                  :class="[offerDetails.teamChoice == chosenGame.hometeam_id ? 'md-accent' : '']"
                >{{ chosenGame.hometeam_abbr }}</md-button>
              </div>
            </div>
            <div class="md-layout md-gutter md-alignment-center-space-around">
              <div class="md-layout-item md-xsmall-size-35 md-small-size-30 md-medium-size-25 md-size-20">
                <md-button
                  class="md-primary md-raised"
                  @click="selectOU(1)"
                  :class="[offerDetails.overUnder == 1 ? 'md-accent' : '']"
                >Over</md-button>
              </div>
              <div class="md-layout-item md-size-5"></div>
              <div class="md-layout-item md-xsmall-size-35 md-small-size-30 md-medium-size-25 md-size-20">
                <md-button
                  class="md-primary md-raised"
                  @click="selectOU(0)"
                  :class="[offerDetails.overUnder == 0 ? 'md-accent' : '']"
                >Under</md-button>
              </div>
            </div>

            <!-- Over Under bet line -->
            <div
              class="md-layout md-alignment-center-center"
              v-if="offerDetails.bettype_id == 20"
              style="margin-top: 1em"
            >
              <label class="md-layout-item md-size-100 sub-text text-center">Combined Scores</label>
              <div
                class="md-layout md-alignment-center-center"
                style="margin-top:-1em"
              >
                <div class="md-layout-item md-size-20">
                  <md-button
                    @click="changeOU(0)"
                    class="md-raised md-primary md-fab md-dense"
                  >
                    <md-icon>remove</md-icon>
                  </md-button>
                </div>
                <div class="md-layout-item md-size-60">
                  <div class="md-layout md-alignment-center-center">
                    <md-field class="md-layout-item md-xsmall-size-30 md-small-size-20 md-size-15">
                      <md-input
                        name="overUnderLine"
                        v-model.number="offerDetails.overUnderLine"
                        class="input-field"
                      />
                    </md-field>
                  </div>
                </div>
                <div class="md-layout-item md-size-20">
                  <md-button
                    @click="changeOU(1)"
                    class="md-raised md-primary md-fab md-dense"
                  >
                    <md-icon>add</md-icon>
                  </md-button>
                </div>
              </div>
            </div>

            <!-- Team Line -->
            <div
              class="md-layout md-alignment-center-center"
              v-if="offerDetails.bettype_id == 30"
              style="margin-top: 1em"
            >
              <label class="md-layout-item md-size-100 sub-text text-center">Team Line</label>
              <div
                class="md-layout md-alignment-center-center"
                style="margin-top:-1em"
              >
                <div class="md-layout-item md-size-20">
                  <md-button
                    @click="changeLine(-1)"
                    class="md-raised md-primary md-fab md-dense negative-button"
                  >
                    <md-icon>remove</md-icon>
                  </md-button>
                </div>
                <div class="md-layout-item md-size-60">
                  <div class="md-layout md-alignment-center-center">
                    <md-field class="md-layout-item md-xsmall-size-30 md-small-size-20 md-size-15">
                      <span
                        class="md-prefix"
                        v-if="offerDetails.line > 0 && !offerDetails.pk"
                      >+</span>
                      <md-input
                        name="line"
                        v-model.number="offerDetails.line"
                        class="input-field"
                        v-if="!offerDetails.pk"
                      />
                      <md-input
                        name="line"
                        v-model="pkString"
                        class="input-field"
                        v-if="offerDetails.pk"
                        disabled
                      />
                    </md-field>
                  </div>
                </div>
                <div class="md-layout-item md-size-20">
                  <md-button
                    @click="changeLine(1)"
                    class="md-raised md-primary md-fab md-dense positive-button"
                  >
                    <md-icon>add</md-icon>
                  </md-button>
                </div>
              </div>
            </div>
            <div
              class="md-layout"
              v-if="offerDetails.bettype_id == 30"
            >
              <md-checkbox
                v-model="offerDetails.pk"
                class="md-primary md-layout-item md-size-100"
              >Winner Only</md-checkbox>
            </div>

            <!-- Bet wager -->
            <div
              class="md-layout md-alignment-center-center"
              v-if="offerDetails.bettype_id != 0"
              style="margin-top: 1em"
            >
              <label class="md-layout-item md-size-100 sub-text text-center">Wager Amount</label>
              <div
                class="md-layout md-alignment-center-center"
                style="margin-top:-1em"
              >
                <div class="md-layout-item md-size-20">
                  <md-button
                    @click="changeWager(0)"
                    class="md-raised md-primary md-fab md-dense"
                  >
                    <md-icon>remove</md-icon>
                  </md-button>
                </div>
                <div class="md-layout-item md-size-60">
                  <div class="md-layout md-alignment-center-center">
                    <md-field class="md-layout-item md-xsmall-size-30 md-small-size-20 md-size-15">
                      <span class="md-prefix">$</span>
                      <md-input
                        name="wager"
                        v-model.number="offerDetails.wager"
                        class="input-field"
                      />
                    </md-field>
                  </div>
                </div>
                <div class="md-layout-item md-size-20">
                  <md-button
                    @click="changeWager(1)"
                    class="md-raised md-primary md-fab md-dense"
                  >
                    <md-icon>add</md-icon>
                  </md-button>
                </div>
              </div>
            </div>

            <md-button
              class="md-accent md-raised float-right"
              :disabled="isBetErrors"
              v-if="offerDetails.bettype_id != 0"
              @click="confirm"
            >Next</md-button>
          </div>
        </md-step>

        <md-step
          id="visibility"
          md-label="Set visibility"
          :md-editable="false"
          :md-done.sync="visibilitySet"
        >
          <div class="md-layout">
            <span class="md-layout-item md-size-100 sub-text">Who is this offer for?</span>
            <md-radio
              v-model="offerDetails.visibility"
              :value="0"
            >Anyone</md-radio>
            <md-radio
              v-model="offerDetails.visibility"
              :value="2"
              v-if="groups.length > 0"
            >A Group</md-radio>

            <md-field v-if="offerDetails.visibility === 2">
              <label>Groups</label>
              <md-select
                v-model="groupid"
                @md-selected="setgroup"
              >
                <md-option
                  v-for="group in groups"
                  :key="group.id"
                  :value="group.id"
                >{{ group.name }}</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout">
            <md-button
              class="md-accent md-raised"
              @click="confirm"
              :disabled="isVisibilityErrors"
            >Next</md-button>
          </div>
        </md-step>

        <md-step
          id="confirm"
          md-label="Confirmation"
          :md-editable="false"
        >
          <div
            class="md-layout"
            v-if="chosenGame !== null"
          >
            <div class="md-layout md-layout-item md-size-100 row">
              <span class="md-layout-item md-size-100 sub-text">Game</span>
              <div class="md-layout-item md-size-100">
                <span :class="{ 'bold-text' : offerDetails.teamChoice == chosenGame.awayteam_id }">{{ chosenGame.awayteam }}</span>
                vs.
                <span :class="{ 'bold-text' : offerDetails.teamChoice == chosenGame.hometeam_id }">{{ chosenGame.hometeam }}</span>
                <span class="float-right">{{ chosenGame.date }}</span>
              </div>
            </div>

            <div class="md-layout md-layout-item md-size-100 row md-alignment-top-space-between">
              <span class="md-layout-item md-size-100 sub-text">Offer Details</span>
              <div class="md-layout-item md-size-10">${{ offerDetails.wager }}</div>
              <div
                v-if="offerDetails.bettype_id != 20"
                class="md-layout-item md-size-30"
              >{{ chosenTeam }}</div>
              <div
                v-if="offerDetails.bettype_id != 20"
                class="md-layout-item md-size-20"
                :class="[ lineColor(offerDetails.line) ]"
              >{{ offerDetails.line | offerLine }}</div>
              <div
                v-if="offerDetails.bettype_id == 20"
                class="md-layout-item md-size-66"
              >
                <span :class="[lineColor(offerDetails.overUnderLine * (offerDetails.overUnder == 0 ? -1 : 1))]">{{ ouSymbol }} {{ offerDetails.overUnderLine }}</span>
              </div>
            </div>

            <div class="md-layout md-layout-item md-size-100 row">
              <span class="md-layout-item md-size-100 sub-text">Visibility</span>
              <div
                class="md-layout-item md-size-100"
                v-if="offerDetails.visibility === 0"
              >Anyone</div>
              <div
                class="md-layout-item md-size-100"
                v-if="offerDetails.visibility === 2"
              >
                <span class="sub-text">Group:</span>
                {{ offerDetails.group.name }}
              </div>
            </div>
          </div>
        </md-step>
      </md-steppers>
      <md-dialog-actions>
        <md-button
          v-if="active !== 'game'"
          class="md-raised negative-button"
          @click="goBack"
        >Go Back</md-button>
        <md-button
          class="md-primary md-raised"
          href="/dashboard"
        >Cancel</md-button>
        <md-button
          v-if="active === 'confirm'"
          class="md-accent md-raised"
          @click="confirm"
        >Confirm</md-button>
      </md-dialog-actions>
    </div>
    <div v-if="showSimilar">
      <!-- Similar offers page -->
      <div class="text-center">
        <h3>Here are some similar offers</h3>
        <p>Instead of creating an offer, you can also take a similar one listed here</p>
      </div>
      <!-- similar wager -->
      <div>
        <offer-list :offers="similarWager" @update="acceptOffer()" @acceptOffer="acceptedOffer()"></offer-list>
      </div>

      <!-- similar line -->
      <div>
        <offer-list :offers="similarLine" @update="acceptOffer()" @acceptOffer="acceptedOffer()"></offer-list>
      </div>

      <md-dialog-actions>
        <md-button
          class="md-primary md-raised"
          @click="showSimilar = false"
        >No Thanks</md-button>
      </md-dialog-actions>
    </div>
  </div>
</template>

<script>
import OfferList from '@/components/offer/List'
import GameService from '@/services/GameService'
import DashboardService from '@/services/DashboardService'
import OfferService from '@/services/OfferService'
import UserService from '@/services/UserService'
import CommunityService from '@/services/CommunityService'
import BackButton from '@/components/widget/Back'
import DateService from '@/services/DateService'
// import UserService from '@/services/UserService'

export default {
  components: {
    OfferList,
    BackButton
  },
  props: {
    game_id: {
      type: [Number, String],
      default: 0
    },
    team_id: {
      type: [Number, String],
      default: 0
    },
    type: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      pkString: 'PK',
      active: 'game',
      detailsSet: false,
      gameChose: false,
      visibilitySet: false,
      showSimilar: false,
      similarWager: [],
      similarLine: [],
      exactOffer: false,
      matchedOffer: null,
      betErrors: [],

      chosenGame: null,
      games: [],

      groups: [],
      groupid: 0,

      offerDetails: {
        game_id: 0,
        teamChoice: -1,
        wager: 10,
        bettype_id: 0,
        line: 0,
        overUnderLine: 0,
        overUnder: -1,
        pk: false,
        visibility: 0,
        group: {
          id: 0,
          name: ''
        },
        offeredto_id: null
      }
    }
  },
  mounted: function () {
    this.$updateloading(1)
    if (this.game_id > 0) {
      GameService.get(this.game_id).then((response) => {
        this.gameClicked(response.data)
      }).catch(errors => {
        this.$notify({
          group: 'errors',
          title: 'Error',
          text: errors.error,
          type: 'warn'
        }).finally(() => {
          this.$updateloading(-1)
        })
        console.dir(errors)
      })
    } else {
      GameService.search({ upcoming: true }, 10, 1, 'starttime').then((response) => {
        this.games = []
        var games = response.data.results
        for (let i = 0; i < games.length; i++) {
          this.$set(this.games, i, games[i])
        }
      }).finally(() => {
        this.$updateloading(-1)
      })
    }
  },
  methods: {
    submit: function () {
      this.$updateloading(1)
      DashboardService.userData().then(
        (response) => {
          var data = response.data
          if (data.unsettled > 0) {
            this.$router.push('/dashboard', () => {
              this.$notify({
                group: 'errors',
                title: 'Unsettled bets',
                text: 'All unsettled bets, and confirmations of payment need to be made before you can make and offer',
                type: 'warn'
              })
            })
          } else if (data.available < this.offerDetails.wager) {
            this.$notify({
              group: 'errors',
              title: 'Not enough funds',
              text: `You have $${data.available} available to make an offer, you cannot make a $${this.offerDetails.wager} offer at this time`,
              type: 'warn'
            })
          } else {
            if (this.exactOffer) {
              var detail = {
                accepted: 'now'
              }
              this.$updateloading(1)
              OfferService.updateOffer(detail, this.matchedOffer.id).then(
                (response) => {
                  this.$router.push('/dashboard', () => {
                    this.$notify({
                      group: 'notices',
                      title: 'Offer matched',
                      text: 'Your offer has been accepted by ' + this.matchedOffer.offername + ', view pending offers for details.',
                      type: 'info'
                    })
                  })
                },
                (err) => {
                  this.$notify({
                    group: 'errors',
                    title: 'Could Not Update Offer',
                    text: err.response.data.error,
                    type: 'error'
                  })
                }
              ).finally(() => {
                this.$updateloading(-1)
              })
            } else {
              if (this.offerDetails.visibility !== 2) {
                this.offerDetails.group = null
              }
              var wager = this.makewager(this.offerDetails)
              this.$updateloading(1)
              OfferService.makeOffer(wager).then(
                (response) => {
                  if (!isNaN(response.data)) {
                    this.$router.push('/dashboard', () => {
                      this.$notify({
                        group: 'notices',
                        title: 'Offer created',
                        text: 'Successfully created offer. View it in the pending section.',
                        type: 'info'
                      })
                    })
                  } else {
                    alert('An error has occured while creating this offer')
                  }
                },
                (failed) => {
                  this.$notify({
                    group: 'errors',
                    title: 'Could Not Create Offer',
                    text: failed.response.data.error,
                    type: 'error'
                  })
                }
              ).finally(() => {
                this.$updateloading(-1)
              })
            }
          }
        }
      ).finally(() => {
        this.$updateloading(-1)
      })
    },
    setgroup: function (val) {
      let g = null
      this.groups.forEach(element => {
        if (element.id === val) {
          g = element
        }
      })
      this.offerDetails.group.id = val
      this.offerDetails.group.name = g.name
    },
    gameClicked: function (game) {
      DashboardService.userData().then(
        (response) => {
          var data = response.data
          if (data.unsettled > 0) {
            this.$router.push('/dashboard', () => {
              this.$notify({
                group: 'errors',
                title: 'Unsettled bets',
                text: 'All unsettled bets, and confirmations of payment need to be made before you can make and offer',
                type: 'warn'
              })
            })
          } else if (data.available <= 0) {
            this.$router.push('/dashboard', () => {
              this.$notify({
                group: 'errors',
                title: 'No funds',
                text: 'Your total betting limit has been reached, once bets settle you will have access to make or take additional offers.',
                type: 'warn'
              })
            })
          } else {
            this.gameChose = true
            this.chosenGame = game
            this.offerDetails.overUnderLine = game.ou_line
            this.offerDetails.game_id = game.id
            this.active = 'details'
          }
        }
      ).finally(() => {
        this.$updateloading(-10)
      })
    },
    acceptOffer: function () {
      this.$router.push('/dashboard', () => {
        this.$notify({
          group: 'notices',
          title: 'Offer Accepted',
          text: 'Successfully accepted the offer.',
          type: 'info'
        })
      })
    },
    selectLine: function (team) {
      this.offerDetails.teamChoice = team
      if (team === this.chosenGame.hometeam_id) {
        this.offerDetails.line = this.chosenGame.ats_line
      } else if (team === this.chosenGame.awayteam_id) {
        this.offerDetails.line = this.chosenGame.ats_line * -1
      }
      this.offerDetails.bettype_id = 30
      this.offerDetails.overUnder = -1
    },
    selectOU: function (type) {
      this.offerDetails.overUnder = type
      this.offerDetails.teamChoice = -1
      this.offerDetails.bettype_id = 20
    },
    confirm: async function () {
      if (this.active === 'details') {
        if (this.offerDetails.pk) this.offerDetails.line = 0
        CommunityService.getgroups().then(
          (response) => {
            this.groups = []
            response.data.forEach(element => {
              if (element.canoffer === 1) {
                this.$set(this.groups, this.groups.length, element)
              }
            })
          }
        )
        this.active = 'visibility'
        this.detailsSet = true
      } else if (this.active === 'visibility') {
        if (this.offerDetails.visibility !== 2) {
          await this.checkExactMatch()
          if (!this.exactOffer) {
            this.getSimilarOffers()
          }
        }
        this.active = 'confirm'
        this.visibilitySet = true
      } else if (this.active === 'confirm') {
        this.submit()
      }
    },
    goBack: function () {
      if (this.active === 'game') {
        this.$router.push('/dashboard')
      } else if (this.active === 'details') {
        this.active = 'game'
        this.gameChose = false
        this.chosenGame = null
        this.offerDetails.chosenTeam = -1
        this.offerDetails.overUnder = -1
        this.offerDetails.bettype_id = 0
      } else if (this.active === 'visibility') {
        this.active = 'details'
        this.visibilitySet = false
        this.detailsSet = false
      } else if (this.active === 'confirm') {
        this.active = 'visibility'
        this.visibilitySet = false
      }
    },
    checkExactMatch: async function () {
      var wager = this.makewager(this.offerDetails)
      var filters = {
        exact: true,
        nottaken: true,
        offer: wager,
        notuser: UserService.getUser().id
      }
      await OfferService.getOffers(filters).then(
        (response) => {
          var offers = response.data.results
          if (offers.length > 0) {
            this.exactOffer = true
            var matchedOffer = null
            offers.forEach(element => {
              if (!matchedOffer) {
                matchedOffer = element
              }
              if (element.offer_rating) {
                if (matchedOffer.offer_rating) {
                  matchedOffer = element.offer_rating > matchedOffer.offer_rating ? element : matchedOffer
                } else {
                  matchedOffer = element
                }
              }
            })
            this.matchedOffer = matchedOffer
          }
        },
        (err) => {
          alert(err.response.data.error)
        }
      )
    },
    getSimilarOffers: function () {
      this.$updateloading(1)
      var wager = this.makewager(this.offerDetails)
      var filters = {
        notuser: UserService.getUser().id,
        similar: true,
        nottaken: true,
        offer: wager,
        like: 'wager',
        limit: 2
      }
      var order = [{
        prefix: 'uro.',
        column: 'rating',
        direction: 'DESC'
      }]
      OfferService.getOffers(filters, 2, 1, order).then(
        (response) => {
          var offers = response.data.results
          this.similarWager = []
          offers.forEach(element => {
            this.$set(this.similarWager, this.similarWager.length, element)
          })
          if (offers.length > 0) {
            this.showSimilar = true
          }
        },
        (err) => {
          alert(err.response.data.error)
        }
      ).finally(() => {
        this.$updateloading(-1)
      })

      this.$updateloading(1)
      filters = {
        notuser: UserService.getUser().id,
        similar: true,
        nottaken: true,
        offer: wager,
        like: 'line',
        limit: 2
      }

      OfferService.getOffers(filters, 2, 1, order).then((response) => {
        var offers = response.data.results
        this.similarLine = []
        offers.forEach(element => {
          this.$set(this.similarLine, this.similarLine.length, element)
        })
        if (offers.length > 0) {
          this.showSimilar = true
        }
      }).catch((err) => {
        alert(err.response.data.error)
      }).finally(() => {
        this.$updateloading(-1)
      })
    },
    changeWager: function (direction) {
      if (direction === 0) {
        if (this.offerDetails.wager > 9) {
          this.offerDetails.wager -= 10
        }
      }
      if (direction === 1) {
        this.offerDetails.wager += 10
      }
    },
    changeLine: function (direction) {
      if (direction === 1) {
        this.offerDetails.line += 0.5
        if (this.offerDetails.pk) {
          this.offerDetails.pk = false
          this.offerDetails.line = 0.5
        }
      }

      if (direction === -1) {
        this.offerDetails.line -= 0.5
        if (this.offerDetails.pk) {
          this.offerDetails.pk = false
          this.offerDetails.line = -0.5
        }
      }
    },
    makewager: function (offer) {
      var wager = {
        game_id: offer.game_id,
        bettype_id: offer.bettype_id,
        offeredbyteam_id: offer.teamChoice,
        lineamount: 0,
        amount: offer.wager,
        friendgroup_id: offer.group ? offer.group.id : null,
        offeredto_id: offer.offeredto_id
      }

      if (offer.pk) {
        wager.bettype_id = 30
      }

      if (offer.bettype_id === 20) {
        wager.lineamount = offer.overUnderLine
        wager.offeredbyteam_id = offer.overUnder === 1 ? this.chosenGame.hometeam_id : this.chosenGame.awayteam_id
      } else {
        wager.lineamount = offer.line
        if (offer.teamChoice === this.chosenGame.awayteam_id) {
          wager.lineamount *= -1
        }
      }
      return wager
    },
    changeOU: function (direction) {
      if (direction === 0) {
        if (this.offerDetails.overUnderLine > 0.5) {
          this.offerDetails.overUnderLine -= 0.5
        }
      }
      if (direction === 1) {
        this.offerDetails.overUnderLine += 0.5
      }
    },
    lineColor: function (line) {
      if (line > 0) {
        return 'positive'
      } else if (line < 0) {
        return 'negative'
      } else return ''
    }
  },
  computed: {
    chosenTeam: function () {
      return this.offerDetails.teamChoice === this.chosenGame.hometeam_id ? this.chosenGame.hometeam : this.chosenGame.awayteam
    },
    ouSymbol: function () {
      return this.offerDetails.overUnder === 1 ? 'OVER' : (this.offerDetails.overUnder === 0 ? 'UNDER' : '')
    },
    isBetErrors: function () {
      var teamselected = this.offerDetails.bettype_id !== 0
      let line = true
      if (this.offerDetails.bettype_id === 20) {
        line = this.offerDetails.overUnderLine !== 0
      }
      var amount = this.offerDetails.wager > 0
      return !(teamselected && line && amount)
    },
    isVisibilityErrors: function () {
      let good = false
      if (this.offerDetails.visibility === 2) {
        good = this.offerDetails.group.id > 0
      } else good = true
      return !good
    }
  },
  filters: {
    offerLine: function (val) {
      val = parseFloat(val)
      if (val > 0) {
        return '+' + val
      } else if (val < 0) {
        return val
      }
      return 'PK'
    },
    datetime: function (val) {
      var day = DateService.formatDateForJS(val)
      let date = ''
      let ampm = 'am'
      date += (day.getMonth() + 1) + '/'
      date += day.getDate() + '/'
      date += day.getFullYear() + ' - '
      let hours = day.getHours()
      if (hours > 12) {
        hours = hours - 12
        ampm = 'pm'
      } else if (+hours === 0) {
        hours = 12
      }
      if (hours < 10) {
        hours = '0' + hours
      }
      date += hours + ':'
      date += day.getMinutes() > 9 ? day.getMinutes() : ('0' + day.getMinutes())
      date += ampm
      return date
    }
  }
}
</script>

<style scoped>
.dialog {
  padding-left: 0 !important;
}
.md-title h4 {
  margin-bottom: 0.1vh !important;
}
.margin {
  margin-left: 0;
  margin-right: 5vw;
}
.text-center {
  align-items: center;
}
.text-left {
  text-align: left;
}
.bold-text {
  font-weight: bold;
  font-size: 1.15em;
}
.row {
  margin-top: 0;
  margin-bottom: 1.2em !important;
  font-size: 1.1em;
}
.makeOffer-content {
  max-height: 100vh;
  overflow: auto;
}
.button-border {
  margin: 1em 1em 1em 1em;
  padding: 0.5em 1em 0.5em 1em;
  width: 90%;
}
.game-list {
  max-height: 40vh;
  overflow: auto;
}
.input-field {
  overflow: hidden;
}
.negative-button {
  background-color: rgb(180, 0, 0) !important;
  color: rgb(255, 255, 255) !important;
}
.positive-button {
  background-color: rgb(0, 180, 0) !important;
}
.negative-button:disabled {
  background-color: rgb(100, 70, 70) !important;
}
.positive-button:disabled {
  background-color: rgb(70, 100, 70) !important;
}
.negative {
  font-weight: bold;
  font-size: 1.11em;
}
.positive {
  font-weight: bold;
  font-size: 1.11em;
}
.float-right {
  float: right;
}

@media screen and (min-width: 601px) {
  .game-list {
    max-height: 30vh;
  }
  .margin {
    margin-right: 0;
  }
  .dialog {
    min-width: 60vw;
  }
  .makeOffer-content {
    max-height: 80vh;
  }
}
</style>
