<template>
  <div
    class="wrapper fadeInDown text-center"
    v-if="contesttype && group"
  >
    <v-row>
      <v-col>
        <h1>{{ group.name }}</h1>
        <h1>{{ contesttype.name }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>{{ contesttype.summary }}</p>
      </v-col>
    </v-row>

    <ContestPrizes
      v-if="contesttype"
      :contest="contesttype"
    ></ContestPrizes>

    <ContestRules
      v-if="contesttype"
      :contest="contesttype"
    ></ContestRules>

    <ContestSettings
      v-if="contesttype"
      :contesttype="contesttype"
      :group_id="group_id"
    ></ContestSettings>
  </div>
</template>

<script>
import CommunityService from '@/services/CommunityService'
import ContestService from '@/services/ContestService'
import ContestRules from '@/components/contests/Rules'
import ContestPrizes from '@/components/contests/Prizes'
import ContestSettings from '@/components/contests/Settings'

export default {
  name: 'ContestType',
  components: {
    ContestRules,
    ContestPrizes,
    ContestSettings
  },

  props: {
    group_id: {
      type: String,
      default: '0'
    },
    contesttype_id: {
      type: String,
      default: '0'
    }
  },

  data: function () {
    return {
      contesttype: null,
      group: null
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      ContestService.getContestType(+this.contesttype_id).then(
        (response) => {
          this.contesttype = response.data
          CommunityService.groupInfoInit(+this.group_id).then(
            (response) => {
              this.group = response.data
            }
          )
        },
        (err) => {
          var error = err.response.data
          this.$router.push('/group/' + this.group_id, () => {
            this.$notify({
              group: 'errors',
              title: error.errors[0],
              text: error.error,
              type: 'warn'
            })
          })
        }
      )
    }
  }
}
</script>
