<style scoped>
    .carousel {
        font-family: 'Roboto';
        max-width: 600px;
        margin: 0 auto;
    }

    .slide1-subheading {
        text-align: center;
        font-size: 14px;
    }

    .slide2-subheading {
        text-align: left;
        font-size: 14px;
    }

    .slide2-subheading span {
        color: rgba(95, 95, 95, 0.9);
    }

    .step {
        color: black;
        font-weight: 550;
        font-size: 17px;
    }

    p {
        text-align: center;
    }

    .red-header {
        color: #dd222d;
        text-decoration: underline;
        font-weight: 600;
        font-size: 18px;
    }

    .swipe {
        font-weight: 500;
    }

    ::v-deep .v-carousel .v-carousel__controls {
        background-color: #dd222d;
        border: 4px solid white;
        z-index: 100;
        height: 10%;
    }

    ::v-deep .v-carousel .v-carousel__controls .v-item-group .v-btn--flat {
        background-color: #212121;
        height: 18px;
        width: 18px;
    }

    ::v-deep .v-carousel .v-carousel__controls .v-item-group .v-item--active {
        background-color: white;
        height: 18px;
        width: 18px;
    }

    ::v-deep .v-carousel .v-carousel__controls .v-item-group .v-btn__content {
        display: none;
    }

    .slide2-image {
      max-width: 50%;
      max-height: 100%;
      float: right;
    }

    .helper_bubble {
      width: 80%;
      margin: 0 auto;
      padding: 4px;
      background-color: #c02626;
      color: white;
      border-radius: 5px;
      margin-bottom: 8px;
    }

    .card-actions {
      background-color: #dd222d;
      border: 4px solid green;
      height: 50px;
      width: 100%;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .skip {
      position: absolute;
      right: 0;
      bottom: -1px;
      z-index: 120;
    }

    .card-actions .delimiters {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }

    .card-actions .delimiters .delimiter {
      height: 20px;
      width: 20px;
      margin: 0 10px;
    }

    .active {
      background-color: white;
    }

    .notactive {
      background-color: #212121;
    }

</style>
<template>
  <div>
      <v-carousel  v-model="currentslide" :show-arrows="false" class="carousel" height="90vh">
        <v-carousel-item>
          <v-card
            color="white"
            tile
            height="90%"
          >
                <v-card-title class="ma-0 pa-1" style="height: 10%;">
                  <div class="heading white--text my-0 mx-auto slides-background-red py-2">
                    <p class="text-center headline robo mb-0" style="font-weight: 600;">Welcome to VigZero!</p>
                  </div>
                </v-card-title>

                <v-card-text class="px-4 py-0 my-0" style="height: 90%;">
                  <div style="height: 100%;">
                  <img src="/img/slides/slide_1.png" style="max-height:100%; width: 100%; object-fit: contain;">
                  </div>
                </v-card-text>
          </v-card>
        </v-carousel-item>

        <v-carousel-item>
          <v-card
            color="white"
            tile
            height="90%"
          >
                <v-card-title class="ma-0 pa-1" style="height: 10%;">
                  <div class="heading white--text my-0 mx-auto slides-background-red py-2">
                    <p class="text-center headline robo mb-0" style="font-weight: 600;">Make a Deposit</p>
                  </div>
                </v-card-title>

                <v-card-text class="px-4 py-0 my-0" style="height: 90%;">
                  <div style="height: 100%;">
                  <img src="/img/slides/slide_2.png" style="max-height:100%; width: 100%; object-fit: contain;">
                  </div>
                </v-card-text>
          </v-card>
        </v-carousel-item>
        <v-carousel-item>
          <v-card
            color="white"
            tile
            height="90%"
          >
                <v-card-title class="ma-0 pa-1" style="height: 10%;">
                  <div class="heading white--text my-0 mx-auto slides-background-red py-2">
                    <p class="text-center headline robo mb-0" style="font-weight: 600;">How To Create A Bet</p>
                  </div>
                </v-card-title>

                <v-card-text class="px-4 py-0 my-0" style="height: 90%;">
                  <div style="height: 100%;">
                  <img src="/img/slides/slide_3.png" style="max-height:100%; width: 100%; object-fit: contain;">
                  </div>
                </v-card-text>
          </v-card>
        </v-carousel-item>
        <v-carousel-item>
          <v-card
            color="white"
            tile
            height="90%"
          >
                <v-card-title class="ma-0 pa-1" style="height: 10%;">
                  <div class="heading white--text my-0 mx-auto slides-background-red py-2">
                    <p class="text-center headline robo mb-0" style="font-weight: 600;">How To Create A Bet</p>
                  </div>
                </v-card-title>

                <v-card-text class="px-4 py-0 my-0" style="height: 90%;">
                  <div style="height: 100%;">
                  <img src="/img/slides/slide_4.png" style="max-height:100%; width: 100%; object-fit: contain;">
                  </div>
                </v-card-text>
          </v-card>
        </v-carousel-item>
        <v-carousel-item>
          <v-card
            color="white"
            tile
            height="90%"
          >
                <v-card-title class="ma-0 pa-1" style="height: 10%;">
                  <div class="heading white--text my-0 mx-auto slides-background-red py-2">
                    <p class="text-center headline robo mb-0" style="font-weight: 600;">Partial Wagers</p>
                  </div>
                </v-card-title>

                <v-card-text class="px-4 py-0 my-0" style="height: 90%;">
                  <div style="height: 100%;">
                  <img src="/img/slides/slide_5.png" style="max-height:100%; width: 100%; object-fit: contain;">
                  </div>
                </v-card-text>
          </v-card>
        </v-carousel-item>
        <div>
        </div>
        <v-btn @click="closeintroslide()" class="skip" text x-large>
          <span v-if="lastSlide">close</span><span v-else>skip</span>
        </v-btn>
      </v-carousel>
    </div>
</template>
<script>
export default {
  name: 'Slides',
  data: () => {
    return {
      currentslide: 0
    }
  },
  methods: {
    closeintroslide: function () {
      this.$emit('closeintroslide')
      this.currentslide = 0
    }
  },
  computed: {
    // currently no used, but may need later when more slides are made
    lastSlide: function () {
      return (this.currentslide === 4)
    }
  }
}
</script>
