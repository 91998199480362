var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { key: _vm.reload },
    [
      _c(
        "v-row",
        { staticClass: "my-0 py-0" },
        [
          _vm.isAllowed
            ? _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.contacts.length == 0,
                      expression: "contacts.length == 0"
                    }
                  ],
                  staticClass: "py-0",
                  attrs: { cols: "12" }
                },
                [
                  _c("InviteCreate", {
                    attrs: { grouplist: _vm.groups },
                    on: { updateinvites: _vm.reloadInvites }
                  })
                ],
                1
              )
            : _c("v-col", { staticClass: "text-center" }, [
                _vm._v(
                  " You do not currently have the ability to invite your friends. "
                )
              ]),
          _vm.hasContactApi
            ? _c(
                "v-col",
                { staticClass: "py-0 text-center", attrs: { cols: "12" } },
                [
                  _c("hr"),
                  _vm.contacts.length == 0
                    ? _c(
                        "v-btn",
                        {
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openContacts($event)
                            }
                          }
                        },
                        [_vm._v("Import Phone Contacts")]
                      )
                    : _vm._e(),
                  _vm.contacts.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.contacts, function(c) {
                          return _c("div", { key: c.tel }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(c.name) +
                                  " - " +
                                  _vm._s(c.email) +
                                  " - " +
                                  _vm._s(c.tel)
                              )
                            ])
                          ])
                        }),
                        0
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.invites
            ? _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("hr"),
                  _c("InviteList", {
                    attrs: {
                      invites: _vm.invites,
                      pagination: _vm.pagination,
                      loading: _vm.loading,
                      grouplist: _vm.groups
                    },
                    on: {
                      updateinvites: _vm.reloadInvites,
                      updatePagination: _vm.updatePagination,
                      searchInvites: _vm.searchInvites
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }