<template>
  <v-container class="mx-0 px-1">
    <DisputeAdmin
      v-if="dispute_id && isadmin"
      :dispute_id="dispute_id"
    ></DisputeAdmin>

    <DisputeUpdate
      v-if="dispute_id && !isadmin"
      :dispute_id="dispute_id"
    ></DisputeUpdate>

    <DisputeCreate
      v-if="wager_id"
      :wager_id="wager_id"
    ></DisputeCreate>

    <DisputeSearch v-if="!dispute_id && !wager_id"></DisputeSearch>
  </v-container>
</template>

<script>
import UserService from '@/services/UserService'
import DisputeSearch from '@/components/disputes/Search'
import DisputeCreate from '@/components/disputes/Create'
import DisputeUpdate from '@/components/disputes/Update'
import DisputeAdmin from '@/components/disputes/Admin'

export default {
  name: 'Dispute',
  components: {
    DisputeSearch,
    DisputeCreate,
    DisputeUpdate,
    DisputeAdmin
  },
  computed: {
    isadmin: function () {
      return UserService.isAdmin()
    }
  },
  props: {
    dispute_id: {
      type: [String, Number],
      default: 0
    },
    wager_id: {
      type: [String, Number],
      default: 0
    }
  }
}
</script>
