import axios from 'axios'
import Vue from 'vue'
import Notifications from 'vue-notification'
import UserService from '@/services/UserService'

Vue.use(Notifications)

const GameService = {

  getLeagueFilter () {
    return parseInt(localStorage.getItem('leagueFilter')) || 0
  },

  setLeagueFilter (value) {
    return localStorage.setItem('leagueFilter', parseInt(value))
  },

  getLeagueFilters () {
    const temp = localStorage.getItem('leagueFilters')
    if (temp !== null) {
      return JSON.parse(localStorage.getItem('leagueFilters'))
    } else {
      return [0]
    }
  },

  setLeagueFilters (value) {
    return localStorage.setItem('leagueFilters', JSON.stringify(value))
  },

  getTeamFilter () {
    const team = localStorage.getItem('teamFilter')
    return (team && team !== 'false')
  },
  setTeamFilter (value) {
    return localStorage.setItem('teamFilter', (value === true || value === 'true'))
  },

  getAvailableFilter () {
    const available = localStorage.getItem('availableFilter')
    return (available && available !== 'false')
  },
  setAvailableFilter (value) {
    return localStorage.setItem('availableFilter', (value === true || value === 'true'))
  },

  async init () {
    await UserService.authHeader(false)
    return axios({
      url: UserService.baseUrl() + 'games/init',
      method: 'post'
    })
  },

  async search (filters, perpage, page, sort) {
    await UserService.authHeader(false)
    var data = {
      filters: filters,
      perpage: perpage,
      page: page,
      sort: sort
    }
    return axios({
      url: UserService.baseUrl() + 'games/search',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async get (id) {
    await UserService.authHeader(false)
    return axios({
      url: UserService.baseUrl() + 'games/' + id,
      method: 'get'
    })
  },

  showSpread (line, team) {
    var spread = null
    if (team === 'away') {
      if (line > 0) {
        spread = '-' + line.toString()
      } else if (line < 0) {
        spread = '+' + Math.abs(line).toString()
      } else {
        spread = 'PK'
      }
    } else {
      if (line > 0) {
        spread = '+' + line.toString()
      } else if (line < 0) {
        spread = '-' + Math.abs(line).toString()
      } else {
        spread = 'PK'
      }
    }
    return spread
  }
}

export default GameService
