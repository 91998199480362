<template>
  <v-row class="my-0 py-0">
    <v-col cols="12" class="py-0">
      <v-card class="card container">
        <v-card-title @click="filterarrow = !filterarrow" class="py-0">
          <div class="mx-auto py-0"><span>Filter</span>
          <v-icon
            class="collapse-arrow"
            :class="{ 'collapse-arrow-down': filterarrow }"
          >keyboard_arrow_down</v-icon></div>
        </v-card-title>
        <v-expand-transition>
          <div v-show="filterarrow">
            <v-row class="my-0 py-0 pl-2">
              <v-col cols="6" class="py-0">
                <v-checkbox
                  v-model="status"
                  value="win"
                  label="Win"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  v-model="status"
                  value="lost"
                  label="Loss"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  v-model="status"
                  value="tie"
                  label="Tie"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  v-model="status"
                  value="notaker"
                  label="Not Taken"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  v-model="status"
                  value="deleted"
                  label="Deleted"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="my-0 py-0">
              <v-col cols="12" class="py-0">

                <v-select
                  v-model="league"
                  label="League"
                  :items="leagues"
                  item-text="display"
                  item-value="id"
                  return-object
                  single-line
                ></v-select>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col cols="12" class="py-0">
                <v-menu
                  v-model="startdatepicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startdate"
                      label="From Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startdate"
                    :allowed-dates="enabledStartDates"
                    @input="startdatepicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-menu
                  v-model="enddatepicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="enddate"
                      label="To Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="enddate"
                    :allowed-dates="enabledEndDates"
                    @input="enddatepicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row align="end" justify="end" class="my-0 py-0">
              <v-col cols="100" class="py-0">
                <v-btn
                  class="white--text pull-right"
                  color="black"
                  @click="changePage(1)"
                >Set Filters</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </v-card>
    </v-col>
    <v-col cols="12"
      class="py-0"
      v-if="offers && offers.length > 0"
    >
      <HistoryList
        :offers="offers"
        :canBet="false"
      ></HistoryList>
    </v-col>
    <v-col cols="12"
      v-else
      class="sub-text py-0 text-center"
    >
      <h1 v-if="!loading">No wagers found.</h1>
    </v-col>
    <v-col cols="12"
      class="py-0"
      v-if="offers && offers.length > 0 && totalPages != 1"
    >
      <Pagination
        :page="pagenumber"
        :nextPage="nextPage"
        :totalPages="totalPages"
        @updatePage="changePage"
      ></Pagination>
    </v-col>
  </v-row>
</template>
<script>
import OfferService from '@/services/OfferService'
import WagerService from '@/services/WagerService'
import HistoryList from '@/components/offer/HistoryList'
import Pagination from '@/components/widget/Pagination'
import UserService from '@/services/UserService'
import DateService from '@/services/DateService'

export default {
  components: {
    HistoryList,
    Pagination
  },
  data: function () {
    return {
      startdatepicker: false,
      enddatepicker: false,
      loading: true,
      offers: null,
      pagenumber: null,
      nextPage: null,
      totalPages: null,
      status: ['win', 'lost', 'tie', 'notaker'],
      leagues: [],
      league: { name: '', sport: '', id: 0, display: 'All Leagues' },
      filterarrow: false,
      mindate: null,
      maxdate: null,
      startdate: null,
      enddate: null
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    init: async function () {
      this.$updateloading(1)
      this.loading = true
      OfferService.historyFilter().then(
        (response) => {
          this.mindate = response.data.startdate
          this.maxdate = response.data.enddate
          this.startdate = response.data.startdate
          this.enddate = response.data.enddate
          this.leagues = response.data.leagues
          this.leagues.forEach(el => {
            el.display = el.name + ' - ' + el.sport
          })
          this.leagues.push({
            name: '',
            sport: '',
            id: 0,
            display: 'All Leagues'
          })
          this.getdata()
        },
        (error) => {
          console.log(error)
          this.loading = false
        }
      ).finally(
        () => {
          this.$updateloading(-1)
        }
      )
    },
    getdata: function () {
      var userid = UserService.getUser().id
      var filters = {
        all: true,
        daysback: 90,
        includechat: true,
        isUser: userid,
        status: this.status,
        startdate: DateService.formatDateForDB(DateService.formatDateForJS(this.startdate + ' 12:00:00'))
      }
      if (this.league.id > 0) {
        filters.league = this.league.id
      }
      if (this.enddate) {
        filters.enddate = DateService.formatDateForDB(DateService.formatDateForJS(this.enddate + ' 12:00:00'))
      }
      var order = [
        {
          column: 'inprogress',
          direction: 'DESC'
        },
        {
          prefix: 'g.',
          column: 'starttime',
          direction: 'DESC'
        },
        {
          column: 'statussort',
          direction: 'ASC'
        }
      ]
      this.$updateloading(1)
      WagerService.search(filters, 20, this.page, order).then(
        (response) => {
          this.offers = response.data.results
          this.pagenumber = response.data.page
          this.nextPage = response.data.nextPage
          this.totalPages = response.data.totalPages
          this.loading = false
        },
        (error) => {
          console.log(error)
        }
      ).finally(
        () => {
          this.$updateloading(-1)
        }
      )
    },
    changePage: function (page) {
      if (this.filterarrow) {
        this.filterarrow = false
      }
      this.page = page
      this.getdata()
    },
    enabledEndDates: function (date) {
      var dateFormatted = DateService.formatDateForJS(date + ' 12:00:00')
      var minFormatted = DateService.formatDateForJS(this.mindate + ' 12:00:00')
      var maxFormatted = DateService.formatDateForJS(this.maxdate + ' 12:00:00')
      var startFormatted = DateService.formatDateForJS(this.startdate + ' 12:00:00')
      return dateFormatted >= minFormatted && dateFormatted <= maxFormatted && (startFormatted && dateFormatted > startFormatted)
    },
    enabledStartDates: function (date) {
      var dateFormatted = DateService.formatDateForJS(date + ' 12:00:00')
      var minFormatted = DateService.formatDateForJS(this.mindate + ' 12:00:00')
      var maxFormatted = DateService.formatDateForJS(this.maxdate + ' 12:00:00')
      var endFormatted = DateService.formatDateForJS(this.enddate + ' 12:00:00')
      return dateFormatted >= minFormatted && dateFormatted <= maxFormatted && (endFormatted && dateFormatted < endFormatted)
    }
  }
}
</script>
<style scoped>
</style>
