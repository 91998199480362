var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-6" },
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            { staticClass: "text-right py-0", attrs: { cols: "12" } },
            [
              _c("v-switch", {
                staticClass: "py-0 my-0 pull-right",
                attrs: { "hide-details": "", disabled: _vm.loading },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "black--text",
                            staticStyle: { "font-size": "0.85rem" }
                          },
                          [_vm._v("Hide Not Taken")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.hideNotTaken,
                  callback: function($$v) {
                    _vm.hideNotTaken = $$v
                  },
                  expression: "hideNotTaken"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.offers && _vm.offers.length > 0
        ? _c(
            "div",
            [
              _c("HistoryList", {
                attrs: { offers: _vm.offers, canBet: false, canChat: true },
                on: {
                  update: function($event) {
                    return _vm.update()
                  }
                }
              })
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "my-0 py-0 text-center", attrs: { cols: "12" } },
                [_vm._v(" No pending wagers. ")]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }