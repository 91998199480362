var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.friendgroup && (_vm.friendgroup.id > 0 || _vm.demo)
    ? _c(
        "v-card",
        { staticClass: "border" },
        [
          _c(
            "v-card-text",
            { staticClass: "chat-notification-heading pt-1 pb-0 px-0" },
            [
              _c(
                "v-row",
                { staticClass: "my-0 mx-1" },
                [
                  _c("v-col", { staticClass: "px-0 py-0" }, [
                    _c(
                      "span",
                      [
                        _c("v-icon", [_vm._v("$bullhorn")]),
                        _c("strong", { staticClass: "pl-1" }, [
                          _vm._v(
                            "(" + _vm._s(_vm.friendgroup.messagecount) + ")"
                          )
                        ])
                      ],
                      1
                    )
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    { staticClass: "px-0 py-0 text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "chat-notification-button-mute ma-0",
                          attrs: {
                            "x-small": "",
                            tile: "",
                            disabled: _vm.loading || _vm.reading || _vm.sending
                          },
                          on: { click: _vm.toggleMuteGroup }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.friendgroup.mutegroup > 0 ? "Unmute" : "Mute"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            {
              ref: "messageContainer",
              staticClass: "overflow-y-auto pa-2 ma-0",
              staticStyle: { "max-height": "250px", "min-height": "100px" }
            },
            [
              _vm.friendgroup &&
              _vm.friendgroup.messages &&
              _vm.friendgroup.messages.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.friendgroup.messages, function(m) {
                      return _c(
                        "v-row",
                        {
                          key: m.id,
                          staticClass: "mt-0 mb-1",
                          attrs: {
                            justify: m.user_id == _vm.userId ? "end" : "start"
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              class:
                                m.user_id == _vm.userId
                                  ? "text-right"
                                  : "text-left"
                            },
                            [
                              _c("div", { staticClass: "timestamp" }, [
                                _vm._v(_vm._s(m.created))
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "message",
                                  class:
                                    m.user_id == _vm.userId
                                      ? "message-from"
                                      : "message-to"
                                },
                                [
                                  _c("div", [
                                    m.user_id != _vm.userId
                                      ? _c("strong", [
                                          _vm._v(_vm._s(m.nickname) + ": ")
                                        ])
                                      : _vm._e(),
                                    _vm._v(" " + _vm._s(m.message))
                                  ])
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e()
            ]
          ),
          _c("v-text-field", {
            staticClass: "chat-notification-reply pt-1 pb-2",
            attrs: {
              "hide-details": "",
              placeholder: "Type your message here",
              solo: "",
              dense: "",
              rounded: ""
            },
            on: {
              keypress: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.sendMessage($event)
              },
              focus: function($event) {
                return _vm.swipe(false)
              },
              blur: function($event) {
                return _vm.swipe(true)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "append",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-0 pa-0",
                          attrs: {
                            disabled: _vm.reading || _vm.sending,
                            icon: ""
                          },
                          on: { click: _vm.sendMessage }
                        },
                        [_c("v-icon", [_vm._v("send")])],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3433718177
            ),
            model: {
              value: _vm.message,
              callback: function($$v) {
                _vm.message = $$v
              },
              expression: "message"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }