<template>
  <div>
    <v-row
      align="center"
      justify="space-between"
      class="offer py-0 my-0 ml-n1"
      no-gutters
    >
      <v-col class="pr-2">
        <v-row class="pa-0 ma-0">
          <v-col
            cols="6"
            class="pa-0 ma-0"
          >
            <div
              class="vigzero-btn team robo offer-btn"
              style="display: flex;"
              :class=" openaway ? 'red-btn' : 'grey-btn'"
            >
              <v-row
                align="center"
                justify="center"
                no-gutters
                class="ma-0 pa-0"
              >
                <v-col class="pa-0">
                  <strong
                    v-if="wager.bettype_id != 20"
                    :class="getTeamFontSize(wager.away)"
                  >
                    {{ wager.away }}
                    <span v-if="wager.favoriteaway">
                      <v-icon
                        class="icon"
                        :class="(openaway) ? 'white--text' : 'black--text'"
                      >{{ (wager.favorite) ? 'favorite' : 'flag' }}</v-icon>
                    </span>
                  </strong>
                  <strong v-else>OVER</strong><br>
                </v-col>
                <v-col
                  cols="2"
                  class="pa-0 line-font text-center"
                >
                  <span v-if="wager.bettype_id != 20">{{ (gameline * -1) | line }}</span>
                  <span v-else>{{ gameline }}</span>
                </v-col>
              </v-row>
              <v-row
                class="my-0"
                v-if="openhome"
              >
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <span class="usern">
                    {{ wager.offername }}
                    <StarRating :rating="wager.offer_rating"></StarRating>
                    <span v-if="openhome && wager.record && (wager.record.wins !== 0 || wager.record.losses !== 0)">
                      <WagerRecord :record="wager.record"></WagerRecord>
                    </span>
                  </span>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col
            cols="6"
            class="text-center ma-0 pa-0"
            align-self="center"
          >
            <div v-if="openaway">
              <h1
                class="cash my-0 py-0 text-right"
                style="line-height: 0.9em;"
              >
                <div class="button-group">
                  <button
                    class="sumdiff-btn minus robo"
                    style="line-height: 0.75em; color: black;"
                    v-if="!wager.all"
                    :disabled="wager.takenbyamount - 5 < 5" @click="decrementAmount"
                    :class="{ 'disabled-btn': wager.takenbyamount - 5 < 5 }"
                  >
                    -
                  </button>
                  <span class="amount">${{ wager.takenbyamount }}</span>
                  <button
                    class="sumdiff-btn plus robo"
                    style="line-height: 0.75em; color: black;"
                    v-if="!wager.all"
                    :disabled="wager.takenbyamount > wager.offeredbyamount || takerAmount(wager.offeredbyamount) < wager.takenbyamount + 5" @click="incrementAmount"
                    :class="{ 'disabled-btn': wager.takenbyamount > wager.offeredbyamount || takerAmount(wager.offeredbyamount) < wager.takenbyamount + 5 }"
                  >
                    +
                  </button>
                </div>
              </h1>
              <span
                class="robo pull-right"
              >
                <span
                  class="value"
                >to win ${{ offeredAmount(wager.takenbyamount) }}<i class="value"> *</i><br></span>
                <span
                  class="value"
                  v-if="getWagerValue() != '0'"
                >Value: <span class="value-text">{{ getWagerValue() }}</span></span>
              </span>
            </div>
            <div v-else class="text-center ml-12">
              <span
                v-if="wager.takenbyamountunrounded"
                class="asterisk-message text-left"
              >{{ winAmountRoundedMessage }}</span><br>
              <span v-if="!wager.all"
                class="asterisk-message text-left"
              >This bet can be taken in full or in part, in increments of $5.</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0">
          <v-col
            cols="6"
            class="pa-0 ma-0"
          >
            <div
              class="vigzero-btn team robo offer-btn"
              style="display: flex;"
              :class=" openhome ? 'red-btn' : 'grey-btn'"
            >
              <v-row
                align="center"
                justify="center"
                no-gutters
                class="ma-0"
              >
                <v-col class="py-0">
                  <v-row
                    justify="center"
                    class="ma-0"
                  >
                    <v-col
                      cols="12"
                      class="pa-0"
                    >
                      <strong
                        v-if="wager.bettype_id != 20"
                        :class="getTeamFontSize(wager.home)"
                      >
                        {{ wager.home }}
                        <span v-if="wager.favoritehome">
                          <v-icon
                            class="icon"
                            :class="(openhome) ? 'white--text' : 'black--text'"
                          >{{ (wager.favorite) ? 'favorite' : 'flag' }}</v-icon>
                        </span>
                      </strong>
                      <strong v-else>UNDER</strong><br>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="2"
                  class="line-font text-center pa-0 ma-0"
                >
                  <span v-if="wager.bettype_id != 20">{{ gameline | line }}</span>
                  <span v-else>{{ gameline }}</span>
                </v-col>
              </v-row>
              <v-row
                class="my-0"
                v-if="openaway"
              >
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <span class="usern">
                    {{ wager.offername }}
                    <StarRating :rating="wager.offer_rating"></StarRating>
                    <span v-if="openaway && wager.record && (wager.record.wins !== 0 || wager.record.losses !== 0)">
                      <WagerRecord :record="wager.record"></WagerRecord>
                    </span>
                  </span>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="text-center pa-0 ma-0"
            align-self="center"
          >
            <div v-if="openhome">
              <h1
                class="cash my-0 py-0 text-right"
                style="line-height: 0.9em;"
              >
                <div class="button-group">
                  <button
                    class="sumdiff-btn minus robo"
                    style="line-height: 0.75em; color: black;"
                    v-if="!wager.all"
                    :disabled="wager.takenbyamount - 5 < 5" @click="decrementAmount"
                    :class="{ 'disabled-btn': wager.takenbyamount - 5 < 5 }"
                  >
                    -
                  </button>
                  <span class="amount amountbig">${{ wager.takenbyamount }}</span>
                  <button
                    class="sumdiff-btn plus robo"
                    style="line-height: 0.75em; color: black;"
                    v-if="!wager.all"
                    :disabled="wager.takenbyamount > wager.offeredbyamount || takerAmount(wager.offeredbyamount) < wager.takenbyamount + 5" @click="incrementAmount"
                    :class="{ 'disabled-btn': wager.takenbyamount > wager.offeredbyamount || takerAmount(wager.offeredbyamount) < wager.takenbyamount + 5 }"
                  >
                    +
                  </button>
                </div>
              </h1>
              <span
                class="robo pull-right"
              >
                <span
                  class="value"
                >to win ${{ offeredAmount(wager.takenbyamount) }}<i class="value"> *</i><br></span>
                <span
                  class="value"
                  v-if="getWagerValue() != '0'"
                >Value: <span class="value-text">{{ getWagerValue() }}</span></span>
              </span>
            </div>
            <div v-else class="text-center ml-12">
              <span
                v-if="wager.takenbyamountunrounded"
                class="asterisk-message text-left"
              >{{ winAmountRoundedMessage }}</span><br>
              <span v-if="!wager.all"
                class="asterisk-message text-left"
              >This bet can be taken in full or in part, in increments of $5.</span>
            </div>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
  </div>
</template>
<script>
import StarRating from '@/components/wagers/StarRating'
import WagerRecord from '@/components/wagers/WagerRecord'

export default {
  components: {
    StarRating,
    WagerRecord
  },
  props: {
    wager: {
      id: Number,
      ammount: Number,
      offeredbyteam_id: Number,
      takenbyteam_id: Number,
      lineamount: Number,
      game_id: Number,
      bettype_id: Number,
      description: String,

      awayteamname: String,
      away: String,
      awayabbr: String,
      awayteam_id: Number,
      awayteamscore: Number,
      hometeamname: String,
      home: String,
      homeabbr: String,
      hometeam_id: Number,
      hometeamscore: Number,

      friendgroup_id: Number,
      offer_rating: Number,
      offeredby_id: Number,
      offername: String,
      takenby_id: Number,
      taker_rating: Number,
      takername: String,

      league: String,
      league_id: Number,
      season: String,
      season_id: Number,
      starttime: String,
      currentline: Number,

      all: Number
    }
  },
  data: () => {
    return {
      winAmountRoundedMessage: '* Rounded to nearest $'
    }
  },
  mounted: function () {
    this.emitAmountChange()
  },
  methods: {
    // get the value of a wager based on the current vegas line
    getWagerValue () {
      if (typeof this.wager.lineamount === 'undefined' || this.wager.lineamount === null) {
        return 0
      }
      var value = this.wager.linediff
      // convert to string to add "+" if greater than 0
      value += ''
      if (value > 0) {
        value = '+' + value
      }
      return value
    },
    getLineWidth: function () {
      let size = 'md-size-20'
      var multiple = this.wager.bettype_id === 20 ? 1 : -1
      if (this.gameline) {
        var linelength = ('' + (Math.abs(this.gameline) * multiple)).length
        if (linelength < 3) {
          size = 'md-size-10'
        } else if (linelength < 5) {
          size = 'md-size-15'
        }
      }
      return size
    },
    getTeamWidth: function () {
      var multiple = this.wager.bettype_id === 20 ? 1 : -1
      if (this.gameline) {
        var linelength = ('' + (Math.abs(this.gameline) * multiple)).length
        if (linelength < 3) {
          return 'md-size-90'
        } else if (linelength < 5) {
          return 'md-size-85'
        } else {
          return 'md-size-80'
        }
      }
    },
    getFontSize: function (str) {
      var len = 1
      if (str < 0) {
        len = 0
      }
      len += (str + '').length

      var size = '4.5em'
      if (len > 3) {
        size = '3.5em'
      }
      if (len > 5) {
        size = '2.5em'
      }
      return size
    },
    getTeamFontSize: function (str) {
      if (str) {
        var split = str.split(' ')
        if (split.length === 1) {
          if (str.length > 8) {
            return 'smallTeam'
          }
        }
      }
    },
    incrementAmount () {
      if ((this.wager.takenbyamount < this.wager.offeredbyamount) && (((this.wager.takenbyamount + 5) <= this.wager.offeredbyamount) || ((this.wager.takenbyamount + 5) <= this.takerAmount(this.wager.offeredbyamount)))) {
        this.wager.takenbyamount += 5
        this.emitAmountChange()
      }
    },
    decrementAmount () {
      if (this.wager.takenbyamount > 5 && (this.wager.takenbyamount - 5) >= 5) {
        this.wager.takenbyamount -= 5
        this.emitAmountChange()
      }
    },
    emitAmountChange () {
      this.$emit('update-amount', this.wager.id, this.wager.takenbyamount)
    },
    takerAmount (amt) {
      var ret = amt
      console.log('offeredby_id === hometeam_id:', +this.wager.offeredbyteam_id === +this.wager.hometeam_id)
      this.myteam = (+this.wager.offeredbyteam_id === +this.wager.hometeam_id) ? this.wager.hometeam : this.wager.awayteam
      this.otherteam = (+this.wager.offeredbyteam_id === +this.wager.hometeam_id) ? this.wager.awayteam : this.wager.hometeam
      var line = this.wager.lineamount !== 'undefined' && this.wager.lineamount != null ? this.wager.lineamount : this.wager.currentline
      if (this.wager.bettype_id !== 10) {
        return amt
      }
      console.log('myteam', this.myteam)
      console.log('otherteam', this.otherteam)
      if (this.myteam === this.wager.awayteam) {
        line = line * -1
        console.log('this.myteam === this.game.awayteam', line)
      }
      if (line > 0) {
        // this is an underdog line
        ret = (line / 100) * amt
        console.log('line > 0', ret)
      } else {
        // this is a favorite line
        ret = (100 / line) * amt
        console.log('line < 0', ret)
      }
      console.log('taker amount', ret)
      return Math.round(Math.abs(ret))
    },
    offeredAmount (amt) {
      var ret = amt
      this.myteam = (+this.wager.takenbyteam_id === +this.wager.hometeam_id) ? this.wager.hometeam : this.wager.awayteam
      this.otherteam = (+this.wager.takenbyteam_id === +this.wager.hometeam_id) ? this.wager.awayteam : this.wager.hometeam
      var line = this.wager.lineamount !== 'undefined' && this.wager.lineamount != null ? this.wager.lineamount : this.wager.currentline
      if (this.wager.bettype_id !== 10) {
        return amt
      }
      if (this.myteam === this.wager.awayteam) {
        console.log('this.myteam === this.game.awayteam', line)
        line = line * -1
      }
      if (line > 0) {
        // this is an underdog line
        ret = (line / 100) * amt
        console.log('line > 0', ret)
      } else {
        // this is a favorite line
        ret = (100 / line) * amt
        console.log('line < 0', ret)
      }
      console.log('taker amount', ret)
      return Math.round(Math.abs(ret))
    }
  },
  computed: {
    gameline: function () {
      return typeof this.wager.lineamount !== 'undefined' && this.wager.lineamount != null ? this.wager.lineamount : this.wager.currentline
    },
    openaway: function () {
      var open = this.wager.bettype_id === 20 ? this.wager.offeredbyteam_id !== this.wager.hometeam_id : this.wager.offeredbyteam_id !== this.wager.awayteam_id
      if ((this.wager.takenby_id != null && this.wager.offeredby_id != null)) {
        open = false
      }
      return open
    },
    openhome: function () {
      var open = this.wager.bettype_id === 20 ? this.wager.offeredbyteam_id !== this.wager.awayteam_id : this.wager.offeredbyteam_id !== this.wager.hometeam_id
      if ((this.wager.takenby_id != null && this.wager.offeredby_id != null)) {
        open = false
      }
      return open
    }
  },
  filters: {
    line: function (val) {
      val = parseFloat(val)
      if (val > 0) {
        return '+' + val
      } else if (val < 0) {
        return val
      }
      return 'PK'
    }
  }
}
</script>
<style scoped>
.disabled-btn {
  background-color: #cccccc; /* Light gray for disabled state */
  color: #666666;           /* Gray color for disabled text */
  cursor: not-allowed;      /* Disabled cursor style */
  opacity: 0.6;             /* Lower opacity to indicate disabled state */
  pointer-events: none;     /* Prevent any interaction */
}

.sumdiff-btn {
  transition: all 0.3s ease; /* Smooth transition when enabling/disabling */
}

.cash {
  margin-top: 0.3em;
  margin-bottom: 0.5em;
}
.offer {
  margin-top: 1em;
}
.asterisk-message {
  font-size: 0.8rem;
}
.value-text {
  font-size: 1.3rem;
}
.smallTeam {
  font-size: 0.7em;
  word-wrap: break-word;
}

.amountbig {
  min-width: 80px;
  display: inline-block;
  text-align: center;
}

@media only screen and (min-width: 525px) {
  .smallTeam {
    font-size: 1em;
    word-wrap: none;
  }
}

/* Responsive styles for smaller screens (<= 500px) */
@media only screen and (max-width: 525px) {
  .sumdiff-btn {
    width: 30px; /* Smaller width for smaller screens */
    height: 30px; /* Smaller height for smaller screens */
    font-size: medium; /* Smaller font size for smaller screens */
    /* padding: 2px;
    margin: 0 2px;  */
  }
  .button-group {
    justify-content: space-between; /* Ensures the buttons and amount are aligned in a single row */
  }
  .amount {
    line-height: 0.5;
    min-width: 45px;
    font-size: medium;
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
