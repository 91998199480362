<template>
  <div>

    <v-row
      class="my-0"
      v-show="!compact"
    >
      <v-col
        class="py-0"
        cols="4"
      >
        <span class="action-section">Bet #{{ payout.id }}</span>
      </v-col>
      <v-col class="py-0 text-right">
        <span class="sub-text">{{ payout.away + ' vs. ' + payout.home }}</span>
      </v-col>
    </v-row>

    <v-row class="my-1">
      <v-col
        cols="12"
        class="py-0"
      >
        <p
          class="mb-1"
          v-if="payout.paymentsent"
        >{{ payout.nickname }} reported sending you <strong>${{ payout.amount }}</strong><span v-show="!compact"> for your {{ getGameInfo(payout) }} <span class="winner">WIN</span></span> from Venmo account <strong>@{{ payout.paymentdetails[0].value }}</strong>.</p>
        <p class="text-center mb-0"><strong>Did you receive this payment?</strong></p>
      </v-col>
    </v-row>

    <v-row
      class="my-0"
      v-if="isDispute(payout)"
    >
      <v-col class="py-0">Dispute: {{ payout.disputetype }} - {{ payout.disputestatus }}</v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        class="py-0"
        align-self="center"
      >
        <v-card-actions class="justify-center">
          <v-btn
            v-if="payout.paymentsent"
            :small="!compact"
            :x-small="compact"
            color="red darken-1"
            class="white--text small"
            @click="denyPayment(payout)"
          >No Payment</v-btn>

          <v-spacer v-if="payout.paymentsent"></v-spacer>

          <v-btn
            v-if="payout.paymentsent"
            :small="!compact"
            :x-small="compact"
            color="light-green darken-2"
            class="white--text small"
            @click="confirmPayment(payout)"
          >Yes, I Got Paid</v-btn>
          <v-dialog
            v-model="confirmDialog"
            width="500"
            v-else
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :small="!compact"
                :x-small="compact"
                color="light-green darken-2"
                class="white--text small"
                v-on="on"
              >Yes, I Got Paid</v-btn>
            </template>
            <v-card>
              <v-card-text>
                <h3 class="text-center black--text pt-5">Are you sure?</h3>
                You are confirming prior to the losing member indicating that payment has been made.
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  class="white--text small"
                  @click="confirmDialog = false"
                >Cancel</v-btn>
                <v-btn
                  color="light-green darken-2"
                  class="white--text small"
                  @click="confirmPayment(payout)"
                >Yes</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DashboardService from '@/services/DashboardService'

export default {
  props: {
    payout: Object,
    compact: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      confirmDialog: false
    }
  },
  methods: {
    async confirmPayment (payout) {
      this.$updateloading(1)
      await DashboardService.confirmPayment(payout.id).then(
        (response) => {
          this.$updateloading(-1)
          this.$notify({
            group: 'notices',
            title: 'Payment Confirmed',
            text: 'This wager is now in your history.',
            type: 'info'
          })
          this.$emit('confirm', payout)
        },
        (error) => {
          this.$updateloading(-1)
          alert('An error has occured, please try again later. If the error continues to happen please contact us\n' + error.response.data.error)
        }
      )
    },

    denyPayment (payout) {
      if (payout.dispute_id !== null && payout.opendispute === 1) {
        this.$router.push('/disputes/' + payout.dispute_id)
      } else {
        this.$emit('deny', payout)
      }
    },

    getGameInfo: function (payout) {
      var homeline = (payout.lineamount > 0 ? ' (+' + payout.lineamount : ' (' + payout.lineamount) + ')'
      var awayline = ((payout.lineamount * -1) > 0 ? '(+' + (payout.lineamount * -1) : '(' + (payout.lineamount * -1)) + ') '

      if (payout.offeredby_id === payout.winner_id) {
        if (payout.hometeam_id === payout.offeredbyteam_id) {
          if (payout.bettype_id === 20) {
            return 'Over ' + payout.lineamount
          } else {
            return payout.homeabbr + ' ' + homeline
          }
        } else {
          if (payout.bettype_id === 20) {
            return 'Under ' + payout.lineamount
          } else {
            return payout.awayabbr + ' ' + awayline
          }
        }
      } else {
        if (payout.hometeam_id === payout.takenbyteam_id) {
          if (payout.bettype_id === 20) {
            return 'Over ' + payout.lineamount
          } else {
            return payout.homeabbr + ' ' + homeline
          }
        } else {
          if (payout.bettype_id === 20) {
            return 'Under ' + payout.lineamount
          } else {
            return payout.awayabbr + ' ' + awayline
          }
        }
      }
    },

    isDispute: function (payout) {
      return payout.opendispute === 1
    }
  }
}
</script>
