<template>
  <v-row
    class="mx-0 py-0"
    v-if="game && game.id"
  >
    <v-col cols="6" class="py-0">
      <h2>Current Line: {{ showSpread(game.ats_line, 'home') }}</h2>
      <h2 class="text-center">Available: {{ +game.wagers_home_line + +game.wagers_away_line }}</h2>
    </v-col>
    <v-col cols="6" class="py-0">
      <h2>Over/Under: {{ (game.ou_line) ? game.ou_line : 'N/A' }}</h2>
      <h2 class="text-center">Available: {{ +game.wagers_home_ou + +game.wagers_away_ou }}</h2>
    </v-col>

    <v-col cols="12"
      class="py-0 my-1 vig-card-expand"
    ></v-col>

    <v-col cols="6" class="py-0">
      <h1 class="text-center">{{ game.awayteam }}</h1>
      <h2 class="text-center">
        Offers: {{ +game.wagers_home_line + +game.wagers_home_ou }}
        <span v-if="detail && game.wagers_home_linemin">
          (
          {{ showSpread(game.wagers_home_linemin, 'home') }}
          <span v-if="game.wagers_home_linemax && game.wagers_home_linemax != game.wagers_home_linemin">
            to {{ showSpread(game.wagers_home_linemax, 'home') }}
          </span>
          )
        </span>
        <span v-if="detail && game.wagers_home_oumin">
          (
          {{ showSpread(game.wagers_home_oumin, 'home') }}
          <span v-if="game.wagers_home_oumax && game.wagers_home_oumax != game.wagers_home_oumin">
            to {{ game.wagers_home_oumax }}
          </span>
          )
        </span>
      </h2>
    </v-col>
    <v-col cols="6" class="py-0">
      <h1 class="text-center">{{ game.hometeam }}</h1>
      <h2 class="text-center">
        Offers: {{ +game.wagers_away_line + +game.wagers_away_ou }}
        <span v-if="detail && game.wagers_away_linemin">
          <br>
          {{ showSpread(game.wagers_away_linemin, 'home') }}
          <span v-if="game.wagers_away_linemax && game.wagers_away_linemax != game.wagers_away_linemin">
            to {{ showSpread(game.wagers_away_linemax, 'home') }}
          </span>
        </span>
        <span v-if="detail && game.wagers_away_oumin">
          <br>
          {{ game.wagers_away_oumin }}
          <span v-if="game.wagers_away_oumax && game.wagers_away_oumax != game.wagers_away_oumin">
            to {{ game.wagers_away_oumax }}
          </span>
        </span>
      </h2>
    </v-col>
  </v-row>
</template>

<script>
import GameService from '@/services/GameService'

export default {
  name: 'BetInfo',
  props: {
    game: Object,
    detail: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showSpread (line, team) {
      return GameService.showSpread(line, team)
    }
  }
}
</script>
