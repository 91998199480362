var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "320" },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(" Remove Contest Entry? ")
          ]),
          _c("v-card-text", [
            _vm._v("Are you sure you want to remove "),
            _c("strong", [_vm._v(_vm._s(_vm.entry.nickname))]),
            _vm._v(
              "'s contest entry? This cannot be undone. The user will need to re-enter the contest."
            )
          ]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "error", raised: "" },
                  on: { click: _vm.remove }
                },
                [_vm._v(" Remove ")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { raised: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }