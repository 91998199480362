import axios from 'axios'
import Vue from 'vue'
import Notifications from 'vue-notification'
import UserService from './UserService'

Vue.use(Notifications)

const NotificationsService = {
  async getNotifications (userid) {
    const data = {
      userid: userid
    }
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'notifications/get',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async saveSettings (notifications) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'notifications/save',
      data: JSON.stringify(notifications),
      method: 'post'
    })
  },

  async getdevice (deviceinfo) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'notifications/getdevice',
      data: JSON.stringify(deviceinfo),
      method: 'post'
    })
  }
}

export default NotificationsService
