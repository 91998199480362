
<template>
  <v-row class="my-0">
    <v-col
      cols="10"
      class="text-center mx-auto my-0 pt-0"
      v-if="wagersSelected > 0"
    >
      <button
        class="vigzero-btn confirm robo text-center mx-auto"
        @click.prevent="takeWagers"
      >Confirm Selected</button>
    </v-col>

    <v-col cols="12" class="py-0">
      <GameHeader :game="gameHeaderInfo()"></GameHeader>
    </v-col>
    <v-col cols="12" class="pt-0">
      <span class="sub-text pull-left">
        Limit: ${{ availableLimit }}
        <span class="sub-text" v-if="vigSavings != 0"> Vig Savings: ${{ vigSavings }}</span>
      </span>
      <span class="sub-text pull-right">Selected: ${{ wagersSelected }}</span>
    </v-col>

    <v-col
      cols="12"
      v-if="wagers && wagers.length > 0"
      class="py-0 my-0"
    >
      <v-row class="py-0 my-0">
        <v-col
          cols="12"
          v-for="wager in wagers"
          :key="wager.id"
          class="py-0 my-0"
        >
          <v-row align="center" justify="start" class="py-0 my-0" no-gutters>
            <v-col cols="12" class="py-0 my-0 pr-3">
              <span v-if="wager.ishonor == 1" style="color: #E53935;">
                * Honor Bet
              </span>
            </v-col>
          </v-row>
          <v-row align="center" justify="start" class="py-0 my-0" no-gutters>
            <v-col cols="11" class="py-0 my-0 pr-3">
              <WagerInfo :wager="wager" @update-amount="handleAmountUpdate"></WagerInfo>
            </v-col>
            <v-col cols="1" class="py-0 my-0" :align-self="(openhome(wager)) ? 'end' : 'start'">
              <v-checkbox
                class="pull-right mr-0"
                :style="(openhome(wager)) ? 'margin-bottom: 12px;' : 'margin-top: 32px;'"
                v-model="wager.selected"
                @change="checkSelectedWagers($event, wager)"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12"
      class="py-0 text-center"
      v-if="wagers && wagers.length == 0"
    >
      <p>No wagers found.</p>
    </v-col>

    <v-dialog v-model="showDepositDialog" persistent max-width="600px" style="overflow: hidden;">
      <v-card class="pa-4 mt-4">
        <v-card-title>
          <span class="headline font-weight-bold">Deposit Money</span>
          <!-- Close Icon Button -->
          <v-spacer></v-spacer>
          <v-icon
            class="close-icon"
            @click="closeDialog"
            v-bind="$attrs"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <strong>Your account is a little short for this bet.</strong>
          VigZero not only allows you to keep more money with zero vigorish, for 2025, no deposit fee (no withdrawal fees ever with VigZero).
          <br><br>
          <strong>How to make a deposit to VigZero:</strong>
          <ol>
            <li>Enter the amount you wish to deposit below.</li>
            <li>Send the amount via Venmo to <b>@VigZero</b> (Venmo should open for convenience after you 'Submit').</li>
            <li>You can make bets immediately but your Venmo transaction must be made/verified prior to the start of any game or your wager(s) will be voided.</li>
          </ol>
          <br>
          <v-text-field v-model="depositAmount"
            label="Intended Deposit Amount"
            prefix="$"
            type="number"
            outlined
            :step="50"
            :rules="[v => !!v || 'This field is required', v => v > 0 || 'Enter a positive number']"
          ></v-text-field>
          <v-btn color="green" @click="submitDeposit()"
            :disabled="isSubmitting"
          >
            Submit
          </v-btn><br><br>
          <!-- <v-btn color="red darken-1" text @click="closeDialog()">Make offer on honor system</v-btn> -->
          <a href="#" class="text-link" @click="makeOfferOnHonorSystem" style="color: #E53935; cursor: pointer;" v-if="VZBalace === 0 && honorbet">MAKE OFFER ON HONOR SYSTEM</a>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import GameService from '@/services/GameService'
import WagerService from '@/services/WagerService'
import UserService from '@/services/UserService'
import GameHeader from '@/components/games/Header'
import WagerInfo from '@/components/wagers/Info'
import TransactionService from '@/services/TransactionService'
import { bus } from '@/main'

export default {
  name: 'WagerList',
  components: {
    GameHeader,
    WagerInfo
  },
  props: {
    wagers: Array,
    game: Object,
    team_id: {
      type: [Number, String],
      default: 0
    },
    type: String,
    betlimit: {
      type: Number,
      default: 0
    }
  },
  computed: {
    wagersSelected: function () {
      var selected = 0
      this.wagers.forEach((w) => {
        if (w.selected) {
          selected += w.takenbyamount
        }
      })
      return selected
    },
    availableLimit: function () {
      return this.betlimit - this.wagersSelected
    },
    hasPaymentMethod: function () {
      var paymentmethods = UserService.getUserObject().paymentmethod
      if (paymentmethods.length > 0) {
        return paymentmethods[0].details[0].value !== ''
      } else {
        return false
      }
    }
  },
  // watch: {
  //   showDepositDialog (newVal) {
  //     if (!newVal && this.awaitingUserDecision) {
  //       this.handleDialogClose()
  //     }
  //   }
  // },
  data: function () {
    return {
      currentline: null,
      vigSavings: 0,
      userid: 0,
      VZBalace: 0,
      depositAmount: 0,
      // awaitingUserDecision: false,
      honorbet: false,
      showDepositDialog: false,
      isSubmitting: false
      // depositMade: false
    }
  },
  // created () {
  //   // Ensure partialamount is initialized before rendering
  //   this.wagers.forEach(wager => {
  //     wager.partialamount = wager.takenbyamount
  //   })
  // },
  mounted: async function () {
    await this.getUserBalance()
    // this.wagers.forEach(wager => {
    //   wager.partialamount = wager.takenbyamount
    // })
    // if (this.VZBalace < 5) {
    //   this.showDepositDialog = true
    // }
    // if (this.VZBalace === 0) {
    //   this.honorbet = true
    // }
    this.init()
  },
  methods: {
    init () {
      this.currentline = (this.type === 'line') ? this.game.ats_line : (this.type === 'money') ? this.game.money_line : this.game.ou_line
      const user = UserService.getUserObject()
      this.vigSavings = user && user.savings ? user.savings : 0
    },
    async takeWagers () {
      const user = UserService.getUserObject()
      if (!user) {
        bus.$emit('notLoggedInModal')
        return
      }
      const totalTakenByAmount = this.wagers.reduce((acc, w) => w.selected ? acc + w.takenbyamount : acc, 0)
      await this.getUserBalance()
      if (this.VZBalace < totalTakenByAmount) {
        if (this.VZBalace !== 0) {
          this.honorbet = false
          this.showDepositDialog = true
          return
        } else if (this.VZBalace === 0) {
          // If balance is zero, check for honor bet eligibility
          for (const wager of this.wagers) {
            if (wager.selected && wager.ishonor !== 1) {
              // If any wager is not eligible for honor bet, set honorbet to false, show deposit dialog, and return
              this.honorbet = false
              this.showDepositDialog = true
              return
            }
          }
        }
      }
      this.processWagerTaking()
    },
    async processWagerTaking () {
      // Logic to process wager taking
      var selected = []
      this.wagers.forEach((w) => {
        if (w.selected) {
          w.currentline = this.currentline
          selected.push(w)
        }
      })
      if (selected.length === 0) {
        return this.$notify({
          group: 'errors',
          title: 'No Wagers Selected',
          text: 'No wagers are selected. Please select at least one wager.',
          type: 'warn'
        })
      }

      this.$updateloading(1)
      await WagerService.accept(selected).then(response => {
        UserService.refreshAuthData()
        this.$updateloading(-1)
        // on successful wager selection
        this.$gtag.event('accept', {
          event_category: 'wager',
          event_label: 'selected',
          value: selected
        })
        this.$router.push('/dashboard/schedule/' + this.game.id, () => {
          this.$notify({
            group: 'notices',
            title: 'Wagers Accepted',
            text: 'Bet' + ((selected.length > 1) ? 's' : '') + ' Confirmed (now listed on Pending Bets view)',
            type: 'info',
            duration: 10000
          })
        })
      }).catch(errors => {
        this.$updateloading(-1)
        this.$notify({
          group: 'errors',
          title: 'Wager Failed',
          text: errors.response.data.error,
          type: 'warn'
        })
        this.$emit('wager-reload', selected)
        console.dir(errors)
      })
      // this.depositMade = false
    },
    async getUserBalance () {
      this.$updateloading(1)
      const user = UserService.getUserObject()
      this.userid = user ? +user.info.id : 0
      await TransactionService.getUserCurrentBalance(this.userid).then(
        (response) => {
          this.VZBalace = response.data
          console.log(this.VZBalace)
        },
        (error) => {
          console.dir(error)
          this.$updateloading(-1)
        }
      ).finally(
        () => {
          this.$updateloading(-1)
        }
      )
    },
    async submitDeposit () {
      this.isSubmitting = true
      if (!this.depositAmount || this.depositAmount <= 0) {
        alert('Please enter a valid amount.')
        this.isSubmitting = false
        return
      }
      this.$updateloading(1)
      const depositData = {
        user_id: this.userid,
        amount: this.depositAmount
      }
      TransactionService.addDeposit(depositData).then(
        (response) => {
          console.log(response)
          // URLs for Venmo
          const webURL = `https://venmo.com/vigzero?txn=pay&amount=${this.depositAmount}&audience=private&note=VZ${response.data.transaction_id}`
          // window.open(webURL, '_blank')
          const venmoURL = `venmo://paycharge?txn=pay&recipients=vigzero&amount=${this.depositAmount}&note=VZ${response.data.transaction_id}`
          const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
          if (isIOS) {
            // Attempt to open the Venmo app
            window.location.href = venmoURL
            setTimeout(() => {
              window.open(webURL, '_blank')
            }, 1500)
          } else {
            // Open the web URL directly for non-iOS devices
            window.open(webURL, '_blank')
          }
        },
        (err) => {
          console.error('Deposit error:', err)
          this.$notify({
            group: 'notices',
            title: 'Error',
            text: 'Failed to submit deposit request',
            type: 'danger'
          })
        }
      ).finally(
        () => {
          // this.depositMade = true
          this.isSubmitting = false
          this.closeDialog()
          this.$updateloading(-1)
        }
      )
    },
    // handleDialogClose () {
    //   if (this.awaitingUserDecision) {
    //     this.awaitingUserDecision = false
    //     if (this.depositMade || !this.showDepositDialog || this.honorbet) {
    //       this.processWagerTaking()
    //     }
    //   }
    // },
    handleAmountUpdate (wagerId, newAmount) {
      const wager = this.wagers.find(w => w.id === wagerId)
      if (wager) {
        wager.takenbyamount = newAmount
      }
    },
    makeOfferOnHonorSystem () {
      // this.honorbet = true
      this.closeDialog()
    },
    closeDialog: function () {
      this.showDepositDialog = false
    },
    showSpread (line, team) {
      return (line === 0) ? 'PK' : GameService.showSpread(line, team)
    },
    async checkSelectedWagers (val, wager) {
      if (val) {
        if (this.wagersSelected > this.betlimit) {
          alert('You don\'t have the available funds')
          wager.selected = false
        }
        await this.getUserBalance()
        if (wager.ishonor === 1) {
          this.honorbet = true
          if ((this.VZBalace !== 0 && wager.takenbyamount > this.VZBalace)) {
            this.showDepositDialog = true
          }
        } else if (wager.ishonor === 0) {
          this.honorbet = false
          if (wager.takenbyamount > this.VZBalace) {
            this.showDepositDialog = true
          }
        }
      }
      this.$emit('wager-selected', this.selectedWagers())
    },
    selectedWagers () {
      return this.wagersSelected
    },
    getWagerValue (wager) {
      if (typeof wager.lineamount === 'undefined' || wager.lineamount === null) {
        return 0
      }
      return this.currentline - wager.lineamount
    },
    gameHeaderInfo: function () {
      return {
        league: this.game.league,
        sport: this.game.sport,
        gamedate: this.game.gamedate_formatted,
        starttime: this.game.starttime_formatted,
        currentline: (this.type === 'line') ? this.game.ats_line : (this.type === 'money') ? this.game.money_line : this.game.ou_line,
        awayteam: this.game.awayteamname,
        hometeam: this.game.hometeamname,
        bettype_id: (this.type === 'line') ? 30 : (this.type === 'money') ? 10 : 20
      }
    },

    openaway: function (wager) {
      var open = (+wager.bettype_id === 20) ? (+wager.offeredbyteam_id !== +wager.hometeam_id) : (+wager.offeredbyteam_id !== +wager.awayteam_id)
      if ((wager.takenby_id != null && wager.offeredby_id != null)) {
        open = false
      }
      return open
    },
    openhome: function (wager) {
      var open = (+wager.bettype_id === 20) ? (+wager.offeredbyteam_id !== +wager.awayteam_id) : (+wager.offeredbyteam_id !== +wager.hometeam_id)
      if ((wager.takenby_id != null && wager.offeredby_id != null)) {
        open = false
      }
      return open
    }
  }
}
</script>
