<template>
  <v-expansion-panels>
    <v-expansion-panel class="v-expansion-panel-skinny">
      <v-expansion-panel-header expand-icon="mdi-menu-down">
        <h2 class="ma-0 black--text">Payouts</h2>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="text-left py-3 px-0">
        <v-simple-table
          v-if="contest.entryfee"
          dense
          class="text-center"
        >
          <template v-slot:default>
            <thead>
              <th class="text-right pr-2">Entries:</th>
              <th
                :class="getEntryClass(16)"
                class="text-right pr-2"
              >16</th>
              <th
                :class="getEntryClass(32)"
                class="text-right pr-2"
              >32</th>
              <th
                :class="getEntryClass(64)"
                class="text-right pr-2"
              >64</th>
            </thead>
            <tbody>
              <tr>
                <th class="pa-0 pl-1">Champion</th>
                <td
                  :class="getEntryClass(16)"
                  class="text-right pa-0 pr-1"
                >${{ calculateWinning(16, 50) }}</td>
                <td
                  :class="getEntryClass(32)"
                  class="text-right pa-0 pr-1"
                >${{ calculateWinning(32, 50) }}</td>
                <td
                  :class="getEntryClass(64)"
                  class="text-right pa-0 pr-1"
                >${{ calculateWinning(64, 50) }}</td>
              </tr>
              <tr>
                <th class="pa-0 pl-1">Runner-Up</th>
                <td
                  :class="getEntryClass(16)"
                  class="text-right pa-0 pr-1"
                >${{ calculateWinning(16, 30) }}</td>
                <td
                  :class="getEntryClass(32)"
                  class="text-right pa-0 pr-1"
                >${{ calculateWinning(32, 30) }}</td>
                <td
                  :class="getEntryClass(64)"
                  class="text-right pa-0 pr-1"
                >${{ calculateWinning(64, 30) }}</td>
              </tr>
              <tr>
                <th class="pa-0 pl-1">Final 4 (each)</th>
                <td
                  :class="getEntryClass(16)"
                  class="text-right pa-0 pr-1"
                >${{ calculateWinning(16, 10) }}</td>
                <td
                  :class="getEntryClass(32)"
                  class="text-right pa-0 pr-1"
                >${{ calculateWinning(32, 10) }}</td>
                <td
                  :class="getEntryClass(64)"
                  class="text-right pa-0 pr-1"
                >${{ calculateWinning(64, 10) }}</td>
              </tr>
              <tr>
                <th class="pa-0 pl-1">Odds of Cashing</th>
                <td
                  :class="getEntryClass(16)"
                  class="text-right pa-0 pr-1"
                >1 in 4</td>
                <td
                  :class="getEntryClass(32)"
                  class="text-right pa-0 pr-1"
                >1 in 8</td>
                <td
                  :class="getEntryClass(64)"
                  class="text-right pa-0 pr-1"
                >1 in 16</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div v-else>
          <ul>
            <li>50% Champion</li>
            <li>30% Runner-Up</li>
            <li>10% Other Final Four (each)</li>
          </ul>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'ContestPrizes',
  props: {
    contest: Object
  },
  methods: {
    calculateWinning (entries, pct) {
      // return ((this.contest.entries > this.contest.minimum) ? this.contest.entries : this.contest.minimum * this.contest.entryfee) * (pct / 100)
      return Math.round((entries * this.contest.entryfee) * (pct / 100))
    },

    getEntryClass (entries) {
      if (+this.contest.entries === +entries) {
        return 'success lighten-3'
      } else if (this.contest.entries >= 32 && this.contest.entries < 64 && entries === 32) {
        return 'success lighten-3'
      } else if (this.contest.entries >= 16 && this.contest.entries < 32 && entries === 16) {
        return 'success lighten-3'
      } else {
        return ''
      }
    }
  }
}
</script>
