<template>
  <div
    v-if="invites"
    class="game-list"
  >
    <div v-if="invites.length === 0">
      <p class="text-center">No invites found.</p>
    </div>
    <ResendInvite @close="closeResend()" :invite="selectedInvite" :edit="edit" :grouplist="grouplist"></ResendInvite>
    <v-data-table
      :footer-props="{
        itemsPerPageText: ''
      }"
      :headers="headers"
      :items="invites"
      :page="pagination.page"
      :items-per-page="pagination.rowsPerPage"
      :server-items-length="pagination.totalItems"
      :loading="loading"
      class="elevation-1"
      mobile-breakpoint="0"
      @update:items-per-page="updateItemCount"
      @update:page="updatePage"
    >
      <template
        v-slot:top
      >
        <v-row class="mb-0 mx-4 pt-2" no-gutters justify="start">
          <v-col cols="12" class="py-0">
            <div class="text-center">
              <span class="pr-2 overline font-weight-black">Accepted</span>
              <v-switch
                style="display:inline-block"
                v-model="unaccepted"
                class="switch-small pa-0 my-0 ml-3 mb-n2 mr-n3"
              ></v-switch>
              <span class="overline font-weight-black">Unaccepted</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col class="py-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-4"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.email`]="{ item }" v-if="unaccepted && search == ''">
        <span v-if="item.email == '' || item.email == null">{{ item.phone | phonenumber }}</span>
        <span v-else>{{ item.email | maxTwenty }}</span>
        <br>
        <span>Code: <b>{{ item.code }}</b></span>
      </template>
      <template v-slot:[`item.created`]="{ item }" v-if="unaccepted && search == ''">
        <v-btn
          @click="selectInvite(item)"
        >Resend</v-btn>
      </template>
      <template v-slot:[`item.name`]="{ item }" v-if="!unaccepted && search == ''">
        <span>{{ item.name | maxTwenty }}</span>
      </template>
      <template v-slot:[`item.accepteduser`]="{ item }" v-if="!unaccepted && search == ''">
        <span>{{ item.accepteduser | maxTwenty }}</span>
      </template>
      <template v-slot:[`item.firstname`]="{ item }" v-if="search != ''">
        <span v-if="item.accepteduser_id == null && (item.email != '' && item.email != null)">
          {{ item.email | maxTwenty }}
        </span>
        <span v-if="item.accepteduser_id == null && (item.email == '' || item.email == null)">
          {{ item.phone | phonenumber }}
        </span>
        <br v-if="item.accepteduser_id == null">
        <span v-if="item.accepteduser_id == null">Code: <b>{{ item.code }}</b></span>
        <span v-if="item.accepteduser_id != null">{{ item.name | maxTwenty }}</span>
      </template>
      <template v-slot:[`item.lastname`]="{ item }" v-if="search != ''">
        <v-btn
          v-if="item.accepteduser_id == null"
          @click="selectInvite(item)"
        >Resend</v-btn>
        <span v-else>{{ item.accepteduser | maxTwenty }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ResendInvite from '@/components/invites/Resend'

export default {
  name: 'InviteList',
  components: {
    ResendInvite
  },
  props: {
    invites: Array,
    grouplist: Array,
    loading: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object,
      default: () => {
        return {
          page: 1,
          rowsPerPage: 5,
          totalItems: 0
        }
      }
    }
  },
  data: () => {
    return {
      edit: false,
      selectedInvite: null,
      search: '',
      unaccepted: true,
      pagination: null,
      searchTimeout: null
    }
  },
  created: function () {
    this.pagination = this.filter
    this.$emit('updatePagination', this.pagination)
  },
  computed: {
    headers: function () {
      if (this.search !== '') {
        return [
          {
            text: '',
            sortable: false,
            value: 'firstname'
          },
          {
            text: '',
            sortable: false,
            value: 'lastname'
          }
        ]
      } else if (this.unaccepted) {
        return [
          {
            text: 'Sent to',
            align: 'start',
            sortable: false,
            value: 'email'
          },
          {
            text: '',
            sortable: false,
            align: 'end',
            value: 'created'
          }
        ]
      } else {
        return [
          {
            text: 'Name',
            sortable: false,
            value: 'name'
          },
          {
            text: 'Nickname',
            sortable: false,
            value: 'accepteduser'
          }
        ]
      }
    }
  },
  methods: {
    updateItemCount: function (data) {
      this.pagination.rowsPerPage = data
      this.$emit('updatePagination', this.pagination)
    },
    updatePage: function (data) {
      this.pagination.page = data
      this.$emit('updatePagination', this.pagination)
    },
    selectInvite: function (invite) {
      this.selectedInvite = invite
      this.edit = true
    },
    closeResend: function () {
      this.edit = false
      this.selectedInvite = null
      this.$emit('updateinvites')
    }
  },
  watch: {
    filter: function (n, o) {
      if (n !== o) {
        this.pagination = n
      }
    },
    unaccepted: function (n, o) {
      if (n !== o) {
        this.search = ''
        var data = {
          searchTerm: this.search,
          unaccepted: n
        }
        this.$emit('searchInvites', data)
      }
    },
    search: function (n, o) {
      if (n !== o) {
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout)
        }
        this.searchTimeout = setTimeout(() => {
          // test for phone number and format it
          if (/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(n)) {
            n = n.replace(/\D+/g, '')
          }
          var data = {
            searchTerm: n,
            unaccepted: this.unaccepted
          }
          this.$emit('searchInvites', data)
        }, 1000)
      }
    }
  },
  filters: {
    maxTwenty: function (val) {
      if (!val) { return }
      return (val.length < 20) ? val : val.substring(0, 20) + '...'
    },
    phonenumber: function (val) {
      val += ''
      if (val.length === 10) {
        val = '(' + val.substring(0, 3) + ') ' + val.substring(3, 6) + '-' + val.substring(6)
      } else if (val.length === 11) {
        val = '+' + val.substring(0, 1) + '(' + val.substring(1, 4) + ')' + val.substring(4, 7) + '-' + val.substring(7)
      }
      return val
    }
  }
}
</script>
