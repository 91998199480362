var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper fadeInDown" }, [
    _c("div", { attrs: { id: "verify-form" } }, [
      _c(
        "form",
        {
          staticClass: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.verify()
            }
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_c("div", [_vm._v("Phone Verification")])]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            ref: "code",
                            attrs: {
                              label: "Activation Code",
                              required: "",
                              minlength: "6",
                              inputmode: "numeric",
                              pattern: "[0-9]*",
                              autocomplete: "one-time-code",
                              disabled: _vm.sending,
                              loading: _vm.sending
                            },
                            on: { keydown: _vm.filterKey },
                            model: {
                              value: _vm.form.activationcode,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "activationcode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.activationcode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: _vm.sending },
                      on: {
                        click: function($event) {
                          return _vm.resend()
                        }
                      }
                    },
                    [_vm._v("Resend")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        type: "submit",
                        disabled: _vm.sending,
                        color: "primary"
                      }
                    },
                    [_vm._v("Submit")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }