<template>
  <div>
    <PaymentReceivedDialog
      :wager="confirmPayout"
      @cancel="confirmPayout = null"
      @paid="confirmPayout = null; update()"
      @deny="confirmPayout = null; update()"
      @dispute="confirmPayout = null; update()"
    ></PaymentReceivedDialog>

    <PaymentSentDialog
      :wager="confirmDebt"
      @cancel="confirmDebt = null"
      @paid="confirmDebt = null; update()"
    ></PaymentSentDialog>

    <v-dialog
      v-if="clickedOffer != null"
      v-model="showDelete"
      persistent
    >
      <v-card>
        <v-card-title class="text-center">
          Delete Offer
        </v-card-title>
        <v-card-text class="pt-2">
          Are you sure you want to delete this offer?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            class="white--text"
            @click="cancelDelete()"
          >Cancel</v-btn>
          <v-btn
            @click="deleteOffer()"
            :disabled="clickedOffer.takenby_id != null"
            color="red"
            class="white--text"
          >DELETE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div
      v-for="offer in offers"
      :key="offer.id"
      @click="offerclicked(offer)"
      :id="generateWagerIdentifier(offer.id)"
    >
      <History
        :offer="offer"
        :admin="offer.admin"
        :canChat="canChat"
        @delete="openDelete(offer)"
        @update="update()"
        @sendpayment="openSendPayment"
        @confirmpayment="update()"
        @denypayment="openConfirmPayment"
        @expanded="setExpandedWagers"
        :status="offer.status ? offer.status : status"
      ></History>
      <div class="spacer"></div>
    </div>
  </div>
</template>
<script>
import History from '@/components/offer/History'
import DashboardService from '@/services/DashboardService'
import PaymentSentDialog from '@/components/dashboard/actions/PaymentSentDialog'
import PaymentReceivedDialog from '@/components/dashboard/actions/PaymentReceivedDialog'
import { bus } from '@/main'

export default {
  components: {
    History,
    PaymentSentDialog,
    PaymentReceivedDialog
  },
  data: () => {
    return {
      confirmPayout: null,
      disputePayout: null,
      confirmDebt: null,
      disputeDebt: null,

      clickedOffer: null,
      showTakeOffer: false,
      showDelete: false,
      type: null
    }
  },
  props: {
    offers: Array,
    status: String,
    canBet: {
      type: Boolean,
      default: true
    },
    canChat: {
      type: Boolean,
      default: false
    },
    section: {
      type: String,
      default: ''
    }
  },
  mounted: function () {
    bus.$on('messages-updated', (data) => {
      this.update()
    })
  },
  beforeDestroy () {
    this.setExpandedWagers()
  },
  methods: {
    setExpandedWagers () {
      var expanded = []
      this.offers.forEach(function (o) {
        if (o.expand) {
          expanded.push(o.id)
        }
      })
      if (JSON.stringify(expanded) !== localStorage.getItem('expandedwagers')) {
        localStorage.setItem('expandedwagers', JSON.stringify(expanded))
      }
    },
    openSendPayment (offer) {
      if (offer.dispute_id > 0) {
        this.$router.push('/disputes/' + offer.dispute_id)
      } else {
        this.confirmDebt = offer
      }
    },
    openConfirmPayment (offer) {
      if (offer.dispute_id > 0) {
        this.$router.push('/disputes/' + offer.dispute_id)
      } else {
        this.confirmPayout = offer
      }
    },
    offerclicked: function (offer) {
      if (offer.takenby_id == null && !offer.admin && this.canBet) {
        /* this.clickedOffer = offer
        this.showTakeOffer = true */
        this.$router.push(this.getWagerLinkUrl(offer))
      } else {
        this.$emit('clicked')
      }
    },
    getWagerLinkUrl: function (wager) {
      var type = (wager.bettype_id === 30) ? 'line' : ((wager.takenbyteam_id === wager.hometeam_id) ? 'under' : 'over')
      var path = '/wager/' + wager.game_id + '/' + wager.takenbyteam_id + '/' + type + '/' + wager.id
      if (this.section) {
        path += '/' + this.section
      }
      return path
    },
    generateWagerIdentifier: function (id) {
      return this.section + 'Wager' + id
    },
    closemodal: function () {
      this.showTakeOffer = false
      this.clickedOffer = null
    },
    openDelete: function (offer) {
      this.clickedOffer = offer
      this.showDelete = true
    },
    cancelDelete: function () {
      this.showDelete = false
      this.clickedOffer = null
    },
    update: function () {
      this.$emit('update')
    },
    deleteOffer: function () {
      this.$updateloading(1)
      DashboardService.getWager(this.clickedOffer.id).then(
        (response) => {
          this.$updateloading(-1)
          const game = response.data
          if (game.takenby_id !== null) {
            this.showDelete = false
            this.$notify({
              group: 'errors',
              title: 'Error',
              text: 'Offer is already taken by someone',
              type: 'warn'
            })
          } else {
            DashboardService.deleteOffer(this.clickedOffer.id)
              .then(response => {
                this.clickedOffer = null
                this.$notify({
                  group: 'notices',
                  title: 'Deleted',
                  text: 'Successfully deleted offer',
                  type: 'info'
                })
                this.$emit('update')
              })
              .catch(error => {
                this.$notify({
                  group: 'errors',
                  title: error.response.data.error,
                  text: error.response.data.errors[0],
                  type: 'warn'
                })
                this.clickedOffer = null
                console.log(error)
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
.spacer {
  min-height: 3vh;
}
.margin {
  margin-left: 5vw;
  margin-right: 5vw;
}
.dialog-content {
  margin-top: 3vh;
  margin-bottom: 3vh;
}
</style>
