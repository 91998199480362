import { render, staticRenderFns } from "./Rules.vue?vue&type=template&id=15448f37&"
import script from "./Rules.vue?vue&type=script&lang=js&"
export * from "./Rules.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels})


/* hot reload */
if (module.hot) {
  var api = require("/builds/websites/vigzero/vig-zero-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15448f37')) {
      api.createRecord('15448f37', component.options)
    } else {
      api.reload('15448f37', component.options)
    }
    module.hot.accept("./Rules.vue?vue&type=template&id=15448f37&", function () {
      api.rerender('15448f37', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/contests/Rules.vue"
export default component.exports