var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.game && _vm.game.id
    ? _c(
        "v-row",
        { staticClass: "mx-0 py-0" },
        [
          _c("v-col", { staticClass: "py-0", attrs: { cols: "6" } }, [
            _c("h2", [
              _vm._v(
                "Current Line: " +
                  _vm._s(_vm.showSpread(_vm.game.ats_line, "home"))
              )
            ]),
            _c("h2", { staticClass: "text-center" }, [
              _vm._v(
                "Available: " +
                  _vm._s(
                    +_vm.game.wagers_home_line + +_vm.game.wagers_away_line
                  )
              )
            ])
          ]),
          _c("v-col", { staticClass: "py-0", attrs: { cols: "6" } }, [
            _c("h2", [
              _vm._v(
                "Over/Under: " +
                  _vm._s(_vm.game.ou_line ? _vm.game.ou_line : "N/A")
              )
            ]),
            _c("h2", { staticClass: "text-center" }, [
              _vm._v(
                "Available: " +
                  _vm._s(+_vm.game.wagers_home_ou + +_vm.game.wagers_away_ou)
              )
            ])
          ]),
          _c("v-col", {
            staticClass: "py-0 my-1 vig-card-expand",
            attrs: { cols: "12" }
          }),
          _c("v-col", { staticClass: "py-0", attrs: { cols: "6" } }, [
            _c("h1", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.game.awayteam))
            ]),
            _c("h2", { staticClass: "text-center" }, [
              _vm._v(
                " Offers: " +
                  _vm._s(
                    +_vm.game.wagers_home_line + +_vm.game.wagers_home_ou
                  ) +
                  " "
              ),
              _vm.detail && _vm.game.wagers_home_linemin
                ? _c("span", [
                    _vm._v(
                      " ( " +
                        _vm._s(
                          _vm.showSpread(_vm.game.wagers_home_linemin, "home")
                        ) +
                        " "
                    ),
                    _vm.game.wagers_home_linemax &&
                    _vm.game.wagers_home_linemax != _vm.game.wagers_home_linemin
                      ? _c("span", [
                          _vm._v(
                            " to " +
                              _vm._s(
                                _vm.showSpread(
                                  _vm.game.wagers_home_linemax,
                                  "home"
                                )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" ) ")
                  ])
                : _vm._e(),
              _vm.detail && _vm.game.wagers_home_oumin
                ? _c("span", [
                    _vm._v(
                      " ( " +
                        _vm._s(
                          _vm.showSpread(_vm.game.wagers_home_oumin, "home")
                        ) +
                        " "
                    ),
                    _vm.game.wagers_home_oumax &&
                    _vm.game.wagers_home_oumax != _vm.game.wagers_home_oumin
                      ? _c("span", [
                          _vm._v(
                            " to " + _vm._s(_vm.game.wagers_home_oumax) + " "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" ) ")
                  ])
                : _vm._e()
            ])
          ]),
          _c("v-col", { staticClass: "py-0", attrs: { cols: "6" } }, [
            _c("h1", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.game.hometeam))
            ]),
            _c("h2", { staticClass: "text-center" }, [
              _vm._v(
                " Offers: " +
                  _vm._s(
                    +_vm.game.wagers_away_line + +_vm.game.wagers_away_ou
                  ) +
                  " "
              ),
              _vm.detail && _vm.game.wagers_away_linemin
                ? _c("span", [
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.showSpread(_vm.game.wagers_away_linemin, "home")
                        ) +
                        " "
                    ),
                    _vm.game.wagers_away_linemax &&
                    _vm.game.wagers_away_linemax != _vm.game.wagers_away_linemin
                      ? _c("span", [
                          _vm._v(
                            " to " +
                              _vm._s(
                                _vm.showSpread(
                                  _vm.game.wagers_away_linemax,
                                  "home"
                                )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.detail && _vm.game.wagers_away_oumin
                ? _c("span", [
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.game.wagers_away_oumin) + " "),
                    _vm.game.wagers_away_oumax &&
                    _vm.game.wagers_away_oumax != _vm.game.wagers_away_oumin
                      ? _c("span", [
                          _vm._v(
                            " to " + _vm._s(_vm.game.wagers_away_oumax) + " "
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }