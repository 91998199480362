<style scoped>
.expander {
  font-size: 1.5em;
  padding: 0 6px;
  font-weight: 600;
}

.icon {
  float: right;
  transform: translateY(2px);
}
</style>
<template>
  <div>
    <div
      class="center-flex"
      style="height:80vh"
      v-if="!display && !fakegroup"
    >
      <v-progress-circular
        :size="70"
        indeterminate
        color="red"
        v-if="!display"
      ></v-progress-circular>
    </div>
    <div v-show="display || fakegroup">
      <v-row>
        <v-col
          cols="12"
          class="py-0"
        >
          <back
            text=""
            @goBack="$router.push((totalGroups > 1) ? '/dashboard/group' : '/dashboard/schedule')"
            emit="goBack"
            class="pull-left pr-3"
          ></back>
          <h1>{{ groupname }} <span class="sub-text">({{ adminname }})</span></h1>
        </v-col>
      </v-row>

      <v-row
        class="mt-0 mb-5"
        v-if="contests && contests.length > 0"
      >
        <v-col class="py-0">
          <div
            class="red-btn expander"
            style="width:100%"
            @click="showContests = !showContests"
          >
            Contests<v-icon
              color="white"
              class="icon"
            >{{ showContests ? 'arrow_drop_down' : 'arrow_drop_up' }}</v-icon>
          </div>
          <div
            v-if="showContests"
            class="p-3"
          >
            <v-row
              v-for="c in contests"
              :key="c.id"
              align="center"
            >
              <v-col class="grow">
                <p class="mb-0">
                  <strong>{{ c.name }}:</strong> ${{ c.entryfee }} entry fee<br>
                  Currently {{ c.entries }}<span v-if="c.maximum"> of {{ c.maximum }}</span> entries
                </p>
                <p class="mb-0">{{ c.summary }}</p>
              </v-col>
              <v-col
                align="right"
                class="shrink"
              >
                <v-btn
                  color="primary"
                  class="text-center"
                  style="padding-top: 7px !important; padding-bottom: 7px !important;"
                  :to="'/group/' + group_id + '/contest/' + c.id + ((c.myentries > 0) ? '' : '/enter')"
                  v-html="(c.myentries > 0) ? 'Entry Confirmed<br>Go to Details' : 'Tap/Click to Enter'"
                ></v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <v-row
        class="mt-0 mb-5"
        v-if="contestsavailable && contestsavailable.length > 0"
      >
        <v-col class="py-0">
          <div
            class="red-btn expander"
            style="width:100%"
            @click="showContestsAvailable = !showContestsAvailable"
          >
            Contests Available<v-icon
              color="white"
              class="icon"
            >{{ showContestsAvailable ? 'arrow_drop_down' : 'arrow_drop_up' }}</v-icon>
          </div>
          <div
            v-if="showContestsAvailable"
            class="p-3"
          >
            <v-row
              v-for="ct in contestsavailable"
              :key="ct.id"
              align="center"
            >
              <v-col class="grow">
                <p class="mb-0"><strong>{{ ct.name }}:</strong> {{ ct.summary }}</p>
              </v-col>
              <v-col
                align="right"
                class="shrink"
              >
                <v-btn
                  small
                  color="primary"
                  :to="'/group/' + group_id + '/contesttype/' + ct.id"
                >Create</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <v-row class="my-0">
        <v-col
          cols="12"
          class="py-0 mb-4"
          v-if="(wagers && wagers.length > 0) || fakegroup"
        >
          <div
            class="red-btn expander"
            style="width:100%"
            @click="showWagers = !showWagers"
          >
            Wagers<v-icon
              color="white"
              class="icon"
            >{{ showWagers ? 'arrow_drop_down' : 'arrow_drop_up' }}</v-icon>
          </div>
          <div
            v-if="showWagers"
            class="px-3 pt-3"
          >
            <div v-if="fakegroup">
              <v-row class="my-0 py-0" @click="notLoggedInModal()">
                <v-col cols="12" class="py-0">
                  <v-row class="my-0 py-0">
                    <v-col cols="12" class="py-0 vig-card-header-mini">
                      <span class="sub-text pull-left">! ! ! <b>EXAMPLE WAGER</b> ! ! !</span>
                      <span class="sub-text pull-right">{{ today }}</span>
                    </v-col>
                    <v-col cols="12" class="py-0 vig-card-header-mini text-center">
                      <span class="sub-text text-center">+5.5 Minnesota Vikings @ Arizona Cardinals -5.5</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="py-0">
                  <v-row align="center" justify="space-between" class="offer py-0 my-0 ml-n1" no-gutters>
                    <v-col class="pr-2">
                      <v-row class="pa-0 ma-0">
                        <v-col cols="8" class="pa-0 ma-0">
                          <div class="vigzero-btn team robo offer-btn grey-btn" style="display: flex;">
                            <v-row align="center" justify="center" no-gutters class="ma-0 pa-0">
                              <v-col class="pa-0">
                                <strong>Vikings</strong>
                              </v-col>
                              <v-col cols="2" class="pa-0 line-font text-center"><span>+5.5</span></v-col>
                            </v-row>
                            <v-row class="my-0">
                              <v-col cols="12" class="py-0">
                                <span class="usern"> VigZero <StarRating :rating="5"></StarRating></span>
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row class="pa-0 ma-0">
                        <v-col cols="8" class="pa-0 ma-0">
                          <div class="vigzero-btn team robo offer-btn red-btn" style="display: flex;">
                            <v-row align="center" justify="center" no-gutters class="ma-0">
                              <v-col class="py-0">
                                <v-row justify="center" class="ma-0">
                                  <v-col cols="12" class="pa-0">
                                    <strong>Cardinals <span><v-icon class="icon white--text">favorite</v-icon></span></strong>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="2" class="line-font text-center pa-0 ma-0"><span>-5.5</span></v-col>
                            </v-row>
                          </div>
                        </v-col>
                        <v-col cols="4" class="text-center pa-0 ma-0" align-self="center">
                          <div>
                            <h1
                              class="cash my-0 py-0 text-right"
                              style="line-height: 0.9em;"
                            >$10</h1>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>

                  </v-row>
                </v-col>
              </v-row>
            </div>
            <!-- @update="update()"
              @acceptOffer="accepted" -->
            <offer-list
              v-else
              :offers="wagers"
              :section="'group_' + group_id"
            ></offer-list>
          </div>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col
          cols="12"
          class="py-0"
        >
          <div
            class="red-btn expander"
            style="width:100%"
            @click="showMessages = !showMessages"
          >
            Group Messaging<v-icon
              color="white"
              class="icon"
            >{{ showMessages ? 'arrow_drop_down' : 'arrow_drop_up' }}</v-icon>
          </div>
          <div
            class="pt-3"
            v-show="showMessages"
          >
            <group-messages
              :friendgroupId="group_id"
              @loaded="messagesLoaded = true"
              :demo="fakegroup"
            ></group-messages>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="groupObject.allowmemberinvite">
        <v-col>
          <div
            class="red-btn expander"
            style="width:100%"
            @click="showInvite = !showInvite"
          >
          Invite members<v-icon
              color="white"
              class="icon"
            >{{ showInvite ? 'arrow_drop_down' : 'arrow_drop_up' }}</v-icon>

          </div>
          <div
            class="pt-3"
            v-show="showInvite"
          >
          <GroupInvite v-if="groupObject && members" :group="groupObject" :members="members" @refresh="init"></GroupInvite>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import GroupInvite from '@/components/groups/Invite'
import CommunityService from '@/services/CommunityService'
import OfferList from '@/components/offer/List'
import GroupMessages from '@/components/chat/GroupMessages'
import StarRating from '@/components/wagers/StarRating'
import Back from '@/components/widget/Back'
import UserService from '@/services/UserService'
import moment from 'moment'
import { bus } from '@/main'

export default {
  name: 'GroupInfo',
  components: {
    OfferList,
    GroupMessages,
    Back,
    GroupInvite,
    StarRating
  },
  props: {
    group_id: {
      type: String,
      default: '0'
    }
  },
  data: function () {
    return {
      wagers: null,
      messages: null,
      contests: null,
      contestsavailable: null,
      showWagers: true,
      showMessages: true,
      showInvite: true,
      showContests: false,
      showContestsAvailable: false,
      groupname: '',
      adminname: '',
      wagersLoaded: false,
      messagesLoaded: false,
      totalGroups: 0,
      groupObject: {},
      members: [],
      fakegroup: false
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    async init () {
      const user = UserService.getUserObject()
      if (!user && +this.group_id === 0) {
        this.fakegroup = true
        this.totalGroups = 2
        this.groupname = 'Example Group'
        this.adminname = 'VigZero'
        return
      }
      CommunityService.groupInfoInit(+this.group_id).then(
        async (response) => {
          this.groupname = response.data.name
          this.adminname = response.data.adminname
          this.wagers = response.data.wagers.results
          this.contests = response.data.contests
          this.totalGroups = response.data.totalgroups
          if (this.contests && this.contests.length > 0) {
            this.showContests = true
          }
          this.contestsavailable = response.data.contestsavailable
          if (this.contestsavailable && this.contestsavailable.length > 0) {
            this.showContestsAvailable = true
          }
          this.wagersLoaded = true
          this.groupObject = {}
          this.groupObject.allowmemberinvite = response.data.allowmemberinvite
          this.groupObject.id = this.group_id
          await this.getMembers()
        },
        (err) => {
          var error = err.response.data
          this.$router.push('/dashboard/group', () => {
            this.$notify({
              group: 'errors',
              title: error.errors[0],
              text: error.error,
              type: 'warn'
            })
          })
        }
      )
    },
    async getMembers () {
      var members = []
      CommunityService.getmembers(+this.group_id).then(
        (response) => {
          response.data.forEach(element => {
            element.admin = element.isadmin
            element.delete = false
            element.cantake = element.cantake === 1
            element.canoffer = element.canoffer === 1
            element.caninvite = element.caninvite === 1
            element.isadmin = element.isadmin === 1
            element.wins = element.wins ? element.wins : 0
            element.losses = element.losses ? element.losses : 0
            element.ties = element.ties ? element.ties : 0
            element.winperc = (isNaN(element.winperc) || element.winperc == null) ? '-' : element.winperc + '%'
            members.push(element)
          })
          this.members = members
        }
      )
    },

    notLoggedInModal: function () {
      bus.$emit('notLoggedInModal')
    }
  },
  computed: {
    display: function () {
      return this.wagersLoaded && this.messagesLoaded
    },

    today: function () {
      return moment().add(1, 'd').format('YYYY-MM-DD') + ' 1:00 PM'
    }
  }
}
</script>
