<style scoped>
  .row-dark {
    background-color: #ebebeb;
  }
</style>
<template>
  <div>
    <div
      v-if="user_id && user_id != 0 && userdata"
      style="font-size: 1.1rem"
    >
      <v-row class="mb-0 mt-n3 stats-banner">
        <v-col cols="7" class="py-0 white--text robo">
          {{ userdata.nickname }}
        </v-col>
        <v-col cols="5" class="py-0">
          <v-rating
            length="5"
            v-model="userdata.rating.rating"
            half-increments
            readonly
            class="pull-right"
            v-if="userdata.rating.rating > 0"
          >
            <template v-slot:item="props">
              <v-icon
                :color="(props.isFilled || props.isHalfFilled) ? 'yellow darken-2' : 'grey lighten-1'"
                class="pa-0"
              >
                {{ (props.isHalfFilled) ? 'star_half' : 'grade' }}
              </v-icon>
            </template>
          </v-rating>
          <span v-else>
          </span>
        </v-col>
      </v-row>
      <div
        style="font-size: 1.1rem"
        class="my-0 py-0"
      >

        <v-card class="pa-4 mt-4">
          <v-card-title class="headline font-weight-bold">
            Initiate Deposit
          </v-card-title>
          <v-card-text>
            VigZero not only saves you with zero vigorish, we NOW save you 3% on deposits as it's free to deposit with us. We are using Venmo in order to fund your account, so make your deposit now.
            <br><br>
            <strong>How to make a deposit to VigZero:</strong>
            <ol>
              <li>
                Hit 'Deposit' and record how much you are going to send to @VigZero via Venmo.
                <v-btn
                  class="white--text mt-2 mb-2"
                  style="font-size: 2rem; padding: 24px 24px; background-color: #4caf50; color: white; width: 100%;"
                  @click="openDialog"
                >
                  Deposit
                </v-btn>
              </li>
              <li>Once we receive verification from Venmo, your account we be in good standing.</li>
              <li>Prior to verification, you can make offers and/or take wagers.  Please note that if any expected deposits are not made by game time, your wagers are subject to be voided.</li>
              <li>Withdraw requests can be made at any time.</li>
            </ol>
            We will notify you every step of the way.
          </v-card-text>
        </v-card>
      </div>

      <!-- Withdraw Button -->
      <v-btn color="red darken-2" style="color: whitesmoke;" class="mt-4 mb-4" @click="openWithdrawDialog()" :disabled="!canWithdraw" :class="{'disabled-button': !canWithdraw}">
        Request Withdrawal
      </v-btn>

      <!-- Withdraw Modal -->
      <v-dialog v-model="showWithdrawDialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="headline">Request Withdrawal</v-card-title>
          <v-card-text>
            VigZero will transfer your withdrawal amount to your Venmo account of record {{ userdata.venmoHandle }}.
            Available Balance: {{ userdata.balance | money }}.
            Please allow up to 72 hours for the transfer.
            <v-text-field
              v-model="withdrawAmount"
              label="Withdrawal Amount"
              prepend-icon="mdi-cash"
              type="text"
              class="mt-4"
              @input="formatAmount"
              :rules="[validateAmount, validateMoney]"
              @blur="fixCents"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="submitWithdrawal()">Submit</v-btn>
            <v-btn color="red darken-1" text @click="closeWithdrawDialog()">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="px-2">
        <v-row
          class="black--text my-0 row-dark"
          justify="space-between"
        >
          <v-col class="text-left py-0">Vig Savings</v-col>
          <v-col class="text-right py-0">
            <span v-if="userdata.savings">{{ userdata.savings | money }}</span>
            <span v-else>---</span>
          </v-col>
        </v-row>

        <v-row class="black--text my-0" justify="space-between">
          <v-col class="text-left py-0">Total Betting Limit</v-col>
          <v-col class="text-right py-0">{{
            userdata.betlimit | money
          }}</v-col>
        </v-row>

        <v-row
          class="black--text my-0 row-dark"
          justify="space-between"
          v-if="userdata.available != null"
        >
          <v-col class="text-left my-0 py-0">Available to bet</v-col>
          <v-col class="text-right my-0 py-0">{{
            userdata.available | money
          }}</v-col>
        </v-row>

        <v-row
          class="black--text my-0"
          justify="space-between"
        >
          <v-col class="text-left my-0 py-0">Pending Deposits</v-col>
          <v-col class="text-right my-0 py-0">{{
            userdata.pendingbalance | money
          }}</v-col>
        </v-row>

        <v-row
          class="black--text my-0 row-dark"
          justify="space-between"
        >
          <v-col class="text-left my-0 py-0">VZ Account Balance</v-col>
          <v-col class="text-right my-0 py-0">{{
            userdata.balance | money
          }}</v-col>
        </v-row>

        <v-row class="black--text my-0" justify="space-between">
          <v-col cols="10" class="text-left py-0"
            >Pending Withdrawals</v-col
          >
          <v-col class="text-right my-0 py-0">{{
            userdata.pendingwithdrawal | money
          }}</v-col>
        </v-row>

        <v-row class="black--text my-0 row-dark" justify="space-between">
          <v-col class="text-left py-0">Unsettled Losses</v-col>
          <v-col class="text-right py-0 text-red">{{
            userdata.debt | money | negative
          }}</v-col>
        </v-row>

        <v-row
          class="black--text my-0"
          justify="space-between"
        >
          <v-col class="text-left py-0">Awaiting Money</v-col>
          <v-col class="text-right py-0 text-green" v-if="userdata.payout != null">
            {{userdata.payout | money | positive}}
          </v-col>
          <v-col class="text-right my-0 py-0" v-else>
            ---
          </v-col>
        </v-row>

        <v-row
          class="black--text my-0 row-dark"
          justify="space-between"
          v-if="userdata.pendingbets != null"
        >
          <v-col class="text-left py-0">Pending Bets</v-col>
          <v-col class="text-right py-0">{{
            userdata.pendingbets | money
          }}</v-col>
        </v-row>
      </div>
    </div>
    <DepositModal
      :dialog="showDialog"
      @submit="handleDeposit"
      @close="closeDialog"
    />
  </div>
</template>
<script>
import UserService from '@/services/UserService'
import TransactionService from '@/services/TransactionService'
import DepositModal from '@/components/modal/DepositModal.vue'

export default {
  components: {
    DepositModal
  },
  data: function () {
    return {
      userdata: null,
      userid: 0,
      avatar: '',
      showDialog: false,
      showWithdrawDialog: false,
      withdrawAmount: 0,
      userBalanceNoPending: 0,
      pendingUserBalance: 0
    }
  },
  props: {
    user_id: {
      type: Number,
      default: -1
    }
  },
  computed: {
    canWithdraw () {
      return !(this.userBalanceNoPending === 0 || this.pendingUserBalance > 0)
    }
  },
  mounted: function () {
    if (this.user_id === -1) {
      const user = UserService.getUserObject()
      if (user && user.info) {
        this.userid = user.info.id
      }
    } else {
      this.userid = this.user_id
    }
    this.init()
    this.getBalances()
  },
  methods: {
    init: function () {
      this.$updateloading(1)
      if (!this.userid || this.userid <= 0) {
        return
      }
      UserService.getUserStats(this.userid).then(
        (response) => {
          this.userdata = response.data
        },
        (err) => {
          console.log(err)
        }
      ).finally(
        () => {
          this.$updateloading(-1)
        }
      )
    },
    async getBalances () {
      this.$updateloading(1)
      await TransactionService.getUserCurrentBalanceNoPending(this.userid).then(
        (response) => {
          this.userBalanceNoPending = response.data
          console.log(this.userBalanceNoPending)
        },
        (error) => {
          console.dir(error)
        }
      )
      await TransactionService.getUserPendingBalance(this.userid).then(
        (response) => {
          this.pendingUserBalance = response.data
          console.log(this.pendingUserBalance)
        },
        (error) => {
          console.dir(error)
        }
      )
      this.$updateloading(-1)
    },
    openDialog: function () {
      this.showDialog = true
    },
    closeDialog: function () {
      this.showDialog = false
    },
    handleDeposit: function (amount) {
      this.$updateloading(1)
      const depositData = {
        user_id: this.userid,
        amount: amount
      }
      TransactionService.addDeposit(depositData).then(
        (response) => {
          console.log(response)
          this.$notify({
            group: 'notices',
            title: 'Success',
            text: 'Deposit request has been submitted successfully',
            type: 'success'
          })
          // URLs for Venmo
          const webURL = `https://venmo.com/vigzero?txn=pay&amount=${amount}&audience=private&note=VZ${response.data.transaction_id}`
          const venmoURL = `venmo://paycharge?txn=pay&recipients=vigzero&amount=${amount}&note=VZ${response.data.transaction_id}`
          const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
          if (isIOS) {
            // Attempt to open the Venmo app
            window.location.href = venmoURL
            setTimeout(() => {
              window.open(webURL, '_blank')
            }, 1500)
          } else {
            // Open the web URL directly for non-iOS devices
            window.open(webURL, '_blank')
          }
          // window.open(webURL, '_blank')
        },
        (err) => {
          console.error('Deposit error:', err)
          this.$notify({
            group: 'notices',
            title: 'Error',
            text: 'Failed to submit deposit request',
            type: 'danger'
          })
        }
      ).finally(
        () => {
          this.closeDialog()
          this.$updateloading(-1)
        }
      )
    },
    formatAmount () {
      let numericValue = this.withdrawAmount.replace(/[^0-9.]/g, '')
      const parts = numericValue.split('.')
      if (parts.length > 2) {
        numericValue = parts[0] + '.' + parts.slice(1).join('')
      }
      this.withdrawAmount = numericValue
    },
    fixCents () {
      if (this.withdrawAmount.includes('.')) {
        const parts = this.withdrawAmount.split('.')
        if (parts[1].length === 0) {
          this.withdrawAmount += '00'
        } else if (parts[1].length === 1) {
          this.withdrawAmount += '0'
        }
      } else {
        this.withdrawAmount += '.00'
      }
    },
    validateAmount (value) {
      return /^[0-9]+(\.[0-9]{1,2})?$/.test(value) || 'Invalid amount format'
    },
    validateMoney (value) {
      return parseFloat(value) > 0 || 'Amount must be greater than zero'
    },
    openWithdrawDialog () {
      this.showWithdrawDialog = true
    },
    closeWithdrawDialog () {
      this.showWithdrawDialog = false
      this.withdrawAmount = 0
    },
    submitWithdrawal () {
      // this.$refs.withdrawalAmount.validate()
      if (this.withdrawAmount <= 0) {
        alert('The minimum deposit amount is greater than $0.')
        return
      }
      console.log(this.userdata.balance)
      console.log(this.withdrawAmount)
      console.log(parseFloat(this.withdrawAmount) > parseFloat(this.userdata.balance))
      if (parseFloat(this.withdrawAmount) > parseFloat(this.userdata.balance)) {
        alert('Withdrawal amount exceeds available balance.')
        return
      }
      this.$updateloading(1)
      const withdrawData = {
        user_id: this.userid,
        amount: this.withdrawAmount
      }
      TransactionService.requestWithdrawal(withdrawData).then(
        response => {
          this.userdata.balance -= this.withdrawAmount
          this.closeWithdrawDialog()
          this.$notify({
            group: 'notices',
            title: 'Success',
            text: 'Withdrawal processed successfully',
            type: 'success'
          })
        },
        error => {
          console.error('Withdrawal error:', error)
          this.closeWithdrawDialog()
          this.$notify({
            group: 'notices',
            title: 'Withdrawal Failed',
            text: 'There was an issue processing your withdrawal.',
            type: 'error'
          })
        }
      ).finally(() => {
        this.$updateloading(-1)
      })
    },
    winPercentColor: function (data) {
      var color = 'text-red'
      if (data > 34 && data < 65) {
        color = 'text-warn'
      }
      if (data > 64) {
        color = 'text-green'
      }
      return color
    },
    getAvatar: function () {
      if (!this.userdata.avatar) {
        return null
      }
      if (this.userdata.avatar.thumbnails && this.userdata.avatar.thumbnails.small) {
        return this.userdata.avatar.thumbnails.small
      } else {
        return this.userdata.avatar.avatar // use the full size image, thumbnails were not created
      }
    }
  },
  filters: {
    money: function (value) {
      if (!value) return '$0'
      else if (value < 0) return '-$' + (value * -1)
      return '$' + value
    },
    positive: function (val) {
      if (!val) return '$0'
      return '+ ' + val
    },
    negative: function (val) {
      if (!val) return '$0'
      return '- ' + val
    }
  }
}
</script>
<style>
.wrap-link {
  word-wrap: break-word;
  white-space: normal;
}
.v-btn.disabled-button {
  background-color: #ef9a9a !important;; /* Light red background */
  color: white !important;; /* White text color */
  opacity: 1; /* Full opacity to make the button visible */
}
</style>
