var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", [
    _c("path", {
      attrs: {
        d:
          "M257.88,246.91v-1.82H257v1.82a1.1,1.1,0,0,0-.34,1.74,1.08,1.08,0,0,0,1.82-.77A1.07,1.07,0,0,0,257.88,246.91Z",
        transform: "translate(-245.01 -245.09)"
      }
    }),
    _c("path", {
      attrs: {
        d:
          "M249.75,246.91v-1.82h-.85v1.82a1.1,1.1,0,0,0-.34,1.74,1.07,1.07,0,1,0,1.19-1.74Z",
        transform: "translate(-245.01 -245.09)"
      }
    }),
    _c("rect", { attrs: { y: "0.94", width: "16.76", height: "4.46" } }),
    _c("rect", {
      staticClass: "cls-2",
      attrs: { x: "4.28", y: "11.13", width: "1.81", height: "1.81" }
    }),
    _c("rect", {
      staticClass: "cls-2",
      attrs: { x: "4.28", y: "8.24", width: "1.81", height: "1.81" }
    }),
    _c("rect", {
      staticClass: "cls-2",
      attrs: { x: "4.28", y: "14.02", width: "1.81", height: "1.81" }
    }),
    _c("rect", {
      staticClass: "cls-2",
      attrs: { x: "7.47", y: "14.02", width: "1.81", height: "1.81" }
    }),
    _c("rect", {
      staticClass: "cls-2",
      attrs: { x: "1.08", y: "11.13", width: "1.81", height: "1.81" }
    }),
    _c("rect", {
      staticClass: "cls-2",
      attrs: { x: "13.86", y: "11.13", width: "1.81", height: "1.81" }
    }),
    _c("rect", {
      staticClass: "cls-2",
      attrs: { x: "13.86", y: "8.24", width: "1.81", height: "1.81" }
    }),
    _c("rect", {
      staticClass: "cls-2",
      attrs: { x: "1.08", y: "14.02", width: "1.81", height: "1.81" }
    }),
    _c("rect", {
      staticClass: "cls-2",
      attrs: { x: "10.67", y: "11.13", width: "1.81", height: "1.81" }
    }),
    _c("rect", {
      staticClass: "cls-2",
      attrs: { x: "7.47", y: "11.13", width: "1.81", height: "1.81" }
    }),
    _c("rect", {
      staticClass: "cls-2",
      attrs: { x: "10.67", y: "8.24", width: "1.81", height: "1.81" }
    }),
    _c("rect", {
      staticClass: "cls-2",
      attrs: { x: "7.47", y: "8.24", width: "1.81", height: "1.81" }
    }),
    _c("rect", {
      staticClass: "cls-2",
      attrs: { x: "10.67", y: "14.02", width: "1.81", height: "1.81" }
    }),
    _c("path", {
      attrs: {
        d:
          "M261.77,259.62v-8.47H245v11.94H258.4v0l.11-.12v-3.32Zm-2.89-6.3h1.81v1.81h-1.81Zm0,2.89h1.81V258h-1.81Zm-11,4.71H246.1v-1.81h1.81Zm0-2.9H246.1v-1.81h1.81Zm3.19,2.9h-1.81v-1.81h1.81Zm0-2.9h-1.81v-1.81h1.81Zm0-2.89h-1.81v-1.81h1.81Zm3.2,5.79h-1.81v-1.81h1.81Zm0-2.9h-1.81v-1.81h1.81Zm0-2.89h-1.81v-1.81h1.81Zm3.19,5.79h-1.81v-1.81h1.81Zm0-2.9h-1.81v-1.81h1.81Zm0-2.89h-1.81v-1.81h1.81Z",
        transform: "translate(-245.01 -245.09)"
      }
    }),
    _c("polygon", {
      attrs: {
        points:
          "13.38 17.96 13.38 17.99 16.76 17.99 16.76 14.54 13.38 17.93 13.38 17.96"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }