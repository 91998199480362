<template>
  <v-data-table
    :headers="headers"
    :items="transactions"
    :items-per-page="itemsPerPage"
    :loading="loading"
    loading-text="Loading..."
    :server-items-length="totalItems"
    @pagination="onPagination"
    mobile-breakpoint="0"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Transaction History</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <!-- <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field> -->
      </v-toolbar>
    </template>
    <template v-slot:item.amount="{ item }">
      <span class="right-align">{{ formatAmount(item.amount) }}</span>
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip :color="statusColor(item.status)" dark>
        {{ item.status }}
      </v-chip>
    </template>
    <template v-slot:item.type="{ item }">
      <v-chip :color="typeColor(item.type)" dark>
        {{ item.type }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    transactions: Array,
    totalItems: Number,
    itemsPerPage: Number,
    loading: Boolean,
    search: String
  },
  data () {
    return {
      headers: [
        // { text: 'ID', value: 'id', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Created', value: 'created', sortable: false },
        { text: 'Updated', value: 'updated', sortable: false }
      ]
    }
  },
  methods: {
    statusColor (status) {
      if (status === 'approved') return 'green'
      if (status === 'rejected') return 'red'
      return 'grey'
    },
    typeColor (type) {
      if (type === 'deposit') return 'blue'
      if (type === 'withdrawal') return 'orange'
      if (type === 'refund') return 'purple'
      return 'grey'
    },
    onPagination (pagination) {
      this.$emit('pagination', pagination)
    },
    formatAmount (amount) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
    }
  }
}
</script>
<style scoped>
.right-align {
  text-align: right;
  display: block; /* Ensures the text alignment affects the line */
  width: 100%; /* Takes full width to push content to the right */
}
</style>
