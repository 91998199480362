var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.userdata
    ? _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "py-0 my-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 my-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "py-0 my-0" },
                        [
                          _c("v-col", { staticClass: "py-0 my-0" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-avatar",
                                  { attrs: { color: "grey", size: "42" } },
                                  [
                                    _vm.showAvatar
                                      ? _c("img", {
                                          attrs: { src: _vm.avatar }
                                        })
                                      : _c(
                                          "span",
                                          {
                                            staticClass: "white--text headline"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("initial")(
                                                  _vm.userdata.nickname
                                                )
                                              )
                                            )
                                          ]
                                        )
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("v-col", { staticClass: "py-0 my-0" }, [
                            _c(
                              "div",
                              [
                                !_vm.showEditAvatar
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            _vm.showEditAvatar = true
                                          }
                                        }
                                      },
                                      [_c("v-icon", [_vm._v(" edit ")])],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.showEditAvatar
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            _vm.showEditAvatar = false
                                          }
                                        }
                                      },
                                      [_c("v-icon", [_vm._v(" close ")])],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _c("v-col")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "py-0 my-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0 my-0" },
                            [
                              _c("v-rating", {
                                staticClass: "pull-right",
                                attrs: {
                                  length: "5",
                                  readonly: "",
                                  "half-increments": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function(props) {
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "pa-0",
                                              attrs: {
                                                color:
                                                  props.isFilled ||
                                                  props.isHalfFilled
                                                    ? "yellow darken-2"
                                                    : "grey lighten-1"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.isHalfFilled
                                                      ? "star_half"
                                                      : "grade"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1930032056
                                ),
                                model: {
                                  value: _vm.userdata.rating.rating,
                                  callback: function($$v) {
                                    _vm.$set(_vm.userdata.rating, "rating", $$v)
                                  },
                                  expression: "userdata.rating.rating"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showEditAvatar
                ? _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-file-input", {
                            ref: "upload",
                            attrs: {
                              accept: "image/*",
                              label: "Profile Picture"
                            },
                            on: { change: _vm.setFileUpload },
                            model: {
                              value: _vm.file.upload,
                              callback: function($$v) {
                                _vm.$set(_vm.file, "upload", $$v)
                              },
                              expression: "file.upload"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.saveAvatar()
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("save")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "py-0 my-0" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        { staticClass: "px-4", attrs: { elevation: "2" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-card-title", [_vm._v("Payment Methods")]),
                              _vm._l(_vm.userdata.paymentmethods, function(pm) {
                                return _c(
                                  "v-card-text",
                                  { key: pm.paymentmethod_id },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("v-card-subtitle", [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "link",
                                              attrs: {
                                                href:
                                                  "https://help.venmo.com/hc/en-us/articles/209690068-How-to-Sign-Up",
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " Create a Venmo account here "
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("v-card-subtitle", [
                                          _vm._v(
                                            "Update your Venmo handle below"
                                          )
                                        ]),
                                        _vm._l(pm.details, function(d) {
                                          return _c(
                                            "div",
                                            { key: d.name },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "text--black",
                                                attrs: {
                                                  label: "Venmo Handle",
                                                  type: "text",
                                                  prefix: "@",
                                                  autocomplete: "venmo",
                                                  readonly: !_vm.editpm,
                                                  "error-messages": _vm.getErrors(
                                                    "venmohandle"
                                                  )
                                                },
                                                on: {
                                                  keydown: function($event) {
                                                    return _vm.preventInvalidVenmoHandle(
                                                      $event
                                                    )
                                                  },
                                                  input: function($event) {
                                                    _vm.errors.venmohandle = ""
                                                    _vm.invalidVenmoHandle = null
                                                  },
                                                  blur: function($event) {
                                                    return _vm.$v.userdata.venmohandle.$touch()
                                                  }
                                                },
                                                model: {
                                                  value: d.value,
                                                  callback: function($$v) {
                                                    _vm.$set(d, "value", $$v)
                                                  },
                                                  expression: "d.value"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }),
                                        _c(
                                          "p",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.editpm,
                                                expression: "editpm"
                                              }
                                            ],
                                            staticClass:
                                              "red--text font-weight-bold"
                                          },
                                          [
                                            _vm._v(
                                              "* ALERT: Providing an incorrect Venmo handle could lead to lost winnings. Neither the payor nor VigZero are responsible when the wrong Venmo handle is provided."
                                            )
                                          ]
                                        )
                                      ],
                                      2
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _c(
                            "v-row",
                            { staticClass: "py-0 my-0" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0 my-0" },
                                [
                                  _c(
                                    "v-card-actions",
                                    [
                                      !_vm.editpm
                                        ? _c(
                                            "v-row",
                                            {
                                              staticClass: "py-0 my-0",
                                              attrs: { justify: "end" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: _vm.editpayments
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [_vm._v("edit")])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.editpm
                                        ? _c(
                                            "v-row",
                                            {
                                              staticClass: "py-0 my-0",
                                              attrs: { justify: "end" }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.changepmmessage)
                                                )
                                              ]),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: _vm.updatepayment
                                                  }
                                                },
                                                [_vm._v(" Save ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: _vm.editpayments
                                                  }
                                                },
                                                [_vm._v(" Cancel ")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0 py-0" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.validateUser($event)
                            }
                          },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "px-2", attrs: { elevation: "2" } },
                            [
                              _c("v-card-title", [
                                _vm._v("Account Information")
                              ]),
                              _c(
                                "v-card-text",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "First Name",
                                      type: "text",
                                      id: "first-name",
                                      name: "first-name",
                                      autocomplete: "given-name",
                                      readonly: !_vm.editing,
                                      "error-messages": _vm.getErrors(
                                        "firstname"
                                      )
                                    },
                                    on: {
                                      blur: function($event) {
                                        return _vm.$v.userdata.firstname.$touch()
                                      }
                                    },
                                    model: {
                                      value: _vm.userdata.firstname,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userdata, "firstname", $$v)
                                      },
                                      expression: "userdata.firstname"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Last Name",
                                      type: "text",
                                      id: "last-name",
                                      name: "last-name",
                                      autocomplete: "given-name",
                                      readonly: !_vm.editing,
                                      "error-messages": _vm.getErrors(
                                        "lastname"
                                      )
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.$v.userdata.lastname.$touch()
                                      }
                                    },
                                    model: {
                                      value: _vm.userdata.lastname,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userdata, "lastname", $$v)
                                      },
                                      expression: "userdata.lastname"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Email",
                                      type: "text",
                                      id: "email",
                                      name: "email",
                                      autocomplete: "email",
                                      readonly: !_vm.editing,
                                      "error-messages": _vm.getErrors("email")
                                    },
                                    on: {
                                      blur: function($event) {
                                        return _vm.$v.userdata.email.$touch()
                                      }
                                    },
                                    model: {
                                      value: _vm.userdata.email,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userdata, "email", $$v)
                                      },
                                      expression: "userdata.email"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Nickname",
                                      type: "text",
                                      id: "nickname",
                                      name: "nickname",
                                      autocomplete: "nickname",
                                      readonly: !_vm.editing,
                                      "error-messages": _vm.getErrors(
                                        "nickname"
                                      )
                                    },
                                    on: {
                                      blur: function($event) {
                                        return _vm.$v.userdata.nickname.$touch()
                                      }
                                    },
                                    model: {
                                      value: _vm.userdata.nickname,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userdata, "nickname", $$v)
                                      },
                                      expression: "userdata.nickname"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Cell Phone",
                                      type: "text",
                                      id: "cellphone",
                                      name: "cellphone",
                                      autocomplete: "cellphone",
                                      readonly: true,
                                      "error-messages": _vm.getErrors(
                                        "cellphone"
                                      )
                                    },
                                    on: {
                                      blur: function($event) {
                                        return _vm.$v.userdata.cellphone.$touch()
                                      }
                                    },
                                    model: {
                                      value: _vm.userdata.cellphone,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userdata, "cellphone", $$v)
                                      },
                                      expression: "userdata.cellphone"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "py-0 my-0" },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "py-0 my-0" },
                                    [
                                      _c(
                                        "v-card-actions",
                                        [
                                          !_vm.editing
                                            ? _c(
                                                "v-row",
                                                { attrs: { justify: "end" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { icon: "" },
                                                      on: {
                                                        click: _vm.editInfo
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("edit")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.editing
                                            ? _c(
                                                "v-row",
                                                { attrs: { justify: "end" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.changeinfomessage
                                                      )
                                                    )
                                                  ]),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        type: "submit"
                                                      }
                                                    },
                                                    [_vm._v("Submit")]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { text: "" },
                                                      on: {
                                                        click: _vm.editInfo
                                                      }
                                                    },
                                                    [_vm._v("Cancel")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "py-0 my-0" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        { staticClass: "px-2" },
                        [
                          _c("v-card-title", [_vm._v("Change Password")]),
                          _c(
                            "v-card-text",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Current Password",
                                  id: "oldpassword",
                                  name: "oldpassword",
                                  autocomplete: "oldpassword",
                                  disabled: !_vm.editpassword,
                                  "append-icon": _vm.showoldpassword
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.showoldpassword
                                    ? "text"
                                    : "password",
                                  "error-messages": _vm.getPasswordErrors(
                                    "old"
                                  ),
                                  required: ""
                                },
                                on: {
                                  "click:append": function($event) {
                                    _vm.showoldpassword = !_vm.showoldpassword
                                  },
                                  blur: function($event) {
                                    return _vm.$v.changepassword.old.$touch()
                                  }
                                },
                                model: {
                                  value: _vm.changepassword.old,
                                  callback: function($$v) {
                                    _vm.$set(_vm.changepassword, "old", $$v)
                                  },
                                  expression: "changepassword.old"
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "New Password",
                                  id: "newpassword",
                                  name: "newpassword",
                                  autocomplete: "newpassword",
                                  disabled: !_vm.editpassword,
                                  "append-icon": _vm.shownewpassword
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.shownewpassword
                                    ? "text"
                                    : "password",
                                  "error-messages": _vm.getPasswordErrors(
                                    "password"
                                  ),
                                  required: ""
                                },
                                on: {
                                  "click:append": function($event) {
                                    _vm.shownewpassword = !_vm.shownewpassword
                                  },
                                  blur: function($event) {
                                    return _vm.$v.changepassword.password.$touch()
                                  }
                                },
                                model: {
                                  value: _vm.changepassword.password,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.changepassword,
                                      "password",
                                      $$v
                                    )
                                  },
                                  expression: "changepassword.password"
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Confirm New Password",
                                  id: "comfirmnew",
                                  name: "comfirmnew",
                                  autocomplete: "confirmnew",
                                  disabled: !_vm.editpassword,
                                  "append-icon": _vm.showconfirmpassword
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.showconfirmpassword
                                    ? "text"
                                    : "password",
                                  "error-messages": _vm.getPasswordErrors(
                                    "confirmpassword"
                                  ),
                                  required: ""
                                },
                                on: {
                                  "click:append": function($event) {
                                    _vm.showconfirmpassword = !_vm.showconfirmpassword
                                  },
                                  blur: function($event) {
                                    return _vm.$v.changepassword.confirmpassword.$touch()
                                  }
                                },
                                model: {
                                  value: _vm.changepassword.confirmpassword,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.changepassword,
                                      "confirmpassword",
                                      $$v
                                    )
                                  },
                                  expression: "changepassword.confirmpassword"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card-actions",
                                    [
                                      !_vm.editpassword
                                        ? _c(
                                            "v-row",
                                            { attrs: { justify: "end" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.editpassword = true
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [_vm._v("edit")])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.editpassword
                                        ? _c(
                                            "v-row",
                                            { attrs: { justify: "end" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.reseteditpassword()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("clear")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.validatePw()
                                                    }
                                                  }
                                                },
                                                [_vm._v("Submit")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "green--text" }, [
                                    _vm._v(_vm._s(_vm.successmessage))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }