var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.user_id && _vm.user_id != 0 && _vm.userdata
        ? _c(
            "div",
            { staticStyle: { "font-size": "1.1rem" } },
            [
              _c(
                "v-row",
                { staticClass: "mb-0 mt-n3 stats-banner" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0 white--text robo",
                      attrs: { cols: "7" }
                    },
                    [_vm._v(" " + _vm._s(_vm.userdata.nickname) + " ")]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "5" } },
                    [
                      _vm.userdata.rating.rating > 0
                        ? _c("v-rating", {
                            staticClass: "pull-right",
                            attrs: {
                              length: "5",
                              "half-increments": "",
                              readonly: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function(props) {
                                    return [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "pa-0",
                                          attrs: {
                                            color:
                                              props.isFilled ||
                                              props.isHalfFilled
                                                ? "yellow darken-2"
                                                : "grey lighten-1"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                props.isHalfFilled
                                                  ? "star_half"
                                                  : "grade"
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1930032056
                            ),
                            model: {
                              value: _vm.userdata.rating.rating,
                              callback: function($$v) {
                                _vm.$set(_vm.userdata.rating, "rating", $$v)
                              },
                              expression: "userdata.rating.rating"
                            }
                          })
                        : _c("span")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "my-0 py-0",
                  staticStyle: { "font-size": "1.1rem" }
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-4 mt-4" },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "headline font-weight-bold" },
                        [_vm._v(" Initiate Deposit ")]
                      ),
                      _c("v-card-text", [
                        _vm._v(
                          " VigZero not only saves you with zero vigorish, we NOW save you 3% on deposits as it's free to deposit with us. We are using Venmo in order to fund your account, so make your deposit now. "
                        ),
                        _c("br"),
                        _c("br"),
                        _c("strong", [
                          _vm._v("How to make a deposit to VigZero:")
                        ]),
                        _c("ol", [
                          _c(
                            "li",
                            [
                              _vm._v(
                                " Hit 'Deposit' and record how much you are going to send to @VigZero via Venmo. "
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "white--text mt-2 mb-2",
                                  staticStyle: {
                                    "font-size": "2rem",
                                    padding: "24px 24px",
                                    "background-color": "#4caf50",
                                    color: "white",
                                    width: "100%"
                                  },
                                  on: { click: _vm.openDialog }
                                },
                                [_vm._v(" Deposit ")]
                              )
                            ],
                            1
                          ),
                          _c("li", [
                            _vm._v(
                              "Once we receive verification from Venmo, your account we be in good standing."
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "Prior to verification, you can make offers and/or take wagers. Please note that if any expected deposits are not made by game time, your wagers are subject to be voided."
                            )
                          ]),
                          _c("li", [
                            _vm._v("Withdraw requests can be made at any time.")
                          ])
                        ]),
                        _vm._v(" We will notify you every step of the way. ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mt-4 mb-4",
                  class: { "disabled-button": !_vm.canWithdraw },
                  staticStyle: { color: "whitesmoke" },
                  attrs: { color: "red darken-2", disabled: !_vm.canWithdraw },
                  on: {
                    click: function($event) {
                      return _vm.openWithdrawDialog()
                    }
                  }
                },
                [_vm._v(" Request Withdrawal ")]
              ),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "600px" },
                  model: {
                    value: _vm.showWithdrawDialog,
                    callback: function($$v) {
                      _vm.showWithdrawDialog = $$v
                    },
                    expression: "showWithdrawDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", { staticClass: "headline" }, [
                        _vm._v("Request Withdrawal")
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _vm._v(
                            " VigZero will transfer your withdrawal amount to your Venmo account of record " +
                              _vm._s(_vm.userdata.venmoHandle) +
                              ". Available Balance: " +
                              _vm._s(_vm._f("money")(_vm.userdata.balance)) +
                              ". Please allow up to 72 hours for the transfer. "
                          ),
                          _c("v-text-field", {
                            staticClass: "mt-4",
                            attrs: {
                              label: "Withdrawal Amount",
                              "prepend-icon": "mdi-cash",
                              type: "text",
                              rules: [_vm.validateAmount, _vm.validateMoney]
                            },
                            on: { input: _vm.formatAmount, blur: _vm.fixCents },
                            model: {
                              value: _vm.withdrawAmount,
                              callback: function($$v) {
                                _vm.withdrawAmount = $$v
                              },
                              expression: "withdrawAmount"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "green darken-1", text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.submitWithdrawal()
                                }
                              }
                            },
                            [_vm._v("Submit")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "red darken-1", text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeWithdrawDialog()
                                }
                              }
                            },
                            [_vm._v("Close")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "px-2" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "black--text my-0 row-dark",
                      attrs: { justify: "space-between" }
                    },
                    [
                      _c("v-col", { staticClass: "text-left py-0" }, [
                        _vm._v("Vig Savings")
                      ]),
                      _c("v-col", { staticClass: "text-right py-0" }, [
                        _vm.userdata.savings
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("money")(_vm.userdata.savings))
                              )
                            ])
                          : _c("span", [_vm._v("---")])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "black--text my-0",
                      attrs: { justify: "space-between" }
                    },
                    [
                      _c("v-col", { staticClass: "text-left py-0" }, [
                        _vm._v("Total Betting Limit")
                      ]),
                      _c("v-col", { staticClass: "text-right py-0" }, [
                        _vm._v(_vm._s(_vm._f("money")(_vm.userdata.betlimit)))
                      ])
                    ],
                    1
                  ),
                  _vm.userdata.available != null
                    ? _c(
                        "v-row",
                        {
                          staticClass: "black--text my-0 row-dark",
                          attrs: { justify: "space-between" }
                        },
                        [
                          _c("v-col", { staticClass: "text-left my-0 py-0" }, [
                            _vm._v("Available to bet")
                          ]),
                          _c("v-col", { staticClass: "text-right my-0 py-0" }, [
                            _vm._v(
                              _vm._s(_vm._f("money")(_vm.userdata.available))
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    {
                      staticClass: "black--text my-0",
                      attrs: { justify: "space-between" }
                    },
                    [
                      _c("v-col", { staticClass: "text-left my-0 py-0" }, [
                        _vm._v("Pending Deposits")
                      ]),
                      _c("v-col", { staticClass: "text-right my-0 py-0" }, [
                        _vm._v(
                          _vm._s(_vm._f("money")(_vm.userdata.pendingbalance))
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "black--text my-0 row-dark",
                      attrs: { justify: "space-between" }
                    },
                    [
                      _c("v-col", { staticClass: "text-left my-0 py-0" }, [
                        _vm._v("VZ Account Balance")
                      ]),
                      _c("v-col", { staticClass: "text-right my-0 py-0" }, [
                        _vm._v(_vm._s(_vm._f("money")(_vm.userdata.balance)))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "black--text my-0",
                      attrs: { justify: "space-between" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-left py-0",
                          attrs: { cols: "10" }
                        },
                        [_vm._v("Pending Withdrawals")]
                      ),
                      _c("v-col", { staticClass: "text-right my-0 py-0" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("money")(_vm.userdata.pendingwithdrawal)
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "black--text my-0 row-dark",
                      attrs: { justify: "space-between" }
                    },
                    [
                      _c("v-col", { staticClass: "text-left py-0" }, [
                        _vm._v("Unsettled Losses")
                      ]),
                      _c("v-col", { staticClass: "text-right py-0 text-red" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("negative")(
                              _vm._f("money")(_vm.userdata.debt)
                            )
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "black--text my-0",
                      attrs: { justify: "space-between" }
                    },
                    [
                      _c("v-col", { staticClass: "text-left py-0" }, [
                        _vm._v("Awaiting Money")
                      ]),
                      _vm.userdata.payout != null
                        ? _c(
                            "v-col",
                            { staticClass: "text-right py-0 text-green" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("positive")(
                                      _vm._f("money")(_vm.userdata.payout)
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _c("v-col", { staticClass: "text-right my-0 py-0" }, [
                            _vm._v(" --- ")
                          ])
                    ],
                    1
                  ),
                  _vm.userdata.pendingbets != null
                    ? _c(
                        "v-row",
                        {
                          staticClass: "black--text my-0 row-dark",
                          attrs: { justify: "space-between" }
                        },
                        [
                          _c("v-col", { staticClass: "text-left py-0" }, [
                            _vm._v("Pending Bets")
                          ]),
                          _c("v-col", { staticClass: "text-right py-0" }, [
                            _vm._v(
                              _vm._s(_vm._f("money")(_vm.userdata.pendingbets))
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("DepositModal", {
        attrs: { dialog: _vm.showDialog },
        on: { submit: _vm.handleDeposit, close: _vm.closeDialog }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }