<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 18 18"
    :aria-labelledby="name"
    role="presentation"
  >
    <title
      :id="name"
      lang="en"
    >{{ name }} icon</title>
    <g :fill="color">
      <icon-pending v-if="name == 'pending'"></icon-pending>
      <icon-bullhorn v-if="name == 'bullhorn'"></icon-bullhorn>
      <icon-best-value v-if="name == 'bestvalue'"></icon-best-value>
      <icon-favorite v-if="name == 'favorite'"></icon-favorite>
      <icon-group v-if="name == 'group'"></icon-group>
      <icon-schedule v-if="name == 'schedule'"></icon-schedule>
    </g>
  </svg>
</template>
<script>
import IconPending from '@/icons/IconPending'
import IconBullhorn from '@/icons/IconBullhorn'
import IconBestValue from '@/icons/IconBestValue'
import IconFavorite from '@/icons/IconFavorite'
import IconGroup from '@/icons/IconGroup'
import IconSchedule from '@/icons/IconSchedule'

export default {
  components: {
    IconPending,
    IconBullhorn,
    IconBestValue,
    IconFavorite,
    IconGroup,
    IconSchedule
  },
  props: {
    name: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
