var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-expansion-panels",
        [
          _c(
            "v-expansion-panel",
            [
              _c(
                "v-expansion-panel-header",
                { attrs: { "expand-icon": "mdi-menu-down" } },
                [
                  _c("h2", { staticClass: "ma-0 black--text" }, [
                    _vm._v("Entrants")
                  ])
                ]
              ),
              _c(
                "v-expansion-panel-content",
                { staticClass: "text-left py-3 px-0" },
                [
                  _c("v-simple-table", {
                    staticClass: "table-striped",
                    attrs: { height: "500px", "fixed-header": "", dense: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function() {
                          return [
                            _c("colgroup", [
                              _c("col", { attrs: { width: "0*" } }),
                              _c("col"),
                              _c("col", { attrs: { width: "0*" } }),
                              _vm.admin && _vm.contest.open
                                ? _c("col", { attrs: { width: "0*" } })
                                : _vm._e()
                            ]),
                            _c("thead", [
                              _c("tr", [
                                _c("th", [_vm._v("#")]),
                                _c("th", { staticClass: "px-0" }, [
                                  _vm._v("Nickname")
                                ]),
                                _c("th", [_vm._v("Paid")]),
                                _vm.admin && _vm.contest.open
                                  ? _c("th")
                                  : _vm._e()
                              ])
                            ]),
                            _c(
                              "tbody",
                              [
                                _vm._l(_vm.contest.entrants, function(e, i) {
                                  return _c(
                                    "tr",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.entryIsQualified(i),
                                          expression: "entryIsQualified(i)"
                                        }
                                      ],
                                      key: "q-" + i,
                                      class:
                                        e.user_id === _vm.userId
                                          ? "warning lighten-5"
                                          : ""
                                    },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "px-0 text-center",
                                          class: _vm.entryIsQualified(i)
                                            ? "entry-in"
                                            : "entry-out"
                                        },
                                        [_vm._v(_vm._s(i + 1))]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "px-1" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              class:
                                                e.user_id === _vm.userId
                                                  ? "font-weight-bold"
                                                  : ""
                                            },
                                            [_vm._v(_vm._s(e.nickname))]
                                          ),
                                          e.user_id === _vm.userId &&
                                          !_vm.admin &&
                                          _vm.contest.open
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.confirmRemoveEntry(
                                                        e
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "error" }
                                                    },
                                                    [_vm._v("mdi-close")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.admin && e.user_id !== _vm.userId
                                            ? _c("span", [
                                                _c("span", [
                                                  _vm._v(
                                                    " (" +
                                                      _vm._s(e.firstname) +
                                                      " " +
                                                      _vm._s(e.lastname) +
                                                      ")"
                                                  )
                                                ]),
                                                _c("br"),
                                                e.venmo
                                                  ? _c("span", [
                                                      _vm._v(" ("),
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href:
                                                              "//venmo.com/u/" +
                                                              e.venmo
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "@" +
                                                              _vm._s(e.venmo)
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(")")
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(" (No Venmo)")
                                                    ])
                                              ])
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "px-0 text-center" },
                                        [
                                          _vm.admin
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.togglePayment(
                                                        e
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: e.paid
                                                          ? "green accent-4"
                                                          : "red lighten-2"
                                                      }
                                                    },
                                                    [_vm._v("mdi-currency-usd")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: e.paid
                                                      ? "green accent-4"
                                                      : "red lighten-2"
                                                  }
                                                },
                                                [_vm._v("mdi-currency-usd")]
                                              )
                                        ],
                                        1
                                      ),
                                      _vm.admin && _vm.contest.open
                                        ? _c(
                                            "td",
                                            { staticClass: "px-0" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    title: "Remove"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.confirmRemoveEntry(
                                                        e
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "error" }
                                                    },
                                                    [_vm._v("mdi-delete")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                }),
                                _vm.hasUnqualifiedEntries
                                  ? _c(
                                      "tr",
                                      { staticClass: "error lighten-3" },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "entry-out",
                                            attrs: { colspan: "4" }
                                          },
                                          [
                                            _vm.contest.open
                                              ? _c("strong", [
                                                  _vm._v(
                                                    "Waiting List: need " +
                                                      _vm._s(
                                                        _vm.missingEntriesToQualify
                                                      ) +
                                                      " more for " +
                                                      _vm._s(_vm.nextThreshold)
                                                  )
                                                ])
                                              : _c("strong", [
                                                  _vm._v("Did not make the cut")
                                                ])
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._l(_vm.contest.entrants, function(e, i) {
                                  return _c(
                                    "tr",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.entryIsQualified(i),
                                          expression: "!entryIsQualified(i)"
                                        }
                                      ],
                                      key: "uq-" + i,
                                      class:
                                        e.user_id === _vm.userId
                                          ? "warning lighten-5"
                                          : ""
                                    },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "px-0 text-center",
                                          class: _vm.entryIsQualified(i)
                                            ? "entry-in"
                                            : "entry-out"
                                        },
                                        [_vm._v(_vm._s(i + 1))]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "px-1" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              class:
                                                e.user_id === _vm.userId
                                                  ? "font-weight-bold"
                                                  : ""
                                            },
                                            [_vm._v(_vm._s(e.nickname))]
                                          ),
                                          e.user_id === _vm.userId &&
                                          !_vm.admin &&
                                          _vm.contest.open
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.confirmRemoveEntry(
                                                        e
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "error" }
                                                    },
                                                    [_vm._v("mdi-close")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.admin && e.user_id !== _vm.userId
                                            ? _c("span", [
                                                _c("span", [
                                                  _vm._v(
                                                    " (" +
                                                      _vm._s(e.firstname) +
                                                      " " +
                                                      _vm._s(e.lastname) +
                                                      ")"
                                                  )
                                                ]),
                                                _c("br"),
                                                e.venmo
                                                  ? _c("span", [
                                                      _vm._v(" ("),
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href:
                                                              "//venmo.com/u/" +
                                                              e.venmo
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "@" +
                                                              _vm._s(e.venmo)
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(")")
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(" (No Venmo)")
                                                    ])
                                              ])
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "px-0 text-center" },
                                        [
                                          _vm.admin
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.togglePayment(
                                                        e
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: e.paid
                                                          ? "green accent-4"
                                                          : "red lighten-2"
                                                      }
                                                    },
                                                    [_vm._v("mdi-currency-usd")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: e.paid
                                                      ? "green accent-4"
                                                      : "red lighten-2"
                                                  }
                                                },
                                                [_vm._v("mdi-currency-usd")]
                                              )
                                        ],
                                        1
                                      ),
                                      _vm.admin && _vm.contest.open
                                        ? _c(
                                            "td",
                                            { staticClass: "px-0" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    title: "Remove"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.confirmRemoveEntry(
                                                        e
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "error" }
                                                    },
                                                    [_vm._v("mdi-delete")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                }),
                                _vm.contest.entries == 0
                                  ? _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-center",
                                          attrs: { colspan: "4" }
                                        },
                                        [_vm._v("No entries yet!")]
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c(
                    "v-row",
                    { staticClass: "mx-0" },
                    [
                      _c("v-col", { staticClass: "px-0" }, [
                        _c("strong", { staticClass: "sub-text black--text" }, [
                          _vm._v(_vm._s(_vm.contest.entries) + " "),
                          _vm.contest.minimum > 0 &&
                          _vm.contest.entries < _vm.contest.minimum
                            ? _c("span", [
                                _vm._v(
                                  "of " +
                                    _vm._s(_vm.contest.minimum) +
                                    " required"
                                )
                              ])
                            : _c("span", [
                                _vm.contest.maximum > 0
                                  ? _c("span", [
                                      _vm._v(
                                        "of " + _vm._s(_vm.contest.maximum)
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                          _vm._v(" spots")
                        ])
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "px-0", attrs: { align: "center" } },
                        [
                          _c(
                            "strong",
                            { staticClass: "sub-text black--text" },
                            [
                              _vm._v(
                                "Need " +
                                  _vm._s(_vm.missingEntriesToQualify) +
                                  " more for " +
                                  _vm._s(_vm.nextThreshold)
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "px-0", attrs: { align: "right" } },
                        [
                          _c(
                            "strong",
                            { staticClass: "sub-text black--text" },
                            [
                              _vm.contest.maximum > 0
                                ? _c("span", [
                                    _vm._v(
                                      " Max Entries: " +
                                        _vm._s(_vm.contest.maximum) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.entry
        ? _c("RemoveEntryDialog", {
            attrs: { entry: _vm.entry, admin: _vm.admin },
            on: {
              cancel: function($event) {
                _vm.entry = null
              },
              remove: _vm.removeEntry
            }
          })
        : _vm._e(),
      _vm.payment
        ? _c("PaymentDialog", {
            attrs: { payment: _vm.payment, admin: _vm.admin },
            on: {
              cancel: function($event) {
                _vm.payment = null
              },
              toggle: _vm.updatePayment
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }