var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-textarea", {
                attrs: {
                  solo: "",
                  name: "input-7-4",
                  clearable: "",
                  "clear-icon": "mdi-close-circle"
                },
                model: {
                  value: _vm.message,
                  callback: function($$v) {
                    _vm.message = $$v
                  },
                  expression: "message"
                }
              }),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    small: "",
                    disabled:
                      !_vm.message.length ||
                      (!_vm.selected.length && !_vm.selectedMembers.length)
                  },
                  on: {
                    click: function($event) {
                      return _vm.sendEmailClick()
                    }
                  }
                },
                [_vm._v("Send")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "py-1", attrs: { cols: "2" } }, [
            _vm._v(" Entrants: ")
          ]),
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.uniqueObj && _vm.uniqueObj.length > 0
                ? _c("v-checkbox", {
                    staticClass: "my-0 py-0",
                    attrs: { label: "Select All" },
                    model: {
                      value: _vm.selectAllEntrants,
                      callback: function($$v) {
                        _vm.selectAllEntrants = $$v
                      },
                      expression: "selectAllEntrants"
                    }
                  })
                : _c("span", [_vm._v("No entries yet!")]),
              _vm._l(this.uniqueObj, function(e, i) {
                return _c("v-checkbox", {
                  key: i,
                  staticClass: "my-0 py-0",
                  attrs: { value: e.user_id, label: e.nickname },
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                })
              })
            ],
            2
          )
        ],
        1
      ),
      _vm.members && _vm.members.length > 0
        ? _c(
            "v-row",
            [
              _c("v-col", { staticClass: "py-1", attrs: { cols: "2" } }, [
                _vm._v(" Remaining Members: ")
              ]),
              _c("v-spacer"),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-checkbox", {
                    staticClass: "my-0 py-0",
                    attrs: { label: "Select All" },
                    model: {
                      value: _vm.selectAllMembers,
                      callback: function($$v) {
                        _vm.selectAllMembers = $$v
                      },
                      expression: "selectAllMembers"
                    }
                  }),
                  _vm._l(this.members, function(e, i) {
                    return _c(
                      "v-row",
                      { key: i, staticClass: "ma-0" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pa-0" },
                          [
                            _c("v-checkbox", {
                              staticClass: "my-0 py-0",
                              attrs: {
                                value: e.user_id,
                                label:
                                  e.nickname +
                                  " (" +
                                  e.firstname +
                                  " " +
                                  e.lastname +
                                  ")"
                              },
                              model: {
                                value: _vm.selectedMembers,
                                callback: function($$v) {
                                  _vm.selectedMembers = $$v
                                },
                                expression: "selectedMembers"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pa-0" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { small: "", color: "error" },
                                on: {
                                  click: function($event) {
                                    return _vm.confirmEntry(e)
                                  }
                                }
                              },
                              [_vm._v("Enter")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.selectedEnterMember
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "320" },
              model: {
                value: _vm.showEnterDialog,
                callback: function($$v) {
                  _vm.showEnterDialog = $$v
                },
                expression: "showEnterDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v(" Enter the Contest? ")
                  ]),
                  _c("v-card-text", [
                    _c("p", [
                      _vm._v(
                        "Are you sure you want to enter " +
                          _vm._s(_vm.selectedEnterMember.nickname) +
                          " into this contest?"
                      )
                    ])
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "success", raised: "" },
                          on: { click: _vm.enterMember }
                        },
                        [_vm._v(" Enter Contest ")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { raised: "" },
                          on: { click: _vm.cancelEnter }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }