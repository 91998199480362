var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.compact,
              expression: "!compact"
            }
          ],
          staticClass: "my-0"
        },
        [
          _c("v-col", { staticClass: "py-0", attrs: { cols: "4" } }, [
            _c("span", { staticClass: "action-section" }, [
              _vm._v("Bet #" + _vm._s(_vm.payout.id))
            ])
          ]),
          _c("v-col", { staticClass: "py-0 text-right" }, [
            _c("span", { staticClass: "sub-text" }, [
              _vm._v(_vm._s(_vm.payout.away + " vs. " + _vm.payout.home))
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-1" },
        [
          _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
            _vm.payout.paymentsent
              ? _c("p", { staticClass: "mb-1" }, [
                  _vm._v(
                    _vm._s(_vm.payout.nickname) + " reported sending you "
                  ),
                  _c("strong", [_vm._v("$" + _vm._s(_vm.payout.amount))]),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.compact,
                          expression: "!compact"
                        }
                      ]
                    },
                    [
                      _vm._v(
                        " for your " + _vm._s(_vm.getGameInfo(_vm.payout)) + " "
                      ),
                      _c("span", { staticClass: "winner" }, [_vm._v("WIN")])
                    ]
                  ),
                  _vm._v(" from Venmo account "),
                  _c("strong", [
                    _vm._v("@" + _vm._s(_vm.payout.paymentdetails[0].value))
                  ]),
                  _vm._v(".")
                ])
              : _vm._e(),
            _c("p", { staticClass: "text-center mb-0" }, [
              _c("strong", [_vm._v("Did you receive this payment?")])
            ])
          ])
        ],
        1
      ),
      _vm.isDispute(_vm.payout)
        ? _c(
            "v-row",
            { staticClass: "my-0" },
            [
              _c("v-col", { staticClass: "py-0" }, [
                _vm._v(
                  "Dispute: " +
                    _vm._s(_vm.payout.disputetype) +
                    " - " +
                    _vm._s(_vm.payout.disputestatus)
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", "align-self": "center" }
            },
            [
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [
                  _vm.payout.paymentsent
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "white--text small",
                          attrs: {
                            small: !_vm.compact,
                            "x-small": _vm.compact,
                            color: "red darken-1"
                          },
                          on: {
                            click: function($event) {
                              return _vm.denyPayment(_vm.payout)
                            }
                          }
                        },
                        [_vm._v("No Payment")]
                      )
                    : _vm._e(),
                  _vm.payout.paymentsent ? _c("v-spacer") : _vm._e(),
                  _vm.payout.paymentsent
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "white--text small",
                          attrs: {
                            small: !_vm.compact,
                            "x-small": _vm.compact,
                            color: "light-green darken-2"
                          },
                          on: {
                            click: function($event) {
                              return _vm.confirmPayment(_vm.payout)
                            }
                          }
                        },
                        [_vm._v("Yes, I Got Paid")]
                      )
                    : _c(
                        "v-dialog",
                        {
                          attrs: { width: "500" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "white--text small",
                                        attrs: {
                                          small: !_vm.compact,
                                          "x-small": _vm.compact,
                                          color: "light-green darken-2"
                                        }
                                      },
                                      on
                                    ),
                                    [_vm._v("Yes, I Got Paid")]
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.confirmDialog,
                            callback: function($$v) {
                              _vm.confirmDialog = $$v
                            },
                            expression: "confirmDialog"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-text", [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "text-center black--text pt-5"
                                  },
                                  [_vm._v("Are you sure?")]
                                ),
                                _vm._v(
                                  " You are confirming prior to the losing member indicating that payment has been made. "
                                )
                              ]),
                              _c("v-divider"),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "white--text small",
                                      attrs: { color: "red darken-1" },
                                      on: {
                                        click: function($event) {
                                          _vm.confirmDialog = false
                                        }
                                      }
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "white--text small",
                                      attrs: { color: "light-green darken-2" },
                                      on: {
                                        click: function($event) {
                                          return _vm.confirmPayment(_vm.payout)
                                        }
                                      }
                                    },
                                    [_vm._v("Yes")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }