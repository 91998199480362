<template>
  <v-container :key="reload">
    <v-row class="my-0 py-0">
      <v-col
        cols="12"
        class="py-0"
        v-show="contacts.length == 0"
        v-if="isAllowed"
      >
        <InviteCreate @updateinvites="reloadInvites" :grouplist="groups"></InviteCreate>
      </v-col>
      <v-col v-else class="text-center">
        You do not currently have the ability to invite your friends.
      </v-col>
      <v-col
        cols="12"
        v-if="hasContactApi"
        class="py-0 text-center"
      >
        <hr>
        <v-btn
          @click.prevent="openContacts"
          v-if="contacts.length == 0"
        >Import Phone Contacts</v-btn>
        <div v-if="contacts.length > 0">
          <div
            v-for="c in contacts"
            :key="c.tel"
          >
            <p>{{ c.name }} - {{ c.email }} - {{ c.tel }}</p>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
        v-if="invites"
      >
        <hr>
        <InviteList
          :invites="invites" :pagination="pagination" :loading="loading" :grouplist="groups"
          @updateinvites="reloadInvites" @updatePagination="updatePagination" @searchInvites="searchInvites"
        ></InviteList>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AccountService from '@/services/AccountService'
import UserService from '@/services/UserService'
import InviteList from '@/components/invites/List'
import InviteCreate from '@/components/invites/Create'
import CommunityService from '@/services/CommunityService'

export default {
  name: 'Invite',
  components: {
    InviteList,
    InviteCreate
  },
  data: function () {
    return {
      invites: [],
      contacts: [],
      groups: [],
      reload: false,
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 5,
        totalItems: 0
      },
      searchTerm: '',
      unaccepted: true
    }
  },
  computed: {
    hasContactApi: function () {
      return false // ('contacts' in navigator && 'ContactsManager' in window)
    },
    isAllowed: function () {
      const user = UserService.getUser()
      return (user.allowinvite === 1 || user.allowinvite === '1')
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    async init () {
      this.loading = true
      this.$updateloading(1)
      var data = {
        pagination: this.pagination,
        filters: {
          search: this.searchTerm,
          unaccepted: this.unaccepted
        }
      }
      await AccountService.getInvites(data).then((response) => {
        this.invites = response.data.results
        this.pagination.totalItems = response.data.totalItems
        this.$updateloading(-1)
        this.loading = false
      }).catch(errors => {
        this.$updateloading(-1)
        this.loading = false
        console.dir(errors)
      })

      await CommunityService.getgroups().then(
        (response) => {
          this.groups = []
          response.data.forEach(element => {
            if (element.allowmemberinvite === 1 && element.caninvite === 1) {
              this.$set(this.groups, this.groups.length, element)
            }
          })
        }
      )
    },
    reloadInvites: function () {
      this.pagination.page = 1
      this.pagination.rowsPerPage = this.pagination.rowsPerPage ? this.pagination.rowsPerPage : 5
      this.init()
    },
    allowInvite: function () {
      const user = UserService.getUser()
      return (user.allowinvite === 1 || user.allowinvite === '1')
    },
    async openContacts () {
      this.contacts = await navigator.contacts.select(['name', 'email', 'tel'], { multiple: true })
      console.dir(this.contacts)
    },
    updatePagination: function (pag) {
      this.pagination = pag
      this.init()
    },
    searchInvites: function (data) {
      this.searchTerm = data.searchTerm
      this.unaccepted = data.unaccepted
      this.pagination.page = 1
      this.init()
    }
  }
}
</script>
