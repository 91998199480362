import axios from 'axios'
import Vue from 'vue'
import Notifications from 'vue-notification'
import UserService from './UserService'

Vue.use(Notifications)

const DashboardService = {

  getPendingExpanded () {
    return (localStorage.getItem('pendingExpanded') !== 'false') || false
  },

  setPendingExpanded (value) {
    return localStorage.setItem('pendingExpanded', value)
  },

  async finishedGames () {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'dashboard/payouts',
      method: 'get'
    })
  },

  async userData () {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'dashboard/userinfo',
      method: 'get'
    })
  },

  async settleDebt (wagerId) {
    await UserService.authHeader()
    var data = {
      wager: wagerId
    }
    return axios({
      url: UserService.baseUrl() + 'dashboard/debt/settle',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async confirmPayment (wagerId) {
    await UserService.authHeader()
    var data = {
      wager: wagerId
    }
    return axios({
      url: UserService.baseUrl() + 'dashboard/payment/confirm',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async denyPayment (wagerId) {
    await UserService.authHeader()
    var data = {
      wager: wagerId
    }
    return axios({
      url: UserService.baseUrl() + 'dashboard/payment/deny',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async deleteOffer (offerId) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'wager/deleteoffer',
      data: JSON.stringify(offerId),
      method: 'post'
    }).then(response => {
      return response
    })
  },

  async getWager (wagerId) {
    await UserService.authHeader(false)
    var data = {
      id: wagerId
    }
    return axios({
      url: UserService.baseUrl() + 'wager/get',
      data: JSON.stringify(data),
      method: 'post'
    })
  }
}

export default DashboardService
