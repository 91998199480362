var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.invites
    ? _c(
        "div",
        { staticClass: "game-list" },
        [
          _vm.invites.length === 0
            ? _c("div", [
                _c("p", { staticClass: "text-center" }, [
                  _vm._v("No invites found.")
                ])
              ])
            : _vm._e(),
          _c("ResendInvite", {
            attrs: {
              invite: _vm.selectedInvite,
              edit: _vm.edit,
              grouplist: _vm.grouplist
            },
            on: {
              close: function($event) {
                return _vm.closeResend()
              }
            }
          }),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              "footer-props": {
                itemsPerPageText: ""
              },
              headers: _vm.headers,
              items: _vm.invites,
              page: _vm.pagination.page,
              "items-per-page": _vm.pagination.rowsPerPage,
              "server-items-length": _vm.pagination.totalItems,
              loading: _vm.loading,
              "mobile-breakpoint": "0"
            },
            on: {
              "update:items-per-page": _vm.updateItemCount,
              "update:page": _vm.updatePage
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function() {
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-0 mx-4 pt-2",
                          attrs: { "no-gutters": "", justify: "start" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "pr-2 overline font-weight-black"
                                    },
                                    [_vm._v("Accepted")]
                                  ),
                                  _c("v-switch", {
                                    staticClass:
                                      "switch-small pa-0 my-0 ml-3 mb-n2 mr-n3",
                                    staticStyle: { display: "inline-block" },
                                    model: {
                                      value: _vm.unaccepted,
                                      callback: function($$v) {
                                        _vm.unaccepted = $$v
                                      },
                                      expression: "unaccepted"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "overline font-weight-black"
                                    },
                                    [_vm._v("Unaccepted")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "my-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0" },
                            [
                              _c("v-text-field", {
                                staticClass: "mx-4",
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  label: "Search",
                                  "single-line": "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                _vm.unaccepted && _vm.search == ""
                  ? {
                      key: "item.email",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.email == "" || item.email == null
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("phonenumber")(item.phone))
                                )
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(_vm._f("maxTwenty")(item.email)))
                              ]),
                          _c("br"),
                          _c("span", [
                            _vm._v("Code: "),
                            _c("b", [_vm._v(_vm._s(item.code))])
                          ])
                        ]
                      }
                    }
                  : null,
                _vm.unaccepted && _vm.search == ""
                  ? {
                      key: "item.created",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.selectInvite(item)
                                }
                              }
                            },
                            [_vm._v("Resend")]
                          )
                        ]
                      }
                    }
                  : null,
                !_vm.unaccepted && _vm.search == ""
                  ? {
                      key: "item.name",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("maxTwenty")(item.name)))
                          ])
                        ]
                      }
                    }
                  : null,
                !_vm.unaccepted && _vm.search == ""
                  ? {
                      key: "item.accepteduser",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("maxTwenty")(item.accepteduser))
                            )
                          ])
                        ]
                      }
                    }
                  : null,
                _vm.search != ""
                  ? {
                      key: "item.firstname",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.accepteduser_id == null &&
                          item.email != "" && item.email != null
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("maxTwenty")(item.email)) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          item.accepteduser_id == null &&
                          (item.email == "" || item.email == null)
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("phonenumber")(item.phone)) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          item.accepteduser_id == null ? _c("br") : _vm._e(),
                          item.accepteduser_id == null
                            ? _c("span", [
                                _vm._v("Code: "),
                                _c("b", [_vm._v(_vm._s(item.code))])
                              ])
                            : _vm._e(),
                          item.accepteduser_id != null
                            ? _c("span", [
                                _vm._v(_vm._s(_vm._f("maxTwenty")(item.name)))
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  : null,
                _vm.search != ""
                  ? {
                      key: "item.lastname",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.accepteduser_id == null
                            ? _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.selectInvite(item)
                                    }
                                  }
                                },
                                [_vm._v("Resend")]
                              )
                            : _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("maxTwenty")(item.accepteduser))
                                )
                              ])
                        ]
                      }
                    }
                  : null
              ],
              null,
              true
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }