var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper fadeInDown" },
    [
      _c(
        "v-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.resetpassword()
            }
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v("Password Reset")]),
                      _c(
                        "v-card-text",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "New Password",
                              name: "password",
                              id: "password",
                              type: "password",
                              disabled: _vm.sending,
                              required: "",
                              "error-messages": _vm.getErrors("password")
                            },
                            on: {
                              blur: function($event) {
                                return _vm.$v.form.password.$touch()
                              }
                            },
                            model: {
                              value: _vm.form.password,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "password", $$v)
                              },
                              expression: "form.password"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Confirm new password",
                              name: "repeat",
                              id: "repeat",
                              type: "password",
                              disabled: _vm.sending,
                              required: "",
                              "error-messages": _vm.getErrors("repeat")
                            },
                            on: {
                              blur: function($event) {
                                return _vm.$v.form.repeat.$touch()
                              }
                            },
                            model: {
                              value: _vm.form.repeat,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "repeat", $$v)
                              },
                              expression: "form.repeat"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "end" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "submit",
                                        text: "",
                                        disabled: _vm.sending
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.resetpassword()
                                        }
                                      }
                                    },
                                    [_vm._v("Submit")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }