<template>
  <v-dialog
    v-model="showDialog"
    persistent
    max-width="320"
  >
    <v-card>
      <v-card-title class="headline">
        Enter the Contest?
      </v-card-title>
      <v-card-text>
        <p>Do you want to enter this contest? There is a ${{ contest.entryfee }} entry fee.</p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="success"
          raised
          @click="enter"
          :disabled="loading"
        >
          Enter Contest
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          raised
          @click="$emit('cancel')"
          :disabled="loading"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ContestService from '@/services/ContestService'

export default {
  name: 'EntryDialog',

  props: {
    contest: Object,
    group: Object
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    showDialog: function () {
      return (this.contest && this.contest.id > 0)
    }
  },

  methods: {
    async enter () {
      if (this.loading || !this.contest) {
        return false
      }
      this.loading = true
      var data = {
        friendgroup_id: this.group.id,
        contest_id: this.contest.id
      }
      ContestService.enterContest(data).then(
        (response) => {
          this.loading = false
          if (response.data.participant_id) {
            this.$emit('enter')
          } else {
            this.$notify({
              group: 'notices',
              title: 'Error',
              text: 'Failed to enter contest. Please try again later.',
              type: 'danger'
            })
          }
        }
      ).catch(err => {
        this.loading = false
        this.$notify({
          group: 'notices',
          title: err.response.data.errors[0],
          text: err.response.data.error,
          type: 'danger'
        })
      })
    }
  }
}
</script>
