<template>
  <div>
    <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <div class="mx-auto text-center">
            Confirm Payment
          </div>
        </v-card-title>
        <v-card-text class="black--text">
          <p class="">Choose "Confirmed" if you got paid for the following bet.</p>
          <v-row class="my-0 py-0">
            <v-col
              cols="12"
              class="py-0"
            >
              <span class="action-section">Bet:</span> #{{ wager.id }} <span class="winner">WIN</span> {{ mySideInfo }}
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <span class="action-section">Amount:</span> ${{ wager.amount }} {{ settletime }}
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <span class="action-section">Payor:</span> {{ (wager.loser_id === wager.offeredby_id) ? wager.offername : wager.takername }}
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <span class="action-section">Venmo:</span> <a
                class="link"
                :href="paymentlink"
                target="_blank"
              >{{ paymenthandle }}</a>
            </v-col>
          </v-row>
          <p>Choose "No Payment" if you checked your payment app and did not receive funds for this bet.</p>
          <p v-if="wager.paymentdenycount > 0">If you deny receiving this payment, this bet will be put into dispute and reviewed.</p>
          <p v-else>Please check carefully as indication of no payment may result in the bet being put into dispute.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            class="white--text small"
            @click="deny"
          >No Payment</v-btn>
          <v-btn
            color="black"
            class="white--text small"
            @click="cancel"
          >Cancel</v-btn>
          <v-btn
            color="light-green darken-2"
            class="white--text small"
            @click="agree"
          >Confirmed</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DashboardService from '@/services/DashboardService'
import DisputeService from '@/services/DisputeService'

export default {
  data: () => {
    return {
      loading: false
    }
  },
  computed: {
    showDialog: function () {
      return (this.wager && this.wager.id > 0)
    },
    mySideInfo: function () {
      var homeline = this.wager.lineamount > 0 ? '+' + this.wager.lineamount : this.wager.lineamount
      var awayline = (this.wager.lineamount * -1) > 0 ? '+' + (this.wager.lineamount * -1) : (this.wager.lineamount * -1)
      var homeinfo = this.wager.bettype_id === 20 ? 'Over ' + this.wager.lineamount : this.wager.home + ' ' + homeline
      var awayinfo = this.wager.bettype_id === 20 ? 'Under ' + this.wager.lineamount : this.wager.away + ' ' + awayline
      if (this.wager.winner_id === this.wager.takenby_id) {
        if (this.wager.takenbyteam_id === this.wager.hometeam_id) {
          return homeinfo
        } else {
          return awayinfo
        }
      } else {
        if (this.wager.offeredbyteam_id === this.wager.hometeam_id) {
          return homeinfo
        } else {
          return awayinfo
        }
      }
    },
    paymentlink: function () {
      return 'https://venmo.com/u/' + this.paymenthandle
    },
    paymenthandle: function () {
      var pmtinfo = ''
      if (this.wager.offerpaymentmethod || this.wager.takerpaymentmethod) {
        if (this.wager.winner_id === this.wager.offeredby_id) {
          pmtinfo = this.wager.takerpaymentmethod[0].value
        } else {
          pmtinfo = this.wager.offerpaymentmethod[0].value
        }
      } else {
        pmtinfo = this.wager.paymentdetails[0].value
      }
      return pmtinfo
    },
    settletime: function () {
      if (this.wager.timetosettle) {
        return '(' + this.wager.timetosettle.toFixed(1) + ' hours since sent)'
      } else {
        return ''
      }
    }
  },
  props: {
    wager: {
      type: Object,
      default: null
    }
  },
  methods: {
    async agree () {
      this.$updateloading(1)
      await DashboardService.confirmPayment(this.wager.id).then(
        (response) => {
          this.$updateloading(-1)
          this.$emit('paid')
        },
        (error) => {
          this.$emit('cancel')
          this.$updateloading(-1)
          alert('An error has occured, please try again later. If the error continues to happen please contact us\n' + error.response.data.error)
        }
      )
    },
    cancel () {
      this.$emit('cancel')
    },
    async deny () {
      this.$updateloading(1)
      await DashboardService.denyPayment(this.wager.id).then(
        async (response) => {
          if (response.data.dispute) {
            console.log('second denial, creating dispute')
            const formData = new FormData()
            formData.append('wager_id', response.data.dispute)
            formData.append('disputetype_id', 10)
            formData.append('description', 'Payment sent was denied 2 times')
            await DisputeService.create(formData).then(response => {
              this.$notify({
                group: 'notices',
                title: 'Dispute Created',
                text: 'This wager is now in dispute',
                type: 'info'
              })
              this.$emit('dispute')
              this.$updateloading(-1)
            }).catch(errors => {
              this.$notify({
                group: 'errors',
                title: 'Dispute Failed',
                text: errors.response.data.error,
                type: 'warn'
              })
              this.$emit('deny')
              console.dir(errors)
              this.$updateloading(-1)
            })
          } else {
            console.log('denying payment')
            this.$emit('deny')
            this.$updateloading(-1)
          }
        },
        (error) => {
          this.$updateloading(-1)
          alert('An error has occured, please try again later. If the error continues to happen please contact us\n' + error.response.data.error)
        }
      )
    }
  }
}
</script>
<style scoped>
@media screen and (max-width: 331px) {
  .small {
    max-width: 33%;
  }
}
</style>
