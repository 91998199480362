<template>
  <div id="app-vue-main">
    <loader-overlay></loader-overlay>
    <notifications
      group="errors"
      class="notifications"
      :duration="-1"
      :ignoreDuplicates="true"
      :max="1"
      width="100%"
      position="top center"
      absolute
    ></notifications>
    <notifications
      group="notices"
      class="notifications"
      :ignoreDuplicates="true"
      :max="1"
      width="100%"
      position="top center"
      absolute
    ></notifications>
    <component :is="layout">
      <router-view id="topEl"></router-view>
    </component>

    <v-dialog v-model="introslide" persistent max-width="600px">
      <Slides @closeintroslide="closeintroslide"></Slides>
    </v-dialog>

    <v-dialog v-model="notLoggedInModal" persistent max-width="320">
      <v-card>
        <v-card-title class="headline">
          Please login
        </v-card-title>
        <v-card-text>
          <p>You must be logged in to complete this action. If you do not have an account you can sign up for free.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            raised
            @click="goSignIn()"
          >
            Sign In
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            raised
            @click="cancelSignIn()"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Vue from 'vue'
import UserService from '@/services/UserService'
import { bus } from '@/main'
import Slides from '@/components/dashboard/slides/Slides.vue'
import './app.scss'

export default {
  name: 'app',
  data () {
    return {
      name: 'help',
      introslide: false,
      loading: 0,
      notLoggedInModal: false
    }
  },

  components: {
    Slides
  },

  computed: {
    layout () {
      return (this.$route.meta.layout || 'default') + '-layout'
    }
  },
  created () {
    // set  axios defaults to always set content type to JSON by default.
    Vue.axios.defaults.headers.post['Content-Type'] = 'application/json'
    Vue.axios.defaults.headers.get['Content-Type'] = 'application/json'
    Vue.axios.defaults.withCredentials = true
    // nothing defined here (when this.$route.path is other than "/")
    // i ntercept all the axios request
    Vue.axios.interceptors.response.use(
      // handle response here
      (response) => {
        return Promise.resolve(response)
      },
      (err) => {
        if (typeof err.response === 'undefined') {
          throw new Error('An internal error has occurred.')
        } else {
          const unAuthErrorCode = [401, 403]
          if (unAuthErrorCode.includes(err.response.status)) {
            console.log('error code in app.vue')
            this.logout()
            return Promise.reject(err.response)
          } else if (err.response.status === 425) {
            // efish service suspended or cancelled
            this.$router.push('/suspended')
          } else {
            throw err
          }
        }
      })

    bus.$on('openslide', () => { this.introslide = true })
    bus.$on('checkslidestatus', () => { this.slideStatus() })
    bus.$on('notLoggedInModal', () => { this.notLoggedInModal = true })
  },
  updated () {
    // something defined here whatever the this.$route.path
  },
  methods: {
    logout: function () {
      // if you ever get an unauthorized, logout the user
      // user clean local storage
      UserService.logout()
      // let user go to login page
      this.$updateloading(-999)
      this.$router.push('/login', () => {
        this.$notify({
          group: 'errors',
          title: 'Logout Complete',
          text: 'Your session has expired, please login again.',
          type: 'danger'
        })
      })
    },

    slideStatus: function () {
      var user = UserService.getUser()
      if (user) {
        const slideviewed = localStorage.getItem('slideviewed')
        this.introslide = user.introslides === '0' && !(slideviewed === '1')

        if (slideviewed === '1' && user.introslides === '0') {
          this.closeintroslide()
        }
      } else {
        const slideviewed = localStorage.getItem('slideviewed')
        this.introslide = !(slideviewed === '1')
      }
    },

    closeintroslide: function () {
      this.introslide = false
      localStorage.setItem('slideviewed', '1')
      const user = UserService.getUser()
      if (user && user.id && +user.id > 0) {
        UserService.setintroslideviewed().then(() => {}).catch(
          (error) => {
            console.log(error)
          }
        )
      }
    },

    goSignIn () {
      this.notLoggedInModal = false
      this.$router.push('/login')
    },

    cancelSignIn () {
      this.notLoggedInModal = false
    }

  }
}
</script>

<style lang="sass">
@import "./app.scss"
</style>

<style>
*::-webkit-scrollbar {
  display: none;
}
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
/* dashboard css */
.container {
  margin-bottom: 1vh;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}

div.md-app,
#topEl {
  min-height: 80vh;
}

/* Fixed header spacing */
#app {
  margin-top: 0px;
}

/* BASIC */
body {
  font-family: "Poppins", sans-serif;
  font-size: 0.85rem;
}

a {
  color: #ff0000;
  text-decoration: none;
  font-weight: 400;
}

a:hover,
a:active {
  text-decoration: none;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.img-wrap {
  margin: 55px 0;
}

.wrapper {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center !important;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
/* .underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
} */

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 45%;
}

.vig-card {
  width: 100% !important;
  margin: 0 0 7px 0 !important;
}
.vig-card h1 {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}
.vig-card h2 {
  text-align: center;
  margin: 0;
  font-size: 12px;
  color: black;
  font-weight: normal;
  display: block;
  text-transform: none;
}
.vig-card-expand {
  border-top: 1px solid #eee;
  padding-top: 0.5em;
}
.vig-card-expand-container {
  width: 100%;
  border-top: 1px solid #eee;
  color: #ddd;
  text-align: center;
  margin-top: 0.5em;
}
.vig-card-header-mini,
.vig-card-footer-mini {
  border-bottom: 1px solid #eee;
  padding: 0 10px;
  background: black;
  color: white;
}
.vig-card-footer-mini {
  border-bottom: none;
  border-top: 1px solid #eee;
}
.vig-card-header-mini span,
.vig-card-footer-mini span {
  background: black;
  color: white;
}

.v-expansion-panel {
  margin-bottom: 1vh;
}
</style>
