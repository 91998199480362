var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "320" },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(" Enter the Contest? ")
          ]),
          _c("v-card-text", [
            _c("p", [
              _vm._v(
                "Do you want to enter this contest? There is a $" +
                  _vm._s(_vm.contest.entryfee) +
                  " entry fee."
              )
            ])
          ]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "success",
                    raised: "",
                    disabled: _vm.loading
                  },
                  on: { click: _vm.enter }
                },
                [_vm._v(" Enter Contest ")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { raised: "", disabled: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }