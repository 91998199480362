var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "my-0" },
    [
      _vm.wagersSelected > 0
        ? _c(
            "v-col",
            {
              staticClass: "text-center mx-auto my-0 pt-0",
              attrs: { cols: "10" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "vigzero-btn confirm robo text-center mx-auto",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.takeWagers($event)
                    }
                  }
                },
                [_vm._v("Confirm Selected")]
              )
            ]
          )
        : _vm._e(),
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "12" } },
        [_c("GameHeader", { attrs: { game: _vm.gameHeaderInfo() } })],
        1
      ),
      _c("v-col", { staticClass: "pt-0", attrs: { cols: "12" } }, [
        _c("span", { staticClass: "sub-text pull-left" }, [
          _vm._v(" Limit: $" + _vm._s(_vm.availableLimit) + " "),
          _vm.vigSavings != 0
            ? _c("span", { staticClass: "sub-text" }, [
                _vm._v(" Vig Savings: $" + _vm._s(_vm.vigSavings))
              ])
            : _vm._e()
        ]),
        _c("span", { staticClass: "sub-text pull-right" }, [
          _vm._v("Selected: $" + _vm._s(_vm.wagersSelected))
        ])
      ]),
      _vm.wagers && _vm.wagers.length > 0
        ? _c(
            "v-col",
            { staticClass: "py-0 my-0", attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "py-0 my-0" },
                _vm._l(_vm.wagers, function(wager) {
                  return _c(
                    "v-col",
                    {
                      key: wager.id,
                      staticClass: "py-0 my-0",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "py-0 my-0",
                          attrs: {
                            align: "center",
                            justify: "start",
                            "no-gutters": ""
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 my-0 pr-3",
                              attrs: { cols: "11" }
                            },
                            [
                              _c("WagerInfo", {
                                attrs: { wager: wager },
                                on: { "update-amount": _vm.handleAmountUpdate }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 my-0",
                              attrs: {
                                cols: "1",
                                "align-self": _vm.openhome(wager)
                                  ? "end"
                                  : "start"
                              }
                            },
                            [
                              _c("v-checkbox", {
                                staticClass: "pull-right mr-0",
                                style: _vm.openhome(wager)
                                  ? "margin-bottom: 12px;"
                                  : "margin-top: 32px;",
                                on: {
                                  change: function($event) {
                                    return _vm.checkSelectedWagers(
                                      $event,
                                      wager
                                    )
                                  }
                                },
                                model: {
                                  value: wager.selected,
                                  callback: function($$v) {
                                    _vm.$set(wager, "selected", $$v)
                                  },
                                  expression: "wager.selected"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider")
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.wagers && _vm.wagers.length == 0
        ? _c(
            "v-col",
            { staticClass: "py-0 text-center", attrs: { cols: "12" } },
            [_c("p", [_vm._v("No wagers found.")])]
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          staticStyle: { overflow: "hidden" },
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.showDepositDialog,
            callback: function($$v) {
              _vm.showDepositDialog = $$v
            },
            expression: "showDepositDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-4 mt-4" },
            [
              _c(
                "v-card-title",
                [
                  _c("span", { staticClass: "headline font-weight-bold" }, [
                    _vm._v("Deposit Money")
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    _vm._b(
                      {
                        staticClass: "close-icon",
                        on: { click: _vm.closeDialog }
                      },
                      "v-icon",
                      _vm.$attrs,
                      false
                    ),
                    [_vm._v(" mdi-close ")]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("strong", [
                    _vm._v("You do not have enough money in your VZ account")
                  ]),
                  _vm._v(
                    " VigZero not only allows you to keep more money with zero vigorish, for the 2024 football season, the deposit fee is waived (no withdrawal fees ever with VigZero). "
                  ),
                  _c("br"),
                  _c("br"),
                  _c("strong", [_vm._v("How to make a deposit to VigZero:")]),
                  _c("ol", [
                    _c("li", [
                      _vm._v(
                        "Tell us how much you are going to deposit and send the amount "
                      ),
                      _c("b", [_vm._v("via Venmo to @VigZero")]),
                      _vm._v(" (Venmo should open for convenience).")
                    ]),
                    _c("li", [
                      _vm._v(
                        "Once we receive and verify funds, your funds will appear in your VZ account."
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "In meantime you can view pending balance on the 'Account Balance' page. Withdraw anytime"
                      )
                    ])
                  ]),
                  _c("br"),
                  _c("v-text-field", {
                    attrs: {
                      label: "Intended Deposit Amount",
                      prefix: "$",
                      type: "number",
                      outlined: "",
                      step: 50,
                      rules: [
                        function(v) {
                          return !!v || "This field is required"
                        },
                        function(v) {
                          return v > 0 || "Enter a positive number"
                        }
                      ]
                    },
                    model: {
                      value: _vm.depositAmount,
                      callback: function($$v) {
                        _vm.depositAmount = $$v
                      },
                      expression: "depositAmount"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.submitDeposit()
                        }
                      }
                    },
                    [_vm._v("Submit Deposit")]
                  ),
                  _c("br"),
                  _c("br"),
                  _vm.VZBalace === 0 && _vm.honorbet
                    ? _c(
                        "a",
                        {
                          staticClass: "text-link",
                          staticStyle: { color: "#E53935", cursor: "pointer" },
                          attrs: { href: "#" },
                          on: { click: _vm.makeOfferOnHonorSystem }
                        },
                        [_vm._v("MAKE OFFER ON HONOR SYSTEM")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }