<template>
  <v-dialog v-model="edit" :loading="loading" :retain-focus="false">
    <v-card>
      <v-card-title class="headline"> Edit Details </v-card-title>
      <v-card-text v-if="invite">
        <div>
          <v-text-field
            v-model="form.email"
            type="email"
            label="Email"
            placeholder="Friend's Email"
            :error-messages="getErrors('email')"
            @blur="$v.form.email.$touch()"
            required
            :disabled="loading"
          ></v-text-field>
        </div>

        <div>
          <v-text-field
            v-model="form.phone"
            label="Phone"
            placeholder="Friend's cell phone"
            :error-messages="getErrors('phone')"
            @blur="$v.form.phone.$touch()"
            required
            :disabled="loading"
          ></v-text-field>
        </div>

        <div>
          <v-textarea
            solo
            v-model="form.message"
            label="Personalized Message"
            :error-messages="getErrors('message')"
            @blur="$v.form.message.$touch()"
            maxlength="250"
            :disabled="loading"
          ></v-textarea>
        </div>
        <v-checkbox v-for="group in grouplist" :key="group.id" :label="group.name" :value="group.id" class="py-0 pl-4 mt-n3" v-model="form.groups"></v-checkbox>
      </v-card-text>
      <v-divider></v-divider>
      <v-spacer></v-spacer>
      <v-card-actions align="right">
        <v-btn
          color="black"
          class="white--text"
          @click="cancelResend()"
          :disabled="loading"
          >Cancel</v-btn
        >
        <v-btn
          @click="resendInvite()"
          style="position: absolute; right: 8px"
          :disabled="loading"
          >Resend Invite</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AccountService from '@/services/AccountService'
import { validationMixin } from 'vuelidate'
import {
  requiredIf,
  email,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'
const cellPhone = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  } else {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)
  }
}

export default {
  name: 'InviteList',
  mixins: [validationMixin],
  props: {
    invite: Object,
    edit: {
      type: Boolean,
      default: false
    },
    grouplist: Array
  },
  data: () => {
    return {
      loading: false,
      form: {
        id: null,
        email: null,
        phone: null,
        message: null,
        code: null,
        groups: []
      }
    }
  },
  validations: {
    form: {
      email: {
        required: requiredIf(function () {
          return !this.form.phone || this.form.phone === ''
        }),
        email
      },
      phone: {
        required: requiredIf(function () {
          return !this.form.email || this.form.email === ''
        }),
        cellPhone,
        minLength: minLength(10)
      },
      message: {
        maxLength: maxLength(250)
      }
    }
  },
  methods: {
    getErrors (fieldName) {
      const err = []
      if (!this.$v.form[fieldName].$dirty) return err
      if (fieldName === 'email') {
        !this.$v.form.email.required && err.push('Email is required')
        !this.$v.form.email.email && err.push('Please enter a valid email')
      }
      if (fieldName === 'phone') {
        !this.$v.form.phone.required && err.push('Phone is required')
        !this.$v.form.phone.cellPhone && err.push('Please enter a valid number')
        !this.$v.form.phone.minLength && err.push('Phone must be at least 10 characters')
      }
      if (fieldName === 'message') {
        !this.$v.form.message.maxLength && err.push('250 characters is the maximum')
      }
      return err
    },
    cancelResend: function () {
      this.form = {
        id: null,
        email: null,
        phone: null,
        message: null,
        code: null
      }
      this.$v.$reset()
      this.$emit('close')
    },
    resendInvite: function () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      if ((!this.form.phone || this.form.phone === '') && (!this.form.email || this.form.email === '')) {
        self.$notify({
          group: 'errors',
          title: 'Phone Required!',
          text: 'A valid mobile phone number or email is required.',
          type: 'warn'
        })
        return false
      }

      // remove delimiters from phone number
      if (this.form.phone) {
        this.form.phone = this.form.phone.toString().replace(/\D+/g, '')
      }

      this.$updateloading(1)
      this.loading = true
      AccountService.createInvite(this.form).then(
        (response) => {
          this.cancelResend()
          this.$updateloading(-1)
          this.loading = false
          this.$notify({
            group: 'notices',
            title: 'Invitation Successful',
            text: 'An invitation has been sent.',
            type: 'success'
          })
        },
        (error) => {
          this.$updateloading(-1)
          this.loading = false
          this.$notify({
            group: 'errors',
            title: 'Invitation Failed',
            text: error.response.data.error,
            type: 'warn'
          })
        }
      ).finally(
        () => {
          this.$emit('reload')
        }
      )
    }
  },
  watch: {
    invite: function (n, o) {
      if (n !== o) {
        if (this.invite) {
          this.form = {
            id: this.invite.id,
            email: this.invite.email,
            phone: this.invite.phone,
            message: this.invite.message,
            code: this.invite.code,
            groups: this.invite.groups
          }
        } else {
          this.form = {
            id: null,
            email: null,
            phone: null,
            message: null,
            groups: []
          }
        }
      }
    }
  }
}
</script>
