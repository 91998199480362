var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "ma-0" },
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                _vm._l(_vm.groups, function(g) {
                  return _c(
                    "v-col",
                    {
                      key: g.id,
                      staticClass: "py-0",
                      attrs: { cols: "12", sm: "6" },
                      on: {
                        click: function($event) {
                          return _vm.opengroup(g.friendgroup_id)
                        }
                      }
                    },
                    [_c("group-tile", { attrs: { group: g } })],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }