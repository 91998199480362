var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDialog
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "" },
              model: {
                value: _vm.showDialog,
                callback: function($$v) {
                  _vm.showDialog = $$v
                },
                expression: "showDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v(" Dispute ")]),
                  _c(
                    "v-card-text",
                    [
                      _vm._v(
                        " It may take a bit for a user to confirm their payment to you and you are not prevented from making other wagers due to this one. For this reason, you will not be allowed to make a dispute unless it has been at least 48 hours since the end of the game. Thank you. "
                      ),
                      _c("v-divider")
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "black" },
                          on: {
                            click: function($event) {
                              return _vm.cancel()
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "red darken-1" },
                          on: {
                            click: function($event) {
                              return _vm.dispute()
                            }
                          }
                        },
                        [_vm._v(" Dispute ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }