<style scoped>
</style>
<template>
  <v-card
    class="vig-card mb-4"
    v-if="wager && wager.id > 0"
  >
    <v-card-title
      class="py-0"
      @click="expand = !expand"
    >
      Wager #{{ wager.id }} - {{ wager.offeredbyamount | currency }} {{ wager.bettype }} {{ wager.lineamount }}
    </v-card-title>

    <v-expand-transition>
      <div
        v-if="expand"
        class="vig-card-expand"
        style="margin-top: 0.5em;"
      >
        <v-card-text>
          <v-row class="ma-0">
            <v-col class="py-0">
              <h1 class="text-center">Offered By: {{ wager.offeredby }}</h1>
              <h2>{{ showWinLoss(wager.offeredby_id) }}</h2>
              <h2>{{ showPaymentStatus(wager.offeredby_id) }}</h2>
            </v-col>
            <v-col class="py-0">
              <h1 class="text-center">Taken By: {{ wager.takenby }}</h1>
              <h2>{{ showWinLoss(wager.takenby_id) }}</h2>
              <h2>{{ showPaymentStatus(wager.takenby_id) }}</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col class="py-0">
              <h1>{{ wager.game.awayteam }}</h1>
              <h1>{{ wager.game.hometeam }}</h1>
            </v-col>
            <v-col class="py-0">
              <h1 class="text-right">{{ wager.game.awayteamscore }}</h1>
              <h1 class="text-right">{{ wager.game.hometeamscore }}</h1>
            </v-col>
            <v-col class="py-0">
              <h2 class="text-center">Final</h2>
              <h2 class="text-center">{{ wager.game.gamedate_formatted }}</h2>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-expand-transition>

    <v-card-actions class="pa-0">
      <div
        class="vig-card-expand-container"
        @click="expand = !expand"
      >
        <v-icon>{{ (expand) ? 'arrow_drop_up' : 'arrow_drop_down' }}</v-icon>
      </div>
    </v-card-actions>

  </v-card>

</template>

<script>
export default {
  name: 'WagerInfo',
  props: {
    wager: Object,
    expanded: {
      type: [Boolean, String],
      default: false
    }
  },
  data: function () {
    return {
      expand: this.expanded
    }
  },
  methods: {
    showWinLoss (userId) {
      return (this.wager.winner_id === userId) ? 'WINNER' : 'LOSER'
    },
    showPaymentStatus (userId) {
      if (this.wager.winner_id === userId) {
        return 'Received: ' + ((this.wager.paymentreceived) ? this.wager.paymentreceived : 'no')
      } else {
        return 'Sent: ' + ((this.wager.paymentsent) ? this.wager.paymentsent : 'no')
      }
    }
  }
}
</script>
