var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 18 18",
        "aria-labelledby": _vm.name,
        role: "presentation"
      }
    },
    [
      _c("title", { attrs: { id: _vm.name, lang: "en" } }, [
        _vm._v(_vm._s(_vm.name) + " icon")
      ]),
      _c(
        "g",
        { attrs: { fill: _vm.color } },
        [
          _vm.name == "pending" ? _c("icon-pending") : _vm._e(),
          _vm.name == "bullhorn" ? _c("icon-bullhorn") : _vm._e(),
          _vm.name == "bestvalue" ? _c("icon-best-value") : _vm._e(),
          _vm.name == "favorite" ? _c("icon-favorite") : _vm._e(),
          _vm.name == "group" ? _c("icon-group") : _vm._e(),
          _vm.name == "schedule" ? _c("icon-schedule") : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }