<style scoped>
</style>
<template>
  <v-card
    v-if="isAllowedToInvite"
    class="vig-card"
  >
    <v-card-text class="vig-card-expand">
      <div>
        <v-text-field
          v-model="form.email"
          type="email"
          label="Email"
          placeholder="Friend's Email"
          :error-messages="getErrors('email')"
          @blur="$v.form.email.$touch()"
          required
        ></v-text-field>
      </div>
      <div class="mb-2 mt-n2 center"><b>AND/OR</b></div>
      <div>
        <v-text-field
          v-model.trim="form.phone"
          label="Phone"
          placeholder="Friend's cell phone"
          :error-messages="getErrors('phone')"
          @blur="$v.form.phone.$touch()"
          required
        ></v-text-field>
      </div>
      <div>
        <v-textarea
          solo
          v-model="form.message"
          label="Personalized Message"
          :error-messages="getErrors('message')"
          @blur="$v.form.message.$touch()"
          maxlength="250"
        ></v-textarea>
      </div>
      <v-row v-if="grouplist.length > 0">
        <v-col cols="12" class="pt-0">Add your invitee to an existing group?</v-col>
        <v-col cols="12" class="py-0">
          <v-checkbox v-for="group in grouplist" :key="group.id" :label="group.name" :value="group.id" class="py-0 pl-4 mt-n3" v-model="form.groups"></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="text-right" @click.prevent="invite">Invite</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import UserService from '@/services/UserService'
import AccountService from '@/services/AccountService'
import { validationMixin } from 'vuelidate'
import {
  requiredIf,
  email,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'
const cellPhone = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  } else {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value.trim())
  }
}

export default {
  name: 'InviteCreate',
  mixins: [validationMixin],
  props: {
    grouplist: Array
  },
  data: function () {
    return {
      form: {
        name: null,
        email: null,
        phone: null,
        message: null,
        groups: []
      }
    }
  },
  validations: {
    form: {
      email: {
        required: requiredIf(function () {
          return !this.form.phone || this.form.phone === ''
        }),
        email
      },
      phone: {
        required: requiredIf(function () {
          return !this.form.email || this.form.email === ''
        }),
        cellPhone,
        minLength: minLength(10)
      },
      message: {
        maxLength: maxLength(250)
      }
    }
  },
  computed: {
    isAllowedToInvite: function () {
      return UserService.getUser().allowinvite
    }
  },
  methods: {
    getErrors (fieldName) {
      const err = []
      if (!this.$v.form[fieldName].$dirty) return err
      if (fieldName === 'email') {
        !this.$v.form.email.required && err.push('Email is required')
        !this.$v.form.email.email && err.push('Please enter a valid email')
      }
      if (fieldName === 'phone') {
        !this.$v.form.phone.required && err.push('Phone is required')
        !this.$v.form.phone.cellPhone && err.push('Please enter a valid number')
        !this.$v.form.phone.minLength && err.push('Phone must be at least 10 characters')
      }
      if (fieldName === 'message') {
        !this.$v.form.message.maxLength && err.push('250 characters is the maximum')
      }
      return err
    },
    async invite () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if ((!this.form.phone || this.form.phone.trim() === '') && (!this.form.email || this.form.email.trim() === '')) {
        self.$notify({
          group: 'errors',
          title: 'Phone or email required!',
          text: 'A valid mobile phone number or email is required.',
          type: 'warn'
        })
        return false
      }

      // remove delimiters from phone number
      if (this.form.phone) {
        this.form.phone = this.form.phone.replace(/\D+/g, '').trim()
      }

      this.$updateloading(1)
      AccountService.createInvite(this.form).then(
        (response) => {
          this.form.name = null
          this.form.email = null
          this.form.phone = null
          this.form.message = null
          this.$v.$reset()
          this.$emit('updateinvites')
          this.$updateloading(-1)
          this.$notify({
            group: 'notices',
            title: 'Invitation Successful',
            text: 'An invitation has been sent.',
            type: 'success'
          })
          // on successful invite creation
          this.$gtag.event('create', {
            event_category: 'invite',
            event_label: 'created',
            value: this.form
          })
        },
        (error) => {
          this.$updateloading(-1)
          this.$notify({
            group: 'errors',
            title: 'Invitation Failed',
            text: error.response.data.error,
            type: 'warn'
          })
        }
      )
    }
  },
  watch: {
    grouplist: function (n, o) {
      if (n !== o) {
        if (n.length === 1) {
          this.form.groups = []
          this.form.groups.push(n[0].id)
        }
      }
    }
  }
}
</script>
