var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "elevation-1",
    attrs: {
      headers: _vm.headers,
      items: _vm.transactions,
      "items-per-page": _vm.itemsPerPage,
      loading: _vm.loading,
      "loading-text": "Loading...",
      "server-items-length": _vm.totalItems,
      "mobile-breakpoint": "0"
    },
    on: { pagination: _vm.onPagination },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c(
              "v-toolbar",
              { attrs: { flat: "" } },
              [
                _c("v-toolbar-title", [_vm._v("Transaction History")]),
                _c("v-divider", {
                  staticClass: "mx-4",
                  attrs: { inset: "", vertical: "" }
                }),
                _c("v-spacer")
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "item.amount",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("span", { staticClass: "right-align" }, [
              _vm._v(_vm._s(_vm.formatAmount(item.amount)))
            ])
          ]
        }
      },
      {
        key: "item.status",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-chip",
              { attrs: { color: _vm.statusColor(item.status), dark: "" } },
              [_vm._v(" " + _vm._s(item.status) + " ")]
            )
          ]
        }
      },
      {
        key: "item.type",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-chip",
              { attrs: { color: _vm.typeColor(item.type), dark: "" } },
              [_vm._v(" " + _vm._s(item.type) + " ")]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }