var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm._v(" W/L " + _vm._s(_vm.record.wins) + "-" + _vm._s(_vm.record.losses))
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }