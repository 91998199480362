var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.releasenotes && _vm.releasenotes.length > 0
    ? _c(
        "div",
        [
          _c(
            "v-card",
            {
              staticClass: "release-note-card card",
              attrs: { loading: _vm.loading }
            },
            [
              _vm.releasenotes[0].headline
                ? _c("v-card-title", { staticClass: "py-1" }, [
                    _vm._v(_vm._s(_vm.releasenotes[0].headline))
                  ])
                : _vm._e(),
              _c("v-card-text", {
                staticClass: "pt-0 pb-2 black--text",
                domProps: { innerHTML: _vm._s(_vm.releasenotes[0].message) }
              }),
              _c(
                "v-card-actions",
                { staticClass: "pt-0 pb-1" },
                [
                  _c(
                    "v-flex",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            color: "secondary",
                            loading: _vm.loading,
                            elevation: "3"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.acknowledge(_vm.releasenotes[0].id)
                            }
                          }
                        },
                        [_vm._v("Got It!")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }