<style scoped>
  .row-dark {
    background-color: #ebebeb;
  }
</style>

<template>
  <div>
    <div
      v-if="user_id && user_id != 0 && userdata"
      style="font-size: 1.1rem"
    >
      <v-row class="mb-0 mt-n3 stats-banner">
        <v-col cols="7" class="py-0 white--text robo">
          {{ userdata.nickname }}
        </v-col>
        <v-col cols="5" class="py-0">
          <v-rating
            length="5"
            v-model="userdata.rating.rating"
            half-increments
            readonly
            class="pull-right"
            v-if="userdata.rating.rating > 0"
          >
            <template v-slot:item="props">
              <v-icon
                :color="(props.isFilled || props.isHalfFilled) ? 'yellow darken-2' : 'grey lighten-1'"
                class="pa-0"
              >
                {{ props.isHalfFilled ? 'star_half' : 'grade' }}
              </v-icon>
            </template>
          </v-rating>
          <span v-else>
          </span>
        </v-col>
      </v-row>

      <div class="px-2">
        <v-row class="my-0 px-1" justify="space-between">
          <v-col align="center">
            <div class="stat-tiles background-green text-center robo" justify="center">
              <div class="center">WIN<br>{{ userdata.wins }}</div>
            </div>
          </v-col>
          <v-col align="center">
            <div class="stat-tiles background-red text-center robo">
              <div class="center">LOSS<br>{{ userdata.losses }}</div>
            </div>
          </v-col>
          <v-col align="center">
            <div class="stat-tiles background-blue text-center robo">
              <div class="center">TIE<br>{{ userdata.ties }}</div>
            </div>
          </v-col>
        </v-row>

        <v-row
          class="black--text my-0"
          justify="space-between"
        >
          <v-col class="text-left py-0">Win Percentage</v-col>
          <v-col class="text-right py-0">
            <span>{{ Math.floor(userdata.winperc) }}%</span>
          </v-col>
        </v-row>

        <v-row class="black--text my-0" justify="space-between">
          <v-col cols="10" class="text-left py-0"
            >Avg hours to settle bet</v-col
          >
          <v-col class="text-right py-0">
            <span v-if="userdata.timetosettle">
              {{ userdata.timetosettle.toFixed(2) }}
            </span>
            <span v-else>---</span>
          </v-col>
        </v-row>

        <v-row class="black--text my-0 row-dark" justify="space-between">
          <v-col cols="10" class="text-left py-0"
            >Avg hours to confirm payment</v-col
          >
          <v-col class="text-right py-0">
            <span v-if="userdata.timetoconfirm">{{
              userdata.timetoconfirm.toFixed(2)
            }}</span>
            <span v-else>---</span>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/UserService'

export default {
  data () {
    return {
      userdata: null,
      userid: 0
    }
  },
  props: {
    user_id: {
      type: Number,
      default: -1
    }
  },
  mounted () {
    if (this.user_id === -1) {
      const user = UserService.getUserObject()
      if (user && user.info) {
        this.userid = user.info.id
      }
    } else {
      this.userid = this.user_id
    }
    this.init()
  },
  methods: {
    init () {
      this.$updateloading(1)
      if (!this.userid || this.userid <= 0) {
        return
      }
      UserService.getUserStats(this.userid)
        .then(
          (response) => {
            this.userdata = response.data
          },
          (err) => {
            console.log(err)
          }
        )
        .finally(() => {
          this.$updateloading(-1)
        })
    }
  },
  filters: {
    money (value) {
      if (!value) return '$0'
      else if (value < 0) return '-$' + value * -1
      return '$' + value
    },
    positive (val) {
      if (!val) return '$0'
      return '+ ' + val
    },
    negative (val) {
      if (!val) return '$0'
      return '- ' + val
    }
  }
}
</script>

<style>
.wrap-link {
  word-wrap: break-word;
  white-space: normal;
}
</style>
