<template>
  <v-row
    class="tourney-team ma-0"
    :class="getTeamClass"
    no-gutters
  >
    <v-col
      class="py-1 px-1 bordered-right"
      cols="9"
      @click.prevent="chooseWager(game.game, (isHomeTeam) ? game.game.hometeam_id : game.game.awayteam_id, 'line')"
    >
      <v-row
        class="teambox ma-0"
        align="center"
      >
        <v-col
          cols="9"
          class="pa-0"
          align="left"
        >
          <h2>{{ tournament.assignedteams[game.round][team].nickname }}</h2>
          <h1>{{ (team === game.favorite) ? game.favorite_team : game.underdog_team }}</h1>
        </v-col>

        <v-col
          class="pa-0"
          align="right"
        >
          <h1>{{ showSpread() }}</h1>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      class="pa-0 text-center"
      align-self="center"
      @click.prevent="chooseWager(game.game, (team === game.favorite) ? game.game.hometeam_id : game.game.awayteam_id, (team === game.favorite) ? 'over' : 'under')"
    >
      <h1 v-if="game.game && game.game.hometeamscore">{{ (isHomeTeam) ? game.game.hometeamscore : game.game.awayteamscore }}</h1>
      <h1 v-else>
        {{ (game.game && game.game.ou_line) ? game.game.ou_line : 'N/A' }}
        <v-icon small>{{ (team === game.favorite) ? 'mdi-arrow-up' : 'mdi-arrow-down' }}</v-icon>
      </h1>
    </v-col>
  </v-row>
</template>

<script>
import GameService from '@/services/GameService'

export default {
  name: 'TournamentTeam',

  props: {
    group_id: {
      type: [String, Number],
      default: '0'
    },
    user_id: {
      type: [String, Number],
      default: '0'
    },
    tournament: Object,
    game: Object,
    team: Number
  },

  computed: {
    isUserTeam: function () {
      var ismine = false
      if (+this.user_id > 0 && this.tournament.assignedteams && this.tournament.assignedteams[this.game.round]) {
        ismine = (+this.tournament.participant[this.tournament.assignedteams[this.game.round][this.team].vigcontestentryid].user_id === +this.user_id)
      }
      return ismine
    },

    isHomeTeam: function () {
      var isHome = false
      if (this.tournament.teams[this.game.underdog].seed === this.tournament.teams[this.game.favorite].seed) {
        // seeds are equal, just assume the home team is our favorited
        isHome = (this.team === this.game.favorite)
      } else if (this.team === this.game.favorite) {
        if (this.game.favorite_seed > this.game.underdog_seed) {
          // fav and dog were switched, the higher seed is favored
          isHome = false
        } else {
          isHome = true
        }
      } else {
        // we want the underdog team
        // isHome = (this.tournament.teams[this.team].seed < this.tournament.teams[this.game.favorite].seed)
        if (this.game.favorite_seed > this.game.underdog_seed) {
          // fav and dog were switched, the higher seed is favored
          isHome = true
        } else {
          isHome = false
        }
      }
      return isHome
    },

    getTeamClass: function () {
      var classes = (this.isUserTeam) ? 'myteam ' : 'opponent '
      if (this.team === this.game.favorite) {
        classes += 'bordered-bottom '
      }
      if (this.game.winner !== 'undecided') {
        if (this.team === this.game.favorite) {
          // showing the favorite team
          if (this.game.winner === 'favorite') {
            classes += 'team-winner'
          } else {
            classes += 'team-loser'
          }
        } else {
          if (this.game.winner === 'favorite') {
            classes += 'team-loser'
          } else {
            classes += 'team-winner'
          }
        }
      }
      return classes
    }
  },

  methods: {
    chooseWager (game, teamId, bettype) {
      if (!game) {
        console.log('invalid game')
        return false
      }
      if (game.gamestatus !== 'Upcoming') {
        console.log('game started')
        return false
      }
      if ((bettype === 'over' || bettype === 'under') && !game.ou_line) {
        alert('This game does not currently have an OU line set. Lines are updated frequently. Please check back soon.')
      } else {
        this.$router.push({ name: 'wager', params: { game_id: game.id, team_id: teamId, type: bettype, section: 'contest_' + this.group_id + '_' + this.tournament.vigcontestid } })
      }
    },

    showSpread () {
      var spread = null
      if (this.game.spread) {
        spread = this.game.spread * -1
        // final spread set
        return this.displaySpread(spread, (this.team === this.game.favorite) ? 'home' : 'away')
      } else if (this.game.game) {
        spread = this.game.game.ats_line
        if (!this.isHomeTeam && this.team === this.game.favorite) {
          // this team is the lower seed
          return this.displaySpread(spread, 'away')
        } else if (this.isHomeTeam && this.team === this.game.underdog) {
          return this.displaySpread(spread, 'home')
        } else {
          return this.displaySpread(spread, (this.team === this.game.favorite) ? 'home' : 'away')
        }
      } else {
        return this.displaySpread(null, 'home')
      }
    },

    displaySpread (spread, team) {
      return GameService.showSpread(spread, team)
    }
  }
}
</script>

<style scoped>
.myteam {
  background-color: #b4daf7 !important;
}
.my-entries .team-winner,
.my-entries .team-loser,
.bracket .team-winner,
.bracket .team-loser,
.bracket .opponent .team-winner {
  background-color: #e0e0e0 !important;
}
.my-entries .myteam.team-winner,
.bracket .team-winner {
  background-color: #beffba !important;
}

.my-entries .myteam.team-loser,
.bracket .myteam.team-loser {
  background-color: rgb(235, 191, 191) !important;
}

/*
.tourney-team .team-winner {
  background-color: #beffba;
}

.team-loser {
  background-color: #e0e0e0;
}
.myteam {
  background-color: #fff59d !important;
}

.mygame >>> .team-winner {
  background-color: #e0e0e0 !important;
}

.myteam.team-winner {
  background-color: #beffba !important;
}

.myteam.team-loser {
  background-color: rgb(235, 191, 191) !important;
}
*/
h2 {
  color: black;
  padding: 0;
  margin: 0;
  text-transform: none;
  font-size: 0.8em;
}
h1 {
  color: black;
  padding: 0;
  margin: 0;
  font-size: 1.1em;
  white-space: nowrap;
}
.bordered-bottom {
  border-bottom: 1px solid black !important;
}
.bordered-left {
  border-left: 1px solid black;
}
.bordered-right {
  border-right: 1px solid black;
}
</style>
