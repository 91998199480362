import router from '@/router'
import { bus } from '@/main'
import axios from 'axios'
import moment from 'moment'
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

const UserService = {

  baseUrl () {
    if (typeof process !== 'undefined' && process.env.NODE_ENV === 'development') {
      return 'https://vig-zero-api.dev.aimitservices.com/'
    } else if (typeof process !== 'undefined' && process.env.NODE_ENV === 'staging') {
      return process.env.VUE_APP_STAGING_API_URL
    } else {
      return 'https://api.vigzero.com/'
    }
  },

  async verifyUser () {
    const user = this.getUser()
    if (!user || !user.id) {
      return null
    }

    return axios({
      url: this.baseUrl() + 'account/verify',
      method: 'post',
      data: { id: user.id },
      headers: {
        common: {
          Authorization: 'Bearer ' + this.getToken()
        }
      }
    })
  },

  setLocalStorages (user, date = new Date()) {
    // storing release notes separate from user
    localStorage.setItem('releasenotes', JSON.stringify(user.releasenotes))
    delete user.releasenotes
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('lastEntry', JSON.stringify(date))
    bus.$emit('localStorageUpdate', date)
  },

  needToRefreshAuthData () {
    const lastEntry = JSON.parse(localStorage.getItem('lastEntry'))
    const curt = new Date()
    return (!lastEntry || moment(curt).diff(moment(lastEntry), 'minutes') >= 15)
  },

  /**
   * Refreshes the vigzero user data, this also
   * verifies if the account is still valid
   */
  async refreshAuthData (redirect = true) {
    const userResponse = await this.verifyUser()
    if (userResponse) {
      this.setLocalStorages(userResponse.data)
      console.log(localStorage.getItem('user'))
      return true
    } else if (redirect) {
      this.redirectToLogin('Your session has expired. Please login again.')
    }
    return false
  },

  async authHeader (redirect = true, from = 'unknown') {
    const user = this.getToken()
    // if no user storage, return null
    if (!user && redirect) {
      console.log('No login from method: ' + from)
      this.redirectToLogin('Your session has expired. Please login again.')
      return
    }

    // check if we need to refresh user token
    // if true, refresh them
    var needRefresh = this.needToRefreshAuthData()
    if (needRefresh) {
      const result = await this.refreshAuthData(redirect)
      if (result === false) {
        return false
      }
    }

    axios.defaults.baseURL = this.baseUrl()
    axios.defaults.headers.common.Authorization = 'Bearer ' + user

    // return authorization header with jwt token
    return {
      Authorization: 'Bearer ' + user
    }
  },

  getToken () {
    const user = this.getUserObject()
    if (user === null) {
      this.logout()
      return null
    } else {
      return user.key
    }
  },

  getAvailableLimit () {
    var userinfo = this.getUserObject()
    if (!userinfo) {
      return 1000
    }
    return parseInt(userinfo.betinfo.available) || 0
  },

  async getReleaseNotes () {
    const temp = localStorage.getItem('releasenotes')
    if (temp !== null) {
      return JSON.parse(localStorage.getItem('releasenotes'))
    } else {
      return []
    }
  },

  async acknowledgeReleaseNote (id) {
    return axios({
      url: this.baseUrl() + 'releasenotes/acknowledge',
      data: JSON.stringify({ id: id }),
      method: 'post'
    })
  },

  async register (formdata) {
    return axios({
      url: this.baseUrl() + 'register',
      method: 'post',
      data: JSON.stringify(formdata)
    }).then(async response => {
      // login successful if there's a jwt token in the response
      if (response && response.data && response.data.key) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.setLocalStorages(response.data)
        await this.authHeader()
      }
      return response
    })
  },

  async getRegistrationCode (code) {
    return axios({
      url: this.baseUrl() + 'register/code',
      data: JSON.stringify({ code: code }),
      method: 'post'
    })
  },

  async verifyActivationCode (activationcode) {
    await this.authHeader()
    return axios({
      url: this.baseUrl() + 'account/verifyphone',
      data: JSON.stringify({ activationcode: activationcode }),
      method: 'post'
    })
  },

  async resendActivationCode () {
    await this.authHeader()
    return axios({
      url: this.baseUrl() + 'account/verifyphone/resend',
      method: 'post'
    })
  },

  async verifyVenmoHandle (handle) {
    const user = this.getToken()
    var id = 0
    if (user) {
      await this.authHeader()
      id = this.getUserObject().info.id
    }
    return axios({
      url: this.baseUrl() + 'register/venmo',
      data: JSON.stringify({ handle: handle, user_id: id }),
      method: 'post'
    })
  },

  async login (username, password) {
    return axios({
      url: this.baseUrl() + 'login',
      method: 'post',
      data: JSON.stringify({ email: username, password: password })
    }).then(async response => {
      // login successful if there's a jwt token in the response
      if (response && response.data && response.data.key) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.setLocalStorages(response.data)
        await this.authHeader()
      }
      return response.data
    })
  },

  logout () {
    delete axios.defaults.headers.common.Authorization
    // remove user from local storage to log user out
    const slideviewed = localStorage.getItem('slideviewed')
    const code = localStorage.getItem('invitecode')
    localStorage.clear()
    if (slideviewed !== null) {
      localStorage.setItem('slideviewed', slideviewed)
    }
    if (code !== null) {
      localStorage.setItem('invitecode', code)
    }
    bus.$emit('localStorageUpdate', new Date())
    // localStorage.removeItem('user');
  },

  loggedIn () {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user === null) {
      return false
    } else if (typeof user.key === 'undefined') {
      this.logout()
    } else {
      return true // todo verify token and expiration stuff
    }
  },

  isAdmin () {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user === null) {
      return false
    } else if (typeof user.key === 'undefined') {
      this.logout()
    } else {
      return user.info.isadmin
    }
  },

  isTrusted () {
    const user = this.getUserObject()
    return ((user && user.trusted) || !user)
  },

  isTrustedRating () {
    const user = this.getUserObject()
    const rating = parseFloat(user.rating) || 0
    return (rating >= 4)
  },

  activated () {
    const user = this.getUserObject()
    return (user && user.activated)
  },

  getUserObject () {
    const temp = localStorage.getItem('user')
    if (temp !== null) {
      return JSON.parse(localStorage.getItem('user'))
    } else {
      return null
    }
  },

  getUser () {
    const temp = this.getUserObject()
    if (temp !== null) {
      return temp.info
    } else {
      return null
    }
  },

  async getGroups () {
    await this.authHeader()
    return axios({
      url: this.baseUrl() + 'groups',
      method: 'get'
    }).then(async response => {
      return response
    })
  },

  async getUserBalance (userId) {
    await this.authHeader()
    return axios({
      url: this.baseUrl() + 'transaction/currentbalance/' + userId,
      method: 'get'
    }).then(async response => {
      return response
    })
  },

  async getUserBalanceRecurringCall (userId) {
    await this.authHeader()
    return axios({
      url: this.baseUrl() + 'transaction/currentbalancerecurring/' + userId,
      method: 'get'
    }).then(async response => {
      return response
    })
  },

  handleResponse (response) {
    const self = this
    return response.text().then(text => {
      const data = text && JSON.parse(text)
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          self.logout()
          // location.reload(true);
        }

        const error = (data && data.error) || response.statusText
        return Promise.reject(error)
      }

      return Promise.resolve(data)
    })
  },

  redirectToLogin (message) {
    this.logout()
    router.push('/login', () => {
      Vue.notify({
        group: 'notices',
        title: 'Logout Complete',
        text: message,
        type: 'danger'
      })
    })
  },

  async updateUserInfo (userinfo) {
    await this.authHeader()
    return axios({
      url: this.baseUrl() + 'account/updateuserinfo',
      data: JSON.stringify(userinfo),
      method: 'post'
    })
  },

  async updatepassword (pwinfo) {
    await this.authHeader()
    return axios({
      url: this.baseUrl() + 'account/updatepassword',
      data: JSON.stringify(pwinfo),
      method: 'post'
    })
  },

  async updatepayment (paymentinfo) {
    await this.authHeader()
    return axios({
      url: this.baseUrl() + 'account/updatepayment',
      data: JSON.stringify(paymentinfo),
      method: 'post'
    })
  },

  async uploadavatar (file) {
    await this.authHeader()
    return axios({
      url: this.baseUrl() + 'account/updateavatar',
      data: file,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },

  async getAvatar () {
    await this.authHeader()
    return axios({
      url: this.baseUrl() + 'account/getavatar',
      method: 'get'
    })
  },

  async resetpassword (data) {
    return axios({
      url: this.baseUrl() + 'account/resetpassword',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async requestresetpassword (data) {
    return axios({
      url: this.baseUrl() + 'account/forgotpassword',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async getUserStats (id) {
    await this.authHeader()
    return axios({
      url: this.baseUrl() + 'account/stats/' + id,
      method: 'get'
    })
  },

  async getreleasenotes () {
    return axios({
      url: this.baseUrl() + 'releasenotes/get',
      method: 'get'
    })
  },

  async setintroslideviewed () {
    await this.authHeader()
    var user = this.getUserObject()
    if (user && user.info.introslides) {
      user.info.introslides = '1'
      localStorage.setItem('user', JSON.stringify(user))
    }
    return axios({
      url: this.baseUrl() + 'account/setintroslideviewed',
      method: 'get'
    })
  }
}

export default UserService
