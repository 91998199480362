<style scoped>
</style>
<template>
  <v-card
    class="vig-card mb-4"
    v-if="dispute && dispute.id && !dispute.closed"
  >
    <v-card-title
      class="py-0"
      @click="expand = !expand"
    >
      Suspend Users
    </v-card-title>

    <v-expand-transition>
      <div
        v-if="expand"
        class="vig-card-expand"
        style="margin-top: 0.5em;"
      >
        <v-card-text>
          <v-select
            v-model="form.duration"
            placeholder="Duration of Suspension"
            :items="suspensionDurations"
            item-text="name"
            item-value="length"
            label="Duration"
          ></v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <div class="text-center">
            <p><strong>{{ (dispute.winner_id === dispute.offeredby_id) ? dispute.offeredby : dispute.takenby }}</strong></p>
            <v-btn
              @click="suspend(dispute.winner_id)"
              :disabled="$v.$invalid"
              small
              dense
              color="error"
            >Suspend Winner</v-btn>
          </div>
          <v-spacer></v-spacer>
          <div class="text-center">
            <p><strong>{{ (dispute.loser_id === dispute.offeredby_id) ? dispute.offeredby : dispute.takenby }}</strong></p>
            <v-btn
              @click="suspend(dispute.loser_id)"
              :disabled="$v.$invalid"
              dense
              color="error"
              small
            >Suspend Loser</v-btn>
          </div>
        </v-card-actions>
      </div>
    </v-expand-transition>

    <v-card-actions class="pa-0">
      <div
        class="vig-card-expand-container"
        @click="expand = !expand"
      >
        <v-icon>{{ (expand) ? 'arrow_drop_up' : 'arrow_drop_down' }}</v-icon>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import DisputeService from '@/services/DisputeService'
import { validationMixin } from 'vuelidate'
import {
  required,
  numeric
} from 'vuelidate/lib/validators'

export default {
  name: 'DisputeActions',
  mixins: [validationMixin],
  props: {
    dispute: Object
  },
  data: function () {
    return {
      expand: false,
      form: {
        duration: null,
        user_id: null
      },
      suspensionDurations: [
        { length: 30, name: '30 Days' },
        { length: 60, name: '60 Days' },
        { length: 90, name: '90 Days' },
        { length: 0, name: 'Indefinite' }
      ]
    }
  },
  validations: {
    form: {
      duration: {
        required,
        numeric
      },
      user_id: {
        numeric
      }
    }
  },
  methods: {
    async suspend (userId) {
      this.$updateloading(1)
      this.form.user_id = userId
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          group: 'errors',
          title: 'Invalid Submission',
          text: 'Please correct your form entries and try again.',
          type: 'warn'
        })
      } else {
        DisputeService.suspend(this.dispute.id, this.form).then(response => {
          this.$updateloading(-1)
          this.$notify({
            group: 'notices',
            title: 'User Suspended',
            text: 'The user has been suspended.',
            type: 'info'
          })
        }).catch(errors => {
          this.$updateloading(-1)
          this.$notify({
            group: 'errors',
            title: 'User Suspension Failed',
            text: errors.error,
            type: 'warn'
          })
          console.dir(errors)
        })
      }
    }
  }
}
</script>
