<style scoped>
.game-score-box h3 {
  margin: 0 auto;
  text-align: center;
}
</style>
<template>
  <div>
    <v-row
      justify="space-around"
      class="py-0 my-0 game-score-box"
      v-if="compact && game.scoreboard && game.scoreboard.box"
    >
      <v-col class="py-0 text-center">
        <div v-if="type === 'line'">
          <strong>{{ game.awayabbr }} {{ scoreAway }}</strong>
        </div>
        <div v-else>
          <strong>
            <span v-if="type === 'UNDER'">{{ type }}</span>
            <span v-else>Total: {{ scoreTotal }}</span>
          </strong>
        </div>
      </v-col>

      <v-col class="py-0 text-center">
        <div v-if="type === 'line'">
          <strong>{{ game.homeabbr }} {{ scoreHome }}</strong>
        </div>
        <div v-else>
          <strong>
            <span v-if="type === 'OVER'">{{ type }}</span>
            <span v-else>Total: {{ scoreTotal }}</span>
          </strong>
        </div>
      </v-col>

      <v-col class="py-0 text-center">
        <strong>
          <span v-if="game.hometeamscore">
            <span :class="(result && offerstatus && (offerstatus !== 'WIN' && offerstatus !== 'LOSS' && game.takenby_id)) ? result : ''">
              Final<span v-if="(result && offerstatus && (offerstatus !== 'WIN' && offerstatus !== 'LOSS' && game.takenby_id))"> - {{ ((result === 'winner') ? 'WIN' : 'LOSS') }}</span>
            </span>
          </span>
          <span v-if="!game.hometeamscore && game.scoreboard">
            {{ game.periodname.charAt(0).toUpperCase() }}{{ (game.scoreboard.period) ? game.scoreboard.period : game.scoreboard.box.home.length }}
            {{ (game.scoreboard.time) ? game.scoreboard.time : ((game.sport === 'Baseball') ? ((game.scoreboard.box.home.length == game.scoreboard.box.away.length) ? 'Bot' : 'Top') : '0:00') }}
          </span>
          <span v-if="!game.hometeamscore && !game.scoreboard">{{ game.periodname.charAt(0).toUpperCase() }}1</span>
        </strong>
      </v-col>
    </v-row>

    <v-row
      class="py-0 my-0 game-score-box"
      v-if="!compact && game.scoreboard && game.scoreboard.box"
    >
      <v-col class="py-0">
        <h3 class="text-left"><strong>{{ game.awayabbr }}</strong></h3>
        <h3 class="text-left"><strong>{{ game.homeabbr }}</strong></h3>
      </v-col>
      <v-col
        class="py-0"
        v-for="p in game.periodsize"
        :key="p"
      >
        <h3>{{ (game.scoreboard.box.away.length >= p) ? game.scoreboard.box.away[p - 1] : '-' }}</h3>
        <h3>{{ (game.scoreboard.box.home.length >= p) ? game.scoreboard.box.home[p - 1] : '-' }}</h3>
      </v-col>
      <v-col
        class="py-0"
        style="border-left: 1px solid #dedede;"
      >
        <h3><strong>{{ scoreAway }}</strong></h3>
        <h3><strong>{{ scoreHome }}</strong></h3>
      </v-col>
      <v-col class="py-0 text-center">
        <span v-if="game.scoreboard.period">
          {{ withOrdinalSuffix(game.scoreboard.period) }}<br>
          {{ (game.scoreboard.time) ? game.scoreboard.time : ((game.sport === 'Baseball') ? ((game.scoreboard.box.home.length == game.scoreboard.box.away.length) ? 'Bot' : 'Top') : '0:00') }}
        </span>
        <span
          v-else
          style="line-height: 3em;"
        >
          Final
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'BoxScore',
  props: {
    game: Object,
    compact: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'line'
    },
    result: {
      type: String,
      default: ''
    },
    offerstatus: {
      type: String,
      default: ''
    }
  },
  computed: {
    scoreAway: function () {
      var score = 0
      if (this.game) {
        if (this.game.awayteamscore !== null) {
          score = this.game.awayteamscore
        } else if (this.game.scoreboard && this.game.scoreboard.box) {
          score = this.getBoxScoreTotal(this.game.scoreboard.box.away)
        }
      }
      return score
    },
    scoreHome: function () {
      var score = 0
      if (this.game) {
        if (this.game.hometeamscore !== null) {
          score = this.game.hometeamscore
        } else if (this.game.scoreboard && this.game.scoreboard.box) {
          score = this.getBoxScoreTotal(this.game.scoreboard.box.home)
        }
      }
      return score
    },
    scoreTotal: function () {
      return this.scoreAway + this.scoreHome
    }
  },
  methods: {
    getBoxScoreTotal (scores) {
      let total = 0
      scores.forEach(function (s) {
        total += +s
      })
      return total
    },
    // turn 1, 2, 3 into 1st, 2nd, 3rd, etc
    withOrdinalSuffix (n) {
      if (parseInt(n) > 0) {
        var s = n % 100
        if (s > 3 && s < 21) {
          return n + 'th'
        }
        switch (s % 10) {
          case 1:
            return n + 'st'
          case 2:
            return n + 'nd'
          case 3:
            return n + 'rd'
          default:
            return n + 'th'
        }
      }
      return n
    }
  }
}
</script>
