var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dispute &&
    _vm.dispute.logs &&
    _vm.dispute.logs.length > 0 &&
    _vm.logsWithUploads.length > 0
    ? _c(
        "v-card",
        { staticClass: "vig-card" },
        [
          _c(
            "v-card-title",
            {
              staticClass: "py-0",
              on: {
                click: function($event) {
                  _vm.expand = !_vm.expand
                }
              }
            },
            [_vm._v(" Uploaded Screenshots ")]
          ),
          _c("v-expand-transition", [
            _vm.expand
              ? _c(
                  "div",
                  {
                    staticClass: "vig-card-expand",
                    staticStyle: { "margin-top": "0.5em" }
                  },
                  [
                    _vm._l(_vm.logsWithUploads, function(log) {
                      return _c(
                        "v-card-text",
                        { key: log.id, staticClass: "py-0" },
                        [
                          _c("v-img", {
                            attrs: { src: log.document.url, alt: "Screenshot" }
                          }),
                          _c("p", { staticClass: "text-center" }, [
                            _c("a", { attrs: { href: log.document.url } }, [
                              _vm._v(
                                "Uploaded by " +
                                  _vm._s(
                                    log.user_id === _vm.dispute.disputeuser_id
                                      ? _vm.dispute.disputeuser
                                      : _vm.dispute.againstuser
                                  ) +
                                  " on " +
                                  _vm._s(log.logdate)
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    }),
                    _c("v-divider")
                  ],
                  2
                )
              : _vm._e()
          ]),
          _c("v-card-actions", { staticClass: "pa-0" }, [
            _c(
              "div",
              {
                staticClass: "vig-card-expand-container",
                on: {
                  click: function($event) {
                    _vm.expand = !_vm.expand
                  }
                }
              },
              [
                _c("v-icon", [
                  _vm._v(
                    _vm._s(_vm.expand ? "arrow_drop_up" : "arrow_drop_down")
                  )
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }