var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.dispute && _vm.dispute.id
        ? _c(
            "v-row",
            { staticClass: "mt-0 mb-4" },
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "vig-card" },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "py-0",
                          on: {
                            click: function($event) {
                              _vm.expand = !_vm.expand
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.dispute.disputetype) + " ")]
                      ),
                      _vm.dispute.closed
                        ? _c("v-card-text", [
                            _c("p", { staticClass: "text-center" }, [
                              _vm._v("This dispute has been closed.")
                            ]),
                            _c("p", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(_vm.dispute.closed))
                            ])
                          ])
                        : _vm._e(),
                      !_vm.dispute.closed &&
                      !_vm.requiresActionFromOwner &&
                      !_vm.requiresActionFromOther
                        ? _c("v-card-text", [
                            _c("p", { staticClass: "text-center" }, [
                              _vm._v(
                                "This dispute is under review and currently requires no action from you."
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.requiresActionFromOwner
                        ? _c(
                            "v-card-text",
                            [
                              _vm.isDisputeOwner
                                ? _c("p", { staticClass: "text-center" }, [
                                    _vm._v(
                                      "Please upload a screenshot of your proof of payment."
                                    )
                                  ])
                                : _vm._e(),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c("p", { staticClass: "text-center" }, [
                                      _vm._v("Venmo Instructions")
                                    ]),
                                    _c("ol", [
                                      _c("li", [
                                        _vm._v("Find the transaction you paid")
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "Click on icon of person you paid"
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v("Choose feed 'Between You'")
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "Screen shot should include Venmo handle of person you paid with proof of your payment."
                                        )
                                      ])
                                    ])
                                  ])
                                ],
                                1
                              ),
                              !_vm.isDisputeOwner
                                ? _c("p", { staticClass: "text-center" }, [
                                    _vm._v(
                                      "This dispute is awaiting a response from " +
                                        _vm._s(_vm.dispute.disputeuser) +
                                        " and currently requires no action from you."
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.requiresActionFromOther
                        ? _c(
                            "v-card-text",
                            { staticClass: "py-0" },
                            [
                              _vm.isDisputeOwner
                                ? _c("p", { staticClass: "text-center" }, [
                                    _vm._v(
                                      "This dispute is awaiting a response from " +
                                        _vm._s(_vm.dispute.againstuser) +
                                        " and currently requires no action from you."
                                    )
                                  ])
                                : _vm._e(),
                              !_vm.isDisputeOwner
                                ? _c("p", { staticClass: "text-center" }, [
                                    _vm._v(
                                      "Please upload a screenshot of your proof of payment."
                                    )
                                  ])
                                : _vm._e(),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c("p", { staticClass: "text-center" }, [
                                      _vm._v("Venmo Instructions")
                                    ]),
                                    _c("ol", [
                                      _c("li", [
                                        _vm._v("Find the transaction you paid")
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "Click on icon of person you paid"
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v("Choose feed 'Between You'")
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "Screen shot should include Venmo handle of person you paid with proof of your payment."
                                        )
                                      ])
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-expand-transition", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.expand,
                                expression: "expand"
                              }
                            ],
                            staticClass: "vig-card-expand",
                            staticStyle: { "margin-top": "0.5em" }
                          },
                          [
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "my-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        class:
                                          "py-0 " +
                                          _vm.getValidationClass("upload"),
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c("v-file-input", {
                                          ref: "upload",
                                          attrs: {
                                            label:
                                              "Screenshot of Proof of Payment",
                                            accept: "image/*",
                                            required: ""
                                          },
                                          on: { change: _vm.setFileUpload },
                                          model: {
                                            value: _vm.form.upload,
                                            callback: function($$v) {
                                              _vm.$set(_vm.form, "upload", $$v)
                                            },
                                            expression: "form.upload"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c("v-textarea", {
                                          attrs: {
                                            label: "Comment",
                                            outlined: "",
                                            "auto-grow": "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.form.description,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "description",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "form.description"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-card-actions",
                              { staticClass: "text-right" },
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "primary",
                                      dense: "",
                                      disabled: _vm.$v.$invalid || _vm.loading
                                    },
                                    on: { click: _vm.update }
                                  },
                                  [_vm._v("Update Dispute")]
                                ),
                                _c("v-spacer")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("v-card-actions", { staticClass: "pa-0" }, [
                        _c(
                          "div",
                          {
                            staticClass: "vig-card-expand-container",
                            on: {
                              click: function($event) {
                                _vm.expand = !_vm.expand
                              }
                            }
                          },
                          [
                            _c("v-icon", [
                              _vm._v(
                                _vm._s(
                                  _vm.expand
                                    ? "arrow_drop_up"
                                    : "arrow_drop_down"
                                )
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("WagerInfo", { attrs: { wager: _vm.wager, expanded: "true" } }),
      _c("DisputeImages", { attrs: { dispute: _vm.dispute } }),
      _c("DisputeLog", { attrs: { dispute: _vm.dispute } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }