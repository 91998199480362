<style scoped></style>
<template>
  <div class="wrapper fadeInDown">
    <div id="verify-form">
      <!-- Verification Form -->
      <form
        class="form"
        @submit.prevent="verify()"
      >
        <v-card>
          <v-card-title>
            <div>Phone Verification</div>
          </v-card-title>
          <v-card-text>
            <v-row class="my-0">
              <v-col
                cols="12"
                class="py-0"
              >
                <v-text-field
                  v-model.trim="form.activationcode"
                  label="Activation Code"
                  required
                  minlength="6"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  autocomplete="one-time-code"
                  ref="code"
                  @keydown="filterKey"
                  :disabled="sending"
                  :loading="sending"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn
              @click="resend()"
              :disabled="sending"
            >Resend</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              :disabled="sending"
              color="primary"
            >Submit</v-btn>
          </v-card-actions>
        </v-card>
      </form>
      <!-- Deposit Information Verbiage -->
      <div style="font-size: 1.1rem" class="my-0 py-0 mt-2 mb-2">
        <v-card class="pa-4 mt-4">
          <v-card-title class="headline font-weight-bold">
            Initiate Deposit
          </v-card-title>
          <v-card-text>
            VigZero not only saves you with zero vigorish, we NOW save you 3% on deposits as it's free to deposit with us. We are using Venmo in order to fund your account, so make your deposit now.
            <br /><br />
            <strong>How to make a deposit to VigZero:</strong>
            <ol>
              <li>Record how much you are going to send to @VigZero via Venmo.</li>
              <li>Once we receive verification from Venmo, your account will be in good standing.</li>
              <li>Prior to verification, you can make offers and/or take wagers. Please note that if any expected deposits are not made by game time, your wagers are subject to be voided.</li>
              <li>Withdraw requests can be made at any time; however, withdrawals are not allowed if there are any pending or unsettled wagers on ongoing games.</li>
            </ol>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import TransactionService from '@/services/TransactionService'

export default {
  name: 'VerifyForm',
  data: () => {
    return {
      form: {
        activationcode: ''
      },
      user: null,
      sending: false
    }
  },
  mounted: function () {
    if (UserService.activated() && this.$route.path !== '/dashboard') {
      this.$nextTick(() => {
        this.$router.replace('/dashboard').catch(err => console.warn('Navigation error:', err))
      })
    }
  },
  // mounted: () => {
  //   this.$nextTick(() => {
  //     if (this.$refs.code) {
  //       this.$refs.code.focus()
  //     }
  //   })
  // },
  computed: {
  },
  methods: {
    filterKey (e) {
      const allowedKeys = ['Backspace', 'Delete', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown']
      const regex = /\d+/
      if (allowedKeys.indexOf(e.key) < 0 && !regex.test(e.key)) {
        return e.preventDefault()
      }
    },

    async resend () {
      this.$updateloading(1)
      this.sending = true
      this.form.activationcode = ''
      UserService.resendActivationCode().then(
        (response) => {
          if (response && response.data && response.data.result) {
            this.$notify({
              group: 'notices',
              title: response.data.message,
              text: 'A new activation code was created and will be sent shortly!',
              type: 'info'
            })
          } else {
            this.$notify({
              group: 'errors',
              title: response.data.message,
              text: 'We failed to generate a new activation code! Please try again later.',
              type: 'warn'
            })
          }
          this.sending = false
          this.$updateloading(-1)
        },
        (error) => {
          console.dir(error)
          this.$updateloading(-1)
          this.sending = false
          this.$notify({
            group: 'errors',
            title: 'Activation Code Error',
            text: 'We failed to generate a new activation code! Please try again later.',
            type: 'warn'
          })
        }
      )
    },

    async verify () {
      this.$updateloading(1)
      this.sending = true
      try {
        const response = await UserService.verifyActivationCode(this.form.activationcode)
        if (response?.data?.result) {
          this.$updateloading(-1)
          const authorized = await UserService.refreshAuthData()
          if (authorized) {
            try {
              const transactionresponse = await TransactionService.getLastUserDeposit()
              console.log(transactionresponse.data)
              console.log(transactionresponse.data.id)
              if (transactionresponse.data && transactionresponse.data.id) {
                console.log('if')
                const webURL = `https://venmo.com/vigzero?txn=pay&amount=${transactionresponse.data.amount}&audience=private&note=VZ${transactionresponse.data.id}`
                const venmoURL = `venmo://paycharge?txn=pay&recipients=vigzero&amount=${transactionresponse.data.amount}&note=VZ${transactionresponse.data.id}`
                const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
                const authorized3 = await UserService.refreshAuthData()
                if (authorized3) {
                  this.sending = false
                  this.$router.push(response.data.redirect).then(() => {
                    this.$notify({
                      group: 'notices',
                      title: 'Account Activated',
                      text: 'Your account has been activated.',
                      type: 'info'
                    })

                    if (isIOS) {
                      // Attempt to open the Venmo app
                      window.location.href = venmoURL
                      setTimeout(() => {
                        window.open(webURL, '_blank')
                      }, 1500)
                    } else {
                      // Open the web URL directly for non-iOS devices
                      window.open(webURL, '_blank')
                    }
                  })
                }
              } else {
                console.log('elseeee')
                const authorized2 = await UserService.refreshAuthData()
                if (authorized2) {
                  this.sending = false
                  this.$router.push(response.data.redirect).then(() => {
                    this.$notify({
                      group: 'notices',
                      title: 'Account Activated',
                      text: 'Your account has been activated.',
                      type: 'info'
                    })
                  })
                }
              }
            } catch (error) {
              console.error('Error fetching last deposit:', error)
            }
          }
        } else {
          this.$notify({
            group: 'errors',
            title: 'Activation Failed',
            text: response.data.error,
            type: 'warn'
          })
          this.$updateloading(-1)
          this.sending = false
        }
      } catch (error) {
        this.$updateloading(-1)
        this.sending = false
        this.form.activationcode = ''
        this.$notify({
          group: 'errors',
          title: 'Activation Failed',
          text: (error.response.data.errors.length > 0) ? error.response.data.errors[0] : error.response.data.error,
          type: 'warn'
        })
      }
    }

  },
  created: function () {
    // reset any loading stuff in case we were
    // redirected from another module and auth expired
    this.$updateloading(-999)
  }
}
</script>
