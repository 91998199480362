
<template>
  <div class="wrapper fadeInDown">
    <v-form @submit.prevent="resetpassword()">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>Password Reset</v-card-title>
            <v-card-text>
              <v-text-field
                label="New Password"
                name="password"
                id="password"
                type="password"
                v-model="form.password"
                :disabled="sending"
                required
                :error-messages="getErrors('password')"
                @blur="$v.form.password.$touch()"
              ></v-text-field>
              <v-text-field
                label="Confirm new password"
                name="repeat"
                id="repeat"
                type="password"
                v-model="form.repeat"
                :disabled="sending"
                required
                :error-messages="getErrors('repeat')"
                @blur="$v.form.repeat.$touch()"
              >
              </v-text-field>
            </v-card-text>
                <v-card-actions>
                  <v-row justify="end">
                    <v-col cols="3">
                      <v-btn
                        type="submit"
                        text
                        :disabled="sending"
                        @click="resetpassword()"
                      >Submit</v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import { validationMixin } from 'vuelidate'
import {
  required,
  sameAs,
  minLength
} from 'vuelidate/lib/validators'

export default {
  props: {
    resethash: {
      type: [String],
      default: ''
    }
  },
  data: function () {
    return {
      form: {
        password: null,
        repeat: null
      },
      errors: {
        password: '',
        repeat: ''
      },
      sending: false
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init: function () {
      console.log(this.resethash)
      if (UserService.loggedIn()) {
        // user is logged in, why not use settings page?
        this.$router.push('/settings', () => {
          this.$notify({
            group: 'notices',
            title: 'Reset Error',
            text: 'Reset from the settings page',
            type: 'danger'
          })
        })
      }
    },
    getErrors (fieldName) {
      const err = []
      if (!this.$v.form[fieldName].$dirty) return err
      if (fieldName === 'password') {
        !this.$v.form.password.required && err.push('new password is required')
        !this.$v.form.password.minLength && err.push('must be at least 6 characters long')
      }
      if (fieldName === 'repeat') {
        !this.$v.form.repeat.required && err.push('confirm password is required')
        !this.$v.form.repeat.sameAsPassword && err.push('passwords must match')
      }
      return err
    },
    resetpassword: function () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.sending = true
        var data = {
          resethash: this.resethash,
          password: this.form.password
        }
        UserService.resetpassword(data).then(
          (response) => {
            if (response.data.updated) {
              this.$router.push('/login', () => {
                this.$notify({
                  group: 'notices',
                  title: 'Password Reset',
                  text: 'Password successfully reset',
                  type: 'info'
                })
              })
            } else {
              this.$notify({
                group: 'notices',
                title: 'Reset Error',
                text: 'Something went wrong',
                type: 'danger'
              })
            }
          }
        ).catch(err => {
          this.$notify({
            group: 'notices',
            title: err.response.data.errors[0],
            text: err.response.data.error,
            type: 'danger'
          })
        }).finally(() => {
          this.sending = false
        })
      }
    },
    getValidationClass (fieldName) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'md-invalid': (field.$invalid && field.$dirty) || this.errors[fieldName].length > 0
        }
      }
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6)
      },
      repeat: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  }
}
</script>
