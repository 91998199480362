var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "about" },
    [
      _c(
        "v-card",
        { staticClass: "px-4" },
        [
          _c(
            "v-card-text",
            { staticClass: "black--text" },
            [
              _c(
                "v-row",
                { staticClass: "mt-0 px-4 pt-4", attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 text-center", attrs: { cols: "12" } },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "25%",
                          "border-radius": "0",
                          height: "auto"
                        },
                        attrs: {
                          src: require("../assets/black_red_icon/512.png")
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _c("v-row", { attrs: { justify: "center" } }, [
                _c(
                  "span",
                  {
                    staticClass: "robo",
                    staticStyle: { "font-size": "1.8em" }
                  },
                  [_vm._v("Welcome to VigZero")]
                )
              ]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("div", [
                        _vm._v(
                          " We are a peer to peer social gambling sports betting community designed to add more entertainment to traditional sports wagering without the 10% sportsbook fee. "
                        )
                      ]),
                      _c("v-spacer"),
                      _c("v-row", { attrs: { justify: "start" } }, [
                        _c(
                          "span",
                          {
                            staticClass: "px-3",
                            staticStyle: { "text-decoration": "underline" }
                          },
                          [_vm._v(" Features and Benefits ")]
                        )
                      ]),
                      _c("v-row", [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "As our name suggests, wagers have no commission or 'vigorish' i.e. 'vig.'"
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "Simple to use, not intimidating, low minimums and bet tracking of favorite teams for the novice or casual sports fan."
                            )
                          ]),
                          _c("li", [
                            _vm._v("Peer to Peer - pay each other directly")
                          ]),
                          _c("li", [
                            _vm._v(
                              "Create bets with the spread you want, set to the current line, or shop existing offers for the best value."
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "A 'Betcentric' almost real-time scoreboard lets you know how your wager is going."
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "Establish your own VigZero private group. Invite friends and make exclusive bets within your crew."
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "Membership is by invitation only and all members are rated based on their promptness in settling their wagers."
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "Get your money sooner! No credit card or illegal offshore accounts needed. No need to deal in digital currency. No trips to the casino to cash in your betting slip."
                            )
                          ])
                        ])
                      ]),
                      _c("v-row", { attrs: { justify: "center" } }, [
                        _c("div", [
                          _c(
                            "a",
                            { staticClass: "link", attrs: { href: "/terms" } },
                            [_vm._v("Terms and Agreement")]
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }