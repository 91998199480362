import { render, staticRenderFns } from "./Results.vue?vue&type=template&id=2295c0b6&"
import script from "./Results.vue?vue&type=script&lang=js&"
export * from "./Results.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/websites/vigzero/vig-zero-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2295c0b6')) {
      api.createRecord('2295c0b6', component.options)
    } else {
      api.reload('2295c0b6', component.options)
    }
    module.hot.accept("./Results.vue?vue&type=template&id=2295c0b6&", function () {
      api.rerender('2295c0b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/contests/Results.vue"
export default component.exports