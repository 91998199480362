var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.filteredPayouts && _vm.filteredPayouts.length > 0
    ? _c(
        "div",
        [
          _c("PaymentReceivedDialog", {
            attrs: { wager: _vm.confirmPayout },
            on: {
              cancel: function($event) {
                _vm.confirmPayout = null
              },
              paid: function($event) {
                _vm.confirmPayout = null
                _vm.$emit("updatedata")
              },
              dispute: function($event) {
                _vm.confirmPayout = null
                _vm.$emit("updatedata")
              },
              deny: function($event) {
                _vm.confirmPayout = null
                _vm.$emit("updatedata")
              }
            }
          }),
          _c("PaymentReceivedDisputeDialog", {
            attrs: { wager: _vm.disputePayout },
            on: {
              cancel: function($event) {
                _vm.disputePayout = null
              },
              paid: function($event) {
                _vm.disputePayout = null
              },
              dispute: function($event) {
                _vm.disputePayout = null
                _vm.$emit("updatedata")
              }
            }
          }),
          _c(
            "v-card",
            { staticClass: "card-shadow card winner-shadow" },
            [
              _c("v-card-title", [
                _c("div", { staticClass: "winner mx-auto" }, [
                  _vm._v("Confirm Payment")
                ])
              ]),
              _c(
                "v-card-text",
                { staticClass: "black--text" },
                _vm._l(_vm.filteredPayouts, function(payout) {
                  return _c(
                    "div",
                    {
                      key: payout.id,
                      staticClass: "row button-border",
                      class: {
                        disable:
                          payout.paymentsent == null || _vm.isDispute(payout)
                      }
                    },
                    [
                      _c("PayoutConfirmation", {
                        attrs: { payout: payout },
                        on: {
                          confirm: function($event) {
                            return _vm.$emit("updatedata")
                          },
                          deny: function($event) {
                            _vm.confirmPayout = payout
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }