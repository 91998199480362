<template>
  <span>
    <v-icon
      class="rating-star"
      color="yellow darken-2"
      small
    >grade</v-icon>
    <span>{{ (rating > 0) ? rating : 'Unrated' }}</span>
  </span>
</template>
<script>

export default {
  props: {
    rating: {
      type: [String, Number],
      default: 0
    }
  }
}
</script>
<style scoped>
.rating-star {
  transform: translateY(-2px);
}
</style>
