var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.groupmodal,
            callback: function($$v) {
              _vm.groupmodal = $$v
            },
            expression: "groupmodal"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "px-3 pt-3", attrs: { loading: _vm.formloading } },
            [
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Group Name",
                      name: "group-name",
                      id: "group-name",
                      type: "text",
                      counter: "18"
                    },
                    model: {
                      value: _vm.groupform.name,
                      callback: function($$v) {
                        _vm.$set(_vm.groupform, "name", $$v)
                      },
                      expression: "groupform.name"
                    }
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c("v-file-input", {
                            ref: "upload",
                            attrs: {
                              label: "Group Avatar",
                              name: "group-avatar",
                              id: "group-avatar"
                            },
                            on: { change: _vm.setFileUpload },
                            model: {
                              value: _vm.editgroupform.avatar,
                              callback: function($$v) {
                                _vm.$set(_vm.editgroupform, "avatar", $$v)
                              },
                              expression: "editgroupform.avatar"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "1" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.saveGroupAvatar()
                                }
                              }
                            },
                            [
                              _c("v-icon", { staticClass: "pt-8 pr-8" }, [
                                _vm._v("save")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [_vm._v("Allow members to invite others?")]),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { staticClass: "py-0" },
                        [
                          _c("v-checkbox", {
                            staticClass: "py-0",
                            attrs: { label: "Allow Invitations" },
                            model: {
                              value: _vm.groupform.allowmemberinvite,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.groupform,
                                  "allowmemberinvite",
                                  $$v
                                )
                              },
                              expression: "groupform.allowmemberinvite"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-autocomplete", {
                    attrs: {
                      label: "Search Users",
                      items: _vm.searchresults,
                      "search-input": _vm.autocomplete,
                      "item-text": "nickname",
                      "return-object": "",
                      loading: _vm.loading,
                      filter: function() {
                        return true
                      },
                      "hide-no-data": !_vm.loading
                    },
                    on: {
                      "update:searchInput": function($event) {
                        _vm.autocomplete = $event
                      },
                      "update:search-input": function($event) {
                        _vm.autocomplete = $event
                      },
                      input: _vm.adduser
                    }
                  }),
                  _vm.memberslist.length > 0
                    ? _c(
                        "v-row",
                        { staticClass: "my-0 py-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "black--text",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("v-row", { staticClass: "my-0 py-0 px-3" }, [
                                _vm._v(" Users ")
                              ]),
                              _vm._l(_vm.memberslist, function(user) {
                                return _c(
                                  "v-row",
                                  {
                                    key: user.id,
                                    staticClass: "my-0 py-0",
                                    attrs: {
                                      justify: "space-between",
                                      align: "center"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-left",
                                        attrs: { cols: "6" }
                                      },
                                      [_vm._v(_vm._s(user.nickname))]
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "2" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { icon: "", text: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeinvite(user)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("person_remove")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "space-between" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    text: "",
                                    disabled: _vm.formloading
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.groupmodal = false
                                    }
                                  }
                                },
                                [_vm._v(" Cancel ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    text: "",
                                    type: "submit",
                                    disabled: _vm.formloading
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.makegroupform($event)
                                    }
                                  }
                                },
                                [_vm._v(" Submit ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.selectedinvite != null
        ? _c(
            "v-dialog",
            {
              attrs: { width: "500" },
              model: {
                value: _vm.invitemodal,
                callback: function($$v) {
                  _vm.invitemodal = $$v
                },
                expression: "invitemodal"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "my-0 py-0",
                          attrs: { align: "center" }
                        },
                        [
                          _c("v-col", { staticClass: "black--text pb-0" }, [
                            _vm._v(
                              "You have been invited by " +
                                _vm._s(_vm.selectedinvite.ownername) +
                                " (" +
                                _vm._s(_vm.selectedinvite.owner) +
                                ") to join "
                            ),
                            _c("b", [_vm._v(_vm._s(_vm.selectedinvite.name))]),
                            _vm._v(". Would you like to join? ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-0 py-0" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "px-4",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "black white--text mx-1",
                                      on: {
                                        click: function($event) {
                                          return _vm.invited("cancel")
                                        }
                                      }
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "background-red white--text mx-1",
                                      on: {
                                        click: function($event) {
                                          return _vm.invited("decline")
                                        }
                                      }
                                    },
                                    [_vm._v("Decline")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "primary mx-1",
                                      on: {
                                        click: function($event) {
                                          return _vm.invited("accept")
                                        }
                                      }
                                    },
                                    [_vm._v("Accept")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.selectedgroup != null
        ? _c(
            "v-dialog",
            {
              attrs: { width: "800" },
              model: {
                value: _vm.editgroupmodal,
                callback: function($$v) {
                  _vm.editgroupmodal = $$v
                },
                expression: "editgroupmodal"
              }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "px-0 pb-0",
                  attrs: { loading: _vm.formloading }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "my-0 py-0", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0 text-right",
                          attrs: { cols: "2" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.cancelupdate()
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "black--text" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Group Name",
                          name: "group-name",
                          id: "group-name"
                        },
                        model: {
                          value: _vm.editgroupform.name,
                          callback: function($$v) {
                            _vm.$set(_vm.editgroupform, "name", $$v)
                          },
                          expression: "editgroupform.name"
                        }
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "10" } },
                            [
                              _c("v-file-input", {
                                ref: "upload",
                                attrs: {
                                  label: "Group Avatar",
                                  name: "group-avatar",
                                  id: "group-avatar"
                                },
                                on: { change: _vm.setFileUpload },
                                model: {
                                  value: _vm.editgroupform.avatar,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editgroupform, "avatar", $$v)
                                  },
                                  expression: "editgroupform.avatar"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "1" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.saveGroupAvatar()
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "pt-8 pr-8" }, [
                                    _vm._v("save")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-checkbox", {
                        staticClass: "py-0",
                        attrs: { label: "Allow invitations by members?" },
                        model: {
                          value: _vm.editgroupform.allowmemberinvite,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.editgroupform,
                              "allowmemberinvite",
                              $$v
                            )
                          },
                          expression: "editgroupform.allowmemberinvite"
                        }
                      }),
                      _c("v-autocomplete", {
                        attrs: {
                          label: "Search Users",
                          items: _vm.searchresults,
                          "search-input": _vm.autocomplete,
                          "item-text": "nickname",
                          "return-object": "",
                          loading: _vm.loading,
                          filter: function() {
                            return true
                          },
                          "hide-no-data": !_vm.loading
                        },
                        on: {
                          "update:searchInput": function($event) {
                            _vm.autocomplete = $event
                          },
                          "update:search-input": function($event) {
                            _vm.autocomplete = $event
                          },
                          input: _vm.inviteuser
                        }
                      }),
                      _c(
                        "v-row",
                        { staticClass: "py-0 my-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "p-0 pr-0",
                              attrs: { cols: "5", sm: "3" }
                            },
                            [_vm._v(" Members ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "checkboxHeaders",
                              attrs: { cols: "7" }
                            },
                            [
                              _c("p", { staticClass: "takeOffer" }, [
                                _vm._v("Take "),
                                _c("br"),
                                _vm._v("Offer ")
                              ]),
                              _c("p", { staticClass: "makeOffer" }, [
                                _vm._v("Make "),
                                _c("br"),
                                _vm._v("Offer ")
                              ]),
                              _c("p", [_vm._v("Invite ")])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._l(_vm.groupmembers, function(user) {
                        return _c(
                          "v-row",
                          {
                            key: user.user_id,
                            staticClass: "my-0 py-0",
                            attrs: { align: "center", justify: "space-evenly" }
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "my-0 py-0 pr-0",
                                attrs: { cols: "5" }
                              },
                              [
                                _vm._v(" " + _vm._s(user.nickname) + " "),
                                !user.isadmin
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { text: "", icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeuser(user)
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("person_remove")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 pr-10",
                                attrs: { cols: "2" }
                              },
                              [
                                _c("v-checkbox", {
                                  staticClass: "py-0",
                                  model: {
                                    value: user.cantake,
                                    callback: function($$v) {
                                      _vm.$set(user, "cantake", $$v)
                                    },
                                    expression: "user.cantake"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "my-0 py-0 pl-4",
                                attrs: { cols: "2", align: "center" }
                              },
                              [
                                _c("v-checkbox", {
                                  staticClass: "py-0",
                                  model: {
                                    value: user.canoffer,
                                    callback: function($$v) {
                                      _vm.$set(user, "canoffer", $$v)
                                    },
                                    expression: "user.canoffer"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "my-0 py-0 pl-5",
                                attrs: { cols: "2", align: "center" }
                              },
                              [
                                _c("v-checkbox", {
                                  staticClass: "py-0",
                                  model: {
                                    value: user.caninvite,
                                    callback: function($$v) {
                                      _vm.$set(user, "caninvite", $$v)
                                    },
                                    expression: "user.caninvite"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  ),
                  _c("v-divider"),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "py-0 my-0 px-2",
                                  attrs: {
                                    justify: "space-between",
                                    align: "center"
                                  }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-left",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "background-red",
                                          attrs: { disabled: _vm.formloading },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.opendelete()
                                            }
                                          }
                                        },
                                        [_vm._v(" delete group ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-right",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "primary",
                                          attrs: {
                                            type: "submit",
                                            disabled: _vm.formloading
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.updategroup($event)
                                            }
                                          }
                                        },
                                        [_vm._v(" save ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.groupdelete,
            callback: function($$v) {
              _vm.groupdelete = $$v
            },
            expression: "groupdelete"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Delete Group?")]),
              _c("v-card-text", [
                _c("p", { staticClass: "black--text" }, [
                  _vm._v(
                    " Are you sure you want to delete this group? (this cannot be undone) "
                  )
                ])
              ]),
              _c(
                "v-row",
                { staticClass: "my-0 py-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "my-0 py-0 px-2",
                              attrs: { justify: "end" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.canceldelete()
                                    }
                                  }
                                },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deletegroup()
                                    }
                                  }
                                },
                                [_vm._v("Delete")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", [
        _c("div", [
          _c(
            "button",
            {
              staticClass: "vigzero-btn blue-btn robo text-center",
              staticStyle: { width: "100%" },
              on: {
                click: function($event) {
                  _vm.groupmodal = true
                }
              }
            },
            [
              _c(
                "span",
                { staticStyle: { display: "block", margin: "0 auto" } },
                [_vm._v("Create Group")]
              )
            ]
          )
        ])
      ]),
      _vm.invitelist.length > 0
        ? _c(
            "v-row",
            { staticClass: "my-0 py-0" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-card-title", [_vm._v("Group Invites")]),
                  _c(
                    "v-card-text",
                    _vm._l(_vm.invitelist, function(invite) {
                      return _c("invitation", {
                        key: invite.id,
                        attrs: { invite: invite },
                        on: {
                          update: function($event) {
                            return _vm.invitedResponse()
                          }
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "px-0 py-0" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "px-0 ma-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "font-weight-bold px-0 ma-0" },
                        [_vm._v("My groups")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    _vm._l(_vm.grouplist, function(group) {
                      return _c(
                        "v-row",
                        {
                          key: group.id,
                          staticClass: "my-0 py-0",
                          attrs: { justify: "space-between" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "vigzero-btn dashboard-btn red-btn ma-0 pa-0",
                                  attrs: {
                                    justify: "space-between",
                                    align: "center"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.clickexpander(group.id)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { style: _vm.namesize(group.name) },
                                    [_vm._v(_vm._s(group.name))]
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      group.isadmin == 1
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "pull-right",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.editgroup(group)
                                                }
                                              }
                                            },
                                            [_c("v-icon", [_vm._v("edit")])],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "py-0 my-1" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-expand-transition",
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.expanded == group.id,
                                                  expression:
                                                    "expanded == group.id"
                                                }
                                              ],
                                              staticClass: "pt-3 mt-n4",
                                              attrs: { block: "" }
                                            },
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass: "mx-1 px-1 py-0"
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pa-0",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "mx-0 mt-2 mb-0",
                                                              attrs: {
                                                                justify:
                                                                  "space-between"
                                                              }
                                                            },
                                                            [
                                                              _c("v-col", {
                                                                staticClass:
                                                                  "pa-0",
                                                                attrs: {
                                                                  cols: "4"
                                                                }
                                                              }),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pa-0 text-center",
                                                                  attrs: {
                                                                    cols: "4"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "W/L/T"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pa-0 text-center",
                                                                  attrs: {
                                                                    cols: "4"
                                                                  }
                                                                },
                                                                [_vm._v("W %")]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._l(
                                                            group.members,
                                                            function(member) {
                                                              return _c(
                                                                "v-row",
                                                                {
                                                                  key:
                                                                    member.id,
                                                                  staticClass:
                                                                    "ma-0",
                                                                  attrs: {
                                                                    justify:
                                                                      "space-between"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "py-1",
                                                                      attrs: {
                                                                        cols:
                                                                          "4"
                                                                      }
                                                                    },
                                                                    [
                                                                      member.accepted !=
                                                                      null
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  member.nickname
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  member.nickname
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "py-1 text-center",
                                                                      attrs: {
                                                                        cols:
                                                                          "4"
                                                                      }
                                                                    },
                                                                    [
                                                                      member.accepted !=
                                                                      null
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      member.wins
                                                                                    ) +
                                                                                      " - " +
                                                                                      _vm._s(
                                                                                        member.losses
                                                                                      ) +
                                                                                      " - " +
                                                                                      _vm._s(
                                                                                        member.ties
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "icon"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "schedule"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "py-0 text-center",
                                                                      attrs: {
                                                                        cols:
                                                                          "4"
                                                                      }
                                                                    },
                                                                    [
                                                                      member.accepted !=
                                                                      null
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      member.winperc
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            [
                                                                              group.isadmin ==
                                                                              1
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        text:
                                                                                          "",
                                                                                        disabled: _vm.disabledResend(
                                                                                          member.id
                                                                                        ),
                                                                                        dense:
                                                                                          ""
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.resendInvite(
                                                                                            member.id
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Resend"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ],
                                                                            1
                                                                          )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          group &&
                                                          group.members &&
                                                          group.isowner > 0
                                                            ? _c(
                                                                "GroupInvite",
                                                                {
                                                                  attrs: {
                                                                    group: group,
                                                                    members:
                                                                      group.members
                                                                  },
                                                                  on: {
                                                                    refresh:
                                                                      _vm.init
                                                                  }
                                                                }
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              group.isadmin != 1
                                                ? _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "my-0 px-4"
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-actions",
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  attrs: {
                                                                    justify:
                                                                      "end"
                                                                  }
                                                                },
                                                                [
                                                                  group.isadmin !=
                                                                  1
                                                                    ? _c(
                                                                        "v-btn",
                                                                        {
                                                                          staticClass:
                                                                            "background-red white--text",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.leavegroupmodal(
                                                                                group
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Leave Group"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "400" },
          model: {
            value: _vm.groupLeave.show,
            callback: function($$v) {
              _vm.$set(_vm.groupLeave, "show", $$v)
            },
            expression: "groupLeave.show"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "px-4" },
            [
              _c("v-card-title", [_vm._v("Leave Group?")]),
              _c(
                "v-card-text",
                [
                  _c("v-row", { staticClass: "black--text my-0 py-0" }, [
                    _c("p", [
                      _vm._v(" Are you sure you want to leave the group "),
                      _c("b", [_vm._v(_vm._s(_vm.groupLeave.name))]),
                      _vm._v("? ")
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0 py-0", attrs: { justify: "end" } },
                [
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-1 mx-0",
                          attrs: { text: "" },
                          on: {
                            click: function($event) {
                              return _vm.cancelLeaveGroup()
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-1 mx-0",
                          attrs: { text: "" },
                          on: {
                            click: function($event) {
                              return _vm.leavegroup()
                            }
                          }
                        },
                        [_vm._v("Leave Group")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }