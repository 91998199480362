<template>
  <div v-if="tournament && round">
    <v-row
      justify="center"
      no-gutters
    >
      <v-col
        cols="2"
        align="right"
      >
        <v-btn
          fab
          x-small
          icon
          elevation="2"
          @click="switchRound(-1)"
          :disabled="!canReverseRound"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h1 class="text-no-wrap text-md-h2">{{ roundName }}</h1>
      </v-col>
      <v-col
        cols="2"
        align="left"
      >
        <v-btn
          fab
          x-small
          icon
          elevation="2"
          @click="switchRound(1)"
          :disabled="!canAdvanceRound"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="userOnly">
      <div v-if="userGames && userGames.length > 0">
        <TournamentMatchup
          v-for="matchup in userGames"
          :key="'ug-' + matchup.id"
          :tournament="tournament"
          :game="matchup"
          :round="rounds[round - 1]"
          :user_id="user_id"
          :group_id="group_id"
          class="my-entries"
        ></TournamentMatchup>
        <v-divider></v-divider>
      </div>
      <div v-else>
        <v-row>
          <v-col class="text-center">No entries in {{ rounds[round - 1] }}</v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <TournamentMatchup
        v-for="matchup in tournament.matchups[round]"
        :key="matchup.id"
        :tournament="tournament"
        :game="matchup"
        :round="rounds[round - 1]"
        :user_id="user_id"
        :group_id="group_id"
        class="bracket"
      ></TournamentMatchup>
    </div>
  </div>
</template>

<script>
import TournamentMatchup from '@/components/tournaments/Matchup'

export default {
  name: 'TournamentBracket',

  components: {
    TournamentMatchup
  },

  data () {
    return {
      round: 1,
      rounds: [
        'Round 1',
        'Round 2',
        'Sweet 16',
        'Elite 8',
        'Final 4',
        'Championship'
      ]
    }
  },

  created () {
    if (this.tournament) {
      this.round = this.tournament.current_round
    }
  },

  props: {
    group_id: {
      type: [String, Number],
      default: '0'
    },
    user_id: {
      type: [String, Number],
      default: 0
    },
    tournament: Object,
    userOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    roundName: function () {
      return this.rounds[this.round - 1]
    },
    canAdvanceRound: function () {
      var maxRound = 0
      for (var obj in this.tournament.matchups) {
        if (obj.length) {
          maxRound++
        }
      }
      return (this.round + 1 <= maxRound)
    },
    canReverseRound: function () {
      return (this.round - 1 > 0)
    },
    userGames: function () {
      var games = []
      if (this.round && this.tournament.matchups && this.tournament.matchups[this.round]) {
        this.tournament.matchups[this.round].forEach((m) => {
          if (m.mygame) {
            games.push(m)
          }
        })
      }
      return games
    }
  },

  methods: {
    switchRound (direction) {
      if (direction > 0 && this.canAdvanceRound) {
        this.round = this.round + 1
      } else if (direction < 0 && this.canReverseRound) {
        this.round = this.round - 1
      }
    }
  }
}
</script>

<style scoped>
</style>
