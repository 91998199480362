<template>
  <v-row
    v-if="canInvite && userId > 0"
    class="ma-3"
    :key="'groupInvite-' + group.id + '-' (loading) ? 'disabled' : 'active'"
  >
    <v-col>
      <v-autocomplete
        label="Search Users to Invite"
        :items="results"
        :search-input.sync="autocomplete"
        item-text="nickname"
        return-object
        :loading="loading"
        :hide-no-data="!loading"
        :filter="() => true"
        clearable
        @input="select"
      >
        <template v-slot:append-outer>
          <v-slide-x-reverse-transition mode="out-in">
            <v-btn
              :disabled="!inviteuser"
              small
              @click="invite"
              class="background-red white--text"
            >Invite</v-btn>
          </v-slide-x-reverse-transition>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import UserService from '@/services/UserService'
import CommunityService from '@/services/CommunityService'

export default {
  name: 'GroupInvite',
  props: {
    group: Object,
    members: Array
  },

  data: () => {
    return {
      userId: 0,
      loading: false,
      timeout: null,
      results: [],
      autocomplete: null,
      member: null,
      inviteuser: null
    }
  },

  created () {
    const user = UserService.getUser()
    if (user) {
      this.userId = +user.id
    } else {
      this.userId = 0
    }
  },

  watch: {
    members () {
      this.members.some((m) => {
        if (m.user_id === this.userId) {
          this.member = m
          return true
        }
      })
    },

    autocomplete (searchTerm) {
      if (searchTerm && searchTerm.length < 3) {
        this.results = []
        return
      }
      // if search term is a formatted phone number remove all non digits
      // Stack Overflow link
      // https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
      if (/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(searchTerm)) {
        searchTerm = searchTerm.replace(/\D+/g, '')
      }
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.search(searchTerm)
      }, 300)
    }
  },

  methods: {
    search (searchTerm) {
      if (searchTerm) {
        this.loading = true
        CommunityService.searchUsers(searchTerm.toLowerCase()).then(
          (response) => {
            this.results = response.data
          },
          (err) => {
            console.log(err)
          }
        ).finally(() => {
          this.loading = false
        })
      }
    },

    select (arg) {
      this.inviteuser = arg || null
    },

    invite () {
      if (!this.inviteuser) {
        return false
      }

      this.loading = true
      CommunityService.inviteUser(this.group.id, this.inviteuser).then(
        (response) => {
          this.inviteuser = null
          // can't get the clearable X to reset when I change this autocomplete variable
          this.$nextTick(() => {
            this.autocomplete = null
          })
          this.$notify({
            group: 'notices',
            title: 'Invite Sent',
            text: response.data.message,
            type: 'info'
          })
          this.$emit('refresh')
        },
        (err) => {
          console.dir(err)
          var e = err.response.data
          if (e.error) {
            this.$notify({
              group: 'errors',
              title: e.errors[0],
              text: e.error,
              type: 'danger'
            })
          }
        }
      ).finally(() => {
        this.loading = false
        // on successful group invitation
        this.$gtag.event('invite', {
          event_category: 'group',
          event_label: 'invited',
          value: this.group.name
        })
      })
    }
  },

  computed: {
    canInvite () {
      return (this.group && this.group.allowmemberinvite && this.member && this.member.caninvite)
    }
  }
}
</script>
