<template>
  <div v-if="tournament && contest">
    <TournamentBracket
      :tournament="tournament"
      :user_id="user_id"
      :user-only="userOnly"
      :admin="admin"
      :group_id="group_id"
      @refresh="$emit('refresh')"
    ></TournamentBracket>
  </div>
</template>

<script>
import TournamentBracket from '@/components/tournaments/Bracket'

export default {
  name: 'ContestResults',
  components: {
    TournamentBracket
  },

  props: {
    contest: Object,
    tournament: Object,
    group_id: {
      type: [String, Number],
      default: '0'
    },
    user_id: {
      type: [String, Number],
      default: '0'
    },
    admin: {
      type: Boolean,
      default: false
    },
    userOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasUserGames: function () {
      return this.contest.myentries > 0
    }
  }
}
</script>
