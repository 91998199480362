<template>
  <div>
    <v-container
      ref="messageContainer"
      style="max-height: 200px;"
      class="overflow-y-auto pa-0 my-0"
    >
      <div v-if="messages && messages.length > 0">
        <v-row
          v-for="m in messages"
          :key="m.id"
          :justify="(m.user_id == userId) ? 'end' : 'start'"
          class="mt-0 mb-1"
        >
          <v-col
            class="py-0"
            :class="(m.user_id == userId) ? 'text-right' : 'text-left'"
          >
            <div class="timestamp">{{ m.created }}</div>
            <div
              class="message"
              :class="(m.user_id == userId) ? 'message-from' : 'message-to'"
            >
              <div>{{ m.message }}</div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-text-field
      v-if="canChat"
      class="chat-notification-reply pt-1 pb-2"
      hide-details
      placeholder="Type your message here"
      solo
      dense
      rounded
      v-model="message"
      @keypress.enter="sendMessage"
      @focus="swipe(false)"
      @blur="swipe(true)"
    >
      <template v-slot:append>
        <v-btn
          class="ma-0 pa-0"
          :disabled="reading || sending"
          @click="sendMessage"
          icon
        >
          <v-icon>send</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import UserService from '@/services/UserService'
import ChatService from '@/services/ChatService'
import { bus } from '@/main'

export default {
  components: {},
  props: {
    wagerId: {
      type: [Number, String],
      default: 0
    },
    messages: {
      type: Array,
      default: function () { return [] }
    },
    canChat: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      sending: false,
      reading: false,
      userId: 0,
      message: null
    }
  },
  mounted: function () {
    var user = UserService.getUserObject()
    this.userId = user ? +user.info.id : 0
  },
  updated: function () {
    var messageContainer = this.$refs.messageContainer
    messageContainer.scrollTop = messageContainer.scrollHeight
    if (this.hasUnreadMessages) {
      this.markWagerMessagesAsRead()
    }
  },
  computed: {
    hasUnreadMessages: function () {
      if (this.messages && this.messages.length > 0) {
        var i = this.messages.length - 1
        while (i >= 0) {
          if (!this.messages[i].viewed && this.messages[i].user_id !== this.userId) {
            return true
          }
          i--
        }
      }
      return false
    }
  },
  methods: {
    async sendMessage () {
      if (!this.wagerId || !this.message || this.sending) {
        return false
      }
      this.sending = true
      await ChatService.sendWagerMessage(this.wagerId, this.message).then(
        (response) => {
          this.message = null
          this.$emit('update')
          bus.$emit('messages-refresh')
          // on successful message sent
          const data = {
            wagerid: this.wagerId,
            message: this.message
          }
          this.$gtag.event('send', {
            event_category: 'message',
            event_label: 'sent',
            value: data
          })
        },
        (err) => {
          console.dir(err)
        }
      ).finally(
        () => {
          this.sending = false
        }
      )
    },
    async markWagerMessagesAsRead () {
      if (!this.wagerId || !this.hasUnreadMessages || this.reading) {
        return false
      }

      this.reading = true
      await ChatService.readWagerMessages(this.wagerId).then(
        (response) => {
          // bus trigger here
          this.$emit('update')
          bus.$emit('messages-refresh')
        },
        (err) => {
          console.dir(err)
        }
      ).finally(
        () => {
          this.reading = false
        }
      )
    },
    swipe: function (enable) {
      if (enable) {
        bus.$emit('inputblur')
      } else {
        bus.$emit('inputfocus')
      }
    }
  }
}
</script>
<style scoped>
.timestamp {
  font-size: 70%;
  color: #aaa;
}
.message {
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: inline-block;
  max-width: 80%;
}
.message-from {
  background: #c12626;
  color: white;
  padding: 4px;
  border-radius: 5px 5px 0 5px;
  margin-left: 15px;
}
.message-to {
  background: #272727;
  color: white;
  padding: 4px;
  border-radius: 5px 5px 5px 0;
  margin-right: 15px;
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
.v-input {
  font-size: 16px !important;
}
</style>
