var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "v-row",
        { staticClass: "py-0 my-0 px-1" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "px-1 pt-4", attrs: { elevation: "10" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "py-0 my-0" },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "text-h5 my-0",
                          attrs: { justify: "start" }
                        },
                        [_vm._v(" Notifications Settings ")]
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "my-0 py-0 black--text",
                          attrs: { justify: "space-around" }
                        },
                        [
                          _c("v-col", { staticClass: "text-center py-0" }, [
                            _vm._v(" Email ")
                          ]),
                          _c("v-col", { staticClass: "text-center py-0" }, [
                            _vm._v(" Text ")
                          ]),
                          _c(
                            "v-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.devices > 0,
                                  expression: "devices > 0"
                                }
                              ],
                              staticClass: "text-center py-0"
                            },
                            [_vm._v("Push")]
                          )
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "black" }),
                      _vm._l(_vm.notifications, function(notification) {
                        return _c(
                          "v-row",
                          { key: notification.id, staticClass: "my-0 py-0" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-0 my-0",
                                attrs: { cols: "12" }
                              },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "pa-0 my-0 black--text",
                                    attrs: { justify: "center" }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(notification.name) + " "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "px-6 my-0",
                                    attrs: { justify: "space-around" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-0 my-0",
                                        attrs: { cols: "1" }
                                      },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "py-0 my-0",
                                          attrs: {
                                            disabled: notification.type !== 6
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.savenotifications()
                                            }
                                          },
                                          model: {
                                            value: notification.email,
                                            callback: function($$v) {
                                              _vm.$set(
                                                notification,
                                                "email",
                                                $$v
                                              )
                                            },
                                            expression: "notification.email"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-0 my-0",
                                        attrs: { cols: "1" }
                                      },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "py-0 my-0",
                                          on: {
                                            change: function($event) {
                                              return _vm.savenotifications()
                                            }
                                          },
                                          model: {
                                            value: notification.text,
                                            callback: function($$v) {
                                              _vm.$set(
                                                notification,
                                                "text",
                                                $$v
                                              )
                                            },
                                            expression: "notification.text"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.devices > 0,
                                            expression: "devices > 0"
                                          }
                                        ],
                                        staticClass: "pa-0 my-0",
                                        attrs: { cols: "1" }
                                      },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "py-0 my-0",
                                          on: {
                                            change: function($event) {
                                              return _vm.savenotifications()
                                            }
                                          },
                                          model: {
                                            value: notification.push,
                                            callback: function($$v) {
                                              _vm.$set(
                                                notification,
                                                "push",
                                                $$v
                                              )
                                            },
                                            expression: "notification.push"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                notification.name !== "Promotional"
                                  ? _c("v-divider", { staticClass: "black" })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }