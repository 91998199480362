<template>
  <v-row class="my-0 py-0">
    <v-col cols="12" class="py-0">
      <v-data-table
        v-if="wagers && wagers.length > 0"
        :headers="headers"
        :items="wagers"
        class="elevation-1"
        @click:row="navigateToWager"
      >
        <template v-slot:item.id="{ item }">
          <span>{{ item.id }}</span><br>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-if="item.ishonor === 1"
                class="honor-bet-badge"
                v-bind="attrs"
                v-on="on"
              >
                HB
              </span>
            </template>
            <span>This is an Honor Bet</span>
          </v-tooltip>
        </template>
        <template v-slot:item.starttime="{ item }">
          <span>{{ item.starttime }}</span>
        </template>
        <template v-slot:item.visitingteam="{ item }">
          <span><button :class="{ 'openoffers': item.wagersaway > 0 }"> {{ item.visitingteam }} </button></span><br>
          <span v-if="item.wagershome > 0"> {{ item.nickname }}</span>
        </template>
        <template v-slot:item.hometeam="{ item }">
          <span><button :class="{ 'openoffers': item.wagershome > 0 }">{{ item.hometeam }}</button></span><br>
          <span v-if="item.wagersaway > 0"> {{ item.nickname }}</span>
        </template>
        <template v-slot:item.offeredline="{ item }">
          <span>{{ item.offeredline }} ({{ item.currentline }})</span>
        </template>
        <template v-slot:item.offeredbyamount="{ item }">
          <span>{{ formatCurrency(item.offeredbyamount) }}</span>
        </template>
      </v-data-table>
      <h3 v-else v-show="!loading" class="text-center">No Open offers available.</h3>
    </v-col>
  </v-row>
</template>

<script>
import WagerService from '@/services/WagerService'

export default {
  data: () => {
    return {
      loading: false,
      wagers: null,
      headers: [
        { text: 'Wager#', value: 'id' },
        { text: 'Start', value: 'starttime' },
        { text: 'Visitor', value: 'visitingteam' },
        { text: 'Home', value: 'hometeam' },
        { text: 'Offered Line (Current Line)', value: 'offeredline' },
        { text: 'Amount', value: 'offeredbyamount' }
      ]
    }
  },
  props: {
    section: {
      type: String,
      default: ''
    },
    scrollid: {
      type: String,
      default: ''
    }
  },
  created () {
    this.getdata()
  },
  methods: {
    async getdata () {
      if (this.loading) {
        return false
      }
      this.$updateloading(1)
      this.loading = true
      this.wagers = null
      await WagerService.getOpenWagers().then(
        (response) => {
          this.wagers = response.data ? response.data.results : []
          this.loading = false
        },
        (error) => {
          this.wagers = []
          alert(error.response.data.error)
          this.$updateloading(-1)
          this.loading = false
        }
      ).finally(() => {
        if (this.section && this.scrollid !== '') {
          const id = document.getElementById(this.section + 'Wager' + this.scrollid)
          if (id) {
            id.scrollIntoView()
          }
        }
        this.$updateloading(-1)
      })
    },
    navigateToWager (wager) {
      this.$router.push(this.getWagerLinkUrl(wager))
    },
    getWagerLinkUrl (wager) {
      const type = (wager.bettype_id === 30)
        ? 'line'
        : (wager.bettype_id === 10) ? 'money' : (wager.takenbyteam_id === wager.hometeam_id ? 'under' : 'over')
      const path = `/wager/${wager.game_id}/${wager.takenbyteam_id}/${type}`
      // if (this.section) {
      //   path += `/${this.section}`
      // }
      return path
    },
    formatCurrency (amount) {
      // Ensure the amount is a number and format it to two decimal places with a dollar sign
      return `$${parseFloat(amount).toFixed(2)}`
    }
  }
}
</script>
<style scoped>
.openoffers {
  background-color: #9d1d21;
  color: white;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  align-items: center;
  font-size: 0.75rem;
  font-weight: bold;
  display: inline-block;
}

.honor-bet-badge {
  background-color: #9d1d21;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  margin-left: 5px;
  font-size: 0.75rem;
  font-weight: bold;
  display: inline-block;
}
</style>
