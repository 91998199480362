<template>
  <div v-if="messages && messages.total > 0">
    <v-card
      class="chat-notification-card card"
      :loading="loading"
    >
      <v-card-text class="chat-notification-heading pt-1 pb-0 px-0">
        <v-row class="my-0 mx-1">
          <v-col class="px-0 py-0">
            <div>
              <span class="text-red">
                <v-icon class="text-red">$bullhorn</v-icon>
                <strong class="pl-1">({{ messages.total }})</strong>
              </span>
              <strong
                v-if="currentMessage.wager_id"
                class="text--black px-2"
              >{{ currentMessage.nickname.substring(0, 17) }}</strong>
              <strong
                v-else
                class="text--black px-2"
              >{{ currentMessage.friendgroup.substring(0, 17) }}</strong>
              <span
                v-if="messages.total !== currentThread.length"
                class="text-red pr-1"
              >({{ currentThread.length }})</span>
              <v-btn
                x-small
                tile
                class="chat-notification-button-mute ma-0"
                :disabled="loading || reading || sending"
                @click="muteThread"
              >Mute</v-btn>
            </div>
          </v-col>
          <v-col
            class="px-0 py-0 text-center"
            cols="2"
          >
            <v-btn
              x-small
              tile
              class="chat-notification-button ma-0"
              :disabled="loading || reading || sending"
              @click="readMessage"
            >{{ messages.total > 1 ? 'Next' : 'OK' }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text
        v-if="currentMessage.wager_id"
        class="chat-notification-gameinfo py-0 px-1"
      >
        <v-row class="my-0 mx-1">
          <v-col class="px-0 py-0">
            <div>
              {{ currentMessage.awayteam }}
              <span v-show="gameStarted">{{ awayScore }}</span>
              @ {{ currentMessage.hometeam }}
              <span v-show="gameStarted">{{ homeScore }}</span>
              <span v-show="gameStatus">| {{ gameStatus }}</span>
              <span
                v-show="gameStarted"
                :class="wagerStatusClass"
              >| {{ wagerStatus }}</span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="chat-notification-message pa-0 pt-1 black--text">
        <v-row class="ma-0">
          <v-col class="px-5 pt-0 pb-2">
            <span class="message">
              <strong v-if="currentMessage.friendgroup_id">{{ currentMessage.nickname }}: </strong>
              <i>{{ currentMessage.message }}</i>
              <span style="font-size: 75%;"> - {{ currentMessage.dtformatted }}</span>
            </span>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="py-0 px-1">
        <v-text-field
          class="chat-notification-reply py-0 pb-2"
          hide-details
          placeholder="Type your reply here"
          solo
          dense
          rounded
          v-model="message"
          @keypress.enter="sendMessage"
          @focus="swipe(false)"
          @blur="swipe(true)"
        >
          <template v-slot:append>
            <v-btn
              class="ma-0 pa-0"
              :disabled="loading || reading || sending"
              @click="sendMessage"
              icon
            >
              <v-icon>send</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import UserService from '@/services/UserService'
import ChatService from '@/services/ChatService'
import { bus } from '@/main'

export default {
  name: 'ChatNotifications',
  data: function () {
    return {
      loading: false,
      sending: false,
      reading: false,
      swipable: true,
      timer: null,
      userId: 0,
      message: null,
      messages: {
        get: function () {
          return ChatService.getMessages()
        }
      }
    }
  },
  mounted: function () {
    var user = UserService.getUserObject()
    this.userId = user ? +user.info.id : 0
    if (!this.timer) {
      this.refresh()
    }
    this.init()
    bus.$on('messages-updated', (data) => {
      this.init()
    })
    bus.$on('messages-refresh', (data) => {
      this.refresh()
    })
  },
  destroyed: function () {
    this.clearRefreshTimer()
  },
  computed: {
    currentMessage: function () {
      return (this.messages && this.messages.total > 0) ? this.messages.messages[0][0] : {}
    },
    currentThread: function () {
      return (this.messages && this.messages.total > 0) ? this.messages.messages[0] : []
    },
    awayScore: function () {
      var score = 0
      if (this.currentMessage && (this.currentMessage.scoreboard || this.currentMessage.awayscore > 0)) {
        score = (this.currentMessage.awayteamscore > 0) ? this.currentMessage.awayteamscore : this.totalBoxScore(this.currentMessage.scoreboard.box.away)
      }
      return score
    },
    homeScore: function () {
      var score = 0
      if (this.currentMessage && (this.currentMessage.scoreboard || this.currentMessage.homescore > 0)) {
        score = (this.currentMessage.hometeamscore > 0) ? this.currentMessage.hometeamscore : this.totalBoxScore(this.currentMessage.scoreboard.box.home)
      }
      return score
    },
    gameStarted: function () {
      return (this.currentMessage.scoreboard || this.currentMessage.hometeamscore !== null)
    },
    gameStatus: function () {
      var status = ''
      if (this.currentMessage && this.currentMessage.hometeamscore !== null) {
        status = 'Final'
      } else if (this.currentMessage && this.currentMessage.scoreboard) {
        status = this.currentMessage.periodname.charAt(0).toUpperCase() +
          ((this.currentMessage.scoreboard.period) ? this.currentMessage.scoreboard.period : this.currentMessage.scoreboard.box.home.length) +
          ' ' + ((this.currentMessage.scoreboard.time) ? this.currentMessage.scoreboard.time : '0:00')
      }
      return status
    },
    wagerStatus: function () {
      var display = ''
      var diff = 0
      if (this.currentMessage.bettype_id === 30) {
        diff = this.homeScore + this.gameline - this.awayScore
        if (diff === 0) {
          display = 'PUSH'
        } else if (+this.myTeam === +this.currentMessage.hometeam_id) {
          display = ((diff > 0) ? '+' : '') + diff.toString()
        } else {
          display = ((diff < 0) ? '+' : '') + (diff * -1).toString()
        }
      } else if (this.currentMessage.bettype_id === 20) {
        // O/U points bet
        diff = this.homeScore + this.awayScore - this.gameline
        if (diff === 0) {
          display = 'PUSH'
        } else if (+this.myTeam === +this.currentMessage.hometeam_id) {
          // over bet
          display = (diff > 0) ? '+' + Math.abs(diff.toString()) : '-' + Math.abs(diff.toString())
        } else {
          display = (diff > 0) ? '-' + Math.abs(diff.toString()) : '+' + Math.abs(diff.toString())
        }
      } else if (this.currentMessage.bettype_id === 10) {
        diff = this.homeScore - this.awayScore
        if (diff === 0) {
          display = 'PUSH'
        }
      }
      return display
    },
    wagerStatusClass: function () {
      var diff = 0
      var cls = ''
      if (this.currentMessage.bettype_id === 30) {
        diff = this.homeScore + this.gameline - this.awayScore
        if ((+this.myTeam === +this.currentMessage.hometeam_id && diff > 0) || (+this.myTeam === +this.currentMessage.awayteam_id && diff < 0)) {
          cls = 'winner'
        } else {
          cls = 'loser'
        }
      } else {
        // O/U points bet
        diff = this.homeScore + this.awayScore - this.gameline
        if ((+this.myTeam === +this.currentMessage.hometeam_id && diff > 0) || (+this.myTeam === +this.currentMessage.awayteam_id && diff < 0)) {
          cls = 'winner'
        } else {
          cls = 'loser'
        }
      }
      return cls
    },
    myTeam: function () {
      if (+this.currentMessage.offeredby_id === +this.userId) {
        return this.currentMessage.offeredbyteam_id
      } else {
        return this.currentMessage.takenbyteam_id
      }
    },
    gameline: function () {
      return (typeof this.currentMessage.lineamount !== 'undefined' && this.currentMessage.lineamount !== null) ? this.currentMessage.lineamount : this.currentMessage.currentline
    }
  },
  methods: {
    totalBoxScore (box) {
      var total = 0
      box.forEach((b) => {
        total += b
      })
      return total
    },

    async init () {
      this.messages = await ChatService.getMessages()
      if (!this.timer) {
        this.startRefreshTimer()
      }
    },

    async refresh () {
      const user = UserService.getUserObject()
      if (this.loading || this.reading || this.sending || !this.swipable || !user || !user.info) {
        return false
      }
      await ChatService.getUnreadMessages()
    },

    async readMessage () {
      if (this.loading || this.reading || !this.currentMessage.id) {
        return false
      }
      this.reading = true
      await ChatService.readMessage(this.currentMessage).then(
        async (response) => {
          this.reading = false
          await this.refresh()
        },
        (err) => {
          console.dir(err)
        }
      ).finally(
        () => {
          this.reading = false
        }
      )
    },

    async muteThread () {
      if (this.loading || this.reading || this.sending || !this.currentMessage.id) {
        return false
      }
      this.reading = true
      if (this.currentMessage.friendgroup_id) {
        await ChatService.toggleMuteGroup(this.currentMessage.friendgroup_id).then(
          async (response) => {
            this.reading = false
            await this.refresh()
          },
          (err) => {
            this.reading = false
            console.dir(err)
          }
        )
      } else {
        await ChatService.toggleMuteWager(this.currentMessage.wager_id, (this.userId === this.currentMessage.offeredby_id)).then(
          async (response) => {
            this.reading = false
            await this.refresh()
          },
          (err) => {
            this.reading = false
            console.dir(err)
          }
        )
      }
    },

    async sendMessage () {
      if (!this.currentMessage || !this.message || this.sending) {
        return false
      }
      this.sending = true
      if (this.currentMessage.wager_id) {
        await ChatService.sendWagerMessage(this.currentMessage.wager_id, this.message).then(
          async (response) => {
            this.message = null
            this.sending = false
            this.swipable = true
            await this.readMessage()
          },
          (err) => {
            console.dir(err)
          }
        ).finally(
          () => {
            this.sending = false
          }
        )
      } else {
        await ChatService.sendGroupMessage(this.currentMessage.friendgroup_id, this.message, this.currentMessage.id).then(
          async (response) => {
            this.message = null
            this.sending = false
            await this.readMessage()
          },
          (err) => {
            console.dir(err)
          }
        ).finally(
          () => {
            this.sending = false
          }
        )
      }
    },

    startRefreshTimer () {
      // set the timer to run every 1 minute
      this.clearRefreshTimer()
      this.timer = setInterval(this.refresh, 60 * 1 * 1000)
    },
    clearRefreshTimer () {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    swipe: function (enable) {
      if (enable) {
        this.swipable = true
        bus.$emit('inputblur')
      } else {
        this.swipable = false
        bus.$emit('inputfocus')
      }
    }
  }
}
</script>
<style scoped>
.message {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
.v-input {
  font-size: 16px !important;
}
</style>
