var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { loading: _vm.loading, "retain-focus": false },
      model: {
        value: _vm.edit,
        callback: function($$v) {
          _vm.edit = $$v
        },
        expression: "edit"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(" Edit Details ")
          ]),
          _vm.invite
            ? _c(
                "v-card-text",
                [
                  _c(
                    "div",
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "email",
                          label: "Email",
                          placeholder: "Friend's Email",
                          "error-messages": _vm.getErrors("email"),
                          required: "",
                          disabled: _vm.loading
                        },
                        on: {
                          blur: function($event) {
                            return _vm.$v.form.email.$touch()
                          }
                        },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Phone",
                          placeholder: "Friend's cell phone",
                          "error-messages": _vm.getErrors("phone"),
                          required: "",
                          disabled: _vm.loading
                        },
                        on: {
                          blur: function($event) {
                            return _vm.$v.form.phone.$touch()
                          }
                        },
                        model: {
                          value: _vm.form.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("v-textarea", {
                        attrs: {
                          solo: "",
                          label: "Personalized Message",
                          "error-messages": _vm.getErrors("message"),
                          maxlength: "250",
                          disabled: _vm.loading
                        },
                        on: {
                          blur: function($event) {
                            return _vm.$v.form.message.$touch()
                          }
                        },
                        model: {
                          value: _vm.form.message,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "message", $$v)
                          },
                          expression: "form.message"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._l(_vm.grouplist, function(group) {
                    return _c("v-checkbox", {
                      key: group.id,
                      staticClass: "py-0 pl-4 mt-n3",
                      attrs: { label: group.name, value: group.id },
                      model: {
                        value: _vm.form.groups,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "groups", $$v)
                        },
                        expression: "form.groups"
                      }
                    })
                  })
                ],
                2
              )
            : _vm._e(),
          _c("v-divider"),
          _c("v-spacer"),
          _c(
            "v-card-actions",
            { attrs: { align: "right" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "black", disabled: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.cancelResend()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  staticStyle: { position: "absolute", right: "8px" },
                  attrs: { disabled: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.resendInvite()
                    }
                  }
                },
                [_vm._v("Resend Invite")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }