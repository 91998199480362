var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "my-0 fixed", attrs: { id: "scheduleFilter" } },
      [
        _c(
          "v-row",
          {
            staticClass: "my-0 py-0 px-4",
            attrs: { justify: "space-between" }
          },
          [
            _c(
              "v-col",
              { staticClass: "py-0 px-0 mr-n4", attrs: { cols: "6" } },
              [
                _c("v-select", {
                  ref: "leagues",
                  attrs: {
                    items: _vm.leagues,
                    "item-text": "display",
                    "item-value": "id",
                    "menu-props": _vm.menuProps,
                    label: "League",
                    multiple: "",
                    "single-line": "",
                    "hide-details": "",
                    disabled: _vm.loading
                  },
                  on: { change: _vm.validateLeagueFilters },
                  scopedSlots: _vm._u([
                    {
                      key: "selection",
                      fn: function(ref) {
                        var item = ref.item
                        var index = ref.index
                        return [
                          _vm.searchData.filters.leagues.length === 1
                            ? _c("span", [_vm._v(_vm._s(item.display))])
                            : _vm._e(),
                          _vm.searchData.filters.leagues.length > 1 &&
                          index === 0
                            ? _c("span", [
                                _vm._v(
                                  "Multiple Leagues (" +
                                    _vm._s(
                                      _vm.searchData.filters.leagues.length
                                    ) +
                                    ")"
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.searchData.filters.leagues,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData.filters, "leagues", $$v)
                    },
                    expression: "searchData.filters.leagues"
                  }
                })
              ],
              1
            ),
            _c(
              "v-col",
              { staticClass: "py-0 px-0 mr-n5", attrs: { cols: "5" } },
              [
                _c("v-switch", {
                  staticClass: "py-0 mt-1",
                  attrs: { label: "My Teams", disabled: _vm.loading },
                  on: { change: _vm.triggerSearch },
                  model: {
                    value: _vm.searchData.filters.favorite,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData.filters, "favorite", $$v)
                    },
                    expression: "searchData.filters.favorite"
                  }
                }),
                _c("v-switch", {
                  staticClass: "py-0 mt-n5 mb-n2",
                  attrs: { label: "Open Offers", disabled: _vm.loading },
                  on: { change: _vm.triggerSearch },
                  model: {
                    value: _vm.searchData.filters.available,
                    callback: function($$v) {
                      _vm.$set(_vm.searchData.filters, "available", $$v)
                    },
                    expression: "searchData.filters.available"
                  }
                })
              ],
              1
            ),
            _c(
              "v-col",
              { staticClass: "py-3 px-0 ml-n7", attrs: { cols: "1" } },
              [
                _c(
                  "v-dialog",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  { attrs: { icon: "", color: "black" } },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v("help")])],
                              1
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.myteamhelper,
                      callback: function($$v) {
                        _vm.myteamhelper = $$v
                      },
                      expression: "myteamhelper"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "pt-3" },
                          [
                            _vm._v(
                              " Use selector switches to turn ON/OFF filtering of your favorite teams and to view only games with offers available."
                            ),
                            _c("br"),
                            _c("br"),
                            _vm._v(" Favorites are determined in two ways:"),
                            _c("br"),
                            _c("br"),
                            _c("v-icon", { attrs: { color: "red" } }, [
                              _vm._v("favorite")
                            ]),
                            _vm._v(
                              " A heart indicates a team you have directly set to be your favorites. A favorite can be set (or unset) while making a wager on a team by clicking on the heart next to it."
                            ),
                            _c("br"),
                            _c("br"),
                            _c("v-icon", { attrs: { color: "red" } }, [
                              _vm._v("flag")
                            ]),
                            _vm._v(
                              " A flag indicates one of the top three teams you have won with. "
                            )
                          ],
                          1
                        ),
                        _c("v-divider"),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "" },
                                on: {
                                  click: function($event) {
                                    _vm.myteamhelper = false
                                  }
                                }
                              },
                              [_vm._v(" Got it ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "px-1" },
      [
        _c("GameList", {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll:#dashboard-content",
              value: _vm.onScroll,
              expression: "onScroll",
              arg: "#dashboard-content"
            }
          ],
          staticClass: "px-3",
          attrs: { games: _vm.games, id: "gamelist" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }