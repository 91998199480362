var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.game && _vm.game.id
    ? _c(
        "v-row",
        { staticClass: "mt-0 mb-4 py-0" },
        [
          _c(
            "v-col",
            {
              staticClass: "py-0 datetime vig-card-header-mini",
              attrs: { cols: "12" }
            },
            [
              _c("span", { staticClass: "sub-text pull-left" }, [
                _vm._v(_vm._s(_vm.game.league) + " - " + _vm._s(_vm.game.sport))
              ]),
              _c("span", { staticClass: "sub-text pull-right" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.game.gamedate_formatted) +
                    " " +
                    _vm._s(_vm.game.starttime_formatted) +
                    " "
                ),
                _vm.game.broadcast
                  ? _c("span", [_vm._v(_vm._s(_vm.game.broadcast))])
                  : _vm._e()
              ])
            ]
          ),
          _c(
            "v-col",
            { staticClass: "mt-n2 py-0", attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "py-0" }, [
                    _vm.game.bettype_ps
                      ? _c(
                          "button",
                          {
                            staticClass: "vigzero-btn schedule-btn",
                            class:
                              /* isSpecialUser && */ _vm.game.wagersaway > 0
                                ? "openoffers"
                                : "",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.chooseWager(
                                  _vm.game.id,
                                  _vm.game.awayteam_id,
                                  "line"
                                )
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pull-left" }, [
                              _vm._v(_vm._s(_vm.game.awayteam) + " "),
                              _vm.game.favorite &&
                              (_vm.game.favoriteaway || _vm.game.commonteamaway)
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "icon text-red" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.game.favoriteaway
                                                ? "favorite"
                                                : "flag"
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _c("span", { staticClass: "pull-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.showSpread(_vm.game.ats_line, "away")
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm.game.bettype_ml && !_vm.game.bettype_ps
                      ? _c(
                          "button",
                          {
                            staticClass: "vigzero-btn schedule-btn",
                            class:
                              /* isSpecialUser && */ _vm.game.moneyaway > 0
                                ? "openoffers"
                                : "",
                            attrs: {
                              disabled:
                                !_vm.moneyLineIsValid(_vm.game) &&
                                _vm.game.moneyaway == 0
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.chooseWager(
                                  _vm.game.id,
                                  _vm.game.awayteam_id,
                                  "money",
                                  _vm.game.money_line * -1
                                )
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pull-left" }, [
                              _vm._v(_vm._s(_vm.game.awayteam) + " "),
                              _vm.game.favorite &&
                              (_vm.game.favoriteaway || _vm.game.commonteamaway)
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "icon text-red" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.game.favoriteaway
                                                ? "favorite"
                                                : "flag"
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _c("span", { staticClass: "pull-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.showSpread(_vm.game.money_line, "away")
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm.game.bettype_ps &&
                  _vm.game.bettype_ml &&
                  (_vm.moneyLineIsValid(_vm.game) ||
                    _vm.moneyLineHasOpenWagers(_vm.game))
                    ? _c(
                        "v-col",
                        { staticClass: "py-0 px-1", attrs: { cols: "2" } },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "vigzero-btn schedule-btn over-under-btn",
                              class:
                                /* isSpecialUser && */ _vm.game.moneyaway > 0
                                  ? "openoffers"
                                  : "",
                              attrs: {
                                disabled:
                                  !_vm.moneyLineIsValid(_vm.game) &&
                                  _vm.game.moneyaway == 0
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.chooseWager(
                                    _vm.game.id,
                                    _vm.game.awayteam_id,
                                    "money",
                                    _vm.game.money_line * -1
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.showSpread(_vm.game.money_line, "away")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.game.bettype_ou
                    ? _c(
                        "v-col",
                        { staticClass: "py-2 px-1", attrs: { cols: "2" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "oudiv",
                              style:
                                "background-image: url('/img/dashboard/" +
                                (_vm.isSpecialUser && _vm.game.wagersover > 0
                                  ? "red"
                                  : "grey") +
                                "_arrow_up.png')",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.chooseWager(
                                    _vm.game.id,
                                    _vm.game.hometeam_id,
                                    "over",
                                    _vm.game.ou_line
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { transform: "translateY(5px)" },
                                  style: _vm.getOUStyle(
                                    _vm.isSpecialUser,
                                    _vm.game.wagersover,
                                    _vm.game.ou_line
                                  )
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.game.ou_line
                                          ? _vm.game.ou_line
                                          : "N/A"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mt-n2 py-0", attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "my-0 py-0" },
                [
                  _c("v-col", { staticClass: "py-0" }, [
                    _vm.game.bettype_ps
                      ? _c(
                          "button",
                          {
                            staticClass: "vigzero-btn schedule-btn",
                            class:
                              /* isSpecialUser && */ _vm.game.wagershome > 0
                                ? "openoffers"
                                : "",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.chooseWager(
                                  _vm.game.id,
                                  _vm.game.hometeam_id,
                                  "line"
                                )
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pull-left" }, [
                              _vm._v(_vm._s(_vm.game.hometeam) + " "),
                              _vm.game.favorite &&
                              (_vm.game.favoritehome || _vm.game.commonteamhome)
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "icon text-red" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.game.favoritehome
                                                ? "favorite"
                                                : "flag"
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _c("span", { staticClass: "pull-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.showSpread(_vm.game.ats_line, "home")
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm.game.bettype_ml && !_vm.game.bettype_ps
                      ? _c(
                          "button",
                          {
                            staticClass: "vigzero-btn schedule-btn",
                            class:
                              /* isSpecialUser && */ _vm.game.moneyhome > 0
                                ? "openoffers"
                                : "",
                            attrs: {
                              disabled:
                                !_vm.moneyLineIsValid(_vm.game) &&
                                _vm.game.moneyhome == 0
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.chooseWager(
                                  _vm.game.id,
                                  _vm.game.hometeam_id,
                                  "money",
                                  _vm.game.money_line
                                )
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pull-left" }, [
                              _vm._v(_vm._s(_vm.game.hometeam) + " "),
                              _vm.game.favorite &&
                              (_vm.game.favoritehome || _vm.game.commonteamhome)
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "icon text-red" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.game.favoritehome
                                                ? "favorite"
                                                : "flag"
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _c("span", { staticClass: "pull-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.showSpread(_vm.game.money_line, "home")
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm.game.bettype_ps &&
                  _vm.game.bettype_ml &&
                  (_vm.moneyLineIsValid(_vm.game) ||
                    _vm.moneyLineHasOpenWagers(_vm.game))
                    ? _c(
                        "v-col",
                        { staticClass: "py-0 px-1", attrs: { cols: "2" } },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "vigzero-btn schedule-btn over-under-btn",
                              class:
                                /* isSpecialUser && */ _vm.game.moneyhome > 0
                                  ? "openoffers"
                                  : "",
                              attrs: {
                                disabled:
                                  !_vm.moneyLineIsValid(_vm.game) &&
                                  _vm.game.moneyhome == 0
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.chooseWager(
                                    _vm.game.id,
                                    _vm.game.hometeam_id,
                                    "money",
                                    _vm.game.money_line
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.showSpread(_vm.game.money_line, "home")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.game.bettype_ou
                    ? _c(
                        "v-col",
                        { staticClass: "py-2 px-1", attrs: { cols: "2" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "oudiv",
                              style:
                                "background-image: url('/img/dashboard/" +
                                (_vm.isSpecialUser && _vm.game.wagersunder > 0
                                  ? "red"
                                  : "grey") +
                                "_arrow_down.png')",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.chooseWager(
                                    _vm.game.id,
                                    _vm.game.awayteam_id,
                                    "under",
                                    _vm.game.ou_line
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    transform: "translateY(-2px)"
                                  },
                                  style: _vm.getOUStyle(
                                    _vm.isSpecialUser,
                                    _vm.game.wagersunder,
                                    _vm.game.ou_line
                                  )
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.game.ou_line
                                          ? _vm.game.ou_line
                                          : "N/A"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }