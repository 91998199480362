<template>
  <v-container>
    <v-card
      v-for="game in games"
      :key="game.id"
      class="mb-4"
    >
      <v-card-title style="font-size: 100%;">
        <v-row class="my-0">
          <v-col class="py-0">{{ game.awayteam }} @ {{ game.hometeam }}</v-col>
          <v-col class="py-0"><span class="subtext pull-right">{{ game.gamedate_formatted }} {{ game.starttime_formatted }}</span></v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
          <BoxScore :game="game"></BoxScore>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import GameService from '@/services/GameService'
import BoxScore from '@/components/games/BoxScore'

export default {
  name: 'Scores',

  components: {
    BoxScore
  },

  data () {
    return {
      games: []
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      GameService.search().then(
        (response) => {
          this.games = response.data.results
        }
      )
    }
  }
}
</script>
