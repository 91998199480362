var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                [
                  _vm.isModal
                    ? _c(
                        "v-btn",
                        {
                          staticStyle: {
                            position: "sticky",
                            top: "0",
                            float: "right"
                          },
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("close")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { staticClass: "my-0", attrs: { justify: "center" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "mt-2", attrs: { justify: "center" } },
                        [
                          _c("div", { staticClass: "black--text" }, [
                            _vm._v("VigZero User Agreement")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "px-6" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "black--text" },
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("b", [
                              _vm._v(
                                "1) Eligibility to join VigZero's peer to peer sports betting community"
                              )
                            ])
                          ]),
                          _c("v-spacer"),
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                " You must certify that you are 21+ years old. "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " You must be invited into our peer to peer community, by a current member. "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " You must provide VigZero with your email address and cell phone number. "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " If you have lost your privileges to be a member of our peer to peer community, you will not be allowed to rejoin. "
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "black--text" },
                        [
                          _c("v-col", [
                            _c("b", [_vm._v("2) Conditions of Membership")])
                          ]),
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                "You must abide by all rules contained herein."
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " You must only invite individuals to join our community that you know and trust to comply with our conditions of membership. "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " You agree that VigZero has no obligation to settle any bets that are not ultimately settled by another Member. "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " You agree that any disagreement with VigZero can only be processed through arbitration. "
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "black--text" },
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("b", [_vm._v("3) Rules")])
                          ]),
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                "Each community member may only have one account."
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " Members who do not pay lost bets, or confirm payment of winning bets, will be removed from the community and will not be allowed to rejoin. "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " Members statistics and peer ratings will be provided to other users, so they can choose to accept or confirm a bet with members based on their peer ratings. If VigZero can match a member's bet offer automatically to another member, the highest rated member will be chosen. "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " Members are restricted by a 'limit', the maximum accumulated amount allowed for outstanding/npeding bets. VigZero has full discretion to set a member's limit and/or criteria to revise any members' limit. "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                "Members may only bet up to $100 on any single bet."
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " A member may not offer or accept a new bet while they have an unpaid status on a losing bet. "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " A member may not offer or accept a new bet if they have a winning bet that is awaiting confirmation of payment. "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " If the status of a winner's bet shows paid and the winner has not confirmed said status for 48 hours, or more, the winner of said bet will not be able to make any additional bets until that status is confirmed, or challenged. "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " A member who invites a new member who disproportionately does not comply VigZero's rules and terms, can be removed from the community at the sole discretion of VigZero. "
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "black--text" },
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("b", [_vm._v("4) Privacy")])
                          ]),
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                " VigZero only tracks a member's winning percentage. Having no way to definitively confirm peer-to-peer transactions, VigZero cannot verify nor report on accumulated earnings or losses. "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " VigZero will not share or sell any member's personal data, unless forced to do so under a valid subpoena. "
                              )
                            ]),
                            _c("li", [
                              _vm._v(
                                " VigZero will collect and may use, in any manner it chooses, group data and trends. "
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isModal
                    ? _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("close")
                                }
                              }
                            },
                            [_vm._v(" Close ")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }