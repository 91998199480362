<style scoped>
</style>
<template>
  <v-container>
    <v-row
      class="py-0 my-0 mb-4"
      v-if="dispute && dispute.id"
    >
      <v-col
        cols="12"
        class="py-0"
      >
        <v-card class="vig-card">
          <v-card-title
            class="py-0"
            @click="expand = !expand"
          >
            Dispute #{{ dispute.id }} - {{ dispute.disputetype }}
          </v-card-title>

          <v-card-text
            class="py-0"
            @click="expand = !expand"
          >
            <div v-if="dispute.closed">
              <h2 class="text-center">This dispute has been closed.</h2>
              <h2 class="text-center">{{ dispute.closed }}</h2>
            </div>
            <div v-if="!dispute.closed && !requiresActionFromOwner && !requiresActionFromOther">
              <h2 class="text-center">This dispute is ready for review.</h2>
            </div>
            <div v-if="requiresActionFromOwner">
              <h2 class="text-center">This dispute is awaiting a response from {{ dispute.disputeuser }}.</h2>
            </div>
            <div v-if="requiresActionFromOther">
              <h2 class="text-center">This dispute is awaiting a response from {{ dispute.againstuser }}.</h2>
            </div>
          </v-card-text>

          <v-expand-transition>
            <div v-show="expand" style="margin-top: 0.5em;">
              <v-divider></v-divider>
              <v-card-text class="pt-5 pb-0">
                <v-row class="my-0">
                  <v-col
                    cols="12"
                    class="py-0"
                  >
                    <v-select
                      label="Dispute Type"
                      v-model="form.disputetype_id"
                      :items="disputetypes"
                      item-text="name"
                      item-value="id"
                      dense
                      hide-details
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    class="pb-0 pt-5"
                  >
                    <v-select
                      label="Dispute Type"
                      v-model="form.disputestatus_id"
                      :items="disputestatuses"
                      item-text="name"
                      item-value="id"
                      dense
                      hide-details
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    class="pb-0 pt-5"
                  >
                    <v-textarea
                      label="Comments"
                      outlined
                      rows="1"
                      v-model.trim="form.description"
                      dense
                      auto-grow
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions class="pb-0 pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  @click="update"
                  :disabled="$v.$invalid"
                  dense
                  color="primary"
                >Update Dispute</v-btn>
              </v-card-actions>
            </div>
          </v-expand-transition>

          <v-card-actions class="pa-0">
            <div
              class="vig-card-expand-container"
              @click="expand = !expand"
            >
              <v-icon>{{ expand ? "arrow_drop_up" : "arrow_drop_down" }}</v-icon>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <WagerInfo :wager="wager"></WagerInfo>
    <DisputeLog :dispute="dispute"></DisputeLog>
    <DisputeImages :dispute="dispute"></DisputeImages>
    <DisputeActions :dispute="dispute"></DisputeActions>
  </v-container>
</template>

<script>
import UserService from '@/services/UserService'
import DisputeService from '@/services/DisputeService'
import WagerInfo from '@/components/wagers/WagerInfo'
import DisputeLog from '@/components/disputes/widgets/Log'
import DisputeImages from '@/components/disputes/widgets/Images'
import DisputeActions from '@/components/disputes/widgets/Actions'

import { validationMixin } from 'vuelidate'
import {
  required,
  numeric
} from 'vuelidate/lib/validators'

export default {
  name: 'DisputeAdmin',
  mixins: [validationMixin],
  components: {
    WagerInfo,
    DisputeLog,
    DisputeImages,
    DisputeActions
  },
  props: {
    dispute_id: {
      type: [String, Number],
      default: 0
    }
  },
  data: function () {
    return {
      expand: false,
      disputetypes: [],
      disputestatuses: [],
      dispute: {},
      wager: {},
      form: {
        dispute_id: this.dispute_id,
        disputetype_id: null,
        disputestatus_id: null,
        description: null
      }
    }
  },
  validations: {
    form: {
      disputetype_id: {
        required,
        numeric
      },
      disputestatus_id: {
        required,
        numeric
      },
      description: {
      }
    }
  },
  mounted: function () {
    this.init()
  },
  computed: {
    isDisputeOwner: function () {
      const userinfo = UserService.getUser()
      return (+this.dispute.user_id === +userinfo.id)
    },
    requiresAction: function () {
      return ((this.isDisputeOwner && this.requiresActionFromOwner) || (!this.isDisputeOwner && this.requiresActionFromOther))
    },
    requiresActionFromOwner: function () {
      return ((this.dispute.disputetype_id === 10 && this.hasUpload(this.dispute.againstuser_id) && !this.hasUpload(this.dispute.disputeuser_id)) ||
        (this.dispute.disputetype_id === 20 && !this.hasUpload(this.dispute.disputeuser_id)))
    },
    requiresActionFromOther: function () {
      return ((this.dispute.disputetype_id === 10 && !this.hasUpload(this.dispute.againstuser_id)) ||
        (this.dispute.disputetype_id === 20 && this.hasUpload(this.dispute.disputeuser_id) && !this.hasUpload(this.dispute.againstuser_id)))
    }
  },
  methods: {
    async init () {
      this.$updateloading(1)
      DisputeService.initUpdate(this.dispute_id).then(response => {
        this.disputetypes = response.data.disputetypes
        this.disputestatuses = response.data.disputestatuses
        this.dispute = response.data.dispute
        this.form.disputetype_id = this.dispute.disputetype_id
        this.form.disputestatus_id = this.dispute.disputestatus_id
        this.wager = response.data.wager
        this.$updateloading(-1)
      }).catch(errors => {
        this.$updateloading(-1)
        this.$notify({
          group: 'errors',
          title: 'Error',
          text: errors.error,
          type: 'warn'
        })
        console.dir(errors)
      })
    },
    async update () {
      this.$updateloading(1)
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          group: 'errors',
          title: 'Invalid Submission',
          text: 'Please correct your form entries and try again.',
          type: 'warn'
        })
      } else {
        const formData = new FormData()
        formData.append('dispute_id', this.dispute_id)
        formData.append('action', 'Update')
        formData.append('disputetype_id', this.form.disputetype_id)
        formData.append('disputestatus_id', this.form.disputestatus_id)
        formData.append('description', this.form.description)
        DisputeService.update(this.dispute_id, formData).then(response => {
          this.$updateloading(-1)
          this.$notify({
            group: 'notices',
            title: 'Dispute Updated',
            text: 'Dispute has been updated for review.',
            type: 'info'
          })
          window.location.reload(true)
        }).catch(errors => {
          this.$updateloading(-1)
          this.$notify({
            group: 'errors',
            title: 'Dispute Update Failed',
            text: errors.error,
            type: 'warn'
          })
          console.dir(errors)
        })
      }
    },
    hasUpload (userId) {
      let upload = false
      if (this.dispute && this.dispute.logs && this.dispute.logs.length > 0) {
        this.dispute.logs.some(function (l) {
          if (l.document_id > 0 && +l.user_id === +userId) {
            return (upload = true)
          }
        })
      }
      return upload
    },
    getValidationClass (fieldName) {
      const field = this.$v.form[fieldName]
      if (field) {
        return {
          'md-invalid': (field.$invalid && field.$dirty)
        }
      }
    }
  }
}
</script>
