import { render, staticRenderFns } from "./Probability.vue?vue&type=template&id=278bc960&scoped=true&"
import script from "./Probability.vue?vue&type=script&lang=js&"
export * from "./Probability.vue?vue&type=script&lang=js&"
import style0 from "./Probability.vue?vue&type=style&index=0&id=278bc960&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "278bc960",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/websites/vigzero/vig-zero-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('278bc960')) {
      api.createRecord('278bc960', component.options)
    } else {
      api.reload('278bc960', component.options)
    }
    module.hot.accept("./Probability.vue?vue&type=template&id=278bc960&scoped=true&", function () {
      api.rerender('278bc960', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/wagers/widget/Probability.vue"
export default component.exports