var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.games
    ? _c("div", { staticClass: "game-list" }, [
        _c(
          "div",
          { staticClass: "pt-4" },
          [
            _vm.games.length === 0
              ? _c("div", [
                  _c("h3", { staticClass: "text-center" }, [
                    _vm._v("No games found")
                  ])
                ])
              : _vm._e(),
            _vm._l(_vm.games, function(game) {
              return _c(
                "div",
                {
                  key: game.id,
                  staticClass: "vig-card",
                  class: game.favorite ? "game-favorite" : "",
                  attrs: { id: "gamescroller_" + game.id }
                },
                [
                  _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          game.expand = !game.expand
                        }
                      }
                    },
                    [_c("GameSummary", { attrs: { game: game } })],
                    1
                  )
                ]
              )
            })
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }