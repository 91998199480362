var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("path", {
    attrs: {
      d:
        "M262.36,250.59a5.3,5.3,0,0,0-3.21-4.37,4.86,4.86,0,0,0-1.55-.4s0,0-.05,0h-1s0,0-.05,0a5.21,5.21,0,0,0-3.07,1.39s-.08,0-.13,0a5.36,5.36,0,0,0-8.56,2.13,5.43,5.43,0,0,0-.31,1.31s0,0,0,.06v1c.05,0,0,0,0,.05a5.37,5.37,0,0,0,1.61,3.3l2.77,2.77v-9c0-.06,0-.09.09-.09H250c.1,0,.12,0,.11.14a.86.86,0,0,0,0,.16v0a.08.08,0,0,0,0,.07v0a.08.08,0,0,0,0,.1s0,0,0,.07h4.18a.07.07,0,0,1,0,0,.3.3,0,0,1,0,.1h0l-.07.05.06.06-.06.07.08,0-.1.06.1.06-.1.06.09,0-.07.06.06.06,0,.06,0,.05-.06.07.07.05-.09,0,.1.06-.09.06.08.06-.08,0,.07.07-.06.06.07.06-.09.05.1.06-.08,0,.07.07-.07,0,.07.08-.07,0,.06.06-.06.07.07,0-.07.06.06.06,0,.06h3.63l-.07.11.06-.05H258s0,.06,0,.07l-1.53,1.54-.82.81,2.4,2.41,0,.06-.06-.05-.06.05-.06-.05-.06.05c0-.05-.07-.05-.11,0s-.07-.06-.11,0-.08-.06-.11,0l-.06-.05-.06,0-.06,0-.06,0s-.07,0-.11,0l-.06-.05-.06.05c0-.06-.07-.05-.11,0s-.07-.06-.11,0l-.06-.05-.06.05c0-.06-.07-.05-.11,0l-.06-.05-.06.05-.05-.05-.06.05s-.07-.07-.11,0l-.06-.05-.06,0v0h-.11v0l-.06,0-.06,0,0,0-.06.05-.06-.05-.06,0s-.07,0-.11,0l-.06-.05-.05,0s-.08-.06-.11,0l-.07-.05,0,.05-.06-.05-.06,0s-.07-.07-.11,0l-.06-.05-.06,0,0,0-.06,0s-.08,0-.11,0l-.06-.05-.06,0v0h-.11v0l0,0,0,0-.06,0h-.11v0s-.08-.07-.1,0h-.13s-.07,0-.11,0-.08-.06-.12,0-.07-.06-.11,0-.08-.07-.1,0h-.13s-.08,0-.1,0H253a.08.08,0,0,0-.12,0c0-.05-.07-.05-.11,0s-.07-.06-.11,0-.08-.07-.1,0h-.13a.08.08,0,0,0-.12,0c0-.05-.07-.05-.11,0s-.07-.06-.11,0-.08-.06-.12,0-.08-.06-.11,0-.08-.06-.12,0,0-.05-.07,0l2.77-1.51H251.6v1.52a.2.2,0,0,1-.06-.17c0-.43,0-.85,0-1.27,0-.09,0-.09-.09-.07s-.05,0-.08,0-.06,0-.1,0-.08.06-.11,0-.08.06-.12,0-.08,0-.12,0-.07,0-.11,0-.08,0-.11,0-.08,0-.12,0-.07.06-.11,0-.08,0-.12,0-.07,0-.11,0-.08,0-.12,0-.11,0-.06.14,0,.1,0,.15a.27.27,0,0,1,0,.09.28.28,0,0,1,0,.09v0a.17.17,0,0,0,0,.07v.4a.22.22,0,0,1,0,.08v3.21l3.22,3.21s.05,0,0,.06h.08s0,0,0-.06l7.25-7.25a5.58,5.58,0,0,0,1.47-2.35,5.9,5.9,0,0,0,.2-1v-.1s0,0,0,0v-.94S262.37,250.61,262.36,250.59Zm-10.71,5.81h0l0,0Z",
      transform: "translate(-244.39 -245.79)"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }