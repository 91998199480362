var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      directives: [
        {
          name: "touch",
          rawName: "v-touch",
          value: {
            left: function() {
              return _vm.swipe(0)
            },
            right: function() {
              return _vm.swipe(1)
            }
          },
          expression:
            "{\n    left: () => swipe(0),\n    right: () => swipe(1),\n  }"
        }
      ],
      staticClass: "pa-0 dash",
      style: _vm.viewHeight
    },
    [
      _c("div", { staticStyle: { height: "40px" } }),
      _c(
        "div",
        {
          staticClass: "py-0 tablabel robo",
          staticStyle: {
            width: "100%",
            position: "absolute",
            top: "8px",
            left: "0",
            "z-index": "5"
          },
          on: {
            click: function($event) {
              return _vm.reload()
            }
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.pageTitle) + " "),
          _vm.activeTab("schedule") || _vm.activeTab("pending")
            ? _c(
                "v-icon",
                {
                  staticClass: "white--text pull-right",
                  staticStyle: {
                    "margin-top": "3px",
                    position: "absolute",
                    right: "4px"
                  },
                  attrs: { size: "30" }
                },
                [_vm._v("refresh")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dashboard-content my-0 pt-2 px-2 z-index:0;",
          attrs: { id: "dashboard-content" }
        },
        [
          _vm.fatalerror != ""
            ? _c("div", [_vm._v(_vm._s(_vm.fatalerror))])
            : _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "container py-0", attrs: { cols: "12" } },
                    [_c("ChatNotifications")],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "container py-0", attrs: { cols: "12" } },
                    [_c("ReleaseNotes")],
                    1
                  ),
                  _vm.groupinvites && _vm.groupinvites.length > 0
                    ? _c(
                        "v-col",
                        {
                          staticClass: "container py-0",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("div", { staticClass: "text-center" }, [
                            _c("h4", [
                              _vm._v(
                                "You've been invited to the group(s) below. Click to join"
                              )
                            ])
                          ]),
                          _vm._l(_vm.groupinvites, function(invite) {
                            return _c("invitation", {
                              key: invite.id,
                              attrs: { invite: invite },
                              on: {
                                update: function($event) {
                                  return _vm.getGroupInvites()
                                }
                              }
                            })
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.disputemessage != ""
                    ? _c(
                        "v-col",
                        {
                          staticClass: "container py-0 px-4",
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "card" },
                            [
                              _c("v-card-text", [
                                _c("p", [_vm._v(_vm._s(_vm.disputemessage))])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.needsPaymentMethod && _vm.loggedIn
                    ? _c(
                        "v-col",
                        {
                          staticClass: "container py-0 px-4",
                          attrs: { cols: "12" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/settings")
                            }
                          }
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "card warning-card" },
                            [
                              _c("v-card-text", [
                                _c("p", [
                                  _c("b", [
                                    _vm._v(
                                      "You have yet to provide your Venmo handle. In order to make or take bets, you must first provide your Venmo handle under Settings. Select this message to go there now."
                                    )
                                  ])
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.debts && _vm.debts.length > 0
                    ? _c(
                        "v-col",
                        {
                          staticClass: "container py-0 px-4",
                          attrs: { cols: "12" }
                        },
                        [
                          _vm.debts && _vm.debts.length > 0
                            ? _c("Debts", {
                                attrs: {
                                  debts: _vm.debts,
                                  reloadKey: _vm.debtsKey
                                },
                                on: { updatedata: _vm.fetchdata }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.payouts && _vm.payouts.length > 0
                    ? _c(
                        "v-col",
                        {
                          staticClass: "container py-0 px-4",
                          attrs: { cols: "12" }
                        },
                        [
                          _vm.payouts && _vm.payouts.length > 0
                            ? _c("Payouts", {
                                attrs: {
                                  payouts: _vm.payouts,
                                  reloadKey: _vm.payoutsKey
                                },
                                on: { updatedata: _vm.fetchdata }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeTab("pending")
                    ? _c(
                        "v-col",
                        { staticClass: "py-0 px-4", attrs: { cols: "12" } },
                        [
                          _vm.showpending
                            ? _c("Pending", {
                                attrs: {
                                  reloadKey: _vm.pendingOffersKey,
                                  section: _vm.section
                                },
                                on: { updatedata: _vm.fetchdata }
                              })
                            : _c("h3", { staticClass: "text-center" }, [
                                _vm._v("No pending items")
                              ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeTab("schedule")
                    ? _c(
                        "v-col",
                        { staticClass: "py-0 px-4", attrs: { cols: "12" } },
                        [
                          _c("Schedule", {
                            staticStyle: { width: "100%" },
                            attrs: { gamescroll: _vm.scrollid }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeTab("favorite")
                    ? _c(
                        "v-col",
                        {
                          staticClass: "py-0 mb-4 px-4",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("Stats", {
                            attrs: {
                              section: _vm.section,
                              scrollid: _vm.scrollid
                            },
                            on: { updatedata: _vm.fetchdata }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeTab("bestvalue")
                    ? _c(
                        "v-col",
                        {
                          staticClass: "py-0 mb-4 px-4",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("BestValueOffers", {
                            attrs: {
                              section: _vm.section,
                              scrollid: _vm.scrollid
                            },
                            on: { updatedata: _vm.fetchdata }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeTab("group")
                    ? _c(
                        "v-col",
                        {
                          staticClass: "py-0 mb-4 px-4",
                          attrs: { cols: "12" }
                        },
                        [_c("group-list")],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0 bottom-nav" },
        [
          _c(
            "v-flex",
            {
              staticClass: "py-0 nav-icon",
              style: _vm.getActiveStyle("group"),
              on: {
                click: function($event) {
                  return _vm.switchTab("group")
                }
              }
            },
            [
              _c("v-img", {
                staticClass: "nav-pic",
                attrs: { src: _vm.getImageSource("group") }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "py-0 nav-icon",
              style: _vm.getActiveStyle("pending"),
              on: {
                click: function($event) {
                  return _vm.switchTab("pending")
                }
              }
            },
            [
              _c("v-img", {
                staticClass: "nav-pic",
                attrs: { src: _vm.getImageSource("pending") }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "py-0 nav-icon",
              style: _vm.getActiveStyle("schedule"),
              on: {
                click: function($event) {
                  return _vm.switchTab("schedule")
                }
              }
            },
            [
              _c("v-img", {
                staticClass: "nav-pic",
                attrs: { src: _vm.getImageSource("schedule") }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "py-0 nav-icon",
              style: _vm.getActiveStyle("bestvalue"),
              on: {
                click: function($event) {
                  return _vm.switchTab("bestvalue")
                }
              }
            },
            [
              _c("v-img", {
                staticClass: "nav-pic",
                attrs: { src: _vm.getImageSource("bestvalue") }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "py-0 nav-icon",
              style: _vm.getActiveStyle("favorite"),
              on: {
                click: function($event) {
                  return _vm.switchTab("favorite")
                }
              }
            },
            [
              _c("v-img", {
                staticClass: "nav-pic",
                attrs: { src: _vm.getImageSource("favorite") }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }