<template>
  <div class="page-container">
    <v-row class="py-0 my-0 px-1">
      <v-col cols="12">
        <v-card
          elevation="10"
          class="px-1 pt-4"
        >
          <v-card-title class="py-0 my-0">
            <v-row
              justify="start"
              class="text-h5 my-0"
            > Notifications Settings </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row
              justify="space-around"
              class="my-0 py-0 black--text"
            >
              <v-col class="text-center py-0"> Email </v-col>
              <v-col class="text-center py-0"> Text </v-col>
              <v-col
                v-show="devices > 0"
                class="text-center py-0"
              >Push</v-col>
            </v-row>
            <v-divider class="black"></v-divider>
            <v-row
              v-for="notification in notifications"
              :key="notification.id"
              class="my-0 py-0"
            >
              <v-col
                cols="12"
                class="pa-0 my-0"
              >
                <v-row
                  justify="center"
                  class="pa-0 my-0 black--text"
                >
                  {{ notification.name }}
                </v-row>
                <v-row
                  justify="space-around"
                  class="px-6 my-0"
                >
                  <v-col
                    cols="1"
                    class="pa-0 my-0"
                  >
                    <v-checkbox
                      class="py-0 my-0"
                      v-model="notification.email"
                      :disabled="notification.type !== 6"
                      @change="savenotifications()"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="1"
                    class="pa-0 my-0"
                  >
                    <v-checkbox
                      class="py-0 my-0"
                      v-model="notification.text"
                      @change="savenotifications()"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="1"
                    class="pa-0 my-0"
                    v-show="devices > 0"
                  >
                    <v-checkbox
                      class="py-0 my-0"
                      v-model="notification.push"
                      @change="savenotifications()"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-divider
                  v-if="notification.name !== 'Promotional'"
                  class="black"
                ></v-divider>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- <v-row class="px-4 my-0">
            <v-col cols="12" class="py-0">
              <v-card-actions class="py-0">
                <v-row justify="start" v-if="!editing" class="my-0">
                  <v-col cols="2" class="py-0">
                    <v-btn icon  @click="editnotifications"
                      ><v-icon>edit</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
                <v-row justify="start" v-if="editing" class="my-0">
                  <v-col cols="1" class="py-0">
                    <v-btn icon @click="resetedit"
                      ><v-icon>cancel</v-icon></v-btn
                    >
                  </v-col>
                  <v-col cols="1" class="py-0">
                    <v-btn
                      text
                      @click="savenotifications"
                      :disabled="notificationempty"
                      ><v-icon>save</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row> -->
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NotificationService from '@/services/NotificationsService'
import UserService from '@/services/UserService'

export default {
  name: 'Game',
  data: function () {
    return {
      notifications: {
        disputeresolved: {
          name: 'Dispute Resolved',
          type: 5,
          push: false,
          email: false,
          text: false
        },
        betindispute: {
          name: 'Bet In Dispute',
          type: 3,
          push: false,
          email: false,
          text: false
        },
        debtsettled: {
          name: 'Debt Settled',
          type: 4,
          push: false,
          email: false,
          text: false
        },
        offeraccepted: {
          name: 'Offer Accepted',
          type: 1,
          push: false,
          email: false,
          text: false
        },
        wagersettled: {
          name: 'Wager Settled',
          type: 2,
          push: false,
          email: false,
          text: false
        },
        group: {
          name: 'Group',
          type: 7,
          push: false,
          email: true,
          text: false
        },
        promotional: {
          name: 'Promotional',
          type: 6,
          push: false,
          email: false,
          text: false
        }
      },
      editing: false,
      notificationempty: false,
      devices: null,
      saveTimer: null
    }
  },
  created: function () {
    this.init()
  },
  mounted: function () {
  },
  updated: function () {
    // this.savenotifications()
  },
  methods: {
    init () {
      this.$updateloading(1)
      NotificationService.getNotifications(UserService.getUser().id)
        .then(response => {
          this.$updateloading(-1)
          this.setNotifications(response.data.notifications)
          this.devices = response.data.devices.length
        })
        .catch(error => {
          console.log(error)
          this.$notify({
            group: 'errors',
            title: 'Something went wrong',
            text: 'Try again later',
            type: 'warn'
          })
        })
    },
    setNotifications (notifications) {
      notifications.forEach(notification => {
        if (+notification.notificationtype_id === 5) {
          this.checkNotifications(notification, this.notifications.disputeresolved)
        }

        if (+notification.notificationtype_id === 3) {
          this.checkNotifications(notification, this.notifications.betindispute)
        }

        if (+notification.notificationtype_id === 4) {
          this.checkNotifications(notification, this.notifications.debtsettled)
        }

        if (+notification.notificationtype_id === 1) {
          this.checkNotifications(notification, this.notifications.offeraccepted)
        }

        if (+notification.notificationtype_id === 2) {
          this.checkNotifications(notification, this.notifications.wagersettled)
        }

        if (+notification.notificationtype_id === 6) {
          this.checkNotifications(notification, this.notifications.promotional)
        }

        if (+notification.notificationtype_id === 7) {
          this.checkNotifications(notification, this.notifications.group)
        }
      })
    },
    checkNotifications (data, notification) {
      notification.email = false
      notification.text = false
      notification.push = false
      if (data.deliverymethod.includes('email')) {
        notification.email = true
      }
      if (data.deliverymethod.includes('text')) {
        notification.text = true
      }
      if (data.deliverymethod.includes('push')) {
        notification.push = true
      }
    },
    editnotifications () {
      this.editing = true
    },
    resetedit () {
      this.init()
      this.editing = false
    },
    savenotifications () {
      if (this.saveTimer) {
        clearTimeout(this.saveTimer)
      }
      this.saveTimer = setTimeout(() => {
        this.$updateloading(1)
        NotificationService.saveSettings(this.notifications)
          .then(response => {
            // this.resetedit()
            this.$updateloading(-1)
          })
          .catch(error => {
            console.error(error)
            this.$notify({
              group: 'errors',
              title: 'Update Failed',
              text: 'Try again later',
              type: 'warn'
            })
            this.resetedit()
            this.$updateloading(-1)
          })
      }, 1000)
    }
  }
}
</script>

<style scoped>
.notificationsetting {
  padding: 20px;
  border-top: 1px solid black;
}

.methodtypes {
  margin: 0 auto;
}
</style>
