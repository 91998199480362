<style scoped>
</style>
<template>
  <v-container class="container">
    <WagerInfo :wager="wager"></WagerInfo>

    <v-row class="my-0 py-0">
      <v-col cols="12" class="py-0">

        <v-card
          class="vig-card"
        >
          <v-card-title>
            <div class="text-center">{{ (wager.dispute_id) ? 'Already Disputed' : 'Create Dispute' }}</div>
          </v-card-title>

          <v-card-text v-if="wager.dispute_id">
            <p class="text-center">This wager has already been disputed.</p>
          </v-card-text>

          <v-card-text v-if="!wager.dispute_id">
            <div :class="getValidationClass('disputetype_id')">
              <v-select
                v-model="form.disputetype_id"
                :items="filteredDisputeTypes"
                label="Dispute Type"
                item-text="name"
                item-value="id"
                required
              ></v-select>
            </div>

            <v-col cols="12" class="py-0">
              <v-textarea
                label="Comments"
                solo
                v-model.trim="form.description"
              ></v-textarea>
            </v-col>

            <v-col cols="12"
              class="py-0"
              v-if="form.disputetype_id == 20"
            >
              <div :class="getValidationClass('upload')">
                <v-file-input
                  v-model="form.upload"
                  label="Proof of Payment"
                  accept="image/*"
                  :required="form.form.disputetype_id == 20"
                  v-on:change="setFileUpload"
                  ref="upload"
                ></v-file-input>
              </div>
            </v-col>
          </v-card-text>

          <v-card-actions>
              <v-btn
                v-if="!wager.dispute_id"
                @click="create"
                :disabled="$v.$invalid"
              >Create</v-btn>
              <v-btn
                v-if="wager.dispute_id"
                :to="{ name: 'disputereview', params: { dispute_id: wager.dispute_id } }"
              >Review Dispute</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserService from '@/services/UserService'
import DisputeService from '@/services/DisputeService'
import WagerInfo from '@/components/wagers/WagerInfo'

import { validationMixin } from 'vuelidate'
import {
  required,
  requiredIf,
  numeric
} from 'vuelidate/lib/validators'

export default {
  name: 'DisputeCreate',
  mixins: [validationMixin],
  components: {
    WagerInfo
  },
  props: {
    wager_id: {
      type: [String, Number],
      default: 0
    }
  },
  data: function () {
    return {
      disputetypes: [],
      wager: {},
      form: {
        wager_id: this.wager_id,
        disputetype_id: null,
        description: null,
        upload: null,
        file: null
      }
    }
  },
  validations: {
    form: {
      wager_id: {
        required,
        numeric
      },
      disputetype_id: {
        required,
        numeric
      },
      upload: {
        required: requiredIf(function (form) {
          return (form && form.disputetype_id === 20)
        })
      }
    }
  },
  mounted: function () {
    this.init()
  },
  computed: {
    filteredDisputeTypes: function () {
      const userinfo = UserService.getUser()
      if (!userinfo.isadmin) {
        const typefilter = (+userinfo.id === +this.wager.winner_id) ? 20 : 10
        return this.disputetypes.filter((t) => {
          return (+t.id !== typefilter)
        })
      }
      return this.disputetypes
    }
  },
  methods: {
    async init () {
      this.$updateloading(1)
      DisputeService.initCreate(this.wager_id).then(response => {
        this.disputetypes = response.data.disputetypes
        this.wager = response.data.wager
        this.form.disputetype_id = this.filteredDisputeTypes[0].id
        this.$updateloading(-1)
      }).catch(errors => {
        this.$updateloading(-1)
        this.$notify({
          group: 'errors',
          title: 'Error',
          text: errors.data.error,
          type: 'warn'
        })
        console.dir(errors)
      })
    },
    async create () {
      this.$updateloading(1)
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          group: 'errors',
          title: 'Invalid Submission',
          text: 'Please correct your form entries and try again.',
          type: 'warn'
        })
      } else {
        const formData = new FormData()
        formData.append('wager_id', this.form.wager_id)
        formData.append('disputetype_id', this.form.disputetype_id)
        formData.append('description', this.form.description)
        formData.append('upload', this.form.upload)
        formData.append('file', this.form.file)
        DisputeService.create(formData).then(response => {
          this.$updateloading(-1)
          this.$router.push('/disputes', () => {
            this.$notify({
              group: 'notices',
              title: 'Dispute Created',
              text: 'Your dispute has been submitted for review.',
              type: 'info'
            })
          })
        }).catch(errors => {
          this.$updateloading(-1)
          this.$notify({
            group: 'errors',
            title: 'Dispute Failed',
            text: errors.response.data.error,
            type: 'warn'
          })
          console.dir(errors)
        })
      }
    },
    setFileUpload () {
      console.dir(this.$refs)
      this.form.file = this.$refs.upload.$refs.inputFile.files[0]
    },
    getValidationClass (fieldName) {
      const field = this.$v.form[fieldName]
      if (field) {
        return {
          'md-invalid': (field.$invalid && field.$dirty)
        }
      }
    }
  }
}
</script>
