<template>
  <div>
    <v-row class="ma-0">
      <v-col
        cols="12"
        class="py-0"
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
            class="py-0"
            v-for="g in groups"
            :key="g.id"
            @click="opengroup(g.friendgroup_id)"
          >
            <group-tile :group="g"></group-tile>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import GroupTile from '@/components/dashboard/GroupTile'
import CommunityService from '@/services/CommunityService'

export default {
  components: {
    GroupTile
  },
  data: function () {
    return {
      groups: null
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    init: function () {
      this.$updateloading(1)
      CommunityService.groupListInit().then(
        (response) => {
          var groups = response.data
          this.groups = []
          if (groups !== null && groups.length > 0) {
            this.groups = groups
            if (this.groups.length === 1 && this.groups[0].friendgroup_id > 0) {
              this.$router.push('/group/' + this.groups[0].friendgroup_id)
            }
          }
        }
      ).finally(() => {
        this.$updateloading(-1)
      })
    },
    opengroup: function (id) {
      this.$router.push('/group/' + id)
    }
  }
}
</script>
