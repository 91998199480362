
<template>
  <div class="wrapper fadeInDown">
    <div>
      <v-form
        @submit.prevent="resetpassword()"
        v-model="valid"
      >
        <v-row>
          <v-col cols="12">
            <v-card class="px-4">
              <v-card-title v-if="!showmessage">Request Password Reset</v-card-title>
              <v-card-title v-if="showmessage">Email Sent</v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="form.email"
                  v-if="!showmessage"
                  label="Email*"
                  type="email"
                  :rules="emailRules"
                  required
                >
                </v-text-field>

                <div
                  v-if="showmessage"
                  class="black--text"
                >
                  If there is an account connected to the email you have entered, we will send an email with instructions
                  on resetting your password. If you have not recieved the email within five minutes,
                  please check your junk/spam folder. If you are still unable to find the email, make another request
                  <span
                    class="link"
                    @click="showmessage = false"
                  >here</span>.
                </div>
              </v-card-text>
              <v-row justify="end">
                <v-col cols="4">
                  <v-card-actions>
                    <v-btn
                      text
                      :disabled="!valid"
                      v-if="!showmessage"
                      @click.prevent="resetpassword()"
                    >Submit</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import { validationMixin } from 'vuelidate'
import {
  required,
  email
} from 'vuelidate/lib/validators'

export default {
  data: function () {
    return {
      form: {
        email: null
      },
      valid: false,
      errors: {
        email: ''
      },
      sending: false,
      showmessage: false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ]
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init: function () {
      if (UserService.loggedIn()) {
        // user is logged in, why not use settings page?
        this.$router.push('/settings', () => {
          this.$notify({
            group: 'notices',
            title: 'Reset Error',
            text: 'Reset from the settings page',
            type: 'danger'
          })
        })
      }
    },
    resetpassword: function () {
      if (this.valid) {
        var data = {
          email: this.form.email
        }
        UserService.requestresetpassword(data).then(
          (response) => {
            if (response.data.emailsent) {
              this.showmessage = true
              this.form.email = null
            } else {
              this.$notify({
                group: 'notices',
                title: 'Error',
                text: 'Something went wrong',
                type: 'danger'
              })
            }
          }
        ).catch(err => {
          this.$notify({
            group: 'notices',
            title: err.response.data.errors[0],
            text: err.response.data.error,
            type: 'danger'
          })
        })
      }
    },
    getValidationClass (fieldName) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'md-invalid': (field.$invalid && field.$dirty) || this.errors[fieldName].length > 0
        }
      }
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  }
}
</script>
