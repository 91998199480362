var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.showSimilar
      ? _c(
          "div",
          { staticClass: "dialog" },
          [
            _c("BackButton"),
            _c(
              "md-steppers",
              {
                attrs: {
                  "md-active-step": _vm.active,
                  "md-vertical": "",
                  "md-linear": ""
                },
                on: {
                  "update:mdActiveStep": function($event) {
                    _vm.active = $event
                  },
                  "update:md-active-step": function($event) {
                    _vm.active = $event
                  }
                }
              },
              [
                _c(
                  "md-step",
                  {
                    attrs: {
                      id: "game",
                      "md-label": "Pick a Game",
                      "md-editable": false,
                      "md-done": _vm.gameChose
                    },
                    on: {
                      "update:mdDone": function($event) {
                        _vm.gameChose = $event
                      },
                      "update:md-done": function($event) {
                        _vm.gameChose = $event
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "md-title text-center" }, [
                      _c("h4", [_vm._v("Upcoming Games")])
                    ]),
                    _c(
                      "div",
                      { staticClass: "md-layot text-center" },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass:
                              "md-layout-item md-size-100 md-accent md-raised",
                            on: {
                              click: function($event) {
                                return _vm.$router.push("/schedule")
                              }
                            }
                          },
                          [_vm._v("Search Games")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "game-list" },
                      _vm._l(_vm.games, function(game) {
                        return _c("div", { key: game.id }, [
                          _c(
                            "div",
                            {
                              staticClass: "button-border",
                              on: {
                                click: function($event) {
                                  return _vm.gameClicked(game)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "datetime" },
                                [
                                  _c("span", { staticClass: "sub-text" }, [
                                    _vm._v(
                                      _vm._s(game.league) +
                                        " " +
                                        _vm._s(
                                          _vm._f("datetime")(game.starttime)
                                        )
                                    )
                                  ]),
                                  _c("md-divider")
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout md-alignment-center-space-between"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "md-layout-item md-size-100"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout md-alignment-center-space-between"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md-layout-item md-size-100"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "md-layout linepadding"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "md-layout-item md-size-100"
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(" ("),
                                                        _c(
                                                          "span",
                                                          {
                                                            class: _vm.lineColor(
                                                              game.ats_line * -1
                                                            )
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "offerLine"
                                                                )(
                                                                  game.ats_line *
                                                                    -1
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(") ")
                                                      ]),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(game.awayteam)
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "md-layout linepadding"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "md-layout-item md-size-100"
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(" ("),
                                                        _c(
                                                          "span",
                                                          {
                                                            class: _vm.lineColor(
                                                              game.ats_line
                                                            )
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "offerLine"
                                                                )(game.ats_line)
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(") ")
                                                      ]),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(game.hometeam)
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md-layout-item md-size-100 text-center"
                                            },
                                            [
                                              _vm._v(
                                                "O/U: " + _vm._s(game.ou_line)
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "md-step",
                  {
                    attrs: {
                      id: "details",
                      "md-label": "Setup Offer",
                      "md-editable": false,
                      "md-done": _vm.detailsSet
                    },
                    on: {
                      "update:mdDone": function($event) {
                        _vm.detailsSet = $event
                      },
                      "update:md-done": function($event) {
                        _vm.detailsSet = $event
                      }
                    }
                  },
                  [
                    _vm.chosenGame !== null
                      ? _c(
                          "div",
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout md-gutter md-alignment-center-space-around"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-xsmall-size-35 md-small-size-30 md-medium-size-25 md-size-20"
                                  },
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass: "md-primary md-raised",
                                        class: [
                                          _vm.offerDetails.teamChoice ==
                                          _vm.chosenGame.awayteam_id
                                            ? "md-accent"
                                            : ""
                                        ],
                                        on: {
                                          click: function($event) {
                                            return _vm.selectLine(
                                              _vm.chosenGame.awayteam_id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.chosenGame.awayteam_abbr)
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "md-layout-item md-size-5" },
                                  [_vm._v("AT")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-xsmall-size-35 md-small-size-30 md-medium-size-25 md-size-20"
                                  },
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass: "md-primary md-raised",
                                        class: [
                                          _vm.offerDetails.teamChoice ==
                                          _vm.chosenGame.hometeam_id
                                            ? "md-accent"
                                            : ""
                                        ],
                                        on: {
                                          click: function($event) {
                                            return _vm.selectLine(
                                              _vm.chosenGame.hometeam_id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.chosenGame.hometeam_abbr)
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout md-gutter md-alignment-center-space-around"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-xsmall-size-35 md-small-size-30 md-medium-size-25 md-size-20"
                                  },
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass: "md-primary md-raised",
                                        class: [
                                          _vm.offerDetails.overUnder == 1
                                            ? "md-accent"
                                            : ""
                                        ],
                                        on: {
                                          click: function($event) {
                                            return _vm.selectOU(1)
                                          }
                                        }
                                      },
                                      [_vm._v("Over")]
                                    )
                                  ],
                                  1
                                ),
                                _c("div", {
                                  staticClass: "md-layout-item md-size-5"
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-xsmall-size-35 md-small-size-30 md-medium-size-25 md-size-20"
                                  },
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass: "md-primary md-raised",
                                        class: [
                                          _vm.offerDetails.overUnder == 0
                                            ? "md-accent"
                                            : ""
                                        ],
                                        on: {
                                          click: function($event) {
                                            return _vm.selectOU(0)
                                          }
                                        }
                                      },
                                      [_vm._v("Under")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm.offerDetails.bettype_id == 20
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout md-alignment-center-center",
                                    staticStyle: { "margin-top": "1em" }
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "md-layout-item md-size-100 sub-text text-center"
                                      },
                                      [_vm._v("Combined Scores")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout md-alignment-center-center",
                                        staticStyle: { "margin-top": "-1em" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "md-layout-item md-size-20"
                                          },
                                          [
                                            _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-raised md-primary md-fab md-dense",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.changeOU(0)
                                                  }
                                                }
                                              },
                                              [
                                                _c("md-icon", [
                                                  _vm._v("remove")
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "md-layout-item md-size-60"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "md-layout md-alignment-center-center"
                                              },
                                              [
                                                _c(
                                                  "md-field",
                                                  {
                                                    staticClass:
                                                      "md-layout-item md-xsmall-size-30 md-small-size-20 md-size-15"
                                                  },
                                                  [
                                                    _c("md-input", {
                                                      staticClass:
                                                        "input-field",
                                                      attrs: {
                                                        name: "overUnderLine"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.offerDetails
                                                            .overUnderLine,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.offerDetails,
                                                            "overUnderLine",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "offerDetails.overUnderLine"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "md-layout-item md-size-20"
                                          },
                                          [
                                            _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-raised md-primary md-fab md-dense",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.changeOU(1)
                                                  }
                                                }
                                              },
                                              [_c("md-icon", [_vm._v("add")])],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.offerDetails.bettype_id == 30
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout md-alignment-center-center",
                                    staticStyle: { "margin-top": "1em" }
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "md-layout-item md-size-100 sub-text text-center"
                                      },
                                      [_vm._v("Team Line")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout md-alignment-center-center",
                                        staticStyle: { "margin-top": "-1em" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "md-layout-item md-size-20"
                                          },
                                          [
                                            _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-raised md-primary md-fab md-dense negative-button",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.changeLine(-1)
                                                  }
                                                }
                                              },
                                              [
                                                _c("md-icon", [
                                                  _vm._v("remove")
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "md-layout-item md-size-60"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "md-layout md-alignment-center-center"
                                              },
                                              [
                                                _c(
                                                  "md-field",
                                                  {
                                                    staticClass:
                                                      "md-layout-item md-xsmall-size-30 md-small-size-20 md-size-15"
                                                  },
                                                  [
                                                    _vm.offerDetails.line > 0 &&
                                                    !_vm.offerDetails.pk
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "md-prefix"
                                                          },
                                                          [_vm._v("+")]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.offerDetails.pk
                                                      ? _c("md-input", {
                                                          staticClass:
                                                            "input-field",
                                                          attrs: {
                                                            name: "line"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.offerDetails
                                                                .line,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.offerDetails,
                                                                "line",
                                                                _vm._n($$v)
                                                              )
                                                            },
                                                            expression:
                                                              "offerDetails.line"
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _vm.offerDetails.pk
                                                      ? _c("md-input", {
                                                          staticClass:
                                                            "input-field",
                                                          attrs: {
                                                            name: "line",
                                                            disabled: ""
                                                          },
                                                          model: {
                                                            value: _vm.pkString,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.pkString = $$v
                                                            },
                                                            expression:
                                                              "pkString"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "md-layout-item md-size-20"
                                          },
                                          [
                                            _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-raised md-primary md-fab md-dense positive-button",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.changeLine(1)
                                                  }
                                                }
                                              },
                                              [_c("md-icon", [_vm._v("add")])],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.offerDetails.bettype_id == 30
                              ? _c(
                                  "div",
                                  { staticClass: "md-layout" },
                                  [
                                    _c(
                                      "md-checkbox",
                                      {
                                        staticClass:
                                          "md-primary md-layout-item md-size-100",
                                        model: {
                                          value: _vm.offerDetails.pk,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.offerDetails,
                                              "pk",
                                              $$v
                                            )
                                          },
                                          expression: "offerDetails.pk"
                                        }
                                      },
                                      [_vm._v("Winner Only")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.offerDetails.bettype_id != 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout md-alignment-center-center",
                                    staticStyle: { "margin-top": "1em" }
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "md-layout-item md-size-100 sub-text text-center"
                                      },
                                      [_vm._v("Wager Amount")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "md-layout md-alignment-center-center",
                                        staticStyle: { "margin-top": "-1em" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "md-layout-item md-size-20"
                                          },
                                          [
                                            _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-raised md-primary md-fab md-dense",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.changeWager(0)
                                                  }
                                                }
                                              },
                                              [
                                                _c("md-icon", [
                                                  _vm._v("remove")
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "md-layout-item md-size-60"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "md-layout md-alignment-center-center"
                                              },
                                              [
                                                _c(
                                                  "md-field",
                                                  {
                                                    staticClass:
                                                      "md-layout-item md-xsmall-size-30 md-small-size-20 md-size-15"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "md-prefix"
                                                      },
                                                      [_vm._v("$")]
                                                    ),
                                                    _c("md-input", {
                                                      staticClass:
                                                        "input-field",
                                                      attrs: { name: "wager" },
                                                      model: {
                                                        value:
                                                          _vm.offerDetails
                                                            .wager,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.offerDetails,
                                                            "wager",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "offerDetails.wager"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "md-layout-item md-size-20"
                                          },
                                          [
                                            _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-raised md-primary md-fab md-dense",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.changeWager(1)
                                                  }
                                                }
                                              },
                                              [_c("md-icon", [_vm._v("add")])],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.offerDetails.bettype_id != 0
                              ? _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-accent md-raised float-right",
                                    attrs: { disabled: _vm.isBetErrors },
                                    on: { click: _vm.confirm }
                                  },
                                  [_vm._v("Next")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                ),
                _c(
                  "md-step",
                  {
                    attrs: {
                      id: "visibility",
                      "md-label": "Set visibility",
                      "md-editable": false,
                      "md-done": _vm.visibilitySet
                    },
                    on: {
                      "update:mdDone": function($event) {
                        _vm.visibilitySet = $event
                      },
                      "update:md-done": function($event) {
                        _vm.visibilitySet = $event
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "md-layout" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "md-layout-item md-size-100 sub-text"
                          },
                          [_vm._v("Who is this offer for?")]
                        ),
                        _c(
                          "md-radio",
                          {
                            attrs: { value: 0 },
                            model: {
                              value: _vm.offerDetails.visibility,
                              callback: function($$v) {
                                _vm.$set(_vm.offerDetails, "visibility", $$v)
                              },
                              expression: "offerDetails.visibility"
                            }
                          },
                          [_vm._v("Anyone")]
                        ),
                        _vm.groups.length > 0
                          ? _c(
                              "md-radio",
                              {
                                attrs: { value: 2 },
                                model: {
                                  value: _vm.offerDetails.visibility,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.offerDetails,
                                      "visibility",
                                      $$v
                                    )
                                  },
                                  expression: "offerDetails.visibility"
                                }
                              },
                              [_vm._v("A Group")]
                            )
                          : _vm._e(),
                        _vm.offerDetails.visibility === 2
                          ? _c(
                              "md-field",
                              [
                                _c("label", [_vm._v("Groups")]),
                                _c(
                                  "md-select",
                                  {
                                    on: { "md-selected": _vm.setgroup },
                                    model: {
                                      value: _vm.groupid,
                                      callback: function($$v) {
                                        _vm.groupid = $$v
                                      },
                                      expression: "groupid"
                                    }
                                  },
                                  _vm._l(_vm.groups, function(group) {
                                    return _c(
                                      "md-option",
                                      {
                                        key: group.id,
                                        attrs: { value: group.id }
                                      },
                                      [_vm._v(_vm._s(group.name))]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "md-layout" },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-accent md-raised",
                            attrs: { disabled: _vm.isVisibilityErrors },
                            on: { click: _vm.confirm }
                          },
                          [_vm._v("Next")]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "md-step",
                  {
                    attrs: {
                      id: "confirm",
                      "md-label": "Confirmation",
                      "md-editable": false
                    }
                  },
                  [
                    _vm.chosenGame !== null
                      ? _c("div", { staticClass: "md-layout" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout md-layout-item md-size-100 row"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "md-layout-item md-size-100 sub-text"
                                },
                                [_vm._v("Game")]
                              ),
                              _c(
                                "div",
                                { staticClass: "md-layout-item md-size-100" },
                                [
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        "bold-text":
                                          _vm.offerDetails.teamChoice ==
                                          _vm.chosenGame.awayteam_id
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.chosenGame.awayteam))]
                                  ),
                                  _vm._v(" vs. "),
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        "bold-text":
                                          _vm.offerDetails.teamChoice ==
                                          _vm.chosenGame.hometeam_id
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.chosenGame.hometeam))]
                                  ),
                                  _c("span", { staticClass: "float-right" }, [
                                    _vm._v(_vm._s(_vm.chosenGame.date))
                                  ])
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout md-layout-item md-size-100 row md-alignment-top-space-between"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "md-layout-item md-size-100 sub-text"
                                },
                                [_vm._v("Offer Details")]
                              ),
                              _c(
                                "div",
                                { staticClass: "md-layout-item md-size-10" },
                                [_vm._v("$" + _vm._s(_vm.offerDetails.wager))]
                              ),
                              _vm.offerDetails.bettype_id != 20
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "md-layout-item md-size-30"
                                    },
                                    [_vm._v(_vm._s(_vm.chosenTeam))]
                                  )
                                : _vm._e(),
                              _vm.offerDetails.bettype_id != 20
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "md-layout-item md-size-20",
                                      class: [
                                        _vm.lineColor(_vm.offerDetails.line)
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("offerLine")(
                                            _vm.offerDetails.line
                                          )
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.offerDetails.bettype_id == 20
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "md-layout-item md-size-66"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.lineColor(
                                              _vm.offerDetails.overUnderLine *
                                                (_vm.offerDetails.overUnder == 0
                                                  ? -1
                                                  : 1)
                                            )
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.ouSymbol) +
                                              " " +
                                              _vm._s(
                                                _vm.offerDetails.overUnderLine
                                              )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout md-layout-item md-size-100 row"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "md-layout-item md-size-100 sub-text"
                                },
                                [_vm._v("Visibility")]
                              ),
                              _vm.offerDetails.visibility === 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "md-layout-item md-size-100"
                                    },
                                    [_vm._v("Anyone")]
                                  )
                                : _vm._e(),
                              _vm.offerDetails.visibility === 2
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "md-layout-item md-size-100"
                                    },
                                    [
                                      _c("span", { staticClass: "sub-text" }, [
                                        _vm._v("Group:")
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.offerDetails.group.name) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ],
              1
            ),
            _c(
              "md-dialog-actions",
              [
                _vm.active !== "game"
                  ? _c(
                      "md-button",
                      {
                        staticClass: "md-raised negative-button",
                        on: { click: _vm.goBack }
                      },
                      [_vm._v("Go Back")]
                    )
                  : _vm._e(),
                _c(
                  "md-button",
                  {
                    staticClass: "md-primary md-raised",
                    attrs: { href: "/dashboard" }
                  },
                  [_vm._v("Cancel")]
                ),
                _vm.active === "confirm"
                  ? _c(
                      "md-button",
                      {
                        staticClass: "md-accent md-raised",
                        on: { click: _vm.confirm }
                      },
                      [_vm._v("Confirm")]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.showSimilar
      ? _c(
          "div",
          [
            _vm._m(0),
            _c(
              "div",
              [
                _c("offer-list", {
                  attrs: { offers: _vm.similarWager },
                  on: {
                    update: function($event) {
                      return _vm.acceptOffer()
                    },
                    acceptOffer: function($event) {
                      return _vm.acceptedOffer()
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _c("offer-list", {
                  attrs: { offers: _vm.similarLine },
                  on: {
                    update: function($event) {
                      return _vm.acceptOffer()
                    },
                    acceptOffer: function($event) {
                      return _vm.acceptedOffer()
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "md-dialog-actions",
              [
                _c(
                  "md-button",
                  {
                    staticClass: "md-primary md-raised",
                    on: {
                      click: function($event) {
                        _vm.showSimilar = false
                      }
                    }
                  },
                  [_vm._v("No Thanks")]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("h3", [_vm._v("Here are some similar offers")]),
      _c("p", [
        _vm._v(
          "Instead of creating an offer, you can also take a similar one listed here"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }