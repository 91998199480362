<template>
  <div>
    <v-row align="center" justify="center" class="my-0 py-0">
      <v-col class="py-0 text-center">
        <v-btn @click="changePageTo(page - 1)"
          :disabled="page == 1">PREV</v-btn>
        <span class="robo mx-3">Page: {{ page }}/{{ totalPages }}</span>
        <v-btn @click="changePageTo(nextPage)"
          :disabled="nextPage == page">NEXT</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    page: null,
    nextPage: null,
    totalPages: null,
    topScroll: {
      type: [String],
      default: 'topEl'
    }
  },
  methods: {
    changePageTo (page) {
      document.getElementById(this.topScroll).scrollIntoView()
      this.$emit('updatePage', page)
    }
  }
}
</script>
