<template>
  <div @click="goBack">
    <v-row no-gutters align="center" justify="center" class="my-0 py-0">
      <v-col class="pa-0">
        <v-btn
          fab
          small
          v-if="show"
          color="black"
          class="white--text pull-right"
        >
          <v-icon>keyboard_backspace</v-icon>
        </v-btn>
      </v-col>
      <v-col class="py-0 ml-2" v-if="text != ''">
        <h3 class="black--text">{{ text }}</h3>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => {
    return {

    }
  },
  props: {
    emit: {
      type: String,
      default: 'default'
    },
    text: {
      type: String,
      default: ''
    }
  },
  methods: {
    goBack: function () {
      if (this.emit === 'default') {
        this.$router.go(-1)
      } else {
        this.$emit(this.emit)
      }
    }
  },
  computed: {
    show: function () {
      // return navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('iPad') !== -1
      return true
    }
  }
}
</script>
