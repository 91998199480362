<style scoped></style>
<template>
  <div class="wrapper fadeInDown">
    <div id="verify-form">
      <!-- Verification Form -->
      <form
        class="form"
        @submit.prevent="verify()"
      >
        <v-card>
          <v-card-title>
            <div>Phone Verification</div>
          </v-card-title>
          <v-card-text>
            <v-row class="my-0">
              <v-col
                cols="12"
                class="py-0"
              >
                <v-text-field
                  v-model.trim="form.activationcode"
                  label="Activation Code"
                  required
                  minlength="6"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  autocomplete="one-time-code"
                  ref="code"
                  @keydown="filterKey"
                  :disabled="sending"
                  :loading="sending"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn
              @click="resend()"
              :disabled="sending"
            >Resend</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              :disabled="sending"
              color="primary"
            >Submit</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/UserService'

export default {
  name: 'VerifyForm',
  data: () => {
    return {
      form: {
        activationcode: ''
      },
      user: null,
      sending: false
    }
  },
  beforeCreate: function () {
    if (UserService.activated()) {
      this.$router.push('/dashboard')
    }
  },
  mounted: () => {
    this.$refs.code.focus()
  },
  computed: {
  },
  methods: {
    filterKey (e) {
      const allowedKeys = ['Backspace', 'Delete', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown']
      const regex = /\d+/
      if (allowedKeys.indexOf(e.key) < 0 && !regex.test(e.key)) {
        return e.preventDefault()
      }
    },

    async resend () {
      this.$updateloading(1)
      this.sending = true
      this.form.activationcode = ''
      UserService.resendActivationCode().then(
        (response) => {
          if (response && response.data && response.data.result) {
            this.$notify({
              group: 'notices',
              title: response.data.message,
              text: 'A new activation code was created and will be sent shortly!',
              type: 'info'
            })
          } else {
            this.$notify({
              group: 'errors',
              title: response.data.message,
              text: 'We failed to generate a new activation code! Please try again later.',
              type: 'warn'
            })
          }
          this.sending = false
          this.$updateloading(-1)
        },
        (error) => {
          console.dir(error)
          this.$updateloading(-1)
          this.sending = false
          this.$notify({
            group: 'errors',
            title: 'Activation Code Error',
            text: 'We failed to generate a new activation code! Please try again later.',
            type: 'warn'
          })
        }
      )
    },

    async verify () {
      this.$updateloading(1)
      this.sending = true
      UserService.verifyActivationCode(this.form.activationcode).then(
        async (response) => {
          if (response && response.data && response.data.result) {
            this.$updateloading(-1)
            const authorized = await UserService.refreshAuthData()
            if (authorized) {
              this.sending = false
              this.$router.push(response.data.redirect, () => {
                this.$notify({
                  group: 'notices',
                  title: 'Account Activated',
                  text: 'Your account has been activated.',
                  type: 'info'
                })
              })
            }
          } else {
            this.$notify({
              group: 'errors',
              title: 'Activation Failed',
              text: response.data.error,
              type: 'warn'
            })
            this.$updateloading(-1)
            this.sending = false
          }
        },
        (error) => {
          this.$updateloading(-1)
          this.sending = false
          this.form.activationcode = ''
          this.$notify({
            group: 'errors',
            title: 'Activation Failed',
            text: (error.response.data.errors.length > 0) ? error.response.data.errors[0] : error.response.data.error,
            type: 'warn'
          })
        }
      )
    }

  },
  created: function () {
    // reset any loading stuff in case we were
    // redirected from another module and auth expired
    this.$updateloading(-999)
  }
}
</script>
