var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", [
    _c("path", {
      attrs: {
        d:
          "M258.42,247.65a3.9,3.9,0,0,1-1,2.65,1.7,1.7,0,0,1-.12-.32,7.38,7.38,0,0,0-1.85-4.39,3.84,3.84,0,0,1-1-2.58c0-1.11.14-2.22.21-3.28a10,10,0,0,0-2.7,3.62,5.74,5.74,0,0,0-.14,1.81c0,.76.12,1.52.18,2.28l-.17,0-.68-.64.27,1.58c-.66-.83-1.2-1.64-1.8-2.41a4.32,4.32,0,0,0-.89-.67,4.91,4.91,0,0,1,.39,4.21,16.61,16.61,0,0,0-.47,1.71,5,5,0,0,0,3.56,6.19,5.32,5.32,0,0,0,6.8-3.11C259.84,252,259.26,249.82,258.42,247.65Zm-2.25,8.71a2.89,2.89,0,0,1-3.48.71,2.59,2.59,0,0,1-1.43-3.13c.07-.26.15-.53.23-.79a2.53,2.53,0,0,0-.15-2.05v0l0,0,1.24,1.17,0-.4h0l0,0v0l.38.16a3.4,3.4,0,0,1,1.35-3.88,8.86,8.86,0,0,0-.1,1.42,2.85,2.85,0,0,0,.33,1.3,5.07,5.07,0,0,1,1.12,2.52l.19,0,.36-1A3.8,3.8,0,0,1,256.17,256.36Z",
        transform: "translate(-248.52 -239.73)"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }