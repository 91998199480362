var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    _vm._l(_vm.games, function(game) {
      return _c(
        "v-card",
        { key: game.id, staticClass: "mb-4" },
        [
          _c(
            "v-card-title",
            { staticStyle: { "font-size": "100%" } },
            [
              _c(
                "v-row",
                { staticClass: "my-0" },
                [
                  _c("v-col", { staticClass: "py-0" }, [
                    _vm._v(
                      _vm._s(game.awayteam) + " @ " + _vm._s(game.hometeam)
                    )
                  ]),
                  _c("v-col", { staticClass: "py-0" }, [
                    _c("span", { staticClass: "subtext pull-right" }, [
                      _vm._v(
                        _vm._s(game.gamedate_formatted) +
                          " " +
                          _vm._s(game.starttime_formatted)
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-text", [_c("BoxScore", { attrs: { game: game } })], 1)
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }