<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <button
          class="vigzero-btn schedule-btn"
          v-bind="attrs"
          v-on="on"
        >
          <div class="">
            {{ invite.name }}<br />
            <span class="sub-text">Owner - {{ invite.owner }}</span>
          </div>
        </button>
      </template>
      <v-card>
        <v-card-text>
          <v-row
            align="center"
            class="my-0 py-0"
          >
            <v-col class="black--text pb-0">You have been invited by {{ invite.ownername }} ({{
                invite.owner
              }}) to join <b>{{ invite.name }}</b>. Would you like to join?
            </v-col>
          </v-row>
        </v-card-text>
        <v-row class="my-0 py-0">
          <v-col cols="12">
            <v-card-actions>
              <v-row
                justify="center"
                style="width:100%"
              >
                <v-btn
                  class="black white--text mx-1"
                  @click="cancel()"
                >Cancel</v-btn>
                <v-btn
                  class="background-red white--text mx-1"
                  @click="respond('decline')"
                >Decline</v-btn>
                <v-btn
                  class="primary mx-1"
                  @click="respond('accept')"
                >Accept</v-btn>
              </v-row>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CommunityService from '@/services/CommunityService'
export default {
  props: {
    invite: Object
  },
  data: function () {
    return {
      dialog: false
    }
  },
  methods: {
    cancel: function () {
      this.dialog = false
    },
    respond: function (type) {
      var data = {
        action: type,
        id: this.invite.id
      }
      CommunityService.invitation(data).then(
        (response) => {
          this.dialog = false
          // on successful user joins groups
          this.$gtag.event('join', {
            event_category: 'group',
            event_label: 'joined',
            value: data
          })

          this.$emit('update')
        }
      )
    }
  }
}
</script>
