var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mx-0 px-1" },
    [
      _vm.dispute_id && _vm.isadmin
        ? _c("DisputeAdmin", { attrs: { dispute_id: _vm.dispute_id } })
        : _vm._e(),
      _vm.dispute_id && !_vm.isadmin
        ? _c("DisputeUpdate", { attrs: { dispute_id: _vm.dispute_id } })
        : _vm._e(),
      _vm.wager_id
        ? _c("DisputeCreate", { attrs: { wager_id: _vm.wager_id } })
        : _vm._e(),
      !_vm.dispute_id && !_vm.wager_id ? _c("DisputeSearch") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }