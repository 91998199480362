var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          staticClass: "my-0 py-0",
          attrs: { align: "center", justify: "center" }
        },
        [
          _c(
            "v-col",
            { staticClass: "py-0 text-center" },
            [
              _c(
                "v-btn",
                {
                  attrs: { disabled: _vm.page == 1 },
                  on: {
                    click: function($event) {
                      return _vm.changePageTo(_vm.page - 1)
                    }
                  }
                },
                [_vm._v("PREV")]
              ),
              _c("span", { staticClass: "robo mx-3" }, [
                _vm._v(
                  "Page: " + _vm._s(_vm.page) + "/" + _vm._s(_vm.totalPages)
                )
              ]),
              _c(
                "v-btn",
                {
                  attrs: { disabled: _vm.nextPage == _vm.page },
                  on: {
                    click: function($event) {
                      return _vm.changePageTo(_vm.nextPage)
                    }
                  }
                },
                [_vm._v("NEXT")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }