<template>
  <v-dialog v-model="dialog" max-width="320px" persistent class="custom-dialog">
    <v-card>
      <v-card-title class="headline">Intended Deposit</v-card-title>
      <v-card-text>
        Please enter the amount you will send @VigZero via Venmo:
        <v-text-field
          v-model="displayAmount"
          label="Enter Amount"
          prepend-icon="attach_money"
          @input="formatAmount"
          class="mt-4"
          :rules="[validateAmount && validateMoney]"
          @blur="fixCents"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="submit">Submit</v-btn>
        <v-btn color="red darken-1" text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean
  },
  data () {
    return {
      amount: null,
      displayAmount: '0.00'
    }
  },
  computed: {
    maxDialogWidth () {
      // Adjust these values based on your design requirements
      if (window.innerWidth < 600) {
        return '50%'
      } else {
        return '300px'
      }
    }
  },
  methods: {
    submit () {
      if (this.amount < 5) {
        // Invalid amount, do not submit or close the dialog
        alert('The minimum deposit amount is $5.')
        return
      }
      this.$emit('submit', this.amount)
      this.amount = null
      this.displayAmount = '0.00'
      this.close()
    },
    close () {
      this.displayAmount = '0.00'
      this.amount = null
      this.$emit('close')
    },
    formatAmount () {
      // Remove non-numeric characters except period
      let numericValue = this.displayAmount.replace(/[^0-9.]/g, '')

      // Remove extra periods if any
      const parts = numericValue.split('.')
      if (parts.length > 2) {
        numericValue = parts[0] + '.' + parts.slice(1).join('')
      }

      // Update the display amount
      this.displayAmount = numericValue

      // Update the internal amount
      this.amount = parseFloat(numericValue).toFixed(2)
      // this.amount = parseFloat(numericValue) || 0
      // this.displayAmount = this.amount.toFixed(2)
    },
    fixCents () {
      if (!this.displayAmount.includes('.')) {
        this.displayAmount += '.00'
      } else {
        const parts = this.displayAmount.split('.')
        if (parts[1].length === 0) {
          this.displayAmount += '00'
        } else if (parts[1].length === 1) {
          this.displayAmount += '0'
        }
      }
    },
    validateAmount (value) {
      return /^[0-9]+(\.[0-9]{1,2})?$/.test(value) || 'Invalid amount'
    },
    validateMoney (value) {
      return parseFloat(value) >= 5 || '$5 minimum deposit'
    }
  }
}
</script>
<style scoped>
.headline {
  font-size: 1.5rem;
  font-weight: bold;
}
/* Hide the up/down arrows */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
@media (max-width: 600px) {
  .custom-dialog .v-dialog__content {
    align-items: flex-start !important; /* Align dialog to the top */
    justify-content: flex-start !important; /* Align dialog to the left */
  }

  .custom-dialog .v-dialog {
    margin-left: 0 !important; /* Align the dialog to the left side of the screen */
  }
}
</style>
