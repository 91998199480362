<template>
  <div v-if="games" class="game-list">
    <div class="pt-4">
      <div v-if="games.length === 0">
        <h3 class="text-center">No games found</h3>
      </div>
      <div
        class="vig-card"
        :class="game.favorite ? 'game-favorite' : ''"
        v-for="game in games"
        :key="game.id"
        :id="'gamescroller_' + game.id"
      >
        <div @click="game.expand = !game.expand">
          <GameSummary :game="game"></GameSummary>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameSummary from '@/components/games/Summary'

export default {
  name: 'GameList',
  props: {
    games: Array
  },
  components: {
    GameSummary
  },
  methods: {
    cancelled: function (game) {
      return game.starttime === game.endtime
    },
    offersavailable: function (game) {
      return (game.wagers_home_line + game.wagers_home_ou + game.wagers_away_line + game.wagers_away_ou > 0)
    }
  }
}
</script>
