var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "about" },
    [
      _c(
        "v-row",
        { staticClass: "mt-0" },
        [
          _c(
            "v-col",
            { staticClass: "mt-0 pt-0", attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-0 px-4 pt-4",
                      attrs: { justify: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0 text-center",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "max-width": "25%",
                              "border-radius": "0",
                              height: "auto"
                            },
                            attrs: {
                              src: require("../assets/black_red_icon/512.png")
                            }
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _c(
                      "span",
                      {
                        staticClass: "robo",
                        staticStyle: { "font-size": "1.8em" }
                      },
                      [_vm._v("Notes")]
                    )
                  ]),
                  _c(
                    "v-card-text",
                    _vm._l(_vm.notes, function(n, i) {
                      return _c(
                        "v-row",
                        { key: i, staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 px-0 black--text",
                              attrs: { cols: "12" },
                              on: {
                                click: function($event) {
                                  return _vm.clickexpander(i)
                                }
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "1" } }, [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.expanded == i
                                                  ? "remove_circle_outline"
                                                  : "add_circle_outline"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", [
                                    _vm._v(" " + _vm._s(n.headline) + " ")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.expanded == i,
                                          expression: "expanded == i"
                                        }
                                      ],
                                      staticClass: "mx-auto pa-3",
                                      attrs: { block: "" },
                                      domProps: { innerHTML: _vm._s(n.message) }
                                    },
                                    [_vm._v(" " + _vm._s(n.message) + " ")]
                                  )
                                ],
                                1
                              ),
                              _c("v-divider")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("back", {
                                attrs: { text: "Back", emit: "back" },
                                on: { back: _vm.tofaq }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }