var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.compact && _vm.game.scoreboard && _vm.game.scoreboard.box
        ? _c(
            "v-row",
            {
              staticClass: "py-0 my-0 game-score-box",
              attrs: { justify: "space-around" }
            },
            [
              _c("v-col", { staticClass: "py-0 text-center" }, [
                _vm.type === "line"
                  ? _c("div", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.game.awayabbr) +
                            " " +
                            _vm._s(_vm.scoreAway)
                        )
                      ])
                    ])
                  : _c("div", [
                      _c("strong", [
                        _vm.type === "UNDER"
                          ? _c("span", [_vm._v(_vm._s(_vm.type))])
                          : _c("span", [
                              _vm._v("Total: " + _vm._s(_vm.scoreTotal))
                            ])
                      ])
                    ])
              ]),
              _c("v-col", { staticClass: "py-0 text-center" }, [
                _vm.type === "line"
                  ? _c("div", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.game.homeabbr) +
                            " " +
                            _vm._s(_vm.scoreHome)
                        )
                      ])
                    ])
                  : _c("div", [
                      _c("strong", [
                        _vm.type === "OVER"
                          ? _c("span", [_vm._v(_vm._s(_vm.type))])
                          : _c("span", [
                              _vm._v("Total: " + _vm._s(_vm.scoreTotal))
                            ])
                      ])
                    ])
              ]),
              _c("v-col", { staticClass: "py-0 text-center" }, [
                _c("strong", [
                  _vm.game.hometeamscore
                    ? _c("span", [
                        _c(
                          "span",
                          {
                            class:
                              _vm.result &&
                              _vm.offerstatus &&
                              _vm.offerstatus !== "WIN" &&
                                _vm.offerstatus !== "LOSS" &&
                                _vm.game.takenby_id
                                ? _vm.result
                                : ""
                          },
                          [
                            _vm._v(" Final"),
                            _vm.result &&
                            _vm.offerstatus &&
                            _vm.offerstatus !== "WIN" &&
                              _vm.offerstatus !== "LOSS" &&
                              _vm.game.takenby_id
                              ? _c("span", [
                                  _vm._v(
                                    " - " +
                                      _vm._s(
                                        _vm.result === "winner" ? "WIN" : "LOSS"
                                      )
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ])
                    : _vm._e(),
                  !_vm.game.hometeamscore && _vm.game.scoreboard
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.game.periodname.charAt(0).toUpperCase()
                            ) +
                            _vm._s(
                              _vm.game.scoreboard.period
                                ? _vm.game.scoreboard.period
                                : _vm.game.scoreboard.box.home.length
                            ) +
                            " " +
                            _vm._s(
                              _vm.game.scoreboard.time
                                ? _vm.game.scoreboard.time
                                : _vm.game.sport === "Baseball"
                                ? _vm.game.scoreboard.box.home.length ==
                                  _vm.game.scoreboard.box.away.length
                                  ? "Bot"
                                  : "Top"
                                : "0:00"
                            ) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  !_vm.game.hometeamscore && !_vm.game.scoreboard
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.game.periodname.charAt(0).toUpperCase()) +
                            "1"
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      !_vm.compact && _vm.game.scoreboard && _vm.game.scoreboard.box
        ? _c(
            "v-row",
            { staticClass: "py-0 my-0 game-score-box" },
            [
              _c("v-col", { staticClass: "py-0" }, [
                _c("h3", { staticClass: "text-left" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.game.awayabbr))])
                ]),
                _c("h3", { staticClass: "text-left" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.game.homeabbr))])
                ])
              ]),
              _vm._l(_vm.game.periodsize, function(p) {
                return _c("v-col", { key: p, staticClass: "py-0" }, [
                  _c("h3", [
                    _vm._v(
                      _vm._s(
                        _vm.game.scoreboard.box.away.length >= p
                          ? _vm.game.scoreboard.box.away[p - 1]
                          : "-"
                      )
                    )
                  ]),
                  _c("h3", [
                    _vm._v(
                      _vm._s(
                        _vm.game.scoreboard.box.home.length >= p
                          ? _vm.game.scoreboard.box.home[p - 1]
                          : "-"
                      )
                    )
                  ])
                ])
              }),
              _c(
                "v-col",
                {
                  staticClass: "py-0",
                  staticStyle: { "border-left": "1px solid #dedede" }
                },
                [
                  _c("h3", [_c("strong", [_vm._v(_vm._s(_vm.scoreAway))])]),
                  _c("h3", [_c("strong", [_vm._v(_vm._s(_vm.scoreHome))])])
                ]
              ),
              _c("v-col", { staticClass: "py-0 text-center" }, [
                _vm.game.scoreboard.period
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.withOrdinalSuffix(_vm.game.scoreboard.period)
                          )
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.game.scoreboard.time
                              ? _vm.game.scoreboard.time
                              : _vm.game.sport === "Baseball"
                              ? _vm.game.scoreboard.box.home.length ==
                                _vm.game.scoreboard.box.away.length
                                ? "Bot"
                                : "Top"
                              : "0:00"
                          ) +
                          " "
                      )
                    ])
                  : _c("span", { staticStyle: { "line-height": "3em" } }, [
                      _vm._v(" Final ")
                    ])
              ])
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }