<template>
  <v-card style="border-radius: 10px">
    <v-img
      :src="getLogo()"
      height="130"
    ></v-img>
    <v-container class="pa-0 ma-0">
      <v-row
        class="pr-5"
        no-gutters
      >
        <v-col cols="8">
          <v-card-title class="group-card-title">{{ group.name }}</v-card-title>
        </v-col>
        <v-spacer v-if="group.wagerCount == 0"></v-spacer>
        <v-col
          align="right"
          v-show="group.wagerCount > 0"
        >
          <v-badge
            overlap
            bordered
            :value="group.wagerCount > 0"
            :content="badgeNumber(group.wagerCount)"
            color="red"
          >
            <v-icon
              dense
              color="black"
            >local_atm</v-icon>
          </v-badge>
        </v-col>
        <v-col align="right">
          <v-badge
            overlap
            bordered
            :value="group.messageCount > 0"
            :content="badgeNumber(group.messageCount)"
            color="red"
          >
            <v-icon
              dense
              color="green"
            >sms</v-icon>
          </v-badge>
        </v-col>
        <v-col
          align="right"
          v-show="group.contestCount > 0"
        >
          <v-icon
            dense
            color="yellow darken-2"
          >mdi-trophy</v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style scoped>
.group-card-title {
  font-size: 18px;
  padding: 0 0 0 15px;
  white-space: nowrap;
}
</style>

<script>
import logo from '@/assets/VZ-logo.png'

export default {
  props: {
    group: {
      type: Object,
      default: null
    }
  },
  data: () => {
    return {
      avatar: null
    }
  },
  methods: {
    getLogo: function () {
      if (this.group.avatar && this.group.avatar.thumbnails && this.group.avatar.thumbnails.large) {
        return this.group.avatar.thumbnails.large
      }
      return logo
    },
    badgeNumber: function (val) {
      if (+val > 99) {
        return '99+'
      } else {
        return '' + val
      }
    }
  }
}
</script>
