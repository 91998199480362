const DateService = {
  formatDateForJS: function (datestr) {
    if (datestr) {
      try {
        var arr = datestr.split(/[- :]/)
        var date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
        if (date) {
          return date
        } else {
          return null
        }
      } catch {
        return null
      }
    } else {
      return null
    }
  },
  formatDateForDB: function (dateObj) {
    var dayPre = dateObj.getDate()
    var monthPre = dateObj.getMonth() + 1
    return dateObj.getFullYear() + '-' + (monthPre < 10 ? '0' + monthPre : monthPre) + '-' + (dayPre < 10 ? '0' + dayPre : dayPre)
  }
}

export default DateService
