var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: "",
                fullscreen: "",
                "hide-overlay": "",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.showDialog,
                callback: function($$v) {
                  _vm.showDialog = $$v
                },
                expression: "showDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("div", { staticClass: "mx-auto text-center" }, [
                      _vm._v(" Confirm Payment ")
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    { staticClass: "black--text" },
                    [
                      _c("p", {}, [
                        _vm._v(
                          'Choose "Confirmed" if you got paid for the following bet.'
                        )
                      ]),
                      _c(
                        "v-row",
                        { staticClass: "my-0 py-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("span", { staticClass: "action-section" }, [
                                _vm._v("Bet:")
                              ]),
                              _vm._v(" #" + _vm._s(_vm.wager.id) + " "),
                              _c("span", { staticClass: "winner" }, [
                                _vm._v("WIN")
                              ]),
                              _vm._v(" " + _vm._s(_vm.mySideInfo) + " ")
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("span", { staticClass: "action-section" }, [
                                _vm._v("Amount:")
                              ]),
                              _vm._v(
                                " $" +
                                  _vm._s(_vm.wager.amount) +
                                  " " +
                                  _vm._s(_vm.settletime) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("span", { staticClass: "action-section" }, [
                                _vm._v("Payor:")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.wager.loser_id ===
                                      _vm.wager.offeredby_id
                                      ? _vm.wager.offername
                                      : _vm.wager.takername
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("span", { staticClass: "action-section" }, [
                                _vm._v("Venmo:")
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: {
                                    href: _vm.paymentlink,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.paymenthandle))]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          'Choose "No Payment" if you checked your payment app and did not receive funds for this bet.'
                        )
                      ]),
                      _vm.wager.paymentdenycount > 0
                        ? _c("p", [
                            _vm._v(
                              "If you deny receiving this payment, this bet will be put into dispute and reviewed."
                            )
                          ])
                        : _c("p", [
                            _vm._v(
                              "Please check carefully as indication of no payment may result in the bet being put into dispute."
                            )
                          ])
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text small",
                          attrs: { color: "red darken-1" },
                          on: { click: _vm.deny }
                        },
                        [_vm._v("No Payment")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text small",
                          attrs: { color: "black" },
                          on: { click: _vm.cancel }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text small",
                          attrs: { color: "light-green darken-2" },
                          on: { click: _vm.agree }
                        },
                        [_vm._v("Confirmed")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }