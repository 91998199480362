var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mx-0 px-1" },
    [
      _c(
        "v-row",
        { staticClass: "my-0 py-0" },
        [
          _vm.showCreate && _vm.game
            ? _c(
                "v-col",
                { staticClass: "py-0 container", attrs: { cols: "12" } },
                [
                  _c("WagerCreate", {
                    attrs: {
                      game: _vm.game,
                      team_id: _vm.team_id,
                      type: _vm.type,
                      betlimit: _vm.betlimit
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0 py-0" },
        [
          !_vm.showCreate && _vm.game && _vm.wagers
            ? _c(
                "v-col",
                { staticClass: "py-0" },
                [
                  _vm.showHeading
                    ? _c(
                        "v-row",
                        { staticClass: "mt-0 mb-3 py-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 px-1",
                              class: _vm.wagerIsValid
                                ? "text-right"
                                : "text-center",
                              attrs: { "align-self": "center" }
                            },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.showHeading && _vm.hasPaymentMethod,
                                      expression:
                                        "showHeading && hasPaymentMethod"
                                    }
                                  ],
                                  staticClass: "selectbet robo"
                                },
                                [
                                  _vm._v("Select Bet(s)"),
                                  _vm.wagerIsValid
                                    ? _c("span", [_vm._v(" or")])
                                    : _vm._e()
                                ]
                              )
                            ]
                          ),
                          _vm.wagerIsValid
                            ? _c("v-col", { staticClass: "py-0" }, [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showHeading,
                                        expression: "showHeading"
                                      }
                                    ],
                                    staticClass: "vigzero-btn blue-btn robo",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openCreate($event)
                                      }
                                    }
                                  },
                                  [_vm._v("Create New Bet")]
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("WagerList", {
                    attrs: {
                      wagers: _vm.wagers.results,
                      game: _vm.game,
                      team_id: _vm.team_id,
                      type: _vm.type,
                      betlimit: _vm.betlimit,
                      section: _vm.section
                    },
                    on: {
                      "wager-selected": _vm.updateSelected,
                      "wager-reload": _vm.reloadWagers
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.game
        ? _c(
            "v-row",
            {
              staticClass: "my-0 pb-0 pt-4",
              attrs: { justify: "space-around" }
            },
            [
              _c(
                "v-col",
                { staticClass: "py-0 mx-auto", attrs: { cols: "4" } },
                [
                  _c("back", {
                    attrs: { emit: "back", text: "Back" },
                    on: { back: _vm.goBack }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }