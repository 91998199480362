var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact" },
    [
      _c(
        "v-form",
        {
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-row",
            { staticClass: "px-4" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { staticClass: "px-4" },
                    [
                      _c("v-card-title", [_vm._v("Contact Us")]),
                      _c(
                        "v-card-text",
                        [
                          _c("v-row", { staticClass: "px-4" }, [
                            _vm.loggedin && _vm.usernickname
                              ? _c("div", [
                                  _vm._v(
                                    " Let us know if you need assistance, have questions or have ideas on how to make VigZero better. "
                                  )
                                ])
                              : _vm._e()
                          ]),
                          !_vm.loggedin
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "First Name*",
                                          required: "",
                                          name: "firstname",
                                          id: "firstname",
                                          disabled: _vm.sent,
                                          "error-messages": _vm.getErrors(
                                            "firstname"
                                          )
                                        },
                                        on: {
                                          blur: function($event) {
                                            return _vm.$v.form.firstname.$touch()
                                          }
                                        },
                                        model: {
                                          value: _vm.form.firstname,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "firstname", $$v)
                                          },
                                          expression: "form.firstname"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Last Name*",
                                          name: "lastname",
                                          id: "lastname",
                                          required: "",
                                          disabled: _vm.sent,
                                          "error-messages": _vm.getErrors(
                                            "lastname"
                                          )
                                        },
                                        on: {
                                          blur: function($event) {
                                            return _vm.$v.form.lastname.$touch()
                                          }
                                        },
                                        model: {
                                          value: _vm.form.lastname,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "lastname", $$v)
                                          },
                                          expression: "form.lastname"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Email*",
                                          type: "email",
                                          name: "email",
                                          id: "email",
                                          required: "",
                                          disabled: _vm.sent,
                                          "error-messages": _vm.getErrors(
                                            "email"
                                          )
                                        },
                                        on: {
                                          blur: function($event) {
                                            return _vm.$v.form.email.$touch()
                                          }
                                        },
                                        model: {
                                          value: _vm.form.email,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "email", $$v)
                                          },
                                          expression: "form.email"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Phone*",
                                          name: "phone",
                                          id: "phone",
                                          type: "text",
                                          required: "",
                                          disabled: _vm.sent,
                                          "error-messages": _vm.getErrors(
                                            "phone"
                                          )
                                        },
                                        on: {
                                          blur: function($event) {
                                            return _vm.$v.form.phone.$touch()
                                          }
                                        },
                                        model: {
                                          value: _vm.form.phone,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "phone", $$v)
                                          },
                                          expression: "form.phone"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.loggedin && _vm.wagers && _vm.wagers.length > 0
                            ? _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _vm._v("Is this about a specific wager?")
                                  ]),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: "Related Wager",
                                          items: _vm.wagers,
                                          "item-text": "display",
                                          "item-value": "id",
                                          clearable: "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.form.wager_id,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "wager_id", $$v)
                                          },
                                          expression: "form.wager_id"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            { staticClass: "px-4" },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  label: "Comment*",
                                  "auto-grow": "",
                                  outlined: "",
                                  rows: "1",
                                  "row-height": "15",
                                  "error-messages": _vm.getErrors("comment")
                                },
                                on: {
                                  blur: function($event) {
                                    return _vm.$v.form.comment.$touch()
                                  }
                                },
                                model: {
                                  value: _vm.form.comment,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "comment", $$v)
                                  },
                                  expression: "form.comment"
                                }
                              })
                            ],
                            1
                          ),
                          !_vm.loggedin
                            ? _c(
                                "v-row",
                                { staticClass: "px-4" },
                                [
                                  _c("vue-recaptcha", {
                                    ref: "recaptcha",
                                    attrs: { sitekey: _vm.capkey },
                                    on: { verify: _vm.onVerify }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-row",
                            { staticClass: "px-8", attrs: { justify: "end" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        type: "submit",
                                        disabled: _vm.sent
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.submitform($event)
                                        }
                                      }
                                    },
                                    [_vm._v(" Submit ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }