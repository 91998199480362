<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          <h2 class="ma-0 black--text">Entrants</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="text-left py-3 px-0">
          <v-simple-table
            class="table-striped"
            height="500px"
            fixed-header
            dense
          >
            <template v-slot:default>
              <colgroup>
                <col width="0*">
                <col>
                <col width="0*">
                <col
                  v-if="admin && contest.open"
                  width="0*"
                >
              </colgroup>
              <thead>
                <tr>
                  <th>#</th>
                  <th class="px-0">Nickname</th>
                  <th>Paid</th>
                  <th v-if="admin && contest.open"></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(e, i) in contest.entrants"
                  :key="'q-' + i"
                  :class="(e.user_id === userId) ? 'warning lighten-5' : ''"
                  v-show="entryIsQualified(i)"
                >
                  <td
                    class="px-0 text-center"
                    :class="(entryIsQualified(i)) ? 'entry-in' : 'entry-out'"
                  >{{ i + 1 }}</td>
                  <td class="px-1">
                    <span :class="(e.user_id === userId) ? 'font-weight-bold' : ''">{{ e.nickname }}</span>
                    <v-btn
                      icon
                      v-if="e.user_id === userId && !admin && contest.open"
                      @click="confirmRemoveEntry(e)"
                    >
                      <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                    <span v-if="admin && e.user_id !== userId">
                      <span> ({{ e.firstname }} {{ e.lastname }})</span>
                      <br>
                      <span v-if="e.venmo"> (<a :href="'//venmo.com/u/' + e.venmo">@{{ e.venmo }}</a>)</span>
                      <span v-else> (No Venmo)</span>
                    </span>
                  </td>
                  <td class="px-0 text-center">
                    <v-btn
                      icon
                      v-if="admin"
                      @click="togglePayment(e)"
                    >
                      <v-icon :color="(e.paid) ? 'green accent-4' : 'red lighten-2'">mdi-currency-usd</v-icon>
                    </v-btn>
                    <v-icon
                      v-else
                      :color="(e.paid) ? 'green accent-4' : 'red lighten-2'"
                    >mdi-currency-usd</v-icon>
                  </td>
                  <td
                    v-if="admin && contest.open"
                    class="px-0"
                  >
                    <v-btn
                      icon
                      title="Remove"
                      @click="confirmRemoveEntry(e)"
                    >
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>

                <tr
                  v-if="hasUnqualifiedEntries"
                  class="error lighten-3"
                >
                  <td
                    colspan="4"
                    class="entry-out"
                  >
                    <strong v-if="contest.open">Waiting List: need {{ missingEntriesToQualify }} more for {{ nextThreshold }}</strong>
                    <strong v-else>Did not make the cut</strong>
                  </td>
                </tr>

                <tr
                  v-for="(e, i) in contest.entrants"
                  :key="'uq-' + i"
                  :class="(e.user_id === userId) ? 'warning lighten-5' : ''"
                  v-show="!entryIsQualified(i)"
                >
                  <td
                    class="px-0 text-center"
                    :class="(entryIsQualified(i)) ? 'entry-in' : 'entry-out'"
                  >{{ i + 1 }}</td>
                  <td class="px-1">
                    <span :class="(e.user_id === userId) ? 'font-weight-bold' : ''">{{ e.nickname }}</span>
                    <v-btn
                      icon
                      v-if="e.user_id === userId && !admin && contest.open"
                      @click="confirmRemoveEntry(e)"
                    >
                      <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                    <span v-if="admin && e.user_id !== userId">
                      <span> ({{ e.firstname }} {{ e.lastname }})</span>
                      <br>
                      <span v-if="e.venmo"> (<a :href="'//venmo.com/u/' + e.venmo">@{{ e.venmo }}</a>)</span>
                      <span v-else> (No Venmo)</span>
                    </span>
                  </td>
                  <td class="px-0 text-center">
                    <v-btn
                      icon
                      v-if="admin"
                      @click="togglePayment(e)"
                    >
                      <v-icon :color="(e.paid) ? 'green accent-4' : 'red lighten-2'">mdi-currency-usd</v-icon>
                    </v-btn>
                    <v-icon
                      v-else
                      :color="(e.paid) ? 'green accent-4' : 'red lighten-2'"
                    >mdi-currency-usd</v-icon>
                  </td>
                  <td
                    v-if="admin && contest.open"
                    class="px-0"
                  >
                    <v-btn
                      icon
                      title="Remove"
                      @click="confirmRemoveEntry(e)"
                    >
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>

                <tr v-if="contest.entries == 0">
                  <td
                    colspan="4"
                    class="text-center"
                  >No entries yet!</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-row class="mx-0">
            <v-col class="px-0">
              <strong class="sub-text black--text">{{ contest.entries }}
                <span v-if="contest.minimum > 0 && contest.entries < contest.minimum">of {{ contest.minimum }} required</span>
                <span v-else>
                  <span v-if="contest.maximum > 0">of {{ contest.maximum }}</span>
                </span> spots</strong>
            </v-col>
            <v-col
              class="px-0"
              align="center"
            >
              <strong class="sub-text black--text">Need {{ missingEntriesToQualify }} more for {{ nextThreshold }}</strong>
            </v-col>
            <v-col
              class="px-0"
              align="right"
            >
              <strong class="sub-text black--text">
                <span v-if="contest.maximum > 0">
                  Max Entries: {{ contest.maximum }}
                </span>
              </strong>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <RemoveEntryDialog
      v-if="entry"
      :entry="entry"
      :admin="admin"
      @cancel="entry = null"
      @remove="removeEntry"
    >
    </RemoveEntryDialog>

    <PaymentDialog
      v-if="payment"
      :payment="payment"
      :admin="admin"
      @cancel="payment = null"
      @toggle="updatePayment"
    >
    </PaymentDialog>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import RemoveEntryDialog from '@/components/contests/RemoveEntryDialog'
import PaymentDialog from '@/components/contests/PaymentDialog'

export default {
  name: 'ContestEntries',
  components: {
    RemoveEntryDialog,
    PaymentDialog
  },
  props: {
    contest: Object,
    admin: Boolean
  },

  data () {
    return {
      userId: 0,
      entry: null,
      payment: null
    }
  },

  created () {
    this.init()
  },

  computed: {
    hasUnqualifiedEntries () {
      return (this.contest.entries < this.contest.minimum || this.contest.entries % this.contest.minimum > 0)
    },
    missingEntriesToQualify () {
      return this.nextThreshold - this.contest.entries
      // return this.contest.minimum - (this.contest.entries % this.contest.minimum)
    },
    nextThreshold () {
      var threshold = this.contest.minimum
      while (this.contest.entries > threshold) {
        threshold = threshold * 2
      }
      return threshold
    }
  },

  methods: {
    init () {
      const user = UserService.getUser()
      this.userId = +user.id
    },

    confirmRemoveEntry (entry) {
      // trigger RemoveEntryDialog
      this.entry = entry
    },

    removeEntry () {
      this.entry = null
      this.$emit('refresh')
    },

    togglePayment (entry) {
      // trigger PaymentDialog
      this.payment = entry
    },

    updatePayment () {
      this.payment = null
      this.$emit('refresh')
    },

    entryIsQualified (idx) {
      var qualified = false
      if (this.contest.maximum > 0 && this.contest.entries === this.contest.maximum) {
        // we have reached maximum capacity, everybody is in
        qualified = true
      } else if (this.contest.minimum > 1) {
        // we have a minimum, let's check
        // first off, let's calculate our cutoff assuming we double our cutoff at every threshold
        var cutoff = this.contest.minimum
        while (this.contest.entries > cutoff) {
          const nextlevel = cutoff * 2
          if (this.contest.entries > nextlevel) {
            cutoff = cutoff * 2
          } else {
            break
          }
        }
        if (this.contest.entries >= cutoff) {
          // we have enough entries to qualify
          // console.log('idx is ' + idx)
          // console.log('min is ' + this.contest.minimum)
          if (idx >= cutoff) {
            // console.log('idx is ' + idx)
            // the index is beyond the minimum, adding special logic for madness
            const remainder = this.contest.entries % cutoff
            // console.log('remainder is ' + remainder)
            // console.log('last one in: ' + (this.contest.entries - remainder))
            if (remainder > 0) {
              // we've got some people left out, check our position in line
              if (idx < this.contest.entries - remainder) {
                // you are in a qualifying group
                qualified = true
              } else {
                // these are the odd men out
                qualified = false
              }
            } else {
              // console.log('an exact multiple of the minimum, everybody in')
              qualified = true
            }
          } else {
            // console.log('otherwise, the index is less than minimum so it qualified')
            qualified = true
          }
        } else {
          // we don't have enough entries to start this contest
          qualified = false
        }
      } else {
        // console.log('no minimum or maximum, everybody in')
        qualified = true
      }
      return qualified
    }
  }
}
</script>

<style scoped>
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.entry-in {
  border-left: 2px solid green;
}
.entry-out {
  border-left: 2px solid red;
}
</style>
