<style scoped>
</style>
<template>
  <v-container class="py-0">
    <v-row class="my-0 py-0">
      <v-col
        cols="12"
        class="py-0"
        v-if="disputes && disputes.length == 0"
      >
        <p>No disputes found.</p>
      </v-col>

      <v-col
        cols="12"
        class="pa-0"
        v-if="disputes && disputes.length > 0"
      >
        <v-card
          class="vig-card px-0"
          v-for="dispute in disputes"
          :key="dispute.id"
        >
          <v-card-title class="py-0">
            <v-row
              class="my-0 py-0"
              @click="dispute.expand = !dispute.expand"
            >
              <v-col class="py-0" cols="9">
                <h1 class="text-left">#{{ dispute.id }} - {{ dispute.disputeuser }} vs {{ dispute.againstuser }} -<span> {{ dispute.amount | currency }}</span></h1>
              </v-col>
              <v-col class="py-0 ml-auto">
                <h1 class="text-right">{{ dispute.disputestatus }}</h1>
              </v-col>
            </v-row>

            <v-row class="my-0 py-0">
              <v-col class="py-0" cols="9">
                <h2 class="text-left">{{ dispute.game }}</h2>
              </v-col>
              <v-col class="py-0 ml-auto">
                <h2 class="text-right">{{ dispute.disputetype }}</h2>
              </v-col>
            </v-row>
          </v-card-title>

          <v-expand-transition class="mt-0">
            <div v-show="dispute.expand">
              <v-divider></v-divider>
              <v-card-text class="pb-0 pt-1">
                <v-row class="my-0 py-0">
                  <v-col class="py-0 pr-0" cols="9">
                    <h2 class="text-left">{{ dispute.description }}</h2>
                    <h2 class="text-left">Created: {{ dispute.created }}</h2>
                  </v-col>
                  <v-col class="pa-0">
                    <v-btn
                      :to="{ name: 'disputereview', params: { dispute_id: dispute.id } }"
                      class="text text-right"
                      color="error"
                      dense
                    >Review</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-expand-transition>

          <v-card-actions class="pa-0">
            <div
              class="vig-card-expand-container ma-0"
              @click="dispute.expand = !dispute.expand"
            >
              <v-icon>{{ (dispute.expand) ? 'arrow_drop_up' : 'arrow_drop_down' }}</v-icon>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'DisputeList',
  props: {
    disputes: Array
  },
  methods: {
    showDetail (dispute) {
      dispute.showdetail = !dispute.showdetail
    }
  }
}
</script>
