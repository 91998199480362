<style scoped></style>
<template>
  <div class="wrapper fadeInDown">
    <div id="login-form">
      <!-- Tabs Titles -->

      <!-- Icon -->

      <!-- Login Form -->
      <v-form
        v-model="valid"
        @submit.prevent="login()"
        ref="theLoginForm"
      >
        <v-row>
          <v-col cols="12">
            <v-card class="px-3">
              <v-card-title>Login</v-card-title>
              <v-card-text>
                <v-text-field
                  name="email"
                  id="email"
                  type="email"
                  label="Email"
                  v-model="email"
                  :disabled="sending"
                  required
                ></v-text-field>
                <v-text-field
                  name="password"
                  id="password"
                  label="Password"
                  v-model="password"
                  :disabled="sending"
                  :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showpassword ? 'text' : 'password'"
                  @click:append="showpassword = !showpassword"
                  required
                ></v-text-field>
              </v-card-text>

              <v-card-actions>
                <div>
                  <a
                    class="link"
                    href="/forgotpassword"
                  >Forgot Password</a>
                </div>
                <v-spacer></v-spacer>
                <v-btn
                  class="pull-right"
                  type="submit"
                  elevation="2"
                  raised
                  color="secondary"
                  :disabled="sending || formInvalid()"
                >
                  Login
                </v-btn>
              </v-card-actions>

            </v-card>
          </v-col>
        </v-row>
      </v-form>

      <v-row>
        <v-col align="center">
          <v-btn
            to="/register"
            elevation="2"
            raised
            color="primary"
          >Register Now</v-btn>
          <!-- <v-btn
            @click="siteDisabled"
            elevation="2"
            raised
            color="primary"
          >Register Now</v-btn> -->
        </v-col>
      </v-row>

      <p style="text-align: center; font-size: 0.8em; font-weight: bold;">
        Must be 21+ - Gambling Problem? Help is available from the toll-free nationwide hotline, call: 1-800-GAMBLER,
        chat: <a href="https://www.ncpgambling.org/help-treatment/chat/">https://www.ncpgambling.org/help-treatment/chat/</a>,
        or visit <a href="https://www.ncpgambling.org/help-treatment/">https://www.ncpgambling.org/help-treatment/</a>
      </p>

      <br />
      <!-- Remind Passowrd -->
      <div>
        <a
          class="underlineHover"
          @click="installer()"
          :style="{'display' : installBtn}"
        >Install PWA</a>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import UserService from '@/services/UserService'

export default {
  name: 'LoginForm',
  data: function () {
    return {
      valid: false,
      email: '',
      password: '',
      showpassword: false,
      installBtn: 'none',
      installPrompt: null,
      sending: false,
      passwordRule: [
        v => !!v || 'required'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ]
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    // siteDisabled: function () {
    //   const self = this
    //   self.$notify({
    //     group: 'errors',
    //     title: 'Register Disabled',
    //     text: 'VigZero will be down for an undetermined amount of time. Members will be notified as soon as it is available again.',
    //     type: 'warn'
    //   })
    // },

    formInvalid: function () {
      let isinvalid = false
      if (Object.prototype.hasOwnProperty.call(this.$refs, 'theLoginForm')) {
        this.$refs.theLoginForm.inputs.forEach(e => {
          if (e.valid === false) {
            isinvalid = true
          }
        })
      } else {
        // refs not available yet, form has not been rendered, default to no
        return true
      }
      return isinvalid
    },
    installer: function () {
      this.installBtn = 'none'
      if (!this.installPrompt) {
        return
      }
      this.installPrompt.prompt()
      this.installPrompt.userChoice.then(result => {
        if (result.outcome === 'accepted') {
          console.log('User accepted!')
        } else {
          console.log('User denied')
        }
        this.installPrompt = null
      })
    },
    showInstallPwaBtn: function () {
      window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault()
        this.installPrompt = e
        this.installBtn = 'block'
      })
    },

    login: async function () {
      if (this.valid) {
        const self = this
        // vue included component in main.js
        this.$updateloading(1)
        UserService.login(this.email, this.password).then(
          (user) => {
            self.$emit('updatelogin')
            self.$notify({
              group: 'errors',
              clean: true
            })
            UserService.getAvatar().then(
              (response) => {
                if (response.data) {
                  localStorage.avatar = JSON.stringify(response.data)
                }
              },
              (error) => {
                console.log(error)
              }
            ).finally(() => {
              self.$updateloading(-1)
              // trigger menu update from login (due to local storage this is not "watchable")
              let redirect = '/dashboard'
              if (this.$route && this.$route.query && this.$route.query.r && this.$route.query.r.length > 0) {
                redirect = this.$route.query.r
              }
              self.$router.push(redirect, function () {
                self.$notify({
                  group: 'notices',
                  title: 'Login Successful',
                  text: 'You are now logged in.',
                  type: 'info'
                })
              })
            })
          },
          function (failed) {
            self.$updateloading(-1)
            // https://github.com/euvl/vue-notification
            // self.$notify({
            //   group: 'errors',
            //   title: 'Login Failed',
            //   text: failed.data.error,
            //   type: 'warn'
            // })
            self.$notify({
              group: 'errors',
              title: 'Login Failed',
              text: failed.data.error,
              type: 'warn'
            })
          }
        )
      }
    },
    update: _.debounce(function (e) {
      this.input = e.target.value
    }, 300)
  },
  created: function () {
    // reset any loading stuff in case we were
    // redirected from another module and auth expired
    this.$updateloading(-999)
    // prepopulate company id if exists from previous login
    if (UserService.loggedIn()) {
      this.$router.push({ path: '/dashboard' })
    }
    this.showInstallPwaBtn()
  }

  // only for disabled banner
  // mounted: function () {
  //   this.$notify({
  //     group: 'errors',
  //     title: 'Site Disabled',
  //     text: 'VigZero will be down for an undetermined amount of time. Members will be notified as soon as it is available again.',
  //     type: 'warn'
  //   })
  // }
}
</script>
