var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tournament && _vm.round
    ? _c(
        "div",
        [
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "2", align: "right" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        "x-small": "",
                        icon: "",
                        elevation: "2",
                        disabled: !_vm.canReverseRound
                      },
                      on: {
                        click: function($event) {
                          return _vm.switchRound(-1)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                    1
                  )
                ],
                1
              ),
              _c("v-col", [
                _c("h1", { staticClass: "text-no-wrap text-md-h2" }, [
                  _vm._v(_vm._s(_vm.roundName))
                ])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "2", align: "left" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        "x-small": "",
                        icon: "",
                        elevation: "2",
                        disabled: !_vm.canAdvanceRound
                      },
                      on: {
                        click: function($event) {
                          return _vm.switchRound(1)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.userOnly
            ? _c("div", [
                _vm.userGames && _vm.userGames.length > 0
                  ? _c(
                      "div",
                      [
                        _vm._l(_vm.userGames, function(matchup) {
                          return _c("TournamentMatchup", {
                            key: "ug-" + matchup.id,
                            staticClass: "my-entries",
                            attrs: {
                              tournament: _vm.tournament,
                              game: matchup,
                              round: _vm.rounds[_vm.round - 1],
                              user_id: _vm.user_id,
                              group_id: _vm.group_id
                            }
                          })
                        }),
                        _c("v-divider")
                      ],
                      2
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", { staticClass: "text-center" }, [
                              _vm._v(
                                "No entries in " +
                                  _vm._s(_vm.rounds[_vm.round - 1])
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
              ])
            : _c(
                "div",
                _vm._l(_vm.tournament.matchups[_vm.round], function(matchup) {
                  return _c("TournamentMatchup", {
                    key: matchup.id,
                    staticClass: "bracket",
                    attrs: {
                      tournament: _vm.tournament,
                      game: matchup,
                      round: _vm.rounds[_vm.round - 1],
                      user_id: _vm.user_id,
                      group_id: _vm.group_id
                    }
                  })
                }),
                1
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }