<style>
.expander-icon {
  color: white !important;
  font-size: 2.5rem !important;
}
.button-border {
  box-shadow: 2px 2px 5px rgba(95, 95, 95, 0.7);
  border-radius: 5px;
  margin: 1em 1em 0 0;
  padding: 0.5em 1em 0 1em;
  width: 100%;
  cursor: pointer;
}
.button-border:hover {
  background-color: rgba(220, 220, 220, 0.4);
}
.button-border.inactive {
  background-color: rgba(220, 220, 220);
}
.row {
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}
.sub-text {
  color: rgb(95, 95, 95);
  font-size: 0.7rem;
}
.mt {
  margin-top: 0.7em;
}
.timer {
  padding: 0.2em 0.2em 0.2em 0.2em;
}
.collapse-arrow-down {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}
.full-height {
  height: 100%;
}
.center {
  text-align: center;
  align-items: center;
  transform: translateY(-50%);
  transform: translateY(50%);
}
.negative {
  color: rgb(180, 0, 0);
}
.positive {
  color: rgb(0, 180, 0);
}
.warning-card {
  background-color: rgb(255, 225, 142) !important;
  cursor: pointer;
}
.active-tab {
  background-color: red;
}
.dashboard-content {
  min-height: calc(90% - 35px) !important;
  max-height: calc(90% - 35px);
  overflow-y: auto;
  overflow-x: hidden;
}
.nav-icon {
  width:20%;
  height:auto;
}
.nav-pic {
  width: 85%;
  height: auto;
  margin:auto auto;
}

@media screen and (max-width: 959px) {
  .card {
    width: 100%;
    margin-left: 0 !important;
  }
}
@media screen and (min-width: 601px) {
  .dash {
    left: auto !important;
    transform: translateX(-12px);
  }

  .bottom-nav {
    left: auto !important;
    transform: translateX(12px);
  }
}
</style>
<template>
  <v-container
    class="pa-0 dash"
    :style="viewHeight"
    v-touch="{
      left: () => swipe(0),
      right: () => swipe(1),
    }"
  >
    <div style="height:40px"></div>
    <div
      class="py-0 tablabel robo"
      style="width: 100%; position: absolute; top: 8px; left: 0; z-index:5;"
      @click="reload()"
    >
      {{ pageTitle }}
      <v-icon v-if="activeTab('schedule') || activeTab('pending')"
        class="white--text pull-right" style="margin-top:3px; position: absolute; right: 4px;" size="30">refresh</v-icon>
    </div>
    <div id="dashboard-content" class="dashboard-content my-0 pt-2 px-2 z-index:0;">
      <div v-if="fatalerror != ''">{{ fatalerror }}</div>
      <v-row v-else class="my-0">
        <v-col
          cols="12"
          class="container py-0"
        >
          <ChatNotifications></ChatNotifications>
        </v-col>
        <v-col cols="12" class="container py-0">
          <ReleaseNotes></ReleaseNotes>
        </v-col>
        <v-col cols="12" class="container py-0" v-if="groupinvites && groupinvites.length > 0">
          <div class="text-center"><h4>You've been invited to the group(s) below. Click to join</h4></div>
          <invitation v-for="invite in groupinvites" :key="invite.id" :invite="invite" @update="getGroupInvites()"></invitation>
        </v-col>
        <v-col
          cols="12"
          class="container py-0 px-4"
          v-if="disputemessage != ''"
        >
          <v-card class="card">
            <v-card-text>
              <p>{{ disputemessage }}</p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          class="container py-0 px-4"
          v-if="needsPaymentMethod && loggedIn"
          @click="$router.push('/settings')"
        >
          <v-card class="card warning-card">
            <v-card-text>
              <p>
                <b
                  >You have yet to provide your Venmo handle. In order to make
                  or take bets, you must first provide your Venmo handle under
                  Settings. Select this message to go there now.</b
                >
              </p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          class="container py-0 px-4"
          v-if="debts && debts.length > 0"
        >
          <Debts
            v-if="debts && debts.length > 0"
            :debts="debts"
            @updatedata="fetchdata"
            :reloadKey="debtsKey"
          ></Debts>
        </v-col>
        <v-col
          cols="12"
          class="container py-0 px-4"
          v-if="payouts && payouts.length > 0"
        >
          <Payouts
            v-if="payouts && payouts.length > 0"
            :payouts="payouts"
            @updatedata="fetchdata"
            :reloadKey="payoutsKey"
          ></Payouts>
        </v-col>

        <!-- pending offers -->
        <v-col cols="12" class="py-0 px-4" v-if="activeTab('pending')">
          <Pending
            v-if="showpending"
            :reloadKey="pendingOffersKey"
            @updatedata="fetchdata"
            :section="section"
          ></Pending>
          <h3 v-else class="text-center">No pending items</h3>
        </v-col>

        <!-- Game schedule -->
        <v-col cols="12" class="py-0 px-4" v-if="activeTab('schedule')">
          <Schedule :gamescroll="scrollid" style="width: 100%;"></Schedule>
        </v-col>

        <v-col cols="12" class="py-0 mb-4 px-4" v-if="activeTab('favorite')">
          <Stats
            :section="section"
            :scrollid="scrollid"
            @updatedata="fetchdata"
          ></Stats>
        </v-col>

        <v-col cols="12" class="py-0 mb-4 px-4" v-if="activeTab('bestvalue')">
          <BestValueOffers
            :section="section"
            :scrollid="scrollid"
            @updatedata="fetchdata"
          ></BestValueOffers>
        </v-col>

        <v-col cols="12" class="py-0 mb-4 px-4" v-if="activeTab('group')">
          <group-list></group-list>
          <!-- <GroupOffers
            :section="section"
            :scrollid="scrollid"
            @updatedata="fetchdata"
          ></GroupOffers> -->
        </v-col>
      </v-row>
    </div>
    <v-row class="my-0 bottom-nav">
      <v-flex class="py-0 nav-icon" @click="switchTab('group')" :style="getActiveStyle('group')">
        <v-img :src="getImageSource('group')" class="nav-pic"></v-img>
      </v-flex>
      <v-flex class="py-0 nav-icon" @click="switchTab('pending')" :style="getActiveStyle('pending')">
        <v-img :src="getImageSource('pending')" class="nav-pic"></v-img>
      </v-flex>
      <v-flex class="py-0 nav-icon" @click="switchTab('schedule')" :style="getActiveStyle('schedule')">
        <v-img :src="getImageSource('schedule')" class="nav-pic"></v-img>
      </v-flex>
      <v-flex class="py-0 nav-icon" @click="switchTab('bestvalue')" :style="getActiveStyle('bestvalue')">
        <v-img :src="getImageSource('bestvalue')" class="nav-pic"></v-img>
      </v-flex>
      <v-flex class="py-0 nav-icon" @click="switchTab('favorite')" :style="getActiveStyle('favorite')">
        <v-img :src="getImageSource('favorite')" class="nav-pic"></v-img>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import DashboardService from '@/services/DashboardService'
import CommunityService from '@/services/CommunityService'
import UserService from '@/services/UserService'

import Debts from '@/components/dashboard/Debts'
import Payouts from '@/components/dashboard/Payouts'
import Schedule from '@/views/Schedule'
import Pending from '@/components/dashboard/PendingOffers'
import Stats from '@/components/dashboard/Stats'
import BestValueOffers from '@/components/dashboard/BestValueOffers'
import Invitation from '@/components/groups/Invitation'
// import GroupOffers from '@/components/dashboard/GroupOffers'
import GroupList from '@/views/GroupList'
import ReleaseNotes from '@/components/ReleaseNotes'
import ChatNotifications from '@/components/chat/Notifications'
import { bus } from '@/main'

export default {
  name: 'Dashboard',
  components: {
    Debts,
    Payouts,
    Schedule,
    Pending,
    Stats,
    BestValueOffers,
    // GroupOffers,
    ReleaseNotes,
    ChatNotifications,
    GroupList,
    Invitation
  },
  data: () => {
    return {
      payouts: [],
      debts: [],
      fatalerror: '',
      pendingOffersKey: 0,
      debtsKey: 0,
      payoutsKey: 0,
      disputemessage: '',
      needsPaymentMethod: true,
      showpending: true,
      expandschedule: false,
      expandpending: false,
      // starting tab to be on
      tab: 2,
      // list of tabs IN ORDER from left to right (must stay in correct order to work properly)
      sections: ['group', 'pending', 'schedule', 'bestvalue', 'favorite'],
      screenheight: 0,
      swipeEnabled: true,
      groupinvites: [],
      loggedIn: false
    }
  },
  props: {
    scrollid: {
      type: String,
      default: ''
    },
    section: {
      type: String,
      default: ''
    }
  },
  created: function () {
    const user = UserService.getUserObject()

    if (user && user.key && user.key !== '') {
      this.loggedIn = true
    }

    if (this.loggedIn) {
      this.fetchdata()
      var paymentmethod = user.paymentmethod
      if (paymentmethod[0] && paymentmethod[0].details[0] && paymentmethod[0].details[0].value !== '') {
        this.needsPaymentMethod = false
      }
    }

    // similar logic to the 'section' watcher. done just in case
    if (this.section && this.section.length > 0) {
      var index = this.sections.indexOf(this.section)
      if (index !== -1) {
        this.switchTab(this.section)
      } else {
        this.switchTab('schedule')
      }
    } else {
      this.switchTab('schedule')
    }

    this.screenheight = window.innerHeight
    window.addEventListener('resize', () => { this.screenheight = window.innerHeight })

    bus.$on('inputfocus', () => { this.swipeEnabled = false; console.log('disable') })
    bus.$on('inputblur', () => { this.swipeEnabled = true; console.log('enable') })
  },
  mounted: function () {
    bus.$emit('checkslidestatus')
  },
  methods: {
    swipe: function (direction) {
      if (!this.swipeEnabled) {
        return
      }

      bus.$emit('close_menu')
      var tab = this.tab
      if (direction === 0) {
        // swipe left - go to next page to the right
        tab += 1
      } else if (direction === 1) {
        // swipe right - go to next page to the left
        tab -= 1
      }

      // wrap around when going over the length of the sections array
      if (tab > this.sections.length - 1) {
        tab = 0
      } else if (tab < 0) {
        tab = 4
      }

      this.switchTab(this.sections[tab])
    },
    switchTab: function (tab) {
      // using tab number and sections array list, make sure we are not already on that current route
      if (this.$router.currentRoute.fullPath !== ('/dashboard/' + tab)) {
        // added in because of props that are passed through, if already on correct route just set the tab number
        if (this.$router.currentRoute.fullPath.includes('/dashboard/' + tab)) {
          this.tab = this.sections.indexOf(tab)
        } else {
          // otherwise push the route to be the section number we are switching to
          // changing the route will take care of the tab number since we are listening on the prop
          // I did it this way because doing this differently either makes things weird in certain cases or makes it call multiple times
          this.$router.push('/dashboard/' + tab)
        }
      } else { // if already on current route, set the tab number
        this.tab = this.sections.indexOf(tab)
      }

      if (this.tab < 0) {
        this.tab = 2
        this.$router.push('/dashboard/schedule')
      }
    },
    fetchdata: function () {
      if (!this.loggedIn) {
        return
      }
      this.$updateloading(1)
      this.payouts = null
      this.debts = null
      DashboardService.finishedGames().then(
        (response) => {
          if (response.data.payouts) {
            this.payouts = response.data.payouts
          }
          if (response.data.debts) {
            this.debts = response.data.debts
          }

          this.disputemessage = ''
          if (this.payouts.length > 0) {
            this.payouts.forEach(element => {
              if (this.isDispute(element)) {
                this.disputemessage = 'You currently have an open dispute for a wager that you won. This will not affect your ability to make or take wagers'
              }
            })
          }
          if (this.debts.length > 0) {
            this.debts.forEach(element => {
              if (this.isDispute(element)) {
                this.disputemessage = 'You currently have an open dispute for a wager that you lost. You will not be able to make or take any more wagers until this is resolved'
              }
            })
          }
        },
        (error) => {
          this.fatalerror = error.response.data.error
        }
      ).finally(() => {
        // this.pendingOffersKey += 1
        this.debtsKey += 1
        this.payoutsKey += 1
        this.pendingOffersKey += 1
        this.$updateloading(-1)
      })

      this.getGroupInvites()
    },
    isDispute: function (wager) {
      return wager.opendispute === 1
    },
    activeTab: function (tab) {
      return tab === this.sections[this.tab]
    },
    getImageSource: function (tab) {
      var img = '/img/dashboard/' + tab
      if (this.activeTab(tab)) {
        img = img + '_active'
      }
      return img + '.png'
    },
    getActiveStyle: function (tab) {
      var result = {
        'background-color': '#212121'
      }
      if (this.activeTab(tab)) {
        result['background-color'] = '#C12626'
      }
      return result
    },
    reload: async function () {
      this.$updateloading(1)
      await bus.$emit('reloadpage')
      await this.fetchdata()
      this.$updateloading(-1)
    },
    getGroupInvites: function () {
      if (!this.loggedIn) {
        return
      }
      CommunityService.getinvites().then(
        (response) => {
          var invites = response.data
          this.groupinvites = []
          if (invites !== null && invites.length > 0) {
            invites.forEach(element => {
              this.$set(this.groupinvites, this.groupinvites.length, element)
            })
          }
        }
      )
    }
  },
  watch: {
    // watch the section that is in the route
    section: function (n, o) {
      if (o !== n || n !== this.section) {
        // if we have a section, get the index of the section from our sections array
        if (this.section && this.section.length > 0) {
          var index = this.sections.indexOf(this.section)
          // if section exists, set the tab number
          if (index !== -1) {
            this.tab = index
          } else { // else go to middle tab
            this.switchTab('schedule')
          }
        } else { // if no section exists go to the middle tab
          this.tab = 2
        }
      }
    }
  },
  computed: {
    pageTitle: function () {
      var title = ''
      var section = this.sections[this.tab]
      switch (section) {
        case 'group':
          title = 'My Groups'
          break
        case 'favorite':
          title = 'Stats'
          break
        case 'bestvalue':
          title = 'Open Offers'
          break
        default:
          title = section
      }

      return title
    },

    viewHeight: function () {
      return {
        'max-height': (this.screenheight - 48) + 'px',
        height: (this.screenheight - 48) + 'px',
        position: 'fixed',
        top: '40px',
        left: 0,
        'max-width': '600px',
        margin: '0 auto'
      }
    }
  }
}
</script>
