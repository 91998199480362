var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticStyle: { "border-radius": "10px" } },
    [
      _c("v-img", { attrs: { src: _vm.getLogo(), height: "130" } }),
      _c(
        "v-container",
        { staticClass: "pa-0 ma-0" },
        [
          _c(
            "v-row",
            { staticClass: "pr-5", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("v-card-title", { staticClass: "group-card-title" }, [
                    _vm._v(_vm._s(_vm.group.name))
                  ])
                ],
                1
              ),
              _vm.group.wagerCount == 0 ? _c("v-spacer") : _vm._e(),
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.group.wagerCount > 0,
                      expression: "group.wagerCount > 0"
                    }
                  ],
                  attrs: { align: "right" }
                },
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        overlap: "",
                        bordered: "",
                        value: _vm.group.wagerCount > 0,
                        content: _vm.badgeNumber(_vm.group.wagerCount),
                        color: "red"
                      }
                    },
                    [
                      _c("v-icon", { attrs: { dense: "", color: "black" } }, [
                        _vm._v("local_atm")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { align: "right" } },
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        overlap: "",
                        bordered: "",
                        value: _vm.group.messageCount > 0,
                        content: _vm.badgeNumber(_vm.group.messageCount),
                        color: "red"
                      }
                    },
                    [
                      _c("v-icon", { attrs: { dense: "", color: "green" } }, [
                        _vm._v("sms")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.group.contestCount > 0,
                      expression: "group.contestCount > 0"
                    }
                  ],
                  attrs: { align: "right" }
                },
                [
                  _c(
                    "v-icon",
                    { attrs: { dense: "", color: "yellow darken-2" } },
                    [_vm._v("mdi-trophy")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }