<template>
  <v-row
    class="my-0 py-0"
    v-if="game"
  >
    <v-col cols="12" class="py-0">
      <BoxScore :scoreboard="game.scoreboard"></BoxScore>
    </v-col>

    <v-col class="py-0">
      <BetInfo :game="game" :detail="detail"></BetInfo>
    </v-col>
  </v-row>
</template>

<script>
import BoxScore from '@/components/games/BoxScore'
import BetInfo from '@/components/games/BetInfo'

export default {
  name: 'GameDetail',
  props: {
    game: Object,
    detail: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BoxScore,
    BetInfo
  }
}
</script>
