<template>
  <v-dialog
    v-model="showDialog"
    persistent
    max-width="320"
  >
    <v-card>
      <v-card-title class="headline">
        Remove Contest Entry?
      </v-card-title>
      <v-card-text>Are you sure you want to remove <strong>{{ entry.nickname }}</strong>'s contest entry? This cannot be undone. The user will need to re-enter the contest.</v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          raised
          @click="remove"
        >
          Remove
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          raised
          @click="$emit('cancel')"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ContestService from '@/services/ContestService'

export default {
  name: 'RemoveEntryDialog',

  props: {
    entry: Object
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    showDialog: function () {
      return (this.entry && this.entry.id > 0)
    }
  },

  methods: {
    async remove () {
      if (this.loading || !this.entry) {
        return false
      }
      this.loading = true
      ContestService.removeEntry(this.entry).then(
        (response) => {
          this.loading = false
          this.$emit('remove')
        },
        (err) => {
          var error = err.response.data
          this.loading = false
          this.$notify({
            group: 'errors',
            title: error.errors[0],
            text: error.error,
            type: 'warn'
          })
        }
      )
    }
  }
}
</script>
