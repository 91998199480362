import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import Icon from '@/layouts/Icon'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    values: {
      bullhorn: {
        component: Icon,
        props: {
          name: 'bullhorn'
        }
      },
      pending: {
        component: Icon,
        props: {
          name: 'pending'
        }
      },
      bestvalue: {
        component: Icon,
        props: {
          name: 'bestvalue'
        }
      },
      myteams: {
        component: Icon,
        props: {
          name: 'favorite'
        }
      },
      group: {
        component: Icon,
        props: {
          name: 'group'
        }
      },
      schedule: {
        component: Icon,
        props: {
          name: 'schedule'
        }
      }
    }
  }
})
