<template>
  <div class="contact">
    <v-form v-model="valid">
      <v-row class="px-4">
        <v-col>
          <v-card class="px-4">
            <v-card-title>Contact Us</v-card-title>
            <v-card-text>
              <v-row class="px-4">
                <div v-if="loggedin && usernickname">
                  Let us know if you need assistance, have questions or have ideas on how to make VigZero better.
                </div>
              </v-row>
              <v-row v-if="!loggedin">
                <v-col cols="12">
                  <v-text-field
                    label="First Name*"
                    required
                    name="firstname"
                    id="firstname"
                    v-model="form.firstname"
                    :disabled="sent"
                    :error-messages="getErrors('firstname')"
                    @blur="$v.form.firstname.$touch()"
                  ></v-text-field>

                  <v-text-field
                    label="Last Name*"
                    name="lastname"
                    id="lastname"
                    v-model="form.lastname"
                    required
                    :disabled="sent"
                    :error-messages="getErrors('lastname')"
                    @blur="$v.form.lastname.$touch()"
                  ></v-text-field>

                  <v-text-field
                    label="Email*"
                    type="email"
                    name="email"
                    id="email"
                    required
                    v-model="form.email"
                    :disabled="sent"
                    :error-messages="getErrors('email')"
                    @blur="$v.form.email.$touch()"
                  />

                  <v-text-field
                    label="Phone*"
                    name="phone"
                    id="phone"
                    type="text"
                    v-model="form.phone"
                    required
                    :disabled="sent"
                    :error-messages="getErrors('phone')"
                    @blur="$v.form.phone.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="loggedin && wagers && wagers.length > 0">
                <v-col cols="12">Is this about a specific wager?</v-col>
                <v-col>
                  <v-select
                    v-model="form.wager_id"
                    label="Related Wager"
                    :items="wagers"
                    item-text="display"
                    item-value="id"
                    clearable
                    hide-details>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="px-4">
                <v-textarea
                  v-model="form.comment"
                  label="Comment*"
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  :error-messages="getErrors('comment')"
                  @blur="$v.form.comment.$touch()"
                ></v-textarea>
              </v-row>
              <v-row
                class="px-4"
                v-if="!loggedin"
              >
                <vue-recaptcha
                  ref="recaptcha"
                  @verify="onVerify"
                  :sitekey="capkey"
                >
                </vue-recaptcha>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row
                justify="end"
                class="px-8"
              >
                <v-col cols="3">
                  <v-btn
                    text
                    type="submit"
                    :disabled="sent"
                    @click.prevent="submitform"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import axios from 'axios'
import UserService from '@/services/UserService'
import { validationMixin } from 'vuelidate'
import VueRecaptcha from 'vue-recaptcha'
import {
  required,
  email,
  minLength
} from 'vuelidate/lib/validators'

export default {
  name: 'Contact',
  mixins: [validationMixin],
  components: {
    VueRecaptcha
  },
  data: () => ({
    form: {
      firstname: '',
      lastname: '',
      email: '',
      comment: '',
      phone: '',
      nickname: '',
      wager_id: null
    },
    captcha: null,
    capkey: '6Lf5ADgaAAAAAGLsIB27S0OGIpOyhVDoBv4LxW-z',
    errors: {
      firstname: '',
      lastname: '',
      email: '',
      comment: '',
      phone: ''
    },
    usernickname: null,
    wagers: null,
    sent: false,
    sendError: false,
    loggedin: false,
    valid: false,
    formHasErrors: false,
    requiredRule: [
      v => !!v || 'required'
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid'
    ]
  }),
  validations: {
    form: {
      firstname: {
        required,
        minLength: minLength(1)
      },
      lastname: {
        required,
        minLength: minLength(1)
      },
      email: {
        required,
        email
      },
      comment: {
        required
      },
      phone: {
        required,
        minLength: minLength(10)
      }
    }
  },
  created: function () {
    const user = UserService.getUserObject()
    if (user) {
      this.form.firstname = user.info.firstname
      this.form.lastname = user.info.lastname
      this.form.email = user.info.email
      this.form.userid = user.info.id
      this.form.nickname = user.info.nickname
      this.form.phone = user.info.cellphone
      this.loggedin = true
      this.usernickname = user.info.nickname
      this.init()
    }
  },
  methods: {
    async init () {
      this.$updateloading(1)
      await axios({
        url: UserService.baseUrl() + 'contactus/init',
        method: 'post',
        data: JSON.stringify({ user_id: this.form.userid })
      }).then(response => {
        this.wagers = response.data
        this.$updateloading(-1)
      })
    },

    submitform () {
      if (this.valid && (this.loggedin || this.captcha)) {
        this.$updateloading(1)
        this.sent = false
        this.sendError = false
        this.form.captcha = this.captcha
        axios({
          url: UserService.baseUrl() + 'contactus/',
          method: 'post',
          data: JSON.stringify(this.form)
        })
          .then(response => {
            this.$updateloading(-1)
            this.sent = true
            this.$v.$reset()
            this.form.firstname = ''
            this.form.lastname = ''
            this.form.email = ''
            this.form.comment = ''
            this.form.phone = ''

            var route = UserService.loggedIn() ? '/dashboard' : '/login'
            this.$router.push(route, () => {
              this.$notify({
                group: 'notices',
                title: 'Message Sent',
                text: 'Thank you for your submission. Our support staff will get back to you within 24 hours. Thank you.',
                type: 'info'
              })
            })
          })
          .catch(error => {
            this.$updateloading(-1)
            console.log(error)
            this.$notify({
              group: 'errors',
              title: 'Something went wrong',
              text: 'We were unable to proccess your comment at this time. Please try again later.',
              type: 'warn'
            })
          })
      }
    },
    onVerify: function (response) {
      // console.log(response)
      this.captcha = response
    },
    getErrors (fieldName) {
      const err = []
      if (!this.$v.form[fieldName].$dirty) return err
      if (fieldName === 'firstname') {
        !this.$v.form.firstname.required && err.push('first name is required')
      }
      if (fieldName === 'lastname') {
        !this.$v.form.lastname.required && err.push('last name is required')
      }
      if (fieldName === 'email') {
        !this.$v.form.email.required && err.push('email is required')
        !this.$v.form.email.email && err.push('please enter a valid email')
        this.errors.email.length > 0 && err.push(this.errors.email)
      }
      if (fieldName === 'phone') {
        !this.$v.form.phone.required && err.push('cellphone is required')
        !this.$v.form.phone.minLength && err.push('please enter a valid cellphone number')
        this.errors.phone.length > 0 && err.push(this.errors.phone)
      }
      if (fieldName === 'comment') {
        !this.$v.form.comment.required && err.push('comment is required')
      }
      return err
    },
    getValidationClass (fieldName) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'md-invalid': (field.$invalid && field.$dirty) || this.errors[fieldName].length > 0
        }
      }
    },
    validateUser () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitform()
      }
    }
  }
}
</script>
