var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "about" },
    [
      _c(
        "v-row",
        { staticClass: "mt-0" },
        [
          _c(
            "v-col",
            { staticClass: "mt-0 pt-0", attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-0 px-4 pt-4",
                      attrs: { justify: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0 text-center",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "max-width": "25%",
                              "border-radius": "0",
                              height: "auto"
                            },
                            attrs: {
                              src: require("../assets/black_red_icon/512.png")
                            }
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _c(
                      "span",
                      {
                        staticClass: "robo",
                        staticStyle: { "font-size": "1.8em" }
                      },
                      [_vm._v("FAQ")]
                    )
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 px-0 black--text",
                              attrs: { cols: "12" },
                              on: {
                                click: function($event) {
                                  return _vm.clickexpander(1)
                                }
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "1" } }, [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.expanded == 1
                                                  ? "remove_circle_outline"
                                                  : "add_circle_outline"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      " How do I install VigZero App on my Android phone? "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.expanded == 1,
                                          expression: "expanded == 1"
                                        }
                                      ],
                                      staticClass: "mx-auto pa-3",
                                      attrs: { block: "" }
                                    },
                                    [
                                      _vm._v(" Use Chrome and visit "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "link",
                                          attrs: {
                                            target: "_blank",
                                            href: "https://app.vigzero.com"
                                          }
                                        },
                                        [_vm._v("app.vigzero.com")]
                                      ),
                                      _vm._v(
                                        ". Once logged in, click on the 3 dots in the top right-hand corner and choose 'Add to Home Screen'. Choose 'Add'. "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-divider")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 px-0 black--text",
                              attrs: { cols: "12" },
                              on: {
                                click: function($event) {
                                  return _vm.clickexpander(2)
                                }
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { justify: "start", align: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "1" } }, [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.expanded == 2
                                                  ? "remove_circle_outline"
                                                  : "add_circle_outline"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      "How do I install VigZero App on my iPhone?"
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.expanded == 2,
                                          expression: "expanded == 2"
                                        }
                                      ],
                                      staticClass: "mx-auto pa-3",
                                      attrs: { block: "" }
                                    },
                                    [
                                      _vm._v(" Use Safari and visit "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "link",
                                          attrs: {
                                            target: "_blank",
                                            href: "https://app.vigzero.com"
                                          }
                                        },
                                        [_vm._v("app.vigzero.com")]
                                      ),
                                      _vm._v(
                                        ". Once logged in, click the center [box with upward arrow] button at the bottom of the screen. Scroll down and choose ‘Add to Home Screen’. Choose ‘Add’. "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-divider")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 px-0 black--text",
                              attrs: { cols: "12" },
                              on: {
                                click: function($event) {
                                  return _vm.clickexpander(3)
                                }
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { justify: "start", align: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "1" } }, [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.expanded == 3
                                                  ? "remove_circle_outline"
                                                  : "add_circle_outline"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      "What payment method am I supposed to use?"
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.expanded == 3,
                                          expression: "expanded == 3"
                                        }
                                      ],
                                      staticClass: "mx-auto pa-3",
                                      attrs: { block: "" }
                                    },
                                    [
                                      _vm._v(
                                        " Although we hope to add additional methods in the future, Venmo is currently required to settle bets. "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-divider")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 px-0 black--text",
                              attrs: { cols: "12" },
                              on: {
                                click: function($event) {
                                  return _vm.clickexpander(10)
                                }
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { justify: "start", align: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "1" } }, [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.expanded == 10
                                                  ? "remove_circle_outline"
                                                  : "add_circle_outline"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", [
                                    _vm._v("What if I paid the wrong person?")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.expanded == 10,
                                          expression: "expanded == 10"
                                        }
                                      ],
                                      staticClass: "mx-auto pa-3",
                                      attrs: { block: "" }
                                    },
                                    [
                                      _vm._v(
                                        " First, pay the correct person by using the link/handle provided to ensure you pay the correct person. Although Venmo gives no guarantees, request your money back in from them ASAP by following their process outlined "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "https://help.venmo.com/hc/en-us/articles/209681208",
                                            target: "_blank"
                                          }
                                        },
                                        [_vm._v("here")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-divider")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 px-0 black--text",
                              attrs: { cols: "12" },
                              on: {
                                click: function($event) {
                                  return _vm.clickexpander(9)
                                }
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { justify: "start", align: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "1" } }, [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.expanded == 9
                                                  ? "remove_circle_outline"
                                                  : "add_circle_outline"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      "How do I boost my rating and why is it important to do so?"
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.expanded == 9,
                                          expression: "expanded == 9"
                                        }
                                      ],
                                      staticClass: "mx-auto pa-3",
                                      attrs: { block: "" }
                                    },
                                    [
                                      _vm._v(
                                        " A member's rating is determined by a variety of factors including but not limited to: "
                                      ),
                                      _c("ul", [
                                        _c("li", [
                                          _vm._v(
                                            "How long it takes you to pay your lost bets and confirm your payments in VigZero."
                                          )
                                        ]),
                                        _c("li", [
                                          _vm._v(
                                            "How long it takes you to confirm that you have been paid for a winning bet."
                                          )
                                        ]),
                                        _c("li", [
                                          _vm._v(
                                            "How many bets you make or take (activity)."
                                          )
                                        ]),
                                        _c("li", [
                                          _vm._v(
                                            "How many of your invitees are in good standing."
                                          )
                                        ]),
                                        _c("li", [
                                          _vm._v(
                                            "How many different members you have placed bets with."
                                          )
                                        ])
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "A member's rating is important. If a rating drops too low, the member can be removed from the platform. Members with high ratings have a higher likelihood of getting their offers taken as their offers are promoted."
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-divider")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 px-0 black--text",
                              attrs: { cols: "12" },
                              on: {
                                click: function($event) {
                                  return _vm.clickexpander(7)
                                }
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { justify: "start", align: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "1" } }, [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.expanded == 7
                                                  ? "remove_circle_outline"
                                                  : "add_circle_outline"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      "How do I favorite a team in order to see offers in 'My Teams'?"
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.expanded == 7,
                                          expression: "expanded == 7"
                                        }
                                      ],
                                      staticClass: "mx-auto pa-3",
                                      attrs: { block: "" }
                                    },
                                    [
                                      _vm._v(
                                        " Favorite teams can be selected from the 'Schedule' section. When you select a team you are interested in making a favorite, tap the heart icon to the left of your team's name to add it to 'My Teams'. To remove an existing favorite, tap the heart icon again. "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-divider")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 px-0 black--text",
                              attrs: { cols: "12" },
                              on: {
                                click: function($event) {
                                  return _vm.clickexpander(8)
                                }
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { justify: "start", align: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "1" } }, [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.expanded == 8
                                                  ? "remove_circle_outline"
                                                  : "add_circle_outline"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      "How often are the current lines of games updated?"
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.expanded == 8,
                                          expression: "expanded == 8"
                                        }
                                      ],
                                      staticClass: "mx-auto pa-3",
                                      attrs: { block: "" }
                                    },
                                    [
                                      _vm._v(
                                        " The closer a game gets to its starting time, the more frequent line updates occur. Remember that unless 'auto adjust' is selected on a bet offer, the line does not change for those available bets. "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-divider")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 px-0 black--text",
                              attrs: { cols: "12" },
                              on: {
                                click: function($event) {
                                  return _vm.clickexpander(4)
                                }
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { justify: "start", align: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "1" } }, [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.expanded == 4
                                                  ? "remove_circle_outline"
                                                  : "add_circle_outline"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", [
                                    _vm._v(" How do I get a Venmo Account? ")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.expanded == 4,
                                          expression: "expanded == 4"
                                        }
                                      ],
                                      staticClass: "mx-auto pa-3",
                                      attrs: { block: "" }
                                    },
                                    [
                                      _vm._v(" Visit "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "link",
                                          attrs: {
                                            target: "_blank",
                                            href:
                                              "https://help.venmo.com/hc/en-us/articles/209690068-How-to-Sign-Up"
                                          }
                                        },
                                        [_vm._v("Venmo")]
                                      ),
                                      _vm._v(" for details. ")
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-divider")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 px-0 black--text",
                              attrs: { cols: "12" },
                              on: {
                                click: function($event) {
                                  return _vm.clickexpander(5)
                                }
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { justify: "start", align: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "1" } }, [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.expanded == 5
                                                  ? "remove_circle_outline"
                                                  : "add_circle_outline"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      "Do I have to deposit money in this app to bet on a game?"
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.expanded == 5,
                                          expression: "expanded == 5"
                                        }
                                      ],
                                      staticClass: "mx-auto pa-3",
                                      attrs: { block: "" }
                                    },
                                    [
                                      _vm._v(
                                        " VigZero does not hold money or deposits for members. VigZero is a peer to peer gaming solution where you are responsible for paying out your losses directly to the winner through Venmo. Members are rated based on their speed at settling wagers. Any members with unsettled wagers are prevented from making bets. A member's limit is based on their history of paying their losses. "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-divider")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0", attrs: { justify: "start" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 px-0 black--text",
                              attrs: { cols: "12" },
                              on: {
                                click: function($event) {
                                  return _vm.clickexpander(6)
                                }
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { justify: "start", align: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "1" } }, [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.expanded == 6
                                                  ? "remove_circle_outline"
                                                  : "add_circle_outline"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      "How can I get my betting limit higher?"
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.expanded == 6,
                                          expression: "expanded == 6"
                                        }
                                      ],
                                      staticClass: "mx-auto pa-3",
                                      attrs: { block: "" }
                                    },
                                    [
                                      _vm._v(
                                        " Your total betting limit increases as payments for your losses are confirmed. Limits are not increased for repeat wagers with the same Member. "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-divider")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0", attrs: { justify: "start" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 px-0 black--text",
                              attrs: { cols: "12" },
                              on: {
                                click: function($event) {
                                  return _vm.clickexpander(11)
                                }
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { justify: "start", align: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "1" } }, [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.expanded == 11
                                                  ? "remove_circle_outline"
                                                  : "add_circle_outline"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      "Did you miss one of our release notes pop ups?"
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.expanded == 11,
                                          expression: "expanded == 11"
                                        }
                                      ],
                                      staticClass: "mx-auto pa-3",
                                      attrs: { block: "" }
                                    },
                                    [
                                      _vm._v(
                                        " to see a complete list of our release notes, you can view them in our "
                                      ),
                                      _c(
                                        "router-link",
                                        { attrs: { to: "/notes" } },
                                        [_vm._v("notes")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0", attrs: { justify: "start" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 px-0 black--text",
                              attrs: { cols: "12" },
                              on: {
                                click: function($event) {
                                  return _vm.clickexpander(12)
                                }
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { justify: "start", align: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "1" } }, [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.expanded == 11
                                                  ? "remove_circle_outline"
                                                  : "add_circle_outline"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      "How can I learn more about responsible gambling?"
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.expanded == 12,
                                          expression: "expanded == 12"
                                        }
                                      ],
                                      staticClass: "mx-auto pa-3",
                                      attrs: { block: "" }
                                    },
                                    [
                                      _vm._v(
                                        " Must be 21+ - Gambling Problem? Help is available from the toll-free nationwide hotline, call: 1-800-GAMBLER, chat: "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "https://www.ncpgambling.org/help-treatment/chat/"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "https://www.ncpgambling.org/help-treatment/chat/"
                                          )
                                        ]
                                      ),
                                      _vm._v(", or visit "),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "https://www.ncpgambling.org/help-treatment/"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "https://www.ncpgambling.org/help-treatment/"
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-divider")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }