import axios from 'axios'
import Vue from 'vue'
import Notifications from 'vue-notification'
import UserService from '@/services/UserService'

Vue.use(Notifications)

const TransactionService = {
  async addDeposit (data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'transaction/deposit',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async requestWithdrawal (data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'transaction/withdrawal',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async requestRefund (data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'transaction/refund',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async getUserCurrentBalance (userId) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'transaction/currentbalance/' + userId,
      method: 'get'
    })
  },

  async getUserCurrentBalanceNoPending (userId) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'transaction/balance/nopending/' + userId,
      method: 'get'
    })
  },

  async getUserPendingBalance (userId) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'transaction/balance/pending/' + userId,
      method: 'get'
    })
  },

  async getFilteredUserTransactions (filters) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'transaction/filter/getusertransactions',
      data: JSON.stringify(filters),
      method: 'post'
    })
  },

  async getUserTransactions () {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'transaction/getusertransactions',
      method: 'get'
    })
  },

  async getLastUserDeposit () {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'transaction/getlastuserdeposit',
      method: 'get'
    })
  },

  async getAllTransactions () {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'transaction/all',
      method: 'get'
    })
  },

  async getTransactionById (id) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'transaction/id/' + id,
      method: 'get'
    })
  },

  async deleteTransaction (id) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'transaction/delete/' + id,
      method: 'post'
    })
  },

  async updateTransaction (id, data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'transaction/update/' + id,
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async updateTransactionStatus (id, data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'transaction/updatestatus/' + id,
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async updateTransactionAmount (id, data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'transaction/updateamount/' + id,
      data: JSON.stringify(data),
      method: 'post'
    })
  }
}

export default TransactionService
