var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.filteredDebts && _vm.filteredDebts.length > 0
    ? _c(
        "div",
        [
          _c("PaymentSentDialog", {
            attrs: { wager: _vm.confirmDebt },
            on: {
              cancel: function($event) {
                _vm.confirmDebt = null
              },
              paid: function($event) {
                _vm.confirmDebt = null
                _vm.$emit("updatedata")
              }
            }
          }),
          _c("PaymentSentDisputeDialog", {
            attrs: { wager: _vm.disputeDebt },
            on: {
              cancel: function($event) {
                _vm.disputeDebt = null
              },
              paid: function($event) {
                _vm.disputeDebt = null
                _vm.$emit("updatedata")
              }
            }
          }),
          _c(
            "v-card",
            { staticClass: "card-shadow card loser-shadow" },
            [
              _c("v-card-title", [
                _c("div", { staticClass: "loser mx-auto" }, [
                  _vm._v("Need to Settle")
                ])
              ]),
              _c(
                "v-card-text",
                _vm._l(_vm.filteredDebts, function(debt, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "row button-border black--text",
                      on: {
                        click: function($event) {
                          return _vm.selectDebt(debt, index)
                        }
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "my-0 py-0",
                          attrs: { align: "center", justify: "space-between" }
                        },
                        [
                          debt.dispute_id > 0
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "my-0 py-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("h4", { staticClass: "text-center" }, [
                                    _vm._v("Dispute Documentation Required")
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            { staticClass: "my-0 py-0", attrs: { cols: "12" } },
                            [
                              _c("span", { staticClass: "action-section" }, [
                                _vm._v("Bet #" + _vm._s(debt.id))
                              ]),
                              _vm._v(
                                " $" +
                                  _vm._s(debt.amount) +
                                  " " +
                                  _vm._s(_vm.getGameInfo(debt)) +
                                  " "
                              ),
                              _c("span", { staticClass: "loser" }, [
                                _vm._v("LOSS TO PAY")
                              ])
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "my-0 py-0", attrs: { cols: "12" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(debt.timetopay.toFixed(1)) +
                                  " hours since final "
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "sub-text text-center my-0 py-0",
                              attrs: { cols: "12" }
                            },
                            [_vm._v(" " + _vm._s(_vm.fullgame(debt)) + " ")]
                          )
                        ],
                        1
                      ),
                      debt.paymentsent != null && !_vm.isDispute(debt)
                        ? _c("div", { staticClass: "nottransparent" }, [
                            _vm._v("Awaiting Confirmation")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }