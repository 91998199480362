<style scoped>
.input-checkbox label input {
  margin: 10px;
}
.input-checkbox label {
  line-height: 10px;
}
.spacer {
  width: 100%;
  min-height: 2vh;
  display: block;
}
h1 {
  font-size: 1.8em;
}
</style>
<template>

  <div class="px-4">
    <v-dialog
      v-model="showUserAgreement"
      width="500"
    >
      <UserAgreement
        :isModal="true"
        @close="showUserAgreement = false"
      ></UserAgreement>
    </v-dialog>
    <div>
      <v-form
        @submit.prevent="register"
        v-model="valid"
        class="my-0 py-0"
      >
        <v-card v-if="registrationInviteOnly && !invite">
          <v-card-title>
            <div class="text-center">Invitation Only</div>
          </v-card-title>

          <v-card-text class="black--text">
            <p
              class="text-center"
              v-if="!invalidcode"
            >Registration is currently by invitation only.</p>
            <p
              class="text-center"
              v-if="invalidcode"
            >Your invitation code is invalid.</p>
          </v-card-text>
        </v-card>

        <v-card
          class="px-4 my-0"
          v-if="(!registrationInviteOnly || invite) && intro"
        >
          <v-card-text>
            <v-row justify="center">
              <img
                src="../assets/black_red_icon/512.png"
                style="max-width:10vh; height:auto; border-radius:0;"
              >
            </v-row>

            <v-row justify="center">
              <h1 class="robo text-center black--text">
                <span v-if="!invalidcode">
                  <span v-if="referral && referral.id">{{ referral.affiliate.name }}</span>
                  <span v-if="invite && invite.id">{{ invite.inviter }}</span>
                  <br>welcomes you to VigZero
                </span>
                <span v-else>We Invite You to VigZero</span>
              </h1>
            </v-row>

            <v-row class="black--text">
              <v-col cols="12">
                <div>
                  The peer to peer sports betting community.
                  Entertainment without the 'Vig'.
                  Bet on games with friends and the world.
                </div>

                <v-spacer></v-spacer>

                <!-- <div style="color: red;">VigZero will be down for an undetermined amount of time. Members will be notified as soon as it's available again.</div>

                <v-spacer></v-spacer> -->
                <v-row justify="start">
                  <span
                    class="px-3"
                    style="text-decoration: underline;"
                  >
                    Why VigZero?
                  </span>
                </v-row>
                <v-row>
                  <ul>
                    <li>Save the whopping 10% vig, juice and commission casinos and online sportbooks charge.  Keep more of your winnings.</li>
                    <li>Deposit/Withdraw funds using your Venmo account</li>
                    <li>Simple to use, not intimidating with low minimums</li>
                    <li>Set your own line! Get a hook, entice with a hook, or have your bet follow the Vegas consensus</li>
                    <li>Private groups allow you to bet directly with friends for more personal wagering including ability to trash talk privately during the game.</li>
                    <li>Track/bet your favorite teams for the novice or casual sports fan.</li>
                    <li>Shop open wagers to easily get in the action.</li>
                    <li>Tracks accumulative and group records against your friends and other VigZero bettors.</li>
                  </ul>
                </v-row>
                <v-row justify="center">
                  <div>
                    <span
                      class="link"
                      @click="closeIntro()"
                    >REGISTER HERE</span>
                  </div>
                </v-row>
                <p style="text-align: center; font-size: 0.8em; font-weight: bold;">
                  Must be 21+ - Gambling Problem? Help is available from the toll-free nationwide hotline, call: 1-800-GAMBLER,
                  chat: <a href="https://www.ncpgambling.org/help-treatment/chat/">https://www.ncpgambling.org/help-treatment/chat/</a>,
                  or visit <a href="https://www.ncpgambling.org/help-treatment/">https://www.ncpgambling.org/help-treatment/</a>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row
          id="topEl"
          class="my-0 py-0"
          v-if="(!registrationInviteOnly || invite) && !intro"
        >
          <v-col cols="12">
            <v-card class="px-3 ma-0">
              <v-card-title>Register</v-card-title>
              <v-card-text>
                <v-text-field
                  label="First Name*"
                  name="firstname"
                  id="firstname"
                  v-model="form.firstname"
                  :disabled="sending"
                  required
                  :error-messages="getErrors('firstname')"
                  @blur="$v.form.firstname.$touch()"
                  @input="errors.firstname = ''"
                ></v-text-field>
                <v-text-field
                  label="Last Name*"
                  name="lastname"
                  id="lastname"
                  v-model="form.lastname"
                  required
                  :disabled="sending"
                  :error-messages="getErrors('lastname')"
                  @blur="$v.form.lastname.$touch()"
                  @input="errors.lastname = ''"
                ></v-text-field>
                <v-text-field
                  label="Email*"
                  type="email"
                  name="email"
                  id="email"
                  required
                  v-model="form.email"
                  :disabled="sending"
                  :error-messages="getErrors('email')"
                  @blur="$v.form.email.$touch()"
                  @input="errors.email = ''"
                ></v-text-field>
                <v-text-field
                  label="Nickname*"
                  type="text"
                  name="nickname"
                  id="nickname"
                  required
                  v-model="form.nickname"
                  @input="errors.nickname = ''"
                  :disabled="sending"
                  :error-messages="getErrors('nickname')"
                  @blur="$v.form.nickname.$touch()"
                ></v-text-field>
                <v-text-field
                  label="Password*"
                  name="password"
                  id="password"
                  type="password"
                  v-model="form.password"
                  :disabled="sending"
                  :error-messages="getErrors('password')"
                  @blur="$v.form.password.$touch()"
                  @input="errors.password = ''"
                ></v-text-field>
                <v-text-field
                  label="Confirm Password*"
                  name="confirmpassword"
                  id="confirmpassword"
                  type="password"
                  v-model="form.confirmpassword"
                  :disabled="sending"
                  :error-messages="getErrors('confirmpassword')"
                  @blur="$v.form.confirmpassword.$touch()"
                  @input="errors.confirmpassword = ''"
                ></v-text-field>
                <v-text-field
                  label="Cell Phone* (IMPORTANT so double check)"
                  id="cellphone"
                  name="cellphone"
                  v-model="form.cellphone"
                  required
                  :disabled="sending"
                  :error-messages="getErrors('cellphone')"
                  @blur="$v.form.cellphone.$touch()"
                  @input="errors.cellphone = ''"
                ></v-text-field>

                <v-text-field
                  label="Invitation or Referral Code"
                  id="code"
                  name="code"
                  v-model="form.code"
                  :disabled="sending"
                  :error-messages="getErrors('code')"
                  required
                  @blur="$v.form.code.$touch()"
                  @input="errors.code = ''"
                ></v-text-field>

                <v-text-field
                  label="Venmo Handle (NOT required when registering)"
                  id="venmohandle"
                  name="venmohandle"
                  v-model="form.venmohandle"
                  prefix="@"
                  @keydown="preventInvalidVenmoHandle($event)"
                  @input="errors.venmohandle = ''; invalidVenmoHandle = null"
                  :disabled="sending"
                  :error-messages="getErrors('venmohandle')"
                  @blur="$v.form.venmohandle.$touch()"
                ></v-text-field>
                <p class="red--text font-weight-bold">* ALERT: You will need a Venmo account to wager and providing an incorrect Venmo handle could lead to lost winnings. Neither the payor nor VigZero are responsible when the wrong Venmo handle is provided.</p>
                <v-checkbox
                  v-model="form.agreement"
                  :error-messages="getErrors('agreement')"
                  @change="$v.form.agreement.$touch()"
                  required
                >
                  <template v-slot:label>
                    <div class="body-2">
                      I agree to the terms of the
                      <a
                        href="#"
                        @click="showUserAgreement = true"
                        class="link"
                      >
                        User Agreement
                      </a>
                    </div>
                  </template>
                </v-checkbox>
                <v-checkbox
                  id="age"
                  name="age"
                  required
                  v-model="form.age"
                  :disabled="sending"
                  @change="$v.form.age.$touch()"
                  :error-messages="getErrors('age')"
                  class="black--text"
                  label="I am 21 years of age or older."
                ></v-checkbox>
                <span
                  class="link"
                  @click="closeIntro()"
                  v-if="intro"
                >Back</span>
              </v-card-text>
              <p style="text-align: center; font-size: 0.8em; font-weight: bold;">
                Must be 21+ - Gambling Problem? Help is available from the toll-free nationwide hotline, call: 1-800-GAMBLER,
                chat: <a href="https://www.ncpgambling.org/help-treatment/chat/">https://www.ncpgambling.org/help-treatment/chat/</a>,
                or visit <a href="https://www.ncpgambling.org/help-treatment/">https://www.ncpgambling.org/help-treatment/</a>
              </p>
              <v-card-actions>
                <v-row class="my-0">
                  <v-col class="py-0">
                    <v-btn
                      type="submit"
                      class="black white--text pull-right mr-n3"
                      :disabled="sending"
                    >Create Account</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
              <v-snackbar v-model="userSaved">The user {{ lastUser }} was saved with success!</v-snackbar>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>
<script>
import UserAgreement from '@/components/UserAgreement'
import UserService from '@/services/UserService'
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
  sameAs,
  minLength
  // maxLength,
  // numeric
} from 'vuelidate/lib/validators'
const cellPhone = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  } else {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value.trim())
  }
}

export default {
  name: 'FormValidation',
  components: {
    UserAgreement
  },
  mixins: [validationMixin],
  data: () => ({
    code: null,
    valid: false,
    registrationInviteOnly: false,
    invalidcode: true,
    showUserAgreement: false,
    invite: null,
    referral: null,
    form: {
      firstname: null,
      lastname: null,
      password: null,
      confirmpassword: null,
      cellphone: null,
      email: null,
      code: '',
      age: false,
      reside: false,
      usage: false,
      nickname: null,
      venmohandle: null,
      agreement: false
    },
    errors: {
      firstname: '',
      lastname: '',
      password: '',
      confirmpassword: '',
      cellphone: '',
      email: '',
      code: '',
      age: '',
      reside: '',
      usage: '',
      nickname: '',
      agreement: '',
      venmohandle: ''
    },
    userSaved: false,
    sending: false,
    lastUser: null,
    intro: true,
    invalidVenmoHandle: null
  }),
  validations: {
    form: {
      firstname: {
        required,
        minLength: minLength(1)
      },
      lastname: {
        required,
        minLength: minLength(1)
      },
      cellphone: {
        required,
        cellPhone
      },
      password: {
        required,
        minLength: minLength(6)
      },
      confirmpassword: {
        required,
        sameAsPassword: sameAs('password')
      },
      email: {
        required,
        email
      },
      nickname: {
        required
      },
      age: {
        required,
        checked: sameAs(() => true)
      },
      reside: {
        required,
        checked: sameAs(() => true)
      },
      usage: {
        required,
        checked: sameAs(() => true)
      },
      agreement: {
        required,
        checked: sameAs(() => true)
      },
      code: {},
      venmohandle: {}
    }
  },
  mounted: function () {
    this.init()
    // this.$notify({
    //   group: 'errors',
    //   title: 'Register Disabled',
    //   text: 'VigZero will be down for an undetermined amount of time. Members will be notified as soon as it is available again.',
    //   type: 'warn'
    // })
  },
  methods: {
    async init () {
      const tmpCode = localStorage.getItem('invitecode')
      if (tmpCode && tmpCode !== '') {
        this.code = tmpCode
      } else {
        if (this.$route.query.code && this.$route.query.code.replace(/^\s+|\s+$/g, '') !== '') {
          this.code = this.$route.query.code
        }
      }
      if (UserService.loggedIn()) {
        // user is logged in, why are you registering?
        this.$router.push('/', () => {
          this.$notify({
            group: 'notices',
            title: 'Registration Error',
            text: 'You are already logged in.',
            type: 'danger'
          })
        })
      }

      if (this.code) {
        this.$updateloading(1)
        await UserService.getRegistrationCode(this.code).then(
          (response) => {
            this.invalidcode = true
            if (response.data) {
              this.form.code = this.code
              if (response.data.type === 'invite' && !response.data.accepteduser_id) {
                this.invite = response.data
                if (this.invite.email) {
                  this.form.email = this.invite.email
                }
                if (this.invite.phone) {
                  this.form.cellphone = this.invite.phone
                }
                this.invalidcode = false
              } else if (response.data.type === 'referral') {
                this.referral = response.data
                this.invalidcode = false
              }
            } else {
              this.$router.push('/register', () => {
                this.$notify({
                  group: 'notices',
                  title: 'Registration Code Invalid',
                  text: 'The registration code you provided does not exist or is no longer active.',
                  type: 'danger'
                })
              })
            }
            this.$updateloading(-1)
          },
          (error) => {
            this.$updateloading(-1)
            console.dir(error)
          }
        )
      }
    },

    getValidationClass (fieldName) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'md-invalid': (field.$invalid && field.$dirty) || this.errors[fieldName].length > 0
        }
      }
    },

    preventInvalidVenmoHandle (e) {
      if (!/[a-zA-Z0-9\-_]/.test(e.key)) {
        e.preventDefault()
      }
      if (this.form.venmohandle !== this.invalidVenmoHandle) {
        this.invalidVenmoHandle = null
      }
      this.$v.form.venmohandle.$touch()
    },

    getErrors (fieldName) {
      const err = []
      if (!this.$v.form[fieldName].$dirty) return err
      if (fieldName === 'firstname') {
        !this.$v.form.firstname.required && err.push('First name is required')
      }
      if (fieldName === 'lastname') {
        !this.$v.form.lastname.required && err.push('Last name is required')
      }
      if (fieldName === 'email') {
        !this.$v.form.email.required && err.push('Email is required')
        !this.$v.form.email.email && err.push('Please enter a valid email')
        this.errors.email.length > 0 && err.push(this.errors.email)
      }
      if (fieldName === 'nickname') {
        !this.$v.form.nickname.required && err.push('Nickname is required')
        this.errors.nickname.length > 0 && err.push(this.errors.nickname)
      }
      if (fieldName === 'password') {
        !this.$v.form.password.required && err.push('Password is required')
        !this.$v.form.password.minLength && err.push('Password must be longer than 6 characters')
      }
      if (fieldName === 'confirmpassword') {
        !this.$v.form.confirmpassword.required && err.push('Confirm password is required')
        !this.$v.form.confirmpassword.sameAsPassword && err.push('Passwords do not match')
      }
      if (fieldName === 'cellphone') {
        !this.$v.form.cellphone.required && err.push('Cellphone is required')
        !this.$v.form.cellphone.cellPhone && err.push('Please enter a valid cellphone number')
        this.errors.cellphone.length > 0 && err.push(this.errors.cellphone)
      }
      if (fieldName === 'agreement') {
        !this.$v.form.agreement.required && err.push('Terms of User Agreement is required')
        !this.$v.form.agreement.checked && err.push('Please check that you agree to the terms of The User Agreement')
      }
      if (fieldName === 'age') {
        !this.$v.form.age.required && err.push('Must be older than 21 years old')
        !this.$v.form.age.checked && err.push('Please check that you are older than 21')
      }
      if (fieldName === 'venmohandle') {
        if (this.errors.venmohandle.length > 0) {
          err.push(this.errors.venmohandle)
        } else if (this.invalidVenmoHandle) {
          err.push('Unable to verify venmo handle!')
        }
      }
      return err
    },

    clearForm () {
      this.$v.$reset()
      this.form.firstname = null
      this.form.lastname = null
      this.form.email = null
      this.form.password = null
      this.form.confirmpassword = null
      this.form.cellphone = null
      this.form.nickname = null
      this.form.code = ''
      this.form.nickname = null
      this.form.age = false
      this.form.agreement = false
      this.form.venmohandle = null
    },
    async register () {
      this.$v.$touch()
      if (this.valid) {
        if (!this.form.age) {
          this.$notify({
            group: 'errors',
            title: 'Age Restricted!',
            text: 'You must be at least 21 years of age to register.',
            type: 'warn'
          })
          return false
        }
        if (!this.form.agreement) {
          this.$notify({
            group: 'errors',
            title: 'Terms of User Agreement',
            text: 'You must agree to the terms of the User Agreement.',
            type: 'warn'
          })
          return false
        }
        if (this.registrationInviteOnly && !this.form.code) {
          this.$notify({
            group: 'errors',
            title: 'Invitation code required!',
            text: 'You must have a valid invitation code.',
            type: 'warn'
          })
          return false
        }
        // remove delimiters from phone number
        this.form.cellphone = this.form.cellphone.replace(/\D+/g, '').trim()

        this.$updateloading(1)
        if (this.form.venmohandle) {
          // check venmo handle URL
          await UserService.verifyVenmoHandle(this.form.venmohandle).then(
            async (response) => {
              if (!response.data.result) {
                this.$notify({
                  group: 'notices',
                  title: 'Invalid Venmo handle!',
                  text: response.data.message,
                  type: 'warn'
                })
                this.errors.venmohandle = response.data.message
                this.invalidVenmoHandle = this.form.venmohandle
                this.$updateloading(-1)
                return false
              } else {
                this.invalidVenmoHandle = null
                await UserService.register(this.form).then(
                  (response) => {
                    window.gtag('event', 'conversion', { send_to: 'AW-314576738/wbwWCLmJxOsCEOKegJYB' })
                    window.fbq('track', 'CompleteRegistration', {
                      content_name: 'registration',
                      value: response.data.info.id,
                      status: 'complete'
                    })
                    this.$updateloading(-1)
                    this.$emit('updatelogin')
                    this.$router.push('/verifyphone', function () {
                      this.$notify({
                        group: 'notices',
                        title: 'Registration Successful',
                        text: 'You have successfully registered.',
                        type: 'success'
                      })
                    })
                    // on successful account creation
                    this.$gtag.event('create', {
                      event_category: 'account',
                      event_label: 'created',
                      value: this.form
                    })
                    this.$updateloading(-1)
                  },
                  (error) => {
                    this.$updateloading(-1)
                    var msg = null
                    Object.keys(error.response.data.errors).forEach(key => {
                      if (!msg) {
                        msg = error.response.data.errors[key]
                      }
                      this.errors[key] = error.response.data.errors[key]
                    })
                    this.sending = false
                    this.$notify({
                      group: 'errors',
                      title: 'User Registration Failed',
                      text: error.response.data.error + ' ' + msg,
                      type: 'warn'
                    })
                  }
                )
              }
            },
            (error) => {
              console.dir(error)
              return false
            }
          )
        } else {
          await UserService.register(this.form).then(
            (response) => {
              window.gtag('event', 'conversion', { send_to: 'AW-314576738/wbwWCLmJxOsCEOKegJYB' })
              this.$updateloading(-1)
              this.$emit('updatelogin')
              this.$router.push('/verifyphone', () => {
                this.$notify({
                  group: 'notices',
                  title: 'Registration Successful',
                  text: 'You have successfully registered.',
                  type: 'success'
                })
              })
              this.$updateloading(-1)
            },
            (error) => {
              this.$updateloading(-1)
              var msg = null
              Object.keys(error.response.data.errors).forEach(key => {
                if (!msg) {
                  msg = error.response.data.errors[key]
                }
                this.errors[key] = error.response.data.errors[key]
                this.$v.form[key].$touch()
              })
              this.sending = false
              this.$notify({
                group: 'errors',
                title: 'User Registration Failed',
                text: error.response.data.error + ' ' + msg,
                type: 'warn'
              })
              console.dir(this.errors)
              this.$v.$touch()
            }
          )
        }
      }
    },
    closeIntro () {
      this.intro = !this.intro
      window.scrollTo(0, 0)
    }
  }
}
</script>
