var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("path", {
    attrs: {
      d:
        "M249.91,252.45l1.1-.54c2,0,4.09,0,6.22,0a7.42,7.42,0,0,0,4.7-1.77l2.6-2.07a1.77,1.77,0,0,1,1.61.47v4.88l1.3,2-1.22,1.92v4.89l-1.23.62c-1.8-1.35-3.38-3-5.82-3.88h-2.64v1.66l1,2-.45.93h-3.78a14.46,14.46,0,0,1-.7-2.17,13.56,13.56,0,0,1,0-2.3c-.56-.38-1.29.11-1.79-.52a5.48,5.48,0,0,0-1.37-.89C249.67,255.85,249.12,254.11,249.91,252.45Zm14.1-1c-1.95,1.28-3.9,2.51-6.33,2.49v2.66l2.82.58,3.56,2.17A36.46,36.46,0,0,0,264,251.42Z",
      transform: "translate(-249.44 -248.07)"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }