var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tournament && _vm.contest
    ? _c(
        "div",
        [
          _c("TournamentBracket", {
            attrs: {
              tournament: _vm.tournament,
              user_id: _vm.user_id,
              "user-only": _vm.userOnly,
              admin: _vm.admin,
              group_id: _vm.group_id
            },
            on: {
              refresh: function($event) {
                return _vm.$emit("refresh")
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }