var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.display && !_vm.fakegroup
      ? _c(
          "div",
          { staticClass: "center-flex", staticStyle: { height: "80vh" } },
          [
            !_vm.display
              ? _c("v-progress-circular", {
                  attrs: { size: 70, indeterminate: "", color: "red" }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.display || _vm.fakegroup,
            expression: "display || fakegroup"
          }
        ]
      },
      [
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { staticClass: "py-0", attrs: { cols: "12" } },
              [
                _c("back", {
                  staticClass: "pull-left pr-3",
                  attrs: { text: "", emit: "goBack" },
                  on: {
                    goBack: function($event) {
                      _vm.$router.push(
                        _vm.totalGroups > 1
                          ? "/dashboard/group"
                          : "/dashboard/schedule"
                      )
                    }
                  }
                }),
                _c("h1", [
                  _vm._v(_vm._s(_vm.groupname) + " "),
                  _c("span", { staticClass: "sub-text" }, [
                    _vm._v("(" + _vm._s(_vm.adminname) + ")")
                  ])
                ])
              ],
              1
            )
          ],
          1
        ),
        _vm.contests && _vm.contests.length > 0
          ? _c(
              "v-row",
              { staticClass: "mt-0 mb-5" },
              [
                _c("v-col", { staticClass: "py-0" }, [
                  _c(
                    "div",
                    {
                      staticClass: "red-btn expander",
                      staticStyle: { width: "100%" },
                      on: {
                        click: function($event) {
                          _vm.showContests = !_vm.showContests
                        }
                      }
                    },
                    [
                      _vm._v(" Contests"),
                      _c(
                        "v-icon",
                        { staticClass: "icon", attrs: { color: "white" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.showContests
                                ? "arrow_drop_down"
                                : "arrow_drop_up"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.showContests
                    ? _c(
                        "div",
                        { staticClass: "p-3" },
                        _vm._l(_vm.contests, function(c) {
                          return _c(
                            "v-row",
                            { key: c.id, attrs: { align: "center" } },
                            [
                              _c("v-col", { staticClass: "grow" }, [
                                _c("p", { staticClass: "mb-0" }, [
                                  _c("strong", [_vm._v(_vm._s(c.name) + ":")]),
                                  _vm._v(
                                    " $" + _vm._s(c.entryfee) + " entry fee"
                                  ),
                                  _c("br"),
                                  _vm._v(" Currently " + _vm._s(c.entries)),
                                  c.maximum
                                    ? _c("span", [
                                        _vm._v(" of " + _vm._s(c.maximum))
                                      ])
                                    : _vm._e(),
                                  _vm._v(" entries ")
                                ]),
                                _c("p", { staticClass: "mb-0" }, [
                                  _vm._v(_vm._s(c.summary))
                                ])
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "shrink",
                                  attrs: { align: "right" }
                                },
                                [
                                  _c("v-btn", {
                                    staticClass: "text-center",
                                    staticStyle: {
                                      "padding-top": "7px !important",
                                      "padding-bottom": "7px !important"
                                    },
                                    attrs: {
                                      color: "primary",
                                      to:
                                        "/group/" +
                                        _vm.group_id +
                                        "/contest/" +
                                        c.id +
                                        (c.myentries > 0 ? "" : "/enter")
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        c.myentries > 0
                                          ? "Entry Confirmed<br>Go to Details"
                                          : "Tap/Click to Enter"
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.contestsavailable && _vm.contestsavailable.length > 0
          ? _c(
              "v-row",
              { staticClass: "mt-0 mb-5" },
              [
                _c("v-col", { staticClass: "py-0" }, [
                  _c(
                    "div",
                    {
                      staticClass: "red-btn expander",
                      staticStyle: { width: "100%" },
                      on: {
                        click: function($event) {
                          _vm.showContestsAvailable = !_vm.showContestsAvailable
                        }
                      }
                    },
                    [
                      _vm._v(" Contests Available"),
                      _c(
                        "v-icon",
                        { staticClass: "icon", attrs: { color: "white" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.showContestsAvailable
                                ? "arrow_drop_down"
                                : "arrow_drop_up"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.showContestsAvailable
                    ? _c(
                        "div",
                        { staticClass: "p-3" },
                        _vm._l(_vm.contestsavailable, function(ct) {
                          return _c(
                            "v-row",
                            { key: ct.id, attrs: { align: "center" } },
                            [
                              _c("v-col", { staticClass: "grow" }, [
                                _c("p", { staticClass: "mb-0" }, [
                                  _c("strong", [_vm._v(_vm._s(ct.name) + ":")]),
                                  _vm._v(" " + _vm._s(ct.summary))
                                ])
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "shrink",
                                  attrs: { align: "right" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        small: "",
                                        color: "primary",
                                        to:
                                          "/group/" +
                                          _vm.group_id +
                                          "/contesttype/" +
                                          ct.id
                                      }
                                    },
                                    [_vm._v("Create")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          : _vm._e(),
        _c(
          "v-row",
          { staticClass: "my-0" },
          [
            (_vm.wagers && _vm.wagers.length > 0) || _vm.fakegroup
              ? _c(
                  "v-col",
                  { staticClass: "py-0 mb-4", attrs: { cols: "12" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "red-btn expander",
                        staticStyle: { width: "100%" },
                        on: {
                          click: function($event) {
                            _vm.showWagers = !_vm.showWagers
                          }
                        }
                      },
                      [
                        _vm._v(" Wagers"),
                        _c(
                          "v-icon",
                          { staticClass: "icon", attrs: { color: "white" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.showWagers
                                  ? "arrow_drop_down"
                                  : "arrow_drop_up"
                              )
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.showWagers
                      ? _c(
                          "div",
                          { staticClass: "px-3 pt-3" },
                          [
                            _vm.fakegroup
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "my-0 py-0",
                                        on: {
                                          click: function($event) {
                                            return _vm.notLoggedInModal()
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { cols: "12" }
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { staticClass: "my-0 py-0" },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "py-0 vig-card-header-mini",
                                                    attrs: { cols: "12" }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "sub-text pull-left"
                                                      },
                                                      [
                                                        _vm._v("! ! ! "),
                                                        _c("b", [
                                                          _vm._v(
                                                            "EXAMPLE WAGER"
                                                          )
                                                        ]),
                                                        _vm._v(" ! ! !")
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "sub-text pull-right"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_vm.today)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "py-0 vig-card-header-mini text-center",
                                                    attrs: { cols: "12" }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "sub-text text-center"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "+5.5 Minnesota Vikings @ Arizona Cardinals -5.5"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { staticClass: "py-0" },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticClass:
                                                  "offer py-0 my-0 ml-n1",
                                                attrs: {
                                                  align: "center",
                                                  justify: "space-between",
                                                  "no-gutters": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  { staticClass: "pr-2" },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      {
                                                        staticClass: "pa-0 ma-0"
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "pa-0 ma-0",
                                                            attrs: { cols: "8" }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "vigzero-btn team robo offer-btn grey-btn",
                                                                staticStyle: {
                                                                  display:
                                                                    "flex"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    staticClass:
                                                                      "ma-0 pa-0",
                                                                    attrs: {
                                                                      align:
                                                                        "center",
                                                                      justify:
                                                                        "center",
                                                                      "no-gutters":
                                                                        ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Vikings"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0 line-font text-center",
                                                                        attrs: {
                                                                          cols:
                                                                            "2"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "+5.5"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    staticClass:
                                                                      "my-0"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "py-0",
                                                                        attrs: {
                                                                          cols:
                                                                            "12"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "usern"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " VigZero "
                                                                            ),
                                                                            _c(
                                                                              "StarRating",
                                                                              {
                                                                                attrs: {
                                                                                  rating: 5
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-row",
                                                      {
                                                        staticClass: "pa-0 ma-0"
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "pa-0 ma-0",
                                                            attrs: { cols: "8" }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "vigzero-btn team robo offer-btn red-btn",
                                                                staticStyle: {
                                                                  display:
                                                                    "flex"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    staticClass:
                                                                      "ma-0",
                                                                    attrs: {
                                                                      align:
                                                                        "center",
                                                                      justify:
                                                                        "center",
                                                                      "no-gutters":
                                                                        ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "py-0"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-row",
                                                                          {
                                                                            staticClass:
                                                                              "ma-0",
                                                                            attrs: {
                                                                              justify:
                                                                                "center"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  "pa-0",
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "strong",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Cardinals "
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            staticClass:
                                                                                              "icon white--text"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "favorite"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "line-font text-center pa-0 ma-0",
                                                                        attrs: {
                                                                          cols:
                                                                            "2"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "-5.5"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "text-center pa-0 ma-0",
                                                            attrs: {
                                                              cols: "4",
                                                              "align-self":
                                                                "center"
                                                            }
                                                          },
                                                          [
                                                            _c("div", [
                                                              _c(
                                                                "h1",
                                                                {
                                                                  staticClass:
                                                                    "cash my-0 py-0 text-right",
                                                                  staticStyle: {
                                                                    "line-height":
                                                                      "0.9em"
                                                                  }
                                                                },
                                                                [_vm._v("$10")]
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c("offer-list", {
                                  attrs: {
                                    offers: _vm.wagers,
                                    section: "group_" + _vm.group_id
                                  }
                                })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "v-row",
          { staticClass: "my-0" },
          [
            _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
              _c(
                "div",
                {
                  staticClass: "red-btn expander",
                  staticStyle: { width: "100%" },
                  on: {
                    click: function($event) {
                      _vm.showMessages = !_vm.showMessages
                    }
                  }
                },
                [
                  _vm._v(" Group Messaging"),
                  _c(
                    "v-icon",
                    { staticClass: "icon", attrs: { color: "white" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.showMessages ? "arrow_drop_down" : "arrow_drop_up"
                        )
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showMessages,
                      expression: "showMessages"
                    }
                  ],
                  staticClass: "pt-3"
                },
                [
                  _c("group-messages", {
                    attrs: { friendgroupId: _vm.group_id, demo: _vm.fakegroup },
                    on: {
                      loaded: function($event) {
                        _vm.messagesLoaded = true
                      }
                    }
                  })
                ],
                1
              )
            ])
          ],
          1
        ),
        _vm.groupObject.allowmemberinvite
          ? _c(
              "v-row",
              [
                _c("v-col", [
                  _c(
                    "div",
                    {
                      staticClass: "red-btn expander",
                      staticStyle: { width: "100%" },
                      on: {
                        click: function($event) {
                          _vm.showInvite = !_vm.showInvite
                        }
                      }
                    },
                    [
                      _vm._v(" Invite members"),
                      _c(
                        "v-icon",
                        { staticClass: "icon", attrs: { color: "white" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.showInvite
                                ? "arrow_drop_down"
                                : "arrow_drop_up"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showInvite,
                          expression: "showInvite"
                        }
                      ],
                      staticClass: "pt-3"
                    },
                    [
                      _vm.groupObject && _vm.members
                        ? _c("GroupInvite", {
                            attrs: {
                              group: _vm.groupObject,
                              members: _vm.members
                            },
                            on: { refresh: _vm.init }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }