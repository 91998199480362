<template>
  <div class="container">
    <h1 class="text-center">Page Not Found</h1>
    <p class="text-center">The page you requested does not exist. Please go back, or select an item from the menu.</p>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>
