<style scoped>
</style>
<template>
  <v-container class="pa-0">
    <v-row
      v-if="isadmin"
      class="ma-0"
      align="center"
    >
      <v-col class="pa-0 pr-1" cols="9">
        <v-select
          v-model="searchData.filters.disputetype_id"
          label="Dispute Type"
          :items="disputetypes"
          item-text="name"
          item-value="id"
          dense
        ></v-select>
        <v-select
          v-model="searchData.filters.disputestatus_id"
          :items="disputestatuses"
          label="Status"
          item-text="name"
          item-value="id"
          required
          dense
        ></v-select>
      </v-col>

      <v-col class="py-0 text-right" cols="3">
        <v-btn
          @click="search"
          dense
          color="primary"
        >Search</v-btn>
      </v-col>
    </v-row>

    <v-divider
      v-if="isadmin"
      class="py-2"
    ></v-divider>

    <v-row class="">
      <v-col
        cols="12"
        class="py-0"
      >
        <DisputeList :disputes="disputes"></DisputeList>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserService from '@/services/UserService'
import DisputeService from '@/services/DisputeService'
import DisputeList from '@/components/disputes/widgets/List'

export default {
  name: 'DisputeSearch',
  components: {
    DisputeList
  },
  data: function () {
    return {
      disputestatuses: [],
      disputetypes: [],
      disputes: [],
      searchData: {
        filters: {
          disputetype_id: null,
          disputestatus_id: null
        },
        page: 1,
        max: 10,
        sort: null
      }
    }
  },
  mounted: function () {
    this.init()
  },
  computed: {
    isadmin: function () {
      return UserService.isAdmin()
    }
  },
  methods: {
    async init () {
      this.$updateloading(1)
      DisputeService.init(this.searchData.filters, this.searchData.max, this.searchData.page, this.searchData.sort).then(response => {
        this.disputestatuses = response.data.disputestatuses
        this.disputetypes = response.data.disputetypes
        this.disputes = this.formatDisputes(response.data.disputes)
        this.$updateloading(-1)
      }).catch(errors => {
        this.$updateloading(-1)
        console.dir(errors)
      })
    },
    async search () {
      this.$updateloading(1)
      DisputeService.search(this.searchData.filters, this.searchData.max, this.searchData.page, this.searchData.sort).then(response => {
        this.disputes = this.formatDisputes(response.data)
        this.$updateloading(-1)
      }).catch(errors => {
        this.$updateloading(-1)
        console.dir(errors)
      })
    },
    formatDisputes (disputes) {
      if (!disputes) {
        disputes = []
      }
      if (disputes.length === 1) {
        disputes[0].expand = true
      } else {
        disputes.forEach(d => {
          d.expand = false
        })
      }
      return disputes
    }
  }
}
</script>
