
<template>
  <v-container class="mx-0 px-1">
    <v-row class="my-0 py-0">
      <v-col
        cols="12"
        class="py-0 container"
        v-if="showCreate && game"
      >
        <WagerCreate
          :game="game"
          :team_id="team_id"
          :type="type"
          :betlimit="betlimit"
        ></WagerCreate>
      </v-col>
    </v-row>

    <v-row class="my-0 py-0">
      <v-col
        class="py-0"
        v-if="!showCreate && game && wagers"
      >
        <v-row
          class="mt-0 mb-3 py-0"
          v-if="showHeading"
        >
          <v-col
            class="py-0 px-1"
            :class="(wagerIsValid) ? 'text-right' : 'text-center'"
            align-self="center"
          >
            <span
              class="selectbet robo"
              v-show="showHeading && hasPaymentMethod"
            >Select Bet(s)<span v-if="wagerIsValid"> or</span>
            </span>
          </v-col>
          <v-col
            class="py-0"
            v-if="wagerIsValid"
          >
            <button
              class="vigzero-btn blue-btn robo"
              v-show="showHeading"
              @click.prevent="openCreate"
            >Create New Bet</button>
          </v-col>
        </v-row>

        <WagerList
          :wagers="wagers.results"
          :game="game"
          :team_id="team_id"
          :type="type"
          :betlimit="betlimit"
          :section="section"
          @wager-selected="updateSelected"
          @wager-reload="reloadWagers"
        ></WagerList>
      </v-col>
    </v-row>

    <v-row
      class="my-0 pb-0 pt-4"
      justify="space-around"
      v-if="game"
    >
      <v-col
        cols="4"
        class="py-0 mx-auto"
      >
        <back
          :emit="'back'"
          @back="goBack"
          text="Back"
        ></back>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserService from '@/services/UserService'
import GameService from '@/services/GameService'
import WagerService from '@/services/WagerService'
import WagerList from '@/components/wagers/List'
import WagerCreate from '@/components/wagers/Create'
import Back from '@/components/widget/Back'

export default {
  name: 'Wager',
  components: {
    WagerList,
    WagerCreate,
    Back
  },
  props: {
    game_id: {
      type: [Number, String],
      default: 0
    },
    team_id: {
      type: [Number, String],
      default: 0
    },
    type: {
      type: String,
      default: ''
    },
    wager_id: {
      type: [Number, String],
      default: 0
    },
    section: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      showCreate: false,
      showHeading: true,
      betlimit: 0,
      selectedWagerAmount: 0,
      game: null,
      wagers: {
        results: [],
        filters: {},
        totalResults: 0,
        perpage: 1000,
        sort: [{ column: 'linediff', direction: 'DESC' }],
        page: 1,
        totalPages: 0
      },
      backToWagers: false,
      returnTo: this.$route.query.returnTo || 'default'
    }
  },
  created () {
    // Ensure partialamount is initialized before rendering
    this.wagers.results.forEach(wager => {
      wager.partialamount = wager.takenbyamount
    })
  },
  mounted: function () {
    this.init()
    // this.wagers.results.forEach(wager => {
    //   wager.partialamount = wager.takenbyamount
    // })
  },
  methods: {
    async init () {
      await UserService.refreshAuthData(false)
      this.betlimit = UserService.getAvailableLimit()
      if (this.game_id > 0) {
        this.$updateloading(1)
        await GameService.get(this.game_id).then((response) => {
          this.$updateloading(-1)
          this.game = response.data
          if (this.game.gamestatus !== 'Upcoming') {
            this.$router.push('/dashboard/schedule', () => {
              this.$notify({
                group: 'errors',
                title: 'Game ' + this.game.gamestatus,
                text: 'This game is ' + this.game.gamestatus + '. No more wagers are allowed.',
                type: 'warn'
              })
            })
          }
        }).catch(errors => {
          this.$updateloading(-1)
          console.dir(errors)
        })
        if (UserService.isTrusted() || UserService.isTrustedRating()) {
          this.loadWagers()
        } else {
          this.showCreate = true
        }
      }
    },

    async loadWagers () {
      this.wagers.filters.game_id = this.game_id
      this.wagers.filters.nottaken = true
      this.wagers.filters.notuser = true
      this.wagers.filters.visibility = 'open'
      if (this.team_id) {
        this.wagers.filters.team_id = this.team_id
      }
      if (this.type) {
        if (this.type === 'money') {
          this.wagers.filters.bettype_id = 10
        } else if (this.type === 'line') {
          this.wagers.filters.bettype_id = 30
        } else {
          this.wagers.filters.bettype_id = 20
        }
      }
      await WagerService.search(this.wagers.filters, this.wagers.perpage, this.wagers.page, this.wagers.sort).then((response) => {
        this.$updateloading(-1)
        this.wagers = response.data
        if (this.wagers.totalResults === 0) {
          if (this.wagerIsValid) {
            this.showCreate = true
          } else {
            // this wager is invalid not accepting any bets currently, redirect to schedule
            this.$router.push('/dashboard/schedule', () => {
              this.$notify({
                group: 'errors',
                title: 'Line Out of Range',
                text: 'The money line spread on this game is out of our acceptable range.',
                type: 'warn'
              })
            })
          }
        } else if (this.wager_id) {
          // auto-select the given wager
          const wagerId = parseInt(this.wager_id) || 0
          for (var i = 0, l = this.wagers.results.length; i < l; i++) {
            if (this.wagers.results[i].id === wagerId) {
              this.wagers.results[i].selected = true
              this.showHeading = false
              i = l
            }
          }
        }
      }).catch(errors => {
        this.$updateloading(-1)
      })
    },
    updateSelected (e) {
      this.showHeading = (e <= 0)
      this.selectedWagerAmount = e
    },
    async reloadWagers (selectedWagers) {
      this.showHeading = true
      await this.loadWagers()
      // reselect the wagers
      if (selectedWagers && selectedWagers.length > 0 && this.wagers && this.wagers.results.length > 0) {
        selectedWagers.forEach((w) => {
          for (var i = 0, l = this.wagers.results.length; i < l; i++) {
            if (this.wagers.results[i].id === w.id) {
              this.wagers.results[i].selected = true
              this.showHeading = false
              i = l
            }
          }
        })
      }
    },
    openCreate: function () {
      this.showCreate = true
      this.backToWagers = true
    },
    goBack: function () {
      if (this.returnTo !== 'default') {
        this.$router.push(this.returnTo)
        return
      }
      if (this.backToWagers) {
        this.backToWagers = false
        this.showCreate = false
      } else {
        if (this.section.includes('contest_')) {
          const tmp = this.section.replace('contest_', '').split('_')
          this.$router.push('/group/' + tmp[0] + '/contest/' + tmp[1])
        } else if (this.section.includes('group')) {
          this.$router.push('/group/' + this.section.replace('group_', ''))
        } else if (this.wager_id) {
          this.$router.push('/dashboard/' + this.section + '/' + this.wager_id)
        } else {
          this.$router.push('/dashboard/schedule/' + this.game.id)
        }
      }
    }
  },
  computed: {
    hasPaymentMethod: function () {
      const user = UserService.getUserObject()
      if (!user) {
        return true
      }
      var paymentmethods = user.paymentmethod
      if (paymentmethods.length > 0) {
        return paymentmethods[0].details[0].value !== ''
      } else {
        return false
      }
    },

    wagerIsValid () {
      if (this.type === 'money') {
        return (this.game.money_line <= this.game.max_money_line && this.game.money_line >= this.game.max_money_line * -1)
      }
      return true
    }
  }
}
</script>
