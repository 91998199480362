<style scoped>
.fixed {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  transform: translateY(-8px);
}

@media screen and (min-width: 601px) {
  .fix {
    transform: translateY(-40px);
  }
}

@media screen and (max-width: 345px) {
  .v-input >>> label {
    font-size: 3.7vw;
  }
}
</style>
<template>
  <div>
    <div
      id="scheduleFilter"
      class="my-0 fixed"
    >
      <v-row
        justify="space-between"
        class="my-0 py-0 px-4"
      >
        <v-col
          cols="6"
          class="py-0 px-0 mr-n4"
        >
          <!-- <v-select
            @input="triggerSearch"
            v-model="searchData.filters.leagueId"
            label="League"
            :items="leagues"
            item-text="display"
            item-value="id"
            single-line
            :disabled="loading"
          ></v-select> -->
          <v-select
            v-model="searchData.filters.leagues"
            :items="leagues"
            item-text="display"
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            label="League"
            multiple
            single-line
            hide-details
            :disabled="loading"
            ref="leagues"
            @change="validateLeagueFilters"
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="searchData.filters.leagues.length === 1">{{ item.display }}</span>
              <span v-if="searchData.filters.leagues.length > 1 && index === 0">Multiple Leagues ({{ searchData.filters.leagues.length }})</span>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="5"
          class="py-0 px-0 mr-n5"
        >
          <v-switch
            v-model="searchData.filters.favorite"
            @change="triggerSearch"
            label="My Teams"
            class="py-0 mt-1"
            :disabled="loading"
          >
          </v-switch>
          <v-switch
            v-model="searchData.filters.available"
            @change="triggerSearch"
            label="Open Offers"
            class="py-0 mt-n5 mb-n2"
            :disabled="loading"
          ></v-switch>
        </v-col>
        <v-col
          cols="1"
          class="py-3 px-0 ml-n7"
        >
          <v-dialog v-model="myteamhelper">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="black"
              >
                <v-icon>help</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text class="pt-3">
                Use selector switches to turn ON/OFF filtering of your
                favorite teams and to view only games with offers
                available.<br /><br />
                Favorites are determined in two ways:<br /><br />
                <v-icon color="red">favorite</v-icon> A heart indicates a
                team you have directly set to be your favorites. A favorite
                can be set (or unset) while making a wager on a team by
                clicking on the heart next to it.<br /><br />
                <v-icon color="red">flag</v-icon> A flag indicates one of
                the top three teams you have won with.
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  @click="myteamhelper = false"
                > Got it </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
    <div class="px-1">
      <!-- margin-top:60px -->
      <GameList
        :games="games"
        class="px-3"
        id="gamelist"
        v-scroll:#dashboard-content="onScroll"
      ></GameList>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import GameService from '@/services/GameService'
import GameList from '@/components/games/List'
import { bus } from '@/main'
// import Pagination from '@/components/widget/Pagination'

export default {
  name: 'Schedule',
  components: {
    GameList
    // Pagination
  },
  data: function () {
    return {
      loading: true,
      refreshtimer: null,
      searched: [],
      searchData: {
        filters: {
          leagueId: 0,
          leagues: [0],
          userId: null,
          favorite: false,
          available: false,
          week: true
        },
        perpage: 0,
        page: 1,
        sort: null,
        pages: 0,
        total: 0,
        nextPage: 1
      },
      games: [],
      leagues: [],
      teamoptions: [
        { name: 'All Teams', value: false },
        { name: 'Favorites', value: true }
      ],
      searchTimeout: null,
      myteamhelper: false,
      scrollTop: 0,
      screenheight: 0,
      previousLeagueFilters: []
    }
  },
  props: {
    gamescroll: String
  },
  created: function () {
    // listen for bus event that gives us the scroll location
    // set that data as the value of our 'scrollTop' variable that we are watching
    bus.$on('schedule_scroll', (data) => {
      this.scrollTop = data
    })

    bus.$on('reloadpage', (data) => {
      var list = document.getElementById('dashboard-content')
      if (list) {
        list.scrollTop = 0
      }
      this.triggerSearch()
    })
    this.startRefreshTimer()

    this.screenheight = window.innerHeight
    window.addEventListener('resize', () => { this.screenheight = window.innerHeight })
  },
  destroyed: function () {
    this.clearRefreshTimer()
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init () {
      this.$updateloading(1)
      this.searchData.filters.leagues = GameService.getLeagueFilters()
      this.previousLeagueFilters = GameService.getLeagueFilters()
      this.searchData.filters.leagueId = GameService.getLeagueFilter()
      this.searchData.filters.favorite = GameService.getTeamFilter()
      this.searchData.filters.available = GameService.getAvailableFilter()
      const user = UserService.getUser()
      if (user) {
        this.searchData.filters.userId = +user.id
      }

      GameService.init().then((response) => {
        this.$updateloading(-1)
        this.leagues = [{
          name: '',
          sport: '',
          display: 'All Leagues',
          id: 0
        }]
        var hasInactiveOptions = false
        response.data.leagues.forEach((el, idx) => {
          if (!el.divider) {
            el.display = el.name + ' - ' + el.sport
          }
          if (el.disabled) {
            if (!hasInactiveOptions) {
              hasInactiveOptions = true
              this.leagues.push({ divider: true })
            }
          }
          this.leagues.push(el)
        })
        this.loading = false
        this.search()
      }).catch(errors => {
        this.$updateloading(-1)
        console.dir(errors)
        this.loading = false
      })
    },

    async validateLeagueFilters (selection) {
      let selected = null
      let added = false
      // BS to see what actually changed since 'selection' is just the entire leagues filter array
      if (this.previousLeagueFilters.length < selection.length) {
        selected = selection.filter(x => !this.previousLeagueFilters.includes(x))[0]
        added = true
      } else if (this.previousLeagueFilters.length > selection.length) {
        selected = this.previousLeagueFilters.filter(x => !selection.includes(x))[0]
      }
      this.previousLeagueFilters = selection
      if (selected === 0 && added) {
        // All Leagues filter selected, clear all others
        this.searchData.filters.leagues = [0]
        this.previousLeagueFilters = this.searchData.filters.leagues
      } else if (selected === 0) {
        this.searchData.filters.leagues = [0]
        this.previousLeagueFilters = this.searchData.filters.leagues
      } else if (this.hasAllLeaguesFilter) {
        // All leagues filter selected, but separate sport selected, remove the 'All Leagues' filter
        const lfs = []
        this.searchData.filters.leagues.forEach((l) => {
          if (l !== 0) {
            lfs.push(l)
          }
        })
        this.searchData.filters.leagues = lfs
        this.previousLeagueFilters = lfs
      }
      this.triggerSearch()
    },

    triggerSearch (delay) {
      if (this.loading) {
        return false
      }
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      bus.$emit('clear_schedule_scroll')
      this.searchTimeout = setTimeout(() => {
        /* var list = document.getElementById('dashboard-content')
        if (list) {
          list.scrollTop = 0
        } */
        this.search()
      }, (delay) ? parseInt(delay) : 800)
    },

    search () {
      this.$updateloading(1)
      GameService.setLeagueFilter(this.searchData.filters.leagueId)
      GameService.setLeagueFilters(this.searchData.filters.leagues)
      GameService.setTeamFilter(this.searchData.filters.favorite)
      GameService.setAvailableFilter(this.searchData.filters.available)
      GameService.search(this.searchData.filters, this.searchData.perpage, this.searchData.page, this.searchData.sort).then((response) => {
        this.games = []
        response.data.results.forEach(d => {
          d.showdetail = false
        })
        this.searchData.page = response.data.page
        this.searchData.pages = response.data.totalPages
        this.searchData.total = response.data.totalResults
        this.searchData.nextPage = response.data.nextPage
        this.games = response.data.results
      }).catch(errors => {
        console.dir(errors)
      }).finally(() => {
        /*
          after searching, make a call to the default layout to request
          the variable that holds the scroll location.
          (retrival of this variable is done in the create method of this function)
        */
        bus.$emit('get_schedule_scroll')
        this.$updateloading(-1)
      })
    },
    startRefreshTimer () {
      // set the timer to run every 5 minutes
      this.clearRefreshTimer()
      this.refreshtimer = setInterval(this.triggerSearch, 30 * 1 * 1000)
    },
    clearRefreshTimer () {
      if (this.refreshtimer) {
        clearInterval(this.refreshtimer)
      }
    },
    onScroll: function (e) {
      bus.$emit('set_schedule_scroll', e.target.scrollTop)
    }
  },
  watch: {
    // watch to see change in variable that decides scroll location
    // if variable changes, scroll to that location
    scrollTop: function (n, o) {
      if (n !== o && n) {
        // check to make sure that the dashboard-content exists before trying to scroll it
        var list = document.getElementById('dashboard-content')
        if (list) {
          list.scrollTop = this.scrollTop
        }
      }
    },

    // close league filter multi select on new selection
    'searchData.filters.leagues': function (n, o) {
      setTimeout(() => {
        this.$refs.leagues.isMenuActive = false
      }, 50)
    }
  },
  filters: {
    leagueName: function (val) {
      if (val !== '') {
        return val + ' - '
      }
    }
  },
  computed: {
    hasAllLeaguesFilter: function () {
      return this.searchData.filters.leagues.includes(0)
    }
  }
}
</script>
