var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "my-0 py-0" },
    [
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "12" } },
        [
          _vm.wagers && _vm.wagers.length > 0
            ? _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: { headers: _vm.headers, items: _vm.wagers },
                on: { "click:row": _vm.navigateToWager },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.id",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [_vm._v(_vm._s(item.id))]),
                          _c("br"),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        item.ishonor === 1
                                          ? _c(
                                              "span",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "honor-bet-badge"
                                                  },
                                                  "span",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" HB ")]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_c("span", [_vm._v("This is an Honor Bet")])]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.starttime",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span", [_vm._v(_vm._s(item.starttime))])]
                      }
                    },
                    {
                      key: "item.visitingteam",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _c(
                              "button",
                              { class: { openoffers: item.wagersaway > 0 } },
                              [_vm._v(" " + _vm._s(item.visitingteam) + " ")]
                            )
                          ]),
                          _c("br"),
                          item.wagershome > 0
                            ? _c("span", [_vm._v(" " + _vm._s(item.nickname))])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.hometeam",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _c(
                              "button",
                              { class: { openoffers: item.wagershome > 0 } },
                              [_vm._v(_vm._s(item.hometeam))]
                            )
                          ]),
                          _c("br"),
                          item.wagersaway > 0
                            ? _c("span", [_vm._v(" " + _vm._s(item.nickname))])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.offeredline",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(item.offeredline) +
                                " (" +
                                _vm._s(item.currentline) +
                                ")"
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.offeredbyamount",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.formatCurrency(item.offeredbyamount))
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3232809328
                )
              })
            : _c(
                "h3",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loading,
                      expression: "!loading"
                    }
                  ],
                  staticClass: "text-center"
                },
                [_vm._v("No Open offers available.")]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }