<template>
  <v-dialog
    v-model="showDialog"
    persistent
    max-width="320"
  >
    <v-card>
      <v-card-title class="headline">
        Entry Fee Paid?
      </v-card-title>
      <v-card-text>
        <div v-if="payment.paid">
          <p>{{ payment.nickname }}'s entry fee has been paid.</p>
          <p>Do you want to mark this entry fee as NOT paid?</p>
        </div>
        <div v-else>
          <p>{{ payment.nickname }}'s entry fee has NOT been paid.</p>
          <p>Do you want to mark this entry fee as paid?</p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :color="(payment.paid) ? 'error' : 'success'"
          raised
          @click="togglePayment"
          :disabled="loading"
        >
          <span v-if="payment.paid">Mark Unpaid</span>
          <span v-else>Mark Paid</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          raised
          @click="$emit('cancel')"
          :disabled="loading"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ContestService from '@/services/ContestService'

export default {
  name: 'PaymentDialog',

  props: {
    payment: Object
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    showDialog: function () {
      return (this.payment && this.payment.id > 0)
    }
  },

  methods: {
    async togglePayment () {
      if (this.loading || !this.payment) {
        return false
      }
      this.loading = true
      ContestService.toggleEntryPayment(this.payment).then(
        (response) => {
          this.loading = false
          this.$emit('toggle')
        },
        (err) => {
          var error = err.response.data
          this.loading = false
          this.$notify({
            group: 'errors',
            title: error.errors[0],
            text: error.error,
            type: 'warn'
          })
        }
      )
    }
  }
}
</script>
