import axios from 'axios'
import Vue from 'vue'
import Notifications from 'vue-notification'
import UserService from '@/services/UserService'

Vue.use(Notifications)

const FavoriteService = {

  async getAll () {
    await UserService.authHeader(false)
    return axios({
      url: UserService.baseUrl() + 'favorite/all',
      method: 'get'
    })
  },

  async add (teamId) {
    await UserService.authHeader()
    var data = {
      team_id: teamId
    }
    return axios({
      url: UserService.baseUrl() + 'favorite/add',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async remove (teamId) {
    await UserService.authHeader()
    var data = {
      team_id: teamId
    }
    return axios({
      url: UserService.baseUrl() + 'favorite/remove',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async isFavorite (teamId) {
    await UserService.authHeader(false)
    var data = {
      team_id: teamId
    }
    return axios({
      url: UserService.baseUrl() + 'favorite/isfav',
      data: JSON.stringify(data),
      method: 'post'
    })
  }
}

export default FavoriteService
