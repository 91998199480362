<template>
  <div>
    <div>
      <BackButton></BackButton>
    </div>
    <md-card class="card container">
      <md-card-expand>
        <md-card-expand-trigger class="md-layout">
          <md-button class="md-layout-item md-size-100 collapse-button" id="collapseFilters">
            <span class="title">Filters</span><span v-if="filterlength > 0">({{filterlength}})</span> <md-icon class="collapse-arrow" :class="{ 'collapse-arrow-down': arrowdirection }">keyboard_arrow_down</md-icon>
          </md-button>
        </md-card-expand-trigger>
        <md-card-expand-content>
          <md-card-content>
            <div class="md-layout md-alignment-center-center md-gutter">
              <div class="md-layout-item md-size-90">
                <md-field>
                  <label for="league">League</label>
                  <md-select v-model="filters.league" name="league" id="league">
                    <md-option v-for="l in leagues" :key="l.id" :value="l.id">{{ l | leaguelable }}</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-90">
                <div class="md-layout md-alignment-center-space-between">
                  <div class="md-layout-item md-size-100">
                    <md-autocomplete v-model="filters.team" :md-options="teams" @md-changed="getTeams" @md-opened="getTeams">
                      <label>Team</label>
                      <template slot="md-autocomplete-item" slot-scope="{ item }">{{ item.name }}</template>
                    </md-autocomplete>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-90">
                <div class="md-layout md-alignment-center-center">
                  <div class="sub-text">Offer Amount</div>
                </div>
                <div class="md-layout md-alignment-center-space-between">
                  <div class="md-layout-item md-size-25">
                    <md-field>
                      <label>Min</label>
                      <span class="md-prefix">$</span>
                      <md-input v-model="filters.lowest" class="money-input"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-25">
                    <md-field>
                      <label>Max</label>
                      <span class="md-prefix">$</span>
                      <md-input v-model="filters.highest" class="money-input"></md-input>
                    </md-field>
                  </div>
                  <!-- hiding line for the time being
                  <div class="md-layout-item md-size-50">
                    <div class="md-layout">
                      <div class="md-layout-item md-size-70">
                        <md-field>
                          <label v-if="filters.bettype == 20">Total Points</label>
                          <label v-else>Line</label>
                          <md-input v-model="filters.line" :disabled="filters.side == 'either' && filters.bettype != 20"></md-input>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-30">
                        <md-field>
                          <md-select v-model="filters.linedirection" name="linedirection" id="linedirection" :disabled="filters.side == 'either' && filters.bettype != 20">
                            <md-option value="<">&lt;</md-option>
                            <md-option value="=">=</md-option>
                            <md-option value=">">&gt;</md-option>
                          </md-select>
                        </md-field>
                      </div>
                    </div>
                  </div>
                  -->
                </div>
              </div>
              <div class="md-layout-item md-size-90">
                <md-field :class="{ 'md-invalid': filters.nickname.length > 0 && filters.nickname.length < 4 && filters.nickname != '' }">
                  <label>User</label>
                  <md-input v-model="filters.nickname"></md-input>
                  <span class="md-error">Must be at least 4 characters</span>
                </md-field>
              </div>
            </div>
            <div class="md-layout md-alignment-center-space-around">
              <div class="md-layout-item md-size-5"></div>
              <div class="md-layout-item md-size-15 text-center link">
                <span @click="resetfilters">Reset</span>
              </div>
              <div class="md-layout-item md-size-60 text-center">
                <md-button class="md-accent md-raised" @click="search">Search</md-button>
              </div>
              <div class="md-layout-item md-size-20"></div>
            </div>
          </md-card-content>
        </md-card-expand-content>
      </md-card-expand>
    </md-card>

    <div class="collapseable" :class="{'collapsed': collapsed }">
    </div>
    <div class="scrollable" v-if="offers.length > 0">
      <div class="md-layout">
        <offer-list :offers="offers" @update="acceptoffer()" @acceptOffer="acceptoffer()" class="md-layout-item md-size-100"></offer-list>
      </div>
      <Pagination :page="searchData.page" :nextPage="searchData.nextPage" :totalPages="searchData.pages"
        @updatePage="loadNext($event)"></Pagination>
    </div>
    <div>

    </div>
    <div v-if="offers.length == 0 && searched">
      <div class="md-layout md-alignment-center-center">
        <div class="md-layout-item grey text-center">
          <h1>No Offers Found</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OfferService from '@/services/OfferService'
import UserService from '@/services/UserService'
import OfferList from '@/components/offer/List'
import Pagination from '@/components/widget/Pagination'
import BackButton from '@/components/widget/Back'

export default {
  components: {
    OfferList,
    Pagination,
    BackButton
  },
  data: function () {
    return {
      offers: [],
      filters: {
        league: 0,
        team: { id: 0, toString: () => '' },
        highest: '',
        lowest: '',
        bettype: 0,
        line: '',
        linedirection: '=',
        side: 'either',
        nickname: ''
      },
      searchData: {
        perpage: 15,
        page: 1,
        sort: null,
        pages: 0,
        total: 0,
        nextPage: 1
      },
      leagues: [{ id: 0, name: '' }],
      teams: [],
      bettypes: [{ id: 0, name: '' }],
      loading: false,
      collapsed: false,
      arrowdirection: false,
      searched: false
    }
  },
  created: function () {
    OfferService.offerfilters().then(
      (response) => {
        var filters = response.data
        filters.leagues.forEach((element) => {
          this.$set(this.leagues, this.leagues.length, element)
        })
        filters.offertypes.forEach((element) => {
          this.$set(this.bettypes, this.bettypes.length, element)
        })
      },
      (error) => {
        this.offers = []
        alert(error.response.data.error)
      }
    )
  },
  methods: {
    search: async function () {
      /*
      if (this.filters.side === 'either' && this.filters.bettype !== 20) {
        this.filters.line = ''
        this.filters.linedirection = '='
      }
      */
      if (this.filters.nickname.length > 3 || this.filters.nickname.length === 0) {
        await this.getdata(this.filters)
        document.getElementById('collapseFilters').click()
      }
    },
    arrowclick: function () {
      this.collapsed = !this.collapsed
      this.arrowdirection = !this.arrowdirection
    },
    acceptoffer: function () {
      this.$notify({
        group: 'notices',
        title: 'Offer Accepted',
        text: 'Successfully accepted the offer.',
        type: 'info'
      })
      this.getdata()
    },
    getdata: function (otherFilters) {
      this.$updateloading(1)
      var filters = {
        time: 'future',
        nottaken: true,
        notuser: UserService.getUser().id,
        visibility: 'open'
      }
      if (otherFilters) {
        if (otherFilters.league !== 0) {
          filters.league = otherFilters.league
        }
        if (otherFilters.team.id !== 0) {
          filters.team = otherFilters.team.id
        }
        if (otherFilters.highest !== 0 && !isNaN(this.filters.highest) && this.filters.highest.trim() !== '') filters.maximum = otherFilters.highest.trim()
        if (otherFilters.lowest !== 0 && !isNaN(this.filters.lowest) && this.filters.lowest.trim() !== '') filters.minimum = otherFilters.lowest.trim()
        /*
        if (otherFilters.line !== 0 && !isNaN(this.filters.line) && this.filters.line.trim() !== '') {
          filters.linedirection = otherFilters.linedirection
          filters.line = otherFilters.line.trim()
        }
        if (otherFilters.bettype !== 0) filters.bettype = otherFilters.bettype
        filters.side = otherFilters.side
        */
        if (otherFilters.nickname !== '' && otherFilters.nickname.length > 3) filters.nickname = otherFilters.nickname
      }

      OfferService.getOffers(filters, this.searchData.perpage, this.searchData.page, this.searchData.sort).then(
        (response) => {
          var offers = response.data.results
          this.offers = []
          for (let i = 0; i < offers.length; i++) {
            this.$set(this.offers, i, offers[i])
          }
          this.searchData.page = response.data.page
          this.searchData.nextPage = response.data.nextPage
          this.searchData.pages = response.data.totalPages
          this.searched = true
        },
        (error) => {
          this.offers = []
          alert(error.response.data.error)
        }
      ).finally(() => {
        this.$updateloading(-1)
      })
    },
    getTeams: function (searchTerm) {
      this.teams = new Promise(resolve => {
        window.setTimeout(() => {
          var teamlist = []
          var filters = {
            league: this.filters.league
          }
          OfferService.searchTeams(filters).then(
            (response) => {
              teamlist = response.data

              if (searchTerm && searchTerm.length > 0) {
                const term = searchTerm.toLowerCase()
                teamlist = teamlist.filter(({ name }) => { return name.toLowerCase().includes(term) })
              }
              var list = teamlist.map(item => ({
                id: item.id ? item.id : 0,
                name: item.name ? item.name : '',
                toString: () => item.name ? item.name : ''
              }))
              resolve(list)
            }
          )
        }, 500)
      })
    },
    resetfilters: function () {
      this.filters = {
        league: 0,
        team: { id: 0, toString: () => '' },
        lowest: '',
        highest: '',
        bettype: 0,
        line: '',
        linedirection: '=',
        side: 'either',
        nickname: ''
      }
      this.getdata(this.filters)
    },
    loadNext: function (page) {
      this.searchData.page = page
      this.getdata(this.filters)
    }
  },
  computed: {
    filterlength: function () {
      var filterlength = 0
      if (this.filters.league !== 0) filterlength++
      if (this.filters.team.toString() !== '') filterlength++
      if (this.filters.highest !== 0 && !isNaN(this.filters.highest) && this.filters.highest.trim() !== '') filterlength++
      if (this.filters.lowest !== 0 && !isNaN(this.filters.lowest) && this.filters.lowest.trim() !== '') filterlength++
      if (this.filters.line !== 0 && !isNaN(this.filters.line) && this.filters.line.trim() !== '') filterlength++
      if (this.filters.bettype !== 0) filterlength++
      if (this.filters.nickname !== '') filterlength++
      return filterlength
    }
  },
  mounted: function () {
    document.getElementById('collapseFilters').addEventListener('click', this.arrowclick)
    document.getElementById('collapseFilters').click()
  },
  filters: {
    leaguelable: function (league) {
      if (league.name !== '') {
        return league.name + ' - ' + league.sport
      }
      return ''
    }
  }
}
</script>

<style>
  .money-input {
    min-width: 0 !important;
    text-align:right !important;
  }
  .collapseable {
    transition: 1s;
    overflow-x: hidden !important;
    overflow-y: auto;
    max-height: 0vh;
    z-index: 2;
    position: fixed;
    background: white;
  }
  .collapsed {
    max-height: 90vh;
  }
  .collapse-arrow {
    transition: 0.7s !important;
  }
  .collapse-arrow-up {
    transform: rotate(180deg);
  }
  .text-center {
    align-items: center;
    text-align: center;
  }
  .grey {
    color: rgb(130, 130, 130);
  }
</style>
