var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "button",
                    _vm._g(
                      _vm._b(
                        { staticClass: "vigzero-btn schedule-btn" },
                        "button",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("div", {}, [
                        _vm._v(" " + _vm._s(_vm.invite.name)),
                        _c("br"),
                        _c("span", { staticClass: "sub-text" }, [
                          _vm._v("Owner - " + _vm._s(_vm.invite.owner))
                        ])
                      ])
                    ]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { staticClass: "my-0 py-0", attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "black--text pb-0" }, [
                        _vm._v(
                          "You have been invited by " +
                            _vm._s(_vm.invite.ownername) +
                            " (" +
                            _vm._s(_vm.invite.owner) +
                            ") to join "
                        ),
                        _c("b", [_vm._v(_vm._s(_vm.invite.name))]),
                        _vm._v(". Would you like to join? ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0 py-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-row",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { justify: "center" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "black white--text mx-1",
                                  on: {
                                    click: function($event) {
                                      return _vm.cancel()
                                    }
                                  }
                                },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "background-red white--text mx-1",
                                  on: {
                                    click: function($event) {
                                      return _vm.respond("decline")
                                    }
                                  }
                                },
                                [_vm._v("Decline")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary mx-1",
                                  on: {
                                    click: function($event) {
                                      return _vm.respond("accept")
                                    }
                                  }
                                },
                                [_vm._v("Accept")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }