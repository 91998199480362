import { render, staticRenderFns } from "./Default.vue?vue&type=template&id=c6c5ef5c&scoped=true&"
import script from "./Default.vue?vue&type=script&lang=js&"
export * from "./Default.vue?vue&type=script&lang=js&"
import style0 from "./Default.vue?vue&type=style&index=0&id=c6c5ef5c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6c5ef5c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VAppBar,VAppBarNavIcon,VAvatar,VBtn,VCol,VContainer,VDivider,VIcon,VList,VListItem,VMain,VMenu,VNavigationDrawer,VRow,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/builds/websites/vigzero/vig-zero-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c6c5ef5c')) {
      api.createRecord('c6c5ef5c', component.options)
    } else {
      api.reload('c6c5ef5c', component.options)
    }
    module.hot.accept("./Default.vue?vue&type=template&id=c6c5ef5c&scoped=true&", function () {
      api.rerender('c6c5ef5c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/Default.vue"
export default component.exports