import Vue from 'vue'
import App from './App.vue'
// import VueMaterial from 'vue-material'
// import 'vue-material/dist/vue-material.min.css'
// import 'vue-material/dist/theme/black-green-light.css'
import './registerServiceWorker'
import router from './router'
import Default from './layouts/Default'
import Loader from './services/LoaderOverlay'
import Notifications from 'vue-notification'
import Vue2Filters from 'vue2-filters'
import VueMoment from 'vue-moment'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './directives/aimSort.directive'
import '@mdi/font/css/materialdesignicons.css'
import vuetify from './plugins/vuetify'
import VueGtag from 'vue-gtag'
import UserService from '@/services/UserService'

Vue.config.productionTip = false
// Vue.use(VueMaterial)
Vue.use(Notifications)
Vue.use(Loader)
Vue.use(VueMoment)
Vue.use(Vue2Filters)
Vue.use(VueAxios, axios)
Vue.use(VueGtag, {
  config: {
    id: 'UA-185000243-1'
  }
}, router)
Vue.component('default-layout', Default)

export const bus = new Vue()

axios.interceptors.response.use(
  function (response) {
    // On every successful response, fetch the user balance
    if (!response.config.url.includes('transaction/currentbalancerecurring')) {
      fetchUserBalance()
    }
    return response
  }
)

function fetchUserBalance () {
  const user = JSON.parse(localStorage.getItem('user'))
  const userId = user?.info?.id
  if (userId) {
    // Use UserService to fetch the user balance
    UserService.getUserBalanceRecurringCall(userId)
      .then((response) => {
        const balance = response.data.toFixed(2)
        user.info.VZBalance = balance
        localStorage.setItem('user', JSON.stringify(user))
        // Emit an event to notify components of balance update
        bus.$emit('userBalanceUpdated', balance)
      })
      .catch((err) => {
        console.error('Error fetching user balance:', err)
      })
  }
}

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#vue')
