import axios from 'axios'
import Vue from 'vue'
import Notifications from 'vue-notification'
import UserService from './UserService'
import { bus } from '@/main'

Vue.use(Notifications)

const ChatService = {

  async getMessages () {
    const temp = localStorage.getItem('messages')
    if (temp !== null) {
      return JSON.parse(localStorage.getItem('messages'))
    } else {
      return []
    }
  },

  async getUnreadMessages () {
    await UserService.authHeader()
    await axios({
      url: UserService.baseUrl() + 'chat/unread',
      method: 'get'
    }).then(
      async (response) => {
        var messages = { messages: [], hidden: [], total: 0 }
        var expanded = this.getExpandedWagers()
        if (response.data && response.data.total > 0) {
          response.data.messages.forEach(function (wager) {
            if (wager.wager_id) {
              if (expanded.length === 0 || expanded.indexOf(wager[0].wager_id) === -1) {
                messages.messages.push(wager)
                messages.total += wager.length
              } else if (expanded.indexOf(wager[0].wager_id) !== -1) {
                messages.hidden.push(wager)
              }
            } else {
              messages.messages.push(wager)
              messages.total += wager.length
            }
          })
        }
        if (JSON.stringify(messages) !== localStorage.getItem('messages')) {
          localStorage.setItem('messages', JSON.stringify(messages))
          bus.$emit('messages-updated')
        }
      },
      (err) => {
        console.dir(err)
      }
    ).finally(() => {
      return this.getMessages()
    })
  },

  async getGroupMessages (friendgroupId, page) {
    await UserService.authHeader()
    var data = { friendgroup_id: friendgroupId, page: page }
    return axios({
      url: UserService.baseUrl() + 'chat/group/read',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async sendGroupMessage (friendgroupId, message, replyId) {
    await UserService.authHeader()
    var data = {
      friendgroup_id: friendgroupId,
      message: message,
      reply_id: replyId
    }
    return axios({
      url: UserService.baseUrl() + 'chat/group/send',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async sendWagerMessage (wagerId, message) {
    await UserService.authHeader()
    var data = {
      wager_id: wagerId,
      message: message
    }
    return axios({
      url: UserService.baseUrl() + 'wager/messages/send',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async toggleMuteWager (wagerId, offerer) {
    await UserService.authHeader()
    var data = {
      wager_id: wagerId,
      column: (offerer) ? 'muteofferedby' : 'mutetakenby'
    }
    return axios({
      url: UserService.baseUrl() + 'wager/messages/mute',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async toggleMuteGroup (friendgroupId) {
    await UserService.authHeader()
    var data = {
      friendgroup_id: friendgroupId
    }
    return axios({
      url: UserService.baseUrl() + 'chat/group/mute',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async readMessage (message) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'chat/read',
      data: JSON.stringify(message),
      method: 'post'
    })
  },

  async readWagerMessages (wagerId) {
    await UserService.authHeader()
    var data = {
      wager_id: wagerId
    }
    return axios({
      url: UserService.baseUrl() + 'wager/messages/read',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  getExpandedWagers () {
    const temp = localStorage.getItem('expandedwagers')
    if (temp !== null) {
      return JSON.parse(localStorage.getItem('expandedwagers'))
    } else {
      return []
    }
  },

  async setExpandedWager (wagerId) {
    var wagers = this.getExpandedWagers()
    const idx = wagers.indexOf(wagerId)
    if (idx === -1) {
      wagers.push(wagerId)
      localStorage.setItem('expandedwagers', JSON.stringify(wagers))
    }
  },

  async removeExpandedWager (wagerId) {
    var wagers = this.getExpandedWagers()
    const idx = wagers.indexOf(wagerId)
    if (idx > -1) {
      wagers.splice(idx, 1)
      localStorage.setItem('expandedwagers', JSON.stringify(wagers))
    }
  }

}

export default ChatService
