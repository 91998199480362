import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from '@/views/PageNotFound'
import UserService from '@/services/UserService'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/register',
    name: 'register',
    meta: { layout: 'default', title: 'Register' },
    component: require('@/views/Register').default // load sync
  },
  {
    path: '/verifyphone',
    name: 'verifyphone',
    meta: { layout: 'default', title: 'Phone Verification' },
    component: require('@/views/Verifyphone').default // load sync
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'default', title: 'Login' },
    component: require('@/views/Login').default // load sync
  },
  {
    path: '/about',
    name: 'about',
    meta: { layout: 'default', title: 'About' },
    component: require('@/views/About').default // load sync
  },
  {
    path: '/contact',
    name: 'contact',
    meta: { layout: 'default', title: 'Contact' },
    component: require('@/views/Contact').default // load sync
  },
  {
    path: '/dashboard/:section?/:scrollid?',
    props: true,
    name: 'dashboard',
    meta: { layout: 'default', title: 'Dashboard' },
    component: require('@/views/Dashboard').default // load sync
  },
  {
    path: '/offers',
    name: 'offers',
    meta: { layout: 'default', title: 'Offers' },
    component: require('@/views/SearchOffers').default // load sync
  },
  {
    path: '/wagerhistory',
    name: 'history',
    meta: { layout: 'default', title: 'History' },
    component: require('@/views/WagerHistory').default // load sync
  },
  {
    path: '/transactions',
    name: 'transactions',
    meta: { layout: 'default', title: 'Transactions' },
    component: require('@/views/TransactionHistory').default // load sync
  },
  {
    path: '/makeoffer',
    name: 'makeoffer',
    meta: { layout: 'default', title: 'Make Offer' },
    component: require('@/views/MakeOffer').default // load sync
  },
  {
    path: '/makeoffer/:game_id',
    name: 'makeoffergame',
    props: true,
    meta: { layout: 'default', title: 'Make Offer' },
    component: require('@/views/MakeOffer').default // load sync
  },
  {
    path: '/settings',
    name: 'settings',
    meta: { layout: 'default', title: 'Settings' },
    component: require('@/views/Account').default // load sync
  },
  {
    path: '/account',
    name: 'account',
    meta: { layout: 'default', title: 'Account' },
    component: require('@/components/UserStats').default // load sync
  },
  {
    path: '/schedule',
    name: 'schedule',
    meta: { layout: 'default', title: 'Schedule' },
    component: require('@/views/Schedule').default // load sync
  },
  {
    path: '/groups',
    name: 'groups',
    meta: { layout: 'default', title: 'Groups' },
    component: require('@/views/Groups').default // load sync
  },
  {
    path: '/game/:game_id',
    name: 'game',
    props: true,
    meta: { layout: 'default', title: 'Game' },
    component: require('@/views/Game').default // load sync
  },
  {
    path: '/disputes/',
    name: 'disputes',
    meta: { layout: 'default', title: 'Disputes' },
    component: require('@/views/Dispute').default
  },
  {
    path: '/disputes/:dispute_id',
    name: 'disputereview',
    props: true,
    meta: { layout: 'default', title: 'Review Dispute' },
    component: require('@/views/Dispute').default
  },
  {
    path: '/disputes/create/:wager_id',
    name: 'disputecreate',
    props: true,
    meta: { layout: 'default', title: 'Create Dispute' },
    component: require('@/views/Dispute').default
  },
  {
    path: '/notifications',
    name: 'notifications',
    meta: { layout: 'default', title: 'Notifications' },
    component: require('@/views/Notifications').default // load sync
  },
  {
    path: '/invite',
    name: 'invite',
    meta: { layout: 'default', title: 'Invite' },
    component: require('@/views/Invite').default
  },
  {
    path: '/wager/:game_id/:team_id?/:type?/:wager_id?/:section?',
    name: 'wager',
    props: true,
    meta: { layout: 'default', title: 'Wagers' },
    component: require('@/views/Wager').default
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    meta: { layout: 'default', title: 'Forgot Password' },
    component: require('@/views/ForgotPassword').default
  },
  {
    path: '/resetpassword/:resethash',
    name: 'resetpassword',
    props: true,
    meta: { layout: 'default', title: 'Reset Password' },
    component: require('@/views/ResetForgotPassword').default
  },
  {
    path: '/terms',
    name: 'terms',
    meta: { layout: 'default', title: 'Terms' },
    component: require('@/components/UserAgreement').default
  },
  {
    path: '/faq',
    name: 'faq',
    meta: { layout: 'default', title: 'FAQ' },
    component: require('@/views/FAQ').default
  },
  {
    path: '/notes',
    name: 'notes',
    meta: { layout: 'default', title: 'Notes' },
    component: require('@/views/Notes').default
  },
  {
    path: '/scores',
    name: 'scores',
    meta: { layout: 'default', title: 'Scores' },
    component: require('@/views/Scores').default
  },
  {
    path: '/loss/:wager_id',
    name: 'loss',
    props: true,
    meta: { layout: 'default', title: 'Loss' },
    component: require('@/views/Loss').default
  },
  {
    path: '/group/:group_id',
    name: 'group',
    props: true,
    meta: { layout: 'default', title: 'Group' },
    component: require('@/views/GroupInfo').default
  },

  {
    path: '/group/:group_id/contesttype/:contesttype_id',
    name: 'contesttype',
    props: true,
    meta: { layout: 'default', title: 'New Contest' },
    component: require('@/views/ContestType').default
  },

  {
    path: '/group/:group_id/contest/:contest_id/:enter?',
    name: 'contest',
    props: true,
    meta: { layout: 'default', title: 'Contest' },
    component: require('@/views/Contest').default
  },

  { path: '*', component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.query.code && to.query.code.replace(/^\s+|\s+$/g, '') !== '') {
    localStorage.setItem('invitecode', to.query.code)
  }
  // redirect to login page if not logged in and trying to access a restricted page
  const loggedIn = UserService.loggedIn()
  const activated = UserService.activated()
  const isAdmin = UserService.isAdmin()
  const adminRequired = /^\/admin/.test(to.path)

  // if (authRequired && !loggedIn && !isRegistration && !isResetPwd) {
  //   console.log('router need auth')
  //   return next('/login')
  // } else if (loggedIn && !activated && to.path !== '/verifyphone') {
  if (loggedIn && !activated && to.path !== '/verifyphone') {
    return next('/verifyphone')
  } else if (adminRequired && !isAdmin) {
    console.log('admin required')
    return next('/login')
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = 'VigZero - ' + nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

router.name = 'router'

export default router
