var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.probability != null,
          expression: "probability != null"
        }
      ],
      staticClass: "pt-3 mb-n3 px-6",
      staticStyle: { width: "100%" }
    },
    [
      _c("div", { staticClass: "percentage", style: _vm.percentageStyle }, [
        _vm._v(_vm._s(_vm.probability) + "%")
      ]),
      _c("div", { staticClass: "gradient-bar" }),
      _c("div", {
        staticClass: "progress-bar",
        style: { width: _vm.probabilityWidth }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }