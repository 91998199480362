<template>
  <div class="about">
    <v-row class="mt-0">
      <v-col
        cols="12"
        class="mt-0 pt-0"
      >
        <v-card>
          <v-row
            class="mt-0 px-4 pt-4"
            justify="center"
          >
            <v-col cols="12" class="py-0 text-center">
              <img
                src="../assets/black_red_icon/512.png"
                style="max-width:25%; border-radius:0; height: auto"
              >
            </v-col>
          </v-row>
          <v-row justify="center">
            <span
              class="robo"
              style="font-size:1.8em"
            >Notes</span>
          </v-row>

          <v-card-text>

            <v-row class="ma-0" v-for="(n , i) in notes" :key="i">
              <v-col
                cols="12"
                class="py-0 px-0 black--text"
                @click="clickexpander(i)"
              >
                <v-row
                  align="center"
                  class="ma-0"
                >
                  <v-col cols="1">
                    <span>
                      <v-icon color="black">{{ expanded == i ? 'remove_circle_outline' : 'add_circle_outline' }}</v-icon>
                    </span>
                  </v-col>
                  <v-col>
                    {{ n.headline }}
                  </v-col>
                </v-row>

                <v-expand-transition>
                  <v-card
                    block
                    v-show="expanded == i"
                    class="mx-auto pa-3"
                    v-html="n.message"
                  >
                    {{ n.message }}
                  </v-card>
                </v-expand-transition>

                <v-divider></v-divider>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col>
                <back
                  @back="tofaq"
                  text="Back"
                  emit="back"
                ></back>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
  </div>
</template>
<script>
import UserService from '@/services/UserService'
import Back from '@/components/widget/Back'

export default {
  components: {
    Back
  },
  data: function () {
    return {
      expanded: -1,
      notes: null
    }
  },
  created () {
    this.init()
  },
  methods: {
    clickexpander: function (id) {
      if (this.expanded === id) {
        this.expanded = -1
      } else {
        this.expanded = id
      }
    },
    init: function () {
      UserService.getreleasenotes()
        .then(response => {
          this.notes = response.data.notes
        })
        .catch(error => {
          console.log(error)
        })
    },
    tofaq: function () {
      this.$router.push('/faq')
    }
  }
}
</script>
<style scoped>
    .expanded {
    max-height: 50vh;
    padding-bottom: 0.5em;
    padding-top: 1em;
    }
</style>
