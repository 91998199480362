<template>
  <div v-if="filteredDebts && filteredDebts.length > 0">

    <PaymentSentDialog
      :wager="confirmDebt"
      @cancel="confirmDebt = null"
      @paid="confirmDebt = null; $emit('updatedata')"
    ></PaymentSentDialog>

    <PaymentSentDisputeDialog
      :wager="disputeDebt"
      @cancel="disputeDebt = null"
      @paid="disputeDebt = null; $emit('updatedata')"
    ></PaymentSentDisputeDialog>

    <v-card class="card-shadow card loser-shadow">
      <v-card-title>
        <div class="loser mx-auto">Need to Settle</div>
      </v-card-title>
      <v-card-text>
        <div
          v-for="(debt, index) in filteredDebts"
          v-bind:key="index"
          class="row button-border black--text"
          @click="selectDebt(debt, index)"
        >
          <v-row align="center" justify="space-between" class="my-0 py-0">
            <v-col cols="12" v-if="debt.dispute_id > 0" class="my-0 py-0">
              <h4 class="text-center">Dispute Documentation Required</h4>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <span class="action-section">Bet #{{ debt.id }}</span> ${{ debt.amount }} {{ getGameInfo(debt) }} <span class="loser">LOSS TO PAY</span>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              {{ debt.timetopay.toFixed(1) }} hours since final
            </v-col>
            <v-col cols="12" class="sub-text text-center my-0 py-0">
              {{ fullgame(debt) }}
            </v-col>
          </v-row>
          <div
            v-if="debt.paymentsent != null && !isDispute(debt)"
            class="nottransparent"
          >Awaiting Confirmation</div>
          <!-- <div
            v-else-if="isDispute(debt)"
            class="nottransparent"
          >Dispute: {{ debt.disputetype }} - {{ debt.disputestatus }}</div> -->
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PaymentSentDialog from '@/components/dashboard/actions/PaymentSentDialog'
import PaymentSentDisputeDialog from '@/components/dashboard/actions/PaymentSentDisputeDialog'

export default {
  components: {
    PaymentSentDialog,
    PaymentSentDisputeDialog
  },
  data: function () {
    return {
      confirmDebt: null,
      disputeDebt: null,

      times: [],
      interval: null,
      selectedDebt: null,
      confirmPayment: false,
      betdetails: false,
      filteredDebts: null
    }
  },
  props: {
    debts: Array
  },
  created: function () {
    this.filteredDebts = null
    this.filteredDebts = this.debts.filter(this.debtsFilter)

    for (let i = 0; i < this.filteredDebts.length; i++) {
      // timetopay is calculated in minutes. convert that into hours and minutes seperately
      this.filteredDebts[i].timetopay = this.filteredDebts[i].timetopay / 60
    }
    this.interval = setInterval(this.timer, 60000)
  },
  destroyed: function () {
    clearInterval(this.interval)
  },
  methods: {
    timer: function () {
      this.filteredDebts.forEach(el => {
        var time = el.timetopay
        time *= 60
        time += 1
        time = time / 60

        el.timetopay = time
      })
    },
    getGameInfo: function (debt) {
      var homeline = (debt.lineamount > 0 ? ' (+' + debt.lineamount : ' (' + debt.lineamount) + ')'
      var awayline = ((debt.lineamount * -1) > 0 ? '(+' + (debt.lineamount * -1) : '(' + (debt.lineamount * -1)) + ') '

      if (debt.offeredby_id === debt.loser_id) {
        if (debt.hometeam_id === debt.offeredbyteam_id) {
          if (debt.bettype_id === 20) {
            return 'Over ' + debt.lineamount
          } else {
            return debt.homeabbr + ' ' + homeline
          }
        } else {
          if (debt.bettype_id === 20) {
            return 'Under ' + debt.lineamount
          } else {
            return debt.awayabbr + ' ' + awayline
          }
        }
      } else {
        if (debt.hometeam_id === debt.takenbyteam_id) {
          if (debt.bettype_id === 20) {
            return 'Over ' + debt.lineamount
          } else {
            return debt.homeabbr + ' ' + homeline
          }
        } else {
          if (debt.bettype_id === 20) {
            return 'Under ' + debt.lineamount
          } else {
            return debt.awayabbr + ' ' + awayline
          }
        }
      }
    },
    fullgame: function (debt) {
      // var homeline = debt.bettype_id === 20 ? '' : (debt.lineamount > 0 ? ' (+' + debt.lineamount : ' (' + debt.lineamount) + ')'
      // var awayline = debt.bettype_id === 20 ? '' : ((debt.lineamount * -1) > 0 ? '(+' + (debt.lineamount * -1) : '(' + (debt.lineamount * -1)) + ') '

      return debt.awayteamscore + ' ' + debt.away + ' vs. ' + debt.home + ' ' + debt.hometeamscore
    },
    selectDebt: function (debt, index) {
      if (typeof debt.dispute_id !== 'undefined' && debt.dispute_id !== null) {
        this.$router.push('/disputes/' + debt.dispute_id)
      } else if (debt.paymentsent != null) {
        this.selectedDebt = debt
        this.betdetails = true
        this.selectedDebt.index = index
        this.disputeDebt = debt
      } else {
        this.selectedDebt = debt
        this.confirmDebt = debt
      }
    },
    dispute: function () {
      if (this.canDispute(this.selectedDebt)) {
        this.betdetails = false
        var id = this.selectedDebt.id
        this.selectedDebt.id = null
        this.$router.push('/disputes/create/' + id)
      } else {
        this.betdetails = false
        this.selectedDebt.id = null
        this.$notify({
          group: 'errors',
          title: 'Cannot Dispute',
          text: 'Cannot make a dispute until 2 hours passes',
          type: 'warn'
        })
      }
    },
    isDispute: function (wager) {
      return wager.dispute_id > 0
    },
    debtsFilter: function (debt) {
      return this.canDispute(debt) || debt.paymentsent == null || this.isDispute(debt)
    },
    canDispute: function (debt) {
      // disabling ability to dispute unconfirmed payments
      return false
      // return (debt.timetopay / 60) >= 2
    }
  },
  filters: {
    timeFormat: function (val) {
      if (val < 10) return '0' + val
      else return val
    }
  }
}
</script>

<style scoped>
.timer {
  border: 0.2em solid red;
}
.disable {
  opacity: 0.6;
  filter: alpha(opacity = 60);
  color: grey;
}
.nottransparent {
  opacity: 1;
  filter: alpha(opacity = 100);
  color: black;
  width: 100%;
  text-align: center;
  font-weight: bold;
}
</style>
