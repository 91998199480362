var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.game && _vm.game.id
    ? _c(
        "v-card",
        { staticClass: "mb-4 tournament-matchup", attrs: { elevation: "2" } },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "datetime vig-card-header-mini font-weight-regular normal-line-height pa-0 px-1",
              class: _vm.isUserGame ? "mygame" : ""
            },
            [
              _c(
                "v-row",
                { staticClass: "ma-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { align: "left" } },
                    [
                      _c("span", { staticClass: "sub-text" }, [
                        _vm._v(
                          _vm._s(_vm.divisionName) + " " + _vm._s(_vm.round)
                        )
                      ])
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { align: "right" } },
                    [
                      _vm.game.game
                        ? _c("span", { staticClass: "sub-text" }, [
                            _vm.game.game.gamestatus === "Upcoming"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.game.game.gamedate_formatted) +
                                      " " +
                                      _vm._s(_vm.game.game.starttime_formatted)
                                  )
                                ])
                              : _c("span", [
                                  _vm.game.game.gamestatus === "In Progress"
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.displayGameClock(
                                                _vm.game.game
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _c("span", [_vm._v("Final")])
                                ])
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c("TournamentTeam", {
                attrs: {
                  game: _vm.game,
                  tournament: _vm.tournament,
                  user_id: _vm.user_id,
                  team: _vm.game.favorite,
                  group_id: _vm.group_id
                }
              }),
              _c("TournamentTeam", {
                attrs: {
                  game: _vm.game,
                  tournament: _vm.tournament,
                  user_id: _vm.user_id,
                  team: _vm.game.underdog,
                  group_id: _vm.group_id
                }
              })
            ],
            1
          ),
          _vm.game.winner !== "undecided"
            ? _c(
                "v-card-title",
                {
                  staticClass:
                    "datetime vig-card-footer-mini font-weight-regular normal-line-height pa-0 px-1",
                  class: _vm.isUserGame ? "mygame" : ""
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { align: "center" } },
                        [
                          _c("span", { staticClass: "sub-text" }, [
                            _vm._v(
                              _vm._s(_vm.winnerNickname) +
                                " advances with " +
                                _vm._s(_vm.winnerTeam)
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }