var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper fadeInDown" }, [
    _c("div", { attrs: { id: "verify-form" } }, [
      _c(
        "form",
        {
          staticClass: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.verify()
            }
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_c("div", [_vm._v("Phone Verification")])]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            ref: "code",
                            attrs: {
                              label: "Activation Code",
                              required: "",
                              minlength: "6",
                              inputmode: "numeric",
                              pattern: "[0-9]*",
                              autocomplete: "one-time-code",
                              disabled: _vm.sending,
                              loading: _vm.sending
                            },
                            on: { keydown: _vm.filterKey },
                            model: {
                              value: _vm.form.activationcode,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "activationcode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.activationcode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: _vm.sending },
                      on: {
                        click: function($event) {
                          return _vm.resend()
                        }
                      }
                    },
                    [_vm._v("Resend")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        type: "submit",
                        disabled: _vm.sending,
                        color: "primary"
                      }
                    },
                    [_vm._v("Submit")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "my-0 py-0 mt-2 mb-2",
          staticStyle: { "font-size": "1.1rem" }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-4 mt-4" },
            [
              _c("v-card-title", { staticClass: "headline font-weight-bold" }, [
                _vm._v(" Initiate Deposit ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  " VigZero not only saves you with zero vigorish, we NOW save you 3% on deposits as it's free to deposit with us. We are using Venmo in order to fund your account, so make your deposit now. "
                ),
                _c("br"),
                _c("br"),
                _c("strong", [_vm._v("How to make a deposit to VigZero:")]),
                _c("ol", [
                  _c("li", [
                    _vm._v(
                      "Record how much you are going to send to @VigZero via Venmo."
                    )
                  ]),
                  _c("li", [
                    _vm._v(
                      "Once we receive verification from Venmo, your account will be in good standing."
                    )
                  ]),
                  _c("li", [
                    _vm._v(
                      "Prior to verification, you can make offers and/or take wagers. Please note that if any expected deposits are not made by game time, your wagers are subject to be voided."
                    )
                  ]),
                  _c("li", [
                    _vm._v(
                      "Withdraw requests can be made at any time; however, withdrawals are not allowed if there are any pending or unsettled wagers on ongoing games."
                    )
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }