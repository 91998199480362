import axios from 'axios'
import Vue from 'vue'
import Notifications from 'vue-notification'
import UserService from './UserService'

Vue.use(Notifications)

const OfferService = {
  async makeOffer (wager) {
    await UserService.authHeader()
    var data = {
      wager: wager
    }
    return axios({
      url: UserService.baseUrl() + 'wager/create',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async getOffers (filters, perpage = 10, page = 1, order = null) {
    await UserService.authHeader()
    var data = {
      filters: filters,
      perpage: perpage,
      page: page,
      order: order
    }
    return axios({
      url: UserService.baseUrl() + 'wager/get',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async offerfilters () {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'wager/filters',
      method: 'get'
    })
  },

  async searchTeams (filters) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'wager/teamsearch',
      data: JSON.stringify(filters),
      method: 'post'
    }).then(response => {
      return response
    })
  },

  async updateOffer (wager, id) {
    await UserService.authHeader()
    var data = {
      wager: wager
    }
    return axios({
      url: UserService.baseUrl() + 'wager/update/' + id,
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async historyFilter () {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'wager/history/init',
      method: 'get'
    })
  }
}

export default OfferService
