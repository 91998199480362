var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.offers, function(offer) {
      return _c(
        "div",
        {
          key: offer.id,
          attrs: { id: _vm.generateWagerIdentifier(offer.id) },
          on: {
            click: function($event) {
              return _vm.offerclicked(offer)
            }
          }
        },
        [
          _c(
            "v-row",
            { staticClass: "my-0 py-0" },
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("GameHeader", {
                    attrs: { game: _vm.gameHeaderInfo(offer) }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0" },
                [_c("WagerInfo", { attrs: { wager: offer } })],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }