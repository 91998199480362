var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.contest
    ? _c(
        "div",
        { staticClass: "wrapper fadeInDown text-center" },
        [
          _c(
            "v-row",
            {
              staticClass: "my-0",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "3" } },
                [
                  _c("v-img", {
                    attrs: { src: _vm.getLogo(), "max-width": "100" }
                  })
                ],
                1
              ),
              _c("v-col", { staticClass: "pa-0" }, [
                _c("h1", [_vm._v(_vm._s(_vm.group.name))]),
                _c("h1", [_vm._v(_vm._s(_vm.contest.name))])
              ]),
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "3", align: "center" } },
                [
                  _c("v-img", {
                    attrs: {
                      alt: "",
                      src: require("../assets/madness-survivor-logo.png"),
                      "max-width": "100"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "my-0" },
            [
              _c("v-col", { staticClass: "py-0" }, [
                _c("p", { staticClass: "mb-1" }, [
                  _vm._v(_vm._s(_vm.contest.summary))
                ])
              ])
            ],
            1
          ),
          _vm.contest.open
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    { staticClass: "mb-3" },
                    [
                      _c("v-col", { staticClass: "py-0" }, [
                        _c("h2", { staticClass: "black--text ma-0" }, [
                          _vm._v("Entry Fee: $" + _vm._s(_vm.contest.entryfee))
                        ])
                      ]),
                      _c("v-col", { staticClass: "py-0" }, [
                        _c("h2", { staticClass: "black--text ma-0" }, [
                          _vm.contest.maximum > 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.contest.maximum - _vm.contest.entries
                                  ) + " spots left"
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  "Entries: " + _vm._s(_vm.contest.entries)
                                )
                              ])
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm.contest.deadline
                    ? _c(
                        "v-row",
                        { staticClass: "mb-3" },
                        [
                          _c("v-col", { staticClass: "py-0" }, [
                            _c("h2", { staticClass: "black--text ma-0" }, [
                              _vm._v(
                                "Deadline: " +
                                  _vm._s(_vm.contest.deadline_formatted)
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0" },
                        [
                          _c("h2", { staticClass: "black--text ma-0" }, [
                            _vm._v(
                              "Your Entries: " + _vm._s(_vm.contest.myentries)
                            ),
                            _vm.contest.limitperuser
                              ? _c("span", [
                                  _vm._v(
                                    " of " + _vm._s(_vm.contest.limitperuser)
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("br"),
                          this.canEnter
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: _vm.loading,
                                    small: "",
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.enterContest = _vm.contest
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.contest.myentries > 0
                                        ? "Enter Again"
                                        : "Enter"
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "v-row",
                    { staticClass: "mb-3" },
                    [
                      _c("v-col", { staticClass: "py-0" }, [
                        _c("h2", { staticClass: "black--text ma-0" }, [
                          _vm._v(
                            "Entries: " +
                              _vm._s(_vm.entriesAlive) +
                              " of " +
                              _vm._s(_vm.assignedTeams) +
                              " left"
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
          _vm.contest.balance > 0
            ? _c(
                "v-alert",
                {
                  attrs: {
                    text: "",
                    prominent: "",
                    type: "error",
                    icon: "mdi-currency-usd",
                    border: "top",
                    elevation: "2",
                    dismissible: ""
                  }
                },
                [
                  _vm._v(
                    " Pay your entry fee balance of $" +
                      _vm._s(_vm.contest.balance) +
                      " easily via Venmo to "
                  ),
                  _c(
                    "a",
                    { attrs: { href: "//venmo.com/u/" + _vm.contest.venmo } },
                    [_vm._v("@" + _vm._s(_vm.contest.venmo))]
                  ),
                  _vm._v(". ")
                ]
              )
            : _vm._e(),
          _vm.contest && _vm.tournament && _vm.tournament.teamsassigned
            ? _c(
                "v-expansion-panels",
                {
                  model: {
                    value: _vm.contestMyEntriesPanel,
                    callback: function($$v) {
                      _vm.contestMyEntriesPanel = $$v
                    },
                    expression: "contestMyEntriesPanel"
                  }
                },
                [
                  _c(
                    "v-expansion-panel",
                    { staticClass: "v-expansion-panel-skinny" },
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-down" } },
                        [
                          _c("h2", { staticClass: "ma-0 black--text" }, [
                            _vm._v("My Entries")
                          ])
                        ]
                      ),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _vm.contest && _vm.tournament
                            ? _c("ContestResults", {
                                attrs: {
                                  contest: _vm.contest,
                                  tournament: _vm.tournament,
                                  group_id: _vm.group_id,
                                  admin: _vm.isAdmin,
                                  user_id: _vm.userId,
                                  "user-only": ""
                                },
                                on: { refresh: _vm.init }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.contest && _vm.tournament && _vm.tournament.teamsassigned
            ? _c(
                "v-expansion-panels",
                {
                  model: {
                    value: _vm.contestResultsPanel,
                    callback: function($$v) {
                      _vm.contestResultsPanel = $$v
                    },
                    expression: "contestResultsPanel"
                  }
                },
                [
                  _c(
                    "v-expansion-panel",
                    { staticClass: "v-expansion-panel-skinny" },
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-down" } },
                        [
                          _c("h2", { staticClass: "ma-0 black--text" }, [
                            _vm._v("Bracket/Results")
                          ])
                        ]
                      ),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _vm.contest && _vm.tournament
                            ? _c("ContestResults", {
                                attrs: {
                                  contest: _vm.contest,
                                  tournament: _vm.tournament,
                                  group_id: _vm.group_id,
                                  admin: _vm.isAdmin,
                                  user_id: _vm.userId
                                },
                                on: { refresh: _vm.init }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.contest && (_vm.contest.myentries > 0 || _vm.isAdmin)
            ? _c("ContestEntries", {
                attrs: { contest: _vm.contest, admin: _vm.isAdmin },
                on: { refresh: _vm.init }
              })
            : _vm._e(),
          _vm.contest && _vm.isAdmin
            ? _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-down" } },
                        [
                          _c("h2", { staticClass: "ma-0 black--text" }, [
                            _vm._v("Contest Settings")
                          ])
                        ]
                      ),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _vm.contest && _vm.isAdmin
                            ? _c("ContestSettings", {
                                attrs: {
                                  contest: _vm.contest,
                                  contesttype: _vm.contesttype,
                                  group_id: _vm.group_id
                                },
                                on: { refresh: _vm.init }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.contest
            ? _c("ContestPrizes", { attrs: { contest: _vm.contest } })
            : _vm._e(),
          _vm.contest
            ? _c("ContestRules", { attrs: { contest: _vm.contest } })
            : _vm._e(),
          _vm.contest && _vm.isAdmin
            ? _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "expand-icon": "mdi-menu-down" } },
                        [
                          _c("h2", { staticClass: "ma-0 black--text" }, [
                            _vm._v("Email")
                          ])
                        ]
                      ),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _vm.contest && _vm.isAdmin
                            ? _c("ContestEmail", {
                                attrs: {
                                  contest: _vm.contest,
                                  group: _vm.group
                                },
                                on: { refresh: _vm.init }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-left" },
                [
                  _c("Back", {
                    staticClass: "pull-left pr-3",
                    attrs: { text: "", emit: "goBack" },
                    on: {
                      goBack: function($event) {
                        return _vm.$router.push("/group/" + _vm.group_id)
                      }
                    }
                  }),
                  _c("h3", { staticClass: "mt-2 black--text" }, [
                    _vm._v("Back To Group")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.enterContest
            ? _c("ContestEntryDialog", {
                attrs: { contest: _vm.enterContest, group: _vm.group },
                on: {
                  cancel: function($event) {
                    _vm.enterContest = null
                  },
                  enter: _vm.processContestEntry
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }