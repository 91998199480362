var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panels",
    [
      _c(
        "v-expansion-panel",
        { staticClass: "v-expansion-panel-skinny" },
        [
          _c(
            "v-expansion-panel-header",
            { attrs: { "expand-icon": "mdi-menu-down" } },
            [_c("h2", { staticClass: "ma-0 black--text" }, [_vm._v("Payouts")])]
          ),
          _c(
            "v-expansion-panel-content",
            { staticClass: "text-left py-3 px-0" },
            [
              _vm.contest.entryfee
                ? _c("v-simple-table", {
                    staticClass: "text-center",
                    attrs: { dense: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function() {
                            return [
                              _c("thead", [
                                _c("th", { staticClass: "text-right pr-2" }, [
                                  _vm._v("Entries:")
                                ]),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-right pr-2",
                                    class: _vm.getEntryClass(16)
                                  },
                                  [_vm._v("16")]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-right pr-2",
                                    class: _vm.getEntryClass(32)
                                  },
                                  [_vm._v("32")]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-right pr-2",
                                    class: _vm.getEntryClass(64)
                                  },
                                  [_vm._v("64")]
                                )
                              ]),
                              _c("tbody", [
                                _c("tr", [
                                  _c("th", { staticClass: "pa-0 pl-1" }, [
                                    _vm._v("Champion")
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right pa-0 pr-1",
                                      class: _vm.getEntryClass(16)
                                    },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(_vm.calculateWinning(16, 50))
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right pa-0 pr-1",
                                      class: _vm.getEntryClass(32)
                                    },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(_vm.calculateWinning(32, 50))
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right pa-0 pr-1",
                                      class: _vm.getEntryClass(64)
                                    },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(_vm.calculateWinning(64, 50))
                                      )
                                    ]
                                  )
                                ]),
                                _c("tr", [
                                  _c("th", { staticClass: "pa-0 pl-1" }, [
                                    _vm._v("Runner-Up")
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right pa-0 pr-1",
                                      class: _vm.getEntryClass(16)
                                    },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(_vm.calculateWinning(16, 30))
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right pa-0 pr-1",
                                      class: _vm.getEntryClass(32)
                                    },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(_vm.calculateWinning(32, 30))
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right pa-0 pr-1",
                                      class: _vm.getEntryClass(64)
                                    },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(_vm.calculateWinning(64, 30))
                                      )
                                    ]
                                  )
                                ]),
                                _c("tr", [
                                  _c("th", { staticClass: "pa-0 pl-1" }, [
                                    _vm._v("Final 4 (each)")
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right pa-0 pr-1",
                                      class: _vm.getEntryClass(16)
                                    },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(_vm.calculateWinning(16, 10))
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right pa-0 pr-1",
                                      class: _vm.getEntryClass(32)
                                    },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(_vm.calculateWinning(32, 10))
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right pa-0 pr-1",
                                      class: _vm.getEntryClass(64)
                                    },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(_vm.calculateWinning(64, 10))
                                      )
                                    ]
                                  )
                                ]),
                                _c("tr", [
                                  _c("th", { staticClass: "pa-0 pl-1" }, [
                                    _vm._v("Odds of Cashing")
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right pa-0 pr-1",
                                      class: _vm.getEntryClass(16)
                                    },
                                    [_vm._v("1 in 4")]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right pa-0 pr-1",
                                      class: _vm.getEntryClass(32)
                                    },
                                    [_vm._v("1 in 8")]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right pa-0 pr-1",
                                      class: _vm.getEntryClass(64)
                                    },
                                    [_vm._v("1 in 16")]
                                  )
                                ])
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1080272961
                    )
                  })
                : _c("div", [
                    _c("ul", [
                      _c("li", [_vm._v("50% Champion")]),
                      _c("li", [_vm._v("30% Runner-Up")]),
                      _c("li", [_vm._v("10% Other Final Four (each)")])
                    ])
                  ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }