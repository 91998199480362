<template>
  <v-row class="my-0 py-0">
    <v-col cols="12" class="py-0 vig-card-header-mini">
      <span class="sub-text pull-left">{{ game.league }} - {{ game.sport }}</span>
      <span class="sub-text pull-right">{{ game.gamedate }} {{ game.starttime }}</span>
    </v-col>
    <v-col cols="12" class="py-0 vig-card-header-mini text-center">
      <span v-if="game.bettype_id == 30" class="sub-text text-center">{{ (game.currentline * -1) | line }} {{ game.awayteam }} @ {{ game.hometeam }} {{ game.currentline | line }}</span>
      <span v-if="game.bettype_id == 20" class="sub-text text-center">{{ game.awayteam }} @ {{ game.hometeam }} - O/U {{ game.currentline }}</span>
      <span v-if="game.bettype_id == 10" class="sub-text text-center">{{ (game.currentline * -1) | line }} {{ game.awayteam }} @ {{ game.hometeam }} {{ game.currentline | line }}</span>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    game: {
      league: String,
      sport: String,
      gamedate: String,
      starttime: String,
      currentline: Number,
      awayteam: String,
      hometeam: String,
      bettype_id: Number
    }
  },
  data: function () {
    return {
    }
  },
  filters: {
    line: function (val) {
      val = parseFloat(val)
      if (val > 0) {
        return '+' + val
      } else if (val < 0) {
        return val
      }
      return 'PK'
    }
  }
}
</script>
<style scoped>

</style>
