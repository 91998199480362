<template>
  <div>
    <div
      v-for="offer in offers"
      :key="offer.id"
      @click="offerclicked(offer)"
      :id="generateWagerIdentifier(offer.id)"
    >
      <v-row class="my-0 py-0">
        <v-col cols="12" class="py-0">
          <GameHeader :game="gameHeaderInfo(offer)"></GameHeader>
        </v-col>
        <v-col class="py-0">
          <WagerInfo
            :wager="offer"
          ></WagerInfo>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
    </div>
  </div>
</template>
<script>
import WagerInfo from '@/components/wagers/Info'
import UserService from '@/services/UserService'
import GameHeader from '@/components/games/Header'

export default {
  components: {
    WagerInfo,
    GameHeader
  },
  data: () => {
    return {
      clickedOffer: null,
      showTakeOffer: false,
      showDelete: false,
      type: null
    }
  },
  props: {
    offers: Array,
    status: String,
    canBet: {
      type: Boolean,
      default: true
    },
    section: {
      type: String,
      default: ''
    }
  },
  methods: {
    offerclicked: function (offer) {
      if (offer.takenby_id == null && this.hasPaymentMethod) {
        /* this.clickedOffer = offer
        this.showTakeOffer = true */
        this.$router.push(this.getWagerLinkUrl(offer))
      } else {
        this.$emit('clicked')
      }
    },
    getWagerLinkUrl: function (wager) {
      var type = (wager.bettype_id === 30) ? 'line' : ((wager.takenbyteam_id === wager.hometeam_id) ? 'under' : 'over')
      var path = '/wager/' + wager.game_id + '/' + wager.takenbyteam_id + '/' + type + '/' + wager.id
      if (this.section) {
        path += '/' + this.section
      }
      return path
    },
    generateWagerIdentifier: function (id) {
      return this.section + 'Wager' + id
    },

    gameHeaderInfo: function (game) {
      return {
        league: game.league,
        sport: game.sport,
        gamedate: game.gamedate_formatted,
        starttime: game.starttime_formatted,
        currentline: game.currentline,
        awayteam: game.awayteamname,
        hometeam: game.hometeamname,
        bettype_id: game.bettype_id
      }
    }
  },
  computed: {
    hasPaymentMethod: function () {
      const user = UserService.getUserObject()
      if (!user || !user.info) {
        return true
      }
      var paymentmethods = user.paymentmethod
      if (paymentmethods.length > 0) {
        return paymentmethods[0].details[0].value !== ''
      } else {
        return false
      }
    }
  }
}
</script>
<style scoped>
.spacer {
  min-height: 3vh;
}
.margin {
  margin-left: 5vw;
  margin-right: 5vw;
}
.dialog-content {
  margin-top: 3vh;
  margin-bottom: 3vh;
}
</style>
