var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "my-0 py-0" },
    [
      _c(
        "v-col",
        { staticClass: "py-0 vig-card-header-mini", attrs: { cols: "12" } },
        [
          _c("span", { staticClass: "sub-text pull-left" }, [
            _vm._v(_vm._s(_vm.game.league) + " - " + _vm._s(_vm.game.sport))
          ]),
          _c("span", { staticClass: "sub-text pull-right" }, [
            _vm._v(_vm._s(_vm.game.gamedate) + " " + _vm._s(_vm.game.starttime))
          ])
        ]
      ),
      _c(
        "v-col",
        {
          staticClass: "py-0 vig-card-header-mini text-center",
          attrs: { cols: "12" }
        },
        [
          _vm.game.bettype_id == 30
            ? _c("span", { staticClass: "sub-text text-center" }, [
                _vm._v(
                  _vm._s(_vm._f("line")(_vm.game.currentline * -1)) +
                    " " +
                    _vm._s(_vm.game.awayteam) +
                    " @ " +
                    _vm._s(_vm.game.hometeam) +
                    " " +
                    _vm._s(_vm._f("line")(_vm.game.currentline))
                )
              ])
            : _vm._e(),
          _vm.game.bettype_id == 20
            ? _c("span", { staticClass: "sub-text text-center" }, [
                _vm._v(
                  _vm._s(_vm.game.awayteam) +
                    " @ " +
                    _vm._s(_vm.game.hometeam) +
                    " - O/U " +
                    _vm._s(_vm.game.currentline)
                )
              ])
            : _vm._e(),
          _vm.game.bettype_id == 10
            ? _c("span", { staticClass: "sub-text text-center" }, [
                _vm._v(
                  _vm._s(_vm._f("line")(_vm.game.currentline * -1)) +
                    " " +
                    _vm._s(_vm.game.awayteam) +
                    " @ " +
                    _vm._s(_vm.game.hometeam) +
                    " " +
                    _vm._s(_vm._f("line")(_vm.game.currentline))
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }