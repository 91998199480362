<template>
  <div>
    <PaymentSentDialog
      :wager="wager"
      @cancel="goToDashboard()"
      @paid="goToDashboard(['Payment sent', 'You have marked the payment as sent'])"
    ></PaymentSentDialog>
  </div>
</template>
<script>
import WagerService from '@/services/WagerService'
import PaymentSentDialog from '@/components/dashboard/actions/PaymentSentDialog'

export default {
  components: {
    PaymentSentDialog
  },
  props: {
    wager_id: {
      type: String,
      default: '0'
    }
  },
  data: () => {
    return {
      wager: null
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    init: function () {
      WagerService.getUnpaidWager(+this.wager_id).then(
        (result) => {
          this.wager = result.data.results[0]
        }
      ).catch(
        (err) => {
          if (err.response) {
            var msgLine1 = err.response.data.errors[0]
            var msgLine2 = err.response.data.error
            this.$router.push('/dashboard/pending', () => {
              this.$notify({
                group: 'errors',
                title: msgLine1,
                text: msgLine2,
                type: 'warn'
              })
            })
          }
        }
      )
    },
    goToDashboard: function (message = null) {
      if (message == null) {
        this.$router.push('/dashboard/pending')
      } else {
        this.$router.push('/dashboard/pending', () => {
          this.$notify({
            group: 'notices',
            title: message[0],
            text: message[1],
            type: 'alert-success'
          })
        })
      }
    }
  }
}
</script>
