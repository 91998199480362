<template>
  <div
    class="button-border"
    :class="(offer.deleted || (!offer.takenby_id && offer.status.substring(0, 4) !== 'Open')) ? 'inactive' : wagerResult + '-shadow'"
  >
    <GameHeader :game="gameHeaderInfo"></GameHeader>

    <v-row
      justify="center"
      align="center"
      style="font-size: larger;"
      class="pb-1 pt-0 my-0"
      @click.prevent="offer.expand = !offer.expand"
    >
      <v-col class="py-0" cols="2"><span class="font-smaller text-left">#{{ offer.id }}</span></v-col>
      <v-col class="py-0 text-center"><span class="text-center"><strong>{{ wagerTeamName }} ({{ showSpread(gameline, myTeamType) }})</strong></span></v-col>
      <v-col class="py-0 text-right" :cols="(offer.bettype_id === 10) ? 4 : 2">
        <span class="text-right py-0 font-smaller text-no-wrap">
          ${{ (isMyOffer) ? offer.offeredbyamount : offer.takenbyamount }}
          <span v-if="offer.offeredbyamount != offer.takenbyamount"> to win ${{ (isMyOffer) ? offer.takenbyamount : offer.offeredbyamount }}</span>
        </span>
      </v-col>

    </v-row>

    <v-row
      justify="space-around"
      class="py-0 my-0"
      @click.prevent="offer.expand = !offer.expand"
    >
      <v-col
        cols="12"
        v-if="offer.gamestatus === 'In Progress' || offer.gamestatus === 'Final'"
        class="py-0"
      >
        <BoxScore
          :game="offer"
          :compact="true"
          :result="wagerResult"
          :offerstatus="offer.status"
          :type="(offer.bettype_id === 20) ? ((myTeamType === 'away') ? 'OVER' : 'UNDER') : 'line'"
        ></BoxScore>
      </v-col>

      <v-col
        cols="12"
        v-if="(offer.gamestatus === 'In Progress' || offer.gamestatus === 'Final')"
        class="py-0"
      >
        <v-row class="my-0 py-0 text-center">
          <v-col class="py-0">
            <span v-if="myTeamType === 'away'"><span :class="wagerResult">{{ showWagerResult() }}</span></span>
            <span v-else>
              <span v-if="offer.takenby_id">{{ (isMyOffer) ? offer.takername : offer.offername }}</span>
            </span>
          </v-col>
          <v-col class="py-0">
            <span v-if="myTeamType === 'home'"><span :class="wagerResult">{{ showWagerResult() }}</span></span>
            <span v-else>
              <span v-if="offer.takenby_id">{{ (isMyOffer) ? offer.takername : offer.offername }}</span>
            </span>
          </v-col>
          <v-col class="py-0">
            <span v-if="offer.takenby_id > 0">
              <span v-if="offer.gamestatus === 'Final'"><strong :class="wagerResult">{{ offer.status }}</strong></span>
              <span v-else>{{ offer.status }}</span>
            </span>
            <span v-else>
              Not Taken
            </span>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        v-else
        class="py-0"
      >
        <v-row class="my-0 py-0">
          <v-col
            cols="8"
            class="py-0"
            v-if="offer.takenby_id && offer.status !== 'Cancelled' && !offer.deleted"
          >
            <div class="text-center">
              {{ (isMyOffer) ? offer.takername : offer.offername }} -
              <span v-if="offer.bettype_id === 20">
                {{ (myTeamType === 'away') ? 'OVER' : 'UNDER' }}
              </span>
              <span v-else>
                {{ (wagerTeamName === offer.home) ? offer.away : offer.home }}
                {{ showSpread(gameline, (myTeamType === 'home') ? 'away' : 'home') }}
              </span>
              <span v-if="offer.record.wins !== 0 || offer.record.losses !== 0 || offer.record.ties !== 0">
                <WagerRecord :record="offer.record"></WagerRecord>
              </span>
            </div>
          </v-col>

          <v-col
            :cols="(offer.takenby_id && offer.status !== 'Cancelled' && !offer.deleted) ? 4 : 12"
            class="text-center py-0"
            style="white-space: nowrap;"
            :style="(offer.takenby_id && offer.status !== 'Cancelled' && !offer.deleted) ? '' : 'text-align:right !important;'"
          >
            {{ offer.status }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      class="mt-0 mb-2"
      v-if="offer.gamestatus === 'In Progress'"
      @click.prevent="offer.expand = !offer.expand"
    >
      <Probability :probability="showWagerProbability()"></Probability>
    </v-row>
    <div
      class="text-center"
      v-if="offer.expand"
    >
      <v-row class="my-0 py-0">
        <v-col
          cols="12"
          class="py-0"
          v-if="offer.gamestatus === 'Final'"
        >
          <v-divider></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            class="my-2"
            v-if="showSendPayment"
            @click.prevent="$emit('sendpayment', offer)"
          >Send Payment</v-btn>

          <PayoutConfirmation
            v-if="showConfirmPayment"
            :payout="getPayoutInfo(offer)"
            compact
            @confirm="$emit('confirmpayment', offer)"
            @deny="$emit('denypayment', offer)"
          ></PayoutConfirmation>
        </v-col>
        <v-col
          cols="12"
          class="py-0"
          v-if="offer.gamestatus === 'Final'"
        >
          <v-divider></v-divider>
          <v-spacer class="pb-2"></v-spacer>
          <h4>{{ displayPaymentMethod((myTeam === offer.offeredbyteam_id) ? offer.takerpaymentmethod : offer.offerpaymentmethod) }}</h4>
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <h3>Wager Type: {{ offer.description }}</h3>
        </v-col>
        <v-col
          cols="6"
          class="py-0"
          v-if="offer.takenby_id"
        >
          <h4>Offered By: {{ offer.offername }}</h4>
          <h5>{{ offer.created }}</h5>
        </v-col>
        <v-col
          cols="6"
          class="py-0"
          v-if="offer.takenby_id"
        >
          <h4 v-if="offer.takenby_id > 0">Taken By: {{ offer.takername }}</h4>
          <h5>{{ offer.accepted }}</h5>
        </v-col>
        <v-col
          cols="12"
          class="py-0"
          v-if="!offer.takenby_id"
        >
          <h5>{{ offer.created }}</h5>
          <div
            v-if="!offer.deleted && !offer.inprogress && !offer.endtime"
            class="text-center"
          >
            <v-btn
              icon
              @click="showedit = !showedit"
            >
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="$emit('delete')"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-col
          cols="12"
          class="pb-0 bt-2"
          v-if="offer.gamestatus === 'Final' || offer.gamestatus === 'In Progress'"
        >
          <v-divider class="pb-2"></v-divider>
          <v-spacer></v-spacer>
          <BoxScore :game="offer"></BoxScore>
        </v-col>
      </v-row>
      <v-row
        class="my-0 py-0"
        v-if="canChat || (offer.messages && offer.messages.length)"
      >
        <v-col cols="12">
          <v-divider class="py-x"></v-divider>
          <WagerMessages
            :wagerId="offer.id"
            :messages="offer.messages"
            :canChat="canChat && offer.takenby_id > 0"
            @update="update"
          ></WagerMessages>
        </v-col>
      </v-row>
    </div>
    <div class="vig-card-expand-container">
      <v-row class="my-0 py-0">
        <v-col
          cols="2"
          class="pa-0 pl-1 text-left black--text"
          @click.prevent="offer.expand = !offer.expand"
        >
          <span
            v-if="offer.takenby_id > 0"
            :class="(hasUnreadMessages) ? 'text-red' : ''"
          >
            <v-icon
              size="18"
              :class="(hasUnreadMessages) ? 'text-red' : ''"
            >$bullhorn</v-icon>
            ({{ offer.messages.length }})
          </span>
        </v-col>
        <v-col
          class="pa-0"
          @click.prevent="offer.expand = !offer.expand"
        >
          <v-icon>{{ (offer.expand) ? 'arrow_drop_up' : 'arrow_drop_down' }}</v-icon>
        </v-col>
        <v-col
          cols="2"
          class="pa-0 pr-1 text-right"
        >
          <span v-if="offer.offeredby_id === this.userId">
            <v-switch
              v-if="canChat && offer.takenby_id > 0"
              class="ma-0 pa-0 switch-small shrink"
              color="red"
              hide-details
              label="Mute"
              :value="(offer.muteofferedby > 0) ? true : false"
              @click="toggleMute(offer)"
            ></v-switch>
          </span>
          <span v-else>
            <v-switch
              v-if="canChat && offer.takenby_id > 0"
              class="ma-0 pa-0 switch-small shrink"
              color="red"
              hide-details
              label="Mute"
              :value="(offer.mutetakenby > 0) ? true : false"
              @click="toggleMute(offer)"
            ></v-switch>
          </span>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="showedit"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-text
          style="line-height:1.5"
          class="pa-0 black--text mt-5"
        >
          <CreateWager
            :game="gameObject"
            :type="wagerType"
            :team_id="offer.offeredbyteam_id"
            :betlimit="betlimit"
            :wager_id="offer.id"
            @update="closeupdate"
          ></CreateWager>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <back
                @back="closeupdate"
                text="Back"
                emit="back"
              ></back>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import GameHeader from '@/components/games/Header'
import BoxScore from '@/components/games/BoxScore'
import CreateWager from '@/components/wagers/Create'
import Back from '@/components/widget/Back'
import GameService from '@/services/GameService'
import UserService from '@/services/UserService'
import ChatService from '@/services/ChatService'
import Probability from '@/components/wagers/widget/Probability'
import WagerMessages from '@/components/chat/WagerMessages'
import PayoutConfirmation from '@/components/dashboard/PayoutConfirmation'
import { bus } from '@/main'
import WagerRecord from '@/components/wagers/WagerRecord'

export default {
  components: {
    BoxScore,
    GameHeader,
    CreateWager,
    Back,
    Probability,
    WagerMessages,
    WagerRecord,
    PayoutConfirmation
  },
  props: {
    admin: {
      type: [Boolean],
      default: false
    },
    status: String,
    canChat: {
      type: Boolean,
      default: false
    },
    offer: {
      id: Number,
      amount: Number,
      offeredbyteam_id: Number,
      takenbyteam_id: Number,
      lineamount: Number,
      game_id: Number,
      bettype_id: Number,
      description: String,

      away: String,
      awayabbr: String,
      awayteam_id: Number,
      awayteamscore: Number,
      home: String,
      homeabbr: String,
      hometeam_id: Number,
      hometeamscore: Number,

      friendgroup_id: Number,
      offer_rating: Number,
      offeredby_id: Number,
      offername: String,
      takenby_id: Number,
      taker_rating: Number,
      takername: String,

      league: String,
      league_id: Number,
      season: String,
      season_id: Number,
      starttime: String,
      currentline: Number
    }
  },
  data: () => {
    return {
      userId: 0,
      showedit: false,
      muting: false,
      edit: {
        wager: 0,
        line: 0,
        adjust: false
      }
    }
  },
  mounted: function () {
    var user = UserService.getUserObject()
    this.userId = user ? +user.info.id : 0
    this.edit.wager = this.offer.offeredbyamount
    this.edit.line = this.offer.offeredbyteam_id === this.offer.hometeam_id || this.offer.bettype_id === 20 ? this.gameline : this.gameline * -1
    this.edit.adjust = this.offer.lineamount == null
    this.offer.timetopay = this.offer.timetopay / 60
    this.offer.timetosettle = this.offer.timetosettle / 60
  },
  watch: {
    'offer.expand': function (n, o) {
      this.$emit('expanded')
    }
  },
  methods: {
    getPayoutInfo (offer) {
      var payout = offer
      payout.paymentdetails = (this.userId === +offer.takenby_id) ? offer.offerpaymentmethod : offer.takerpaymentmethod
      payout.nickname = (this.userId === +offer.takenby_id) ? offer.offername : offer.takername
      return payout
    },

    isMuted (offer) {
      if (this.userId === offer.offeredby_id) {
        return offer.muteofferedby
      } else {
        return offer.mutetakenby
      }
    },
    async toggleMute (offer) {
      if (this.muting) {
        return false
      }
      this.muting = true
      await ChatService.toggleMuteWager(offer.id, (this.userId === offer.offeredby_id)).then(
        (response) => {
          this.muting = false
          bus.$emit('messages-refresh')
          this.update()
        },
        (err) => {
          this.muting = false
          console.dir(err)
        }
      )
    },
    showWagerResult () {
      var display = ''
      var diff = 0
      if (this.offer.bettype_id === 30) {
        diff = this.homeScore + this.gameline - this.awayScore
        if (diff === 0) {
          display = 'PUSH'
        } else if (+this.myTeam === +this.offer.hometeam_id) {
          display = ((diff > 0) ? '+' : '') + diff.toString()
        } else {
          display = ((diff < 0) ? '+' : '') + (diff * -1).toString()
        }
      } else {
        // ML or O/U points bet
        diff = 0
        if (this.offer.bettype_id === 20) {
          diff = this.homeScore + this.awayScore - this.gameline
        } else {
          diff = this.homeScore - this.awayScore
        }
        if (diff === 0) {
          display = 'PUSH'
        } else if (+this.myTeam === +this.offer.hometeam_id) {
          // over bet
          display = (diff > 0) ? '+' + Math.abs(diff.toString()) : '-' + Math.abs(diff.toString())
        } else {
          display = (diff > 0) ? '-' + Math.abs(diff.toString()) : '+' + Math.abs(diff.toString())
        }
      }

      return display
    },
    showWagerProbability () {
      var probability = parseInt(this.offer.probability)
      if (+this.myTeam !== +this.offer.hometeam_id) {
        probability = 100 - probability
      }
      return probability
    },
    displayPaymentMethod (method) {
      // const p = JSON.parse(method)
      if (method && method[0]) {
        return method[0].name + ' - @' + method[0].value
      }
    },
    getBoxScoreTotal (scores) {
      let total = 0
      scores.forEach(function (s) {
        total += +s
      })
      return total
    },
    showSpread (line, team) {
      var spread = GameService.showSpread(line, team)
      if (this.offer.bettype_id === 20 && spread.length > 1) {
        spread = spread.substr(1)
      }
      return spread
    },

    lineColor: function (line) {
      line = parseFloat(line)
      return {
        positive: line > 0,
        negative: line < 0
      }
    },
    cancelUpdate: function () {
      this.showedit = false
      this.edit.wager = this.offer.offeredbyamount
      this.edit.line = this.offer.offeredbyteam_id === this.offer.hometeam_id ? this.gameline : this.gameline * -1
    },
    update: function () {
      this.$emit('update')
    },
    closeupdate: function () {
      this.showedit = false
      this.$emit('update')
    }
  },
  computed: {
    showSendPayment: function () {
      // show send payment if I am the loser and I have not yet sent payment
      return (+this.offer.loser_id === +this.userId && this.offer.paymentsent === null)
    },
    showConfirmPayment: function () {
      // show confirmation if I am the winner and it's marked as paid and I haven't confirmed
      return (+this.offer.winner_id === +this.userId && this.offer.paymentreceived === null)
    },
    showDisputeSendPayment: function () {
      return false
    },
    showDisputeConfirmPayment: function () {
      // show dispute action if still not paid after 48 hours
      // return (!this.showConfirmPayment && +this.offer.winner_id === +this.userId && this.offer.paymentreceived === null && (this.offer.paymentsent !== null || this.offer.timesincegameend >= 48))
      return false
    },
    isMyOffer: function () {
      return (+this.userId === +this.offer.offeredby_id)
    },
    myTeamType: function () {
      if (this.isMyOffer) {
        return (this.offer.offeredbyteam_id === this.offer.hometeam_id) ? 'home' : 'away'
      } else {
        return (this.offer.takenbyteam_id === this.offer.hometeam_id) ? 'home' : 'away'
      }
    },
    myTeam: function () {
      if (+this.offer.offeredby_id === +this.userId) {
        return this.offer.offeredbyteam_id
      } else {
        return this.offer.takenbyteam_id
      }
    },
    homeScore: function () {
      if (this.offer.hometeamscore) {
        return this.offer.hometeamscore
      } else if (this.offer.scoreboard) {
        return this.getBoxScoreTotal(this.offer.scoreboard.box.home)
      } else {
        return 0
      }
    },
    awayScore: function () {
      if (this.offer.awayteamscore) {
        return this.offer.awayteamscore
      } else if (this.offer.scoreboard) {
        return this.getBoxScoreTotal(this.offer.scoreboard.box.away)
      } else {
        return 0
      }
    },
    wagerResult: function () {
      var display = ''
      var diff = 0
      if (this.offer.inprogress || this.offer.hometeamscore) {
        if (this.offer.bettype_id === 30) {
          diff = this.homeScore + this.gameline - this.awayScore
          if (diff !== 0) {
            if (+this.myTeam === +this.offer.hometeam_id) {
              display = (diff > 0) ? 'winner' : 'loser'
            } else {
              display = (diff < 0) ? 'winner' : 'loser'
            }
          }
          if (this.offer.gamestatus === 'In Progress' && this.offer.probability !== null) {
            // base this on probability if available
            if (+this.myTeam === this.offer.hometeam_id) {
              display = (this.offer.probability > 50) ? 'winner' : 'loser'
            } else {
              display = (this.offer.probability < 50) ? 'winner' : 'loser'
            }
            if (this.offer.probability === 50) {
              display = ''
            }
          }
        } else {
          // O/U points bet
          diff = 0
          if (this.offer.bettype_id === 20) {
            diff = this.homeScore + this.awayScore - this.gameline
          } else {
            diff = this.homeScore - this.awayScore
          }
          if (diff !== 0) {
            if (+this.myTeam === +this.offer.hometeam_id) {
              // over bet
              display = (diff > 0) ? 'winner' : 'loser'
            } else {
              display = (diff < 0) ? 'winner' : 'loser'
            }
          }
          if (this.offer.gamestatus === 'In Progress' && this.offer.probability !== null) {
            // base this on probability if available
            if (+this.myTeam === this.offer.hometeam_id) {
              display = (this.offer.probability > 50) ? 'winner' : 'loser'
            } else {
              display = (this.offer.probability < 50) ? 'winner' : 'loser'
            }
            if (this.offer.probability === 50) {
              display = ''
            }
          }
        }
      }
      return display
    },
    wagerTeamName: function () {
      var name = null
      if (this.offer.bettype_id === 20) {
        if (+this.userId === +this.offer.offeredby_id) {
          name = (+this.offer.offeredbyteam_id === +this.offer.hometeam_id) ? 'OVER' : 'UNDER'
        } else {
          name = (+this.offer.offeredbyteam_id === +this.offer.hometeam_id) ? 'UNDER' : 'OVER'
        }
      } else {
        if (+this.userId === +this.offer.offeredby_id) {
          name = (+this.offer.offeredbyteam_id === +this.offer.hometeam_id) ? this.offer.home : this.offer.away
        } else {
          name = (+this.offer.offeredbyteam_id === +this.offer.hometeam_id) ? this.offer.away : this.offer.home
        }
      }
      return name
    },
    gameHeaderInfo: function () {
      return {
        league: this.offer.league,
        sport: this.offer.sport,
        gamedate: this.offer.gamedate_formatted,
        starttime: this.offer.starttime_formatted,
        currentline: this.offer.currentline,
        awayteam: this.offer.away,
        hometeam: this.offer.home,
        bettype_id: this.offer.bettype_id
      }
    },
    gameline: function () {
      return (typeof this.offer.lineamount !== 'undefined' && this.offer.lineamount !== null) ? this.offer.lineamount : this.offer.currentline
    },
    errors: function () {
      return isNaN(this.edit.wager) || this.edit.wager < 1
    },
    openaway: function () {
      var open = this.offer.bettype_id === 20 ? this.offer.offeredbyteam_id !== this.offer.hometeam_id : this.offer.offeredbyteam_id !== this.offer.awayteam_id
      if ((this.offer.takenby_id != null && this.offer.offeredby_id != null) || this.admin || this.offer.deleted === 1 || this.status === 'Not Taken') {
        open = false
      }
      return open
    },
    openhome: function () {
      var open = this.offer.bettype_id === 20 ? this.offer.offeredbyteam_id !== this.offer.awayteam_id : this.offer.offeredbyteam_id !== this.offer.hometeam_id
      if ((this.offer.takenby_id != null && this.offer.offeredby_id != null) || this.admin || this.offer.deleted === 1 || this.status === 'Not Taken') {
        open = false
      }
      return open
    },
    nickname: function () {
      let names = []
      var offerer = this.offer.offername
      if (this.offer.offer_rating) {
        offerer += ' [' + this.offer.offer_rating + ']'
      }
      var taker = this.offer.takername
      if (this.offer.taker_rating) {
        taker += ' [' + this.offer.taker_rating + ']'
      }
      if (this.offer.bettype_id === 20) {
        names = this.offer.hometeam_id === this.offer.offeredbyteam_id ? [offerer, taker] : [taker, offerer]
      } else {
        names = this.offer.hometeam_id === this.offer.offeredbyteam_id ? [taker, offerer] : [offerer, taker]
      }
      if (names[0] == null) {
        names[0] = ''
      }
      if (names[1] == null) {
        names[1] = ''
      }
      return names
    },
    gameObject: function () {
      var offer = this.offer
      offer.ats_line = this.offer.currentline
      offer.ou_line = this.offer.currentline
      offer.gamedate = offer.gamedate_formatted
      offer.starttime = offer.starttime_formatted
      offer.awayteam = offer.away
      offer.hometeam = offer.home
      return offer
    },
    wagerType: function () {
      if (this.offer.bettype_id === 30) {
        return 'line'
      } else if (this.offer.bettype_id === 10) {
        return 'money'
      } else {
        if (this.offer.offeredbyteam_id === this.offer.hometeam_id) {
          return 'over'
        } else {
          return 'under'
        }
      }
    },
    betlimit: function () {
      var user = UserService.getUserObject()
      if (user) {
        var limit = isNaN(user.betinfo.available) ? 0 : +user.betinfo.available
        limit = limit + this.offer.offeredbyamount
        return limit
      } else {
        return 0
      }
    },
    hasUnreadMessages: function () {
      if (this.offer.messages && this.offer.messages.length > 0) {
        var i = this.offer.messages.length - 1
        while (i >= 0) {
          if (!this.offer.messages[i].viewed && this.offer.messages[i].user_id !== this.userId) {
            return true
          }
          i--
        }
      }
      return false
    }
  },
  filters: {
    line: function (val) {
      val = parseFloat(val)
      if (val > 0) {
        return '+' + val
      } else if (val < 0) {
        return val
      }
      return 'PK'
    }
  }
}
</script>
<style scoped>
.vig-card-expand h4,
.vig-card-expand h3,
.vig-card-expand h5 {
  margin: 4px auto !important;
}
.open {
  border: 0.2em solid rgb(45, 45, 45);
}
.linepadding {
  padding: 0.2em 0.2em 0.2em 0.2em;
}
.datetime {
  width: 100%;
}
.bolded {
  font-weight: bold;
}
.float-right {
  float: right;
}
.negative-button {
  background-color: rgb(180, 0, 0) !important;
  color: rgb(255, 255, 255) !important;
}
.positive-button {
  background-color: rgb(0, 180, 0) !important;
}
.negative-button:disabled {
  background-color: rgb(100, 70, 70) !important;
}
.positive-button:disabled {
  background-color: rgb(70, 100, 70) !important;
}
.input-field {
  max-width: 100%;
}
.font-smaller {
  font-size: 85%;
}
</style>
