<template>
  <div
    class="page-container"
    v-if="userdata"
  >
    <v-container>
      <v-row class="py-0 my-0">
        <v-col
          cols="6"
          class="py-0 my-0"
        >
          <v-row class="py-0 my-0">
            <v-col class="py-0 my-0">
              <div>
                <v-avatar
                  color="grey"
                  size="42"
                >
                  <img
                    v-if="showAvatar"
                    :src="avatar"
                  />
                  <span
                    class="white--text headline"
                    v-else
                  >{{ userdata.nickname | initial }}</span>
                </v-avatar>
              </div>
            </v-col>
            <v-col class="py-0 my-0">
              <div>
                <v-btn
                  icon
                  @click="showEditAvatar = true"
                  v-if="!showEditAvatar"
                >
                  <v-icon>
                    edit
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="showEditAvatar = false"
                  v-if="showEditAvatar"
                >
                  <v-icon>
                    close
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col></v-col>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6">
          <v-row class="py-0 my-0">
            <v-col class="py-0 my-0">
              <v-rating
                length="5"
                v-model="userdata.rating.rating"
                readonly
                half-increments
                class="pull-right"
              >
                <template v-slot:item="props">
                  <v-icon
                    :color="(props.isFilled || props.isHalfFilled) ? 'yellow darken-2' : 'grey lighten-1'"
                    class="pa-0"
                  >
                    {{ (props.isHalfFilled) ? 'star_half' : 'grade' }}
                  </v-icon>
                </template>
              </v-rating>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        v-if="showEditAvatar"
      >
        <v-col cols="8">
          <v-file-input
            accept="image/*"
            label="Profile Picture"
            v-on:change="setFileUpload"
            v-model="file.upload"
            ref="upload"
          ></v-file-input>
        </v-col>
        <v-col>
          <v-row>
            <v-col>
              <v-btn
                icon
                @click="saveAvatar()"
              >
                <v-icon>save</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="py-0 my-0">
        <v-col>
          <v-card
            class="px-4"
            elevation="2"
          >
            <v-row>
              <v-card-title>Payment Methods</v-card-title>
              <v-card-text
                v-for="pm in userdata.paymentmethods"
                :key="pm.paymentmethod_id"
              >
                <div>
                  <v-card-subtitle>
                    <a
                      href="https://help.venmo.com/hc/en-us/articles/209690068-How-to-Sign-Up"
                      target="_blank"
                      class="link"
                    >
                      Create a Venmo account here
                    </a>
                  </v-card-subtitle>
                  <v-card-subtitle>Update your Venmo handle below</v-card-subtitle>
                  <div
                    v-for="d in pm.details"
                    :key="d.name"
                  >
                    <!-- <label for="venmo">{{ d.name }}</label> -->
                    <v-text-field
                      label="Venmo Handle"
                      type="text"
                      prefix="@"
                      autocomplete="venmo"
                      v-model="d.value"
                      :readonly="!editpm"
                      class="text--black"
                      @keydown="preventInvalidVenmoHandle($event)"
                      @input="errors.venmohandle = ''; invalidVenmoHandle = null"
                      :error-messages="getErrors('venmohandle')"
                      @blur="$v.userdata.venmohandle.$touch()"
                    ></v-text-field>
                  </div>
                  <p class="red--text font-weight-bold" v-show="editpm">* ALERT: Providing an incorrect Venmo handle could lead to lost winnings. Neither the payor nor VigZero are responsible when the wrong Venmo handle is provided.</p>
                </div>
              </v-card-text>
            </v-row>
            <v-row class="py-0 my-0">
              <v-col class="py-0 my-0">
                <v-card-actions>
                  <v-row
                    v-if="!editpm"
                    justify="end"
                    class="py-0 my-0"
                  >
                    <v-btn
                      icon
                      @click="editpayments"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row
                    v-if="editpm"
                    justify="end"
                    class="py-0 my-0"
                  >
                    <span>{{ changepmmessage }}</span>
                    <v-btn
                      text
                      @click="updatepayment"
                    > Save </v-btn>
                    <v-btn
                      text
                      @click="editpayments"
                    > Cancel </v-btn>
                  </v-row>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="my-0 py-0">
        <v-col>
          <v-form
            v-model="valid"
            @submit.prevent="validateUser"
          >
            <v-card
              class="px-2"
              elevation="2"
            >
              <v-card-title>Account Information</v-card-title>

              <v-card-text>
                <v-text-field
                  label="First Name"
                  type="text"
                  id="first-name"
                  name="first-name"
                  autocomplete="given-name"
                  v-model="userdata.firstname"
                  :readonly="!editing"
                  :error-messages="getErrors('firstname')"
                  @blur="$v.userdata.firstname.$touch()"
                ></v-text-field>

                <v-text-field
                  label="Last Name"
                  type="text"
                  id="last-name"
                  name="last-name"
                  autocomplete="given-name"
                  v-model="userdata.lastname"
                  :readonly="!editing"
                  :error-messages="getErrors('lastname')"
                  @input="$v.userdata.lastname.$touch()"
                ></v-text-field>

                <v-text-field
                  label="Email"
                  type="text"
                  id="email"
                  name="email"
                  autocomplete="email"
                  v-model="userdata.email"
                  :readonly="!editing"
                  :error-messages="getErrors('email')"
                  @blur="$v.userdata.email.$touch()"
                ></v-text-field>

                <v-text-field
                  label="Nickname"
                  type="text"
                  id="nickname"
                  name="nickname"
                  autocomplete="nickname"
                  v-model="userdata.nickname"
                  :readonly="!editing"
                  :error-messages="getErrors('nickname')"
                  @blur="$v.userdata.nickname.$touch()"
                ></v-text-field>

                <v-text-field
                  label="Cell Phone"
                  type="text"
                  id="cellphone"
                  name="cellphone"
                  autocomplete="cellphone"
                  v-model="userdata.cellphone"
                  :readonly="true"
                  :error-messages="getErrors('cellphone')"
                  @blur="$v.userdata.cellphone.$touch()"
                ></v-text-field>
              </v-card-text>
              <v-row class="py-0 my-0">
                <v-col class="py-0 my-0">
                  <v-card-actions>
                    <v-row
                      v-if="!editing"
                      justify="end"
                    >
                      <v-btn
                        icon
                        @click="editInfo"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </v-row>
                    <v-row
                      v-if="editing"
                      justify="end"
                    >
                      <span>{{ changeinfomessage }}</span>
                      <v-btn
                        text
                        type="submit"
                      >Submit</v-btn>
                      <v-btn
                        text
                        @click="editInfo"
                      >Cancel</v-btn>
                    </v-row>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
      <v-row class="py-0 my-0">
        <v-col>
          <v-card class="px-2">
            <v-card-title>Change Password</v-card-title>
            <v-card-text>

              <v-text-field
                label="Current Password"
                id="oldpassword"
                name="oldpassword"
                autocomplete="oldpassword"
                v-model="changepassword.old"
                :disabled="!editpassword"
                :append-icon="showoldpassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showoldpassword ? 'text' : 'password'"
                @click:append="showoldpassword = !showoldpassword"
                :error-messages="getPasswordErrors('old')"
                required
                @blur="$v.changepassword.old.$touch()"
              ></v-text-field>

              <v-text-field
                label="New Password"
                id="newpassword"
                name="newpassword"
                autocomplete="newpassword"
                v-model="changepassword.password"
                :disabled="!editpassword"
                :append-icon="shownewpassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="shownewpassword ? 'text' : 'password'"
                @click:append="shownewpassword = !shownewpassword"
                :error-messages="getPasswordErrors('password')"
                required
                @blur="$v.changepassword.password.$touch()"
              ></v-text-field>

              <v-text-field
                label="Confirm New Password"
                id="comfirmnew"
                name="comfirmnew"
                autocomplete="confirmnew"
                v-model="changepassword.confirmpassword"
                :disabled="!editpassword"
                :append-icon="showconfirmpassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showconfirmpassword ? 'text' : 'password'"
                @click:append="showconfirmpassword = !showconfirmpassword"
                :error-messages="getPasswordErrors('confirmpassword')"
                required
                @blur="$v.changepassword.confirmpassword.$touch()"
              ></v-text-field>

            </v-card-text>
            <v-row>
              <v-col>
                <v-card-actions>
                  <v-row
                    v-if="!editpassword"
                    justify="end"
                  >
                    <v-btn
                      icon
                      @click="editpassword = true"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row
                    v-if="editpassword"
                    justify="end"
                  >
                    <v-btn
                      icon
                      @click="reseteditpassword()"
                    >
                      <v-icon>clear</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      @click="validatePw()"
                    >Submit</v-btn>
                  </v-row>
                </v-card-actions>
                <span class="green--text">{{successmessage}}</span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DashboardService from '@/services/DashboardService'
import _ from 'lodash'
import UserService from '@/services/UserService'
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
  minLength,
  sameAs
} from 'vuelidate/lib/validators'

const cellPhone = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  } else {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)
  }
}

export default {
  name: 'Account',
  mixins: [validationMixin],
  data: function () {
    return {
      valid: false,
      showoldpassword: false,
      shownewpassword: false,
      showconfirmpassword: false,
      userdata: {
        nickname: '',
        rating: {}
      },
      originaldata: {},
      editing: false,
      editpm: false,
      errors: {
        firstname: '',
        lastname: '',
        cellphone: '',
        email: '',
        nickname: '',
        details: ''
      },
      changepassword: {
        old: '',
        password: '',
        confirmpassword: ''
      },
      paymenterrors: {
        err: false,
        venmo: ''
      },
      paymentmethods: {
        venmo: ''
      },
      file: {
        upload: '',
        file: null
      },
      successmessage: '',
      changeinfomessage: '',
      changepmmessage: '',
      showEditAvatar: false,
      showAvatar: false,
      avatar: '',
      editpassword: false,
      skipVenmoValidation: false,
      invalidVenmoHandle: null,
      requiredRule: [
        v => !!v || 'required'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      numberRules: [
        v => !!v || 'Number is required',
        v => (v || '').length > 9 || 'must enter a valid number'
      ]
    }
  },
  validations: {
    userdata: {
      firstname: {
        required
      },
      lastname: {
        required
      },
      email: {
        required,
        email
      },
      nickname: {
        required
      },
      cellphone: {
        required,
        cellPhone,
        minLength: minLength(10)
      },
      venmohandle: {}
    },
    changepassword: {
      old: {
        required
      },
      password: {
        required
      },
      confirmpassword: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  created: function () {
    this.getuserdata()
    this.getAvatar()
  },
  computed: {
    winPercent: function () {
      var denom = parseInt(this.userdata.wins) + parseInt(this.userdata.losses) + parseInt(this.userdata.ties)
      var winPercentage = (this.userdata.wins / denom) * 100
      return Math.floor(winPercentage)
    }
  },
  methods: {
    preventInvalidVenmoHandle (e) {
      if (!/[a-zA-Z0-9\-_]/.test(e.key)) {
        e.preventDefault()
      }
      if (e.key !== this.invalidVenmoHandle) {
        this.invalidVenmoHandle = null
      }
      this.$v.userdata.venmohandle.$touch()
    },
    validateUser () {
      this.$v.userdata.$touch()

      if (!this.$v.userdata.$invalid) {
        this.changeInfo()
      }
    },
    validatePw () {
      this.$v.changepassword.$touch()

      if (!this.$v.changepassword.$invalid) {
        this.updatepassword()
      }
    },
    getErrors (fieldName) {
      const err = []
      if (!this.$v.userdata[fieldName].$dirty) return err
      if (fieldName === 'firstname') {
        !this.$v.userdata.firstname.required && err.push('First Name is required')
      }
      if (fieldName === 'lastname') {
        !this.$v.userdata.lastname.required && err.push('Last Name is required')
      }
      if (fieldName === 'email') {
        !this.$v.userdata.email.required && err.push('Email is required')
        !this.$v.userdata.email.email && err.push('Please enter a valid Email')
      }
      if (fieldName === 'nickname') {
        !this.$v.userdata.nickname.required && err.push('Nickname is required')
      }
      if (fieldName === 'cellphone') {
        !this.$v.userdata.cellphone.required && err.push('Cellphone is required')
        !this.$v.userdata.cellphone.cellPhone && err.push('must enter a valid number')
        !this.$v.userdata.cellphone.minLength && err.push('Cellphone is too short')
      }
      if (fieldName === 'venmohandle') {
        if (this.paymenterrors.err) {
          err.push(this.paymenterrors.venmo)
        }
      }
      return err
    },
    getPasswordErrors (fieldName) {
      const err = []
      if (!this.$v.changepassword[fieldName].$dirty) return err
      if (fieldName === 'old') {
        !this.$v.changepassword.old.required && err.push('password is required')
      }
      if (fieldName === 'password') {
        !this.$v.changepassword.password.required && err.push('new password is required')
      }
      if (fieldName === 'confirmpassword') {
        !this.$v.changepassword.confirmpassword.required && err.push('confirm new password is required')
        !this.$v.changepassword.confirmpassword.sameAsPassword && err.push('new passwords must match')
      }
      return err
    },
    editInfo () {
      if (this.editing) {
        // cancel, reset data
        this.userdata = _.clone(this.originaldata)
      }
      this.editing = !this.editing
    },
    editpayments () {
      this.paymenterrors.err = false
      this.paymenterrors.venmo = ''
      this.changepmmessage = ''
      if (this.editpm === true) {
        // cancel
        this.userdata = _.clone(this.originaldata)
      }
      this.editpm = !this.editpm
    },
    changeInfo () {
      const userInfo = {
        firstname: this.userdata.firstname,
        lastname: this.userdata.lastname,
        email: this.userdata.email,
        nickname: this.userdata.nickname,
        cellphone: this.userdata.cellphone
      }
      // reset all errors
      this.errors = {}
      this.$updateloading(1)
      UserService.updateUserInfo(userInfo)
        .then(res => {
          this.changeinfomessage = res.data.result
          this.getuserdata()
          this.editpm = false
          this.$v.$reset()
          // clear success message after a few seconds
          setTimeout(() => {
            this.changeinfomessage = ''
          }, 8000)
        }, res => {
          this.changeinfomessage = res.response.data.error
          this.errors = res.response.data.errors
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.$updateloading(-1)
        })
    },
    async updatepayment () {
      this.$updateloading(1)
      this.paymenterrors.err = false
      this.paymenterrors.venmo = ''
      let submit = true
      this.userdata.paymentmethods.forEach(element => {
        if (element.paymentmethod_id === 10) {
          element.details.forEach(async element => {
            const str = element.value.toString()
            if (!/^[a-zA-Z0-9\-_]+$/.test(str)) {
              submit = false
              this.paymenterrors.err = true
              this.paymenterrors.venmo = 'Venmo handles can only include letter, numbers, - and _'
              this.$updateloading(-1)
            } else {
              // check venmo handle URL
              await UserService.verifyVenmoHandle(str).then(
                (response) => {
                  if (!response.data.result) {
                    submit = false
                    this.paymenterrors.err = true
                    this.paymenterrors.venmo = response.data.message
                    this.editpm = true
                    this.$updateloading(-1)
                  } else {
                    this.$updateloading(-1)
                  }
                  if (submit) {
                    return this.savepaymentinfo()
                  }
                },
                (error) => {
                  console.dir(error)
                }
              )
            }
          })
        }
      })
    },
    async savepaymentinfo () {
      await UserService.updatepayment(this.userdata.paymentmethods)
        .then(
          (res) => {
            if (res.status === 200) {
              this.getuserdata()
              this.editpm = false
              var user = UserService.getUserObject()
              user.paymentmethod = this.originaldata.paymentmethods
              UserService.setLocalStorages(user)
            }
            this.$updateloading(-1)
          },
          (res) => {
            this.$updateloading(-1)
            if (res.response.status === 422) {
              this.changepmmessage = res.response.data.error
            } else {
              this.paymenterrors.err = true
              this.paymenterrors.venmo = [res.response.data.error]
            }
          })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.$updateloading(-1)
        })
    },
    updatepassword () {
      this.errors = {}
      this.$updateloading(1)
      UserService.updatepassword(this.changepassword)
        .then(res => {
          this.successmessage = 'Password Successfully changed'
          this.reseteditpassword()
          this.getuserdata()
          // clear success message after a bit
          setTimeout(() => {
            this.successmessage = ''
          }, 5000)
        }, res => {
          if (res.response.status === 422) {
            this.changepassmessage = res.response.data.error
            this.errors = res.response.data.errors
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.$updateloading(-1)
        })
    },

    getuserdata () {
      DashboardService.userData().then(
        (response) => {
          this.userdata = null
          this.userdata = response.data
          this.originaldata = _.clone(response.data)
        },
        (error) => {
          this.fatalerror = error.response.data.error
        })
    },

    setFileUpload: function () {
      console.log(this)
      this.file.file = this.$refs.upload.$refs.input.files[0]
    },

    saveAvatar: function () {
      this.$updateloading(1)
      const formData = new FormData()
      formData.append('upload', this.file.upload)
      formData.append('file', this.file.file)
      UserService.uploadavatar(formData).then(
        (response) => {
          console.log(response)
          if (response.data.success) {
            localStorage.avatar = JSON.stringify(response.data)
            this.getAvatar()
            this.showEditAvatar = false
          }
          this.$updateloading(-1)
        },
        (error) => {
          this.fatalerror = error.response.data.error
          this.$updateloading(-1)
        })
    },
    getAvatar: function () {
      let data = null
      try {
        data = JSON.parse(localStorage.avatar)
      } catch (err) {
      }
      if (!data) {
        this.avatar = null
        return
      }
      if (data.thumbnails && data.thumbnails.small) {
        this.avatar = data.thumbnails.small
      } else {
        this.avatar = data.avatar // use the full size image, thumbnails were not created
      }
      if (this.avatar) {
        this.showAvatar = true
      }
    },
    reseteditpassword: function () {
      this.errors = {}
      this.changepassword.confirmpassword = null
      this.changepassword.old = null
      this.changepassword.password = null
      this.changepassmessage = ''
      this.editpassword = false
      this.$v.$reset()
    }
  },
  filters: {
    money: function (value) {
      if (!value) return ''
      else if (value < 0) return '-$' + (value * -1)
      return '$' + value
    },
    positive: function (val) {
      if (!val) return '$0'
      return '+ ' + val
    },
    negative: function (val) {
      if (!val) return '$0'
      return '- ' + val
    },
    initial: function (val) {
      if (!val) return ''
      return val.substring(0, 1)
    }
  }
}
</script>

<style scoped>
.row {
  margin-bottom: 0;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.stat-space {
  display: inline-block;
  margin-right: 1.5em;
}
/* screw material */
.md-error {
  opacity: 1 !important;
}
.spacer {
  min-height: 2vh;
}
</style>
