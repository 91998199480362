import axios from 'axios'
import Vue from 'vue'
import Notifications from 'vue-notification'
import UserService from '@/services/UserService'

Vue.use(Notifications)
/*
$app->group('/disputes', function (RouteCollectorProxy $group) use ($app) {
  $group->post('/init[/]', \Aim\Controller\DisputeController::class . ':init');
  $group->post('/search[/]', \Aim\Controller\DisputeController::class . ':search');
  $group->get('/get[/]', \Aim\Controller\DisputeController::class . ':get');
  $group->post('/create[/]', \Aim\Controller\DisputeController::class . ':create');
  $group->post('/update/{dispute_id}[/]', \Aim\Controller\DisputeController::class . ':update');
}); */
const DisputeService = {

  async init (filters, perpage, page, sort) {
    await UserService.authHeader()
    var data = {
      filters: filters,
      perpage: perpage,
      page: page,
      sort: sort
    }
    return axios({
      url: UserService.baseUrl() + 'disputes/init',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async search (filters, perpage, page, sort) {
    await UserService.authHeader()
    var data = {
      filters: filters,
      perpage: perpage,
      page: page,
      sort: sort
    }
    return axios({
      url: UserService.baseUrl() + 'disputes/search',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async get (id) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'disputes/' + id,
      method: 'get'
    })
  },

  async initCreate (wagerId) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'disputes/create/init',
      data: JSON.stringify({ wager_id: wagerId }),
      method: 'post'
    })
  },

  async create (data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'disputes/create',
      data: data,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },

  async initUpdate (disputeId) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'disputes/update/init',
      data: JSON.stringify({ dispute_id: disputeId }),
      method: 'post'
    })
  },

  async update (disputeId, data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'disputes/update/' + disputeId,
      data: data,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },

  async suspend (disputeId, data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'disputes/suspend/' + disputeId,
      data: data,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }
}

export default DisputeService
