<template>
  <v-form
    class="py-4"
    v-model="formValid"
    @submit.prevent="createContest"
  >
    <v-card>
      <v-card-title
        class="justify-center"
        v-if="!contest"
      >Contest Settings</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="form.entryfee"
          label="Entry Fee *"
          type="number"
          step="5"
          min="10"
          max="100"
          :rules="entryFeeRules"
          prepend-icon="attach_money"
          :readonly="contest && !contest.open"
        >
        </v-text-field>

        <v-text-field
          v-model="form.minimum"
          label="Minimum Participants"
          type="number"
          :disabled="contesttype && contesttype.minimum > 0"
          prepend-icon="mdi-account"
        >
        </v-text-field>

        <v-text-field
          v-model="form.maximum"
          label="Maximum Participants"
          type="number"
          :disabled="contesttype && contesttype.maximum > 0"
          prepend-icon="mdi-account-multiple"
        >
        </v-text-field>

        <v-text-field
          v-model="form.limitperuser"
          label="Max Entries Per User (0 for unlimited)"
          type="number"
          min="0"
          prepend-icon="mdi-ticket"
          :readonly="contest && !contest.open"
        >
        </v-text-field>

        <v-card-actions v-if="!contest || contest.open">
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!formValid || loading"
            @click.prevent="save"
            color="success"
            elevation="2"
          >{{ (contest) ? 'Save' : 'Create' }}</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import ContestService from '@/services/ContestService'
import { validationMixin } from 'vuelidate'
import {
  required
} from 'vuelidate/lib/validators'

export default {
  name: 'ContestSettings',

  props: {
    group_id: {
      type: String,
      default: '0'
    },
    contesttype: {
      type: Object,
      default: null
    },
    contest: {
      type: Object,
      default: null
    }
  },

  data: function () {
    return {
      loading: false,
      form: {
        entryfee: 10,
        minimum: null,
        maximum: null,
        limitperuser: 1
      },
      formValid: false,
      entryFeeRules: [
        v => !!v || 'Entry fee is required'
      ]
    }
  },

  mounted () {
    if (this.contesttype && this.contesttype.minimum > 0) {
      this.form.minimum = this.contesttype.minimum
    }
    if (this.contesttype && this.contesttype.maximum > 0) {
      this.form.maximum = this.contesttype.maximum
    }

    if (this.contest && this.contest.id > 0) {
      this.form.entryfee = this.contest.entryfee
      this.form.limitperuser = this.contest.limitperuser
    }
  },

  methods: {
    save () {
      if (this.formValid && !this.loading) {
        this.loading = true
        var data = {
          friendgroup_id: this.group_id,
          entryfee: this.form.entryfee,
          minimum: this.form.minimum,
          maximum: this.form.maximum,
          limitperuser: this.form.limitperuser
        }
        if (this.contest && this.contest.id > 0) {
          data.contest_id = this.contest.id
          data.contesttype_id = this.contest.contesttype_id
        } else if (this.contesttype && this.contesttype.id > 0) {
          data.contesttype_id = this.contesttype.id
        }
        ContestService.saveContest(data).then(
          (response) => {
            if (response.data.contest_id) {
              if (this.contest && this.contest.id > 0) {
                // this was an update, trigger refresh
                this.$notify({
                  group: 'notices',
                  title: 'Contest Saved',
                  text: 'Your contest has been saved and is available for entry.',
                  type: 'info'
                })
                this.$emit('refresh')
              } else {
                this.$router.push('/group/' + this.group_id + '/contest/' + response.data.contest_id, () => {
                  this.$notify({
                    group: 'notices',
                    title: 'Contest Saved',
                    text: 'Your contest has been saved and is available for entry.',
                    type: 'info'
                  })
                })
              }
            } else {
              this.$notify({
                group: 'errors',
                title: 'Error',
                text: 'Failed to save contest. Please try again later.',
                type: 'warn'
              })
            }
            this.loading = false
          }
        ).catch(err => {
          this.loading = false
          this.$notify({
            group: 'errors',
            title: err.response.data.errors[0],
            text: err.response.data.error,
            type: 'warn'
          })
        })
      }
    }
  },

  mixins: [validationMixin],

  validations: {
    form: {
      entryfee: {
        required
      }
    }
  }
}
</script>
