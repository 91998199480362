var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dispute && _vm.dispute.id && !_vm.dispute.closed
    ? _c(
        "v-card",
        { staticClass: "vig-card mb-4" },
        [
          _c(
            "v-card-title",
            {
              staticClass: "py-0",
              on: {
                click: function($event) {
                  _vm.expand = !_vm.expand
                }
              }
            },
            [_vm._v(" Suspend Users ")]
          ),
          _c("v-expand-transition", [
            _vm.expand
              ? _c(
                  "div",
                  {
                    staticClass: "vig-card-expand",
                    staticStyle: { "margin-top": "0.5em" }
                  },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c("v-select", {
                          attrs: {
                            placeholder: "Duration of Suspension",
                            items: _vm.suspensionDurations,
                            "item-text": "name",
                            "item-value": "length",
                            label: "Duration"
                          },
                          model: {
                            value: _vm.form.duration,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "duration", $$v)
                            },
                            expression: "form.duration"
                          }
                        })
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _c(
                      "v-card-actions",
                      [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("p", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.dispute.winner_id ===
                                      _vm.dispute.offeredby_id
                                      ? _vm.dispute.offeredby
                                      : _vm.dispute.takenby
                                  )
                                )
                              ])
                            ]),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled: _vm.$v.$invalid,
                                  small: "",
                                  dense: "",
                                  color: "error"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.suspend(_vm.dispute.winner_id)
                                  }
                                }
                              },
                              [_vm._v("Suspend Winner")]
                            )
                          ],
                          1
                        ),
                        _c("v-spacer"),
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("p", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.dispute.loser_id ===
                                      _vm.dispute.offeredby_id
                                      ? _vm.dispute.offeredby
                                      : _vm.dispute.takenby
                                  )
                                )
                              ])
                            ]),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled: _vm.$v.$invalid,
                                  dense: "",
                                  color: "error",
                                  small: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.suspend(_vm.dispute.loser_id)
                                  }
                                }
                              },
                              [_vm._v("Suspend Loser")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("v-card-actions", { staticClass: "pa-0" }, [
            _c(
              "div",
              {
                staticClass: "vig-card-expand-container",
                on: {
                  click: function($event) {
                    _vm.expand = !_vm.expand
                  }
                }
              },
              [
                _c("v-icon", [
                  _vm._v(
                    _vm._s(_vm.expand ? "arrow_drop_up" : "arrow_drop_down")
                  )
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }