var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.dispute && _vm.dispute.id
        ? _c(
            "v-row",
            { staticClass: "py-0 my-0 mb-4" },
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "vig-card" },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "py-0",
                          on: {
                            click: function($event) {
                              _vm.expand = !_vm.expand
                            }
                          }
                        },
                        [
                          _vm._v(
                            " Dispute #" +
                              _vm._s(_vm.dispute.id) +
                              " - " +
                              _vm._s(_vm.dispute.disputetype) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-card-text",
                        {
                          staticClass: "py-0",
                          on: {
                            click: function($event) {
                              _vm.expand = !_vm.expand
                            }
                          }
                        },
                        [
                          _vm.dispute.closed
                            ? _c("div", [
                                _c("h2", { staticClass: "text-center" }, [
                                  _vm._v("This dispute has been closed.")
                                ]),
                                _c("h2", { staticClass: "text-center" }, [
                                  _vm._v(_vm._s(_vm.dispute.closed))
                                ])
                              ])
                            : _vm._e(),
                          !_vm.dispute.closed &&
                          !_vm.requiresActionFromOwner &&
                          !_vm.requiresActionFromOther
                            ? _c("div", [
                                _c("h2", { staticClass: "text-center" }, [
                                  _vm._v("This dispute is ready for review.")
                                ])
                              ])
                            : _vm._e(),
                          _vm.requiresActionFromOwner
                            ? _c("div", [
                                _c("h2", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "This dispute is awaiting a response from " +
                                      _vm._s(_vm.dispute.disputeuser) +
                                      "."
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm.requiresActionFromOther
                            ? _c("div", [
                                _c("h2", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "This dispute is awaiting a response from " +
                                      _vm._s(_vm.dispute.againstuser) +
                                      "."
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]
                      ),
                      _c("v-expand-transition", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.expand,
                                expression: "expand"
                              }
                            ],
                            staticStyle: { "margin-top": "0.5em" }
                          },
                          [
                            _c("v-divider"),
                            _c(
                              "v-card-text",
                              { staticClass: "pt-5 pb-0" },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "my-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            label: "Dispute Type",
                                            items: _vm.disputetypes,
                                            "item-text": "name",
                                            "item-value": "id",
                                            dense: "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.form.disputetype_id,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "disputetype_id",
                                                $$v
                                              )
                                            },
                                            expression: "form.disputetype_id"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pb-0 pt-5",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            label: "Dispute Type",
                                            items: _vm.disputestatuses,
                                            "item-text": "name",
                                            "item-value": "id",
                                            dense: "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.form.disputestatus_id,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "disputestatus_id",
                                                $$v
                                              )
                                            },
                                            expression: "form.disputestatus_id"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pb-0 pt-5",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c("v-textarea", {
                                          attrs: {
                                            label: "Comments",
                                            outlined: "",
                                            rows: "1",
                                            dense: "",
                                            "auto-grow": "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.form.description,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "description",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "form.description"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-card-actions",
                              { staticClass: "pb-0 pt-3" },
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      disabled: _vm.$v.$invalid,
                                      dense: "",
                                      color: "primary"
                                    },
                                    on: { click: _vm.update }
                                  },
                                  [_vm._v("Update Dispute")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("v-card-actions", { staticClass: "pa-0" }, [
                        _c(
                          "div",
                          {
                            staticClass: "vig-card-expand-container",
                            on: {
                              click: function($event) {
                                _vm.expand = !_vm.expand
                              }
                            }
                          },
                          [
                            _c("v-icon", [
                              _vm._v(
                                _vm._s(
                                  _vm.expand
                                    ? "arrow_drop_up"
                                    : "arrow_drop_down"
                                )
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("WagerInfo", { attrs: { wager: _vm.wager } }),
      _c("DisputeLog", { attrs: { dispute: _vm.dispute } }),
      _c("DisputeImages", { attrs: { dispute: _vm.dispute } }),
      _c("DisputeActions", { attrs: { dispute: _vm.dispute } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }