var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper fadeInDown" }, [
    _c(
      "div",
      { attrs: { id: "login-form" } },
      [
        _c(
          "v-form",
          {
            ref: "theLoginForm",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.login()
              }
            },
            model: {
              value: _vm.valid,
              callback: function($$v) {
                _vm.valid = $$v
              },
              expression: "valid"
            }
          },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-card",
                      { staticClass: "px-3" },
                      [
                        _c("v-card-title", [_vm._v("Login")]),
                        _c(
                          "v-card-text",
                          [
                            _c("v-text-field", {
                              attrs: {
                                name: "email",
                                id: "email",
                                type: "email",
                                label: "Email",
                                disabled: _vm.sending,
                                required: ""
                              },
                              model: {
                                value: _vm.email,
                                callback: function($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email"
                              }
                            }),
                            _c("v-text-field", {
                              attrs: {
                                name: "password",
                                id: "password",
                                label: "Password",
                                disabled: _vm.sending,
                                "append-icon": _vm.showpassword
                                  ? "mdi-eye"
                                  : "mdi-eye-off",
                                type: _vm.showpassword ? "text" : "password",
                                required: ""
                              },
                              on: {
                                "click:append": function($event) {
                                  _vm.showpassword = !_vm.showpassword
                                }
                              },
                              model: {
                                value: _vm.password,
                                callback: function($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c("div", [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: "/forgotpassword" }
                                },
                                [_vm._v("Forgot Password")]
                              )
                            ]),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                staticClass: "pull-right",
                                attrs: {
                                  type: "submit",
                                  elevation: "2",
                                  raised: "",
                                  color: "secondary",
                                  disabled: _vm.sending || _vm.formInvalid()
                                }
                              },
                              [_vm._v(" Login ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { align: "center" } },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      to: "/register",
                      elevation: "2",
                      raised: "",
                      color: "primary"
                    }
                  },
                  [_vm._v("Register Now")]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._m(0),
        _c("br"),
        _c("div", [
          _c(
            "a",
            {
              staticClass: "underlineHover",
              style: { display: _vm.installBtn },
              on: {
                click: function($event) {
                  return _vm.installer()
                }
              }
            },
            [_vm._v("Install PWA")]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "text-align": "center",
          "font-size": "0.8em",
          "font-weight": "bold"
        }
      },
      [
        _vm._v(
          " Must be 21+ - Gambling Problem? Help is available from the toll-free nationwide hotline, call: 1-800-GAMBLER, chat: "
        ),
        _c(
          "a",
          {
            attrs: { href: "https://www.ncpgambling.org/help-treatment/chat/" }
          },
          [_vm._v("https://www.ncpgambling.org/help-treatment/chat/")]
        ),
        _vm._v(", or visit "),
        _c(
          "a",
          { attrs: { href: "https://www.ncpgambling.org/help-treatment/" } },
          [_vm._v("https://www.ncpgambling.org/help-treatment/")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }