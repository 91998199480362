var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "tourney-team ma-0",
      class: _vm.getTeamClass,
      attrs: { "no-gutters": "" }
    },
    [
      _c(
        "v-col",
        {
          staticClass: "py-1 px-1 bordered-right",
          attrs: { cols: "9" },
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.chooseWager(
                _vm.game.game,
                _vm.isHomeTeam
                  ? _vm.game.game.hometeam_id
                  : _vm.game.game.awayteam_id,
                "line"
              )
            }
          }
        },
        [
          _c(
            "v-row",
            { staticClass: "teambox ma-0", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "9", align: "left" } },
                [
                  _c("h2", [
                    _vm._v(
                      _vm._s(
                        _vm.tournament.assignedteams[_vm.game.round][_vm.team]
                          .nickname
                      )
                    )
                  ]),
                  _c("h1", [
                    _vm._v(
                      _vm._s(
                        _vm.team === _vm.game.favorite
                          ? _vm.game.favorite_team
                          : _vm.game.underdog_team
                      )
                    )
                  ])
                ]
              ),
              _c("v-col", { staticClass: "pa-0", attrs: { align: "right" } }, [
                _c("h1", [_vm._v(_vm._s(_vm.showSpread()))])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "pa-0 text-center",
          attrs: { "align-self": "center" },
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.chooseWager(
                _vm.game.game,
                _vm.team === _vm.game.favorite
                  ? _vm.game.game.hometeam_id
                  : _vm.game.game.awayteam_id,
                _vm.team === _vm.game.favorite ? "over" : "under"
              )
            }
          }
        },
        [
          _vm.game.game && _vm.game.game.hometeamscore
            ? _c("h1", [
                _vm._v(
                  _vm._s(
                    _vm.isHomeTeam
                      ? _vm.game.game.hometeamscore
                      : _vm.game.game.awayteamscore
                  )
                )
              ])
            : _c(
                "h1",
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.game.game && _vm.game.game.ou_line
                          ? _vm.game.game.ou_line
                          : "N/A"
                      ) +
                      " "
                  ),
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.team === _vm.game.favorite
                          ? "mdi-arrow-up"
                          : "mdi-arrow-down"
                      )
                    )
                  ])
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }