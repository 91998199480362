var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _vm.isadmin
        ? _c(
            "v-row",
            { staticClass: "ma-0", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-0 pr-1", attrs: { cols: "9" } },
                [
                  _c("v-select", {
                    attrs: {
                      label: "Dispute Type",
                      items: _vm.disputetypes,
                      "item-text": "name",
                      "item-value": "id",
                      dense: ""
                    },
                    model: {
                      value: _vm.searchData.filters.disputetype_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData.filters, "disputetype_id", $$v)
                      },
                      expression: "searchData.filters.disputetype_id"
                    }
                  }),
                  _c("v-select", {
                    attrs: {
                      items: _vm.disputestatuses,
                      label: "Status",
                      "item-text": "name",
                      "item-value": "id",
                      required: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.searchData.filters.disputestatus_id,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.searchData.filters,
                          "disputestatus_id",
                          $$v
                        )
                      },
                      expression: "searchData.filters.disputestatus_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0 text-right", attrs: { cols: "3" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { dense: "", color: "primary" },
                      on: { click: _vm.search }
                    },
                    [_vm._v("Search")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isadmin ? _c("v-divider", { staticClass: "py-2" }) : _vm._e(),
      _c(
        "v-row",
        {},
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [_c("DisputeList", { attrs: { disputes: _vm.disputes } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }