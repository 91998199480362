<template>
  <div class="about">
    <v-row class="mt-0">
      <v-col
        cols="12"
        class="mt-0 pt-0"
      >
        <v-card>
          <v-row
            class="mt-0 px-4 pt-4"
            justify="center"
          >
            <v-col cols="12" class="py-0 text-center">
              <img
                src="../assets/black_red_icon/512.png"
                style="max-width:25%; border-radius:0; height: auto"
              >
            </v-col>
          </v-row>
          <v-row justify="center">
            <span
              class="robo"
              style="font-size:1.8em"
            >FAQ</span>
          </v-row>

          <v-card-text>
            <v-row class="ma-0">
              <v-col
                cols="12"
                class="py-0 px-0 black--text"
                @click="clickexpander(1)"
              >
                <v-row
                  align="center"
                  class="ma-0"
                >
                  <v-col cols="1">
                    <span>
                      <v-icon color="black">{{ expanded == 1 ? 'remove_circle_outline' : 'add_circle_outline' }}</v-icon>
                    </span>
                  </v-col>
                  <v-col>
                    How do I install VigZero App on my Android phone?
                  </v-col>
                </v-row>

                <v-expand-transition>
                  <v-card
                    block
                    v-show="expanded == 1"
                    class="mx-auto pa-3"
                  >
                    Use Chrome and visit <a
                      class="link"
                      target="_blank"
                      href="https://app.vigzero.com"
                    >app.vigzero.com</a>.
                    Once logged in, click on the 3 dots in the top right-hand corner and choose 'Add to Home Screen'.
                    Choose 'Add'.
                  </v-card>
                </v-expand-transition>

                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row class="ma-0">
              <v-col
                cols="12"
                class="py-0 px-0 black--text"
                @click="clickexpander(2)"
              >
                <v-row
                  justify="start"
                  align="center"
                  class="ma-0"
                >
                  <v-col cols="1"><span>
                      <v-icon color="black">{{ expanded == 2 ? 'remove_circle_outline' : 'add_circle_outline' }}</v-icon>
                    </span></v-col>
                  <v-col>How do I install VigZero App on my iPhone?</v-col>
                </v-row>
                <v-expand-transition>
                  <v-card
                    block
                    v-show="expanded == 2"
                    class="mx-auto pa-3"
                  >
                    Use Safari and visit <a
                      class="link"
                      target="_blank"
                      href="https://app.vigzero.com"
                    >app.vigzero.com</a>.
                    Once logged in, click the center [box with upward arrow] button at the bottom of the screen.
                    Scroll down and choose ‘Add to Home Screen’. Choose ‘Add’.
                  </v-card>
                </v-expand-transition>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row class="ma-0">
              <v-col
                cols="12"
                class="py-0 px-0 black--text"
                @click="clickexpander(3)"
              >
                <v-row
                  justify="start"
                  align="center"
                  class="ma-0"
                >
                  <v-col cols="1"><span>
                      <v-icon color="black">{{ expanded == 3 ? 'remove_circle_outline' : 'add_circle_outline' }}</v-icon>
                    </span></v-col>
                  <v-col>What payment method am I supposed to use?</v-col>
                </v-row>
                <v-expand-transition>
                  <v-card
                    block
                    v-show="expanded == 3"
                    class="mx-auto pa-3"
                  >
                    Although we hope to add additional methods in the future, Venmo is currently required to settle bets.
                  </v-card>
                </v-expand-transition>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row class="ma-0">
              <v-col
                cols="12"
                class="py-0 px-0 black--text"
                @click="clickexpander(10)"
              >
                <v-row
                  justify="start"
                  align="center"
                  class="ma-0"
                >
                  <v-col cols="1"><span>
                      <v-icon color="black">{{ expanded == 10 ? 'remove_circle_outline' : 'add_circle_outline' }}</v-icon>
                    </span></v-col>
                  <v-col>What if I paid the wrong person?</v-col>
                </v-row>
                <v-expand-transition>
                  <v-card
                    block
                    v-show="expanded == 10"
                    class="mx-auto pa-3"
                  >
                    First, pay the correct person by using the link/handle provided to ensure you pay the correct person.
                    Although Venmo gives no guarantees, request your money back in from them ASAP by following their process outlined <a href="https://help.venmo.com/hc/en-us/articles/209681208" target="_blank">here</a>
                  </v-card>
                </v-expand-transition>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row class="ma-0">
              <v-col
                cols="12"
                class="py-0 px-0 black--text"
                @click="clickexpander(9)"
              >
                <v-row
                  justify="start"
                  align="center"
                  class="ma-0"
                >
                  <v-col cols="1">
                    <span>
                      <v-icon color="black">{{ expanded == 9 ? 'remove_circle_outline' : 'add_circle_outline' }}</v-icon>
                    </span>
                  </v-col>
                  <v-col>How do I boost my rating and why is it important to do so?</v-col>
                </v-row>
                <v-expand-transition>
                  <v-card
                    block
                    v-show="expanded == 9"
                    class="mx-auto pa-3"
                  >
                    A member's rating is determined by a variety of factors including but not limited to:
                    <ul>
                      <li>How long it takes you to pay your lost bets and confirm your payments in VigZero.</li>
                      <li>How long it takes you to confirm that you have been paid for a winning bet.</li>
                      <li>How many bets you make or take (activity).</li>
                      <li>How many of your invitees are in good standing.</li>
                      <li>How many different members you have placed bets with.</li>
                    </ul>
                    <p>A member's rating is important.  If a rating drops too low, the member can be removed from the platform.
                      Members with high ratings have a higher likelihood of getting their offers taken as their offers are promoted.</p>
                  </v-card>
                </v-expand-transition>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row class="ma-0">
              <v-col
                cols="12"
                class="py-0 px-0 black--text"
                @click="clickexpander(7)"
              >
                <v-row
                  justify="start"
                  align="center"
                  class="ma-0"
                >
                  <v-col cols="1"><span>
                      <v-icon color="black">{{ expanded == 7 ? 'remove_circle_outline' : 'add_circle_outline' }}</v-icon>
                    </span></v-col>
                  <v-col>How do I favorite a team in order to see offers in 'My Teams'?</v-col>
                </v-row>
                <v-expand-transition>
                  <v-card
                    block
                    v-show="expanded == 7"
                    class="mx-auto pa-3"
                  >
                    Favorite teams can be selected from the 'Schedule' section. When you select a team you are interested in making a favorite,
                    tap the heart icon to the left of your team's name to add it to 'My Teams'.
                    To remove an existing favorite, tap the heart icon again.
                  </v-card>
                </v-expand-transition>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row class="ma-0">
              <v-col
                cols="12"
                class="py-0 px-0 black--text"
                @click="clickexpander(8)"
              >
                <v-row
                  justify="start"
                  align="center"
                  class="ma-0"
                >
                  <v-col cols="1"><span>
                      <v-icon color="black">{{ expanded == 8 ? 'remove_circle_outline' : 'add_circle_outline' }}</v-icon>
                    </span></v-col>
                  <v-col>How often are the current lines of games updated?</v-col>
                </v-row>
                <v-expand-transition>
                  <v-card
                    block
                    v-show="expanded == 8"
                    class="mx-auto pa-3"
                  >
                    The closer a game gets to its starting time, the more frequent line updates occur.
                    Remember that unless 'auto adjust' is selected on a bet offer, the line does not change for those available bets.
                  </v-card>
                </v-expand-transition>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row class="ma-0">
              <v-col
                cols="12"
                class="py-0 px-0 black--text"
                @click="clickexpander(4)"
              >
                <v-row
                  justify="start"
                  align="center"
                  class="ma-0"
                >
                  <v-col cols="1">
                    <span>
                      <v-icon color="black">{{ expanded == 4 ? 'remove_circle_outline' : 'add_circle_outline' }}</v-icon>
                    </span>
                  </v-col>
                  <v-col>
                    How do I get a Venmo Account?
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <v-card
                    block
                    v-show="expanded == 4"
                    class="mx-auto pa-3"
                  >
                    Visit <a
                      class="link"
                      target="_blank"
                      href="https://help.venmo.com/hc/en-us/articles/209690068-How-to-Sign-Up"
                    >Venmo</a> for details.
                  </v-card>
                </v-expand-transition>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row class="ma-0">
              <v-col
                cols="12"
                class="py-0 px-0 black--text"
                @click="clickexpander(5)"
              >
                <v-row
                  justify="start"
                  align="center"
                  class="ma-0"
                >
                  <v-col cols="1"><span>
                      <v-icon color="black">{{ expanded == 5 ? 'remove_circle_outline' : 'add_circle_outline' }}</v-icon>
                    </span></v-col>
                  <v-col>Do I have to deposit money in this app to bet on a game?</v-col>
                </v-row>

                <v-expand-transition>
                  <v-card
                    block
                    v-show="expanded == 5"
                    class="mx-auto pa-3"
                  >
                    VigZero does not hold money or deposits for members.
                    VigZero is a peer to peer gaming solution where you are responsible for paying out
                    your losses directly to the winner through Venmo. Members are rated based on their speed at settling wagers.
                    Any members with unsettled wagers are prevented from making bets.
                    A member's limit is based on their history of paying their losses.
                  </v-card>
                </v-expand-transition>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row
              justify="start"
              class="ma-0"
            >
              <v-col
                cols="12"
                class="py-0 px-0 black--text"
                @click="clickexpander(6)"
              >
                <v-row
                  justify="start"
                  align="center"
                  class="ma-0"
                >
                  <v-col cols="1"><span>
                      <v-icon color="black">{{ expanded == 6 ? 'remove_circle_outline' : 'add_circle_outline' }}</v-icon>
                    </span></v-col>
                  <v-col>How can I get my betting limit higher?</v-col>
                </v-row>

                <v-expand-transition>
                  <v-card
                    block
                    v-show="expanded == 6"
                    class="mx-auto pa-3"
                  >
                    Your total betting limit increases as payments for your losses are confirmed.
                    Limits are not increased for repeat wagers with the same Member.
                  </v-card>
                </v-expand-transition>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row
              justify="start"
              class="ma-0"
            >
              <v-col
                cols="12"
                class="py-0 px-0 black--text"
                @click="clickexpander(11)"
              >
                <v-row
                  justify="start"
                  align="center"
                  class="ma-0"
                >
                  <v-col cols="1"><span>
                      <v-icon color="black">{{ expanded == 11 ? 'remove_circle_outline' : 'add_circle_outline' }}</v-icon>
                    </span></v-col>
                  <v-col>Did you miss one of our release notes pop ups?</v-col>
                </v-row>

                <v-expand-transition>
                  <v-card
                    block
                    v-show="expanded == 11"
                    class="mx-auto pa-3"
                  >
                    to see a complete list of our release notes, you can view them in our
                    <router-link
                      to="/notes"
                    >notes</router-link>
                  </v-card>
                </v-expand-transition>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row
              justify="start"
              class="ma-0"
            >
              <v-col
                cols="12"
                class="py-0 px-0 black--text"
                @click="clickexpander(12)"
              >
                <v-row
                  justify="start"
                  align="center"
                  class="ma-0"
                >
                  <v-col cols="1"><span>
                      <v-icon color="black">{{ expanded == 11 ? 'remove_circle_outline' : 'add_circle_outline' }}</v-icon>
                    </span></v-col>
                  <v-col>How can I learn more about responsible gambling?</v-col>
                </v-row>

                <v-expand-transition>
                  <v-card
                    block
                    v-show="expanded == 12"
                    class="mx-auto pa-3"
                  >
                    Must be 21+ - Gambling Problem? Help is available from the toll-free nationwide hotline, call: 1-800-GAMBLER,
                    chat: <a href="https://www.ncpgambling.org/help-treatment/chat/">https://www.ncpgambling.org/help-treatment/chat/</a>,
                    or visit <a href="https://www.ncpgambling.org/help-treatment/">https://www.ncpgambling.org/help-treatment/</a>
                  </v-card>
                </v-expand-transition>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      expanded: -1
    }
  },
  methods: {
    clickexpander: function (id) {
      if (this.expanded === id) {
        this.expanded = -1
      } else {
        this.expanded = id
      }
    }
  }
}
</script>
<style scoped>
.expanded {
  max-height: 50vh;
  padding-bottom: 0.5em;
  padding-top: 1em;
}
</style>
