var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.contesttype && _vm.group
    ? _c(
        "div",
        { staticClass: "wrapper fadeInDown text-center" },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h1", [_vm._v(_vm._s(_vm.group.name))]),
                _c("h1", [_vm._v(_vm._s(_vm.contesttype.name))])
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [_c("v-col", [_c("p", [_vm._v(_vm._s(_vm.contesttype.summary))])])],
            1
          ),
          _vm.contesttype
            ? _c("ContestPrizes", { attrs: { contest: _vm.contesttype } })
            : _vm._e(),
          _vm.contesttype
            ? _c("ContestRules", { attrs: { contest: _vm.contesttype } })
            : _vm._e(),
          _vm.contesttype
            ? _c("ContestSettings", {
                attrs: { contesttype: _vm.contesttype, group_id: _vm.group_id }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }