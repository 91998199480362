var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app",
        [
          _c(
            "v-app-bar",
            {
              attrs: {
                app: "",
                dense: "",
                dark: "",
                width: "100%",
                "max-width": "600px",
                absolute: ""
              }
            },
            [
              _c(
                "v-app-bar-nav-icon",
                {
                  on: {
                    click: function($event) {
                      _vm.menuVisible = true
                    }
                  }
                },
                [_c("v-icon", [_vm._v("menu")])],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "div",
                            _vm._g(_vm._b({}, "div", attrs, false), on),
                            [
                              _c(
                                "v-row",
                                { staticClass: "my-0 pb-0 pt-2 ml-n4" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0 text-center",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "logo mt-n1",
                                        attrs: { src: _vm.getLogo() }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0 text-center",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.vigsavings != 0,
                                              expression: "vigsavings != 0"
                                            }
                                          ],
                                          staticClass:
                                            "sub-text white--text mt-n1 ml-2"
                                        },
                                        [
                                          _vm._v(
                                            "$" +
                                              _vm._s(_vm.vigsavings) +
                                              " saved and counting"
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.menuOpen,
                    callback: function($$v) {
                      _vm.menuOpen = $$v
                    },
                    expression: "menuOpen"
                  }
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "menu-item",
                          attrs: { to: "/dashboard/schedule" }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "menu-icon", attrs: { size: "32" } },
                            [_vm._v("$schedule")]
                          ),
                          _vm._v(" Schedule ")
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "menu-item",
                          attrs: { to: "/dashboard/pending" }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "menu-icon", attrs: { size: "32" } },
                            [_vm._v("$pending")]
                          ),
                          _vm._v(" Pending ")
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "menu-item",
                          attrs: { to: "/dashboard/bestvalue" }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "menu-icon",
                              staticStyle: { "margin-left": "6px" },
                              attrs: { size: "32" }
                            },
                            [_vm._v("$bestvalue")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "-6px" } },
                            [_vm._v("Open Offers")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "menu-item",
                          attrs: { to: "/dashboard/group" }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "menu-icon", attrs: { size: "32" } },
                            [_vm._v("$group")]
                          ),
                          _vm._v(" My Groups / Contests ")
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "menu-item",
                          attrs: { to: "/dashboard/favorite" }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "menu-icon", attrs: { size: "32" } },
                            [_vm._v("$myteams")]
                          ),
                          _vm._v(" My Teams ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-spacer"),
              !_vm.username
                ? _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0",
                          attrs: { outlined: "", color: "white", to: "/login" }
                        },
                        [_vm._v("Login")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.username
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        { staticClass: "py-0 my-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0 my-0", attrs: { cols: "5" } },
                            [
                              _c(
                                "div",
                                { staticClass: "balance-display mt-4" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.formatAmount(_vm.userBalance))
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0 my-0", attrs: { cols: "7" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: { left: "", bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  { attrs: { icon: "" } },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm.avatar
                                                  ? _c("v-avatar", [
                                                      _c("img", {
                                                        attrs: {
                                                          src: _vm.avatar
                                                        }
                                                      })
                                                    ])
                                                  : _c("v-icon", [
                                                      _vm._v("account_circle")
                                                    ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    4201054349
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "menu-item",
                                          attrs: { to: "/settings" }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "menu-icon" },
                                            [_vm._v("settings")]
                                          ),
                                          _vm._v(" Settings ")
                                        ],
                                        1
                                      ),
                                      !_vm.isiOS()
                                        ? _c(
                                            "v-list-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.isSubscribed == false &&
                                                    !_vm.notificationsBlocked,
                                                  expression:
                                                    "isSubscribed == false && !notificationsBlocked"
                                                }
                                              ],
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.subscribe()
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "menu-icon" },
                                                [_vm._v("notifications_active")]
                                              ),
                                              _vm._v(" Enable Notifications ")
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.isiOS()
                                        ? _c(
                                            "v-list-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.isSubscribed == true,
                                                  expression:
                                                    "isSubscribed == true"
                                                }
                                              ],
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.unsubscribe()
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "menu-icon" },
                                                [_vm._v("notifications_off")]
                                              ),
                                              _vm._v(" Disable Notifications ")
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-list-item",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.installBtn == true,
                                              expression: "installBtn == true"
                                            }
                                          ],
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.installer()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "menu-icon" },
                                            [_vm._v("get_app")]
                                          ),
                                          _vm._v(" Install App ")
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.logout()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "menu-icon" },
                                            [_vm._v("exit_to_app")]
                                          ),
                                          _vm._v(" Logout ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-navigation-drawer",
            {
              attrs: { absolute: "", temporary: "" },
              model: {
                value: _vm.menuVisible,
                callback: function($$v) {
                  _vm.menuVisible = $$v
                },
                expression: "menuVisible"
              }
            },
            [
              _c(
                "v-list",
                [
                  _vm.username
                    ? _c(
                        "v-list-item",
                        { staticClass: "menu-item", attrs: { to: "/" } },
                        [
                          _c("v-icon", { staticClass: "menu-icon" }, [
                            _vm._v("home")
                          ]),
                          _c("span", { staticClass: "v-list-item-text" }, [
                            _vm._v("Home")
                          ])
                        ],
                        1
                      )
                    : _c(
                        "v-list-item",
                        { staticClass: "menu-item", attrs: { to: "/" } },
                        [
                          _c("v-icon", { staticClass: "menu-icon" }, [
                            _vm._v("home")
                          ]),
                          _c("span", { staticClass: "v-list-item-text" }, [
                            _vm._v("Dashboard")
                          ])
                        ],
                        1
                      ),
                  _vm.username
                    ? _c(
                        "v-list-item",
                        { staticClass: "menu-item", attrs: { to: "/account" } },
                        [
                          _c("v-icon", { staticClass: "menu-icon" }, [
                            _vm._v("mdi-currency-usd")
                          ]),
                          _c("span", { staticClass: "v-list-item-text" }, [
                            _vm._v("Account")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.username
                    ? _c(
                        "v-list-item",
                        {
                          staticClass: "menu-item",
                          attrs: { to: "/transactions" }
                        },
                        [
                          _c("v-icon", { staticClass: "menu-icon" }, [
                            _vm._v("history")
                          ]),
                          _c("span", { staticClass: "v-list-item-text" }, [
                            _vm._v("Transaction History")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.username
                    ? _c(
                        "v-list-item",
                        {
                          staticClass: "menu-item",
                          attrs: { to: "/wagerhistory" }
                        },
                        [
                          _c("v-icon", { staticClass: "menu-icon" }, [
                            _vm._v("history")
                          ]),
                          _c("span", { staticClass: "v-list-item-text" }, [
                            _vm._v("Wager History")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.username
                    ? _c(
                        "v-list-item",
                        { staticClass: "menu-item", attrs: { to: "/groups" } },
                        [
                          _c("v-icon", { staticClass: "menu-icon" }, [
                            _vm._v("group")
                          ]),
                          _c("span", { staticClass: "v-list-item-text" }, [
                            _vm._v("Group Manager")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.username && _vm.allowInvite()
                    ? _c(
                        "v-list-item",
                        { staticClass: "menu-item", attrs: { to: "/invite" } },
                        [
                          _c("v-icon", { staticClass: "menu-icon" }, [
                            _vm._v("person_add")
                          ]),
                          _c("span", { staticClass: "v-list-item-text" }, [
                            _vm._v("Invite Friends")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.username
                    ? _c(
                        "v-list-item",
                        {
                          staticClass: "menu-item",
                          attrs: { to: "/disputes" }
                        },
                        [
                          _c("v-icon", { staticClass: "menu-icon" }, [
                            _vm._v("report")
                          ]),
                          _c("span", { staticClass: "v-list-item-text" }, [
                            _vm._v("Disputes")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.username
                    ? _c(
                        "v-list-item",
                        {
                          staticClass: "menu-item",
                          attrs: { to: "/notifications" }
                        },
                        [
                          _c("v-icon", { staticClass: "menu-icon" }, [
                            _vm._v("notifications")
                          ]),
                          _c("span", { staticClass: "v-list-item-text" }, [
                            _vm._v("Notifications")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    { staticClass: "menu-item", attrs: { to: "/about" } },
                    [
                      _c("v-icon", { staticClass: "menu-icon" }, [
                        _vm._v("info")
                      ]),
                      _c("span", { staticClass: "v-list-item-text" }, [
                        _vm._v("About")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { staticClass: "menu-item", attrs: { to: "/faq" } },
                    [
                      _c("v-icon", { staticClass: "menu-icon" }, [
                        _vm._v("help")
                      ]),
                      _c("span", { staticClass: "v-list-item-text" }, [
                        _vm._v("FAQ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { staticClass: "menu-item", attrs: { to: "/contact" } },
                    [
                      _c("v-icon", { staticClass: "menu-icon" }, [
                        _vm._v("email")
                      ]),
                      _c("span", { staticClass: "v-list-item-text" }, [
                        _vm._v("Contact Us")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      staticClass: "menu-item",
                      attrs: { to: "" },
                      on: {
                        click: function($event) {
                          return _vm.openslides()
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "menu-icon" }, [
                        _vm._v("visibility")
                      ]),
                      _c("span", { staticClass: "v-list-item-text" }, [
                        _vm._v("View Tutorial")
                      ])
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-list-item",
                    {
                      staticClass: "menu-item",
                      attrs: {
                        href: "https://www.ncpgambling.org/help-treatment/",
                        target: "_blank"
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "menu-icon" }, [
                        _vm._v("priority_high")
                      ]),
                      _c("span", { staticClass: "v-list-item-text" }, [
                        _vm._v("Responsible Gambling")
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "text-center" }, [
                    _c("span", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v("Gamblers Anonymous | 1-800-GAMBLER")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-main",
            [
              _c(
                "v-container",
                { staticClass: "pb-0 my-0", attrs: { fluid: "" } },
                [_vm._t("default")],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }