var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "button-border",
      class:
        _vm.offer.deleted ||
        (!_vm.offer.takenby_id && _vm.offer.status.substring(0, 4) !== "Open")
          ? "inactive"
          : _vm.wagerResult + "-shadow"
    },
    [
      _c("GameHeader", { attrs: { game: _vm.gameHeaderInfo } }),
      _c(
        "v-row",
        {
          staticClass: "pb-1 pt-0 my-0",
          staticStyle: { "font-size": "larger" },
          attrs: { justify: "center", align: "center" },
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.offer.expand = !_vm.offer.expand
            }
          }
        },
        [
          _c("v-col", { staticClass: "py-0", attrs: { cols: "2" } }, [
            _c("span", { staticClass: "font-smaller text-left" }, [
              _vm._v("#" + _vm._s(_vm.offer.id))
            ])
          ]),
          _c("v-col", { staticClass: "py-0 text-center" }, [
            _c("span", { staticClass: "text-center" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.wagerTeamName) +
                    " (" +
                    _vm._s(_vm.showSpread(_vm.gameline, _vm.myTeamType)) +
                    ")"
                )
              ])
            ])
          ]),
          _c(
            "v-col",
            {
              staticClass: "py-0 text-right",
              attrs: { cols: _vm.offer.bettype_id === 10 ? 4 : 2 }
            },
            [
              _c(
                "span",
                { staticClass: "text-right py-0 font-smaller text-no-wrap" },
                [
                  _vm._v(
                    " $" +
                      _vm._s(
                        _vm.isMyOffer
                          ? _vm.offer.offeredbyamount
                          : _vm.offer.takenbyamount
                      ) +
                      " "
                  ),
                  _vm.offer.offeredbyamount != _vm.offer.takenbyamount
                    ? _c("span", [
                        _vm._v(
                          " to win $" +
                            _vm._s(
                              _vm.isMyOffer
                                ? _vm.offer.takenbyamount
                                : _vm.offer.offeredbyamount
                            )
                        )
                      ])
                    : _vm._e()
                ]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "py-0 my-0",
          attrs: { justify: "space-around" },
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.offer.expand = !_vm.offer.expand
            }
          }
        },
        [
          _vm.offer.gamestatus === "In Progress" ||
          _vm.offer.gamestatus === "Final"
            ? _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("BoxScore", {
                    attrs: {
                      game: _vm.offer,
                      compact: true,
                      result: _vm.wagerResult,
                      offerstatus: _vm.offer.status,
                      type:
                        _vm.offer.bettype_id === 20
                          ? _vm.myTeamType === "away"
                            ? "OVER"
                            : "UNDER"
                          : "line"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.offer.gamestatus === "In Progress" ||
          _vm.offer.gamestatus === "Final"
            ? _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "my-0 py-0 text-center" },
                    [
                      _c("v-col", { staticClass: "py-0" }, [
                        _vm.myTeamType === "away"
                          ? _c("span", [
                              _c("span", { class: _vm.wagerResult }, [
                                _vm._v(_vm._s(_vm.showWagerResult()))
                              ])
                            ])
                          : _c("span", [
                              _vm.offer.takenby_id
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.isMyOffer
                                          ? _vm.offer.takername
                                          : _vm.offer.offername
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ])
                      ]),
                      _c("v-col", { staticClass: "py-0" }, [
                        _vm.myTeamType === "home"
                          ? _c("span", [
                              _c("span", { class: _vm.wagerResult }, [
                                _vm._v(_vm._s(_vm.showWagerResult()))
                              ])
                            ])
                          : _c("span", [
                              _vm.offer.takenby_id
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.isMyOffer
                                          ? _vm.offer.takername
                                          : _vm.offer.offername
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ])
                      ]),
                      _c("v-col", { staticClass: "py-0" }, [
                        _vm.offer.takenby_id > 0
                          ? _c("span", [
                              _vm.offer.gamestatus === "Final"
                                ? _c("span", [
                                    _c("strong", { class: _vm.wagerResult }, [
                                      _vm._v(_vm._s(_vm.offer.status))
                                    ])
                                  ])
                                : _c("span", [_vm._v(_vm._s(_vm.offer.status))])
                            ])
                          : _c("span", [_vm._v(" Not Taken ")])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "my-0 py-0" },
                    [
                      _vm.offer.takenby_id &&
                      _vm.offer.status !== "Cancelled" &&
                      !_vm.offer.deleted
                        ? _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "8" } },
                            [
                              _c("div", { staticClass: "text-center" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isMyOffer
                                        ? _vm.offer.takername
                                        : _vm.offer.offername
                                    ) +
                                    " - "
                                ),
                                _vm.offer.bettype_id === 20
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.myTeamType === "away"
                                              ? "OVER"
                                              : "UNDER"
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.wagerTeamName === _vm.offer.home
                                              ? _vm.offer.away
                                              : _vm.offer.home
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.showSpread(
                                              _vm.gameline,
                                              _vm.myTeamType === "home"
                                                ? "away"
                                                : "home"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]),
                                _vm.offer.record.wins !== 0 ||
                                _vm.offer.record.losses !== 0 ||
                                _vm.offer.record.ties !== 0
                                  ? _c(
                                      "span",
                                      [
                                        _c("WagerRecord", {
                                          attrs: { record: _vm.offer.record }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center py-0",
                          staticStyle: { "white-space": "nowrap" },
                          style:
                            _vm.offer.takenby_id &&
                            _vm.offer.status !== "Cancelled" &&
                            !_vm.offer.deleted
                              ? ""
                              : "text-align:right !important;",
                          attrs: {
                            cols:
                              _vm.offer.takenby_id &&
                              _vm.offer.status !== "Cancelled" &&
                              !_vm.offer.deleted
                                ? 4
                                : 12
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.offer.status) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      ),
      _vm.offer.gamestatus === "In Progress"
        ? _c(
            "v-row",
            {
              staticClass: "mt-0 mb-2",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.offer.expand = !_vm.offer.expand
                }
              }
            },
            [
              _c("Probability", {
                attrs: { probability: _vm.showWagerProbability() }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.offer.expand
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-row",
                { staticClass: "my-0 py-0" },
                [
                  _vm.offer.gamestatus === "Final"
                    ? _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-divider"),
                          _c("v-spacer"),
                          _vm.showSendPayment
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "my-2",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.$emit("sendpayment", _vm.offer)
                                    }
                                  }
                                },
                                [_vm._v("Send Payment")]
                              )
                            : _vm._e(),
                          _vm.showConfirmPayment
                            ? _c("PayoutConfirmation", {
                                attrs: {
                                  payout: _vm.getPayoutInfo(_vm.offer),
                                  compact: ""
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.$emit(
                                      "confirmpayment",
                                      _vm.offer
                                    )
                                  },
                                  deny: function($event) {
                                    return _vm.$emit("denypayment", _vm.offer)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.offer.gamestatus === "Final"
                    ? _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-divider"),
                          _c("v-spacer", { staticClass: "pb-2" }),
                          _c("h4", [
                            _vm._v(
                              _vm._s(
                                _vm.displayPaymentMethod(
                                  _vm.myTeam === _vm.offer.offeredbyteam_id
                                    ? _vm.offer.takerpaymentmethod
                                    : _vm.offer.offerpaymentmethod
                                )
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
                    _c("h3", [
                      _vm._v("Wager Type: " + _vm._s(_vm.offer.description))
                    ])
                  ]),
                  _vm.offer.takenby_id
                    ? _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "6" } },
                        [
                          _c("h4", [
                            _vm._v("Offered By: " + _vm._s(_vm.offer.offername))
                          ]),
                          _c("h5", [_vm._v(_vm._s(_vm.offer.created))])
                        ]
                      )
                    : _vm._e(),
                  _vm.offer.takenby_id
                    ? _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "6" } },
                        [
                          _vm.offer.takenby_id > 0
                            ? _c("h4", [
                                _vm._v(
                                  "Taken By: " + _vm._s(_vm.offer.takername)
                                )
                              ])
                            : _vm._e(),
                          _c("h5", [_vm._v(_vm._s(_vm.offer.accepted))])
                        ]
                      )
                    : _vm._e(),
                  !_vm.offer.takenby_id
                    ? _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("h5", [_vm._v(_vm._s(_vm.offer.created))]),
                          !_vm.offer.deleted &&
                          !_vm.offer.inprogress &&
                          !_vm.offer.endtime
                            ? _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.showedit = !_vm.showedit
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("edit")])],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit("delete")
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("delete")])],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm.offer.gamestatus === "Final" ||
                  _vm.offer.gamestatus === "In Progress"
                    ? _c(
                        "v-col",
                        { staticClass: "pb-0 bt-2", attrs: { cols: "12" } },
                        [
                          _c("v-divider", { staticClass: "pb-2" }),
                          _c("v-spacer"),
                          _c("BoxScore", { attrs: { game: _vm.offer } })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.canChat || (_vm.offer.messages && _vm.offer.messages.length)
                ? _c(
                    "v-row",
                    { staticClass: "my-0 py-0" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-divider", { staticClass: "py-x" }),
                          _c("WagerMessages", {
                            attrs: {
                              wagerId: _vm.offer.id,
                              messages: _vm.offer.messages,
                              canChat: _vm.canChat && _vm.offer.takenby_id > 0
                            },
                            on: { update: _vm.update }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "vig-card-expand-container" },
        [
          _c(
            "v-row",
            { staticClass: "my-0 py-0" },
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-1 text-left black--text",
                  attrs: { cols: "2" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.offer.expand = !_vm.offer.expand
                    }
                  }
                },
                [
                  _vm.offer.takenby_id > 0
                    ? _c(
                        "span",
                        { class: _vm.hasUnreadMessages ? "text-red" : "" },
                        [
                          _c(
                            "v-icon",
                            {
                              class: _vm.hasUnreadMessages ? "text-red" : "",
                              attrs: { size: "18" }
                            },
                            [_vm._v("$bullhorn")]
                          ),
                          _vm._v(
                            " (" + _vm._s(_vm.offer.messages.length) + ") "
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.offer.expand = !_vm.offer.expand
                    }
                  }
                },
                [
                  _c("v-icon", [
                    _vm._v(
                      _vm._s(
                        _vm.offer.expand ? "arrow_drop_up" : "arrow_drop_down"
                      )
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-0 pr-1 text-right", attrs: { cols: "2" } },
                [
                  _vm.offer.offeredby_id === this.userId
                    ? _c(
                        "span",
                        [
                          _vm.canChat && _vm.offer.takenby_id > 0
                            ? _c("v-switch", {
                                staticClass: "ma-0 pa-0 switch-small shrink",
                                attrs: {
                                  color: "red",
                                  "hide-details": "",
                                  label: "Mute",
                                  value:
                                    _vm.offer.muteofferedby > 0 ? true : false
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleMute(_vm.offer)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _c(
                        "span",
                        [
                          _vm.canChat && _vm.offer.takenby_id > 0
                            ? _c("v-switch", {
                                staticClass: "ma-0 pa-0 switch-small shrink",
                                attrs: {
                                  color: "red",
                                  "hide-details": "",
                                  label: "Mute",
                                  value:
                                    _vm.offer.mutetakenby > 0 ? true : false
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleMute(_vm.offer)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.showedit,
            callback: function($$v) {
              _vm.showedit = $$v
            },
            expression: "showedit"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                {
                  staticClass: "pa-0 black--text mt-5",
                  staticStyle: { "line-height": "1.5" }
                },
                [
                  _c("CreateWager", {
                    attrs: {
                      game: _vm.gameObject,
                      type: _vm.wagerType,
                      team_id: _vm.offer.offeredbyteam_id,
                      betlimit: _vm.betlimit,
                      wager_id: _vm.offer.id
                    },
                    on: { update: _vm.closeupdate }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("back", {
                            attrs: { text: "Back", emit: "back" },
                            on: { back: _vm.closeupdate }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }