<style scoped>
</style>
<template>
  <v-container>
    <v-row
      class="mt-0 mb-4"
      v-if="dispute && dispute.id"
    >
      <v-col
        cols="12"
        class="py-0"
      >
        <v-card class="vig-card">
          <v-card-title
            class="py-0"
            @click="expand = !expand"
          >
            {{ dispute.disputetype }}
          </v-card-title>

          <v-card-text v-if="dispute.closed">
            <p class="text-center">This dispute has been closed.</p>
            <p class="text-center">{{ dispute.closed }}</p>
          </v-card-text>
          <v-card-text v-if="!dispute.closed && !requiresActionFromOwner && !requiresActionFromOther">
            <p class="text-center">This dispute is under review and currently requires no action from you.</p>
          </v-card-text>
          <v-card-text v-if="requiresActionFromOwner">
            <p
              class="text-center"
              v-if="isDisputeOwner"
            >Please upload a screenshot of your proof of payment.</p>
            <v-row>
              <v-col cols="12">
                <p class="text-center">Venmo Instructions</p>
                <ol>
                  <li>Find the transaction you paid</li>
                  <li>Click on icon of person you paid</li>
                  <li>Choose feed 'Between You'</li>
                  <li>Screen shot should include Venmo handle of person you paid with proof of your payment.</li>
                </ol>
              </v-col>
            </v-row>
            <p
              class="text-center"
              v-if="!isDisputeOwner"
            >This dispute is awaiting a response from {{ dispute.disputeuser }} and currently requires no action from you.</p>
          </v-card-text>
          <v-card-text
            v-if="requiresActionFromOther"
            class="py-0"
          >
            <p
              class="text-center"
              v-if="isDisputeOwner"
            >This dispute is awaiting a response from {{ dispute.againstuser }} and currently requires no action from you.</p>
            <p
              class="text-center"
              v-if="!isDisputeOwner"
            >Please upload a screenshot of your proof of payment.</p>
            <v-row>
              <v-col cols="12">
                <p class="text-center">Venmo Instructions</p>
                <ol>
                  <li>Find the transaction you paid</li>
                  <li>Click on icon of person you paid</li>
                  <li>Choose feed 'Between You'</li>
                  <li>Screen shot should include Venmo handle of person you paid with proof of your payment.</li>
                </ol>
              </v-col>
            </v-row>
          </v-card-text>

          <v-expand-transition>
            <div
              v-show="expand"
              class="vig-card-expand"
              style="margin-top: 0.5em;"
            >
              <v-card-text>
                <v-row class="my-0">
                  <v-col
                    cols="12"
                    :class="'py-0 ' + getValidationClass('upload')"
                  >
                    <v-file-input
                      v-model="form.upload"
                      label="Screenshot of Proof of Payment"
                      accept="image/*"
                      required
                      @change="setFileUpload"
                      ref="upload"
                    ></v-file-input>
                  </v-col>

                  <v-col
                    cols="12"
                    class="py-0"
                  >
                    <v-textarea
                      label="Comment"
                      outlined
                      auto-grow
                      hide-details
                      v-model.trim="form.description"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions class="text-right">
                <v-spacer></v-spacer>
                <v-btn
                  @click="update"
                  color="primary"
                  dense
                  :disabled="$v.$invalid || loading"
                >Update Dispute</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </div>
          </v-expand-transition>
          <v-card-actions class="pa-0">
            <div
              class="vig-card-expand-container"
              @click="expand = !expand"
            >
              <v-icon>{{ (expand) ? 'arrow_drop_up' : 'arrow_drop_down' }}</v-icon>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <WagerInfo
      :wager="wager"
      expanded="true"
    ></WagerInfo>
    <DisputeImages :dispute="dispute"></DisputeImages>
    <DisputeLog :dispute="dispute"></DisputeLog>
  </v-container>
</template>

<script>
import UserService from '@/services/UserService'
import DisputeService from '@/services/DisputeService'
import WagerInfo from '@/components/wagers/WagerInfo'
import DisputeLog from '@/components/disputes/widgets/Log'
import DisputeImages from '@/components/disputes/widgets/Images'

import { validationMixin } from 'vuelidate'
import {
  requiredIf
} from 'vuelidate/lib/validators'

export default {
  name: 'DisputeUpdate',
  mixins: [validationMixin],
  components: {
    WagerInfo,
    DisputeLog,
    DisputeImages
  },
  props: {
    dispute_id: {
      type: [String, Number],
      default: 0
    }
  },
  data: function () {
    return {
      loading: false,
      expand: false,
      disputetypes: [],
      dispute: {},
      wager: {},
      form: {
        dispute_id: this.dispute_id,
        description: '',
        upload: null,
        file: null
      }
    }
  },
  validations: {
    form: {
      upload: {
        required: requiredIf(function (form) {
          return (form && this.requiresAction)
        })
      }
    }
  },
  mounted: function () {
    this.init()
  },
  computed: {
    isDisputeOwner: function () {
      const userinfo = UserService.getUser()
      return (+this.dispute.user_id === +userinfo.id)
    },
    requiresAction: function () {
      return ((this.isDisputeOwner && this.requiresActionFromOwner) || (!this.isDisputeOwner && this.requiresActionFromOther))
    },
    requiresActionFromOwner: function () {
      return ((this.dispute.disputetype_id === 10 && this.hasUpload(this.dispute.againstuser_id) && !this.hasUpload(this.dispute.disputeuser_id)) ||
        (this.dispute.disputetype_id === 20 && !this.hasUpload(this.dispute.disputeuser_id)))
    },
    requiresActionFromOther: function () {
      return ((this.dispute.disputetype_id === 10 && !this.hasUpload(this.dispute.againstuser_id)) ||
        (this.dispute.disputetype_id === 20 && this.hasUpload(this.dispute.disputeuser_id) && !this.hasUpload(this.dispute.againstuser_id)))
    }
  },
  methods: {
    async init () {
      this.$updateloading(1)
      this.loading = true
      DisputeService.initUpdate(this.dispute_id).then(response => {
        this.disputetypes = response.data.disputetypes
        this.dispute = response.data.dispute
        this.wager = response.data.wager
        if (this.requiresAction) {
          this.expand = true
        }
        this.$updateloading(-1)
        this.loading = false
      }).catch(errors => {
        this.$updateloading(-1)
        this.$notify({
          group: 'errors',
          title: 'Error',
          text: errors.error,
          type: 'warn'
        })
        console.dir(errors)
        this.loading = false
      })
    },
    async update () {
      this.$updateloading(1)
      this.loading = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          group: 'errors',
          title: 'Invalid Submission',
          text: 'Please correct your form entries and try again.',
          type: 'warn'
        })
        this.loading = false
      } else {
        const formData = new FormData()
        formData.append('dispute_id', this.dispute_id)
        formData.append('action', 'Update')
        formData.append('description', this.form.description)
        formData.append('upload', this.form.upload)
        formData.append('file', this.form.file)
        DisputeService.update(this.dispute_id, formData).then(response => {
          this.$updateloading(-1)
          this.loading = false
          this.$notify({
            group: 'notices',
            title: 'Dispute Updated',
            text: 'Dispute has been updated for review.',
            type: 'info'
          })
          window.location.reload(true)
        }).catch(errors => {
          this.$updateloading(-1)
          this.loading = false
          this.$notify({
            group: 'errors',
            title: 'Dispute Update Failed',
            text: errors.error,
            type: 'warn'
          })
          console.dir(errors)
        })
      }
    },
    setFileUpload () {
      console.dir(this.$refs)
      this.form.file = this.$refs.upload.$refs.input.files[0]
    },
    hasUpload (userId) {
      let upload = false
      if (this.dispute && this.dispute.logs && this.dispute.logs.length > 0) {
        this.dispute.logs.some(function (l) {
          if (l.document_id > 0 && +l.user_id === +userId) {
            return (upload = true)
          }
        })
      }
      return upload
    },
    getValidationClass (fieldName) {
      const field = this.$v.form[fieldName]
      if (field) {
        return {
          'v-invalid': (field.$invalid && field.$dirty)
        }
      }
    }
  }
}
</script>
