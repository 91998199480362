<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header expand-icon="mdi-menu-down">
        <h2 class="ma-0 black--text">Rules</h2>
      </v-expansion-panel-header>
      <v-expansion-panel-content
        class="text-left pa-3"
        v-html="contest.description"
      ></v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'ContestRules',
  props: {
    contest: Object
  }
}
</script>
