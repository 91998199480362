import axios from 'axios'
import Vue from 'vue'
import Notifications from 'vue-notification'
import UserService from '@/services/UserService'

Vue.use(Notifications)

const AccountService = {

  async getInvites (data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'invite',
      data: data,
      method: 'post'
    })
  },

  async createInvite (data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'invite/create/',
      data: JSON.stringify(data),
      method: 'post'
    })
  }

}

export default AccountService
