var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dispute.logs && _vm.dispute.logs.length
    ? _c(
        "v-card",
        { staticClass: "vig-card mb-4" },
        [
          _c(
            "v-card-title",
            {
              staticClass: "py-0",
              on: {
                click: function($event) {
                  _vm.expand = !_vm.expand
                }
              }
            },
            [_vm._v("Activity Log")]
          ),
          _c("v-expand-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.expand,
                    expression: "expand"
                  }
                ],
                staticClass: "vig-card-expand",
                staticStyle: { "margin-top": "0.5em" }
              },
              [
                _c(
                  "v-card-text",
                  { staticClass: "py-0" },
                  _vm._l(_vm.dispute.logs, function(l) {
                    return _c(
                      "v-row",
                      { key: l.id, staticClass: "ma-0" },
                      [
                        _c("v-col", { staticClass: "px-0 py-2" }, [
                          _vm._v(_vm._s(l.logdate.substring(5)) + ": "),
                          _c("strong", [_vm._v(_vm._s(l.user))]),
                          _vm._v(" - " + _vm._s(l.action)),
                          l.description
                            ? _c("span", [
                                _vm._v(" - " + _vm._s(l.description))
                              ])
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ]),
          _c("v-card-actions", { staticClass: "pa-0" }, [
            _c(
              "div",
              {
                staticClass: "vig-card-expand-container",
                on: {
                  click: function($event) {
                    _vm.expand = !_vm.expand
                  }
                }
              },
              [
                _c("v-icon", [
                  _vm._v(
                    _vm._s(_vm.expand ? "arrow_drop_up" : "arrow_drop_down")
                  )
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }