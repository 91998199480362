var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-icon",
        {
          staticClass: "rating-star",
          attrs: { color: "yellow darken-2", small: "" }
        },
        [_vm._v("grade")]
      ),
      _c("span", [_vm._v(_vm._s(_vm.rating > 0 ? _vm.rating : "Unrated"))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }