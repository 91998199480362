<template>
  <div>
    <v-row class="my-0">
      <v-col
        cols="12"
        class="py-0"
      >
        <v-card>
          <v-btn
            icon
            style="position:sticky;top:0;float:right"
            v-if="isModal"
            @click="$emit('close')"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-row
            justify="center"
            class="my-0"
          >
            <v-card-title
              justify="center"
              class="mt-2"
            >
              <div class="black--text">VigZero User Agreement</div>
            </v-card-title>
          </v-row>
          <v-card-text class="px-6">
            <v-row class="black--text">
              <v-col cols="12">
                <b>1) Eligibility to join VigZero's peer to peer sports betting
                  community</b>
              </v-col>
              <v-spacer></v-spacer>
              <ul>
                <li>
                  You must certify that you are 21+ years old.
                </li>
                <li>
                  You must be invited into our peer to peer community, by a
                  current member.
                </li>
                <li>
                  You must provide VigZero with your email address and cell
                  phone number.
                </li>
                <li>
                  If you have lost your privileges to be a member of our peer to
                  peer community, you will not be allowed to rejoin.
                </li>
              </ul>
            </v-row>
            <v-row class="black--text">
              <v-col>
                <b>2) Conditions of Membership</b>
              </v-col>
              <ul>
                <li>You must abide by all rules contained herein.</li>
                <li>
                  You must only invite individuals to join our community that
                  you know and trust to comply with our conditions of
                  membership.
                </li>
                <li>
                  You agree that VigZero has no obligation to settle any bets
                  that are not ultimately settled by another Member.
                </li>
                <li>
                  You agree that any disagreement with VigZero can only be
                  processed through arbitration.
                </li>
              </ul>
            </v-row>
            <v-row class="black--text">
              <v-col cols="12">
                <b>3) Rules</b>
              </v-col>
              <ul>
                <li>Each community member may only have one account.</li>
                <li>
                  Members who do not pay lost bets, or confirm payment of
                  winning bets, will be removed from the community and will not
                  be allowed to rejoin.
                </li>
                <li>
                  Members statistics and peer ratings will be provided to other
                  users, so they can choose to accept or confirm a bet with
                  members based on their peer ratings. If VigZero can match a
                  member's bet offer automatically to another member, the
                  highest rated member will be chosen.
                </li>
                <li>
                  Members are restricted by a 'limit', the maximum accumulated
                  amount allowed for outstanding/npeding bets. VigZero has full
                  discretion to set a member's limit and/or criteria to revise
                  any members' limit.
                </li>
                <li>Members may only bet up to $100 on any single bet.</li>
                <li>
                  A member may not offer or accept a new bet while they have an
                  unpaid status on a losing bet.
                </li>
                <li>
                  A member may not offer or accept a new bet if they have a
                  winning bet that is awaiting confirmation of payment.
                </li>
                <li>
                  If the status of a winner's bet shows paid and the winner has
                  not confirmed said status for 48 hours, or more, the winner of
                  said bet will not be able to make any additional bets until
                  that status is confirmed, or challenged.
                </li>
                <li>
                  A member who invites a new member who disproportionately does
                  not comply VigZero's rules and terms, can be removed from the
                  community at the sole discretion of VigZero.
                </li>
              </ul>
            </v-row>
            <v-row class="black--text">
              <v-col cols="12">
                <b>4) Privacy</b>
              </v-col>
              <ul>
                <li>
                  VigZero only tracks a member's winning percentage. Having no
                  way to definitively confirm peer-to-peer transactions, VigZero
                  cannot verify nor report on accumulated earnings or losses.
                </li>
                <li>
                  VigZero will not share or sell any member's personal data,
                  unless forced to do so under a valid subpoena.
                </li>
                <li>
                  VigZero will collect and may use, in any manner it chooses,
                  group data and trends.
                </li>
              </ul>
            </v-row>
          </v-card-text>

          <v-card-actions v-if="isModal">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="$emit('close')"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'UserAgreementDialog',
  data: () => ({
  }),
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
