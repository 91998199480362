<template>
  <div class="pb-6">
    <!-- <v-row class="my-0" :class="allowAutoRefresh ? 'mb-n6' : ''">
      <v-col cols="12" class="text-right py-0">
        <div v-if="allowAutoRefresh">
          <v-switch
            v-model="autoRefresh"
            class="py-0 my-0 pull-right"
          >
          <template v-slot:label>
            <span class="black--text" style="font-size:0.85rem">Auto-Refresh</span>
          </template>
          </v-switch>
        </div>
        <div v-else>
          <a
            href=""
            @click.prevent="refresh(true)"
          >Refresh</a>
        </div>
      </v-col>
    </v-row> -->
    <!-- Pending -->
    <v-row class="my-0">
      <v-col
        cols="12"
        class="text-right py-0"
      >
        <v-switch
          v-model="hideNotTaken"
          class="py-0 my-0 pull-right"
          hide-details
          :disabled="loading"
        >
          <template v-slot:label>
            <span
              class="black--text"
              style="font-size:0.85rem"
            >Hide Not Taken</span>
          </template>
        </v-switch>
      </v-col>
    </v-row>
    <div v-if="offers && offers.length > 0">
      <HistoryList
        :offers="offers"
        @update="update()"
        :canBet="false"
        :canChat="true"
      ></HistoryList>
    </div>
    <v-row v-else>
      <v-col
        cols="12"
        class="my-0 py-0 text-center"
      >
        No pending wagers.
      </v-col>
    </v-row>
  </div>
</template>

<script>
import WagerService from '@/services/WagerService'
import HistoryList from '@/components/offer/HistoryList'
import UserService from '@/services/UserService'

export default {
  components: {
    HistoryList
  },
  props: {
    debts: Array,
    payouts: Array,
    reloadKey: Number,
    section: String,
    visible: {
      type: [String, Boolean],
      default: false
    }
  },
  data: () => {
    return {
      loading: false,
      allowAutoRefresh: false,
      hideNotTaken: false,
      autoRefresh: true,
      refreshtimer: null,
      offers: null
    }
  },
  created: function () {
    this.hideNotTaken = WagerService.getPendingHideNotTaken()
    if (!this.offers || !this.offers.length) {
      this.$updateloading(1)
      this.getdata().then(() => {
        this.$updateloading(-1)
        if (this.allowAutoRefresh) {
          this.startRefreshTimer()
        }
      })
    }
  },
  destroyed: function () {
    this.clearRefreshTimer()
  },
  methods: {
    getdata: async function (loadingScreen) {
      if (this.loading) {
        // don't allow more than one running request
        return false
      }
      const user = UserService.getUserObject()
      if (!user) {
        this.autoRefresh = false
        this.clearRefreshTimer()
        return false
      }
      if (loadingScreen) {
        this.$updateloading(1)
      }
      this.loading = true
      await WagerService.pendingInit({ showallwagers: !this.hideNotTaken }).then(
        (response) => {
          if (loadingScreen) {
            this.$updateloading(-1)
          }
          this.loading = false
          if (this.offers && this.offers.length > 0) {
            this.offers.forEach((o) => {
              if (o.expand) {
                response.data.offers.results.some((no) => {
                  if (no.id === o.id) {
                    no.expand = true
                  }
                })
              }
            })
          }
          this.offers = response.data.offers.results
          this.allowAutoRefresh = false
          this.offers.some((o) => {
            if (o.inprogress > 0) {
              this.allowAutoRefresh = true
              return true
            }
          })
        },
        (error) => {
          if (loadingScreen) {
            this.$updateloading(-1)
          }
          this.loading = false
          alert(error.response.data.error)
        }
      )
      if (this.allowAutoRefresh && this.autoRefresh) {
        this.startRefreshTimer()
      } else {
        this.autoRefresh = false
        this.clearRefreshTimer()
      }
      this.$emit('totalpending', this.totalpending)
    },
    update: function () {
      this.$emit('updatedata')
      this.getdata()
      this.$forceUpdate()
    },
    refresh (loadingScreen) {
      if (this.section === 'pending') {
        this.getdata(loadingScreen)
      }
    },
    startRefreshTimer () {
      // set the timer to run every 5 minutes
      this.clearRefreshTimer()
      this.refreshtimer = setInterval(this.refresh, 30 * 1 * 1000)
    },
    clearRefreshTimer () {
      if (this.refreshtimer) {
        clearInterval(this.refreshtimer)
      }
    }
  },
  watch: {
    visible: function (n, o) {
      if (o !== n) {
        if (n && n !== 'false') {
          this.getdata()
        }
      }
    },
    autoRefresh: function (n, o) {
      if (n !== o) {
        if (!n || n === 'false') {
          this.clearRefreshTimer()
        } else {
          this.startRefreshTimer()
        }
      }
    },
    hideNotTaken: function (n, o) {
      if (n !== o) {
        WagerService.setPendingHideNotTaken(n)
        this.getdata()
      }
    },
    reloadKey: function (o, n) {
      if (o !== n) {
        this.getdata()
        this.$forceUpdate()
      }
    }
  },
  computed: {
    totalpending: function () {
      var totaloffers = 0
      if (this.offers) {
        totaloffers += this.offers.length
      }
      if (this.filteredPayouts) {
        totaloffers += this.filteredPayouts.length
      }
      if (this.filteredDebts) {
        totaloffers += this.filteredDebts.length
      }
      return totaloffers
    }
  }
}
</script>

<style scoped>
.collapse-arrow {
  transition: 0.7s;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
}
.bottommarg {
  margin-bottom: 2em;
}
h4 {
  margin-top: 0 !important;
}
</style>
