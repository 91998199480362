var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "py-0" },
    [
      _c(
        "v-row",
        { staticClass: "my-0 py-0" },
        [
          _vm.disputes && _vm.disputes.length == 0
            ? _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
                _c("p", [_vm._v("No disputes found.")])
              ])
            : _vm._e(),
          _vm.disputes && _vm.disputes.length > 0
            ? _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                _vm._l(_vm.disputes, function(dispute) {
                  return _c(
                    "v-card",
                    { key: dispute.id, staticClass: "vig-card px-0" },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "py-0" },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "my-0 py-0",
                              on: {
                                click: function($event) {
                                  dispute.expand = !dispute.expand
                                }
                              }
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "9" } },
                                [
                                  _c("h1", { staticClass: "text-left" }, [
                                    _vm._v(
                                      "#" +
                                        _vm._s(dispute.id) +
                                        " - " +
                                        _vm._s(dispute.disputeuser) +
                                        " vs " +
                                        _vm._s(dispute.againstuser) +
                                        " -"
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(dispute.amount)
                                          )
                                      )
                                    ])
                                  ])
                                ]
                              ),
                              _c("v-col", { staticClass: "py-0 ml-auto" }, [
                                _c("h1", { staticClass: "text-right" }, [
                                  _vm._v(_vm._s(dispute.disputestatus))
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "my-0 py-0" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "9" } },
                                [
                                  _c("h2", { staticClass: "text-left" }, [
                                    _vm._v(_vm._s(dispute.game))
                                  ])
                                ]
                              ),
                              _c("v-col", { staticClass: "py-0 ml-auto" }, [
                                _c("h2", { staticClass: "text-right" }, [
                                  _vm._v(_vm._s(dispute.disputetype))
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-expand-transition", { staticClass: "mt-0" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: dispute.expand,
                                expression: "dispute.expand"
                              }
                            ]
                          },
                          [
                            _c("v-divider"),
                            _c(
                              "v-card-text",
                              { staticClass: "pb-0 pt-1" },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "my-0 py-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0 pr-0",
                                        attrs: { cols: "9" }
                                      },
                                      [
                                        _c("h2", { staticClass: "text-left" }, [
                                          _vm._v(_vm._s(dispute.description))
                                        ]),
                                        _c("h2", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "Created: " +
                                              _vm._s(dispute.created)
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      { staticClass: "pa-0" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "text text-right",
                                            attrs: {
                                              to: {
                                                name: "disputereview",
                                                params: {
                                                  dispute_id: dispute.id
                                                }
                                              },
                                              color: "error",
                                              dense: ""
                                            }
                                          },
                                          [_vm._v("Review")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("v-card-actions", { staticClass: "pa-0" }, [
                        _c(
                          "div",
                          {
                            staticClass: "vig-card-expand-container ma-0",
                            on: {
                              click: function($event) {
                                dispute.expand = !dispute.expand
                              }
                            }
                          },
                          [
                            _c("v-icon", [
                              _vm._v(
                                _vm._s(
                                  dispute.expand
                                    ? "arrow_drop_up"
                                    : "arrow_drop_down"
                                )
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }