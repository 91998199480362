var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      staticClass: "py-4",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.createContest($event)
        }
      },
      model: {
        value: _vm.formValid,
        callback: function($$v) {
          _vm.formValid = $$v
        },
        expression: "formValid"
      }
    },
    [
      _c(
        "v-card",
        [
          !_vm.contest
            ? _c("v-card-title", { staticClass: "justify-center" }, [
                _vm._v("Contest Settings")
              ])
            : _vm._e(),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: {
                  label: "Entry Fee *",
                  type: "number",
                  step: "5",
                  min: "10",
                  max: "100",
                  rules: _vm.entryFeeRules,
                  "prepend-icon": "attach_money",
                  readonly: _vm.contest && !_vm.contest.open
                },
                model: {
                  value: _vm.form.entryfee,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "entryfee", $$v)
                  },
                  expression: "form.entryfee"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Minimum Participants",
                  type: "number",
                  disabled: _vm.contesttype && _vm.contesttype.minimum > 0,
                  "prepend-icon": "mdi-account"
                },
                model: {
                  value: _vm.form.minimum,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "minimum", $$v)
                  },
                  expression: "form.minimum"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Maximum Participants",
                  type: "number",
                  disabled: _vm.contesttype && _vm.contesttype.maximum > 0,
                  "prepend-icon": "mdi-account-multiple"
                },
                model: {
                  value: _vm.form.maximum,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "maximum", $$v)
                  },
                  expression: "form.maximum"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Max Entries Per User (0 for unlimited)",
                  type: "number",
                  min: "0",
                  "prepend-icon": "mdi-ticket",
                  readonly: _vm.contest && !_vm.contest.open
                },
                model: {
                  value: _vm.form.limitperuser,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "limitperuser", $$v)
                  },
                  expression: "form.limitperuser"
                }
              }),
              !_vm.contest || _vm.contest.open
                ? _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.formValid || _vm.loading,
                            color: "success",
                            elevation: "2"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.save($event)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.contest ? "Save" : "Create"))]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }