var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-4" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.showUserAgreement,
            callback: function($$v) {
              _vm.showUserAgreement = $$v
            },
            expression: "showUserAgreement"
          }
        },
        [
          _c("UserAgreement", {
            attrs: { isModal: true },
            on: {
              close: function($event) {
                _vm.showUserAgreement = false
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-form",
            {
              staticClass: "my-0 py-0",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.register($event)
                }
              },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _vm.registrationInviteOnly && !_vm.invite
                ? _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v("Invitation Only")
                        ])
                      ]),
                      _c("v-card-text", { staticClass: "black--text" }, [
                        !_vm.invalidcode
                          ? _c("p", { staticClass: "text-center" }, [
                              _vm._v(
                                "Registration is currently by invitation only."
                              )
                            ])
                          : _vm._e(),
                        _vm.invalidcode
                          ? _c("p", { staticClass: "text-center" }, [
                              _vm._v("Your invitation code is invalid.")
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              (!_vm.registrationInviteOnly || _vm.invite) && _vm.intro
                ? _c(
                    "v-card",
                    { staticClass: "px-4 my-0" },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c("img", {
                              staticStyle: {
                                "max-width": "10vh",
                                height: "auto",
                                "border-radius": "0"
                              },
                              attrs: {
                                src: require("../assets/black_red_icon/512.png")
                              }
                            })
                          ]),
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c(
                              "h1",
                              { staticClass: "robo text-center black--text" },
                              [
                                !_vm.invalidcode
                                  ? _c("span", [
                                      _vm.referral && _vm.referral.id
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.referral.affiliate.name
                                              )
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm.invite && _vm.invite.id
                                        ? _c("span", [
                                            _vm._v(_vm._s(_vm.invite.inviter))
                                          ])
                                        : _vm._e(),
                                      _c("br"),
                                      _vm._v("welcomes you to VigZero ")
                                    ])
                                  : _c("span", [
                                      _vm._v("We Invite You to VigZero")
                                    ])
                              ]
                            )
                          ]),
                          _c(
                            "v-row",
                            { staticClass: "black--text" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("div", [
                                    _vm._v(
                                      " The peer to peer sports betting community. Entertainment without the 'Vig'. Bet on games with friends and the world. "
                                    )
                                  ]),
                                  _c("v-spacer"),
                                  _c("v-row", { attrs: { justify: "start" } }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "px-3",
                                        staticStyle: {
                                          "text-decoration": "underline"
                                        }
                                      },
                                      [_vm._v(" Why VigZero? ")]
                                    )
                                  ]),
                                  _c("v-row", [
                                    _c("ul", [
                                      _c("li", [
                                        _vm._v(
                                          "Save the whopping 10% vig, juice and commission casinos and online sportbooks charge. Keep more of your winnings."
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "Deposit/Withdraw funds using your Venmo account"
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "Simple to use, not intimidating with low minimums"
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "Set your own line! Get a hook, entice with a hook, or have your bet follow the Vegas consensus"
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "Private groups allow you to bet directly with friends for more personal wagering including ability to trash talk privately during the game."
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "Track/bet your favorite teams for the novice or casual sports fan."
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "Shop open wagers to easily get in the action."
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "Tracks accumulative and group records against your friends and other VigZero bettors."
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "v-row",
                                    { attrs: { justify: "center" } },
                                    [
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "link",
                                            on: {
                                              click: function($event) {
                                                return _vm.closeIntro()
                                              }
                                            }
                                          },
                                          [_vm._v("REGISTER HERE")]
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "font-size": "0.8em",
                                        "font-weight": "bold"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " Must be 21+ - Gambling Problem? Help is available from the toll-free nationwide hotline, call: 1-800-GAMBLER, chat: "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "https://www.ncpgambling.org/help-treatment/chat/"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "https://www.ncpgambling.org/help-treatment/chat/"
                                          )
                                        ]
                                      ),
                                      _vm._v(", or visit "),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "https://www.ncpgambling.org/help-treatment/"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "https://www.ncpgambling.org/help-treatment/"
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              (!_vm.registrationInviteOnly || _vm.invite) && !_vm.intro
                ? _c(
                    "v-row",
                    { staticClass: "my-0 py-0", attrs: { id: "topEl" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "px-3 ma-0" },
                            [
                              _c("v-card-title", [_vm._v("Register")]),
                              _c(
                                "v-card-text",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "First Name*",
                                      name: "firstname",
                                      id: "firstname",
                                      disabled: _vm.sending,
                                      required: "",
                                      "error-messages": _vm.getErrors(
                                        "firstname"
                                      )
                                    },
                                    on: {
                                      blur: function($event) {
                                        return _vm.$v.form.firstname.$touch()
                                      },
                                      input: function($event) {
                                        _vm.errors.firstname = ""
                                      }
                                    },
                                    model: {
                                      value: _vm.form.firstname,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "firstname", $$v)
                                      },
                                      expression: "form.firstname"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Last Name*",
                                      name: "lastname",
                                      id: "lastname",
                                      required: "",
                                      disabled: _vm.sending,
                                      "error-messages": _vm.getErrors(
                                        "lastname"
                                      )
                                    },
                                    on: {
                                      blur: function($event) {
                                        return _vm.$v.form.lastname.$touch()
                                      },
                                      input: function($event) {
                                        _vm.errors.lastname = ""
                                      }
                                    },
                                    model: {
                                      value: _vm.form.lastname,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "lastname", $$v)
                                      },
                                      expression: "form.lastname"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Email*",
                                      type: "email",
                                      name: "email",
                                      id: "email",
                                      required: "",
                                      disabled: _vm.sending,
                                      "error-messages": _vm.getErrors("email")
                                    },
                                    on: {
                                      blur: function($event) {
                                        return _vm.$v.form.email.$touch()
                                      },
                                      input: function($event) {
                                        _vm.errors.email = ""
                                      }
                                    },
                                    model: {
                                      value: _vm.form.email,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "email", $$v)
                                      },
                                      expression: "form.email"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Nickname*",
                                      type: "text",
                                      name: "nickname",
                                      id: "nickname",
                                      required: "",
                                      disabled: _vm.sending,
                                      "error-messages": _vm.getErrors(
                                        "nickname"
                                      )
                                    },
                                    on: {
                                      input: function($event) {
                                        _vm.errors.nickname = ""
                                      },
                                      blur: function($event) {
                                        return _vm.$v.form.nickname.$touch()
                                      }
                                    },
                                    model: {
                                      value: _vm.form.nickname,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "nickname", $$v)
                                      },
                                      expression: "form.nickname"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Password*",
                                      name: "password",
                                      id: "password",
                                      type: "password",
                                      disabled: _vm.sending,
                                      "error-messages": _vm.getErrors(
                                        "password"
                                      )
                                    },
                                    on: {
                                      blur: function($event) {
                                        return _vm.$v.form.password.$touch()
                                      },
                                      input: function($event) {
                                        _vm.errors.password = ""
                                      }
                                    },
                                    model: {
                                      value: _vm.form.password,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "password", $$v)
                                      },
                                      expression: "form.password"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Confirm Password*",
                                      name: "confirmpassword",
                                      id: "confirmpassword",
                                      type: "password",
                                      disabled: _vm.sending,
                                      "error-messages": _vm.getErrors(
                                        "confirmpassword"
                                      )
                                    },
                                    on: {
                                      blur: function($event) {
                                        return _vm.$v.form.confirmpassword.$touch()
                                      },
                                      input: function($event) {
                                        _vm.errors.confirmpassword = ""
                                      }
                                    },
                                    model: {
                                      value: _vm.form.confirmpassword,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "confirmpassword",
                                          $$v
                                        )
                                      },
                                      expression: "form.confirmpassword"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label:
                                        "Cell Phone* (IMPORTANT so double check)",
                                      id: "cellphone",
                                      name: "cellphone",
                                      required: "",
                                      disabled: _vm.sending,
                                      "error-messages": _vm.getErrors(
                                        "cellphone"
                                      )
                                    },
                                    on: {
                                      blur: function($event) {
                                        return _vm.$v.form.cellphone.$touch()
                                      },
                                      input: function($event) {
                                        _vm.errors.cellphone = ""
                                      }
                                    },
                                    model: {
                                      value: _vm.form.cellphone,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "cellphone", $$v)
                                      },
                                      expression: "form.cellphone"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Invitation or Referral Code",
                                      id: "code",
                                      name: "code",
                                      disabled: _vm.sending,
                                      "error-messages": _vm.getErrors("code"),
                                      required: ""
                                    },
                                    on: {
                                      blur: function($event) {
                                        return _vm.$v.form.code.$touch()
                                      },
                                      input: function($event) {
                                        _vm.errors.code = ""
                                      }
                                    },
                                    model: {
                                      value: _vm.form.code,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "code", $$v)
                                      },
                                      expression: "form.code"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label:
                                        "Venmo Handle (NOT required when registering)",
                                      id: "venmohandle",
                                      name: "venmohandle",
                                      prefix: "@",
                                      disabled: _vm.sending,
                                      "error-messages": _vm.getErrors(
                                        "venmohandle"
                                      )
                                    },
                                    on: {
                                      keydown: function($event) {
                                        return _vm.preventInvalidVenmoHandle(
                                          $event
                                        )
                                      },
                                      input: function($event) {
                                        _vm.errors.venmohandle = ""
                                        _vm.invalidVenmoHandle = null
                                      },
                                      blur: function($event) {
                                        return _vm.$v.form.venmohandle.$touch()
                                      }
                                    },
                                    model: {
                                      value: _vm.form.venmohandle,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "venmohandle", $$v)
                                      },
                                      expression: "form.venmohandle"
                                    }
                                  }),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "red--text font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        "* ALERT: You will need a Venmo account to wager and providing an incorrect Venmo handle could lead to lost winnings. Neither the payor nor VigZero are responsible when the wrong Venmo handle is provided."
                                      )
                                    ]
                                  ),
                                  _c("v-checkbox", {
                                    attrs: {
                                      "error-messages": _vm.getErrors(
                                        "agreement"
                                      ),
                                      required: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$v.form.agreement.$touch()
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "body-2" },
                                                [
                                                  _vm._v(
                                                    " I agree to the terms of the "
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "link",
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.showUserAgreement = true
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" User Agreement ")]
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      672898856
                                    ),
                                    model: {
                                      value: _vm.form.agreement,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "agreement", $$v)
                                      },
                                      expression: "form.agreement"
                                    }
                                  }),
                                  _c("v-checkbox", {
                                    staticClass: "black--text",
                                    attrs: {
                                      id: "age",
                                      name: "age",
                                      required: "",
                                      disabled: _vm.sending,
                                      "error-messages": _vm.getErrors("age"),
                                      label: "I am 21 years of age or older."
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$v.form.age.$touch()
                                      }
                                    },
                                    model: {
                                      value: _vm.form.age,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "age", $$v)
                                      },
                                      expression: "form.age"
                                    }
                                  }),
                                  _vm.intro
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "link",
                                          on: {
                                            click: function($event) {
                                              return _vm.closeIntro()
                                            }
                                          }
                                        },
                                        [_vm._v("Back")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    "font-size": "0.8em",
                                    "font-weight": "bold"
                                  }
                                },
                                [
                                  _vm._v(
                                    " Must be 21+ - Gambling Problem? Help is available from the toll-free nationwide hotline, call: 1-800-GAMBLER, chat: "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "https://www.ncpgambling.org/help-treatment/chat/"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "https://www.ncpgambling.org/help-treatment/chat/"
                                      )
                                    ]
                                  ),
                                  _vm._v(", or visit "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "https://www.ncpgambling.org/help-treatment/"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "https://www.ncpgambling.org/help-treatment/"
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "my-0" },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "py-0" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "black white--text pull-right mr-n3",
                                              attrs: {
                                                type: "submit",
                                                disabled: _vm.sending
                                              }
                                            },
                                            [_vm._v("Create Account")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-snackbar",
                                {
                                  model: {
                                    value: _vm.userSaved,
                                    callback: function($$v) {
                                      _vm.userSaved = $$v
                                    },
                                    expression: "userSaved"
                                  }
                                },
                                [
                                  _vm._v(
                                    "The user " +
                                      _vm._s(_vm.lastUser) +
                                      " was saved with success!"
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }