var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app-vue-main" } },
    [
      _c("loader-overlay"),
      _c("notifications", {
        staticClass: "notifications",
        attrs: {
          group: "errors",
          duration: -1,
          ignoreDuplicates: true,
          max: 1,
          width: "100%",
          position: "top center",
          absolute: ""
        }
      }),
      _c("notifications", {
        staticClass: "notifications",
        attrs: {
          group: "notices",
          ignoreDuplicates: true,
          max: 1,
          width: "100%",
          position: "top center",
          absolute: ""
        }
      }),
      _c(
        _vm.layout,
        { tag: "component" },
        [_c("router-view", { attrs: { id: "topEl" } })],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.introslide,
            callback: function($$v) {
              _vm.introslide = $$v
            },
            expression: "introslide"
          }
        },
        [_c("Slides", { on: { closeintroslide: _vm.closeintroslide } })],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "320" },
          model: {
            value: _vm.notLoggedInModal,
            callback: function($$v) {
              _vm.notLoggedInModal = $$v
            },
            expression: "notLoggedInModal"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(" Please login ")
              ]),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    "You must be logged in to complete this action. If you do not have an account you can sign up for free."
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", raised: "" },
                      on: {
                        click: function($event) {
                          return _vm.goSignIn()
                        }
                      }
                    },
                    [_vm._v(" Sign In ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { raised: "" },
                      on: {
                        click: function($event) {
                          return _vm.cancelSignIn()
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }