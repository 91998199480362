<template>
    <span> W/L {{ record.wins }}-{{ record.losses }}</span>
</template>
<script>

export default {
  name: 'WagerRecord',
  data: function () {
    return {
    }
  },
  props: {
    record: {
      type: Object
    }
  }
}
</script>
