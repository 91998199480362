import axios from 'axios'
import Vue from 'vue'
import Notifications from 'vue-notification'
import UserService from '@/services/UserService'

Vue.use(Notifications)

const WagerService = {

  getWagerStep () {
    var step = localStorage.getItem('wagerStep') || 0
    if (!step) {
      const userinfo = UserService.getUserObject()
      if (userinfo) {
        step = parseInt(userinfo.betinfo.lastwager) || 0
      } else {
        step = 0
      }
    }
    return step
  },

  setWagerStep (value) {
    return localStorage.setItem('wagerStep', value)
  },

  getPendingHideNotTaken () {
    var v = localStorage.getItem('PendingHideNotTaken') || 0
    return (v === true || v === 'true')
  },

  setPendingHideNotTaken (value) {
    return localStorage.setItem('PendingHideNotTaken', value)
  },

  async search (filters, perpage, page, sort) {
    await UserService.authHeader(false)
    var data = {
      filters: filters,
      perpage: perpage,
      page: page,
      sort: sort
    }
    return axios({
      url: UserService.baseUrl() + 'wager/search',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async getFavoriteOffers () {
    await UserService.authHeader(false)
    return axios({
      url: UserService.baseUrl() + 'wager/offers/favorite',
      method: 'post'
    })
  },

  async getBestValueOffers () {
    await UserService.authHeader(false)
    return axios({
      url: UserService.baseUrl() + 'wager/offers/bestvalue',
      method: 'post'
    })
  },

  async getOpenWagers () {
    await UserService.authHeader(false)
    return axios({
      url: UserService.baseUrl() + 'wager/open/',
      method: 'get'
    })
  },

  async getGroupOffers () {
    await UserService.authHeader(false)
    return axios({
      url: UserService.baseUrl() + 'wager/offers/group',
      method: 'post'
    })
  },

  async offersInit (data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'wager/offers/init',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async pendingInit (data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'wager/pending/init',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async createInit (data) {
    await UserService.authHeader(false)
    return axios({
      url: UserService.baseUrl() + 'wager/create/init',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async create (data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'wager/create',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async accept (wagers) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'wager/accept',
      data: JSON.stringify({ wagers: wagers }),
      method: 'post'
    })
  },

  async getWager (wagerId) {
    await UserService.authHeader(false)
    var data = {
      id: wagerId
    }
    return axios({
      url: UserService.baseUrl() + 'wager/get',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async updateOffer (wager, id) {
    await UserService.authHeader()
    var data = {
      wager: wager
    }
    return axios({
      url: UserService.baseUrl() + 'wager/update/' + id,
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async getUnpaidWager (wagerId) {
    await UserService.authHeader()
    var data = {
      id: wagerId
    }
    return axios({
      url: UserService.baseUrl() + 'wager/getunpaid',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async getAllVigSavings () {
    return axios({
      url: UserService.baseUrl() + 'vigsavings',
      method: 'get'
    })
  },

  async getWagerRecord (data) {
    return axios({
      url: UserService.baseUrl() + 'wager/record',
      data: JSON.stringify(data),
      method: 'post'
    })
  }
}

export default WagerService
