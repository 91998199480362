<style scoped>
  .border {
    border: 3px groove;
  }
</style>
<template>
  <v-card v-if="friendgroup && (friendgroup.id > 0 || demo)" class="border">
    <v-card-text class="chat-notification-heading pt-1 pb-0 px-0">
      <v-row class="my-0 mx-1">
        <v-col class="px-0 py-0">
          <span>
            <v-icon>$bullhorn</v-icon>
            <strong class="pl-1">({{ friendgroup.messagecount }})</strong>
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="px-0 py-0 text-right">
          <v-btn
            x-small
            tile
            class="chat-notification-button-mute ma-0"
            :disabled="loading || reading || sending"
            @click="toggleMuteGroup"
          >{{ (friendgroup.mutegroup > 0) ? 'Unmute' : 'Mute' }}</v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-container
      ref="messageContainer"
      style="max-height: 250px; min-height: 100px;"
      class="overflow-y-auto pa-2 ma-0"
    >
      <div v-if="friendgroup && friendgroup.messages && friendgroup.messages.length > 0">
        <v-row
          v-for="m in friendgroup.messages"
          :key="m.id"
          :justify="(m.user_id == userId) ? 'end' : 'start'"
          class="mt-0 mb-1"
        >
          <v-col
            class="py-0"
            :class="(m.user_id == userId) ? 'text-right' : 'text-left'"
          >
            <div class="timestamp">{{ m.created }}</div>
            <div
              class="message"
              :class="(m.user_id == userId) ? 'message-from' : 'message-to'"
            >
              <div><strong v-if="m.user_id != userId">{{ m.nickname }}: </strong> {{ m.message }}</div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <v-text-field
      class="chat-notification-reply pt-1 pb-2"
      hide-details
      placeholder="Type your message here"
      solo
      dense
      rounded
      v-model="message"
      @keypress.enter="sendMessage"
      @focus="swipe(false)"
      @blur="swipe(true)"
    >
      <template v-slot:append>
        <v-btn
          class="ma-0 pa-0"
          :disabled="reading || sending"
          @click="sendMessage"
          icon
        >
          <v-icon>send</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </v-card>
</template>
<script>
import UserService from '@/services/UserService'
import ChatService from '@/services/ChatService'
import { bus } from '@/main'

export default {
  name: 'GroupMessages',
  components: {},
  props: {
    friendgroupId: {
      type: [Number, String],
      default: 0
    },
    demo: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      friendgroup: {},
      page: 1,
      loading: false,
      sending: false,
      reading: false,
      userId: 0,
      message: null,
      timer: null,
      count: 0
    }
  },
  mounted: function () {
    var user = UserService.getUserObject()
    this.userId = user ? +user.info.id : 0
    this.init()
    this.$emit('loaded')
    var messageContainer = this.$refs.messageContainer
    if (messageContainer) {
      messageContainer.scrollTop = messageContainer.scrollHeight
    }
  },
  updated: function () {
    var messageContainer = this.$refs.messageContainer
    // if first load then scroll to the bottom.
    // other part of if statemnt is to stop it from scrolling to the bottom when they are scolled to a different part of chat
    if (messageContainer && (messageContainer.clientHeight + messageContainer.scrollTop + 20 >= messageContainer.scrollHeight || this.count === 0)) {
      messageContainer.scrollTop = messageContainer.scrollHeight
      this.count++
    }
  },
  destroyed: function () {
    this.count = 0
    this.clearRefreshTimer()
  },
  computed: {
  },
  methods: {
    async init () {
      await this.loadMessages()
      if (!this.timer) {
        this.startRefreshTimer()
      }
    },

    async refresh () {
      if (this.loading || this.reading || this.sending) {
        return false
      }
      await this.loadMessages()
    },

    async loadMessages () {
      this.loading = true
      if (this.demo) {
        this.friendgroup = {
          id: 0,
          messages: [
            { user_id: 1, created: null, nickname: 'VigZero', message: 'Welcome to the group page' },
            { user_id: 1, created: null, nickname: 'VigZero', message: 'Here you will be able to see friend\'s offers, chat, and enter contests within your group' }
          ],
          messagecount: 2,
          mutegroup: 0
        }
      }
      var user = UserService.getUserObject()
      if (!user) {
        return
      }
      await ChatService.getGroupMessages(this.friendgroupId, this.page).then(
        (response) => {
          this.friendgroup = response.data
          this.$emit('update')
          bus.$emit('messages-refresh')
        },
        (err) => {
          console.dir(err)
        }
      ).finally(
        () => {
          this.loading = false
        }
      )
    },

    startRefreshTimer () {
      // set the timer to run every 1 minute
      this.clearRefreshTimer()
      this.timer = setInterval(this.refresh, 60 * 1 * 1000)
    },

    clearRefreshTimer () {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },

    async toggleMuteGroup () {
      this.reading = true
      await ChatService.toggleMuteGroup(this.friendgroupId).then(
        async (response) => {
          this.reading = false
          await this.refresh()
        },
        (err) => {
          this.reading = false
          console.dir(err)
        }
      )
    },

    async sendMessage () {
      if (this.demo) {
        if (!this.message) {
          return
        }
        this.friendgroup.messagecount++
        this.friendgroup.messages.push(
          { user_id: 0, created: null, nickname: 'Demo User', message: this.message }
        )
        this.message = null
        return
      }
      if (!this.friendgroupId || !this.message || this.sending || this.demo) {
        return false
      }
      this.sending = true

      await ChatService.sendGroupMessage(this.friendgroupId, this.message).then(
        async (response) => {
          this.$emit('update')
          bus.$emit('messages-refresh')
          const data = {
            friendgroupid: this.friendgroupId,
            message: this.message
          }
          this.message = null
          this.$gtag.event('send', {
            event_category: 'group-message',
            event_label: 'sent',
            value: data
          })
          await this.loadMessages()
        },
        (err) => {
          console.dir(err)
        }
      ).finally(
        () => {
          this.sending = false
        }
      )
    },

    swipe: function (enable) {
      if (enable) {
        bus.$emit('inputblur')
      } else {
        bus.$emit('inputfocus')
      }
    }
  }
}
</script>
<style scoped>
.timestamp {
  font-size: 70%;
  color: #aaa;
}
.message {
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: inline-block;
  max-width: 80%;
}
.message-from {
  background: #c12626;
  color: white;
  padding: 4px;
  border-radius: 5px 5px 0 5px;
  margin-left: 15px;
}
.message-to {
  background: #272727;
  color: white;
  padding: 4px;
  border-radius: 5px 5px 5px 0;
  margin-right: 15px;
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
.v-input {
  font-size: 16px !important;
}
</style>
