var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.wager && _vm.wager.id > 0
    ? _c(
        "v-card",
        { staticClass: "vig-card mb-4" },
        [
          _c(
            "v-card-title",
            {
              staticClass: "py-0",
              on: {
                click: function($event) {
                  _vm.expand = !_vm.expand
                }
              }
            },
            [
              _vm._v(
                " Wager #" +
                  _vm._s(_vm.wager.id) +
                  " - " +
                  _vm._s(_vm._f("currency")(_vm.wager.offeredbyamount)) +
                  " " +
                  _vm._s(_vm.wager.bettype) +
                  " " +
                  _vm._s(_vm.wager.lineamount) +
                  " "
              )
            ]
          ),
          _c("v-expand-transition", [
            _vm.expand
              ? _c(
                  "div",
                  {
                    staticClass: "vig-card-expand",
                    staticStyle: { "margin-top": "0.5em" }
                  },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-row",
                          { staticClass: "ma-0" },
                          [
                            _c("v-col", { staticClass: "py-0" }, [
                              _c("h1", { staticClass: "text-center" }, [
                                _vm._v(
                                  "Offered By: " + _vm._s(_vm.wager.offeredby)
                                )
                              ]),
                              _c("h2", [
                                _vm._v(
                                  _vm._s(
                                    _vm.showWinLoss(_vm.wager.offeredby_id)
                                  )
                                )
                              ]),
                              _c("h2", [
                                _vm._v(
                                  _vm._s(
                                    _vm.showPaymentStatus(
                                      _vm.wager.offeredby_id
                                    )
                                  )
                                )
                              ])
                            ]),
                            _c("v-col", { staticClass: "py-0" }, [
                              _c("h1", { staticClass: "text-center" }, [
                                _vm._v("Taken By: " + _vm._s(_vm.wager.takenby))
                              ]),
                              _c("h2", [
                                _vm._v(
                                  _vm._s(_vm.showWinLoss(_vm.wager.takenby_id))
                                )
                              ]),
                              _c("h2", [
                                _vm._v(
                                  _vm._s(
                                    _vm.showPaymentStatus(_vm.wager.takenby_id)
                                  )
                                )
                              ])
                            ])
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [_c("v-divider")],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "ma-0" },
                          [
                            _c("v-col", { staticClass: "py-0" }, [
                              _c("h1", [
                                _vm._v(_vm._s(_vm.wager.game.awayteam))
                              ]),
                              _c("h1", [
                                _vm._v(_vm._s(_vm.wager.game.hometeam))
                              ])
                            ]),
                            _c("v-col", { staticClass: "py-0" }, [
                              _c("h1", { staticClass: "text-right" }, [
                                _vm._v(_vm._s(_vm.wager.game.awayteamscore))
                              ]),
                              _c("h1", { staticClass: "text-right" }, [
                                _vm._v(_vm._s(_vm.wager.game.hometeamscore))
                              ])
                            ]),
                            _c("v-col", { staticClass: "py-0" }, [
                              _c("h2", { staticClass: "text-center" }, [
                                _vm._v("Final")
                              ]),
                              _c("h2", { staticClass: "text-center" }, [
                                _vm._v(
                                  _vm._s(_vm.wager.game.gamedate_formatted)
                                )
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("v-card-actions", { staticClass: "pa-0" }, [
            _c(
              "div",
              {
                staticClass: "vig-card-expand-container",
                on: {
                  click: function($event) {
                    _vm.expand = !_vm.expand
                  }
                }
              },
              [
                _c("v-icon", [
                  _vm._v(
                    _vm._s(_vm.expand ? "arrow_drop_up" : "arrow_drop_down")
                  )
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }