<template>
  <v-container>
    <BackButton></BackButton>
    <v-row class="my-0 py-0">
      <v-col cols="12" class="py-0">
        <v-card
          class="vig-card"
        >
          <v-card-title>
            <GameSummary :game="game"></GameSummary>
          </v-card-title>

          <v-card-text class="vig-card-expand">
            <v-row class="my-0 py-0">
              <v-col cols="12" class="py-0">
                <GameDetail
                  :game="game"
                  :detail="true"
                ></GameDetail>
              </v-col>
              <v-col cols="12"
                class="py-0 vig-card-expand"
                v-if="offers && offers.length"
              >
                <table style="width: 100%; ">
                  <offer-list :offers="offers" @update="update()" @acceptOffer="accepted"></offer-list>
                </table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="game.id && !cancelled">
            <v-spacer></v-spacer>
            <router-link
              :to="{ name: 'makeoffergame', params: { game_id: game.id } }"
              tag="v-btn"
              class="text-right"
            >Make Offer</router-link>
          </v-card-actions>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import GameService from '@/services/GameService'
import OfferService from '@/services/OfferService'
import UserService from '@/services/UserService'
import GameSummary from '@/components/games/Summary'
import GameDetail from '@/components/games/Detail'
import OfferList from '@/components/offer/List'
import BackButton from '@/components/widget/Back'

export default {
  name: 'Game',
  components: {
    GameSummary,
    GameDetail,
    OfferList,
    BackButton
  },
  data: function () {
    return {
      game: {},
      offers: []
    }
  },
  props: {
    game_id: {
      type: [Number, String],
      default: 0
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init () {
      var filters = {
        time: 'future',
        nottaken: true,
        notuser: UserService.getUser().id,
        visibility: 'open',
        maximum: 'available',
        game: this.game_id
      }
      if (this.game_id > 0) {
        this.$updateloading(1)
        GameService.get(this.game_id).then((response) => {
          this.game = response.data
          this.game.expand = true
          OfferService.getOffers(filters).then((response) => {
            this.$updateloading(-1)
            this.offers = response.data.results
          }).catch(errors => {
            this.$updateloading(-1)
            console.dir(errors)
          })
        }).catch(errors => {
          this.$updateloading(-1)
          console.dir(errors)
        })
      }
    },
    update: function () {
      this.init()
    },
    accepted: function () {
      this.$notify({
        group: 'notices',
        title: 'Offer accpeted',
        text: 'Check pending for more info',
        type: 'info'
      })
      this.init()
    }
  },
  computed: {
    cancelled: function () {
      return this.game.starttime === this.game.endtime
    }
  }
}
</script>
