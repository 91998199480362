var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { on: { click: _vm.goBack } },
    [
      _c(
        "v-row",
        {
          staticClass: "my-0 py-0",
          attrs: { "no-gutters": "", align: "center", justify: "center" }
        },
        [
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _vm.show
                ? _c(
                    "v-btn",
                    {
                      staticClass: "white--text pull-right",
                      attrs: { fab: "", small: "", color: "black" }
                    },
                    [_c("v-icon", [_vm._v("keyboard_backspace")])],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.text != ""
            ? _c("v-col", { staticClass: "py-0 ml-2" }, [
                _c("h3", { staticClass: "black--text" }, [
                  _vm._v(_vm._s(_vm.text))
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }