var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          staticClass: "offer py-0 my-0 ml-n1",
          attrs: { align: "center", justify: "space-between", "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            { staticClass: "pr-2" },
            [
              _c(
                "v-row",
                { staticClass: "pa-0 ma-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0 ma-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vigzero-btn team robo offer-btn",
                          class: _vm.openaway ? "red-btn" : "grey-btn",
                          staticStyle: { display: "flex" }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "ma-0 pa-0",
                              attrs: {
                                align: "center",
                                justify: "center",
                                "no-gutters": ""
                              }
                            },
                            [
                              _c("v-col", { staticClass: "pa-0" }, [
                                _vm.wager.bettype_id != 20
                                  ? _c(
                                      "strong",
                                      {
                                        class: _vm.getTeamFontSize(
                                          _vm.wager.away
                                        )
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.wager.away) + " "
                                        ),
                                        _vm.wager.favoriteaway
                                          ? _c(
                                              "span",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "icon",
                                                    class: _vm.openaway
                                                      ? "white--text"
                                                      : "black--text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.wager.favorite
                                                          ? "favorite"
                                                          : "flag"
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _c("strong", [_vm._v("OVER")]),
                                _c("br")
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 line-font text-center",
                                  attrs: { cols: "2" }
                                },
                                [
                                  _vm.wager.bettype_id != 20
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("line")(_vm.gameline * -1)
                                          )
                                        )
                                      ])
                                    : _c("span", [_vm._v(_vm._s(_vm.gameline))])
                                ]
                              )
                            ],
                            1
                          ),
                          _vm.openhome
                            ? _c(
                                "v-row",
                                { staticClass: "my-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "usern" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.wager.offername) +
                                              " "
                                          ),
                                          _c("StarRating", {
                                            attrs: {
                                              rating: _vm.wager.offer_rating
                                            }
                                          }),
                                          _vm.openhome &&
                                          _vm.wager.record &&
                                          (_vm.wager.record.wins !== 0 ||
                                            _vm.wager.record.losses !== 0)
                                            ? _c(
                                                "span",
                                                [
                                                  _c("WagerRecord", {
                                                    attrs: {
                                                      record: _vm.wager.record
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center ma-0 pa-0",
                      attrs: { cols: "6", "align-self": "center" }
                    },
                    [
                      _vm.openaway
                        ? _c("div", [
                            _c(
                              "h1",
                              {
                                staticClass: "cash my-0 py-0 text-right",
                                staticStyle: { "line-height": "0.9em" }
                              },
                              [
                                _c("div", { staticClass: "button-group" }, [
                                  !_vm.wager.all
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "sumdiff-btn minus robo",
                                          class: {
                                            "disabled-btn":
                                              _vm.wager.takenbyamount - 5 < 5
                                          },
                                          staticStyle: {
                                            "line-height": "0.75em",
                                            color: "black"
                                          },
                                          attrs: {
                                            disabled:
                                              _vm.wager.takenbyamount - 5 < 5
                                          },
                                          on: { click: _vm.decrementAmount }
                                        },
                                        [_vm._v(" - ")]
                                      )
                                    : _vm._e(),
                                  _c("span", { staticClass: "amount" }, [
                                    _vm._v(
                                      "$" + _vm._s(_vm.wager.takenbyamount)
                                    )
                                  ]),
                                  !_vm.wager.all
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "sumdiff-btn plus robo",
                                          class: {
                                            "disabled-btn":
                                              _vm.wager.takenbyamount >
                                                _vm.wager.offeredbyamount ||
                                              _vm.takerAmount(
                                                _vm.wager.offeredbyamount
                                              ) <
                                                _vm.wager.takenbyamount + 5
                                          },
                                          staticStyle: {
                                            "line-height": "0.75em",
                                            color: "black"
                                          },
                                          attrs: {
                                            disabled:
                                              _vm.wager.takenbyamount >
                                                _vm.wager.offeredbyamount ||
                                              _vm.takerAmount(
                                                _vm.wager.offeredbyamount
                                              ) <
                                                _vm.wager.takenbyamount + 5
                                          },
                                          on: { click: _vm.incrementAmount }
                                        },
                                        [_vm._v(" + ")]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            ),
                            _c("span", { staticClass: "robo pull-right" }, [
                              _c("span", { staticClass: "value" }, [
                                _vm._v(
                                  "to win $" +
                                    _vm._s(
                                      _vm.offeredAmount(_vm.wager.takenbyamount)
                                    )
                                ),
                                _c("i", { staticClass: "value" }, [
                                  _vm._v(" *")
                                ]),
                                _c("br")
                              ]),
                              _vm.getWagerValue() != "0"
                                ? _c("span", { staticClass: "value" }, [
                                    _vm._v("Value: "),
                                    _c("span", { staticClass: "value-text" }, [
                                      _vm._v(_vm._s(_vm.getWagerValue()))
                                    ])
                                  ])
                                : _vm._e()
                            ])
                          ])
                        : _c("div", { staticClass: "text-center ml-12" }, [
                            _vm.wager.takenbyamountunrounded
                              ? _c(
                                  "span",
                                  { staticClass: "asterisk-message text-left" },
                                  [_vm._v(_vm._s(_vm.winAmountRoundedMessage))]
                                )
                              : _vm._e(),
                            _c("br"),
                            !_vm.wager.all
                              ? _c(
                                  "span",
                                  { staticClass: "asterisk-message text-left" },
                                  [
                                    _vm._v(
                                      "This bet can be taken in full or in part, in increments of $5."
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pa-0 ma-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0 ma-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vigzero-btn team robo offer-btn",
                          class: _vm.openhome ? "red-btn" : "grey-btn",
                          staticStyle: { display: "flex" }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "ma-0",
                              attrs: {
                                align: "center",
                                justify: "center",
                                "no-gutters": ""
                              }
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0" },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "ma-0",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _vm.wager.bettype_id != 20
                                            ? _c(
                                                "strong",
                                                {
                                                  class: _vm.getTeamFontSize(
                                                    _vm.wager.home
                                                  )
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.wager.home) +
                                                      " "
                                                  ),
                                                  _vm.wager.favoritehome
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "icon",
                                                              class: _vm.openhome
                                                                ? "white--text"
                                                                : "black--text"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.wager
                                                                    .favorite
                                                                    ? "favorite"
                                                                    : "flag"
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            : _c("strong", [_vm._v("UNDER")]),
                                          _c("br")
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "line-font text-center pa-0 ma-0",
                                  attrs: { cols: "2" }
                                },
                                [
                                  _vm.wager.bettype_id != 20
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm._f("line")(_vm.gameline))
                                        )
                                      ])
                                    : _c("span", [_vm._v(_vm._s(_vm.gameline))])
                                ]
                              )
                            ],
                            1
                          ),
                          _vm.openaway
                            ? _c(
                                "v-row",
                                { staticClass: "my-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "usern" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.wager.offername) +
                                              " "
                                          ),
                                          _c("StarRating", {
                                            attrs: {
                                              rating: _vm.wager.offer_rating
                                            }
                                          }),
                                          _vm.openaway &&
                                          _vm.wager.record &&
                                          (_vm.wager.record.wins !== 0 ||
                                            _vm.wager.record.losses !== 0)
                                            ? _c(
                                                "span",
                                                [
                                                  _c("WagerRecord", {
                                                    attrs: {
                                                      record: _vm.wager.record
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center pa-0 ma-0",
                      attrs: { cols: "6", "align-self": "center" }
                    },
                    [
                      _vm.openhome
                        ? _c("div", [
                            _c(
                              "h1",
                              {
                                staticClass: "cash my-0 py-0 text-right",
                                staticStyle: { "line-height": "0.9em" }
                              },
                              [
                                _c("div", { staticClass: "button-group" }, [
                                  !_vm.wager.all
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "sumdiff-btn minus robo",
                                          class: {
                                            "disabled-btn":
                                              _vm.wager.takenbyamount - 5 < 5
                                          },
                                          staticStyle: {
                                            "line-height": "0.75em",
                                            color: "black"
                                          },
                                          attrs: {
                                            disabled:
                                              _vm.wager.takenbyamount - 5 < 5
                                          },
                                          on: { click: _vm.decrementAmount }
                                        },
                                        [_vm._v(" - ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    { staticClass: "amount amountbig" },
                                    [
                                      _vm._v(
                                        "$" + _vm._s(_vm.wager.takenbyamount)
                                      )
                                    ]
                                  ),
                                  !_vm.wager.all
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "sumdiff-btn plus robo",
                                          class: {
                                            "disabled-btn":
                                              _vm.wager.takenbyamount >
                                                _vm.wager.offeredbyamount ||
                                              _vm.takerAmount(
                                                _vm.wager.offeredbyamount
                                              ) <
                                                _vm.wager.takenbyamount + 5
                                          },
                                          staticStyle: {
                                            "line-height": "0.75em",
                                            color: "black"
                                          },
                                          attrs: {
                                            disabled:
                                              _vm.wager.takenbyamount >
                                                _vm.wager.offeredbyamount ||
                                              _vm.takerAmount(
                                                _vm.wager.offeredbyamount
                                              ) <
                                                _vm.wager.takenbyamount + 5
                                          },
                                          on: { click: _vm.incrementAmount }
                                        },
                                        [_vm._v(" + ")]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            ),
                            _c("span", { staticClass: "robo pull-right" }, [
                              _c("span", { staticClass: "value" }, [
                                _vm._v(
                                  "to win $" +
                                    _vm._s(
                                      _vm.offeredAmount(_vm.wager.takenbyamount)
                                    )
                                ),
                                _c("i", { staticClass: "value" }, [
                                  _vm._v(" *")
                                ]),
                                _c("br")
                              ]),
                              _vm.getWagerValue() != "0"
                                ? _c("span", { staticClass: "value" }, [
                                    _vm._v("Value: "),
                                    _c("span", { staticClass: "value-text" }, [
                                      _vm._v(_vm._s(_vm.getWagerValue()))
                                    ])
                                  ])
                                : _vm._e()
                            ])
                          ])
                        : _c("div", { staticClass: "text-center ml-12" }, [
                            _vm.wager.takenbyamountunrounded
                              ? _c(
                                  "span",
                                  { staticClass: "asterisk-message text-left" },
                                  [_vm._v(_vm._s(_vm.winAmountRoundedMessage))]
                                )
                              : _vm._e(),
                            _c("br"),
                            !_vm.wager.all
                              ? _c(
                                  "span",
                                  { staticClass: "asterisk-message text-left" },
                                  [
                                    _vm._v(
                                      "This bet can be taken in full or in part, in increments of $5."
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }