var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        {
          ref: "messageContainer",
          staticClass: "overflow-y-auto pa-0 my-0",
          staticStyle: { "max-height": "200px" }
        },
        [
          _vm.messages && _vm.messages.length > 0
            ? _c(
                "div",
                _vm._l(_vm.messages, function(m) {
                  return _c(
                    "v-row",
                    {
                      key: m.id,
                      staticClass: "mt-0 mb-1",
                      attrs: {
                        justify: m.user_id == _vm.userId ? "end" : "start"
                      }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          class:
                            m.user_id == _vm.userId ? "text-right" : "text-left"
                        },
                        [
                          _c("div", { staticClass: "timestamp" }, [
                            _vm._v(_vm._s(m.created))
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "message",
                              class:
                                m.user_id == _vm.userId
                                  ? "message-from"
                                  : "message-to"
                            },
                            [_c("div", [_vm._v(_vm._s(m.message))])]
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ]
      ),
      _vm.canChat
        ? _c("v-text-field", {
            staticClass: "chat-notification-reply pt-1 pb-2",
            attrs: {
              "hide-details": "",
              placeholder: "Type your message here",
              solo: "",
              dense: "",
              rounded: ""
            },
            on: {
              keypress: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.sendMessage($event)
              },
              focus: function($event) {
                return _vm.swipe(false)
              },
              blur: function($event) {
                return _vm.swipe(true)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "append",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-0 pa-0",
                          attrs: {
                            disabled: _vm.reading || _vm.sending,
                            icon: ""
                          },
                          on: { click: _vm.sendMessage }
                        },
                        [_c("v-icon", [_vm._v("send")])],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3433718177
            ),
            model: {
              value: _vm.message,
              callback: function($$v) {
                _vm.message = $$v
              },
              expression: "message"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }