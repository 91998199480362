<template>
  <div>
    <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <div
            class="mx-auto text-center"
            style="font-size: 30px;"
          >
            Payment Instructions<br> &amp;<br>Confirmation
          </div>
        </v-card-title>
        <v-card-text class="black--text">
          <v-row
            class="my-0 py-0"
            v-if="wager.paymentdenycount > 0"
          >
            <v-col
              cols="12"
              class="py-0"
            >
              <h4>{{ (wager.winner_id === wager.offeredby_id) ? wager.offername : wager.takername }}
                could not confirm receiving your payment. Please double check your transaction.</h4>
            </v-col>
          </v-row>
          <v-row
            class="my-0 py-0"
            justify="space-between"
          >
            <v-col
              cols="12"
              class="py-0"
            >
              <span class="action-section">Bet:</span> #{{ wager.id }}
              <span class="loser">LOSS</span> {{ mySideInfo }}
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <span class="action-section">Amount:</span> ${{ wager.amount }}
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <span class="action-section">Pay to:</span>
              {{ (wager.winner_id === wager.offeredby_id) ? wager.offername : wager.takername }}
              {{ otherSideInfo }}
            </v-col>
            <v-col
              cols="12"
              class="pt-0 pb-4"
            >
              <span class="action-section">Venmo:</span> <a
                class="link"
                :href="paymentlink"
                target="_blank"
                style="font-size: 16px;"
              >{{ paymenthandle }}</a>
            </v-col>
          </v-row>
          <p>1. Copy or note the bet reference for entering in Venmo note:</p>
          <div
            style="font-size: 3rem; line-height:normal"
            class="robo text-center"
          >
            VZ{{ wager.id }}
          </div>

          <p>2. Use the link below or go to your Venmo app and pay <b>{{ paymenthandle }}</b>:</p>

          <div>
            <div
              style="width: 100%"
              class="text-center pb-4"
            >
              <a
                class="link"
                :href="paymentlink"
                target="_blank"
                style="font-size: 16px;"
              >{{
                paymentlink
              }}</a>
            </div>
          </div>

          <p>3. IMPORTANT: Confirm below attesting to paying the winner in full for the referenced bet.</p>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            class="white--text"
            @click="cancel"
          >Cancel</v-btn>
          <v-btn
            color="light-green darken-2"
            class="white--text"
            @click="agree"
          >Payment Made</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DashboardService from '@/services/DashboardService'

export default {
  data: () => {
    return {
      loading: false
    }
  },
  computed: {
    showDialog: function () {
      return this.wager && this.wager.id > 0
    },
    mySideInfo: function () {
      var homeline =
        this.wager.lineamount > 0
          ? '+' + this.wager.lineamount
          : this.wager.lineamount
      var awayline =
        this.wager.lineamount * -1 > 0
          ? '+' + this.wager.lineamount * -1
          : this.wager.lineamount * -1
      var homeinfo =
        this.wager.bettype_id === 20
          ? 'Over ' + this.wager.lineamount
          : this.wager.homeabbr + ' ' + homeline
      var awayinfo =
        this.wager.bettype_id === 20
          ? 'Under ' + this.wager.lineamount
          : this.wager.awayabbr + ' ' + awayline
      if (this.wager.loser_id === this.wager.takenby_id) {
        if (this.wager.takenbyteam_id === this.wager.hometeam_id) {
          return homeinfo
        } else {
          return awayinfo
        }
      } else {
        if (this.wager.offeredbyteam_id === this.wager.hometeam_id) {
          return homeinfo
        } else {
          return awayinfo
        }
      }
    },
    otherSideInfo: function () {
      var homeline =
        this.wager.lineamount > 0
          ? '+' + this.wager.lineamount
          : this.wager.lineamount
      var awayline =
        this.wager.lineamount * -1 > 0
          ? '+' + this.wager.lineamount * -1
          : this.wager.lineamount * -1
      var homeinfo =
        this.wager.bettype_id === 20
          ? 'Over ' + this.wager.lineamount
          : this.wager.homeabbr + ' ' + homeline
      var awayinfo =
        this.wager.bettype_id === 20
          ? 'Under ' + this.wager.lineamount
          : this.wager.awayabbr + ' ' + awayline
      if (this.wager.winner_id === this.wager.takenby_id) {
        if (this.wager.takenbyteam_id === this.wager.hometeam_id) {
          return homeinfo
        } else {
          return awayinfo
        }
      } else {
        if (this.wager.offeredbyteam_id === this.wager.hometeam_id) {
          return homeinfo
        } else {
          return awayinfo
        }
      }
    },
    paymentlink: function () {
      return 'https://venmo.com/u/' + this.paymenthandle
    },
    paymenthandle: function () {
      var pmtinfo = ''
      if (this.wager.offerpaymentmethod || this.wager.takerpaymentmethod) {
        if (this.wager.winner_id === this.wager.offeredby_id) {
          pmtinfo = this.wager.offerpaymentmethod[0].value
        } else {
          pmtinfo = this.wager.takerpaymentmethod[0].value
        }
      } else {
        pmtinfo = this.wager.paymentdetails[0].value
      }
      return pmtinfo
    },
    timetopay: function () {
      if (this.wager.timetopay) {
        return '(' + this.wager.timetopay.toFixed(1) + ' hours since game)'
      } else {
        return ''
      }
    }
  },
  props: {
    wager: {
      type: Object,
      default: null
    }
  },
  methods: {
    async agree () {
      this.$updateloading(1)
      await DashboardService.settleDebt(this.wager.id).then(
        (response) => {
          this.$updateloading(-1)
          this.$emit('paid')
        },
        (error) => {
          this.$updateloading(-1)
          alert(
            'An error has occured, please try again later. If the error continues to happen please contact us\n' +
            error.response.data.error
          )
        }
      )
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
