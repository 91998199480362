<template>
  <div
    class="wrapper fadeInDown text-center"
    v-if="contest"
  >
    <v-row
      class="my-0"
      align="center"
      justify="center"
    >
      <v-col
        class="pa-0"
        cols="3"
      >
        <v-img
          :src="getLogo()"
          max-width="100"
        ></v-img>
      </v-col>
      <v-col class="pa-0">
        <h1>{{ group.name }}</h1>
        <h1>{{ contest.name }}</h1>
      </v-col>
      <v-col
        class="pa-0"
        cols="3"
        align="center"
      >
        <v-img
          alt=""
          src="../assets/madness-survivor-logo.png"
          max-width="100"
        ></v-img>
      </v-col>
    </v-row>
    <v-row class="my-0">
      <v-col class="py-0">
        <p class="mb-1">{{ contest.summary }}</p>
      </v-col>
    </v-row>

    <div v-if="contest.open">
      <v-row class="mb-3">
        <v-col class="py-0">
          <h2 class="black--text ma-0">Entry Fee: ${{ contest.entryfee }}</h2>
        </v-col>
        <v-col class="py-0">
          <h2 class="black--text ma-0"><span v-if="contest.maximum > 0">{{ contest.maximum - contest.entries }} spots left</span><span v-else>Entries: {{ contest.entries }}</span></h2>
        </v-col>
      </v-row>

      <v-row
        class="mb-3"
        v-if="contest.deadline"
      >
        <v-col class="py-0">
          <h2 class="black--text ma-0">Deadline: {{ contest.deadline_formatted }}</h2>
        </v-col>
      </v-row>

      <v-row class="mb-3">
        <v-col class="py-0">
          <h2 class="black--text ma-0">Your Entries: {{ contest.myentries }}<span v-if="contest.limitperuser"> of {{ contest.limitperuser }}</span></h2>
          <br>
          <v-btn
            v-if="this.canEnter"
            :disabled="loading"
            small
            @click="enterContest = contest"
            color="primary"
          >{{ (contest.myentries > 0) ? 'Enter Again' : 'Enter' }}</v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row class="mb-3">
        <v-col class="py-0">
          <h2 class="black--text ma-0">Entries: {{ entriesAlive }} of {{ assignedTeams }} left</h2>
        </v-col>
      </v-row>
    </div>

    <v-alert
      v-if="contest.balance > 0"
      text
      prominent
      type="error"
      icon="mdi-currency-usd"
      border="top"
      elevation="2"
      dismissible
    >
      Pay your entry fee balance of ${{ contest.balance }} easily via Venmo to <a :href="'//venmo.com/u/' + contest.venmo">@{{ contest.venmo }}</a>.
    </v-alert>

    <v-expansion-panels
      v-if="contest && tournament && tournament.teamsassigned"
      v-model="contestMyEntriesPanel"
    >
      <v-expansion-panel class="v-expansion-panel-skinny">
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          <h2 class="ma-0 black--text">My Entries</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ContestResults
            v-if="contest && tournament"
            :contest="contest"
            :tournament="tournament"
            :group_id="group_id"
            :admin="isAdmin"
            :user_id="userId"
            user-only
            @refresh="init"
          ></ContestResults>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels
      v-if="contest && tournament && tournament.teamsassigned"
      v-model="contestResultsPanel"
    >
      <v-expansion-panel class="v-expansion-panel-skinny">
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          <h2 class="ma-0 black--text">Bracket/Results</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ContestResults
            v-if="contest && tournament"
            :contest="contest"
            :tournament="tournament"
            :group_id="group_id"
            :admin="isAdmin"
            :user_id="userId"
            @refresh="init"
          ></ContestResults>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <ContestEntries
      v-if="contest && (contest.myentries > 0 || isAdmin)"
      :contest="contest"
      :admin="isAdmin"
      @refresh="init"
    ></ContestEntries>

    <v-expansion-panels v-if="contest && isAdmin">
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          <h2 class="ma-0 black--text">Contest Settings</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ContestSettings
            v-if="contest && isAdmin"
            :contest="contest"
            :contesttype="contesttype"
            :group_id="group_id"
            @refresh="init"
          ></ContestSettings>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <ContestPrizes
      v-if="contest"
      :contest="contest"
    ></ContestPrizes>

    <ContestRules
      v-if="contest"
      :contest="contest"
    ></ContestRules>

    <v-expansion-panels v-if="contest && isAdmin">
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          <h2 class="ma-0 black--text">Email</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ContestEmail
            v-if="contest && isAdmin"
            :contest="contest"
            :group="group"
            @refresh="init"
          ></ContestEmail>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <v-col class="text-left">
        <Back
          text=""
          @goBack="$router.push('/group/' + group_id)"
          emit="goBack"
          class="pull-left pr-3"
        ></Back>
        <h3 class="mt-2 black--text">Back To Group</h3>
      </v-col>
    </v-row>

    <ContestEntryDialog
      v-if="enterContest"
      :contest="enterContest"
      :group="group"
      @cancel="enterContest = null"
      @enter="processContestEntry"
    ></ContestEntryDialog>

  </div>
</template>

<script>
import logo from '@/assets/logo.png'
import UserService from '@/services/UserService'
import ContestService from '@/services/ContestService'
import Back from '@/components/widget/Back'
import ContestEntryDialog from '@/components/contests/EntryDialog'
import ContestRules from '@/components/contests/Rules'
import ContestEntries from '@/components/contests/Entries'
import ContestPrizes from '@/components/contests/Prizes'
import ContestSettings from '@/components/contests/Settings'
import ContestResults from '@/components/contests/Results'
import ContestEmail from '@/components/contests/Email'

export default {
  name: 'Contest',
  components: {
    Back,
    ContestRules,
    ContestEntries,
    ContestPrizes,
    ContestEntryDialog,
    ContestSettings,
    ContestResults,
    ContestEmail
  },

  props: {
    group_id: {
      type: String,
      default: '0'
    },
    contest_id: {
      type: String,
      default: '0'
    },
    enter: {
      type: [String, Boolean],
      default: false
    }
  },

  data: function () {
    return {
      loading: false,
      userId: 0,
      contest: null,
      contesttype: null,
      group: null,
      tournament: null,
      enterContest: null,
      contestResultsPanel: null,
      contestMyEntriesPanel: null
    }
  },

  mounted () {
    const user = UserService.getUser()
    if (user) {
      this.userId = +user.id
      this.init()
    } else {
      this.$router.push('/login/?r=/group/' + +this.group_id + '/contest/' + +this.contest_id)
    }
  },

  watch: {
    showResults: function (n, o) {
      if (o !== n && n) {
        this.init()
      }
    }
  },

  methods: {
    init () {
      this.loading = true
      this.$updateloading(1)
      ContestService.getContest(+this.contest_id, (this.enter && this.enter.length > 0)).then(
        (response) => {
          this.contest = response.data.contest
          this.contesttype = response.data.contesttype
          this.group = response.data.group
          this.tournament = response.data.tournament
          this.loading = false
          if (this.enter && this.enter.length > 0) {
            this.$router.push('/group/' + this.group_id + '/contest/' + this.contest_id)
          }
          this.$updateloading(-1)
        },
        (err) => {
          var error = err.response.data
          this.loading = false
          this.$router.push('/group/' + this.group_id, () => {
            this.$notify({
              group: 'errors',
              title: error.errors[0],
              text: error.error,
              type: 'warn'
            })
          })
          this.$updateloading(-1)
        }
      )
    },

    processContestEntry () {
      this.enterContest = null
      this.init()
      this.$notify({
        group: 'notices',
        title: 'Contest Entered',
        text: 'Your contest entry was submitted successfully.',
        type: 'info'
      })
    },

    getLogo: function () {
      if (this.group.avatar && this.group.avatar.thumbnails && this.group.avatar.thumbnails.large) {
        return this.group.avatar.thumbnails.large
      }
      return logo
    }
  },

  computed: {
    showResults: function () {
      return (this.contestResultsPanel === 0 || this.contestMyEntriesPanel === 0)
    },

    canEnter: function () {
      return ((this.contest) && (!this.contest.maximum || this.contest.entries < this.contest.maximum) && (!this.contest.limitperuser || this.contest.myentries < this.contest.limitperuser))
    },

    isAdmin: function () {
      return (this.userId === this.group.user_id)
    },

    entriesAlive: function () {
      var losses = 0
      var entries = 0
      if (this.tournament.current_round && this.tournament.matchups && this.tournament.matchups[this.tournament.current_round]) {
        this.tournament.matchups[this.tournament.current_round].forEach((m) => {
          if (m.mygame) {
            if (+m.favorite_userid === +this.userId) {
              entries++
            }
            if (+m.underdog_userid === +this.userId) {
              entries++
            }
            if (m.winner === 'favorite' && +m.favorite_userid !== +this.userId) {
              losses++
            } else if (m.winner !== 'undecided' && +m.underdog_userid !== +this.userId) {
              losses++
            }
          }
        })
      }
      return entries - losses
    },

    assignedTeams: function () {
      var teams = 0
      if (this.tournament.assignedteams && this.tournament.assignedteams[1] && this.tournament.participant) {
        for (const t in this.tournament.assignedteams[1]) {
          if (this.tournament.participant[this.tournament.assignedteams[1][t].vigcontestentryid] && +this.tournament.participant[this.tournament.assignedteams[1][t].vigcontestentryid].user_id === +this.userId) {
            teams++
          }
        }
      }
      return teams
    }
  }
}
</script>

<style>
.v-expansion-panel-skinny .v-expansion-panel-content__wrap {
  padding: 0 7px !important;
}
</style>
