
<template>
  <div>
    <v-dialog
      v-model="showDialog"
      v-if="showDialog"
      persistent
    >
      <v-card>
        <v-card-title>
          Dispute
        </v-card-title>
        <v-card-text>
          It may take a bit for a user to confirm their payment to you and you are not
          prevented from making other wagers due to this one. For this reason, you will not be allowed to make a dispute unless
          it has been at least 48 hours since the end of the game. Thank you.
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-btn color="black" class="white--text" @click="cancel()">
            Cancel
          </v-btn>
          <v-btn color="red darken-1" class="white--text" @click="dispute()">
            Dispute
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      loading: false
    }
  },
  computed: {
    showDialog: function () {
      return (this.wager && this.wager.id > 0)
    }
  },
  props: {
    wager: {
      type: Object,
      default: null
    }
  },
  methods: {
    dispute () {
      if (this.wager.candispute) {
        this.$router.push('/disputes/create/' + this.wager.id)
      } else {
        this.$notify({
          group: 'errors',
          title: 'Cannot Dispute',
          text: 'Cannot make a dispute until 48 hours have passed',
          type: 'warn'
        })
      }
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
