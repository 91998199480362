<template>
  <div v-if="releasenotes && releasenotes.length > 0">
    <v-card
      class="release-note-card card"
      :loading="loading"
    >
      <v-card-title class="py-1" v-if="releasenotes[0].headline">{{ releasenotes[0].headline }}</v-card-title>
      <v-card-text class="pt-0 pb-2 black--text" v-html="releasenotes[0].message"></v-card-text>
      <v-card-actions class="pt-0 pb-1">
        <v-flex class="text-right">
          <v-btn
            small
            color="secondary"
            @click.prevent="acknowledge(releasenotes[0].id)"
            :loading="loading"
            elevation="3"
          >Got It!</v-btn>
        </v-flex>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-line vue/no-dupe-keys
import UserService from '@/services/UserService'
import { bus } from '@/main'

export default {
  name: 'ReleaseNotes',
  data: function () {
    return {
      loading: false,
      releasenotes: {
        get: function () {
          return UserService.getReleaseNotes()
        }
      }
    }
  },
  created () {
    this.init()
    bus.$on('localStorageUpdate', (data) => {
      this.init()
    })
  },
  methods: {
    async init () {
      this.releasenotes = await UserService.getReleaseNotes()
    },
    async acknowledge (id) {
      this.loading = true
      const user = UserService.getUserObject()

      if (!user) {
        return
      }

      // this.$updateloading(1)
      await UserService.acknowledgeReleaseNote(id).then(async (response) => {
        if (response.data && response.data.id > 0) {
          // refresh release notes
          await UserService.refreshAuthData()
          await this.init()
        }
        // this.$updateloading(-1)
        this.loading = false
      }).catch(errors => {
        // this.$updateloading(-1)
        this.loading = false
        console.dir(errors)
      })
    }
  }
}
</script>
