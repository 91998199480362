<style scoped>
</style>
<template>
  <v-card
    class="vig-card mb-4"
    v-if="dispute.logs && dispute.logs.length"
  >
    <v-card-title
      class="py-0"
      @click="expand = !expand"
    >Activity Log</v-card-title>
    <v-expand-transition>
      <div
        v-show="expand"
        class="vig-card-expand"
        style="margin-top: 0.5em;"
      >
        <v-card-text class="py-0">
          <v-row
            v-for="l in dispute.logs"
            :key="l.id"
            class="ma-0"
          >
            <v-col class="px-0 py-2">{{ l.logdate.substring(5) }}: <strong>{{ l.user }}</strong> - {{ l.action }}<span v-if="l.description"> - {{ l.description }}</span></v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-expand-transition>
    <v-card-actions class="pa-0">
      <div
        class="vig-card-expand-container"
        @click="expand = !expand"
      >
        <v-icon>{{ (expand) ? 'arrow_drop_up' : 'arrow_drop_down' }}</v-icon>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'DisputeLog',
  props: {
    dispute: Object
  },
  data: function () {
    return {
      expand: false,
      headers: [
        { text: 'Action', align: 'start', value: 'action' },
        { text: 'User', value: 'user' },
        { text: 'Time Stamp', value: 'logdate' }
      ]
    }
  }
}
</script>
