<style scoped>
  .gradient-bar {
    width: 100%;
    background-image: linear-gradient(to right, #c02626, rgb(255, 255, 0), #4a992e);
    height: 7px;
    z-index: 0;
  }

  .progress-bar {
    border-style: none solid none none;
    border-width: 2px;
    height: 20px;
    z-index: 1;
    transform: translate(0, -0.97em);
    transition: 1s
  }
</style>
<template>
  <div style="width:100%;" v-show="probability != null" class="pt-3 mb-n3 px-6">
    <div class="percentage" :style="percentageStyle">{{ probability }}%</div>
    <div class="gradient-bar"></div>
    <div class="progress-bar" :style="{ 'width': probabilityWidth }"></div>
  </div>
</template>
<script>
export default {
  props: {
    probability: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {}
  },
  computed: {
    probabilityWidth: function () {
      var prob = this.probability
      if (prob > 100) {
        prob = 100
      } else if (prob < 0) {
        prob = 0
      }
      return prob + '%'
    },
    percentageStyle: function () {
      var prob = this.probability
      if (prob > 100) {
        prob = 100
      } else if (prob < 0) {
        prob = 0
      }
      var style = {
        'text-align': 'right',
        width: prob + '%',
        transform: 'translateY(-0.7em)',
        transition: '1s'
      }
      if (prob < 2) {
        style['margin-left'] = '-0.45em'
      } else if (prob === 2) {
        style['margin-left'] = '-0.2em'
      } else if (prob === 3) {
        style['margin-left'] = '0'
      } else if (prob === 4) {
        style['margin-left'] = '0.2em'
      } else if (prob === 5) {
        style['margin-left'] = '0.45em'
      } else if (prob > 5 && prob < 10) {
        style['margin-left'] = '0.6em'
      } else if (prob > 9 && prob < 100) {
        style['margin-left'] = '1em'
      } else {
        style['margin-left'] = '1.45em'
      }

      var hue = ((prob / 100) * (104 - 0))
      var perc1 = ((prob / 100) * (54 - 67)) + 67
      var perc2 = ((prob / 100) * (39 - 45)) + 45
      style.color = 'hsl(' + hue + ', ' + perc1 + '%, ' + perc2 + '%)'

      return style
    }
  }
}
</script>
