var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PaymentReceivedDialog", {
        attrs: { wager: _vm.confirmPayout },
        on: {
          cancel: function($event) {
            _vm.confirmPayout = null
          },
          paid: function($event) {
            _vm.confirmPayout = null
            _vm.update()
          },
          deny: function($event) {
            _vm.confirmPayout = null
            _vm.update()
          },
          dispute: function($event) {
            _vm.confirmPayout = null
            _vm.update()
          }
        }
      }),
      _c("PaymentSentDialog", {
        attrs: { wager: _vm.confirmDebt },
        on: {
          cancel: function($event) {
            _vm.confirmDebt = null
          },
          paid: function($event) {
            _vm.confirmDebt = null
            _vm.update()
          }
        }
      }),
      _vm.clickedOffer != null
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "" },
              model: {
                value: _vm.showDelete,
                callback: function($$v) {
                  _vm.showDelete = $$v
                },
                expression: "showDelete"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "text-center" }, [
                    _vm._v(" Delete Offer ")
                  ]),
                  _c("v-card-text", { staticClass: "pt-2" }, [
                    _vm._v(" Are you sure you want to delete this offer? ")
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "black" },
                          on: {
                            click: function($event) {
                              return _vm.cancelDelete()
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            disabled: _vm.clickedOffer.takenby_id != null,
                            color: "red"
                          },
                          on: {
                            click: function($event) {
                              return _vm.deleteOffer()
                            }
                          }
                        },
                        [_vm._v("DELETE")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.offers, function(offer) {
        return _c(
          "div",
          {
            key: offer.id,
            attrs: { id: _vm.generateWagerIdentifier(offer.id) },
            on: {
              click: function($event) {
                return _vm.offerclicked(offer)
              }
            }
          },
          [
            _c("History", {
              attrs: {
                offer: offer,
                admin: offer.admin,
                canChat: _vm.canChat,
                status: offer.status ? offer.status : _vm.status
              },
              on: {
                delete: function($event) {
                  return _vm.openDelete(offer)
                },
                update: function($event) {
                  return _vm.update()
                },
                sendpayment: _vm.openSendPayment,
                confirmpayment: function($event) {
                  return _vm.update()
                },
                denypayment: _vm.openConfirmPayment,
                expanded: _vm.setExpandedWagers
              }
            }),
            _c("div", { staticClass: "spacer" })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }