var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canInvite && _vm.userId > 0
    ? _c(
        "v-row",
        {
          key:
            "groupInvite-" + _vm.group.id + "-"(_vm.loading)
              ? "disabled"
              : "active",
          staticClass: "ma-3"
        },
        [
          _c(
            "v-col",
            [
              _c("v-autocomplete", {
                attrs: {
                  label: "Search Users to Invite",
                  items: _vm.results,
                  "search-input": _vm.autocomplete,
                  "item-text": "nickname",
                  "return-object": "",
                  loading: _vm.loading,
                  "hide-no-data": !_vm.loading,
                  filter: function() {
                    return true
                  },
                  clearable: ""
                },
                on: {
                  "update:searchInput": function($event) {
                    _vm.autocomplete = $event
                  },
                  "update:search-input": function($event) {
                    _vm.autocomplete = $event
                  },
                  input: _vm.select
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "append-outer",
                      fn: function() {
                        return [
                          _c(
                            "v-slide-x-reverse-transition",
                            { attrs: { mode: "out-in" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "background-red white--text",
                                  attrs: {
                                    disabled: !_vm.inviteuser,
                                    small: ""
                                  },
                                  on: { click: _vm.invite }
                                },
                                [_vm._v("Invite")]
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  4120275591
                )
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }