var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAllowedToInvite
    ? _c(
        "v-card",
        { staticClass: "vig-card" },
        [
          _c(
            "v-card-text",
            { staticClass: "vig-card-expand" },
            [
              _c(
                "div",
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "email",
                      label: "Email",
                      placeholder: "Friend's Email",
                      "error-messages": _vm.getErrors("email"),
                      required: ""
                    },
                    on: {
                      blur: function($event) {
                        return _vm.$v.form.email.$touch()
                      }
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "mb-2 mt-n2 center" }, [
                _c("b", [_vm._v("AND/OR")])
              ]),
              _c(
                "div",
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Phone",
                      placeholder: "Friend's cell phone",
                      "error-messages": _vm.getErrors("phone"),
                      required: ""
                    },
                    on: {
                      blur: function($event) {
                        return _vm.$v.form.phone.$touch()
                      }
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "phone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("v-textarea", {
                    attrs: {
                      solo: "",
                      label: "Personalized Message",
                      "error-messages": _vm.getErrors("message"),
                      maxlength: "250"
                    },
                    on: {
                      blur: function($event) {
                        return _vm.$v.form.message.$touch()
                      }
                    },
                    model: {
                      value: _vm.form.message,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "message", $$v)
                      },
                      expression: "form.message"
                    }
                  })
                ],
                1
              ),
              _vm.grouplist.length > 0
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "12" } },
                        [_vm._v("Add your invitee to an existing group?")]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        _vm._l(_vm.grouplist, function(group) {
                          return _c("v-checkbox", {
                            key: group.id,
                            staticClass: "py-0 pl-4 mt-n3",
                            attrs: { label: group.name, value: group.id },
                            model: {
                              value: _vm.form.groups,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "groups", $$v)
                              },
                              expression: "form.groups"
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text-right",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.invite($event)
                    }
                  }
                },
                [_vm._v("Invite")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }