import axios from 'axios'
import Vue from 'vue'
import Notifications from 'vue-notification'
import UserService from './UserService'

Vue.use(Notifications)

const CommunityService = {

  async searchUsers (searchterm) {
    await UserService.authHeader()
    var search = {
      searchterm: searchterm
    }
    return axios({
      url: UserService.baseUrl() + 'community/searchusers',
      data: JSON.stringify(search),
      method: 'post'
    })
  },

  async makegroup (name) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'community/makegroup',
      data: JSON.stringify(name),
      method: 'post'
    })
  },

  async inviteUser (groupId, user) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'community/invite/' + groupId,
      data: JSON.stringify(user),
      method: 'post'
    })
  },

  async inviteusers (users, groupid) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'community/inviteusers/' + groupid,
      data: JSON.stringify(users),
      method: 'post'
    })
  },

  async getgroups () {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'community/getgroups',
      method: 'get'
    })
  },

  async getinvites () {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'community/getinvites',
      method: 'get'
    })
  },

  async invitation (data) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'community/invitation',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async getmembers (id) {
    await UserService.authHeader(false)
    return axios({
      url: UserService.baseUrl() + 'community/' + id + '/members',
      method: 'get'
    })
  },

  async updategroup (group) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'community/updategroup/',
      data: JSON.stringify(group),
      method: 'post'
    })
  },

  async uploadGroupAvatar (file) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'community/updategroup/avatar',
      data: file,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },

  async getGroupAvatar () {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'community/getgroupavatar',
      method: 'get'
    })
  },

  async deletegroup (id) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'community/delete/' + id,
      method: 'get'
    })
  },

  async resendInvite (groupmemberid) {
    var data = {
      member_id: groupmemberid
    }
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'community/resendinvite',
      data: JSON.stringify(data),
      method: 'post'
    })
  },

  async leavegroup (groupid) {
    await UserService.authHeader()
    return axios({
      url: UserService.baseUrl() + 'community/leave',
      data: JSON.stringify({ groupid: groupid }),
      method: 'post'
    })
  },

  async groupListInit () {
    await UserService.authHeader(false)
    return axios({
      url: UserService.baseUrl() + 'community/listInit',
      method: 'get'
    })
  },

  async groupInfoInit (groupid) {
    await UserService.authHeader(false)
    return axios({
      url: UserService.baseUrl() + 'community/groupInfoInit',
      data: JSON.stringify({ groupid: groupid }),
      method: 'post'
    })
  }
}

export default CommunityService
