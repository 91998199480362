var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.messages && _vm.messages.total > 0
    ? _c(
        "div",
        [
          _c(
            "v-card",
            {
              staticClass: "chat-notification-card card",
              attrs: { loading: _vm.loading }
            },
            [
              _c(
                "v-card-text",
                { staticClass: "chat-notification-heading pt-1 pb-0 px-0" },
                [
                  _c(
                    "v-row",
                    { staticClass: "my-0 mx-1" },
                    [
                      _c("v-col", { staticClass: "px-0 py-0" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "span",
                              { staticClass: "text-red" },
                              [
                                _c("v-icon", { staticClass: "text-red" }, [
                                  _vm._v("$bullhorn")
                                ]),
                                _c("strong", { staticClass: "pl-1" }, [
                                  _vm._v("(" + _vm._s(_vm.messages.total) + ")")
                                ])
                              ],
                              1
                            ),
                            _vm.currentMessage.wager_id
                              ? _c(
                                  "strong",
                                  { staticClass: "text--black px-2" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currentMessage.nickname.substring(
                                          0,
                                          17
                                        )
                                      )
                                    )
                                  ]
                                )
                              : _c(
                                  "strong",
                                  { staticClass: "text--black px-2" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currentMessage.friendgroup.substring(
                                          0,
                                          17
                                        )
                                      )
                                    )
                                  ]
                                ),
                            _vm.messages.total !== _vm.currentThread.length
                              ? _c("span", { staticClass: "text-red pr-1" }, [
                                  _vm._v(
                                    "(" + _vm._s(_vm.currentThread.length) + ")"
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "chat-notification-button-mute ma-0",
                                attrs: {
                                  "x-small": "",
                                  tile: "",
                                  disabled:
                                    _vm.loading || _vm.reading || _vm.sending
                                },
                                on: { click: _vm.muteThread }
                              },
                              [_vm._v("Mute")]
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0 text-center",
                          attrs: { cols: "2" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "chat-notification-button ma-0",
                              attrs: {
                                "x-small": "",
                                tile: "",
                                disabled:
                                  _vm.loading || _vm.reading || _vm.sending
                              },
                              on: { click: _vm.readMessage }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.messages.total > 1 ? "Next" : "OK")
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.currentMessage.wager_id
                ? _c(
                    "v-card-text",
                    { staticClass: "chat-notification-gameinfo py-0 px-1" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-0 mx-1" },
                        [
                          _c("v-col", { staticClass: "px-0 py-0" }, [
                            _c("div", [
                              _vm._v(
                                " " + _vm._s(_vm.currentMessage.awayteam) + " "
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.gameStarted,
                                      expression: "gameStarted"
                                    }
                                  ]
                                },
                                [_vm._v(_vm._s(_vm.awayScore))]
                              ),
                              _vm._v(
                                " @ " +
                                  _vm._s(_vm.currentMessage.hometeam) +
                                  " "
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.gameStarted,
                                      expression: "gameStarted"
                                    }
                                  ]
                                },
                                [_vm._v(_vm._s(_vm.homeScore))]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.gameStatus,
                                      expression: "gameStatus"
                                    }
                                  ]
                                },
                                [_vm._v("| " + _vm._s(_vm.gameStatus))]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.gameStarted,
                                      expression: "gameStarted"
                                    }
                                  ],
                                  class: _vm.wagerStatusClass
                                },
                                [_vm._v("| " + _vm._s(_vm.wagerStatus))]
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-card-text",
                {
                  staticClass: "chat-notification-message pa-0 pt-1 black--text"
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c("v-col", { staticClass: "px-5 pt-0 pb-2" }, [
                        _c("span", { staticClass: "message" }, [
                          _vm.currentMessage.friendgroup_id
                            ? _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.currentMessage.nickname) + ": "
                                )
                              ])
                            : _vm._e(),
                          _c("i", [_vm._v(_vm._s(_vm.currentMessage.message))]),
                          _c("span", { staticStyle: { "font-size": "75%" } }, [
                            _vm._v(
                              " - " + _vm._s(_vm.currentMessage.dtformatted)
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "py-0 px-1" },
                [
                  _c("v-text-field", {
                    staticClass: "chat-notification-reply py-0 pb-2",
                    attrs: {
                      "hide-details": "",
                      placeholder: "Type your reply here",
                      solo: "",
                      dense: "",
                      rounded: ""
                    },
                    on: {
                      keypress: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.sendMessage($event)
                      },
                      focus: function($event) {
                        return _vm.swipe(false)
                      },
                      blur: function($event) {
                        return _vm.swipe(true)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-0 pa-0",
                                  attrs: {
                                    disabled:
                                      _vm.loading || _vm.reading || _vm.sending,
                                    icon: ""
                                  },
                                  on: { click: _vm.sendMessage }
                                },
                                [_c("v-icon", [_vm._v("send")])],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2282405287
                    ),
                    model: {
                      value: _vm.message,
                      callback: function($$v) {
                        _vm.message = $$v
                      },
                      expression: "message"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }