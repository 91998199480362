var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("h1", { staticClass: "text-center" }, [_vm._v("Page Not Found")]),
      _c("p", { staticClass: "text-center" }, [
        _vm._v(
          "The page you requested does not exist. Please go back, or select an item from the menu."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }