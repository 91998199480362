<template>
  <v-row class="my-0 py-0">
    <v-col cols="12" class="py-0">
      <v-card class="card container">
        <v-card-title @click="filterarrow = !filterarrow" class="py-0">
          <div class="mx-auto py-0">
            <span>Filter</span>
            <v-icon
              class="collapse-arrow"
              :class="{ 'collapse-arrow-down': filterarrow }"
            >keyboard_arrow_down</v-icon>
          </div>
        </v-card-title>
        <v-expand-transition>
          <div v-show="filterarrow">
            <v-row class="my-0 py-0 pl-2">
              <v-col cols="6" class="py-0">
                <v-checkbox
                  v-model="statusFilters"
                  value="pending"
                  label="Pending"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  v-model="statusFilters"
                  value="approved"
                  label="Approved"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  v-model="statusFilters"
                  value="rejected"
                  label="Rejected"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  v-model="typeFilters"
                  value="deposit"
                  label="Deposit"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  v-model="typeFilters"
                  value="withdrawal"
                  label="Withdrawal"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  v-model="typeFilters"
                  value="refund"
                  label="Refund"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-checkbox
                  v-model="typeFilters"
                  value="wager"
                  label="Wager"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="my-0 py-0">
              <v-col cols="12" class="py-0">
                <v-menu
                  v-model="startdatepicker"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startdateDisplay"
                      label="From Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      @clear="clearStartDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :value="startdate"
                    @input="setStartDate"
                    :max="new Date().toISOString().substr(0, 10)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-menu
                  v-model="enddatepicker"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="enddateDisplay"
                      label="To Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      @clear="clearEndDate"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :value="enddate"
                    @input="setEndDate"
                    :max="new Date().toISOString().substr(0, 10)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row align="end" justify="end" class="my-0 py-0">
              <v-col cols="100" class="py-0">
                <v-btn
                  class="white--text pull-right"
                  color="black"
                  @click="applyFilters"
                >Set Filters</v-btn>
                <v-btn class="white--text ml-2" color="grey" @click="clearDates">Clear Dates</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </v-card>
    </v-col>
    <v-col cols="12" class="py-0">
      <TransactionTable
        :transactions="transactions"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loading="loading"
        :search="search"
        @pagination="handlePagination"
      ></TransactionTable>
    </v-col>
  </v-row>
</template>

<script>
import TransactionService from '@/services/TransactionService'
import TransactionTable from '@/components/TransactionTable.vue'

export default {
  components: {
    TransactionTable
  },
  data: function () {
    return {
      startdatepicker: false,
      enddatepicker: false,
      loading: true,
      transactions: [],
      totalItems: 0,
      itemsPerPage: 10,
      page: 1,
      search: '',
      statusFilters: [],
      typeFilters: [],
      startdate: null,
      enddate: null,
      startdateDisplay: '',
      enddateDisplay: '',
      filterarrow: false,
      mindate: null,
      maxdate: null
    }
  },
  watch: {
    startdateDisplay (newVal) {
      if (!newVal) {
        this.clearStartDate()
      }
    },
    enddateDisplay (newVal) {
      if (!newVal) {
        this.clearEndDate()
      }
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    init: function () {
      this.getTransactions()
    },
    getTransactions: function () {
      this.loading = true
      const params = {
        status: this.statusFilters,
        type: this.typeFilters,
        startdate: this.startdate,
        enddate: this.enddate,
        search: this.search,
        page: this.page,
        itemsPerPage: this.itemsPerPage
      }
      TransactionService.getFilteredUserTransactions(params).then(
        (response) => {
          this.transactions = response.data.transactions
          this.totalItems = response.data.total
          this.loading = false
        },
        (error) => {
          console.error(error)
          this.loading = false
        }
      )
    },
    applyFilters: function () {
      this.page = 1
      this.getTransactions()
    },
    handlePagination: function (pagination) {
      this.page = pagination.page
      this.itemsPerPage = pagination.itemsPerPage
      this.getTransactions()
    },
    setStartDate: function (date) {
      this.startdatepicker = false
      this.startdate = `${date}T00:00:00`
      this.startdateDisplay = date
    },
    setEndDate: function (date) {
      this.enddatepicker = false
      this.enddate = `${date}T23:59:59`
      this.enddateDisplay = date
    },
    clearStartDate: function () {
      this.startdate = null
      this.startdateDisplay = ''
    },
    clearEndDate: function () {
      this.enddate = null
      this.enddateDisplay = ''
    },
    clearDates: function () {
      this.clearStartDate()
      this.clearEndDate()
      this.applyFilters()
    }
  }
}
</script>

<style scoped>
.collapse-arrow {
  transition: transform 0.3s ease;
}
.collapse-arrow-down {
  transform: rotate(180deg);
}
</style>
