var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: "",
                fullscreen: "",
                "hide-overlay": "",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.showDialog,
                callback: function($$v) {
                  _vm.showDialog = $$v
                },
                expression: "showDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c(
                      "div",
                      {
                        staticClass: "mx-auto text-center",
                        staticStyle: { "font-size": "30px" }
                      },
                      [
                        _vm._v(" Payment Instructions"),
                        _c("br"),
                        _vm._v(" &"),
                        _c("br"),
                        _vm._v("Confirmation ")
                      ]
                    )
                  ]),
                  _c(
                    "v-card-text",
                    { staticClass: "black--text" },
                    [
                      _vm.wager.paymentdenycount > 0
                        ? _c(
                            "v-row",
                            { staticClass: "my-0 py-0" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.wager.winner_id ===
                                          _vm.wager.offeredby_id
                                          ? _vm.wager.offername
                                          : _vm.wager.takername
                                      ) +
                                        " could not confirm receiving your payment. Please double check your transaction."
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        {
                          staticClass: "my-0 py-0",
                          attrs: { justify: "space-between" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("span", { staticClass: "action-section" }, [
                                _vm._v("Bet:")
                              ]),
                              _vm._v(" #" + _vm._s(_vm.wager.id) + " "),
                              _c("span", { staticClass: "loser" }, [
                                _vm._v("LOSS")
                              ]),
                              _vm._v(" " + _vm._s(_vm.mySideInfo) + " ")
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("span", { staticClass: "action-section" }, [
                                _vm._v("Amount:")
                              ]),
                              _vm._v(" $" + _vm._s(_vm.wager.amount) + " ")
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("span", { staticClass: "action-section" }, [
                                _vm._v("Pay to:")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.wager.winner_id ===
                                      _vm.wager.offeredby_id
                                      ? _vm.wager.offername
                                      : _vm.wager.takername
                                  ) +
                                  " " +
                                  _vm._s(_vm.otherSideInfo) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pt-0 pb-4", attrs: { cols: "12" } },
                            [
                              _c("span", { staticClass: "action-section" }, [
                                _vm._v("Venmo:")
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  staticStyle: { "font-size": "16px" },
                                  attrs: {
                                    href: _vm.paymentlink,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.paymenthandle))]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          "1. Copy or note the bet reference for entering in Venmo note:"
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "robo text-center",
                          staticStyle: {
                            "font-size": "3rem",
                            "line-height": "normal"
                          }
                        },
                        [_vm._v(" VZ" + _vm._s(_vm.wager.id) + " ")]
                      ),
                      _c("p", [
                        _vm._v(
                          "2. Use the link below or go to your Venmo app and pay "
                        ),
                        _c("b", [_vm._v(_vm._s(_vm.paymenthandle))]),
                        _vm._v(":")
                      ]),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "text-center pb-4",
                            staticStyle: { width: "100%" }
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                staticStyle: { "font-size": "16px" },
                                attrs: {
                                  href: _vm.paymentlink,
                                  target: "_blank"
                                }
                              },
                              [_vm._v(_vm._s(_vm.paymentlink))]
                            )
                          ]
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          "3. IMPORTANT: Confirm below attesting to paying the winner in full for the referenced bet."
                        )
                      ])
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "black" },
                          on: { click: _vm.cancel }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "light-green darken-2" },
                          on: { click: _vm.agree }
                        },
                        [_vm._v("Payment Made")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }