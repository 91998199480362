<template>
  <v-card
    class="vig-card"
    v-if="dispute && dispute.logs && dispute.logs.length > 0 && logsWithUploads.length > 0"
  >
    <v-card-title
      class="py-0"
      @click="expand = !expand"
    >
      Uploaded Screenshots
    </v-card-title>
    <v-expand-transition>
      <div
        v-if="expand"
        class="vig-card-expand"
        style="margin-top: 0.5em;"
      >
        <v-card-text
          class="py-0"
          v-for="log in logsWithUploads"
          :key="log.id"
        >
          <v-img
            :src="log.document.url"
            alt="Screenshot"
          ></v-img>
          <p class="text-center">
            <a :href="log.document.url">Uploaded by {{ (log.user_id === dispute.disputeuser_id) ? dispute.disputeuser : dispute.againstuser }} on {{ log.logdate }}</a></p>
        </v-card-text>
        <v-divider></v-divider>
      </div>
    </v-expand-transition>
    <v-card-actions class="pa-0">
      <div
        class="vig-card-expand-container"
        @click="expand = !expand"
      >
        <v-icon>{{ (expand) ? 'arrow_drop_up' : 'arrow_drop_down' }}</v-icon>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'DisputeImages',
  props: {
    dispute: Object
  },
  computed: {
    logsWithUploads: function () {
      var logs = []
      if (this.dispute && this.dispute.logs && this.dispute.logs.length > 0) {
        this.dispute.logs.forEach(function (l) {
          if (l.document_id > 0) {
            logs.push(l)
          }
        })
      }
      return logs
    }
  },
  data: function () {
    return {
      expand: false
    }
  },
  methods: {
  }
}
</script>
