var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.game
    ? _c(
        "v-row",
        { staticClass: "my-0 py-0" },
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [_c("BoxScore", { attrs: { scoreboard: _vm.game.scoreboard } })],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0" },
            [_c("BetInfo", { attrs: { game: _vm.game, detail: _vm.detail } })],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }