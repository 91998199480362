var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.user_id && _vm.user_id != 0 && _vm.userdata
      ? _c(
          "div",
          { staticStyle: { "font-size": "1.1rem" } },
          [
            _c(
              "v-row",
              { staticClass: "mb-0 mt-n3 stats-banner" },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "py-0 white--text robo",
                    attrs: { cols: "7" }
                  },
                  [_vm._v(" " + _vm._s(_vm.userdata.nickname) + " ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "py-0", attrs: { cols: "5" } },
                  [
                    _vm.userdata.rating.rating > 0
                      ? _c("v-rating", {
                          staticClass: "pull-right",
                          attrs: {
                            length: "5",
                            "half-increments": "",
                            readonly: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function(props) {
                                  return [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "pa-0",
                                        attrs: {
                                          color:
                                            props.isFilled || props.isHalfFilled
                                              ? "yellow darken-2"
                                              : "grey lighten-1"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.isHalfFilled
                                                ? "star_half"
                                                : "grade"
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2473410521
                          ),
                          model: {
                            value: _vm.userdata.rating.rating,
                            callback: function($$v) {
                              _vm.$set(_vm.userdata.rating, "rating", $$v)
                            },
                            expression: "userdata.rating.rating"
                          }
                        })
                      : _c("span")
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "px-2" },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "my-0 px-1",
                    attrs: { justify: "space-between" }
                  },
                  [
                    _c("v-col", { attrs: { align: "center" } }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "stat-tiles background-green text-center robo",
                          attrs: { justify: "center" }
                        },
                        [
                          _c("div", { staticClass: "center" }, [
                            _vm._v("WIN"),
                            _c("br"),
                            _vm._v(_vm._s(_vm.userdata.wins))
                          ])
                        ]
                      )
                    ]),
                    _c("v-col", { attrs: { align: "center" } }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "stat-tiles background-red text-center robo"
                        },
                        [
                          _c("div", { staticClass: "center" }, [
                            _vm._v("LOSS"),
                            _c("br"),
                            _vm._v(_vm._s(_vm.userdata.losses))
                          ])
                        ]
                      )
                    ]),
                    _c("v-col", { attrs: { align: "center" } }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "stat-tiles background-blue text-center robo"
                        },
                        [
                          _c("div", { staticClass: "center" }, [
                            _vm._v("TIE"),
                            _c("br"),
                            _vm._v(_vm._s(_vm.userdata.ties))
                          ])
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "black--text my-0",
                    attrs: { justify: "space-between" }
                  },
                  [
                    _c("v-col", { staticClass: "text-left py-0" }, [
                      _vm._v("Win Percentage")
                    ]),
                    _c("v-col", { staticClass: "text-right py-0" }, [
                      _c("span", [
                        _vm._v(_vm._s(Math.floor(_vm.userdata.winperc)) + "%")
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "black--text my-0",
                    attrs: { justify: "space-between" }
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text-left py-0", attrs: { cols: "10" } },
                      [_vm._v("Avg hours to settle bet")]
                    ),
                    _c("v-col", { staticClass: "text-right py-0" }, [
                      _vm.userdata.timetosettle
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.userdata.timetosettle.toFixed(2)) +
                                " "
                            )
                          ])
                        : _c("span", [_vm._v("---")])
                    ])
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "black--text my-0 row-dark",
                    attrs: { justify: "space-between" }
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text-left py-0", attrs: { cols: "10" } },
                      [_vm._v("Avg hours to confirm payment")]
                    ),
                    _c("v-col", { staticClass: "text-right py-0" }, [
                      _vm.userdata.timetoconfirm
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.userdata.timetoconfirm.toFixed(2))
                            )
                          ])
                        : _c("span", [_vm._v("---")])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }