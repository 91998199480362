<template>
  <div>
    <v-dialog
      v-model="showDialog"
      v-if="showDialog"
      persistent
    >
      <v-card>
        <v-card-title>
          Wager Details
        </v-card-title>
        <v-card-text>
          {{ wagerinfo() }}
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-btn color="black" class="white--text" @click="cancel()">
            Cancel
          </v-btn>
          <v-btn color="red darken-1" class="white--text" @click="dispute()">
            Dispute
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DashboardService from '@/services/DashboardService'
import DateService from '@/services/DateService'

export default {
  data: () => {
    return {
      loading: false
    }
  },
  computed: {
    showDialog: function () {
      return (this.wager && this.wager.id > 0)
    }
  },
  props: {
    wager: {
      type: Object,
      default: null
    }
  },
  methods: {
    async dispute () {
      this.$updateloading(1)
      await DashboardService.settleDebt(this.wager.id).then(
        (response) => {
          this.$updateloading(-1)
          this.$emit('paid')
        },
        (error) => {
          this.$updateloading(-1)
          alert('An error has occured, please try again later. If the error continues to happen please contact us\n' + error.response.data.error)
        }
      )
    },
    cancel () {
      this.$emit('cancel')
    },
    wagerinfo: function () {
      var time = DateService.formatDateForJS(this.wager.paymentsent)
      let str = ''
      str += (time.getMonth() + 1) + '/' + time.getDate() + ' at ' + time.getHours() + ':' + time.getMinutes()
      return 'Sent on ' + str + ' . ' + 'It may take some time for a user to confirm your payment.' +
        'If you have waited for more than 2 hours you may create a dispute now and provide proof of payment.\n' +
        'A VigZero admin will review the dispute claim and resolve this problem as soon as possible.'
    }
  }
}
</script>
