import { render, staticRenderFns } from "./IconPending.vue?vue&type=template&id=a14d1780&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/websites/vigzero/vig-zero-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a14d1780')) {
      api.createRecord('a14d1780', component.options)
    } else {
      api.reload('a14d1780', component.options)
    }
    module.hot.accept("./IconPending.vue?vue&type=template&id=a14d1780&", function () {
      api.rerender('a14d1780', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/icons/IconPending.vue"
export default component.exports